import {SUBSCRIPTION} from './api-v2';
import DateUtils from './date-utils';

export default class PricingUtils {
  constructor(subscription, user) {
    this.subscription = subscription;
    this.user = user;
  }

  isValid() {
    return this.subscription && this.user;
  }

  getNumCreditsInPlan() {
    if (!this.isValid()) return null;

    const credits = SUBSCRIPTION.getMaxMonthlyUploadsBySub(this.subscription);
    if (credits === Number.MAX_SAFE_INTEGER) return 'unlimited';
    return credits;
  }

  isTeamPlan() {
    if (!this.isValid()) return null;

    return this.user.teamId;
  }

  getCreditsRemainingBeforeExtraCharges() {
    if (!this.isValid()) return null;

    const credits = SUBSCRIPTION.getUploadsRemainingBeforeMetered(this.subscription);
    if (credits === Number.MAX_SAFE_INTEGER) return 'unlimited';
    return credits;
  }

  getMeteredRenewalDate() {
    if (!this.isValid()) return null;

    const renewalDate = new Date(this.subscription.meteredCurrentPeriodEnd);
    return renewalDate.toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});
  }

  timeTilCreditRenewalString() {
    if (!this.isValid()) return null;

    const daysTilCreditReload = SUBSCRIPTION.daysTilCreditRenewal(this.subscription);
    if (daysTilCreditReload === null) return '';
    if (daysTilCreditReload < 0) return 'Your credits have automatically renewed.';
    const timeTilCreditReload = SUBSCRIPTION.timeTilCreditRenewal(this.subscription);
    return 'Your monthly upload credits will renew in ' + timeTilCreditReload + '.';
  }

  nextPaymentString() {
    if (!this.isValid()) return null;

    const daysTilCreditReload = SUBSCRIPTION.daysTilCreditRenewal(this.subscription);
    if (daysTilCreditReload === null) return '';
    const dateOfCreditRenewal = DateUtils.formatDate(this.subscription.current_period_end);
    const paymentCost = SUBSCRIPTION.getNextSubPaymentCost(this.subscription);
    if (!SUBSCRIPTION.isSubActive(this.subscription.sub_status) || paymentCost === 0) {
      return "You don't have any upcoming payments.";
    } else if (paymentCost == null) {
      return 'Your next payment will be on ' + dateOfCreditRenewal + '.';
    } else if (paymentCost === 0) {
      return "You don't have any upcoming payments.";
    } else {
      return 'Your next payment of ' + paymentCost + ' will be on ' + dateOfCreditRenewal + '.';
    }
  }

  getSubUsageProgressValue() {
    if (!this.isValid()) return null;

    const creditsAvailable = this.getNumCreditsInPlan();
    const creditsRemaining = this.getCreditsRemainingBeforeExtraCharges();
    return (creditsRemaining / creditsAvailable) * 100;
  }

  getSubscriptionUsageInfo() {
    if (!this.isValid()) return null;

    return `You have ${this.getCreditsRemainingBeforeExtraCharges()} / ${this.getNumCreditsInPlan()} uploads remaining in your ${this.isTeamPlan() ? "team's " : ''} ${SUBSCRIPTION.getProductNamePretty(this.subscription?.product, this.subscription?.price_id, this.subscription?.sub_status)} Plan this month. ${this.timeTilCreditRenewalString()} ${this.nextPaymentString()}`;
  }

  getMeteredUsage() {
    if (!this.isValid()) return null;

    if (this.subscription.meteredSubStatus !== 'active') return null;
    return this.subscription.meteredUploads ?? 0;
  }

  getMeteredUsageInfo() {
    if (!this.isValid()) return null;
    if (this.getMeteredUsage() == null) return null;

    const nextInvoiceDate = this.getMeteredUsage()
      ? `You will receive an invoice on ${this.getMeteredRenewalDate()}.`
      : '';
    return (
      `You also have an active Pay Per Upload plan, so you can upload freely and will be invoiced at the end of the month for any uploads you use in excess of your ${SUBSCRIPTION.getProductNamePretty(this.subscription?.product, this.subscription?.price_id, this.subscription?.sub_status)} Plan. You've used ${this.getMeteredUsage() ?? 0} additional credits this month.` +
      nextInvoiceDate
    );
  }
}
