import {
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {useEffect, useRef, useState} from 'react';

const Latency = ({setSeekLatency, defaultLatency}) => {
  const [sliderValue, setSliderValue] = useState(defaultLatency ?? 30);
  const [showTooltip, setShowTooltip] = useState(false);

  const [stateShowStartButton, setShowStartButton] = useState(true);

  // howler instances
  const refHowler = useRef();
  const refSoundId = useRef();
  const refHowler2 = useRef();
  const refSoundId2 = useRef();

  const refTimer = useRef();
  const refLatency = useRef(defaultLatency ?? 30);

  useEffect(() => {
    initHowler();

    // force stopping audio when user exits screen
    return () => refHowler.current.pause();
  }, []);

  function initHowler() {
    refHowler.current = getHowlerInstance(true);
    refHowler2.current = getHowlerInstance(false);
  }

  function getHowlerInstance(isFirstInstance) {
    const {Howl} = require('howler');
    return new Howl({
      src: 'audio/latency.wav',
      html5: true,
      onload: (id) => {},
      onloaderror: (id, error) => console.log(error),
      onseek: (id) => console.log('onseek id: ', id),
      onplayerror: (id, error) => console.log(error),
      onpause: (id) => console.log('AP onPause'),
      onplay: (id) => console.log('AP onPlay'),
      onend: () => {
        console.log('AP onEnd', isFirstInstance);
        play();
      },
    });
  }

  function onSliderChange(val) {
    setSliderValue(val);
    refLatency.current = val;
    setSeekLatency(val / 1000);
  }

  function play() {
    setShowStartButton(false);

    refHowler.current.seek(0, refSoundId.current);
    refSoundId.current = refHowler.current.play();

    clearInterval(refTimer.current);
    refTimer.current = setInterval(() => {
      const ttlDur = refHowler.current.duration();
      if (refHowler.current.seek() >= ttlDur / 2) {
        console.log('seeking');
        refHowler.current.pause();
        seek(false, ttlDur / 2);
        clearInterval(refTimer.current);
      }
    }, 10);
  }

  function seek(isFirstInstance, pos) {
    const player = isFirstInstance ? refHowler.current : refHowler2.current;
    const soundId = isFirstInstance ? refSoundId.current : refSoundId2.current;
    pos = pos + refLatency.current / 1000;
    player.seek(pos, soundId);
    const newSoundId = player.play();
    if (isFirstInstance) {
      refSoundId.current = newSoundId;
    } else {
      refSoundId2.current = newSoundId;
    }
  }

  function pause() {
    setShowStartButton(true);

    refHowler.current.pause();
    refHowler2.current.pause();
    clearInterval(refTimer.current);
  }

  const done = () => pause();

  return (
    <Flex direction={'row'} justify={'center'}>
      <Stack justify={'start'}>
        <Text>Adjust the slider until the word "latency" sounds smooth, then click Done.</Text>

        {stateShowStartButton && <Button onClick={play}>Start</Button>}

        <Slider
          id="slider"
          min={0}
          defaultValue={defaultLatency}
          max={500}
          aria-label="latency adjustment slider"
          onChange={onSliderChange}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <Tooltip hasArrow bg="blue.500" color="white" placement="top" isOpen={showTooltip} label={`${sliderValue}`}>
            <SliderThumb />
          </Tooltip>
        </Slider>

        <Button color={'primary'} onClick={done}>
          Done
        </Button>
      </Stack>
    </Flex>
  );
};

export default Latency;
