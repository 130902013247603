import {Stack, Wrap, WrapItem} from '@chakra-ui/react';
import {forwardRef, useCallback, useImperativeHandle, useMemo, useState} from 'react';

import {ZCheckbox, ZInputSavable, ZNumberInput} from '../common/ComponentStyle';
import {BodySm, H4} from '../common/TextStyle';
import ButtonPrimary from './ButtonPrimary';

/**
 * passing ref so that the submit function can be invoked from outside of the form
 *
 * @param {Function} onSubmit -  returns the current forms state if validated, and returns false if not validated
 */
const ZForm = forwardRef(
  (
    {
      inputs,
      onSubmit = () => console.log('onSubmit not passed as prop'),
      onChange: onChangeCallback = undefined,
      initialState = {},
      buttonLabel = 'Submit',
      title,
      omitSubmitButton,
      ...props
    },
    ref,
  ) => {
    const [stateInvalidAttributes, setStateInvalidAttributes] = useState([]);

    const zFormOnChangeCallback = useCallback((attributeName) => {
      setStateInvalidAttributes((curr) => curr.filter((attribute) => attribute !== attributeName));
    }, []);

    const formManager = useMemo(
      () =>
        new ZFormManager(
          inputs.map((input) => input.id),
          onChangeCallback,
          zFormOnChangeCallback,
          initialState,
        ),
      [inputs, initialState],
    );

    useImperativeHandle(ref, () => ({
      async refSubmitForm() {
        return await submitForm();
      },
    }));

    async function submitForm() {
      const {state, invalidAttributes} = formManager.onSubmit();
      if (invalidAttributes) {
        setStateInvalidAttributes(invalidAttributes);
        return await onSubmit(false);
      } else {
        return await onSubmit(state);
      }
    }

    return (
      <Stack spacing={8} {...props}>
        {title && <H4>{title}</H4>}

        <Stack spacing={4}>
          {inputs &&
            inputs.map((input, index) => (
              <Stack key={index} spacing={0}>
                <ZInputCustom formManager={formManager} input={input} />
                {stateInvalidAttributes.includes(input.id) && <BodySm color={'red'}>This field is required.</BodySm>}
              </Stack>
            ))}
        </Stack>

        {!omitSubmitButton && (
          <Stack direction="row" justifyContent={'end'}>
            <Wrap>
              <WrapItem>
                <ButtonPrimary onClick={submitForm}>{buttonLabel}</ButtonPrimary>
              </WrapItem>
            </Wrap>
          </Stack>
        )}
      </Stack>
    );
  },
);

const ZInputCustom = ({input, formManager, ...props}) => (
  <Stack {...props}>
    <InputType input={input} formManager={formManager} />
  </Stack>
);

const InputType = ({input, formManager, ...props}) => {
  switch (input.inputType) {
    case 'input':
      return (
        <ZInputSavable
          attributeName={input.id}
          label={input.label}
          subLabel={input.subLabel}
          defaultValue={input.defaultValue}
          onChange={(attributeName, value) => formManager.onChange(attributeName, value, input.inputType)}
          labelType={input.labelType}
          placeholder={input.placeholder}
          isSaveable={false}
        />
      );

    case 'textArea':
      return (
        <ZInputSavable
          isTextArea={true}
          attributeName={input.id}
          label={input.label}
          subLabel={input.subLabel}
          defaultValue={input.defaultValue}
          onChange={(attributeName, value) => formManager.onChange(attributeName, value, input.inputType)}
          labelType={input.labelType}
          placeholder={input.placeholder}
          isSaveable={false}
        />
      );

    case 'checkbox':
      return (
        <ZCheckbox
          attributeName={input.id}
          defaultChecked={input.defaultValue}
          label={input.label}
          labelType={input.labelType}
          tooltipText={input.tooltipText}
          onChange={(attributeName, value) => formManager.onChange(attributeName, value, input.inputType)}
        />
      );

    case 'numberInput':
      return (
        <ZNumberInput
          attributeName={input.id}
          label={input.label}
          subLabel={input.subLabel}
          defaultValue={input.defaultValue}
          labelType={input.labelType}
          onChange={(attributeName, value) => formManager.onChange(attributeName, value, input.inputType)}
        />
      );

    default:
      console.log('inputType not found: ', input.inputType, input);
  }
};

class ZFormManager {
  constructor(attributeNames, onChangeCallback, zFormOnChangeCallback, initialState) {
    this.attributeNames = attributeNames ?? [];
    this.state = {};
    if (!initialState) {
      initialState = {};
    }

    // Initialize state with default values
    attributeNames.forEach((attributeName) => {
      const defaultValue = initialState[attributeName]?.value;
      const inputType = initialState[attributeName]?.type;
      const required = initialState[attributeName]?.required;

      this.state[attributeName] = {
        value: defaultValue !== undefined ? defaultValue : '',
        type: inputType,
        required: required,
      };
    });

    this.onChangeCallback = onChangeCallback ?? (() => {});
    this.zFormOnChangeCallback = zFormOnChangeCallback ?? (() => {});
  }

  getInvalidAttributes() {
    const invalidAttributes = [];

    // Error handling
    if (!this.state) {
      console.error('State is not defined');
      return null;
    }

    if (!Array.isArray(this.attributeNames)) {
      console.error('attributeNames should be an array');
      return null;
    }

    // Check each attributeName
    for (const attributeName of this.attributeNames) {
      const stateInput = this.state[attributeName];

      // Check if the value is a string and trim it if so
      const trimmedValue = typeof stateInput?.value === 'string' ? stateInput.value.trim() : stateInput?.value;

      // Check if the input is required and the value is falsy (including empty string after trimming)
      if (!stateInput || (stateInput.required !== false && !trimmedValue)) {
        invalidAttributes.push(attributeName);
      }
    }

    return invalidAttributes.length > 0 ? invalidAttributes : null;
  }

  onSubmit() {
    const invalidAttributes = this.getInvalidAttributes();
    return {state: this.state, invalidAttributes};
  }

  onChange(attributeName, value) {
    this.state[attributeName] = {...this.state[attributeName], value};
    this.onChangeCallback(attributeName, value, this.state[attributeName].type);
    this.zFormOnChangeCallback(attributeName);
  }
}

export {ZForm, ZInputCustom, ZFormManager};
