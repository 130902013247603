import {Box, Progress, Text} from '@chakra-ui/react';

/** value range is 1-100 */
const ZProgressBar = ({
  value,
  size = 'sm',
  animateDeterminate = true,
  transition = '0.5s',
  backgroundImage = null,
  ...props
}) => (
  <Box position="relative" height={2} width="100%">
    <Progress
      value={value}
      position="absolute"
      top="0"
      left="0"
      opacity=".9"
      borderRadius="8px"
      width="100%"
      zIndex={2}
      size={size}
      colorScheme="blue"
      sx={{
        '& > div:first-of-type': {
          transitionProperty: 'width',
          transition: transition,
          backgroundImage: backgroundImage ?? '/loadingBar.png',
          backgroundSize: '1000px',
          backgroundPosition: 'left center',
        },
      }}
      {...props}
    />

    {value != 100 && animateDeterminate && (
      <Progress
        isIndeterminate={true}
        zIndex={1}
        position="absolute"
        top="0"
        left="0"
        borderRadius="8px"
        width="100%"
        size={size}
        colorScheme="blue"
      />
    )}
  </Box>
);

export default ZProgressBar;
