import {Box, Flex, Icon, Stack} from '@chakra-ui/react';
import {useEffect, useMemo, useRef} from 'react';
import {FiDownload, FiUpload} from 'react-icons/fi';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {ZAlertFullWidth} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowHeader} from '../../components/common/Structural';
import {BodySmMuted} from '../../components/common/TextStyle';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import episodeStore from '../../stores/episode-store';
import {EPISODE, TRANSCRIPT} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import {Loader} from '../../utils/loader';

export const PrivateTranscript = () => {
  const {projectId, episodeId} = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const zUser = authStore((state) => state.user);
  const zSetError = appStore((state) => state.setError);
  const zIsLoading = appStore((state) => state.isLoading);
  const zSetLoading = appStore((state) => state.setLoading);

  const zEpisode = episodeStore((state) => state.episode);
  const zSetEpisode = episodeStore((state) => state.setEpisode);
  const zRemoveEpisode = episodeStore((state) => state.removeEpisode);
  const zSetStatus = episodeStore((state) => state.setStatus);
  const zTranscript = episodeStore((state) => state.transcript);
  const zSetTranscript = episodeStore((state) => state.setTranscript);
  const zTranscriptJobProgress = episodeStore((state) => state.transcriptJobProgress);
  const zSetTranscriptJobProgress = episodeStore((state) => state.setTranscriptJobProgress);
  const zNukeEpisodeStore = episodeStore((state) => state.nukeEpisodeStore);

  const refLoaders = useRef({});

  const {episodeApi, transcriptApi} = useEpisodeDependentAPIs();

  // EPISODE
  useEffect(() => {
    initialLoad();

    return () => {
      console.log('nuking episode store');
      stopAllLoaders();
      zNukeEpisodeStore();
    };
  }, []);

  function stopAllLoaders() {
    Object.values(refLoaders.current).forEach((loader) => {
      loader.stop();
    });
  }

  async function initialLoad() {
    console.log('EPISODE: initial load');

    // poll episode until complete
    zSetLoading(true);
    pollEpisode();
  }

  function pollEpisode() {
    console.log('EPISODE: poll episode');

    refLoaders.current['episode']?.stop(); // resetting loader if one already exists
    const loader = new Loader(() => episodeApi.getEpisode(false), {timeout: 45 * 60 * 1000});
    loader.initialResponse((episode) => {
      const status = EPISODE.getStatus(episode);
      updateEpisodeStore(episode, status);
      zSetLoading(false);
      zSetTranscriptJobProgress(0);
    });
    loader.isDone((episode) => {
      if (!episode) {
        zSetLoading(false);
        zSetTranscriptJobProgress(0);
        return true;
      }

      const status = EPISODE.getStatus(episode);
      updateEpisodeStore(episode, status);
      if ((status.errorStages?.length ?? 0) > 0) zSetError('pollEpisode', {errorStages: status.errorStages});
      return (
        status.allStagesComplete == true ||
        (status.errorStages?.length ?? 0) > 0 ||
        episodeStore.getState().episodePollingPaused
      );
    });
    loader.done((episode) => {
      console.log('episode poll done');
      if (!episode) {
        zSetTranscriptJobProgress(0);
        return;
      }

      const status = EPISODE.getStatus(episode);
      updateEpisodeStore(episode, status);
    });
    loader.start();
    refLoaders.current['episode'] = loader; // adding loader reference to be removed on unmount
  }

  function updateEpisodeStore(episode, status) {
    zSetEpisode(episode);
    zSetStatus(status);
  }

  useEffect(() => {
    if (!zEpisode) {
      return;
    }

    transcriptApi.setFileName(zEpisode.fileName);
    transcriptApi.setTranscriptionMethod(zEpisode.stages.stageTranscribe.config.method);
  }, [zEpisode]);

  // TRANSCRIPT
  useEffect(() => {
    if (!zEpisode || !zEpisode.resTranscribeJobId || zEpisode.resTranscriptUrl) {
      return;
    }

    // polling for job success
    refLoaders.current['transcript']?.stop(); // resetting loader if one already exists

    const loader = new Loader(() => transcriptApi.getTranscriptionJob(zEpisode.resTranscribeJobId), {
      timeout: 45 * 60 * 1000,
    });
    loader.isDone((job) => job?.status == 'error' || job?.status == 'completed'); // queued, processing, completed, error
    loader.progress((job) => updateTranscriptJobProgress(job));
    loader.done((job) => updateTranscriptJobProgress(job));
    loader.start();

    refLoaders.current['transcript'] = loader; // adding loader reference to be removed on unmount
  }, [zEpisode?.resTranscribeJobId]);

  function updateTranscriptJobProgress(job) {
    if (job?.status == 'error') {
      zSetTranscriptJobProgress(0);
      zSetError('TJOBID', {job, episodeId, projectId});
      return;
    } else if (job?.status == 'completed') {
      zSetTranscriptJobProgress(95); // 100 when url available
      return;
    } else if (job?.status == 'queued') {
      zSetTranscriptJobProgress(10);
      return;
    } else if (job?.status == 'processing') {
      zSetTranscriptJobProgress(30);
      return;
    } else if (job) {
      zSetTranscriptJobProgress(5);
      return;
    } else {
      zSetTranscriptJobProgress(0);
      return;
    }
  }

  useEffect(() => {
    if (!zEpisode || !zEpisode.resTranscriptUrl) {
      return;
    }

    transcriptApi.setTranscriptUrl(zEpisode.resTranscriptUrl);
    zSetTranscriptJobProgress(100);

    async function loadTranscript() {
      const transcript = await transcriptApi.getDataFromS3();
      if (!transcript) {
        zSetError('ST1 transcript', {message: 'transcript not found', episodeId, projectId});
        return;
      }

      zSetTranscript(transcript);
    }

    loadTranscript();
  }, [zEpisode?.resTranscriptUrl]);

  async function downloadThenDeleteTranscript() {
    zSetLoading('Download & delete...');

    // download
    await TRANSCRIPT.downloadDocx(zTranscript, zEpisode.fileName, false, true, null);

    // delete transcript
    let isSuccess = transcriptApi.deleteAssemblyJob(zEpisode.resTranscribeJobId);
    if (!isSuccess) {
      zSetError('onDeleteLibraryItem', {episodeId}, 'Something went wrong while deleting your transcript.');
    }

    isSuccess = await episodeApi.deleteEpisode(episodeId);
    if (!isSuccess) {
      zSetError('onDeleteLibraryItem', {episodeId}, 'Something went wrong while deleting your episode.');
    } else {
      zRemoveEpisode(zEpisode);
      zSetEpisode(null);
    }

    // disable download button
    zSetTranscriptJobProgress(0);
    zSetTranscript(null);
    zSetLoading(false);
  }

  function navigateToUpload() {
    const pathParts = location.pathname.split('/');

    while (pathParts.length > 0) {
      const lastPart = pathParts[pathParts.length - 1];
      if (lastPart === 'private_transcript') {
        pathParts.pop();
        break;
      }
      pathParts.pop();
    }

    navigate({pathname: pathParts.join('/')});
  }

  const Success = () => (
    <ZAlertFullWidth status="success" borderRadius={'10px'}>
      Your transcript is ready!
    </ZAlertFullWidth>
  );

  const LoadingBar = () => (
    <>
      {!zEpisode ? (
        <ZAlertFullWidth status={'info'}>
          The file you're looking for could not be found. Either it was deleted, or the url you've entered is incorrect.
        </ZAlertFullWidth>
      ) : (
        <Stack>
          <BodySmMuted>
            Your transcript will be ready soon. This page will refresh automatically. Transcript{' '}
            {zTranscriptJobProgress == 100 || zTranscript ? 'Complete' : 'Progress...'}
          </BodySmMuted>
          <ZProgressBar value={zTranscriptJobProgress ?? 0} size={'xs'} isIndeterminate={false} />
        </Stack>
      )}
    </>
  );

  const Body = () => (
    <>
      <Box pt={32}>{zTranscript ? <Success /> : <LoadingBar />}</Box>

      <Flex pt={8} gap={2} justifyContent={'end'}>
        <ButtonSecondary wrap={true} onClick={navigateToUpload} leftIcon={<Icon as={FiUpload} />} size="lg">
          Upload Another Episode
        </ButtonSecondary>
        <ButtonPrimary
          wrap={true}
          onClick={downloadThenDeleteTranscript}
          size={'lg'}
          leftIcon={<Icon as={FiDownload} />}
          isDisabled={!zTranscript}
        >
          Download Transcript
        </ButtonPrimary>
      </Flex>
    </>
  );

  return (
    <FlowContainer>
      <FlowHeader
        title={'Private Transcript'}
        description={
          "When your transcript is ready, you'll be able to download it here. You will only be able to access this transcript once, as it will be deleted when the download button is clicked."
        }
      />

      <FlowBody>{!zIsLoading && <Body />}</FlowBody>
    </FlowContainer>
  );
};
