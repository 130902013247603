import {Auth0Provider} from '@auth0/auth0-react';
import {createStandaloneToast} from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import {HelmetProvider} from 'react-helmet-async';

import App from './App';
import {theme} from './components/common/theme';
import './index.css';
import reportWebVitals from './reportWebVitals';

// search ZMetaTagHelmet.js to see tags
const tagManagerArgs = {gtmId: 'GTM-PNRX2WT'};
TagManager.initialize(tagManagerArgs);

const {ToastContainer} = createStandaloneToast(theme);

// disable console.log in production
if (!process.env.REACT_APP_DEV_ENV) {
  console.log = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Auth0Provider
      domain="podflow.us.auth0.com"
      clientId="P8crRhdhUAZLQlyxe2ShcrqlJDLcWbkd"
      redirectUri={window.location.origin}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <App />
      <ToastContainer />
    </Auth0Provider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
