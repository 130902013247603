import {Box, Flex, Icon, Stack, Tooltip} from '@chakra-ui/react';
import {useMemo, useState} from 'react';
import {BsCloud, BsCloudArrowUp, BsCloudCheck} from 'react-icons/bs';
import {FiFileText, FiHome, FiMenu} from 'react-icons/fi';
import {MdArrowBack} from 'react-icons/md';
import {Outlet, useNavigate} from 'react-router-dom';

import {ZIconButton} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowHeader} from '../../components/common/Structural';
import {H3} from '../../components/common/TextStyle';
import {EpisodeSettingsModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import {EPISODE, GPT} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import {ExportOptionsButton} from './EpSection';

export const EpisodeLayout = () => {
  const [stateDisplayEpisodeSettingsModal, setDisplayEpisodeSettingsModal] = useState();

  // App store
  const zSetError = appStore((state) => state.setError);
  const zSetLoading = appStore((state) => state.setLoading);

  // Episode store
  const zEpisode = episodeStore((state) => state.episode);
  const zUpdateEpisode = episodeStore((state) => state.updateEpisode);

  // Template store
  const zTemplates = episodeStore((state) => state.templates);
  const zSaveableTemplates = episodeStore((state) => state.saveableTemplates);

  // GPT store
  const zGptReqPromptsFinished = episodeStore((state) => state.gptReqPromptsFinished);

  // APIs
  const {episodeApi} = useEpisodeDependentAPIs();

  const isEpisodeSaveable = zSaveableTemplates && zSaveableTemplates.length > 0;

  const allPromptsFinished = useMemo(
    () => GPT.allPromptsFinished(zTemplates, zGptReqPromptsFinished),
    [zGptReqPromptsFinished],
  );

  return (
    <FlowContainer minHeight="100vh" maxHeight="100vh">
      <FlowHeader
        entireComponent={
          <EpisodeHeaderComponent
            isEpisodeSaveable={isEpisodeSaveable}
            allPromptsFinished={allPromptsFinished}
            setDisplayEpisodeSettingsModal={setDisplayEpisodeSettingsModal}
          />
        }
      />

      <FlowBody>
        <Outlet />

        <EpisodeSettingsModal
          openThisModal={stateDisplayEpisodeSettingsModal}
          episodeApi={episodeApi}
          zEpisode={zEpisode}
          zUpdateEpisode={zUpdateEpisode}
          zSetLoading={zSetLoading}
          zSetError={zSetError}
        />
      </FlowBody>
    </FlowContainer>
  );
};

const AutoSaveIcon = ({isEpisodeSaveable}) => {
  const zIsSaving = episodeStore((state) => state.isSaving);
  const zTranscriptSaveable = episodeStore((state) => state.transcriptSaveable);

  return (
    <Flex direction="row" alignItems="center">
      {zIsSaving ? (
        <Tooltip label={'saving'} borderRadius={'lg'}>
          <Box>
            <Icon color={'inherit'} w={'1.5em'} h={'1.5em'} as={BsCloudArrowUp} />
          </Box>
        </Tooltip>
      ) : isEpisodeSaveable || zTranscriptSaveable ? (
        <Tooltip label={'waiting'} borderRadius={'lg'}>
          <Box>
            <Icon color={'inherit'} w={'1.5em'} h={'1.5em'} as={BsCloud} />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip label={'saved'} borderRadius={'lg'}>
          <Box>
            <Icon color={'inherit'} w={'1.5em'} h={'1.5em'} as={BsCloudCheck} />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};

const EpisodeHeaderComponent = ({isEpisodeSaveable, allPromptsFinished, setDisplayEpisodeSettingsModal}) => {
  const navigate = useNavigate();

  const zEpisode = episodeStore((state) => state.episode);
  const zSelectedTabKey = episodeStore((state) => state.selectedTabKey); // the key that has been selected
  const zSetProgrammaticallySelectTabByKey = episodeStore((state) => state.setProgrammaticallySelectTabByKey);

  const zBreadcrumbs = appStore((state) => state.breadcrumbs);
  const zSetError = appStore((state) => state.setError);

  return (
    <Stack spacing={0}>
      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <Flex gap={4} maxWidth={'40vw'} alignItems={'center'}>
          <Flex gap={2} alignItems={'center'}>
            <ZIconButton
              icon={<Icon as={MdArrowBack} />}
              tooltipText={'Back to project'}
              onClick={() => {
                const targetBreadCrumb = zBreadcrumbs[zBreadcrumbs.length - 2];
                if (targetBreadCrumb) navigate(targetBreadCrumb.path);
              }}
            />
            {zSelectedTabKey !== 'home' && (
              <ZIconButton
                isPrimary={true}
                icon={<Icon as={FiHome} />}
                tooltipText={'Visit Home Tab'}
                onClick={() => zSetProgrammaticallySelectTabByKey('home')}
              />
            )}
          </Flex>

          <H3 maxWidth={'100%'} overflow="hidden" whiteSpace={'nowrap'} textOverflow="ellipsis">
            {zEpisode?.fileName ? zEpisode.fileName : 'Episode...'}
          </H3>
        </Flex>

        <Flex gap={4} alignItems={'center'}>
          <AutoSaveIcon isEpisodeSaveable={isEpisodeSaveable} />

          <Flex gap={2} alignItems={'center'}>
            <ZIconButton icon={<Icon as={FiMenu} />} onClick={() => setDisplayEpisodeSettingsModal({})} />

            <ExportOptionsButton
              buttonCta="Export All"
              triggerIsPrimaryButton={false}
              isDisabled={!allPromptsFinished}
              tooltipLabel={!allPromptsFinished ? 'Waiting for all content to finish generating...' : null}
              exportOptions={[
                {
                  cta: 'Export to document (.docx)',
                  icon: <Icon as={FiFileText} />,
                  onClick: () => {
                    try {
                      const {
                        episode,
                        templateMeta,
                        templates,
                        transcript,
                        transcriptFormatSettings,
                        speakers,
                        gptReqDataMap,
                      } = episodeStore.getState();
                      EPISODE.downloadEpisodeDocx(
                        episode.fileName,
                        templateMeta,
                        templates,
                        transcript,
                        transcriptFormatSettings,
                        speakers,
                        gptReqDataMap,
                      );
                    } catch (e) {
                      zSetError('generateAndDownloadDocx', e.message);
                    }
                  },
                },
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  );
};
