import {Box, Divider, Flex, Icon, Stack} from '@chakra-ui/react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {FiArrowRight} from 'react-icons/fi';
import {MdRefresh} from 'react-icons/md';
import {useParams} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import {Step} from '../../components/abstraction_high/StepComponents';
import ZCard from '../../components/abstraction_high/ZCard';
import {FlowHelpIcon, displayInfoToast} from '../../components/common/Structural';
import {BodySm, BodySmMuted, BodySmSemiBold, H4} from '../../components/common/TextStyle';
import {ConfirmModal, InputModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import {EPISODE} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import InputUtils from '../../utils/input-utils';
import {EpInputs} from './EpInputs';
import {AssignSpeakersMapping, TranscriptDisplay} from './Transcript';

export const EpPrefs = ({isModal, onCloseModal, pollEpisode}) => {
  const {projectId, episodeId} = useParams();

  const {episodeApi, transcriptApi, lambdaApi} = useEpisodeDependentAPIs();

  const refZForm = useRef(null);
  const zEpisode = episodeStore((state) => state.episode);
  const zSpeakers = episodeStore((state) => state.speakers);
  const zSetTranscriptSpeakerOptions = episodeStore((state) => state.setTranscriptSpeakerOptions);
  const zFormInputs = episodeStore((state) => state.formInputs);
  const zSetProgrammaticallySelectTabByKey = episodeStore((state) => state.setProgrammaticallySelectTabByKey);
  const zForceShowEpPrefs = episodeStore((state) => state.forceShowEpPrefs);
  const zSetForceShowEpPrefs = episodeStore((state) => state.setForceShowEpPrefs);
  const zSetTriggerInitTabElements = episodeStore((state) => state.setTriggerInitTabElements);
  const zSetError = appStore((state) => state.setError);

  const [stateSubmitting, setSubmitting] = useState(false);
  const [stateDisplayConfirmModalProps, setDisplayConfirmModalProps] = useState(null);

  const DETAILS_STEP_TITLE = 'Episode Info';
  const LABEL_STEP_TITLE = 'Label Speakers';

  const defaultStepContent = [
    {
      name: 'Step 1',
      tooltipLabel: 'Episode Info',
      title: DETAILS_STEP_TITLE,
      message:
        "We save the details you provide to improve the accuracy of your results, and to save you time you'd otherwise spend copying repetitive information between episodes.",
      bodyComponent: <EpInputs refZForm={refZForm} onInputSubmission={onInputSubmission} />,
      onContinue: startInputSubmission,
    },
    {
      name: 'Step 2',
      helpIcon: (
        <FlowHelpIcon
          ytSrc={'https://www.youtube.com/embed/HfuZiE-jHB8?si=uvShHogFs1V7yGM_&autoplay=1'}
          ytTitle={'Labeling Speakers in Podflow'}
          tooltipLabel={'Labeling Speakers in Podflow'}
          showPingAnimation={true}
          width={'.89rem'}
        />
      ),
      tooltipLabel: 'Label Speakers',
      title: LABEL_STEP_TITLE,
      message:
        'Labeling the speakers in the transcript will help us attribute ideas and perspectives to the correct speaker.',
      bodyComponent: <TranscriptSpeakerLabeling transcriptApi={transcriptApi} />,
    },
  ];

  const [stateCurrentStep, setCurrentStep] = useState(0);

  // auto navigate user to second step if they've already submitted inputs
  const [stateHasCheckedForStateAdvancement, setHasCheckedForStateAdvancement] = useState(false);
  useEffect(() => {
    if (stateHasCheckedForStateAdvancement) return;
    if (!zEpisode || !zFormInputs || zFormInputs.length == 0) return;
    if (!canGoToNextStep) return;
    if (stateCurrentStep !== 0) return;

    const hasUserSubmittedInputs = EPISODE.getHasUserSubmittedInputs(zEpisode, zFormInputs);
    if (hasUserSubmittedInputs) setCurrentStep(stateCurrentStep + 1);
    setHasCheckedForStateAdvancement(true);
  }, [zFormInputs]);

  /** @returns: unique names submitted via episode details */
  function getSubmittedGuestAndHostNames() {
    const epInputs = episodeStore.getState().epInputs;
    if (!epInputs || !Object.keys(epInputs).length) return [];

    const guestNames = getSubmittedGuestNames();
    const hostNames =
      (epInputs['iid_cb3842b137a6495895345f1b4628eba7'] || {})['value']?.split(',').map((name) => name.trim()) ?? [];
    const uniqueNames = new Set([...guestNames, ...hostNames]);
    return [...uniqueNames].filter((name) => name.length); // filtering empty values
  }

  /** @returns: unique guest names submitted via episode details */
  function getSubmittedGuestNames() {
    const epInputs = episodeStore.getState().epInputs;
    if (!epInputs || !Object.keys(epInputs).length) return [];

    const guestNames =
      (epInputs['iid_b38ff39c21dc447cb1b4c5712c1f520d'] || {})['value']?.split(',').map((name) => name.trim()) || [];
    const uniqueNames = new Set([...guestNames]);
    return [...uniqueNames].filter((name) => name.length); // filtering empty values
  }

  function updateSpeakerOptions() {
    const transcript = episodeStore.getState().transcript;
    if (!transcript) return; // can't update speaker options without transcript because speaker options is an element of transcript

    // update speaker options on tab switch with most recent host and guest name(s)
    // Guest Name(s): iid_b38ff39c21dc447cb1b4c5712c1f520d
    // Host Name(s): iid_cb3842b137a6495895345f1b4628eba7
    let uniqueNames = getSubmittedGuestAndHostNames();
    if (!uniqueNames.length) return;

    // add any names that are already in the transcript
    const existingNames = transcript.speakerOptions ?? [];
    uniqueNames = new Set([...uniqueNames, ...existingNames]);
    uniqueNames = [...uniqueNames];

    // create a set of unique names
    zSetTranscriptSpeakerOptions(uniqueNames);
  }

  useEffect(() => {
    updateSpeakerOptions();
  }, [stateCurrentStep]);

  function findStepIndexByTitle(title) {
    return memoStepContent.findIndex((step) => step.title === title);
  }

  const memoStepContent = useMemo(() => {
    if (!zEpisode) return defaultStepContent;

    if (zEpisode.errorFileInvalid) {
      const stepContentCopy = defaultStepContent.map((item) => ({...item}));
      stepContentCopy.unshift({
        name: 'Error',
        tooltipLabel: 'Error',
        bodyComponent: <FileInvalid />,
      });
      console.log('newContent size', stepContentCopy.length);
      setCurrentStep(0);
      return stepContentCopy;
    }

    return defaultStepContent;
  }, [zEpisode?.errorFileInvalid]);

  const goToPrevStep = () => {
    setCurrentStep(stateCurrentStep - 1);
  };

  const goToNextStep = async (skipOnContinueInvocation = false) => {
    if (!skipOnContinueInvocation) {
      const doContinue = await memoStepContent[stateCurrentStep].onContinue?.();
      if (doContinue === false) return;
    }

    // skip the speaker label step if there is only one speaker
    if (memoStepContent[stateCurrentStep + 1].title === LABEL_STEP_TITLE) {
      if (getSubmittedGuestAndHostNames().length === 1) {
        if (stateCurrentStep + 2 >= memoStepContent.length - 1) {
          submitPreferences(true);
          return;
        } else {
          setCurrentStep(stateCurrentStep + 2);
        }
      }
    }

    setCurrentStep(stateCurrentStep + 1);
  };

  const canGoToPrevStep = useMemo(() => {
    return stateCurrentStep > 0;
  }, [stateCurrentStep, memoStepContent]);

  const canGoToNextStep = useMemo(() => {
    if (zEpisode?.errorFileInvalid) return false;
    return stateCurrentStep < memoStepContent.length - 1;
  }, [stateCurrentStep, memoStepContent, zEpisode?.errorFileInvalid]);

  const isIndexDisabled = useMemo(() => {
    return (index) => {
      if (zEpisode?.errorFileInvalid) return index === 0 ? false : true;
      return false;
    };
  }, [stateCurrentStep, memoStepContent, zEpisode?.errorFileInvalid]);

  const memoHasUserLabeledSpeakers = useMemo(() => {
    const hasUserLabeledSpeakers = zSpeakers?.length > 0 && !zSpeakers.find((speaker) => speaker.length <= 1); // ensuring the speaker name is not just a single character
    return hasUserLabeledSpeakers;
  }, [zSpeakers]);

  async function submitPreferences(skippingToSubmit = false) {
    setSubmitting(true);

    const episode = episodeStore.getState().episode;
    const hasUserSubmittedInputs = EPISODE.getHasUserSubmittedInputs(episode, zFormInputs);
    if (!hasUserSubmittedInputs) {
      setSubmitting(false);
      displayInfoToast('Please submit the details about your episode.');
      setCurrentStep(findStepIndexByTitle(DETAILS_STEP_TITLE)); // go to the speaker labeling step
      return;
    }

    const skipSpeakerLabeling = getSubmittedGuestAndHostNames().length === 1;
    if (!skipSpeakerLabeling && !memoHasUserLabeledSpeakers) {
      setSubmitting(false);
      if (!skippingToSubmit) displayInfoToast('Please label the speakers in the transcript before continuing.');
      setCurrentStep(findStepIndexByTitle(LABEL_STEP_TITLE)); // go to the speaker labeling step
      return;
    }

    // Update Episode first and wait for it to complete
    const episodeUpdateSuccess = await episodeApi.updateEpisode({gptInvokedByUser: true});
    if (!episodeUpdateSuccess) {
      zSetError('EpPrefs.submitPreferences');
      setSubmitting(false);
      return;
    }

    // Invoke Lambda function after DynamoDB update completes
    const lambdaInvokeSuccess = await lambdaApi.invokeDataPipeline(projectId, episodeId);
    if (!lambdaInvokeSuccess) {
      zSetError('EpPrefs.submitPreferences.lambdaInvoke');
      setSubmitting(false);
      return;
    }

    onPreferencesSubmitted();
  }

  function onPreferencesSubmitted() {
    setSubmitting(false);

    if (isModal) {
      onCloseModal();
    }

    pollEpisode();
  }

  async function startInputSubmission() {
    // check if guest was submitted. If not, present confirmation modal
    if (getSubmittedGuestNames().length == 0) {
      setDisplayConfirmModalProps({});
      return false;
    } else {
      return await submitInputs();
    }
  }

  /** @returns: true if successful, false otherwise */
  async function submitInputs() {
    if (!refZForm.current) {
      zSetError('EpPrefs.submitInputs no refZForm.current');
      return;
    }

    setSubmitting(true);
    const inputsSubmitted = await refZForm.current.refSubmitForm();

    // trigger data pipeline, which is waiting for inputsCustom to start transcription
    const lambdaInvokeSuccess = await lambdaApi.invokeDataPipeline(projectId, episodeId);
    if (!lambdaInvokeSuccess) {
      zSetError('EpPrefs.submitInputs pipeline');
      setSubmitting(false);
      return;
    }

    setSubmitting(false);
    if (!inputsSubmitted)
      displayInfoToast('Please complete the required fields before submitting the details about your episode.');
    return inputsSubmitted;
  }

  /** @param isSuccess: is true if input submission was successful */
  function onInputSubmission(isSuccess) {
    console.log('onInputsSubmitted. isSuccess: ', isSuccess);
    // if (!isSuccess) {
    //   setSubmitting(false);
    //   displayInfoToast("Please complete the required fields before submitting the details about your episode.");
    //   return;
    // }
  }

  const Header = ({...props}) => {
    const zEpisode = episodeStore((state) => state.episode);

    const Steps = ({currentStep, ...props}) => {
      return (
        <Flex gap={2} width="100%" {...props}>
          {memoStepContent.map((data, index) => (
            <Step
              key={index}
              onClick={() => setCurrentStep(index)}
              isActive={currentStep === index}
              isDisabled={isIndexDisabled(index)}
              index={index}
              stepData={data}
            />
          ))}
        </Flex>
      );
    };

    const alreadySubmitted = zEpisode?.gptInvokedByUser == true;

    return (
      <ZCard variant="outline">
        <Stack>
          <Stack direction="row" justify="space-between">
            <Steps currentStep={stateCurrentStep} />
            <Stack flexGrow={1} />
            {canGoToPrevStep && (
              <ButtonSecondary
                onClick={goToPrevStep}
                tooltipLabel={'Go to previous step.'}
                tooltipLabelPlacement={'left'}
              >
                Back
              </ButtonSecondary>
            )}
            <ButtonPrimary
              tooltipLabel={
                alreadySubmitted
                  ? 'Already submitted'
                  : !canGoToNextStep && !memoHasUserLabeledSpeakers && 'Please label all speakers before submitting'
              }
              isDisabled={alreadySubmitted || (!canGoToNextStep && !memoHasUserLabeledSpeakers)}
              onClick={() => {
                if (canGoToNextStep) {
                  goToNextStep();
                } else {
                  submitPreferences();
                }
              }}
              rightIcon={canGoToNextStep && !alreadySubmitted && <Icon as={FiArrowRight} />}
              loadingText={canGoToNextStep ? 'Saving...' : 'Submitting...'}
              isLoading={stateSubmitting}
            >
              {alreadySubmitted ? 'Submitted' : canGoToNextStep ? 'Next' : 'Submit'}
            </ButtonPrimary>
            {alreadySubmitted && zForceShowEpPrefs && (
              <ButtonPrimary
                onClick={() => {
                  zSetForceShowEpPrefs(false);
                  zSetTriggerInitTabElements();
                }}
                rightIcon={<Icon as={FiArrowRight} />}
              >
                Home
              </ButtonPrimary>
            )}
          </Stack>
        </Stack>
      </ZCard>
    );
  };

  const Body = ({...props}) => {
    const stepContent = memoStepContent[stateCurrentStep] ?? memoStepContent[0];

    return (
      <Stack spacing={4}>
        <Flex gap={4}>
          <Box flex={7} variant="outline" height={'60vh'} maxHeight={'60vh'} overflow={'scroll'}>
            <Stack spacing={4}>
              <Stack pt={4} justifyContent={'space-between'}>
                <Stack height={'5rem'}>
                  <Flex gap={2} alignItems={'end'}>
                    <H4>{stepContent.title}</H4>
                    {stepContent.helpIcon}
                  </Flex>
                  <BodySmMuted>{stepContent.message}</BodySmMuted>
                </Stack>
              </Stack>
              {memoStepContent[stateCurrentStep]?.bodyComponent ?? memoStepContent[0].bodyComponent}
            </Stack>
          </Box>
          {!isModal && <Box flex={3}>{/* <Helpers lambdaApi={lambdaApi} /> */}</Box>}
        </Flex>
      </Stack>
    );
  };

  const memoHeader = useMemo(() => {
    return <Header />;
  }, [stateCurrentStep, memoStepContent, stateSubmitting, memoHasUserLabeledSpeakers]);

  const memoBody = useMemo(() => {
    return <Body />;
  }, [stateCurrentStep, memoStepContent]);

  return (
    <Stack spacing={4}>
      {memoHeader}
      {memoBody}
      <ConfirmModal
        positiveText="Continue"
        positiveCallback={async () => {
          const isSuccess = await submitInputs();
          if (isSuccess) goToNextStep(true);
        }}
        titleText={'No guests?'}
        bodyText={"You haven't specified any guests for this episode. Are you sure you want to continue?"}
        openThisModal={stateDisplayConfirmModalProps}
        closeModal={() => setDisplayConfirmModalProps(null)}
      />
    </Stack>
  );
};

const FileInvalid = ({...props}) => {
  const zEpisode = episodeStore((state) => state.episode);

  return (
    <Stack spacing={4} {...props}>
      <Stack pt={4}>
        <H4>Invalid File</H4>
        <BodySm>
          It seems something may be wrong with the file you uploaded. Sometimes this is a result of file formatting
          issues. If you're not sure what's wrong, try re-uploading your file as a mp3. Please send us a quick message
          if you have questions - we'd love to help resolve this for you.
        </BodySm>
        {zEpisode?.errorFileInvalid.message && <BodySm>Error Message: "{zEpisode?.errorFileInvalid.message}"</BodySm>}
        <BodySmSemiBold>
          We've added a credit back to your account so that you can try again with a different file.
        </BodySmSemiBold>
        <Divider pt={4} />
      </Stack>
    </Stack>
  );
};

const TranscriptSpeakerLabeling = ({transcriptApi}) => {
  const [stateSpeakerModalProps, setSpeakerModalProps] = useState({openThisModal: false});

  const zTranscript = episodeStore((state) => state.transcript);
  const zTranscriptJobProgress = episodeStore((state) => state.transcriptJobProgress);
  const zSetTranscriptUtterances = episodeStore((state) => state.setTranscriptUtterances);
  const zOriginalUtteranceSpeakerMapping = episodeStore((state) => state.originalUtteranceSpeakerMapping);
  const zSetOriginalUtteranceSpeakerMapping = episodeStore((state) => state.setOriginalUtteranceSpeakerMapping);

  const undoRecentSpeakerLabel = () => {
    zSetTranscriptUtterances(transcriptApi.setSpeakersInUtterances(zTranscript, zOriginalUtteranceSpeakerMapping));
    zSetOriginalUtteranceSpeakerMapping(null);
  };

  const resetSpeakerLabels = () => {
    const originalUtterances = transcriptApi.resetSpeakersInUtterances(zTranscript);
    if (!originalUtterances) {
      displayInfoToast(
        "We can't automatically reset these speaker labels. Please send us a quick message so we can resolve this.",
      );
      return;
    }
    zSetTranscriptUtterances(originalUtterances);
    zSetOriginalUtteranceSpeakerMapping(null);
    // zSetTranscriptSaveable(true); // not necessary - user is required to change this
  };

  const LabelingHeader = ({...props}) => {
    return (
      <Stack spacing={0} {...props}>
        <ZCard variant="outline" height={'4.6rem'}>
          <Flex justifyContent={'space-between'}>
            <Stack maxWidth={'66%'}>
              {!!zTranscript?.speakerOptions && (
                <AssignSpeakersMapping
                  transcriptApi={transcriptApi}
                  setSpeakerModalProps={setSpeakerModalProps}
                  mapHorizontally={true}
                />
              )}
            </Stack>
            <Flex gap={2}>
              {/* {zOriginalUtteranceSpeakerMapping && (
                <ButtonSecondary leftIcon={<Icon as={MdUndo} />} onClick={undoRecentSpeakerLabel}>
                  Undo Assignment
                </ButtonSecondary>
              )} */}
              {zTranscript?.original_utterance_speaker_labels && (
                <ButtonSecondary leftIcon={<Icon as={MdRefresh} />} onClick={resetSpeakerLabels}>
                  Reset Labels
                </ButtonSecondary>
              )}
            </Flex>
          </Flex>
        </ZCard>
        <InputModal {...stateSpeakerModalProps} inputValidationCallback={InputUtils.validateInputLengthFunc(2)} />
      </Stack>
    );
  };

  return (
    <Stack spacing={4}>
      {/* HEADING */}
      {zTranscriptJobProgress === 100 && <LabelingHeader />}

      {/* TRANSCRIPT */}
      <TranscriptDisplay height="40vh" />
    </Stack>
  );
};
