import {Flex, Icon, Stack, useDisclosure} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {FiCopy, FiFileText} from 'react-icons/fi';

import {ExportOptionsButton} from '../../pages/episode/EpSection';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import {copyToClipboard} from '../../utils';
import {TRANSCRIPT} from '../../utils/api-v2';
import ButtonSecondary from '../abstraction_high/ButtonSecondary';
import {BodyMdSemiBold, BodySm} from '../common/TextStyle';
import {ModalShell} from '../modals/Modal';

function DownloadTranscriptButton({
  isDisabled,
  onDownload: parentDownloadTrigger = () => {},
  fileName = 'podflow',
  formatSettings,
  speakers,
}) {
  const zSetError = appStore((state) => state.setError);
  const zTranscript = episodeStore((state) => state.transcript);

  const [stateSrtFormatModalProps, setSrtFormatModalProps] = useState();

  // Button Methods
  function onDownloadTranscript() {
    console.log('api.current.downloadDocx()');
    TRANSCRIPT.downloadDocx(zTranscript, fileName, formatSettings, speakers);
    parentDownloadTrigger();
  }

  function onDownloadSRTTranscript(formatSettings = {includeSpeakers: false}) {
    TRANSCRIPT.downloadSRT(zTranscript, fileName, formatSettings);
    parentDownloadTrigger();
  }

  return (
    <>
      <ExportOptionsButton
        buttonCta="Export Transcript"
        triggerIsPrimaryButton={false}
        isDisabled={isDisabled}
        exportOptions={[
          {
            cta: 'Copy to clipboard',
            icon: <Icon as={FiCopy} />,
            onClick: () => {
              try {
                const copyContent = TRANSCRIPT.getCopyContent(zTranscript, formatSettings, speakers);
                copyToClipboard(copyContent);
              } catch (e) {
                zSetError('transcript copy to clipboard', e.message);
              }
            },
          },
          {
            cta: 'Transcript (.docx)',
            icon: <Icon as={FiFileText} />,
            onClick: () => {
              try {
                onDownloadTranscript();
              } catch (e) {
                zSetError('generateAndDownloadDocx', e.message);
              }
            },
          },
          {
            cta: 'Transcript (.srt)',
            icon: <Icon as={FiFileText} />,
            onClick: () => {
              setSrtFormatModalProps({});
            },
          },
        ]}
      />
      <SrtFormatModal
        openThisModal={stateSrtFormatModalProps}
        onCloseCallback={(includeSpeakers) => {
          setSrtFormatModalProps(null);
          try {
            onDownloadSRTTranscript({includeSpeakers});
          } catch (e) {
            zSetError('onDownloadSRTTranscript', e.message);
          }
        }}
      />
    </>
  );
}

const SrtFormatModal = ({openThisModal, onCloseCallback, ...props}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (openThisModal) if (!isOpen) onOpen();
  }, [openThisModal]);

  function submit(includeSpeakers) {
    onCloseCallback(includeSpeakers);
    close();
  }

  function close() {
    onClose();
  }

  const header = <BodyMdSemiBold>SRT Format Options</BodyMdSemiBold>;

  const body = (
    <Stack>
      <BodySm>Would you like to include speaker labels in your subtitles?</BodySm>
    </Stack>
  );

  const footer = (
    <Flex justifyContent={'end'} gap={2}>
      <ButtonSecondary onClick={() => submit(true)}>With Speaker Labels</ButtonSecondary>
      <ButtonSecondary onClick={() => submit(false)}>Without Speaker Labels</ButtonSecondary>
    </Flex>
  );

  return (
    <ModalShell
      header={header}
      body={body}
      footer={footer}
      modalSize={'2xl'}
      disclosure={{isOpen, onOpen, onClose: close}}
      closeOnEsc={true}
    />
  );
};

export default DownloadTranscriptButton;
