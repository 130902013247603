import {Flex, Stack, useDisclosure} from '@chakra-ui/react';
import {useEffect, useMemo} from 'react';

import {ModalShell} from '../../components/modals/Modal';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import {EpPrefs} from './EpPrefs';

function EpModal({openThisModal, onCloseCallback = () => {}, pollEpisode, closeModal}) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const {episodeApi, lambdaApi, projectApi} = useEpisodeDependentAPIs();

  // ensuring the state that triggers this modal is reset when the modal is closed.
  // This allows the modal to be opened again if necessary
  useEffect(() => {
    return () => (isOpen ? onCloseCallback() : null);
  }, [isOpen]);

  useEffect(() => {
    if (openThisModal && !isOpen) onOpen();
  }, [openThisModal]);

  const header = <Stack></Stack>;
  const footer = <Flex></Flex>;
  const closedView = <Stack></Stack>;
  const body = useMemo(
    () => <EpPrefs isModal={true} onCloseModal={onClose} pollEpisode={pollEpisode} />,
    [episodeApi, lambdaApi, projectApi],
  );

  return (
    <ModalShell
      modalSize={'5xl'}
      closedView={closedView}
      header={header}
      body={body}
      footer={footer}
      disclosure={{isOpen, onOpen, onClose}}
      closeModal={closeModal}
    />
  );
}

export default EpModal;
