import {Stack} from '@chakra-ui/react';
import {useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {ZForm} from '../../components/abstraction_high/ZForm';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import projectStore from '../../stores/project-store';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';

export const EpInputs = ({refZForm, onInputSubmission = () => console.log('todo onInputsSubmitted')}) => {
  const {projectId} = useParams();

  const {episodeApi, projectApi} = useEpisodeDependentAPIs();

  const zProject = projectStore((state) => state.project);
  const zFormInputs = episodeStore((state) => state.formInputs);
  const zEpInputs = episodeStore((state) => state.epInputs);
  const zSetEpInputs = episodeStore((state) => state.setEpInputs);
  const zUpdateEpInput = episodeStore((state) => state.updateEpInput);
  const zEventFormInputRefresh = episodeStore((state) => state.eventFormInputRefresh);
  const zSetEpisodeInputsCustom = episodeStore((state) => state.setEpisodeInputsCustom);
  const zSetError = appStore((state) => state.setError);

  const inputsAndInitialState = useMemo(() => {
    if (!zFormInputs || !zProject) {
      return {inputs: [], initialState: {}};
    }

    // map over zFormInputs to get inputs array with default values from zEpInputs
    const inputs = zFormInputs.map((input) => ({
      ...input,
      defaultValue: zEpInputs?.[input.id]?.value, // ?? zProject.configInputs?.[input.id] };
    }));

    // creating initial state for form, but ensuring the state doesn't cause re-render of form
    const initialState = inputs.reduce(
      (acc, input) => {
        acc[input.id] = {...input, value: zEpInputs?.[input.id]?.value};
        return acc;
      },
      {...zEpInputs},
    );

    return {inputs, initialState};
  }, [zEpInputs, zFormInputs, zProject, zEventFormInputRefresh]);

  const changeHandler = useCallback((key, value, type) => zUpdateEpInput(key, {value, type}), [zUpdateEpInput]);

  const handleSubmit = useCallback(
    async (inputData) => {
      if (!inputData) {
        onInputSubmission(false);
        return false;
      }

      // update zustand store
      zSetEpInputs(inputData);

      const projectUpdatePromise = projectApi.updateProject(projectId, {
        configInputs: {
          ...zProject.configInputs,
          ...Object.entries(inputData).reduce((accumulator, [key, data]) => {
            if (zFormInputs.find((formInput) => formInput.id === key)?.reusable) accumulator[key] = data.value;
            return accumulator;
          }, {}),
        },
      });

      zSetEpisodeInputsCustom(inputData);
      const episodeUpdatePromise = episodeApi.updateEpisode({inputsCustom: inputData});

      const responses = await Promise.all([projectUpdatePromise, episodeUpdatePromise]);
      responses.forEach((isSuccess) => {
        if (isSuccess) {
          return true;
        }

        zSetError('EpInputs', 'projectUpdatePromise, episodeUpdatePromise, lambdaInvokePromise');
        onInputSubmission(false);
        return false;
      });

      onInputSubmission(true);
      return true;
    },
    [zFormInputs],
  );

  return (
    <Stack spacing={4}>
      <ZForm
        pt={8}
        ref={refZForm}
        inputs={inputsAndInitialState.inputs}
        buttonLabel="Submit"
        initialState={inputsAndInitialState.initialState}
        onSubmit={handleSubmit}
        onChange={changeHandler}
        omitSubmitButton={true}
      />
    </Stack>
  );
};
