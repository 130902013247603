export default class SessionStorageUtils {
  static Keys = {
    PRE_AUTH_PATH: 'preAuthPath',
    TIER_USAGE: 'tierUsage',
  };

  static isSessionStorageAvailable() {
    try {
      const key = '__test__';
      sessionStorage.setItem(key, key);
      sessionStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  static clearSessionStorage() {
    sessionStorage.clear();
  }

  /** param: preAuthPath optional - if not provided, using the current window path */
  static setPreAuthPath(preAuthPath = null) {
    const currentPath = preAuthPath ?? window.location.pathname + window.location.search;
    if (!currentPath || currentPath === '/') {
      return;
    }

    // Set expiration for 10 minutes from now (in milliseconds)
    const expiryTimestamp = Date.now() + 10 * 60 * 1000;
    sessionStorage.setItem(
      SessionStorageUtils.Keys.PRE_AUTH_PATH,
      JSON.stringify({path: currentPath, expires: expiryTimestamp}),
    );
  }

  /** returns path if navigation necessary */
  static checkPreAuthPath() {
    const storedData = JSON.parse(sessionStorage.getItem(SessionStorageUtils.Keys.PRE_AUTH_PATH));
    this.clearPreAuthPath();

    if (storedData && Date.now() <= storedData.expires) {
      return storedData.path;
    } else {
      return false;
    }
  }

  static clearPreAuthPath() {
    sessionStorage.removeItem(SessionStorageUtils.Keys.PRE_AUTH_PATH);
  }

  static setTierUsagePrevention() {
    // Set expiration for 10 minutes from now (in milliseconds)
    const expiryTimestamp = Date.now() + 10 * 60 * 1000;
    sessionStorage.setItem(this.Keys.TIER_USAGE, JSON.stringify({expires: expiryTimestamp}));
  }

  static checkTierUsagePrevention() {
    const storedData = JSON.parse(sessionStorage.getItem(this.Keys.TIER_USAGE));
    if (storedData && Date.now() <= storedData.expires) {
      return true;
    } else {
      return false;
    }
  }

  static clearTierUsagePrevention() {
    console.log('clearTierUsagePrevention() fired');
    sessionStorage.removeItem(this.Keys.TIER_USAGE);
  }
}
