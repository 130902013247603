import {loadFont as loadFontArchivo} from '@remotion/google-fonts/Archivo';
import {loadFont as loadFontBebasNeue} from '@remotion/google-fonts/BebasNeue';
import {loadFont as loadFontLato} from '@remotion/google-fonts/Lato';
import {loadFont as loadFontMontserrat} from '@remotion/google-fonts/Montserrat';
import {loadFont as loadFontNunito} from '@remotion/google-fonts/Nunito';
import {loadFont as loadFontOswald} from '@remotion/google-fonts/Oswald';
import {loadFont as loadFontPoppins} from '@remotion/google-fonts/Poppins';
import {loadFont as loadFontRaleway} from '@remotion/google-fonts/Raleway';
import {loadFont as loadFontRoboto} from '@remotion/google-fonts/Roboto';
import {loadFont as loadFontSplineSans} from '@remotion/google-fonts/SplineSans';
import {loadFont as loadFontTitanOne} from '@remotion/google-fonts/TitanOne';
import {useEffect, useState} from 'react';

export const AspectRatio = {
  SQUARE: '1:1',
  // FOUR_BY_THREE: '4:3',
  SIXTEEN_BY_NINE: '16:9',
  NINE_BY_SIXTEEN: '9:16',
  // FOUR_BY_FIVE: '4:5',
  // ONE_NINE_BY_ONE: '1.91:1',
};

export const FitFill = {
  FIT: 'Fit',
  FILL: 'Fill',
};

export const AspectRatioValue = {
  [AspectRatio.SQUARE]: 1 / 1,
  // [AspectRatio.FOUR_BY_THREE]: 4 / 3,
  [AspectRatio.SIXTEEN_BY_NINE]: 16 / 9,
  [AspectRatio.NINE_BY_SIXTEEN]: 9 / 16,
  // [AspectRatio.FOUR_BY_FIVE]: 4 / 5,
  // [AspectRatio.ONE_NINE_BY_ONE]: 1.91,
};

export const BackgroundColor = {
  BRAND: '#252525',
  BRAND2: '#3a3a3a',
  BRAND3: '#4f4f4f',
  BRAND4: '#646464',
  BRAND5: '#797979',
};

export const TextColor = {
  BRAND: '#f5f5f5',
  BRAND3: '#d9d9d9',
  BRAND2: '#e0e0e0',
  BRAND4: '#cccccc',
  BRAND5: '#b3b3b3',
};

export const FontFamily = {
  Roboto: loadFontRoboto().fontFamily,
  Montserrat: loadFontMontserrat().fontFamily,
  Lato: loadFontLato().fontFamily,
  Poppins: loadFontPoppins().fontFamily,
  Raleway: loadFontRaleway().fontFamily,
  Nunito: loadFontNunito().fontFamily,
  Oswald: loadFontOswald().fontFamily,
  Archivo: loadFontArchivo().fontFamily,
  TitanOne: loadFontTitanOne().fontFamily,
  BebasNeue: loadFontBebasNeue().fontFamily,
  SplineSans: loadFontSplineSans().fontFamily,
};

export const defaultInputProps = {
  aspectRatio: AspectRatio.SIXTEEN_BY_NINE,
  backgroundColor: BackgroundColor.BRAND,
  textColor: TextColor.BRAND,
  fontSize: 1.5,
  fontFamily: FontFamily.Roboto,
  fontWeight: 500,
  textPosition: '30%',
  defaultWidth: 1920,
  fill: false,
  fillPosition: 50,
  showCaption: true,
};

/**
 * Takes in a number of vh and returns the equivalent in px
 *
 * @param {*} vh
 * @returns
 */
export const convertVhToPx = (vh) => {
  const oneVhInPx = window.innerHeight / 100;
  return oneVhInPx * vh;
};

/**
 * Takes in height and aspect ratio and returns the width
 *
 * @param {keyof AspectRatio} aspectRatio
 * @param {number} height
 */
export const useHeightWidth = (aspectRatio, height, defaultWidth = 1) => {
  const [width, setWidth] = useState(defaultWidth);

  useEffect(() => {
    setWidth(parseInt(height * AspectRatioValue[aspectRatio]));
  }, [aspectRatio, height]);

  return [width, parseInt(height)];
};

/**
 * Takes in width and aspect ratio and returns the height
 *
 * @param {keyof AspectRatio} aspectRatio
 * @param {number} width
 */
export const useWidthHeight = (aspectRatio, width, defaultHeight = 1) => {
  const [height, setHeight] = useState(defaultHeight);

  useEffect(() => {
    setHeight(parseInt(width / AspectRatioValue[aspectRatio])); // (270 / (16 / 9)) =
  }, [aspectRatio, width]);

  return [parseInt(width), height];
};

/**
 * Takes in width and aspect ratio and returns the height
 *
 * @param {keyof AspectRatio} aspectRatio
 * @param {number} width
 */
export const calcWidthHeight = (aspectRatio, width) => {
  let height = Math.floor(width / AspectRatioValue[aspectRatio]);
  if (height % 2 !== 0) height -= 1;
  return [parseInt(width), height];
};

export const useFilterWords = (words, seconds) => {
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const out = words?.filter((word) => {
      return word.start <= seconds && word.end >= seconds;
    });

    setFiltered(out);
  }, [words, seconds]);

  return filtered;
};

/**
 *
 * @param {import('../../stores/episode-store').Word[]} words
 * @param {number} start
 * @param {number} end
 * @returns {import('../../stores/episode-store').Word}
 */
export const useGroupWords = (words, start, end) => {
  const [grouped, setGrouped] = useState([{}, {}, {}]);

  useEffect(() => {
    if (!words) return setGrouped([{}, {}, {}]); // idk maybe just set words to empty array

    const [pre, grouped, post] = words?.reduce(
      (result, word, index) => {
        if (word.start < start * 1000) {
          if (result[0].text === undefined) result.text = '';
          if (result[0].start === undefined) result.start = word.start;
          result[0].text += ' ' + word.text;
          result[0].end = word.end;
        }

        if (word.start >= start * 1000 && word.end <= end * 1000) {
          if (result[1].text === undefined) result[1].text = '';
          if (result[1].start === undefined) result[1].start = word.start;
          result[1].text += ' ' + word.text;
          result[1].end = word.end;
        }

        if (word.end > end * 1000) {
          if (result[2].text === undefined) result[2].text = '';
          if (result[2].start === undefined) result[2].start = word.start;
          result[2].text += ' ' + word.text;
          result[2].end = word.end;
        }

        return result;
      },
      [{}, {}, {}],
    );

    setGrouped([pre, grouped, post]);
  }, [words, start, end]);

  return grouped;
};

export const useDisplayWords = (words, every = 4) => {
  const [displayWords, setDisplayWords] = useState([]);

  useEffect(() => {
    setDisplayWords(
      words?.reduce((result, word, index) => {
        if (index % every === 0) {
          // Start a new group
          result.push({start: word.start, end: word.end, text: word.text});
        } else {
          // Add word to the current group
          result[result.length - 1].text += ' ' + word.text;
          result[result.length - 1].end = word.end;
        }
        return result;
      }, []),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(words), every]);

  return displayWords;
};
