import {Box, Text, Tooltip} from '@chakra-ui/react';

/**
 * @typedef {Object} NavItemProps
 * @property {string} name
 * @property {React.ReactNode} icon
 * @property {() => void} onClick
 * @property {boolean} selected
 * @property {'large' | string} navSize
 */

/** @param {NavItemProps} props */
const NavItem = ({name, icon, onClick, selected, navSize}) => (
  <Tooltip label={name} placement="right" borderRadius={'5px'}>
    <Box
      width="100%"
      paddingX={4}
      paddingY={4}
      display="flex"
      flexDirection="row"
      gap={4}
      bg={selected ? 'brand.bg' : 'gray.50'}
      borderRadius="10px"
      cursor="pointer"
      alignItems={'center'}
      justifyContent={navSize === 'large' ? 'start' : 'center'}
      shadow={selected ? 'base' : 'base'}
      _hover={{background: selected ? 'brand.bg2' : 'brand.bg', transition: 'all .2s'}}
      onClick={onClick}
      transition={'all .2s'}
    >
      {icon}

      <Text
        display={navSize === 'large' ? 'flex' : 'none'}
        fontFamily={'Inter'}
        fontWeight={'bold'}
        fontSize="large"
        lineHeight="5"
        color="gray.800"
        style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
      >
        {name}
      </Text>
    </Box>
  </Tooltip>
);

export default NavItem;
