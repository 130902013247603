import Cookies from 'universal-cookie';

export default class CookieUtils {
  constructor() {
    this.cookies = new Cookies();
  }

  static Keys = {
    IS_PODFLOW_ADMIN: 'isPodflowAdmin',
    USER_DISMISSED_TUTORIAL: 'userDismissedTutorial',
    LAST_SUB_TRANSACTION_ID: 'lastSubTransactionId',
  };

  getCookie(key) {
    return this.cookies.get(key);
  }

  getCookieAsBoolean(key) {
    let isPodflowAdmin = this.cookies.get(key) === 'true';
    return isPodflowAdmin;
  }

  /**
   *
   * @param {String} key
   * @param {String} value
   * @param {String} path - ie "/login" or "/" if this cookie is universally accessible
   * @param {Number} daysTilExpiration
   */
  setCookie(key, value, path = '/', daysTilExpiration = 30) {
    if (daysTilExpiration > 365) daysTilExpiration = 365;
    this.cookies.set(key, value, {path: path, maxAge: 60 * 60 * 24 * daysTilExpiration});
  }

  remove(key, path) {
    this.cookies.remove(key, {path: path});
  }
}
