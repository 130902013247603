import axios from 'axios';
import {Document, Packer} from 'docx';

import {formatAsFileName, replaceFileExt, truncateAtNearestSpace} from '.';
import {htmlToPlainText} from '../components/abstraction_high/QuillEditor';
import {TabIcons} from '../components/abstraction_high/ZTabs';
import Constants from './constants';
import {getPublicS3URL} from './data-transfer';
import DateUtils from './date-utils';
import DocxUtils from './docx-utils';
import PromptDepGraphs from './prompt-dep-graphs';
import {getUUID} from './uuid';

const devEnv = process.env.REACT_APP_DEV_ENV;

class API {
  constructor(userSub) {
    this.userSub = userSub;
  }

  /**
   * @param {string} path - i.e. /ddb/episode
   * @returns data if successful, false if not
   */
  async get(path, params = {}, throwError = false) {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + path, {params});
      if (response.status !== 200) throw new Error('api get response status: ' + response.status);
      return response.data;
    } catch (e) {
      if (throwError) {
        throw e;
      }

      if (e.response && e.response.status === 404) {
        console.log('get() 404:', path);
        return null;
      }

      console.log('get() error:', path, e);
      return null;
    }
  }

  /**
   * @param {string} path - i.e. /ddb/episode
   * @param {*} data - an object containing the data to be sent
   * @returns {Promise<boolean>} true if successful, false if not
   */
  async put(path, data = {}) {
    try {
      const response = await axios.put(process.env.REACT_APP_BASE_URL + path, data);
      if (response.status !== 200) throw new Error('api put response status: ' + response.status);
      return response.data;
    } catch (e) {
      console.log('put() error:', path, e);
      return false;
    }
  }

  /**
   * @param {string} path - i.e. /ddb/episode
   * @param {*} data - an object containing the data to be sent
   * @returns {Promise<boolean>} true if successful, false if not
   */
  async post(path, data = {}) {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + path, data);
      if (response.status !== 200) throw new Error('api post response status: ' + response.status);
      console.log('post() success');
      return response.data;
    } catch (e) {
      console.log('post() error:', path, e);
      return false;
    }
  }

  /**
   * @param {string} path - i.e. /ddb/episode
   * @param {import('axios').AxiosRequestConfig<any>['params'] | undefined} params - an object containing the parameters to be appended in the URL
   * @returns {Promise<boolean>} true if successful, false if not
   */
  async delete(path, params) {
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_URL + path, {params});
      if (response.status !== 200) throw new Error('api delete response status: ' + response.status);
      return response.data;
    } catch (e) {
      console.log('delete() error:', path, e);
      return false;
    }
  }

  /**
   * @param {string} origin
   * @param {string} error
   * @param {*} extraData
   * @returns {Promise<boolean>} true if successful, false if not
   */
  async postError(origin, error, extraData = {}) {
    if (devEnv) {
      console.log('skipping postError in dev env: ', origin, error, extraData);
      return true;
    }

    const data = {origin, error, extraData};
    const response = await this.post('/ddb/error', data);
    return response;
  }

  static blobDownload(blob, fileName) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
    }
  }

  static htmlDownload(html, fileName) {
    const blob = new Blob([html], {type: 'text/plain;charset=utf-8'});
    this.blobDownload(blob, fileName);
  }

  static createDocxDocument(docxElements) {
    return new Document({sections: [{properties: {}, children: docxElements}]});
  }

  static async docxDownload(doc, fileName) {
    // Used to export the file into a .docx file
    const buffer = await Packer.toBlob(doc);
    const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
    API.blobDownload(blob, fileName);
  }

  async getApiVersion() {
    const apiVersion = await this.get('/version');
    return apiVersion;
  }
}

class PROJECT extends API {
  constructor(userSub) {
    super(userSub);

    this.lastEvaluatedKeyMap = {}; // mapped by userSub-index
  }

  /**
   *
   * @returns project item if successful, null if not
   */
  async getProject(projectId) {
    const project = await this.get('/ddb/project', {projectId});
    return project;
  }

  /**
   *
   * @param {*} name
   * @returns project object if successful, false if not
   */
  async postProject(name, data = {}) {
    const project = await this.post('/ddb/project', {
      userSub: this.userSub, // secondary index
      name,
      ...data,
    });
    return project;
  }

  async updateProject(projectId, data) {
    const isSuccess = await this.put('/ddb/project', {
      projectId,
      ...data,
    });
    return isSuccess;
  }

  async getProjects(userSub, forceGet = false) {
    if (!forceGet && this.lastEvaluatedKeyMap[userSub] === null) return [];

    const {projects, lastEvaluatedKey} = await this.get('/ddb/projects', {
      userSub: userSub,
      lastEvaluatedKey: this.lastEvaluatedKeyMap[userSub],
    });
    this.lastEvaluatedKeyMap[userSub] = lastEvaluatedKey;

    const filteredProjects = this.filterPrivateProjects(userSub, projects);
    return PROJECT.filterArchivedProjects(filteredProjects);
  }

  /**
   * @param {*} userSub
   * @param {*} projects
   * @returns projects array with private projects filtered out, unless projects are owned by authUser
   */
  filterPrivateProjects(userSub, projects) {
    if (!projects) return [];

    return projects.filter((p) => {
      if (this.userSub === userSub) return true;
      return !p.isPrivate;
    });
  }

  static filterArchivedProjects(projects) {
    if (!projects) return [];

    return projects.filter((p) => !p.isArchived);
  }

  /**
   *
   * @param {*} userSubs
   * @returns a map of userSub to { projects, lastEvaluatedKey }
   */
  async batchGetProjects(userSubs) {
    const projectRequests = userSubs.map((userSub) => ({userSub, lastEvaluatedKey: this.lastEvaluatedKeyMap[userSub]}));
    const projectsResponses = await this.get('/ddb/projects/batch', {projectRequests}); // returns a map of userSub to { projects, lastEvaluatedKey }

    // update lastEvaluatedKeyMap
    Object.keys(projectsResponses).forEach((userSub) => {
      const response = projectsResponses[userSub];
      this.lastEvaluatedKeyMap[userSub] = response.lastEvaluatedKey;
    });

    // parse relevant data from projectsResponses - now a map of userSub to projects array
    const parsedResponses = {};
    Object.keys(projectsResponses).forEach((userSub) => {
      const response = projectsResponses[userSub];
      parsedResponses[userSub] = this.filterPrivateProjects(userSub, response.projects);
    });

    // filter out archived projects
    Object.keys(parsedResponses).forEach((userSub) => {
      parsedResponses[userSub] = PROJECT.filterArchivedProjects(parsedResponses[userSub]);
    });

    return parsedResponses;
  }

  canLoadMoreProjects(userSub) {
    return this.lastEvaluatedKeyMap[userSub] != null;
  }

  static getProjectColor(projectId) {
    if (!projectId) return 'white';

    const colors = ['playful.blue', 'playful.teal', 'playful.purple', 'playful.red', 'playful.orange'];
    let sum = 0;
    for (let i = 0; i < projectId.length; i++) {
      sum += projectId.charCodeAt(i);
    }
    const index = sum % colors.length;
    return colors[index];
  }

  static getProjectAcronym(name) {
    if (!name) return null;

    // limits to 4 characters. Upper case.
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
      .substring(0, 4);
  }

  static getSortedProjects(projects) {
    if (!projects) return null;

    // Create a copy of the projects array
    return [...projects].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
}

class EPISODE extends API {
  constructor(userSub, projectId, episodeId, updateZEpisodeCallback = null) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId; // timestamp (may be null to start)
    this.updateZEpisodeCallback = updateZEpisodeCallback ?? (() => true);
    this.episode = null;
    this.lastEvaluatedKey = null;
    console.log(`EP API(${projectId}, ${episodeId})`);
  }

  async setEpisodeId(episodeId) {
    if (this.episodeId === episodeId) return;

    this.episodeId = episodeId;
    this.episode = null;
  }

  /**
   * @param {boolean} errorOn404 - default true, if false omits postError to error ddb table
   */
  async getEpisode(errorOn404 = true) {
    return await this.get('/ddb/episode', {projectId: this.projectId, timestamp: this.episodeId, errorOn404});
  }

  async getStages(forceRefresh = false) {
    if (!this.episode || forceRefresh) this.episode = await this.getEpisode();
    return this.episode?.stages;
  }

  async getShownotesURL(forceRefresh = false) {
    if (!this.episode || forceRefresh) this.episode = await this.getEpisode();
    return this.episode?.shownotesURL;
  }

  async getEnhanceId(forceRefresh = false) {
    if (!this.episode || forceRefresh) this.episode = await this.getEpisode();
    return this.episode?.enhanceID;
  }

  async getTranscriptionS3Key(forceRefresh = false) {
    if (!this.episode || forceRefresh) this.episode = await this.getEpisode();
    return this.episode?.transcriptionS3Key;
  }

  async getEpisodeDetails(forceRefresh = false) {
    if (!this.episode || forceRefresh) this.episode = await this.getEpisode();
    return this.episode?.episodeDetails ? JSON.parse(this.episode.episodeDetails) : null;
  }

  async getEpisodes() {
    const data = await this.get('/ddb/episodes', {projectId: this.projectId, lastEvaluatedKey: this.lastEvaluatedKey});
    this.lastEvaluatedKey = data?.lastEvaluatedKey;
    return data;
  }

  async postEpisode(sk, data) {
    if (!sk) {
      throw new Error('postEpisode: episodeId is required');
    }

    return await this.post('/ddb/episode', {
      projectId: this.projectId,
      episodeId: sk,
      ...data,
    });
  }

  /**
   * in addition to updating episode in ddb, this also updates zEpisode in the zustand store
   * @param {*} data
   * @returns isSuccess
   */
  async updateEpisode(data, episodeId = null) {
    const isSuccess = await this.put('/ddb/episode', {
      projectId: this.projectId,
      timestamp: episodeId ?? this.episodeId,
      ...data,
    });
    if (!isSuccess) {
      return false;
    }

    return this.updateZEpisodeCallback(data);
  }

  async deleteEpisode(episodeId) {
    const isSuccess = await this.delete('/ddb/episode', {projectId: this.projectId, timestamp: episodeId});
    return isSuccess;
  }

  static getRunnableStageKeys(stages, episode) {
    const runnableStageKeys = [];

    function getStageKeysExecutedOnCompletion(stageKey) {
      // add stages key if not optional, or is optional and has been marked as required

      if (stages[stageKey].optional === true) {
        const episodeKeys = Object.keys(episode);
        if (
          !stages[stageKey].requiredIfPresent?.every(
            (requiredItem) => episodeKeys.includes(requiredItem) && episode[requiredItem],
          )
        ) {
          return;
        }
      }

      runnableStageKeys.push(stageKey);

      for (const key of stages[stageKey].onCompletion) {
        getStageKeysExecutedOnCompletion(key);
      }
    }

    // starting with stageUpload, recursively get stages to execute on completion
    getStageKeysExecutedOnCompletion('stageUpload');
    return runnableStageKeys;
  }

  static getHasUserSubmittedInputs(zEpisode, formInputs) {
    const inputsCustom = zEpisode?.inputsCustom;
    if (!formInputs || !inputsCustom) return false;

    const formInputIdsRequired = formInputs.filter((input) => input.required).map((input) => input.id);
    const areAllRequiredInputsPresent = formInputIdsRequired.every((id) => id in inputsCustom);

    const isUserInputMissing = !formInputs || !areAllRequiredInputsPresent;
    console.log('hasUserSubmittedInputs', !isUserInputMissing);
    return !isUserInputMissing;
  }

  static getStatus(episode) {
    if (!episode) {
      return {
        allStagesComplete: 0,
        percentageComplete: 0,
        runningStages: [],
        errorStages: [],
      };
    }

    const stages = episode.stages;

    const runnableStageKeys = this.getRunnableStageKeys(stages, episode);
    const totalStages = runnableStageKeys.length;

    const completedStages = [];
    const errorStages = [];
    const runningStages = [];

    for (const stageKey of Object.keys(stages)) {
      const stage = stages[stageKey];
      const prerequisitesSatisfied = stage['prerequisites'].every((req) =>
        this.stageRequirementSatisfied(req, episode),
      );
      const completionRequirementsSatisfied = stage.completionRequirements.every((req) =>
        this.stageRequirementSatisfied(req, episode),
      );

      if (prerequisitesSatisfied && completionRequirementsSatisfied) {
        completedStages.push(stage.config.stageName);
      } else if (prerequisitesSatisfied && episode[stage.config.stageName] !== 'error') {
        runningStages.push(stage.config.stageName);
      }

      if (episode[stage.config.stageName] === 'error') {
        errorStages.push(stage.config.stageName);
      }
    }

    if (episode['stagePipeline'] === 'error') {
      console.log('getStageStatus: stagePipeline is "error"');
      errorStages.push('stagePipeline');
    }

    if (errorStages.length > 0) {
      console.log('getStageStatus: error stages: ', errorStages);
    }

    return {
      allStagesComplete: (completedStages?.length ?? 0) === totalStages,
      percentageComplete: (completedStages?.length ?? 0) / totalStages,
      runningStages: runningStages,
      errorStages: errorStages.length > 0 ? errorStages : undefined,
    };
  }

  static stageRequirementSatisfied(requirement, episodeData) {
    const attributeValue = episodeData[requirement['attribute']];

    if (requirement['value'] === 'exists') {
      if (attributeValue != undefined) {
        return true;
      } else {
        // console.log("req not satisfied", requirement);
      }
    } else if (requirement['value'] === 'notExists') {
      if (attributeValue == undefined) {
        return true;
      } else {
        // console.log("req not satisfied", requirement);
      }
    } else if (requirement['value'] === 'true' || requirement['value'] === 'false') {
      if (String(attributeValue).toLowerCase() === requirement['value']) {
        return true;
      } else {
        // console.log("req not satisfied", requirement);
      }
    } else {
      if (attributeValue === requirement['value']) {
        return true;
      } else {
        // console.log("req not satisfied", requirement);
      }
    }

    return false;
  }

  static async downloadEpisodeDocx(
    fileName,
    templateMeta,
    templates,
    transcript,
    formatSettings,
    speakers,
    gptReqDataMap,
  ) {
    fileName = formatAsFileName(replaceFileExt(fileName, ''));
    if (fileName.length > 0) fileName += '___';
    fileName += 'episode_export';
    fileName += '.docx';

    const sectionDocxElements = GPT.getAllSectionDocxElementsFormatted(templateMeta, templates, gptReqDataMap);
    const transcriptDocxElements = TRANSCRIPT.getTranscriptDocxElements(
      fileName,
      transcript.utterances,
      formatSettings,
      speakers,
    );
    const doc = this.createDocxDocument([...sectionDocxElements, ...transcriptDocxElements]);
    this.docxDownload(doc, fileName);
  }
}

class ENHANCE extends API {
  constructor(userSub) {
    super(userSub);
    console.log(`EAPI constructor()`);

    this.audioPreviewManager = null;
  }

  setAudioPreviewManager(audioPreviewManager) {
    this.audioPreviewManager = audioPreviewManager;
  }

  /** This hits cleanvoice's api */
  async getCleanvoiceEdit(enhanceId) {
    const data = await this.get('/cleanvoice/edit', {id: enhanceId});
    return data;
  }

  /** this hits sur4ide database */
  async getEnhanceJob(enhanceId) {
    const data = await this.get('/ddb/enhanceJob', {pk: enhanceId});
    return data;
  }

  async updateEnhanceJob(enhanceId, data) {
    const isSuccess = await this.put('/ddb/enhanceJob', {pk: enhanceId, ...data});
    return isSuccess;
  }
}

/**
 * @typedef {object} Config
 * @property {string} aspectRatio
 * @property {string} backgroundColor
 * @property {number} defaultWidth
 * @property {string} fontFamily
 * @property {string} fontSize
 * @property {number} fontWeight
 * @property {string} textColor
 * @property {string} textPosition
 * @property {boolean} fill
 * @property {number} fillPosition
 * @property {boolean} showCaption
 *
 * @typedef {object} Clip A Clip is a segment of an episode that is extracted and saved as a separate entity.
 * @property {string} bucketName "remotionlambda-uswest2-tb5ec3y1ia"
 * @property {string} clipId "78f8aa98c7874b8c"
 * @property {Config} config "{\"aspectRatio\": \"16:9\", \"backgroundColor\": \"#252525\", \"defaultWidth\": 1920, \"fontFamily\": \"Roboto\", \"fontSize\": \"20px\", \"fontWeight\": 500, \"textColor\": \"#f5f5f5\", \"textPosition\": \"30%\"}"
 * @property {string} createdAt 1712767258861
 * @property {number} endTime 207.3
 * @property {string} outputFile "https://s3.us-west-2.amazonaws.com/remotionlambda-uswest2-tb5ec3y1ia/renders/bt8as73je1/out.mp4"
 * @property {string} outputUrl "https://s3.us-west-2.amazonaws.com/remotionlambda-uswest2-tb5ec3y1ia/renders/bt8as73je1/out.mp4"
 * @property {string} pk "649d9647c60b7a34c79062e5_1712579857403___1712767131784"
 * @property {string} renderId "bt8as73je1"
 * @property {number} startTime 0.9
 * @property {string} status "complete"
 * @property {number} timeToFinish 58298
 * @property {number} updatedAt 1717085984563
 */
class CLIPS extends API {
  constructor(userSub, projectId, episodeId) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId;
    this.pk = projectId + '___' + episodeId;
    console.log(`CLIPS API(${projectId}, ${episodeId})`);
  }

  /**
   * @returns {Promise<Clip[]>}
   */
  async getClips() {
    return (await this.get('/ddb/clips', {pk: this.pk}))?.clips
      ?.sort?.((a, b) => b.updatedAt - a.updatedAt)
      ?.map?.((clip) => {
        clip.config = clip.config ? JSON.parse(clip.config) : null;
        return clip;
      });
  }

  /**
   * @param {string} clipId
   * @returns {Promise<Clip>}
   */
  async getClip(clipId) {
    return await this.get('/ddb/clip', {pk: this.pk, clipId}).then((clip) => {
      clip.config = clip.config ? JSON.parse(clip.config) : null;
      return clip;
    });
  }

  /**
   * @param {Clip} data
   * @returns
   */
  async postClip(clipId, data) {
    return await this.post('/ddb/clip', {pk: this.pk, clipId, ...data, config: JSON.stringify(data.config)});
  }

  /**
   * @param {string} clipId
   * @param {Clip} data
   * @returns
   */
  async updateClip(clipId, data) {
    return await this.put('/ddb/clip', {pk: this.pk, clipId, ...data, config: JSON.stringify(data.config)});
  }

  /**
   * @param {string} clipId
   * @returns
   */
  async deleteClip(clipId) {
    return await this.delete('/ddb/clip', {pk: this.pk, clipId});
  }

  async getClipConfig() {
    return await this.get('/ddb/clipConfig', {pk: this.pk});
  }
}

class CLIP_CONFIG extends API {
  constructor(userSub) {
    super(userSub);
    console.log(`CLIP_CONFIG API()`);
  }

  /** @param {string} pk: projectId */
  async getClipConfigs(pk) {
    return await this.get('/ddb/clipConfigs', {pk});
  }

  /**
   * @param {string} pk: projectId
   * @param {string} sk: configId
   */
  async getClipConfig(pk, sk) {
    return await this.get('/ddb/clipConfig', {pk, sk})
      .then((clipConfig) => {
        console.log('clipConfig', clipConfig);
        clipConfig.config = clipConfig.config ? JSON.parse(clipConfig.config) : null;
        return clipConfig;
      })
      .catch((e) => {
        console.log('getClipConfig error', e);
      });
  }

  /** @param {string} pk: projectId */
  async updateClipConfig(pk, sk, data) {
    return await this.put('/ddb/clipConfig', {pk, sk, ...data});
  }

  /** @param {string} pk: projectId */
  async postClipConfig(pk, sk, data) {
    return await this.post('/ddb/clipConfig', {pk, sk, ...data});
  }

  /** @param {string} pk: projectId */
  async deleteClipConfig(pk, sk) {
    return await this.delete('/ddb/clipConfig', {pk, sk});
  }
}

class CHAT extends API {
  constructor(userSub, projectId, episodeId) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId;
    this.pk = projectId + '___' + episodeId;
    console.log(`CHAT API(${projectId}, ${episodeId})`);
  }

  /** posts an empty chat history item if chat history 404 */
  async getOrPostChatHistory() {
    const chatHistory = await this.get('/ddb/chatHistory', {
      pk: this.projectId,
    });
    return chatHistory;
  }

  async updateChatHistory(data) {
    const isSuccess = await this.put('/ddb/chatHistory', {
      pk: this.projectId,
      ...data,
    });
    return isSuccess;
  }
}

class EditorAPI extends API {
  constructor(fileUDID) {
    this.fileUDID = fileUDID;
    console.log(`API(${fileUDID})`);
  }

  async getTranscriptFromS3() {
    const objectURL = getPublicS3URL('sur4ide-aws-transcription', 'processed/' + this.fileUDID + '.json');
    return (await axios.get(objectURL)).data;
  }

  // eslint-disable-next-line no-dupe-class-members
  async getTranscriptFromS3(s3URL) {
    try {
      console.log('s3URL', s3URL);
      const response = await axios.get(s3URL);
      return await response.data;
    } catch (e) {
      console.log('error getTranscriptFromS3(): ', e);
      return {};
    }
  }
}

class TRANSCRIPT extends API {
  constructor(userSub, projectId, episodeId, episodeApi = null) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId;

    this.fileName = null;
    this.transcriptionMethod = null;
    this.transcriptUrl = null;

    this.data = null;

    this.episodeApi = episodeApi ?? new EPISODE(userSub, projectId, episodeId);
  }

  setFileName(fileName) {
    console.log('TAPI set fileName ', this.fileName);
    this.fileName = fileName;
  }

  setTranscriptionMethod(method) {
    console.log('TAPI set transcriptionMethod', method);
    this.transcriptionMethod = method;
  }

  setTranscriptUrl(url) {
    console.log('TAPI set transcriptUrl', url);
    this.transcriptUrl = url;
  }

  async getTranscriptionJob(jobId) {
    return this.transcriptionMethod == 'assemblyai' ? this.getAssemblyJob(jobId) : this.getDeepgramJob(jobId);
  }

  async getAssemblyJob(jobId) {
    const job = await this.get('/assembly/job', {
      jobId,
    });
    return job;
  }

  async deleteAssemblyJob(jobId) {
    // deletes the transcript
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_URL + '/assembly/job', {
        params: {jobId: jobId},
      });

      console.log('delete assembly job response', response);
      if (response.status !== 200) throw new Error();
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }

  async getDeepgramJob(jobId) {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + '/deepgram/job', {
        params: {jobId: jobId},
      });

      if (response.status !== 200) throw new Error();
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }

  async getDataFromS3() {
    const data = (await axios.get(this.transcriptUrl)).data;
    this.data = data;
    return this.data;
  }

  async getText() {
    const data = this.data;
    if (!data) await this.getDataFromS3();
    return this.data?.text;
  }

  async getWords() {
    const data = this.data;
    if (!data) await this.getDataFromS3();
    return this.data?.words;
  }

  async getUtterances() {
    const data = this.data;
    if (!data) await this.getDataFromS3();
    return this.data?.utterances;
  }

  async getParagraphs() {
    const data = this.data;
    if (!data) await this.getDataFromS3();
    return this.data?.paragraphs;
  }

  /**
   *
   * @param {*} modifiedTranscriptData
   * @returns true if success
   */
  async putTranscriptData(modifiedTranscriptData) {
    console.log('putTranscriptData');
    try {
      if (!this.transcriptionMethod || !this.projectId || !this.episodeId) throw new Error('missing params');
      const response = await axios.put(process.env.REACT_APP_BASE_URL + '/s3/transcript', {
        objectKey: 'transcript/' + this.transcriptionMethod + '/' + this.projectId + '___' + this.episodeId + '.json',
        transcriptData: modifiedTranscriptData,
      });

      if (response.status !== 200) throw new Error();
      console.log('putTranscriptData success');
      return true;
    } catch (e) {
      console.log('putTranscriptData', e);
      return false;
    }
  }

  async breakCache() {
    const axiosInstance = axios.create({
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    const transcript = (await axiosInstance.get(this.transcriptUrl)).data;
    this.transcript = transcript;
    console.log('breakCacheForTranscript success');
    return transcript;
  }

  /**
   * @returns true if success - success means transcript will rerun
   */
  async regenerateTranscript() {
    console.log('regenerateTranscript');
    const updateSuccess = await this.episodeApi.updateEpisode({resTranscribeJobId: ''});
    if (!updateSuccess) return false;

    const lambdaApi = new LAMBDA(this.projectId, this.episodeId);
    return lambdaApi.invokeDataPipeline(this.projectId, this.episodeId);
  }

  /**
   * @returns true if transcipript is available and loaded, but there are no transcribed words
   */
  async isTranscriptEmptyButAvailable() {
    const words = this.getWords();
    if (words) return words.length === 0;
    return false; // transcript unavailable. Could still be loading
  }

  static getUtteranceLabelText(
    utterance,
    formatSettings,
    speakers,
    shouldBypassStyledLabelPrevention = false,
    shouldAllowStylingWithHtml = false,
  ) {
    if (!utterance) return '';
    const style = formatSettings.style;
    if (style === 'bold_speakers' && !shouldBypassStyledLabelPrevention) return {}; // no label text for bold_speakers style

    const {speaker, start, end, text} = utterance;
    const timestampText = formatSettings.showTimestamps ? DateUtils.formatSecondsHHMMSS(start / 1000) : '';
    let speakerText = formatSettings.showSpeakers
      ? speakers?.[speaker]
        ? speakers?.[speaker]
        : speaker.length === 1 || speaker.length == undefined
          ? 'Speaker ' + speaker
          : speaker
      : '';
    if (style === 'bold_speakers' && shouldAllowStylingWithHtml) speakerText = `<strong>${speakerText}</strong>`;

    if (utterance.label) return {displayText: utterance.label, timestampText, speakerText, usedLabel: true}; // utterance.label is set when a user edits a label

    let displayText = '';
    displayText += timestampText;
    displayText += timestampText && speakerText ? ' - ' + speakerText : speakerText;

    return {displayText, timestampText, speakerText};
  }

  static getUtteranceBodyText(utterance, formatSettings, speakers, shouldAllowStylingWithHtml = false) {
    if (!utterance) return '';
    const style = formatSettings.style;
    const text = utterance.text;

    let result = '';
    if (style === 'bold_speakers') {
      const shouldBypassStyledLabelPrevention = true; // allows the label data to be created in spite of the style, which may not display it
      const {
        displayText: labelDisplayText,
        timestampText,
        speakerText,
      } = TRANSCRIPT.getUtteranceLabelText(
        utterance,
        formatSettings,
        speakers,
        shouldBypassStyledLabelPrevention,
        shouldAllowStylingWithHtml,
      );
      result = labelDisplayText ? `${labelDisplayText}: ${text}` : text;
    } else {
      result = text;
    }

    return result;
  }

  static getCopyContent(transcript, formatSettings, speakers) {
    let content = '';
    for (const utterance of transcript.utterances) {
      if (!utterance || !utterance.text) continue;
      const {displayText: labelText} = TRANSCRIPT.getUtteranceLabelText(utterance, formatSettings, speakers);
      const utteranceText = TRANSCRIPT.getUtteranceBodyText(utterance, formatSettings, speakers);
      content += labelText ? labelText + '\n' + utteranceText + '\n\n' : utteranceText + '\n\n';
    }
    return content;
  }

  static getTranscriptDocxElements(fileName, utterances, formatSettings, speakers) {
    return [
      DocxUtils.Title('Transcript'),
      ...DocxUtils.EmptyLines(),
      ...DocxUtils.Text([fileName], {...DocxUtils.DEFAULT_TEXT_OPTIONS, bold: true}), // make text bold
      ...DocxUtils.EmptyLines(2),
      ...utterances.reduce((result, utterance) => {
        const {displayText: labelText} = TRANSCRIPT.getUtteranceLabelText(utterance, formatSettings, speakers);
        const utteranceText = TRANSCRIPT.getUtteranceBodyText(utterance, formatSettings, speakers, true);
        if (labelText) result.push(...DocxUtils.Text([labelText]));
        result.push(...DocxUtils.TextWithStrongTags([utteranceText]), ...DocxUtils.EmptyLines(2));
        return result;
      }, []),
    ];
  }

  static async downloadDocx(transcript, fileName, formatSettings, speakers) {
    try {
      fileName = replaceFileExt(fileName, '');
      const docxElements = this.getTranscriptDocxElements(fileName, transcript.utterances, formatSettings, speakers);
      const doc = this.createDocxDocument(docxElements);

      fileName = formatAsFileName(replaceFileExt(fileName, '')) + '_transcript' + '.docx';
      await this.docxDownload(doc, fileName);
    } catch (e) {
      console.log(e);
    }
  }

  async searchAndReplace(search, replace, transcript) {
    if (!transcript) {
      console.log('searchAndReplace: No transcript data');
      return;
    }
    replace = replace.trim();
    search = search.trim();
    // Create a deep copy of the transcript object
    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
    const dataCopy = deepCopy(transcript);

    replaceInText(search, replace, dataCopy);
    // replaceInWordArray(search, replace, dataCopy);
    dataCopy?.utterances?.forEach((utterance) => {
      replaceInText(search, replace, utterance);
      // replaceInWordArray(search, replace, utterance);
    });
    dataCopy?.paragraphs?.forEach((paragraph) => {
      replaceInText(search, replace, paragraph);
      // replaceInWordArray(search, replace, paragraph);
    });
    return dataCopy;

    function replaceInText(search, replace, data, caseSensitive = true) {
      if (!data?.text) {
        console.log('replaceInText: No text');
        return;
      }
      // const escapedSearch = escapeRegExp(search);
      const regexFlag = caseSensitive ? 'g' : 'gi'; // use "gi" for case-insensitive search
      const regex = new RegExp(search, regexFlag);
      data.text = data.text.replace(regex, replace);
    }

    // function escapeRegExp(string) {
    //   return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    // }

    // function replaceInWordArray(search, replace, data, caseSensitive = true, ignorePunctuation = false) {
    //   if (!data?.words) {
    //     console.log('replaceInWordArray: No words');
    //     return;
    //   }
    //   if (!caseSensitive) search = search.toLowerCase();

    //   const regexFlag = caseSensitive ? "g" : "gi"; // use "gi" for case-insensitive search
    //   data.words = data.words.map((wordObject) => {
    //     const wordLower = wordObject.text.toLowerCase();
    //     if (wordLower.includes(search)) {
    //       return {
    //         ...wordObject,
    //         text: wordObject.text.replace(new RegExp(search, regexFlag), replace),
    //       };
    //     }
    //     return wordObject;
    //   });
    // }
  }

  /** returns an array of speaker labels that correspond to transcript utterances
   * used to allow a reset of speaker label assignment
   * data used to reset is stored locally, not yet uploaded to server
   */
  static getUtteranceSpeakerMapping(transcript) {
    return transcript['utterances'].map((utterance) => utterance['speaker']);
  }

  /**
   *
   * @param {*} utteranceSpeakerMapping - array of speaker labels that correspond to transcript utterances
   * @returns updated utterances
   */
  setSpeakersInUtterances(transcript, utteranceSpeakerMapping) {
    !utteranceSpeakerMapping && console.log('setSpeakersInUtterances: No speakerMapping');
    if (!utteranceSpeakerMapping) throw new Error('setSpeakersInUtterances: No speakerMapping');
    if (transcript.utterances.length !== utteranceSpeakerMapping.length)
      throw new Error('setSpeakersInUtterances: utterances and speakerMapping length mismatch');

    // Create a deep copy of the transcript object
    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
    const transcriptCopy = deepCopy(transcript);

    transcriptCopy.utterances.forEach((utterance, index) => {
      utterance.speaker = utteranceSpeakerMapping[index];
    });
    return transcriptCopy.utterances;
  }

  replaceSpeakerInUtterances(oldSpeaker, newSpeaker, transcript) {
    console.log('replaceSpeakerInUtterances', oldSpeaker, newSpeaker, 'transcript', !!transcript);
    if (!transcript) return;

    // Create a deep copy of the transcript object
    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
    const transcriptCopy = deepCopy(transcript);

    let replacementCount = 0;
    transcriptCopy.utterances.forEach((utterance) => {
      if (utterance.speaker == oldSpeaker) {
        replacementCount++;
        utterance.speaker = newSpeaker;
      }
    });

    console.log('replaceSpeakerInUtterances replacementCount', replacementCount);
    return transcriptCopy.utterances;
  }

  resetSpeakersInUtterances(transcript) {
    if (!transcript || !transcript.original_utterance_speaker_labels) return;

    // Create a deep copy of the transcript object
    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
    const transcriptCopy = deepCopy(transcript);

    // ensure the number of utterances matches:
    let numUtterancesSaved = 0;
    Object.values(transcriptCopy.original_utterance_speaker_labels).forEach((utterances) => {
      numUtterancesSaved += utterances.length;
    });
    if (numUtterancesSaved !== transcriptCopy.utterances.length) {
      console.log('resetSpeakersInUtterances: utterance count mismatch');
      return null;
    }

    // Iterate over the speaker labels to reset speaker in each utterance
    Object.entries(transcriptCopy.original_utterance_speaker_labels).forEach(([key, value]) => {
      value.forEach((utteranceIdx) => {
        if (transcriptCopy.utterances[utteranceIdx]) {
          transcriptCopy.utterances[utteranceIdx].speaker = key;
        }
      });
    });

    console.log('replaceSpeakerInUtterances');
    return transcriptCopy.utterances;
  }

  async getTranscriptParagraphs() {
    try {
      const paragraphs = (await this.getAltData())?.paragraphs?.paragraphs;
      return paragraphs;
    } catch (e) {
      return [];
    }
  }

  async getTranscriptUtterances() {
    try {
      const utterances = (await this.getResults())?.utterances;
      return utterances;
    } catch (e) {
      return [];
    }
  }

  async getTranscriptWords() {
    try {
      const words = (await this.getResults())?.map((utterance) => utterance?.words)?.flat();
      return words;
    } catch (e) {
      return [];
    }
  }

  /**
   * SAMPLE DATA:
   * {
      "utterances": [
        {
          "speaker": "Jacob",
          "words": [
            {
              "text": "Hey",
              "start": 810,
              "end": 926,
              "confidence": 0.64455,
              "speaker": "A"
            },
            {
              "text": "everyone,",
              "start": 948,
              "end": 1182,
              "confidence": 0.61864,
              "speaker": "A"
            }
          ]
        }
      ]
    }
  */
  static downloadSRT(transcript, fileName, formatSettings) {
    const {includeSpeakers} = formatSettings;
    const maxWordsPerLine = includeSpeakers ? 7 : 9;

    function textsToSRT(utterances) {
      let srt = '';
      let sequence = 1;

      utterances.forEach(({speaker, words}) => {
        // Extract speaker from each utterance
        let line = '';
        let lineWordCount = 0;
        let lineStart = words[0].start; // Initialize line start with the first word's start time

        words.forEach((word, index) => {
          if (lineWordCount < maxWordsPerLine) {
            line += `${word.text} `;
            lineWordCount++;
          }

          // Append speaker's name to the line when max words per line limit is reached or at the last word
          if (lineWordCount === maxWordsPerLine || index === words.length - 1) {
            const lineEnd = word.end; // The end time of the line is the end time of the current word
            srt += `${sequence}\n`;
            srt += `${formatTime(lineStart)} --> ${formatTime(lineEnd)}\n`;
            if (includeSpeakers) {
              line = `${speaker}: ${line.trim()}`; // Include speaker's name before the line text
            } else {
              line = line.trim();
            }
            srt += `${line}\n\n`;

            // Prepare for the next line
            sequence++;
            line = '';
            lineWordCount = 0;
            if (index !== words.length - 1) {
              lineStart = words[index + 1].start;
            }
          }
        });
      });

      return srt;
    }

    function formatTime(milliseconds) {
      let ms = milliseconds % 1000;
      milliseconds = (milliseconds - ms) / 1000;
      let secs = milliseconds % 60;
      milliseconds = (milliseconds - secs) / 60;
      let mins = milliseconds % 60;
      let hrs = (milliseconds - mins) / 60;

      return `${pad(hrs)}:${pad(mins)}:${pad(secs)},${pad(ms, 3)}`;
    }

    function pad(number, length = 2) {
      return ('000' + number).slice(-length);
    }

    const srtContent = textsToSRT(transcript.utterances);
    fileName = 'transcript_' + fileName + '.srt';
    this.htmlDownload(srtContent, fileName);
  }
}

class SUBSCRIPTION extends API {
  constructor(userSub) {
    super(userSub);
  }

  /**
   * @param {string} userSub
   * @param {boolean} errorOn404 - default true, if false omits postError to error ddb table
   */
  async getSubscription(userSub, errorOn404 = true) {
    const subscription = await this.get('/ddb/subscription', {
      userSub,
      errorOn404,
    });
    return subscription;
  }

  /**
   * @param {string} userSub
   * @param {*} numUploads - number of files being uploaded
   * @returns - number of uploads remaining in period. If negative, then user has no more uploads and upload should be restricted.
   */
  async updateSubscriptionUsage(userSub, numUploads, projectId) {
    console.log({numUploads, userSub});
    const usageMetrics = await this.put('/subscription', {
      userSub,
      numUploads,
      projectId,
    });
    return usageMetrics;
  }

  async cancelTieredSubscriptionImmediately(userSub, subscriptionId) {
    const isSuccess = await this.delete(`/stripe/subscriptions/${subscriptionId}`, {userSub});
    return isSuccess;
  }

  // tiers
  static TIER_FREE = 'free';
  static TIER_TEAM = 'team';
  static TIER_I = 'TIER_I';
  static TIER_II = 'TIER_II';
  static TIER_III = 'TIER_III';
  static TIER_IV = 'TIER_IV'; // variable

  // pricing id mappings
  static DEFAULT_PRICE_IDS_MONTHLY_TIER_I = ['price_1P6cIiEvipwwrcCTfOMDYQRV', 'price_1P6cKZEvipwwrcCTeAUWIfEO']; // test // prod
  static DEFAULT_PRICE_IDS_MONTHLY_TIER_II = ['price_1P6cIiEvipwwrcCT3jHTRlN7', 'price_1P6cKZEvipwwrcCT48HXJxua']; // test // prod
  static DEFAULT_PRICE_IDS_MONTHLY_TIER_III = ['price_1P6cIiEvipwwrcCT20KipZ8J', 'price_1P6cKZEvipwwrcCTA3U4RckB']; // test // prod
  static DEFAULT_PRICE_IDS_MONTHLY_TIER_IV = ['price_1P6cIiEvipwwrcCTpx9xBwEQ', 'price_1P6cKZEvipwwrcCT400LwLgP']; // test // prod
  static DEFAULT_PRICE_IDS_YEARLY_TIER_I = ['price_1P6cIiEvipwwrcCTNpjuBzgY', 'price_1P6cKaEvipwwrcCT5JB6r8Mr']; // test // prod
  static DEFAULT_PRICE_IDS_YEARLY_TIER_II = ['price_1P6cIiEvipwwrcCTg6lX0Yxn', 'price_1P6cKZEvipwwrcCTxzYLiJyx']; // test // prod
  static DEFAULT_PRICE_IDS_YEARLY_TIER_III = ['price_1P6cIiEvipwwrcCTJcF3SL5L', 'price_1P6cKZEvipwwrcCTYVAc27g8']; // test // prod
  static DEFAULT_PRICE_IDS_YEARLY_TIER_IV = ['price_1P6cIiEvipwwrcCT3Vs01u5N', 'price_1P6cKZEvipwwrcCTMQccy8vp']; // test // prod

  static DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_I = devEnv
    ? this.DEFAULT_PRICE_IDS_MONTHLY_TIER_I[0]
    : this.DEFAULT_PRICE_IDS_MONTHLY_TIER_I[1];
  static DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_II = devEnv
    ? this.DEFAULT_PRICE_IDS_MONTHLY_TIER_II[0]
    : this.DEFAULT_PRICE_IDS_MONTHLY_TIER_II[1];
  static DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_III = devEnv
    ? this.DEFAULT_PRICE_IDS_MONTHLY_TIER_III[0]
    : this.DEFAULT_PRICE_IDS_MONTHLY_TIER_III[1];
  static DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_IV = devEnv
    ? this.DEFAULT_PRICE_IDS_MONTHLY_TIER_IV[0]
    : this.DEFAULT_PRICE_IDS_MONTHLY_TIER_IV[1];

  static DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_I = devEnv
    ? this.DEFAULT_PRICE_IDS_YEARLY_TIER_I[0]
    : this.DEFAULT_PRICE_IDS_YEARLY_TIER_I[1];
  static DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_II = devEnv
    ? this.DEFAULT_PRICE_IDS_YEARLY_TIER_II[0]
    : this.DEFAULT_PRICE_IDS_YEARLY_TIER_II[1];
  static DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_III = devEnv
    ? this.DEFAULT_PRICE_IDS_YEARLY_TIER_III[0]
    : this.DEFAULT_PRICE_IDS_YEARLY_TIER_III[1];
  static DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_IV = devEnv
    ? this.DEFAULT_PRICE_IDS_YEARLY_TIER_IV[0]
    : this.DEFAULT_PRICE_IDS_YEARLY_TIER_IV[1];

  // deprecated credit mappings
  static FREE_TIER_NUM_UPLOADS = 1;
  static STANDARD_TIER_NUM_UPLOADS = 4;
  static PRO_TIER_NUM_UPLOADS = 8;
  static productIdToMaxCreditsMapping = {
    prod_OYLUbjwV8cxaHW: 12, // "12 a month" // test mode
    prod_NerMZH3DTU3DyE: 12, // "12 a month"
    prod_NkBf5FymEONR7w: 15, // "15 a month"
  };

  // deprecated products
  static FREE_TIER_PROD_ID = 'prod_free';
  static TEAM_TIER_PRODUCT_ID_SPECIAL = 'prod_team'; // special case allows distinction between users with/without active stripe subs to be handled correctly - a user who cancels active stripe sub should be deactivated
  static TEAM_TIER_PROD_IDS = ['prod_team', 'prod_NuIzqNzIh1YarQ']; // prod_NuIzqNzIh1YarQ is "Agency Unlimited $100/m"
  static STANDARD_TIER_STRIPE_PROD_IDS = ['prod_NKoNJ5SXS7DYGs', 'prod_NKnbv0upBhDcsr'];
  static PRO_TIER_STRIPE_PROD_IDS = ['prod_NKqMUFRLrF8yto', 'prod_NKncWcBrIesgvk', 'prod_NV96eBpEzx3QBD']; // prod_NV96eBpEzx3QBD: is "Kim Thompson $24/mo" //
  static PRO_VARIABLE_TIER_STRIPE_PROD_IDS = ['prod_NkBf5FymEONR7w', 'prod_NerMZH3DTU3DyE', 'prod_OYLUbjwV8cxaHW']; // LEGACY PRO VARIABLE // prod_NkBf5FymEONR7w: is "15 a month" // prod_NerMZH3DTU3DyE: is "12 a month" // prod_OYLUbjwV8cxaHW: is "12 a month" test mode
  static PODFLOW_STANDARD_VARIABLE_PROD_IDS = ['prod_PLQaAnqhkZIinn', 'prod_PLjQg2QAquLbcm']; // test_mode // live_mode // legacy
  static PODFLOW_PRO_VARIABLE_PROD_IDS = ['prod_PLQUyCiIblKsvc', 'prod_PLjQryg7o3ukMC']; // test_mode // live_mode // legacy
  static METERED_TIER_STRIPE_PROD_IDS = ['prod_Nh5hxjvMxRNisb', 'prod_NgQANRpLQKRanP'];

  // active products
  static DEFAULT_PROD_IDS = ['prod_PwVJVoyz2eL6nN', 'prod_PwVLrVeiyngFqw']; // test mode // live mode
  static getDefaultProductId() {
    return devEnv ? SUBSCRIPTION.DEFAULT_PROD_IDS[0] : SUBSCRIPTION.DEFAULT_PROD_IDS[1];
  }

  static productDetailsMapping = new Map([
    [SUBSCRIPTION.FREE_TIER_PROD_ID, {maxNumUploads: SUBSCRIPTION.FREE_TIER_NUM_UPLOADS, name: 'Free'}],
    ...SUBSCRIPTION.STANDARD_TIER_STRIPE_PROD_IDS.map((id) => [
      id,
      {maxNumUploads: SUBSCRIPTION.STANDARD_TIER_NUM_UPLOADS, name: 'Standard'},
    ]),
    ...SUBSCRIPTION.PRO_TIER_STRIPE_PROD_IDS.map((id) => [
      id,
      {maxNumUploads: SUBSCRIPTION.PRO_TIER_NUM_UPLOADS, name: 'Pro'},
    ]),
    ...SUBSCRIPTION.PRO_VARIABLE_TIER_STRIPE_PROD_IDS.map((id) => [
      id,
      {maxNumUploads: SUBSCRIPTION.productIdToMaxCreditsMapping[id], name: 'Pro'},
    ]),

    ...SUBSCRIPTION.PODFLOW_STANDARD_VARIABLE_PROD_IDS.map((id) => [id, {maxNumUploads: -1, name: 'Standard'}]), // maxUploads determined by quantity of subscription item
    ...SUBSCRIPTION.PODFLOW_PRO_VARIABLE_PROD_IDS.map((id) => [id, {maxNumUploads: -1, name: 'Standard'}]), // maxUploads determined by quantity of subscription item

    ...SUBSCRIPTION.METERED_TIER_STRIPE_PROD_IDS.map((id) => [
      id,
      {maxNumUploads: Number.MAX_SAFE_INTEGER, name: 'Pay-as-you-go'},
    ]),
    ...SUBSCRIPTION.TEAM_TIER_PROD_IDS.map((id) => [id, {maxNumUploads: Number.MAX_SAFE_INTEGER, name: 'Team'}]),

    ...SUBSCRIPTION.DEFAULT_PROD_IDS.map((id) => [id, {maxNumUploads: -1, name: null}]), // maxUploads determined by quantity of subscription item
  ]);
  static priceDetailsMapping = new Map([
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_MONTHLY_TIER_I.map((id) => [
      id,
      {name: 'Creator', defaultMonthlyCredits: 8, defaultCredits: 8},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_MONTHLY_TIER_II.map((id) => [
      id,
      {name: 'Professional', defaultMonthlyCredits: 20, defaultCredits: 20},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_MONTHLY_TIER_III.map((id) => [
      id,
      {name: 'Small Business', defaultMonthlyCredits: 60, defaultCredits: 60},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_MONTHLY_TIER_IV.map((id) => [
      id,
      {name: 'Custom', defaultMonthlyCredits: -1, defaultCredits: -1},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_YEARLY_TIER_I.map((id) => [
      id,
      {name: 'Creator', defaultMonthlyCredits: 8, defaultCredits: 8 * 12},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_YEARLY_TIER_II.map((id) => [
      id,
      {name: 'Professional', defaultMonthlyCredits: 20, defaultCredits: 20 * 12},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_YEARLY_TIER_III.map((id) => [
      id,
      {name: 'Small Business', defaultMonthlyCredits: 60, defaultCredits: 60 * 12},
    ]),
    ...SUBSCRIPTION.DEFAULT_PRICE_IDS_YEARLY_TIER_IV.map((id) => [
      id,
      {name: 'Custom', defaultMonthlyCredits: -1, defaultCredits: -1},
    ]),
  ]);

  static isSubActive(subStatus) {
    return subStatus === 'active' || subStatus === 'trialing';
  }

  /**
   * @param subscription: from db
   * @returns true if user is paying for a subscription
   */
  static hasActiveSub(subscription) {
    return this.isSubActive(subscription?.sub_status);
  }

  static dateOfCreditRenewal(subscription) {
    if (!subscription) return null; // assuming free tier

    const isAnnual = subscription?.sub_interval === 'year';
    const billingCycleAnchor = subscription?.billing_cycle_anchor;

    let tsRenewal;
    let alreadyRenewed = false; // this is when stripe hasn't yet updated the item, but the period has passed and we manually renew the monthly credits
    if (isAnnual) {
      let actualCurrentPeriodIndex = DateUtils.calculateCurrentPeriodIndex(billingCycleAnchor, Date.now());
      const monthsToAdvance = actualCurrentPeriodIndex + 1;
      const currentPeriodEnd = DateUtils.advanceMonths(billingCycleAnchor, monthsToAdvance);
      tsRenewal = currentPeriodEnd;
      alreadyRenewed = subscription.current_period_index < actualCurrentPeriodIndex;
    } else {
      tsRenewal = subscription?.current_period_end;
      alreadyRenewed = subscription?.current_period_end < Date.now();
    }

    // date of next renewal, and alreadyRenewed is true if the subscription has already renewed but not yet updated on backend
    return {date: tsRenewal, alreadyRenewed};
  }

  static daysTilCreditRenewal(subscription) {
    if (subscription?.cancel_at_period_end && SUBSCRIPTION.isSubActive(subscription?.sub_status)) return null; // their subscription won't renew (it will renew, but it will be free tier)
    if (!subscription?.current_period_end) return null; // assuming free tier

    const futureDate = this.dateOfCreditRenewal(subscription).date;
    const todayDate = Date.now();

    if (this.dateOfCreditRenewal(subscription).alreadyRenewed) return -1; // already renewed (or will renew when user uploads)
    return DateUtils.daysBetweenDates(futureDate, todayDate);
  }

  static timeTilCreditRenewal(subscription) {
    if (subscription?.cancel_at_period_end && SUBSCRIPTION.isSubActive(subscription?.sub_status)) return null; // their subscription won't renew (it will renew, but it will be free tier)
    if (!subscription?.current_period_end) return null; // assuming free tier

    const futureDate = this.dateOfCreditRenewal(subscription).date;
    const todayDate = Date.now();

    return DateUtils.timeBetweenDatesString(futureDate, todayDate);
  }

  static getNextSubPaymentCost(subscription) {
    if (subscription.sub_quantity === undefined || subscription.amount) return null; // not supported
    if (!subscription) return 0; // assuming free tier

    const isAnnual = subscription?.sub_interval === 'year';
  }

  /** defaults to free prod */
  static getProductNamePretty(productId, priceId, sub_status = 'active') {
    if (!productId && !priceId) productId = SUBSCRIPTION.FREE_TIER_PROD_ID;
    if (sub_status !== 'active') productId = SUBSCRIPTION.FREE_TIER_PROD_ID; // if subscription is not active, then it's free tier

    let name = SUBSCRIPTION.productDetailsMapping.get(productId)?.name ?? null;
    if (name) return name; // name is null when name is reliant on priceId

    name = SUBSCRIPTION.priceDetailsMapping.get(priceId)?.name ?? null;
    if (name) return name;

    return 'Unknown';
  }

  static getMaxMonthlyUploadsBySub(subscription) {
    const hasActiveSub = SUBSCRIPTION.hasActiveSub(subscription);
    if (!hasActiveSub) return SUBSCRIPTION.FREE_TIER_NUM_UPLOADS; // assuming free tier

    const product = subscription?.product; // assuming free tier if subscription is null
    const isAnnual = subscription?.sub_interval === 'year';
    const quantity = subscription?.sub_quantity;
    let maxCredits = SUBSCRIPTION.productDetailsMapping.get(product ?? SUBSCRIPTION.FREE_TIER_PROD_ID)?.maxNumUploads;

    // check if maxCredits is determined by subscription quantity
    const maxUploads = this.productDetailsMapping.get(product)?.maxNumUploads;
    if (maxUploads === -1) {
      if (quantity === undefined)
        throw new Error(`getMaxMonthlyUploadsBySub(): quantity must be defined for product ${product}`);
      return isAnnual ? Math.ceil(quantity / 12) : quantity;
    } else if (maxUploads !== undefined) {
      return maxUploads;
    }

    // check for errors
    if (maxCredits == null) throw new Error(`getMaxMonthlyUploadsBySub: product not recognized ${subscription}`);

    return maxCredits;
  }

  static getUploadsRemainingBeforeMetered(subscription) {
    if (!subscription) return SUBSCRIPTION.FREE_TIER_NUM_UPLOADS; // assuming free tier

    try {
      const allowedCreditsBySub = SUBSCRIPTION.getMaxMonthlyUploadsBySub(subscription);

      // check if subscription is expired
      if ((SUBSCRIPTION.daysTilCreditRenewal(subscription) ?? 0) < 0) return allowedCreditsBySub; // then the subscription will be renwed when the user uploads, so return max credits (applies to free tier)

      // calculate remaining credits
      let creditsRemaining = allowedCreditsBySub - (subscription.monthlyUploads ?? 0);
      if (creditsRemaining < 0 && subscription.extraCredit) return subscription.extraCredit; // check to see if user is relying on extra credit
      creditsRemaining += subscription.extraCredit ?? 0; // account for extra credits
      return Math.max(0, creditsRemaining);
    } catch (e) {
      console.log('error getUploadsRemaining', subscription, e);
      return -1;
    }
  }

  /**
   *
   * @param {*} subscription
   * @returns -1 if error occurs, Number.MAX_SAFE_INTEGER
   */
  static getUploadsRemaining(subscription) {
    if (!subscription) return SUBSCRIPTION.FREE_TIER_NUM_UPLOADS; // assuming free tier

    // check for usage based plan
    if (subscription.meteredSubStatus === 'active') return Number.MAX_SAFE_INTEGER;
    return this.getUploadsRemainingBeforeMetered(subscription);
  }
}

class USER extends API {
  constructor() {
    super();
  }

  static getUserSubFromAuthUser(authUser) {
    if (!authUser) return null;
    return authUser.sub.split('|')[1];
  }

  /**
   * @param {string} userSub
   * @param {boolean} errorOn404 - default true, if false omits postError to error ddb table
   * @param {boolean} throwError - default false, if true throws error if 404
   */
  async getUser(userSub, errorOn404 = true, throwError = false) {
    const user = await this.get('/ddb/user', {userSub, errorOn404}, throwError);
    return user;
  }

  async getUserByEmailIndex(email) {
    const user = await this.get('/ddb/userByEmailIndex', {email});
    return user;
  }

  async postUser(authUser, data) {
    const userSub = USER.getUserSubFromAuthUser(authUser);
    const user = await this.post('/user', {
      userSub,
      ...authUser,
      ...data,
    });
    return user;
  }

  async postExistingUser(user) {
    const userItem = await this.post('/ddb/existingUser', {user});
    return userItem;
  }

  async updateUser(userSub, attributes = {}) {
    const isSuccess = await this.put('/ddb/user', {
      userSub,
      ...attributes,
    });
    return isSuccess;
  }

  /** converts a user from auth0 to a user with attributes specific to user table in ddb */
  static authUserToUser(authUser) {
    // Destructure properties from authUser
    const {email, sub, given_name, family_name, nickname, phone_number, picture, created_at, ...extras} = authUser;

    // Extract userSub from sub
    const userSub = sub ? sub.split('|').pop() : null;

    // Basic validation. You can expand on this as needed.
    if (!userSub || !email || !sub) {
      throw new Error('Invalid Auth0 user'); // or return an error response
    }

    // Transform to userItem suitable for DynamoDB
    const userItem = {
      userSub: userSub,
      email: email,
      firstName: given_name,
      lastName: family_name,
      oAuthId: sub,
      nickname: nickname,
      phoneNumber: phone_number,
      picture: picture,
      createdAt: created_at,
      signupConversionRecognized: false,
      ...extras,
    };

    return userItem;
  }

  /**
   * merges auth0 user and google_user, which are the only two currently available
   * - if this ever gets updated (including the attributes), search for other places where this is used by arbitrary id: 32XJ320
   */
  static mergeUsers(primaryUser, secondaryUser) {
    // Start with a copy of the primary user's attributes.
    let mergedUser = {...primaryUser};

    // List of attribute keys that you specifically want to merge
    const attributeKeys = [
      'lastName',
      'locale',
      'signupConversionRecognized',
      'email',
      'picture',
      'name',
      'firstName',
      'email_verified',
      'userSub',
      'updated_at',
      'nickname',
      'oAuthId',
    ];

    // Merge based on primary user's attributes.
    for (const key of attributeKeys) {
      if (key in primaryUser) {
        mergedUser[key] = primaryUser[key];
      } else if (key in secondaryUser) {
        mergedUser[key] = secondaryUser[key];
      }
    }

    // Special case for 'signupConversionRecognized'
    if (
      (primaryUser.hasOwnProperty('signupConversionRecognized') && primaryUser.signupConversionRecognized) ||
      (secondaryUser.hasOwnProperty('signupConversionRecognized') && secondaryUser.signupConversionRecognized)
    ) {
      mergedUser.signupConversionRecognized = true;
    }

    // Special case for 'email_verified'
    if (
      (primaryUser.hasOwnProperty('email_verified') && primaryUser.email_verified) ||
      (secondaryUser.hasOwnProperty('email_verified') && secondaryUser.email_verified)
    ) {
      mergedUser.email_verified = true;
    }

    // Special case for 'configSelectedProject'
    delete mergedUser.configSelectedProject;
    if ('configSelectedProject' in primaryUser) {
      mergedUser.configSelectedProject = primaryUser.configSelectedProject;
    }

    // todo: google's picture should be default - picture

    return mergedUser;
  }

  static getFullName(user) {
    if (!user) return 'Unknown';
    let fullName = user.firstName ? user.firstName + ' ' + user.lastName : user.nickname ? user.nickname : user.email;
    return fullName.trim();
  }
}

class TEAM extends API {
  constructor(userSub, authEmail) {
    super(userSub);
    this.authEmail = authEmail;
  }

  /**
   *
   * @param {*} teamId
   * @returns all team items associated with pk (teamId)
   */
  async getTeam(teamId) {
    const team = await this.get('/ddb/queryTeam', {pk: teamId});
    return TEAM.getSortedTeam(team, this.authEmail);
  }

  /**
   *
   * @param {*} pk - teamId
   * @param {*} sk - user email
   * @returns
   */
  async updateTeamItem(pk, sk) {
    const isSuccess = await this.put('/ddb/team', {
      pk,
      sk,
    });
    return isSuccess;
  }

  async inviteUser(userSub, email, role, invitedByEmail, teamId = null) {
    const team = await this.post('/ddb/inviteUser', {userSub, email, role, invitedByEmail, teamId});
    return team;
  }

  async acceptInvite(teamId, email, userSub) {
    const team = await this.put('/ddb/acceptTeamInvite', {pk: teamId, sk: email, userSub});
    return team;
  }

  async deleteTeamItem(pk, sk, userSub) {
    const isSuccess = await this.delete('/ddb/team', {pk, sk, userSub});
    return isSuccess;
  }

  static getSortedTeam(team, authEmail) {
    return team.sort((a, b) => {
      // Check if a or b is the current user
      if (a.sk === authEmail) return -1;
      if (b.sk === authEmail) return 1;

      // Sort 'admin' role before others
      if (a.role === 'admin' && b.role !== 'admin') return -1;
      if (b.role === 'admin' && a.role !== 'admin') return 1;

      // Keep original order if none of the above conditions are met
      return 0;
    });
  }

  /**
   *
   * @param {*} team
   * @returns list of team members that have accepted their invite
   */
  static getActiveTeamMembers(team) {
    if (!team) return [];
    return team.filter((teammate) => !teammate.invitationPending);
  }

  static getTeamOwner(team) {
    if (!team) return null;
    return team.find((teammate) => teammate.isOwner === true);
  }

  static getAuthTeamMember(team, authEmail) {
    if (!team) return null;
    return team.find((teammate) => teammate.sk === authEmail);
  }

  static hasAdminAbility(user, team) {
    const hasTeam = user.teamId;
    const isTeamAdmin = TEAM.getAuthTeamMember(team, user.email)?.role === 'admin';
    const hasAdminAbility = hasTeam ? isTeamAdmin : true;
    return hasAdminAbility;
  }
}

class LAMBDA extends API {
  constructor(userSub) {
    super(userSub);
  }

  async invokeDataPipeline(projectId, episodeId) {
    const isSuccess = await this.post('/lambda/invokeDataPipeline', {
      projectId,
      episodeId,
    });
    return isSuccess;
  }

  async invokeClipRenderJobFinder(projectId, episodeId) {
    const isSuccess = await this.post('/lambda/invokeClipRenderJobFinder', {
      projectId,
      episodeId,
    });
    return isSuccess;
  }
}

class GPT extends API {
  constructor(userSub, projectId, episodeId) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId;

    this.gptReqId = null;
  }

  setGptReqId = (gptReqId) => {
    this.gptReqId = gptReqId;
  };

  async getDataLayer() {
    return await this.get('/gpt/data-layer');
  }

  /**
   *
   * @param {*} id
   * @returns gpt request item, null
   */
  async getGptRequest(id) {
    const data = await this.get('/ddb/gptRequest', {id});
    return data;
  }

  async updateGptRequest(id, data = {}) {
    const isSuccess = await this.put('/ddb/gptRequest', {
      id,
      ...data,
    });
    return isSuccess;
  }

  /** @returns selected modBlock, null if no modChain or original block is selected */
  static getSelectedModBlock(block) {
    if (!block) return null;

    if (block.modChain) {
      return block.modChain.selectedIdx === 0 ? null : block.modChain.chain[block.modChain.selectedIdx - 1];
    } else {
      return null;
    }
  }

  /** @returns full pk of modBlock. ex. pid_key_learings___mod_1 */
  static getFullModBlockPk(blockPk, modBlockPk) {
    if (!modBlockPk || !blockPk) throw new Error('getFullModBlockPk: blockPk and modBlockPk must be defined');
    return blockPk + '___' + modBlockPk;
  }

  static getBlockPk(blockPk, modBlockPk = null) {
    return modBlockPk ? this.getFullModBlockPk(blockPk, modBlockPk) : blockPk;
  }

  static parseFullModBlockPk(promptPk) {
    // get the modBlockPk if it exists
    let modBlockPk = null;
    if (promptPk.split('___').length === 2) {
      modBlockPk = promptPk.split('___')[1];
      promptPk = promptPk.split('___')[0];
    }
    return {promptPk, modBlockPk};
  }

  static getSectionContent(templates, sectionId, gptReqDataMap) {
    const blocks = templates[sectionId].blocks;

    return Object.keys(blocks)
      .sort((a, b) => blocks[a].index - blocks[b].index)
      .map((elementId) => this.getBlockContent(blocks[elementId], gptReqDataMap))
      .filter((blockContent) => blockContent != null)
      .map((blockContent) => htmlToPlainText(blockContent))
      .join('\n\n');
  }

  /**
   * !! Prompt must be complete before invoking this
   *
   * @param {*} gptRequestId
   * @param {*} promptId
   * @returns gpt request data, null.
   */
  async getGptRequestData(gptRequestId, promptId) {
    const data = await this.get('/ddb/gptRequestData', {pk: gptRequestId, sk: promptId});
    return data;
  }

  /**
   * !! Prompt must be complete before invoking this
   *
   * @param {*} gptRequestId
   * @param {*} promptId
   * @returns gpt request data, null.
   */
  async batchGetGptRequestData(gptRequestId, promptIds) {
    const data = await this.get('/ddb/gptRequestData/batch', {pk: gptRequestId, skList: promptIds});
    return data;
  }

  static getBlockContent(block, gptReqDataMap) {
    if (!block) return null;
    if (!gptReqDataMap) return null;

    if (block.type === 'prompt') {
      // get modBlock if it's selected
      const selectedModBlock = this.getSelectedModBlock(block);

      // check if body is available
      if (!selectedModBlock && block.body) return block.body;
      if (selectedModBlock?.body) return selectedModBlock.body;

      // if body not assigned, then get from gptReqDataMap
      const pk = this.getBlockPk(block.pk, selectedModBlock?.pk);
      if (gptReqDataMap[pk]) return gptReqDataMap[pk];
    }

    // for all other types, return block body
    return block.body;
  }

  static getSectionElementContent(templates, sectionId, gptReqDataMap) {
    const section = templates[sectionId];
    const blocks = section.blocks;

    // Start with the section header
    const docxElements = [{header: section.name}];

    // Sort the block keys by their index and then process each block
    Object.keys(blocks)
      .sort((a, b) => blocks[a].index - blocks[b].index)
      .forEach((blockPk) => {
        const block = blocks[blockPk];
        const blockContent = this.getBlockContent(block, gptReqDataMap);
        if (blockContent == null) return;

        // Add block title if it is a prompt
        if (block.type === 'prompt' && (block.displayName || block.name))
          docxElements.push({body: block.displayName ?? block.name, isHeader: true});

        // Add the block content
        docxElements.push({body: htmlToPlainText(blockContent), isHeader: block.isHeader});
      });

    return docxElements;
  }

  static getSectionDocxElements(templates, sectionId, gptReqDataMap) {
    let elements = GPT.getSectionElementContent(templates, sectionId, gptReqDataMap);

    elements.sort((a, b) => a.index - b.index);

    let docxElements = [];
    for (const element of elements) {
      if (element.header) {
        docxElements.push(...DocxUtils.EmptyLines());
        docxElements.push(DocxUtils.Title(element.header)); // add title content
        docxElements.push(...DocxUtils.EmptyLines());
      }
      if (element.body) {
        let textOptions = undefined;
        if (element.isHeader) textOptions = {...DocxUtils.DEFAULT_TEXT_OPTIONS, bold: true};

        docxElements.push(...DocxUtils.Text([element.body], textOptions)); // add body content
        docxElements.push(...DocxUtils.EmptyLines());
      }
    }

    return docxElements;
  }

  // each section contains an object { title: "title", content: "content" }
  static async generateAndDownloadSectionDocx(templates, sectionId, fileName, gptReqDataMap) {
    const docxElements = GPT.getSectionDocxElements(templates, sectionId, gptReqDataMap);
    fileName =
      formatAsFileName(replaceFileExt(fileName, '')) + '_' + formatAsFileName(templates[sectionId].name, true) ||
      'export';

    // create docx file
    const doc = this.createDocxDocument(docxElements);
    fileName = fileName + '.docx';
    await this.docxDownload(doc, fileName);
  }

  static getAllSectionDocxElementsFormatted(templateMeta, templates, gptReqDataMap) {
    let docxElements = [];
    for (const tm of templateMeta.templates) {
      docxElements.push(...GPT.getSectionDocxElements(templates, tm.sk, gptReqDataMap), ...DocxUtils.EmptyLines(2));
    }
    return docxElements;
  }

  static getFailedRequests(gptReq) {
    console.log('getFailedRequests: gptReq', gptReq);
    if (!gptReq) return [];

    const failedStatusKeys = [];
    GPT.getAllReqStatusKeys(gptReq).forEach((reqKey) => {
      if (gptReq[reqKey] === 'failed') {
        failedStatusKeys.push(reqKey);
      }
    });
    return failedStatusKeys;
  }

  async resetFailedRequests(gptReq) {
    const failedStatusKeys = GPT.getFailedRequests(gptReq);
    console.log('resetFailedRequests: failedStatusKeys', failedStatusKeys);

    const updateData = {};
    failedStatusKeys.forEach((reqKey) => {
      updateData[reqKey] = 'error';
      updateData[reqKey.replace('___status', '___attempts')] = 1;
    });

    if (!Object.keys(updateData).length) {
      console.log('resetFailedRequests: no failed requests to reset');
      return null; // this is on purpose, the client only looks for false
    }

    const isSuccess = this.updateGptRequest(gptReq.id, {...updateData, status: 'retrying'});
    return isSuccess;
  }

  static aproxNumTokens(text) {
    if (!text) return 0;
    return Math.round(text.split(' ').length * (4 / 3));
  }

  static getAllPromptIdsFromTemplates(templates) {
    const promptIdsSet = new Set();
    for (const sectionId of Object.keys(templates)) {
      for (const blockPk of Object.keys(templates[sectionId].blocks)) {
        const block = templates[sectionId].blocks[blockPk];
        if (block.type == 'prompt') {
          if (block.modChain)
            block.modChain.chain.forEach((modBlock) => promptIdsSet.add(GPT.getFullModBlockPk(blockPk, modBlock.pk)));
          promptIdsSet.add(blockPk);
        }
      }
    }
    return Array.from(promptIdsSet);
  }

  static getAllReqStatusKeys(gptReq) {
    return this.getAllKeys(gptReq).filter((reqKey) => reqKey.includes('___status'));
  }

  static getAllKeys(gptReq) {
    return Object.keys(gptReq);
  }

  static getPromptReqKeys(blockPk, gptReq, modBlockPk = null) {
    const reqKeys = [];
    if (!gptReq.reqIdMap || Object.keys(gptReq.reqIdMap).length === 0) return reqKeys; // could indicate that gpt request is empty, which would happen if transcript had no data

    const pk = modBlockPk ? GPT.getFullModBlockPk(blockPk, modBlockPk) : blockPk;
    const reqKey = gptReq.reqIdMap[pk];
    if (!reqKey) return []; // when newly created user prompt is created, the gpt request is not updated with the new prompt id
    reqKeys.push(reqKey);

    if (reqKeys.length === 0) {
      console.error('reqKeys.size === 0');
      return [];
    }

    const sortedReqKeys = reqKeys.sort(
      (a, b) => this.getSubIndexFromSubPromptReqKey(a) - this.getSubIndexFromSubPromptReqKey(b),
    );
    return sortedReqKeys;
  }

  /**
   *
   * @param {*} gptReq
   * @returns req_keys that have status of either success or failed
   */
  static getFinishedGptReqKeys(gptReq) {
    const reqStatusKeys = this.getAllReqStatusKeys(gptReq);
    const finishedReqKeys = reqStatusKeys
      .filter((reqKey) => gptReq[reqKey] === 'success' || gptReq[reqKey] === 'failed')
      .map((reqKey) => reqKey.replace('___status', ''));
    return finishedReqKeys;
  }

  /** finished means prompt status either "success" || "failed" */
  static allPromptsFinished(templates, gptReqPromptsFinished) {
    if (
      !gptReqPromptsFinished ||
      Object.keys(gptReqPromptsFinished).length === 0 ||
      !templates ||
      Object.keys(templates).length === 0
    )
      return false;

    // Get the keys from gptReq
    const allPromptIds = this.getAllPromptIdsFromTemplates(templates);

    // Check if every key in gptReq also exists in gptReqPromptsFinished
    return allPromptIds.every((key) => gptReqPromptsFinished.hasOwnProperty(key));
  }

  static isPromptCompleteByPromptId(promptId, gptReq, modBlockPk = null) {
    const reqKeys = this.getPromptReqKeys(promptId, gptReq, modBlockPk);
    return this.isPromptComplete(reqKeys, gptReq);
  }

  /** pass in an array of keys or single key */
  static isPromptComplete(reqKeys, gptReq) {
    if (typeof reqKeys === 'string') {
      reqKeys = [reqKeys];
    }

    if (reqKeys.length > 1) {
      // if all subprompts are complete, then prompt is complete
      for (const reqKey of reqKeys) {
        const status = this.getStatusByKey(reqKey, gptReq);
        if (status !== 'success') {
          return false;
        }
      }

      return true;
    } else {
      // only one req
      const status = this.getStatusByKey(reqKeys[0], gptReq);
      return status === 'success';
    }
  }

  static getStatusByPromptId(promptId, gptReq, modBlockPk = null) {
    const reqKeys = this.getPromptReqKeys(promptId, gptReq, modBlockPk);
    return this.getStatusByKey(reqKeys[0], gptReq);
  }

  static getStatusByKey(reqKey, gptReq) {
    const statusKey = reqKey + '___status';
    if (!gptReq[statusKey]) {
      return 'waiting';
    } else {
      return gptReq[statusKey];
    }
  }

  static getPromptProgress(promptId, gptReq, includeCurrent = false) {
    const dependencies = this.getDependencies(promptId, gptReq);
    let numCompletions = 0;
    for (const depReqKey of dependencies) {
      if (this.isPromptComplete(depReqKey, gptReq)) numCompletions++;
    }

    const percentageDependenciesComplete = numCompletions / dependencies.length;
    return includeCurrent ? percentageDependenciesComplete * 90 : percentageDependenciesComplete * 100;
  }

  // req_0
  static getSubIndexFromSubPromptReqKey(reqKey) {
    return Number(reqKey.split('_')[1]);
  }
  /** prompt must be complete before invocation */
  async batchGetPromptOutput(pids, gptReq) {
    const promptOutputs = {};

    // get the gpt request data for all prompt ids
    let requestablePks = pids.map((pid) => GPT.parseFullModBlockPk(pid).promptPk); // only using root pk to fetch from server
    requestablePks = Array.from(new Set(requestablePks)); // remove duplicates
    const reqDataList = await this.batchGetGptRequestData(gptReq.id, requestablePks);
    const reqDataMap = reqDataList.reduce((acc, reqData) => {
      acc[reqData.sk] = reqData;
      return acc;
    }, {});

    // for each prompt id, get the output and add it to the promptOutputs object
    for (let pid of pids) {
      const {promptPk, modBlockPk} = GPT.parseFullModBlockPk(pid);

      const reqData = reqDataMap[promptPk];
      const dataElements = GPT.parseDataElementsFromGptRequestData(reqData, promptPk, modBlockPk);
      promptOutputs[pid] = dataElements;
    }

    return promptOutputs;
  }

  /** prompt must be complete before invocation */
  async getPromptOutput(pid, gptReq) {
    console.log('getPromptOutput');
    const {promptPk, modBlockPk} = GPT.parseFullModBlockPk(pid);

    const reqData = await this.getGptRequestData(gptReq.id, promptPk);
    const dataElements = GPT.parseDataElementsFromGptRequestData(reqData, promptPk, modBlockPk);
    return dataElements;
  }

  static parseDataElementsFromGptRequestData(reqData, promptId, modBlockPk = null) {
    // get reqData attribute keys
    let reqKeys = [];
    if (modBlockPk) {
      reqKeys.push(modBlockPk);
    } else {
      reqKeys.push('default');
    }

    function specialFormatting(data) {
      if (promptId.includes('pid_default_topic_filtering') && Array.isArray(data)) {
        // Creating episode outline
        return data.map((topic) => `(${topic.timestamp}) ${topic.topic_name}`).join('\n');
      } else if (promptId.includes('pid_default_topic_summary') && Array.isArray(data)) {
        // Creating detailed outline
        return data
          .map((topic) => `${topic.timestamp} - ${topic.topic_name}\n${topic.expanded_description}`)
          .join('\n\n');
      } else if (promptId.includes('pid_default_topic_highlight') && Array.isArray(data)) {
        // Creating episode outline using highlights
        return data.map((topic) => `(${topic.timestamp}) ${topic.highlight}`).join('\n');
      } else if (promptId.includes('pid_default_quotes') && Array.isArray(data)) {
        // Formatting quotes
        return data
          .map((obj) => {
            let quote = obj.quote.trim('"'); // strip any pre-existing quotes
            let sanitizedQuote = `"${quote}"`;
            let formattedTs = DateUtils.formatSecondsHHMMSS(obj.start / 1000); // Assuming you have a function to format timestamps
            return `(${formattedTs}) ${sanitizedQuote} - ${obj.speaker}`;
          })
          .join('\n\n');
      } else if (promptId.includes('key_tips') && Array.isArray(data)) {
        return data.map((x) => '- ' + x).join('\n');
      } else if (promptId.includes('key_learnings') && Array.isArray(data)) {
        return data.map((x) => '- ' + x).join('\n');
      } else if (promptId.includes('key_questions') && Array.isArray(data)) {
        return data.map((x) => '- ' + x).join('\n');
      } else if (promptId.includes('intriguing_questions') && Array.isArray(data)) {
        return data.map((x) => '- ' + x).join('\n');
      } else if (promptId.includes('episode_title') && Array.isArray(data)) {
        return data.map((x) => '- ' + x).join('\n');
      } else if (promptId.includes('email_subjects') && Array.isArray(data)) {
        return data.map((x) => '- ' + x).join('\n');
      } else if (promptId.includes('hashtags') && Array.isArray(data)) {
        return data.join(' ');
      } else if (promptId.includes('clip_captions') && Array.isArray(data)) {
        return data.map((x, i) => `${i + 1}. ${x}`).join('\n\n');
      } else if (promptId.includes('instagram_captions') && Array.isArray(data)) {
        return data.map((x, i) => `${i + 1}. ${x}`).join('\n\n');
      } else if (promptId.includes('twitter_posts') && Array.isArray(data)) {
        return data.join('\n\n---\n\n');
      } else if (promptId.includes('general_social_post') && Array.isArray(data)) {
        return data.join('\n\n---\n\n');
      } else if (promptId.includes('seo_keywords') && Array.isArray(data)) {
        return data
          .map((keyword, i) => {
            const propertiesList = Object.entries(keyword)
              .map(([key, value]) => {
                return `- ${key}: ${value}`;
              })
              .join('\n');

            return `${i + 1}.\n${propertiesList}`;
          })
          .join('\n\n');
      }

      return data;
    }

    function formatData(data, key) {
      if (!data) return;

      try {
        // check for special formatting
        data = specialFormatting(data);

        // perform default formatting
        if (typeof data === 'string') {
          return data;
        } else if (Array.isArray(data)) {
          if (data.length === 0) throw new Error('No array data to display');
          if (typeof data[0] !== 'string') throw new Error("Array data isn't list of strings");
          return data.join('\n\n');
        } else if (typeof data === 'object' && data !== null) {
          throw new Error('data is of type object. Should have display attribute here');
        } else {
          throw new Error('data of another type');
        }
      } catch (e) {
        // this.postError("parseDataElementsFromGptRequestData formatData", "invlid data type for epExport" + e.message).catch((e) => console.error("Error posting error API", e));
        console.error('gptReqFormatData except for key:', key, e.message);
      }
      return data;
    }

    if (!reqData) reqData = {};
    const dataElements = reqKeys.map((reqKey) => formatData(reqData[reqKey], promptId));
    return dataElements
      .map((data) => (!data ? 'Not enough information provided. Transcript too short?' : data))
      .join('<br>'); // formatting
  }

  static sortSubReqKeys(subReqKeys) {
    if (!subReqKeys || subReqKeys.length === 0) return [];

    return subReqKeys.sort((a, b) => {
      // Extract numbers from strings
      let aNumber = parseInt(a.split('_').pop(), 10);
      let bNumber = parseInt(b.split('_').pop(), 10);

      // Compare numbers
      return aNumber - bNumber;
    });
  }

  static getDependencies(promptId, gptReq) {
    console.log('getDependencies', promptId);
    const dependencies = new Set();

    const reqKeys = this.getPromptReqKeys(promptId, gptReq);

    function addReqDependencies(reqKey) {
      const req = gptReq.reqGraph[reqKey];
      const depGraph = PromptDepGraphs.PROMPT_DEP_GRAPHS[gptReq.templateVersion];
      for (const dep of depGraph[req.id] ?? depGraph[PromptDepGraphs.DEFAULT_USER_DEFINED_PROMPT_DEP_KEY]) {
        const depKey = gptReq.reqIdMap[dep];
        addReqDependencies(depKey);
        dependencies.add(depKey);
      }
    }

    for (const reqKey of reqKeys) {
      dependencies.add(reqKey);
      addReqDependencies(reqKey);
    }

    return Array.from(dependencies);
  }
}

class EPISODE_EXPORT extends API {
  constructor(userSub, projectId, episodeId) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId;
    this.pk = projectId + '___' + episodeId;
    console.log(`EP_EXPORT API(${projectId}, ${episodeId})`);
  }

  /**
   *
   * @param {*} sk - sectionId
   * @returns
   */
  async getEpExport(sk) {
    const epExport = await this.get('/ddb/epExport', {
      pk: this.pk,
      sk,
    });
    return epExport;
  }

  /**
   *
   * @returns get all episode exports associated with pk - in object form with key being sectionId
   */
  async getEpExports() {
    const data = await this.get('/ddb/queryEpExports', {pk: this.pk});
    const epExports = data?.reduce((exports, sectionExport, index) => {
      exports[sectionExport.id] = sectionExport;
      return exports;
    }, {});
    return epExports;
  }

  /**
   * @param {*} data
   * @returns isSuccess
   */
  async updateEpExport(sk, data) {
    const isSuccess = await this.put('/ddb/epExport', {
      pk: this.pk,
      sk,
      ...data,
    });
    return isSuccess;
  }

  async fetchOrCreateExports(templateMeta, templates) {
    // fetch saved exports
    const savedExports = await this.getEpExports();

    const createBlocks = (templateBlocks) => {
      let currentIndex = 0;
      return templateBlocks.reduce((exportBlocks, templateBlock) => {
        const exportBlock = {
          id: templateBlock.pk,
          type: templateBlock.type,
          name: templateBlock.name,
          displayName: templateBlock.displayName,
          body: '',
          index: currentIndex++,
        };

        exportBlocks[templateBlock.pk] = exportBlock;

        return exportBlocks;
      }, {});
    };

    const createSection = (template) => {
      return {
        pk: template.pk,
        sk: template.sk,
        name: template.name,
        description: template.description,
        elements: createBlocks(template.blocks),
      };
    };

    const createExports = (templateMeta, templates, savedExports) => {
      const exports = {};
      templateMeta.templates.forEach((templateRef) => {
        if (savedExports?.[templateRef.sk]) {
          exports[templateRef.sk] = savedExports[templateRef.sk];
        } else {
          const template = templates[templateRef.sk];
          exports[templateRef.sk] = createSection(template);
        }
      });

      return exports;
    };

    return createExports(templateMeta, templates, savedExports);
  }
}

class STAT extends API {
  constructor() {
    super();
  }

  // stat category enum
  static Category = {
    COPY_ACTION: 'copy_action',
    EXPORT_ACTION: 'export_action',
    CUSTOM_PROMPT: 'custom_prompt',
    UKNOWN: 'unknown',
  };

  async postStat(pk, data, userSub = undefined) {
    if (!Object.values(STAT.Category).includes(pk)) {
      if (devEnv) throw new Error('Invalid category provided');
    }

    const response = await this.post('/ddb/stat', {
      pk: pk || STAT.Category.UKNOWN,
      userSub,
      data,
    });
    return response;
  }
}

class PROMPT extends API {
  constructor(userSub, existingPrompt = null) {
    super(userSub);

    this.promptMetadata = existingPrompt?.metadata ?? {
      createdBy: this.userSub,
      createdAt: Date.now().toString(),
    };
  }

  /**
   *
   * @param {*} id
   * @returns prompt item, null
   */
  async getPrompt(id) {
    const data = await this.get('/ddb/prompt', {id});
    return data;
  }

  /**
   *
   * @param {*} prompt
   * @returns prompt object if successful, false if not
   */
  async postPrompt(prompt) {
    const promptItem = await this.post('/ddb/prompt', {prompt});
    return promptItem;
  }

  async getPrompts() {
    const {prompts, lastEvaluatedKey} = await this.get('/ddb/prompts', {lastEvaluatedKey: this.lastEvaluatedKey});
    this.lastEvaluatedKey = lastEvaluatedKey;
    return prompts;
  }
}

class TEMPLATE extends API {
  constructor(userSub) {
    super(userSub);

    this.lastEvaluatedTemplateKey = null;
  }

  /**
   *
   * @param {*} templateId
   * @returns template item, null
   */
  async getTemplate(templateId) {
    const id = templateId;
    const data = await this.get('/ddb/template', {id});
    return data;
  }

  async postTemplate(template) {
    const templateItem = await this.post('/ddb/template', {template});
    return templateItem;
  }

  async getTemplates() {
    const templates = await this.get('/ddb/templates', {lastEvaluatedKey: this.lastEvaluatedPromptKey});
    this.lastEvaluatedPromptKey = templates.lastEvaluatedKey;
    return templates;
  }
}

class TEMPLATE_V2 extends API {
  constructor(userSub, projectId, episodeId, episodeApi, gptApi) {
    super(userSub);
    this.projectId = projectId;
    this.episodeId = episodeId;
    this.pk = this.projectId + '___' + this.episodeId;
    this.episodeApi = episodeApi;
    this.gptApi = null;

    // These must be set before an update call to either templates or templateMeta
    // These are fully maintained by this API class
    this.currentTemplateMeta = null;
    this.currentTemplates = {};

    // This class ensures updates to a template are automatically reflected in the templateMeta
    // It also ensures that updates made to a template aren't made to templates with a pk associated with a different episode than the current episode
    // This ensures that templates for past episodes are maintained

    // When an update is made, and the pk (projectId and episodeId) differs from the currentTemplate pk, then the update is posted instead
    // Also, templateMeta "templates" attirbute is updated when a new template is posted to ensure that templateMeta retains the correct reference

    // TODO, make calls simultaneously, and ensure that both complete or both are rolled back (transaction)
  }

  setGptApi(gptApi) {
    this.gptApi = gptApi;
  }

  setCurrentTemplateMeta(templateMeta) {
    // create mutable copy
    templateMeta = {...templateMeta};
    this.currentTemplateMeta = templateMeta;
  }

  setCurrentTemplates(templates) {
    // create mutable copy
    templates = {...templates};
    this.currentTemplates = templates;
  }

  setCurrentTemplate(template) {
    this.currentTemplates[template.sk] = template;
  }

  /**
   *
   * @param {*} pk (as templateId - found in episode)
   * @returns template item, null
   */
  async getTemplateMeta(pk = null) {
    const templateMeta = await this.get('/ddb/templateMeta', {pk: pk ?? this.pk});
    this.setCurrentTemplateMeta(templateMeta);
    return templateMeta;
  }

  async updateTemplateMeta(attributes) {
    if (!this.currentTemplateMeta) {
      this.postError('updateTemplateMeta', 'currentTemplateMeta is null', {episodeUuid: this.pk});
      return null;
    }

    const updatedTemplateMeta = {...this.currentTemplateMeta, ...attributes, pk: this.pk};
    if (this.pk !== this.currentTemplateMeta.pk) {
      return await this.postTemplateMeta(updatedTemplateMeta);
    }

    const isSuccess = await this.put('/ddb/templateMeta', {pk: this.pk, ...attributes});
    if (!isSuccess) return null;

    this.setCurrentTemplateMeta(updatedTemplateMeta);
    return true;
  }

  async postTemplateMeta(templateMeta) {
    let isSuccess = await this.post('/ddb/templateMeta', templateMeta);
    if (!isSuccess) return null;

    // update gptRequest and episode with new gptTemplateIdV2 - set to new templateMeta.pk

    const updatePromises = [this.episodeApi.updateEpisode({gptTemplateIdV2: templateMeta.pk})];
    // gptApi is added to this class when the gptRequestId becomes available in zEpisode
    // it might not be available when posted initially because sometimes the gptRequest hasn't been created by the time we assign a new templateMeta to the episode. (e.g. when specialTemplateMods yields new template)
    // if it's not available now, then it will be correctly set when the gptRequest is created in gptRequestInit
    if (this.gptApi && this.gptApi.gptReqId)
      updatePromises.push(this.gptApi.updateGptRequest(this.gptApi.gptReqId, {gptTemplateIdV2: templateMeta.pk}));
    const results = await Promise.all(updatePromises.map((p) => p.catch((e) => e)));
    const allSuccess = results.every((result) => result !== false && !(result instanceof Error));
    if (!allSuccess) return null;

    this.setCurrentTemplateMeta(templateMeta);
    return true;
  }

  async getTemplate(pk, sk) {
    const template = await this.get('/ddb/templateV2', {pk: pk ?? this.projectId, sk: sk ?? this.episodeId});
    this.setCurrentTemplate(template);
    return template;
  }

  /**
   * @param {*} keyList - list of objects: {pk, sk}
   * @returns templates items array, null
   */
  async batchGetTemplates(keyList) {
    if (!keyList || keyList.length === 0) return [];
    const templatesList = await this.get('/ddb/templatesV2/batch', {keyList});
    const templates = templatesList.reduce((acc, template) => {
      acc[template.sk] = template;
      return acc;
    }, {});

    this.setCurrentTemplates(templates);
    return templates;
  }

  /** @returns updatedTemplateMeta & updatedTemplate */
  async updateTemplate(sk, attributes) {
    if (!this.currentTemplates || !this.currentTemplates[sk] || !this.currentTemplateMeta) {
      this.postError('updateTemplateMeta', 'missing template or currentTemplateMeta is null', {
        episodeUuid: this.pk,
        templates: this.currentTemplates,
      });
      return null;
    }

    const currentTemplate = this.currentTemplates[sk];
    const updatedTemplate = {...currentTemplate, ...attributes, pk: this.pk};
    if (this.pk !== currentTemplate.pk) return await this.postTemplate(updatedTemplate);

    const isSuccess = await this.put('/ddb/templateV2', {pk: this.pk, sk: currentTemplate.sk, ...attributes});
    if (!isSuccess) return null;

    this.setCurrentTemplate(updatedTemplate);
    return true;
  }

  async deleteTemplate(sk) {
    if (!this.currentTemplates || !this.currentTemplates[sk] || !this.currentTemplateMeta) {
      this.postError('updateTemplateMeta', 'currentTemplateMeta is null', {episodeUuid: this.pk});
      return null;
    }

    // remove template from templateMeta
    const templateMetaUpdateAttributes = {
      templates: this.currentTemplateMeta.templates.filter((tmpl) => tmpl.sk !== sk),
    };
    let isSuccess = await this.updateTemplateMeta(templateMetaUpdateAttributes);
    if (!isSuccess) return null;

    // delete template
    isSuccess = await this.delete('/ddb/templateV2', {pk: this.pk, sk: sk});
    if (!isSuccess) return null;

    // remove template from currentTemplates
    delete this.currentTemplates[sk];

    // remove template from currentTemplateMeta
    this.setCurrentTemplateMeta({...this.currentTemplateMeta, ...templateMetaUpdateAttributes});
    return true;
  }

  /** @returns template & updatedTemplateMeta */
  async postTemplate(template) {
    let templateMetaUpdateAttributes;
    if (this.currentTemplateMeta.templates.some((t) => t.sk === template.sk)) {
      // update templateMeta attributes - ensuring the pk & sk are set to the current episode for the updated template
      templateMetaUpdateAttributes = {
        templates: this.currentTemplateMeta.templates.map((tmpl) =>
          tmpl.sk === template.sk ? {...tmpl, pk: template.pk, sk: template.sk} : tmpl,
        ),
      };
    } else {
      // add template to templateMeta
      templateMetaUpdateAttributes = {
        templates: [...this.currentTemplateMeta.templates, {pk: template.pk, sk: template.sk}],
      };
    }
    const updatedTemplateMeta = {...this.currentTemplateMeta, ...templateMetaUpdateAttributes, pk: this.pk};
    let isSuccess = await this.updateTemplateMeta(templateMetaUpdateAttributes);
    if (!isSuccess) return null;

    isSuccess = await this.post('/ddb/templateV2', template);
    if (!isSuccess) return null;

    this.setCurrentTemplate(template);
    this.setCurrentTemplateMeta(updatedTemplateMeta);
    return true;
  }

  /**
   * @param {*} templates - array of template objects
   * @returns updatedTemplateMeta & updatedTemplate */
  async postTemplates(templates) {
    const isSuccess = await this.post('/ddb/templatesV2/batch', {templates: templates});
    if (!isSuccess) return null;

    this.setCurrentTemplates(templates);
    return true;
  }

  async ogTemplateToTemplateV2(ogJson) {
    if (!ogJson) return null;

    async function buildDependencyGraph(ogJson, userSub) {
      async function calculateDependencies(element) {
        element = {...element};
        const newDependencies = {};
        const ogDependencies = element.dependencies || [];

        // exit recursion
        if (ogDependencies.length == 0) return null;

        // enter recursion
        for (const depPk of ogDependencies) {
          if (!ogPrompts[depPk]) {
            const prompt = await promptApi.getPrompt(depPk);
            ogPrompts[depPk] = prompt;
          }

          const newDeps = await calculateDependencies(ogPrompts[depPk]);
          newDependencies[depPk] = newDeps;
        }
        return newDependencies;
      }

      function consolidateDependencies(dependencies) {
        for (const depKey of Object.keys(dependencies)) {
          const depObj = dependencies[depKey];
          if (depObj == null) {
            dependencies[depKey] = [];
          } else {
            dependencies[depKey] = Object.keys(depObj);
          }
        }

        return dependencies;
      }

      // build dependency graph
      let ogPrompts = {};
      const promptApi = new PROMPT(userSub);
      const visitedPromptElements = new Set();
      let dependencyGraph = {};
      var sections = ogJson.sections || [];
      const allPromptElements = sections
        .reduce((acc, section) => {
          return [...acc, ...section.elements];
        }, [])
        .filter((element) => element.type === 'prompt');
      for (const promptElement of allPromptElements) {
        if (visitedPromptElements.has(promptElement.id)) continue;
        visitedPromptElements.add(promptElement.id);

        let dependencies = await calculateDependencies(promptElement);
        if (!dependencies) dependencies = {};
        dependencyGraph = {...dependencyGraph, ...dependencies};
      }

      dependencyGraph = consolidateDependencies(dependencyGraph);
      return dependencyGraph;
    }

    // Helper function to extract blocks based on the original schema
    function extractBlocks(section) {
      var blocks = {};
      var sectionElements = section.elements || [];
      for (var i = 0; i < sectionElements.length; i++) {
        var elem = sectionElements[i];
        var body = elem.type == 'text' ? '' : null;
        const type = elem.type;
        blocks[elem.id] = {
          pk: elem.id,
          name: elem.name,
          displayName: elem.displayName,
          type: type,
          description: elem.description,
          dependencies: elem.dependencies,
          body,
          index: i,
        };
      }
      return blocks;
    }

    function findCuratedId(sectionId, tabIcons) {
      for (const [key, value] of Object.entries(tabIcons)) {
        if (value.ogSk && value.ogSk === sectionId) {
          return key; // Return the key (e.g., "DETAILS_V1") if a match is found
        }
      }
      return null; // Return null if no match is found
    }

    // Helper function to get the current timestamp in milliseconds
    function currentTimeMillis() {
      return new Date().getTime();
    }

    // Initialize the new schema with the templateMeta information
    const formInputIds = ogJson.formInputIds || [];
    const formInputIdsRequired = ogJson.formInputIdsRequired || [];

    // omit socialsMediaFormInputId
    const socialsMediaFormInputId = 'iid_6e1c921cebd248878bb8618ae9a94a2f';
    const socialsMediaFormInputIdIndex = formInputIds.indexOf(socialsMediaFormInputId);
    if (socialsMediaFormInputIdIndex > -1) formInputIds.splice(socialsMediaFormInputIdIndex, 1);

    var newSchema = {
      templateMeta: {
        pk: ogJson.id,
        created: currentTimeMillis(),
        version: Constants.DEFAULT_TEMPLATE_VERSION,
        templates: [], // populated with references to the 'template' elements.
        formInputIds: formInputIds,
        formInputIdsRequired: formInputIdsRequired,
      },
      templates: {}, //  The key will be the section id, the value is an object with pk and sk attributes
    };

    // build the dependency graph - builds dependency graph for the entire template
    // await buildDependencyGraph(ogJson, this.userSub);

    // Creating template objects based on the sections in the original schema
    var sections = ogJson.sections || [];
    for (var j = 0; j < sections.length; j++) {
      var section = sections[j];
      var blocks = extractBlocks(section);

      // remove socialsMediaFormInputId from blocks, if it exists, and shift indices of remaining blocks
      if (blocks.hasOwnProperty(socialsMediaFormInputId)) {
        const socialsInputBlockIndex = blocks[socialsMediaFormInputId].index;
        delete blocks[socialsMediaFormInputId];

        // shift indices of remaining blocks
        Object.values(blocks).forEach((block) => {
          if (block.index > socialsInputBlockIndex) {
            block.index--;
          }
        });
      }

      // construct final template object
      var templateObject = {
        pk: ogJson.id,
        sk: section.id,
        curatedId: findCuratedId(section.id, TabIcons), // Set curatedId based on the TabIcons enum
        created: currentTimeMillis(),
        name: section.name,
        description: section.description,
        blocks: blocks,
      };
      newSchema.templates[templateObject.sk] = templateObject;

      // Adding references to the 'templateMeta' elements
      newSchema.templateMeta.templates.push({
        pk: templateObject.pk,
        sk: templateObject.sk,
      });
    }

    // add prompt studio template
    const promptStudioTemplate = {
      pk: ogJson.id,
      sk: 'sid_prompt_studio',
      created: currentTimeMillis(),
      description:
        "Prompt Studio is like chatGPT, except it knows all about your episode. If you want more content, you can write a prompt here to generate it. You can also save your favorite prompts so that they're automatically generated in future episodes.",
      curatedId: 'PROMPT_STUDIO_V1',
      isReusable: false,
      name: 'Prompt Studio',
      blocks: {},
    };
    newSchema.templates[promptStudioTemplate.sk] = promptStudioTemplate;
    newSchema.templateMeta.templates.push({
      pk: promptStudioTemplate.pk,
      sk: promptStudioTemplate.sk,
    });

    return newSchema;
  }

  static mergeEpExportsIntoTemplate(epExports, templateMeta, templates, episodeUuid) {
    console.log('epExport', epExports);
    const modifiedTemplateSks = [];
    for (const sectionId of Object.keys(epExports)) {
      if (!templates[sectionId]) {
        // No saved export to modify any template item
        continue;
      }
      modifiedTemplateSks.push(sectionId);

      const epExport = epExports[sectionId];
      const blocks = {};
      Object.keys(epExport.elements).forEach((elementId) => {
        const element = epExport.elements[elementId];
        blocks[element.id] = {
          pk: element.id,
          type: element.type,
          description: element.prompt?.description,
          name: element.prompt?.name,
          displayName: element.prompt?.displayName,
          dependencies: element.prompt?.dependencies || [],
          body: element.body,
          index: element.index,
        };
      });

      templates[sectionId] = {
        ...templates[sectionId],
        pk: episodeUuid,
        description: epExport.description,
        blocks,
      };

      const sk = templates[sectionId].sk;
      templateMeta.templates = templateMeta.templates.map((template) =>
        template.sk === sk ? {...template, sk, pk: episodeUuid} : template,
      );
    }

    const modifiedTemplates = modifiedTemplateSks.map((sk) => templates[sk]);
    return modifiedTemplates;
  }

  async specialTemplateMods(templateMeta, templates) {
    let templateSksUpdated = new Set();
    let updatedTemplateMeta = false;

    // remove all impermanent blocks from prompt studio template
    const promptStudioTemplate = templates.sid_prompt_studio;
    if (!promptStudioTemplate) {
      console.log('specialTemplateMods promptStudioTemplate is null');
      // this.postError('specialTemplateMods', 'promptStudioTemplate is null', {episodeUuid: this.pk});
      // return true; // not necessarily an error
    }
    if (promptStudioTemplate?.pk !== this.pk) {
      // ensure this is only run once. If template has already been modified, don't remove impermanent blocks again
      for (const blockPk of Object.keys(promptStudioTemplate.blocks)) {
        if (promptStudioTemplate.blocks[blockPk].impermanent) {
          delete promptStudioTemplate.blocks[blockPk];
          templateSksUpdated.add(promptStudioTemplate.sk);
        }
      }
    }

    // remove "body" && "modChain" attributes from all blocks of type "prompt" in all templates
    for (const sk of Object.keys(templates)) {
      const template = templates[sk];
      if (template.pk === this.pk) continue; // Ensure this only runs once. If blocks have already been removed, don't remove them again

      for (const blockPk of Object.keys(template.blocks)) {
        const block = template.blocks[blockPk];
        if (block.type === 'prompt' && block.body) {
          delete block.body;
          templateSksUpdated.add(sk);
        }

        if (block.type === 'prompt' && block.modChain) {
          delete block.modChain;
          templateSksUpdated.add(sk);
        }
      }
    }

    if (!templateMeta.formInputIds.includes('iid_has_additional_speakers')) {
      templateMeta.formInputIds.push('iid_has_additional_speakers');
      updatedTemplateMeta = true;
    }

    // switching to lateest template version  // update for new episodes only - when I start creating versioned prompt_processors. For now, everyone needs to use the latest version
    // && !zEpisode.gptInvokedByUser) {
    if (templateMeta.version !== 'V_3_5_24') {
      templateMeta.version = 'V_3_5_24';
      updatedTemplateMeta = true;
    }

    // post any changes
    if (templateSksUpdated.size > 0) console.log('specialTemplateMods templateSksUpdated', templateSksUpdated);
    for (const sk of templateSksUpdated) {
      const isSuccess = await this.updateTemplate(sk, templates[sk]);
      if (!isSuccess) return null;
    }

    if (updatedTemplateMeta) {
      const isSuccess = await this.updateTemplateMeta(templateMeta);
      if (!isSuccess) return null;
    }

    return {templateMeta, templates};
  }

  static getNewBlockId(type = 'block') {
    let prefix = 'bid_';
    if (type === 'prompt') prefix = 'pid_';
    return prefix + getUUID();
  }

  static createUserPromptBlock(index, userPrompt, displayName = null) {
    if (!displayName) {
      displayName = truncateAtNearestSpace(userPrompt, 50);
      if (displayName !== userPrompt) displayName = 'Custom Prompt: ' + displayName + '...'; // if truncated, add prefix and suffix
    }

    return {
      pk: this.getNewBlockId('prompt'),
      type: 'prompt',
      impermanent: true,
      index: index,
      body: '',
      dependencies: ['pid_default_episode_summary', 'pid_default_topic_highlight'],
      name: displayName,
      displayName: displayName,
      description: userPrompt,
      userPrompt: userPrompt,
    };
  }

  static getNewTemplateStarterAttributes(episodeUuid) {
    return {
      pk: episodeUuid,
      sk: 'sid_' + getUUID(),
      created: Date.now(),
    };
  }

  static createUndefinedBlock(index) {
    return {
      pk: this.getNewBlockId(),
      type: 'undefined',
      index: index || 0,
      body: '',
    };
  }

  static createStarterTextBlock(index, startingText) {
    return {
      pk: this.getNewBlockId(),
      type: 'block',
      index: index || 0,
      body: startingText || '',
    };
  }

  static createStarterTemplate(episodeUuid) {
    const starterBlock = this.createStarterTextBlock(
      0,
      'This is a starter template. You can edit this text, or delete it and add your own blocks. Any edits you make will be saved automatically, and this template will automatically be added to your episode.',
    );
    const undefinedBlock = this.createUndefinedBlock(1);

    return {
      ...this.getNewTemplateStarterAttributes(episodeUuid),
      name: null,
      description: null,
      blocks: {[starterBlock.pk]: starterBlock, [undefinedBlock.pk]: undefinedBlock},
    };
  }
}

class PROMPT_MOD extends API {
  constructor(userSub, projectId) {
    super(userSub);
    this.projectId = projectId;
    this.lastEvaluatedKey = null;
  }

  /**
   *
   * @param {} promptMod : attributes: projectId(pk), timestamp(sk), templateSk, promptPk, mod, userSub
   * @returns posted item
   */
  async postPromptMod(data) {
    const promptMod = {pk: this.projectId, sk: Date.now(), ...data};
    console.log('postPromptMod', promptMod);
    const item = await this.post('/ddb/promptMod', promptMod);
    return item;
  }

  async getPromptMods() {
    const data = await this.get('/ddb/promptMods', {pk: this.projectId, lastEvaluatedKey: this.lastEvaluatedKey});
    if (!data) return [];
    this.lastEvaluatedKey = data.lastEvaluatedKey;

    // Step 1: Sort the array by `sk` in descending order
    const sortedHistory = [...data.items].sort((a, b) => b.sk - a.sk);
    // Step 2: Take the top 10 items
    const recent10Items = sortedHistory.slice(0, 10);
    return recent10Items;
  }
}

class INPUT extends API {
  constructor(userSub) {
    super(userSub);
  }

  /**
   *
   * @param {*} id
   * @returns input item, null
   */
  async getInput(id) {
    const data = await this.get('/ddb/promptInput', {id});
    return data;
  }

  /**
   *
   * @param {*} idList - list of ids
   * @returns input items, null
   */
  async batchGetInputs(idList) {
    if (!idList || idList.length === 0) return [];
    const data = await this.get('/ddb/promptInput/batch', {pkList: idList});
    return data;
  }

  /**
   *
   * @param {*} input
   * @returns input object if successful, false if not
   */
  async postInput(input) {
    const inputItem = await this.post('/ddb/promptInput', {
      input,
    });
    return inputItem;
  }

  async getInputs() {
    const {inputs, lastEvaluatedKey} = await this.get('/ddb/promptInputs', {lastEvaluatedKey: this.lastEvaluatedKey});
    this.lastEvaluatedKey = lastEvaluatedKey;
    return inputs;
  }
}

export {
  API,
  PROJECT,
  PROMPT,
  TEMPLATE,
  TEMPLATE_V2,
  PROMPT_MOD,
  INPUT,
  GPT,
  EPISODE_EXPORT,
  EPISODE,
  STAT,
  SUBSCRIPTION,
  USER,
  TEAM,
  TRANSCRIPT,
  LAMBDA,
  ENHANCE,
  CLIPS,
  CLIP_CONFIG,
  CHAT,
  EditorAPI,
};
