import {BasePlugin} from '@uppy/core';

export default class UppyPodflowPlugin extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = opts.id || 'UppyPodflowPlugin';
    this.type = opts.type || 'modifier';
    this.preProcessing = opts.preProcessing;
    this.postProcessing = opts.postProcessing;
  }

  preProcessingInit = (fileIDs) => {
    return this.preProcessing(fileIDs, this.uppy);
  };

  postProcessingInit = (fileIDs) => {
    return this.postProcessing(fileIDs, this.uppy);
  };

  install() {
    this.uppy.addPreProcessor(this.preProcessingInit);
    this.uppy.addPostProcessor(this.postProcessingInit);
  }
}
