import {useAuth0} from '@auth0/auth0-react';
import {ChakraProvider, Flex, Stack, Text} from '@chakra-ui/react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Crisp} from 'crisp-sdk-web';
import {useEffect, useMemo} from 'react';
import {Navigate, Outlet, Route, BrowserRouter as Router, Routes} from 'react-router-dom';

import ButtonSecondary from './components/abstraction_high/ButtonSecondary';
import ZProgressBar from './components/abstraction_high/ZProgressBar';
import {displayToast, getToast} from './components/common/Structural';
import {ZMetaTagHelmet} from './components/common/ZMetaTagHelmet';
import {theme} from './components/common/theme';
import Navbar from './components/navbar/Navbar';
import Auth from './pages/auth/Auth';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import SignupSuccess from './pages/auth/SignupSuccess';
import {InputBuilder} from './pages/builders/InputBuilder';
import {PromptBuilder} from './pages/builders/PromptBuilder';
import {TemplateBuilder} from './pages/builders/TemplateBuilder';
import Editor from './pages/editor/Editor';
import {Episode} from './pages/episode/Episode';
import {EpisodeLayout} from './pages/episode/EpisodeLayout';
import {PrivateTranscript} from './pages/episode/PrivateTranscript';
import Feedback from './pages/misc/Feedback';
import {PageNotFound} from './pages/misc/PageNotFound';
import Privacy from './pages/misc/Privacy';
import Publish from './pages/misc/Publish';
import Terms from './pages/misc/Terms';
import OrderSuccess from './pages/payment/OrderSuccess';
import Pricing from './pages/payment/Pricing';
import {Project} from './pages/project/Project';
import {Projects} from './pages/project/Projects';
import {Invite} from './pages/settings/Invite';
import {Team} from './pages/settings/Team';
import authStore from './stores/auth-store';
import {deepEqual, retryWithExponentialBackoff} from './utils';
import {USER} from './utils/api-v2';
import {APIProvider} from './utils/api-v2-context';
import GTM from './utils/google-tag-manager';
import LocalStorageUtils from './utils/local-storage-utils';
import SessionStorageUtils from './utils/session-storage-utils';

// CRISP
Crisp.configure('26f2c362-a6f7-4642-89c5-70dcc2766ed6');

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePk = process.env.REACT_APP_DEV_ENV
  ? 'pk_test_51MWo1EEvipwwrcCT7B6Vyy6DhG87P78g8c65pKNXwZR6zCBFAohnZSXAKrtOrejcYLhBFzL5WTgCTZLQNB8Ix7gE00NNAQ5v8r'
  : 'pk_live_51MWo1EEvipwwrcCTyinClSK72RD0x6asrOgxS9G7kCjZp0yYZrEnvBd1iQ43fkeboSDFOLUokHRehcfTi9aHdB8L00GNH8URTk';
const stripePromise = loadStripe(stripePk);

function App() {
  // AUTHENTICATION && USER
  const {isLoading, user: authUser, isAuthenticated} = useAuth0();

  const zUser = authStore((state) => state.user);
  const zSetAuthUser = authStore((state) => state.setAuthUser);
  const zSetUser = authStore((state) => state.setUser);
  const zAuthenticating = authStore((state) => state.authenticating);
  const zSetAuthenticating = authStore((state) => state.setAuthenticating);

  const authorized = useMemo(() => zUser && zUser.email_verified, [zUser]);
  const email = useMemo(() => zUser?.email, [zUser]);
  const admin = useMemo(() => authorized && ['jacob@sur4ide.com', 'jacobpzinn@gmail.com'].includes(email), [zUser]);

  const userApi = useMemo(() => new USER(), []);

  const devEnv = process.env.REACT_APP_DEV_ENV;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      zSetUser(null);
      zSetAuthUser(null);
      zSetAuthenticating(false);
      return;
    }

    // check for email already in user table
    zSetAuthUser(authUser);

    // initialize the user - iff the user is not already initialized or authUser signed in changed (not sure how that would happen)
    if (authStore.getState().user?.oAuthId !== authUser.sub.split('|')[1]) {
      initUser(authUser);
    }

    // navigate user to page they were trying to access, if this path is set
    const preAuthPath = SessionStorageUtils.checkPreAuthPath();
    if (preAuthPath) {
      window.location.replace(preAuthPath);
    }
  }, [isAuthenticated, isLoading]);

  async function initUser(authUser) {
    if (!authUser) {
      return;
    }

    try {
      // pretend to be a user
      if (devEnv && false) {
        pretendToBeUser();
        return;
      }

      // continue to real user, or create new user if user not found
      await retryWithExponentialBackoff(async () => {
        // get user from db
        const user = await userApi.getUser(authUser.sub.split('|')[1], false, true);
        if (!user) {
          throw new Error('User not found');
        }

        zSetUser(user);

        // in case auth0 user has changed, update user in db & in zustand
        const mergedUser = USER.mergeUsers(user, USER.authUserToUser(authUser));
        if (!deepEqual(user, mergedUser)) {
          zSetUser(mergedUser);
          await userApi.postExistingUser(mergedUser);
        }
      });
    } catch (error) {
      // create user if user has not been created
      if (error.response && error.response.status === 404) {
        console.log('User not found. Creating user...');
        await createUser(authUser);
      } else {
        console.log('Failed to load user after 3 attempts.');
      }
    } finally {
      zSetAuthenticating(false);
    }
  }

  async function createUser(authUser) {
    try {
      await retryWithExponentialBackoff(async () => {
        // check for an existing user with the same email address. If found, merge them and sign in as the user found in db.
        const usersByEmail = await userApi.getUserByEmailIndex(authUser.email);
        if (usersByEmail.length >= 1) {
          if (usersByEmail.length > 1) {
            userApi.postError('frontend', 'multiple users with same email', {usersByEmail});
          }

          // merge users
          const existingUser = usersByEmail[0];
          const mergedUser = USER.mergeUsers(existingUser, USER.authUserToUser(authUser));
          console.log('an account already exists with this email address, logging in to linked account');
          zSetUser(mergedUser);

          // update existing user in db if merge caused changes
          if (!deepEqual(existingUser, mergedUser)) {
            await userApi.postExistingUser(mergedUser);
            console.log(
              'Merging linked account. The user signed in with auth0 has new information, updated user in db. OG:',
              existingUser,
              'NEW:',
              mergedUser,
            );
          }
        } else {
          // create user
          const user = await userApi.postUser(authUser, {signupConversionRecognized: true});
          console.log('created user:', user);
          if (!user) {
            throw new Error('Failed to create user');
          }

          zSetUser(user);

          // mark signup conversion
          new GTM().conversionSignup(user.userSub, user.email);
        }
      }, 2);
    } catch (error) {
      userApi.postError('frontend', 'failed to create user after several attempts', {authUser, error});
    }
  }

  function pretendToBeUser() {
    if (!devEnv) return;

    const forceSignInUser = {
      userSub: '651daa26213d15e3ae782d22',
      configSelectedProject: '651daa26213d15e3ae782d22_1705697088608',
      email: 'dawn-michelle@marketdominationllc.com',
      email_verified: true,
      name: 'dawn-michelle@marketdominationllc.com',
      nickname: 'dawn-michelle',
      oAuthId: 'auth0|651daa26213d15e3ae782d22',
      picture:
        'https://s.gravatar.com/avatar/72a2681cdd0e5bafb36814061b402d82?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fda.png',
      signupConversionRecognized: true,
      teamId: '2c3b5e9b20c94aec',
      updated_at: '2023-10-04T18:08:38.316Z',
    };
    zSetUser(forceSignInUser);
  }

  const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
  const UPDATE_TOAST_ID = 'update-toast-id';

  const checkForUpdate = async () => {
    // 1. Check that local storage is available.
    if (!LocalStorageUtils.isLocalStorageAvailable()) {
      console.warn('LocalStorage is not available or reliable on this browser.');
      return;
    }

    // 2. Check local storage to see if it's been over 30 minutes since the last session start.
    const lastSessionTimestamp = localStorage.getItem('sessionTimestamp');
    const now = Date.now();

    if (lastSessionTimestamp && now - lastSessionTimestamp <= SESSION_DURATION) return; // Within the same session. Do not check for update.

    // 3. Set the session variable in local storage.
    localStorage.setItem('sessionTimestamp', now.toString());

    // 4. Check the backendApiVersion variable in local storage.
    const storedApiVersion = localStorage.getItem('backendApiVersion');
    const currentApiVersion = await userApi.getApiVersion(); // Assuming this function is async and returns the API version

    if (!currentApiVersion) {
      console.log('Could not get current API version.');
      return;
    }

    // Update the backendApiVersion variable in local storage & display toast if update is available
    if (!storedApiVersion) {
      console.log('No stored version available. Current version: ', currentApiVersion);
      localStorage.setItem('backendApiVersion', currentApiVersion);
    } else if (storedApiVersion !== currentApiVersion) {
      console.log('New version available: ', currentApiVersion);
      displayUpdateToast();
      localStorage.setItem('backendApiVersion', currentApiVersion);
    } else {
      console.log('No new version available. Current version: ', currentApiVersion);
    }
  };

  const displayUpdateToast = () => {
    if (getToast().isActive(UPDATE_TOAST_ID)) {
      return;
    }

    displayToast('New Version Availaible', {
      id: UPDATE_TOAST_ID,
      position: 'bottom-left',
      duration: null, // This makes the toast non-dismissible based on duration
      isClosable: false, // This makes the toast non-dismissible via close button
      render: ({onClose}) => (
        <Stack
          maxW="sm"
          borderWidth="1px"
          borderRadius="md"
          p={3}
          gap={2}
          bg="blue.600"
          color="white"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>There's a new update!</Text>

          <ButtonSecondary
            colorScheme="white"
            onClick={() => {
              window.location.reload();
              onClose(); // Optional: Close the toast after clicking the button
            }}
          >
            Refresh
          </ButtonSecondary>
        </Stack>
      ),
    });
  };

  // Check for updates on load and focus
  useEffect(() => {
    window.addEventListener('load', checkForUpdate);
    window.addEventListener('focus', checkForUpdate);
    return () => {
      window.removeEventListener('load', checkForUpdate);
      window.removeEventListener('focus', checkForUpdate);
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <ChakraProvider theme={theme}>
        <Flex height="100%" width={'100%'}>
          <ZMetaTagHelmet />

          <Router>
            <Navbar />

            {zAuthenticating && <ZProgressBar isIndeterminate={true} />}

            {!zAuthenticating && (
              <Routes>
                <Route
                  path="/"
                  element={
                    <APIProvider>
                      <Outlet />
                    </APIProvider>
                  }
                >
                  {/* AUTH PAGES */}
                  <Route exact path="auth" element={<Auth />} />
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<Signup />} />

                  {/* MVP PAGES */}
                  <Route path="feedback" element={<Feedback />} />
                  <Route path="publish" element={<Publish />} />
                  <Route path="editor" element={<Editor />} />

                  <Route path="" element={authorized ? <Outlet /> : <Auth origin="/" />}>
                    {/* SETTINGS PAGES */}
                    <Route exact path="settings/team" element={<Team />} />
                    <Route exact path="settings/team/:inviteId" element={<Invite />} />

                    {/* CORE PAGES */}
                    <Route exact path="" element={<Navigate to="projects" />} />
                    <Route exact path="projects" element={<Projects />} />
                    <Route path="projects/:projectId" element={<Project />} />
                    <Route path="projects/:projectId/episodes" element={<Navigate to="/projects" />} />
                    <Route path="projects/:projectId/episodes/:episodeId" element={<EpisodeLayout />}>
                      <Route path="" element={<Episode />} />
                      <Route path="tab" element={<Navigate to="home" />} />
                      <Route path="tab/:tabId" element={<Episode />} />
                    </Route>
                    <Route path="projects/:projectId/private_transcript/:episodeId" element={<PrivateTranscript />} />

                    {/* PRICING PAGES */}
                    <Route path="pricing" element={<Pricing />} />

                    {/* ORDER SUCCESS PAGES */}
                    <Route path="order/success/:session_id?" element={<OrderSuccess />} />
                  </Route>

                  {/* ADMIN PAGES */}
                  <Route path="" element={admin ? <Outlet /> : <Auth origin="/" />}>
                    <Route path="prompt_builder" element={<PromptBuilder />} />
                    <Route path="prompt_builder/:promptId" element={<PromptBuilder />} />
                    <Route path="template_builder" element={<TemplateBuilder />} />
                    <Route path="template_builder/:templateId" element={<TemplateBuilder />} />
                    <Route path="input_builder" element={<InputBuilder />} />
                    <Route path="input_builder/:inputId" element={<InputBuilder />} />
                  </Route>

                  {/* MISC PAGES */}
                  <Route path="signup/success" element={<SignupSuccess />} />
                  <Route path="legal/privacy" element={<Privacy />} />
                  <Route path="legal/terms" element={<Terms />} />

                  {/* ADMIN PAGES */}
                  {/* <Route path="admin" element={<Outlet />}>
                    <Route path="" element={<AdminDashboard />} />
                    <Route path="login" element={<AdminLogin />} />
                    <Route path="dashboard" element={<AdminDashboard />} />
                  </Route> */}
                </Route>

                {/* Catch all unmatched routes and redirect to NotFoundPage or another URL */}
                <Route path="404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            )}
          </Router>
        </Flex>
      </ChakraProvider>
    </Elements>
  );
}

export default App;
