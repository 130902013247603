import {
  Button,
  Checkbox,
  CircularProgress,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import {useEffect, useMemo, useRef, useState} from 'react';
import {AiOutlineAudio} from 'react-icons/ai';
import {FiArrowLeft, FiArrowRight, FiUploadCloud} from 'react-icons/fi';
import {MdExplicit, MdTitle} from 'react-icons/md';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';

import FancyButton from '../../components/abstraction_high/FancyButton';
import ProviderButton from '../../components/abstraction_mid/ProviderButton';
import {EPISODE} from '../../utils/api-v2';
import {getPK} from '../../utils/data-transfer';

/**
 * TODO by Friday:
 * Frontend:
 *  - Description, Timestamps, Best Quotes, Episode Links
 *  - more attributes by provider { author, image_url, }
 * Backend:
 *  - If the Enhancment is skipping. where do i get the downloadUrl, or throw error
 *  - Castos: explicit is Podcast Based
 *
 * DONE:
 * Frontend:
 *  - alert or button for the redirect
 *  - Navigate Button
 *  - figure out keywords, description, shownotes
 *  - alert or button for the redirect
 *  - get from shownotes
 *  - Auto populate the SelectProvider feilds on the provider that is selected
 *  - more attributes by provider { author, image_url, }
 * Backend:
 *  - Fixed AudioLibrary onPodcastChange, when a provider is set and a default provider is
 *  - moved to AudioLibraryItem
 *  - more attributes by provider
 *  - Castos: explicit is Podcast Based
 */
const Publish = () => {
  const navigate = useNavigate();
  const toast = useToast();

  // const userSub1 = "dev-buzzsprout";
  // const userSub2 = "dev-castos";
  // const userSub3 = "dev-spreaker";
  // const userSub4 = "dev-podbean";
  // const fileUDID = `${userSub4}___1678090738271`
  // fileUDID=110032364517505309520___1680206071325

  const [searchParams, setSearchParams] = useSearchParams();
  const [fileUDID, setFileUDID] = useState(searchParams.get('fileUDID'));
  const api = useMemo(() => new EPISODE(fileUDID), [fileUDID]);

  const [shownotes, setShownotes] = useState({});
  useEffect(() => {
    async function getGeneratedMaterialsItem(fileUDID) {
      const {userSub, timestamp} = getPK(fileUDID);
      const response = await axios.get(process.env.REACT_APP_BASE_URL + '/ddb/generated-materials', {
        params: {
          userSub: userSub,
          timestamp: timestamp,
        },
      });

      if (response.data.userSub) {
        delete response.data.userSub;
      }
      if (response.data.timestamp) {
        delete response.data.timestamp;
      }

      return response.data;
    }

    getGeneratedMaterialsItem(fileUDID)
      .then((data) => {
        console.log({shownotes: data});
        setShownotes(data);
      })
      .catch((err) => {
        console.log('failed getGeneratedMaterialsItem');
        console.log(err);
      });
  }, [fileUDID]);

  // Implemented in [onProviderChange, onPodcastChange, onPublish]. Disable all forms and buttons and add a CircleProgress somewhere
  const [disableDueToLoading, setDisableDueToLoading] = useState(false);

  const [_token, set_Token] = useState('');
  const getToken = () => _token; // TODO: remove these two lines
  const setToken = (v) => set_Token(v); // TODO: see if i can use _token instead of getToken()

  const [episodeItem, setEpisodeItem] = useState({});
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState();
  const [podcasts, setPodcasts] = useState([]);
  const [podcast, setPodcast] = useState();
  const [providerDataIsLoading, setProviderDataIsLoading] = useState(true);
  const [podcastDataIsLoading, setPodcastDataIsLoading] = useState(true);

  const [episodeTitleInvalid, setEpisodeTitleInvalid] = useState(false); // TODO
  const refTitle = useRef();
  const refDescription = useRef();
  const [season, setSeason] = useState(1);
  const [episode, setEpisode] = useState(1);
  const [episodeType, setEpisodeType] = useState('full');
  const [isExplicit, setIsExplicit] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [isPublished, setIsPublished] = useState(true);
  const refKeywords = useRef();

  // Get fileName
  const [fileName, setFileName] = useState('Loading filename...');

  function navigateToPage(page) {
    const search = createSearchParams(searchParams).toString();
    navigate({pathname: page, search: search});
  }

  // Description, Timestamps, Best Quotes, Episode Links
  const Description = useMemo(
    () => (
      <Textarea
        placeholder="Description"
        isInvalid={false}
        isDisabled={disableDueToLoading}
        height="160px"
        alignSelf="stretch"
        borderColor="gray.200"
        borderStartWidth="1px"
        borderEndWidth="1px"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        defaultValue={
          shownotes.general_summary
            ? `
${shownotes.general_summary || ''}

Timestamps:
${shownotes.timestamps || ''}

Best Quotes:
${shownotes.best_quotes || ''}

Episode Links:
${shownotes.episode_links || ''}
        `.trim()
            : ''
        }
        ref={refDescription}
        // css={{
        //   '&::-webkit-scrollbar': {
        //     display: 'none'
        //   }
        // }}
      />
    ),
    [
      refDescription.current,
      disableDueToLoading,
      shownotes.general_summary,
      shownotes.timestamps,
      shownotes.best_quotes,
      shownotes.episode_links,
    ],
  );

  async function fetchUser({userSub}) {
    console.log(`fetchUser(${{userSub}})`);

    try {
      const res = await axios.get('/api/ddb/userConfig', {params: {userSub}});
      console.log('success fetchUser', {res});
      return res?.data;
    } catch (err) {
      console.log('error fetchUser', {err});

      toast({
        title: "Unable to fetch 'user'",
        status: 'error',
        isClosable: true,
      });

      return {};
    }
  }

  async function fetchEpisodeItem() {
    console.log(`fetchEpisodeItem()`);

    try {
      const res = await api.getEpisode();
      console.log('success fetchEpisodeItem', {res});
      return res;
    } catch (err) {
      console.log('error fetchEpisodeItem', {err});

      toast({
        title: "Unable to fetch 'Audio Item'",
        status: 'error',
        isClosable: true,
      });

      return {};
    }
  }

  async function fetchProviders() {
    console.log(`fetchProviders()`);

    try {
      const res = await axios.get('/api/pub/providers');
      return res?.data?.providers;
    } catch (err) {
      console.log('fetchProviders.catch', err);

      const resp = err?.response?.data;
      const message = resp?.message
        ? resp?.status?.toUpperCase() + ': ' + resp?.message
        : 'Unable to fetch providers list';
      toast({
        title: message,
        status: 'error',
        isClosable: true,
      });
      return [];
    }
  }

  async function fetchPodcasts({userSub, timestamp, token}) {
    console.log(`fetchPodcasts(${{userSub, timestamp, token}})`);

    try {
      const res = await axios.get('/api/pub/podcasts', {params: {userSub, timestamp, token}});
      console.log('fetchPodcasts.then', res);

      return res?.data;
    } catch (err) {
      console.log('fetchPodcasts.catch', err);

      toast({
        title: 'Unable to query Podcasts',
        status: 'error',
        isClosable: true,
      });

      return null;
    }
  }

  // Both need to trigger resetToken call
  async function onProviderChange({key, form}) {
    setProviderDataIsLoading(true);
    setDisableDueToLoading(true);

    console.log({key, form});
    const {userSub, timestamp} = getPK(fileUDID);

    axios
      .post('/api/pub/provider', {key, ...form}, {params: {userSub, timestamp, token: getToken()}})
      .then(() => {
        toast({
          title: 'Successfully Updated Provider!',
          status: 'success',
          isClosable: true,
        });

        // Get the current URL search parameters
        const urlParams = new URLSearchParams(window.location.search);
        const newUrl = `${window.location.pathname}?fileUDID=${urlParams.get('fileUDID')}`;
        window.history.replaceState({}, '', newUrl);

        // Reload the page
        window.location.reload();
      })
      .catch((e) => {
        setProviderDataIsLoading(false);
        setDisableDueToLoading(false);

        toast({
          title: e.response.data.message || 'Unable to update Provider',
          status: 'error',
          isClosable: true,
        });

        throw e;
      });
  }

  async function onPodcastChange({podcastID}) {
    setPodcastDataIsLoading(true);
    setDisableDueToLoading(true);

    const {userSub, timestamp} = getPK(fileUDID);
    console.log({podcastID});

    const providerData = episodeItem.provider;
    providerData['podcastID'] = podcastID;

    axios
      .put('/api/pub/provider', providerData, {params: {userSub, timestamp, token: getToken()}})
      .then((resp) => {
        console.log('success onPodcastChange: ', resp);

        toast({
          title: 'Successfully Updated PodcastID!',
          status: 'success',
          isClosable: true,
        });

        // Get the current URL search parameters
        const urlParams = new URLSearchParams(window.location.search);

        var newUrl = `${window.location.pathname}`;
        if (provider?.key === 'podbean') {
          newUrl += `?${urlParams.toString()}`;
        } else {
          newUrl += `?fileUDID=${urlParams.get('fileUDID')}`;
        }

        window.history.replaceState({}, '', newUrl);

        // Reload the page
        window.location.reload();
      })
      .catch((e) => {
        setPodcastDataIsLoading(false);
        setDisableDueToLoading(false);

        console.log('error onPodcastChange: ', e);
        toast({
          title: e.response.data.message || 'Unable to Update PodcastID',
          status: 'error',
          isClosable: true,
        });

        throw e;
      });
  }

  async function onPublish() {
    setDisableDueToLoading(true);

    if (!podcast) {
      toast({
        title: 'Podcast Not Set',
        status: 'warning',
        isClosable: true,
      });
      setDisableDueToLoading(false);
      return;
    }

    // TODO: set Provider in Audio

    const {userSub, timestamp} = getPK(fileUDID);

    const params = {userSub, timestamp, token: getToken()};
    const form = {
      title: refTitle.current.value,
      description: refDescription.current.value,
      season: parseInt(season),
      episode: parseInt(episode),
      explicit: isExplicit,
      keywords: refKeywords.current.value,

      type: episodeType,
      isPublic: isPublic,
      isPublished: isPublished,

      // "image_url": "https://www.google.com/my_artwork_file.jpeg"
    };

    console.log(params, form);

    return axios
      .post('/api/pub/episodes', form, {params})
      .then((resp) => {
        setDisableDueToLoading(false);

        toast({
          title: 'Successfully published your episode!',
          status: 'success',
          isClosable: true,
        });

        navigateToPage('/shownote-confirmation');
      })
      .catch((e) => {
        setDisableDueToLoading(false);

        console.error(e);

        toast({
          title: e.response.data.message || 'An error occurred while publishing your episode. Please contact support.',
          status: 'error',
          isClosable: true,
        });
      });
  }

  function getParams() {
    const parameterString = window?.location?.hash?.includes('#')
      ? window?.location?.hash?.replace('#', '')
      : window?.location?.search;
    const searchParameters = new URLSearchParams(parameterString || '');
    return Object.fromEntries(searchParameters.entries());
  }

  class OAuthClient {
    DISPLAY_NAME = 'OAuthClient';
    RESPONSE_TYPE = 'token';
    DEV_REDIRECT_URI = 'http://localhost:3000/publish/';
    PROD_REDIRECT_URL = 'https://app.getpodflow.com/';

    constructor({clientID}) {
      this.clientID = clientID;
    }

    get params() {
      return getParams();
    }

    get REDIRECT_URI() {
      return process.env.NODE_ENV == 'development' ? this.DEV_REDIRECT_URI : this.PROD_REDIRECT_URL;
    }

    async useEffect() {
      const {state, access_token, error} = this.params;
      console.log({state, access_token, error});

      if (!state) {
        return this.authorize();
      } else if (!!access_token) {
        return this.onToken();
      } else if (!!error) {
        return this.onError();
      }
    }

    authorize() {
      // TODO: While waiting just Sit there waiting (CircularProgress and Disabled)
      if (
        window.confirm(
          "Authorization required for '" +
            this.DISPLAY_NAME +
            "'. To proceed with this action, you will be redirected to the authorization page. Are you sure you want to leave this page and authorize the action?",
        )
      ) {
        window.location.href = `${this.URL}?client_id=${this.clientID}&response_type=${this.RESPONSE_TYPE}&state=${fileUDID}&scope=${this.SCOPE}&redirect_uri=${this.REDIRECT_URI}`;
      }
    }

    onToken() {
      if (getToken()) {
        return getToken();
      }

      const access_token = this.params.access_token;
      setSearchParams((prev) => ({...prev, fileUDID: this.params.state, access_token, ...this.params}));
      setFileUDID(this.params.fileUDID);

      toast({
        title: 'Successfully Connected!!',
        status: 'success',
        isClosable: true,
      });

      return access_token;
    }
    onError() {
      toast({
        title: this.params.error_description,
        status: 'error',
        isClosable: true,
      });
    }
    refresh() {
      /* Toast "Session Expired...refresh(ing) page" */
    }
  }

  /**
   * It seems the AccessToken is for the pre-selected podcast,
   * so we don't need to allow a change of podcast.
   *
   * Or /podcasts will return a list of length 1 and call it good
   *
   * NOTE: title-min-length: 5
   */
  class PodbeanClient extends OAuthClient {
    DISPLAY_NAME = 'Podbean';
    SCOPE = 'podcast_read+podcast_update+episode_publish+episode_read';
    URL = 'https://api.podbean.com/v1/dialog/oauth';
    DEV_REDIRECT_URI = 'https://localhost.mcmullin.app/publish';
  }

  class SpreakerClient extends OAuthClient {
    DISPLAY_NAME = 'Speaker';
    SCOPE = 'basic';
    URL = 'https://www.spreaker.com/oauth2/authorize';
  }

  useEffect(() => {
    (async () => {
      const _fileUDID = getParams()?.fileUDID || getParams()?.state;
      setFileUDID(_fileUDID);
      if (!_fileUDID) {
        navigate('/');
        return;
      }

      const {userSub, timestamp} = getPK(_fileUDID);

      let _providers = await fetchProviders({userSub, timestamp});
      setProviders(_providers);

      // Get the correct Provider Data, from User or Audio Item
      const user = await fetchUser({userSub});
      const episodeItem = await fetchEpisodeItem({userSub});
      if (!episodeItem?.provider || !episodeItem?.provider?.key) {
        episodeItem.provider = user.provider;
      }

      console.log({audioItem: episodeItem});
      setEpisodeItem(episodeItem);
      setFileName(episodeItem.uploadTitle);

      if (!episodeItem?.provider || !episodeItem?.provider?.key) {
        toast({
          title: 'Provider Info not set',
          status: 'error',
          isClosable: true,
        });
        setProviderDataIsLoading(false);
        setPodcastDataIsLoading(false);
        return;
      }

      const providerClients = {
        podbean: new PodbeanClient({clientID: episodeItem?.provider?.clientID}),
        spreaker: new SpreakerClient({clientID: episodeItem?.provider?.clientID}),
      };
      const providerClient = providerClients?.[episodeItem?.provider?.key];

      let _provider = _providers.find((v) => v?.key === episodeItem?.provider?.key);
      setProvider(_provider);
      setProviderDataIsLoading(false);

      var localToken;
      if (_provider?.type === 'oauth') {
        localToken = await providerClient.useEffect();
      } else if (_provider?.type === 'token') {
        localToken = episodeItem?.provider?.token;
      } else {
        throw new Error("Audio's Provider attribute 'type' is not Supported(oauth, token): " + _provider?.type);
      }
      setToken(localToken);

      console.log(episodeItem, providerClient, _provider, localToken, userSub);

      let _podcasts = await fetchPodcasts({userSub, timestamp, token: localToken});
      setPodcasts(_podcasts || []);
      setPodcastDataIsLoading(false);

      if (!episodeItem?.provider?.podcastID && _podcasts) {
        setPodcast(null);
        toast({
          title: 'Select a Podcast!',
          status: 'warning',
          isClosable: true,
        });
        return;
      }

      // TODO: will remove, just in case i missed any
      if (_podcasts?.length > 0 && !_podcasts?.[0]?.id) {
        toast({
          title: "Selected Provider does not use 'id' as Podcast Identifier. Please Contact Support",
          status: 'warning',
          isClosable: true,
        });
        return;
      }

      let _podcast = _podcasts?.find((v) => v.id == episodeItem?.provider?.podcastID); // Require '==', because I save the ID as a string.
      console.log({_podcast, _podcasts});
      setPodcast(_podcast);
    })();
  }, []);

  return (
    // <Stack justify="center" align="center" spacing="60px" maxWidth="100%" background="#FFFFFF" width="full" overflowY="scroll">
    <Stack justify="flex-start" align="center" padding="40px" spacing="40px" overflowY="scroll" width="full">
      <Stack justify="flex-start" align="center" spacing="10px">
        <Text fontFamily="Inter" lineHeight="1" fontWeight="bold" fontSize="72px" color="black" textAlign="center">
          Publish
        </Text>
        <Text
          fontFamily="Inter"
          lineHeight="1.33"
          fontWeight="regular"
          fontSize="24px"
          color="black"
          width="600px"
          maxWidth="100%"
          textAlign="center"
        >
          Fill in some final details, and we will publish for you!
        </Text>
      </Stack>
      <Stack
        padding="40px"
        borderRadius="8px"
        justify="flex-start"
        align="center"
        spacing="20px"
        width="90vw"
        maxWidth="600px"
        borderColor="gray.200"
        borderStartWidth="1px"
        borderEndWidth="1px"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        background="#FFFFFF"
      >
        <ProviderButton
          audioLibraryItem={episodeItem}
          provider={provider}
          providers={providers}
          podcast={podcast}
          podcasts={podcasts}
          onPodcastChange={onPodcastChange}
          onProviderChange={onProviderChange}
          providerDataIsLoading={providerDataIsLoading}
          podcastDataIsLoading={podcastDataIsLoading}
        />

        <Stack direction="row" justify="flex-start" align="center" spacing="20px" width="100%">
          <Stack
            borderRadius="8px"
            width="50px"
            minWidth="50px"
            height="50px"
            minHeight="50px"
            background="gray.200"
            justify="center"
            align="center"
          >
            <Icon as={AiOutlineAudio} boxSize={6} />
          </Stack>
          <Text
            fontFamily="Inter"
            lineHeight="1.33"
            fontWeight="regular"
            fontSize="20px"
            color="gray.600"
            textOverflow="ellipsis"
            isTruncated
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {fileName}
          </Text>
        </Stack>

        <InputGroup size="lg" alignSelf="stretch">
          <InputLeftAddon background="gray.100">
            <Icon as={MdTitle} />
          </InputLeftAddon>
          <Input
            size="lg"
            multiple={true}
            isInvalid={episodeTitleInvalid}
            isDisabled={disableDueToLoading}
            placeholder="Episode Title *"
            defaultValue={shownotes.general_title || ''}
            ref={refTitle}
          />
        </InputGroup>

        {Description}

        <Stack direction="row" align="center" justify="center" spacing="10px">
          <Stack justify="flex-start" align="flex-start" spacing="5px" width="100%">
            <Text
              fontFamily="Inter"
              lineHeight="1.43"
              fontWeight="regular"
              fontSize="14px"
              color="black"
              width="100%"
              height="23px"
            >
              Season
            </Text>
            <NumberInput
              isDisabled={disableDueToLoading}
              defaultValue={season}
              onChange={(x) => setSeason(x)}
              min={1}
              size="lg"
              keepWithinRange={true}
              allowMouseWheel
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Stack>
          <Stack justify="flex-start" align="flex-start" spacing="5px" width="100%">
            <Text
              fontFamily="Inter"
              lineHeight="1.43"
              fontWeight="regular"
              fontSize="14px"
              color="black"
              width="100%"
              height="23px"
            >
              Episode
            </Text>
            <NumberInput
              isDisabled={disableDueToLoading}
              defaultValue={episode}
              onChange={(x) => setEpisode(x)}
              min={1}
              size="lg"
              keepWithinRange={true}
              allowMouseWheel
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Stack>
        </Stack>

        <Stack direction="row" width="100%" justify="space-between" align="center">
          <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="20px" color="gray.600">
            Episode Type
          </Text>
          <RadioGroup
            isDisabled={disableDueToLoading || !(provider?.acceptsType ?? true)}
            onChange={setEpisodeType}
            value={episodeType}
            colorScheme="teal"
          >
            <Stack spacing={5} direction="row">
              <Radio size="lg" value="full">
                Full
              </Radio>
              <Radio size="lg" value="trailer">
                Trailer
              </Radio>
              <Radio size="lg" value="bonus">
                Bonus
              </Radio>
            </Stack>
          </RadioGroup>
        </Stack>

        <Checkbox
          size="lg"
          colorScheme="teal"
          width="100%"
          isChecked={isExplicit}
          onChange={() => setIsExplicit(!isExplicit)}
          isDisabled={disableDueToLoading || !(provider?.acceptsExplicit ?? true)}
        >
          <Stack direction="row" pl="10px" align="center">
            <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="20px" color="gray.600">
              Explicit Content
            </Text>
            <Icon as={MdExplicit} />
          </Stack>
        </Checkbox>

        <Checkbox
          size="lg"
          colorScheme="teal"
          width="100%"
          isChecked={isPublic}
          onChange={() => setIsPublic(!isPublic)}
          isDisabled={disableDueToLoading || !(provider?.acceptsIsPublic ?? true)}
        >
          <Stack direction="row" pl="10px" align="center">
            <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="20px" color="gray.600">
              {isPublic ? 'Public' : 'Private'}
            </Text>
            <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="14px" color="gray.400">
              {isPublic ? '/ Private' : '/ Public'}
            </Text>
          </Stack>
        </Checkbox>

        <Checkbox
          size="lg"
          colorScheme="teal"
          width="100%"
          isChecked={isPublished}
          onChange={() => setIsPublished(!isPublished)}
          isDisabled={disableDueToLoading || !(provider?.acceptsIsPublished ?? true)}
        >
          <Stack direction="row" pl="10px" align="center">
            <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="20px" color="gray.600">
              {isPublished ? 'Publish' : 'Draft'}
            </Text>
            <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="14px" color="gray.400">
              {isPublished ? '/ Draft' : '/ Publish'}
            </Text>
          </Stack>
        </Checkbox>

        <Textarea
          placeholder="Keywords (comma-separated)"
          isInvalid={false}
          isDisabled={disableDueToLoading || !(provider?.acceptsKeywords ?? true)}
          height="80px"
          alignSelf="stretch"
          borderColor="gray.200"
          borderStartWidth="1px"
          borderEndWidth="1px"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          ref={refKeywords}
        />

        {disableDueToLoading ? (
          <>
            <CircularProgress color="teal" trackColor="" size={10} isIndeterminate />
            <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="12px" color="gray.600">
              may take a minute or two
            </Text>
          </>
        ) : (
          <Stack direction="row" spacing="20px" padding="40px" width="100%" align="center" justify="center">
            <Button
              size="lg"
              variant="ghost"
              onClick={() => navigateToPage('/shownotes')}
              leftIcon={<Icon as={FiArrowLeft} />}
            >
              shownotes
            </Button>
            <FancyButton size="lg" onClick={onPublish} leftIcon={<Icon as={FiUploadCloud} />}>
              Publish
            </FancyButton>
            <Button
              size="lg"
              variant="ghost"
              onClick={() => navigateToPage('/shownotes')}
              rightIcon={<Icon as={FiArrowRight} />}
            >
              skip
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Publish;
