import {Helmet} from 'react-helmet-async';

// Default values based on your homepage or general website info
const defaultTitle = 'Podflow AI';
const defaultDescription =
  'Podflow is like a personal assistant for creators. Podflow uses AI to turn episode recordings into shownotes, social media posts, SEO blog articles, and everything else creators need to grow organically. Create 10x more, 10x faster.';
const defaultImage = 'https://podflow-assets.s3.us-west-2.amazonaws.com/hero/episode_screenshot.jpg'; // "https://podflow-assets.s3.us-west-2.amazonaws.com/logo-combo/logo_icon_v2.png";
const defaultUrl = 'https://www.podflow.ai/';

export const ZMetaTagHelmet = ({title, description, image, url}) => (
  <Helmet prioritizeSeoTags>
    <title>{title || defaultTitle}</title>
    <meta name="description" content={description || defaultDescription} />
    <link rel="canonical" href={url || defaultUrl} />
    <meta property="og:title" content={title || defaultTitle} />
    <meta property="og:description" content={description || defaultDescription} />
    <meta property="og:image" content={image || defaultImage} />
    <meta property="og:url" content={url || defaultUrl} />
    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title || defaultTitle} />
    <meta name="twitter:description" content={description || defaultDescription} />
    <meta name="twitter:image" content={image || defaultImage} />
    <link
      rel="apple-touch-icon"
      sizes="256x256"
      href="https://podflow-assets.s3.us-west-2.amazonaws.com/logoV2/256.png"
    />
  </Helmet>
);
