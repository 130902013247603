import {Box, Flex, Grid, Icon, Input, Stack} from '@chakra-ui/react';
import {useCallback, useState} from 'react';
import {FiSettings} from 'react-icons/fi';

import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import ZCard from '../../components/abstraction_high/ZCard';
import {Body16SemiBold, HeaderLgBold} from '../../components/common/TextStyle';
import episodeStore from '../../stores/episode-store';

const EpHome = ({tabElements}) => {
  // episodeStore
  const zTemplates = episodeStore((state) => state.templates);
  const zSetProgrammaticallySelectTabByKey = episodeStore((state) => state.setProgrammaticallySelectTabByKey);
  const zSetScrollToBlockPk = episodeStore((state) => state.setScrollToBlockPk);

  // local state
  const [stateSearch, setSearch] = useState('');
  const cleanSearch = (search) => (!!search ? search.trim().toLowerCase() : null);

  const getProcessedBlocks = useCallback(
    (sk, sectionName) => {
      if (!zTemplates || !zTemplates[sk]) {
        return null;
      }

      const blocks = Object.keys(zTemplates[sk].blocks)
        .map((blockKey) => zTemplates[sk].blocks[blockKey]) // Convert keys to block objects
        .sort((a, b) => a.index - b.index) // Sorting by index
        .filter((block) => block.type === 'prompt'); // Filtering blocks where type is 'prompt'

      const search = cleanSearch(stateSearch);
      if (!search) {
        return blocks;
      }

      // if it matches a section name, return all blocks
      if (sectionName.toLowerCase().includes(search)) {
        return blocks;
      }

      // else return matching blocks
      return blocks.filter((block) => (block.displayName ?? block.name)?.toLowerCase().includes(search));
    },
    [zTemplates, stateSearch],
  );

  /**
   * Returns a CSS color string with optional opacity.
   *
   * @param {number} index - A deterministic number representing the index of the color.
   * @param {number} [opacity=null] - A decimal between 0 and 1 representing the opacity.
   * @returns {string} - A string of the RGB or RGBA value.
   */
  function getColor(index, opacity = null) {
    const playfulColors = [
      'rgb(83, 149, 247)', // blue
      'rgb(14, 188, 178)', // teal
      'rgb(65, 69, 208)', // purple
      'rgb(244, 215, 5)', // yellow
      'rgb(217, 61, 7)', // red
      'rgb(225, 158, 1)', // orange
    ];

    // Ensure the index is within the bounds of the playfulColors array
    const safeIndex = index % playfulColors.length;
    const baseColor = playfulColors[safeIndex];

    // Check if opacity is provided and is within the valid range
    if (opacity !== null && opacity >= 0 && opacity <= 1) {
      // Replace 'rgb' with 'rgba' and append the opacity value
      return baseColor.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }

    // Return the original RGB color if no valid opacity is provided
    return baseColor;
  }

  const Section = ({title, children, colorIndex, ...props}) => (
    <Stack spacing={2} {...props}>
      <HeaderLgBold fontSize="20px" lineHeight="20px" color="black">
        {title}
      </HeaderLgBold>

      <Grid templateColumns="repeat(auto-fill, minmax(180px, 1fr))" gap={4}>
        <Box
          border={`1px solid ${getColor(colorIndex)}`}
          bg={getColor(colorIndex, 0.33)}
          borderRadius="10px"
          justifyContent="center"
          alignItems="center"
          height={'.5rem'}
          {...props}
        />
      </Grid>

      <Grid templateColumns="repeat(auto-fill, minmax(180px, 1fr))" gap={4}>
        {children}
      </Grid>
    </Stack>
  );

  const ContentItem = ({sk, sectionName, name, blockPk, colorIndex, ...props}) => (
    <Stack
      cursor={'pointer'}
      spacing={0}
      padding={2}
      border={`1px solid ${getColor(colorIndex)}`}
      bg={getColor(colorIndex, 0.1)}
      _hover={{bg: getColor(colorIndex, 0.33)}}
      borderRadius="10px"
      justifyContent="center"
      alignItems="center"
      height={'4.39rem'}
      onClick={() => {
        zSetScrollToBlockPk(blockPk); // blockPk can be null
        zSetProgrammaticallySelectTabByKey(sk);
      }}
      {...props}
    >
      <Body16SemiBold textAlign="center">{name ?? sectionName}</Body16SemiBold>
    </Stack>
  );

  /** Should show all of the blocks in the section if search includes section name, otherwise just blocks matching search within section */
  function shouldHideSection(tabEl) {
    const search = cleanSearch(stateSearch);
    if (!search) {
      return false;
    }

    if (tabEl.title.toLowerCase().includes(search)) {
      return false;
    }

    if (!getProcessedBlocks(tabEl.sk, tabEl.title)?.length) {
      return true;
    }

    return false;
  }

  return (
    <Stack spacing={12} overflowY={'scroll'}>
      <Header stateSearch={stateSearch} setSearch={setSearch} />

      {tabElements?.map(
        (tabEl, i) =>
          !shouldHideSection(tabEl) && (
            <Section key={i} title={tabEl.title} colorIndex={i}>
              {getProcessedBlocks(tabEl.sk, tabEl.title)?.length ? (
                getProcessedBlocks(tabEl.sk, tabEl.title).map((block) => (
                  <ContentItem
                    sk={tabEl.sk}
                    blockPk={block.pk}
                    key={block.pk}
                    sectionName={tabEl.title}
                    name={block.displayName || block.name || 'missing'}
                    colorIndex={i}
                  />
                ))
              ) : (
                <ContentItem
                  sk={tabEl.sk}
                  blockPk={null}
                  sectionName={`Check out the ${tabEl.title}`}
                  gridColumn={'span 2'}
                  colorIndex={i}
                />
              )}
            </Section>
          ),
      )}
    </Stack>
  );
};

const Header = ({stateSearch, setSearch}) => {
  const zSetForceShowEpPrefs = episodeStore((state) => state.setForceShowEpPrefs);
  const zSetTriggerInitTabElements = episodeStore((state) => state.setTriggerInitTabElements);

  return (
    <ZCard variant="outline">
      <Stack>
        <Flex direction="row" gap={4}>
          <Input
            width={'100%'}
            type="text"
            placeholder="Filter..."
            value={stateSearch} // Ensure the input value is controlled by React's state
            onChange={(e) => setSearch(e.target.value)}
          />

          <ButtonSecondary
            tooltipLabel={'Go back to episode settings.'}
            tooltipLabelPlacement={'left'}
            rightIcon={<Icon as={FiSettings} />}
            onClick={() => {
              zSetForceShowEpPrefs(true);
              zSetTriggerInitTabElements();
            }}
          >
            Back
          </ButtonSecondary>
        </Flex>
      </Stack>
    </ZCard>
  );
};

export default EpHome;
