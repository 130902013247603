import {Button, Stack, Text} from '@chakra-ui/react';
import jwt_decode from 'jwt-decode';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import GTM from '../../utils/google-tag-manager';

const SignupSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const refTimer = useRef();
  const timerIterations = useRef(0);
  const iterationsTilRedirect = 1; // this mean 2 seconds

  const [stateTimeTilRedirect, setTimeTilRedirect] = useState(iterationsTilRedirect + 1);
  const [allowManualRedirect, setAllowManualRedirect] = useState(false);

  useEffect(() => {
    // sending conversion event
    if (searchParams.get('state')) {
      // decode encoded user data
      const token = searchParams.get('session_token');
      var {sub, email} = jwt_decode(token);

      console.log('sending signup conversion event');
      new GTM().conversionSignup(sub, email);
    } else {
      console.log('page origin is not auth0. redirecting home. not sending event.');
      navigate('/');
      return;
    }

    refTimer.current = setInterval(() => {
      console.log('stateTimeTilRedirect', stateTimeTilRedirect);
      const iterationsRemaining = iterationsTilRedirect - timerIterations.current;
      setTimeTilRedirect(iterationsRemaining);

      if (timerIterations.current < iterationsTilRedirect) {
        timerIterations.current++;
      } else {
        clearInterval(refTimer.current);
        setTimeout(() => {
          setAllowManualRedirect(true);
        }, 1000);
        continueAuthentication();
      }
    }, 1000);
  }, []);

  function continueAuthentication() {
    // this is the callback required to finish the auth0 authentication flow
    console.log('continueAuthentication');
    const authState = searchParams.get('state');
    window.location.href = `https://podflow.us.auth0.com/continue?state=${encodeURIComponent(authState)}`;
  }

  return (
    <Stack justify="flex-start" align="center" padding="40px" spacing="40px" width="full">
      <Stack justify="flex-start" align="center" spacing={{base: '6', md: '7'}}>
        <Text
          fontFamily="Inter"
          lineHeight="1.33"
          fontWeight="regular"
          fontSize="24px"
          color="black"
          textAlign="center"
        >
          Welcome to podflow!
        </Text>
        <Text fontFamily="Inter" lineHeight="1.33" fontWeight="thin" fontSize="20px" color="gray" textAlign="center">
          We'll redirect you back to the application in {stateTimeTilRedirect} seconds.
        </Text>
        {allowManualRedirect && <Button onClick={continueAuthentication}>Manually Redirect</Button>}
      </Stack>
    </Stack>
  );
};

export default SignupSuccess;
