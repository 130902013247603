import {fitText} from '@remotion/layout-utils';
import {AbsoluteFill, OffthreadVideo, Sequence, staticFile, useCurrentFrame, useVideoConfig} from 'remotion';

import {useDisplayWords} from './utils';

/**
 * https://www.remotion.dev/docs/video-manipulation
 *
 * @typedef {import('../../pages/episode/Clips').Config} ClipConfig
 *
 * @typedef {object} VideoProdProps
 * @property {ClipConfig['url']} url
 * @property {Word[]} words
 * @property {ClipConfig['startTime']} startTime
 * @property {ClipConfig['endTime']} endTime
 * @property {ClipConfig['backgroundColor']} backgroundColor
 * @property {ClipConfig['textColor']} textColor
 * @property {ClipConfig['fontSize']} fontSize - multiplier
 * @property {ClipConfig['fontFamily']} fontFamily
 * @property {ClipConfig['fontWeight']} fontWeight
 * @property {ClipConfig['textPosition']} textPosition
 * @property {ClipConfig['fill']} fill
 * @property {ClipConfig['fillPosition']} fillPosition
 * @property {ClipConfig['showCaption']} showCaption
 *
 * @param {VideoProdProps} props
 *
 * @returns
 */
export const VideoProd = ({url, words = [], startTime, endTime, ...props}) => {
  const frame = useCurrentFrame();
  const {fps, width, height} = useVideoConfig();

  const _startTime = startTime * fps;
  const _endTime = endTime * fps;
  const seconds = ((frame + _startTime) / fps) * 1000;

  const displayWords = useDisplayWords(words);

  // Maybe filter by word not by word grouping so the grouping is faster?
  const displayText = displayWords.find((word, index) => word.start <= seconds && seconds <= word.end)?.text;

  url = url.startsWith('http') || url.startsWith('https') ? url : staticFile(url);

  const fillPosition = 100 - (props.fillPosition ?? 50);

  const {fontSize} = fitText({
    text: displayText,
    withinWidth: width,
    fontFamily: props.fontFamily,
    fontWeight: props.fontWeight,
  });

  return (
    <AbsoluteFill>
      <div style={{position: 'absolute', width, height, backgroundColor: props.backgroundColor}}>
        {url && (
          <>
            <AbsoluteFill>
              <Sequence from={-_startTime} durationInFrames={_endTime}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    objectFit: props.fill ? 'cover' : 'contain',
                    objectPosition: props.fill ? fillPosition + '% 50%' : 'center',
                  }}
                >
                  <OffthreadVideo
                    src={url}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'inherit',
                      objectPosition: props.fill ? 'inherit' : fillPosition + '% 50%',
                    }}
                    onError={() => {
                      console.log('Error loading video');
                    }}
                  />
                </div>
              </Sequence>
            </AbsoluteFill>

            {props.showCaption && (
              <AbsoluteFill>
                <Sequence from={-_startTime} durationInFrames={_endTime}>
                  <div
                    style={{
                      fontSize: fontSize * props.fontSize,
                      fontWeight: props.fontWeight,
                      fontFamily: props.fontFamily,
                      color: props.textColor,
                      textAlign: 'center',
                      margin: 'auto',
                      position: 'relative',
                      top: `calc(100% - ${props.textPosition})`,
                      paddingLeft: 20,
                      paddingRight: 20,
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {displayText}
                  </div>
                </Sequence>
              </AbsoluteFill>
            )}
            {/* May need to add audio support */}
            {/* https://www.remotion.dev/docs/using-audio */}
          </>
        )}
      </div>
    </AbsoluteFill>
  );
};

/**
 * Load the transcript from the URL and render the video
 *
 * url = 'https://podflow-data.s3.amazonaws.com/file_raw/101827665538316186212_1709355576271___1710102665745.mp4',
 * transcript_url = 'https://podflow-data.s3.amazonaws.com/transcript/assemblyai/101827665538316186212_1709355576271___1710102665745.json',
 */
