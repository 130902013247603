import {Divider, Flex, Icon, Stack, Switch} from '@chakra-ui/react';
import {useEffect, useRef} from 'react';
import {BsStars} from 'react-icons/bs';
import {FiMic, FiPause, FiPlay} from 'react-icons/fi';
import {useParams} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import ZCard from '../../components/abstraction_high/ZCard';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {ZCheckboxCardGroup, ZIconButton, ZSwitch} from '../../components/common/ComponentStyle';
import {BodySm, BodySmMuted, BodySmSemiBold} from '../../components/common/TextStyle';
import {H4} from '../../components/common/TextStyle';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import projectStore from '../../stores/project-store';
import {replaceFileExt} from '../../utils';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import {ExportOptionsButton} from './EpSection';

const Enhance = ({pollEpisodeCallback}) => {
  // Get episode and enhanceEnabled from store
  const zEpisode = episodeStore((state) => state.episode);
  const zEnhanceEnabled = episodeStore((state) => state.enhanceEnabled);
  const enhanceEnabled = zEpisode?.userRequestedEnhance || zEpisode?.resEnhancedUrl || zEnhanceEnabled;

  return (
    <Stack spacing={4}>
      <EnhanceHeader pollEpisode={pollEpisodeCallback} />

      <Stack pt={4}>
        <H4>Pro Sound Studio</H4>

        <BodySm>
          Pro Sound Studio makes recordings sound as if they were recorded in a professional studio. Only audio files
          are supported at this time.{' '}
          {zEpisode?.userRequestedEnhance
            ? 'This process usually takes around 15 minutes for an hour of audio, but you can leave the screen at any time and come back later to check on the progress.'
            : 'To begin, click the "Enable Sound Studio" button above, then select the options below that apply to your recording. When you\'re ready, click "Begin Audio Upgrade"'}
        </BodySm>

        <Divider />
      </Stack>

      {enhanceEnabled && <EnhanceBody />}
    </Stack>
  );
};

const EnhanceHeader = ({pollEpisode}) => {
  const {projectId, episodeId} = useParams();

  const {lambdaApi, episodeApi, projectApi} = useEpisodeDependentAPIs();

  const zProject = projectStore((state) => state.project);
  const zEpisode = episodeStore((state) => state.episode);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);
  const zEnhanceJob = episodeStore((state) => state.enhanceJob);
  const zSetEnhanceEnabled = episodeStore((state) => state.setEnhanceEnabled);
  const zEnhanceEnabled = episodeStore((state) => state.enhanceEnabled);
  const zEnhanceDownloading = episodeStore((state) => state.enhanceDownloading);
  const zSetEnhanceDownloading = episodeStore((state) => state.setEnhanceDownloading);

  const getEnhanceEnabled = () => zEpisode?.userRequestedEnhance || zEnhanceEnabled;
  const toggleEnhanceEnabled = () => zSetEnhanceEnabled(!zEnhanceEnabled);

  async function saveConfigAndRequestEnhance() {
    const projectUpdatePromise = projectApi.updateProject(projectId, {configEnhance: zProject.configEnhance});
    const episodeUpdatePromise = episodeApi.updateEpisode({userRequestedEnhance: true});
    const lambdaInvokePromise = lambdaApi.invokeDataPipeline(projectId, episodeId);

    // ensure each response was successful
    const responses = await Promise.all([projectUpdatePromise, episodeUpdatePromise, lambdaInvokePromise]);
    responses.forEach((isSuccess) =>
      !isSuccess ? zSetError('saveConfigAndRequestEnhance', 'Error requesting enhance') : null,
    );

    pollEpisode();
  }

  function downloadEnhancedAudio() {
    console.log('downloadEnhancedAudio', zEnhanceJob?.enhancedUrl);
    if (zEnhanceJob?.enhancedUrl) {
      zSetEnhanceDownloading(true);
      zSetLoading(true);
      fetch(zEnhanceJob.enhancedUrl)
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;

          // rename the file with the isolated filaname
          a.download = replaceFileExt(zEpisode.fileName, '.mp3');

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .finally(() => {
          zSetLoading(false);
          zSetEnhanceDownloading(false);
        });
    }
  }

  return (
    <ZCard variant="outline" justifyContent={'center'} height={'4.6rem'}>
      <Stack>
        <Stack direction="row" justify="space-between">
          <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
            <BodySm>Enable Sound Studio</BodySm>
            <Switch
              size={'sm'}
              disabled={zEpisode.userRequestedEnhance}
              onChange={toggleEnhanceEnabled}
              isChecked={getEnhanceEnabled()}
            />
          </Stack>

          <Stack flexGrow={1}></Stack>

          {zEnhanceDownloading ? (
            <ButtonSecondary isLoading loadingText="Downloading" />
          ) : zEnhanceJob?.enhancedUrl ? (
            <ExportOptionsButton
              exportOptions={[
                {
                  cta: 'Download enhanced audio (.mp3)',
                  icon: <Icon as={FiMic} />,
                  onClick: downloadEnhancedAudio,
                },
              ]}
              triggerIsPrimaryButton={true}
            />
          ) : zEpisode?.userRequestedEnhance ? (
            <ButtonSecondary isLoading loadingText="Processing" />
          ) : (
            zEpisode &&
            getEnhanceEnabled() && (
              <ButtonPrimary
                wrap={true}
                leftIcon={<Icon color={'white'} as={BsStars} />}
                onClick={saveConfigAndRequestEnhance}
              >
                Begin Audio Upgrade
              </ButtonPrimary>
            )
          )}
        </Stack>
      </Stack>
    </ZCard>
  );
};

const EnhanceBody = () => {
  const {enhanceApi} = useEpisodeDependentAPIs();

  const zEnhanceJob = episodeStore((state) => state.enhanceJob);
  const zEpisode = episodeStore((state) => state.episode);

  const clipsAreAvailable = zEnhanceJob?.clipUrlOriginal && zEnhanceJob?.clipUrlEnhanced;

  function getEnhanceProgressText() {
    if (!zEnhanceJob) return zEpisode?.userRequestedEnhance ? 'Starting...' : 'waiting for start...';
    return zEnhanceJob.progress == 100 ? 'Complete' : 'Progress...';
  }

  return (
    <Stack spacing={4} pt={4}>
      <Stack>
        <BodySmMuted>Recording Upgrade {getEnhanceProgressText()}</BodySmMuted>
        <ZProgressBar value={zEnhanceJob?.progress ?? 0} size={'xs'} isIndeterminate={false} />
      </Stack>
      <Flex gap={4}>
        <EnhanceConfiguration flex={4} />
        <Stack flex={5} variant="outline" height={'50vh'} maxHeight={'50vh'} overflow={'scroll'}>
          {clipsAreAvailable ? (
            <EnhancePreview enhanceApi={enhanceApi} />
          ) : (
            <BodySmMuted>
              A preview of your enhanced recording will appear here when we've finished processing...
            </BodySmMuted>
          )}
          {/* {zEnhanceResult && <EnhanceResults cleanvoiceResult={zEnhanceResult} />} */}
        </Stack>
      </Flex>
    </Stack>
  );
};

const EnhanceConfiguration = ({...props}) => {
  const zSetProjectConfigEnhance = projectStore((state) => state.setProjectConfigEnhance);
  const zProject = projectStore((state) => state.project);
  const zEpisode = episodeStore((state) => state.episode);

  function getSelectedInputs() {
    if (zProject.configEnhance == null) {
      const defaultEnhanceConfig = ['removeNoise'];
      updateEnhanceConfig(defaultEnhanceConfig);
      return defaultEnhanceConfig;
    }
    return Object.keys(zProject.configEnhance).filter((key) => zProject.configEnhance[key] === true);
  }

  function updateEnhanceConfig(selectedElementIds) {
    console.log('updateEnhanceConfig', selectedElementIds);

    const enhanceConfig = {};
    elements.forEach((element) => {
      enhanceConfig[element.id] = selectedElementIds.includes(element.id);
    });
    zSetProjectConfigEnhance(enhanceConfig);
  }

  const elements = [
    {
      id: 'includesMusic',
      title: 'Does your audio include music?',
      description: "If the audio file being uploaded contains music, we'll do our best to leave it unaltered.",
    },
    {
      id: 'removeNoise',
      title: 'Does your audio have background noise?',
      description: "Let's get rid of those background noises and other strange sounds.",
    },
    {
      id: 'aggressiveEnhancement',
      title: 'Want us to try editing your podcast?',
      description: "We'll remove filler words, stutters, and long silences. This may cut more than you want.",
    },
  ];

  return (
    <Stack {...props}>
      <ZCheckboxCardGroup
        elements={elements}
        selectedElements={getSelectedInputs()}
        disabledElements={
          zEpisode?.userRequestedEnhance || zEpisode?.resEnhancedUrl ? elements.map((element) => element.id) : []
        }
        onChange={(selectedElementIds) => {
          updateEnhanceConfig(selectedElementIds);
        }}
        elementBodyComponent={({element}) => {
          return (
            <Stack spacing={0}>
              <BodySmSemiBold>{element.title}</BodySmSemiBold>
              <BodySm>{element.description}</BodySm>
            </Stack>
          );
        }}
      />
    </Stack>
  );
};

const EnhancePreview = ({enhanceApi}) => {
  const zEnhanceJob = episodeStore((state) => state.enhanceJob);
  const zCurrentPlayerId = episodeStore((state) => state.currentPlayerId);
  const zSetCurrentPlayerId = episodeStore((state) => state.setCurrentPlayerId);
  const zAudioState = episodeStore((state) => state.audioState);
  const zSetAudioState = episodeStore((state) => state.setAudioState);
  const zSetEnhancePlaybackProgress = episodeStore((state) => state.setEnhancePlaybackProgress);
  const zEnhancePlaybackProgress = episodeStore((state) => state.enhancePlaybackProgress);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    // ensuring audio urls are available
    const clipUrlOriginal = zEnhanceJob?.clipUrlOriginal;
    const clipUrlEnhanced = zEnhanceJob?.clipUrlEnhanced;
    if (!clipUrlOriginal || !clipUrlEnhanced) return;

    if (!enhanceApi.audioPreviewManager) {
      const audioPreviewManager = new AudioPreviewManager();
      audioPreviewManager.initPreviewPlayers(clipUrlOriginal, clipUrlEnhanced, {
        apOnLoad: function apOnLoad(referenceId) {
          console.log('apOnLoad', referenceId);
        },
        apOnPause: function apOnPause(referenceId) {
          console.log('apOnPause', referenceId);
        },
        apOnStateChange: function apOnStateChange(state) {
          console.log('apOnStateChange', state);
          zSetAudioState(state);
          if (state === PreviewAudioPlayer.STATE_PLAYING) {
            startInterval();
          } else {
            stopInterval();
          }
        },
        apOnPlay: function apOnPlay(referenceId) {
          console.log('apOnPlay', referenceId);
        },
        apOnEnd: function apOnEnd(referenceId) {
          console.log('apOnEnd', referenceId);
          zSetEnhancePlaybackProgress(getDuration());
        },
      });
      enhanceApi.setAudioPreviewManager(audioPreviewManager);
    }
  }, [zEnhanceJob?.clipUrlOriginal, zEnhanceJob?.clipUrlEnhanced]);

  useEffect(() => {
    return () => {
      // force stopping audio when user exits screen
      enhanceApi.audioPreviewManager?.pause();
      // clear interval when component unmounts
      stopInterval();
    };
  }, []);

  function startInterval() {
    if (intervalIdRef.current === null) {
      intervalIdRef.current = setInterval(() => {
        zSetEnhancePlaybackProgress(getSeekPosition());
      }, 50);
    }
  }

  function stopInterval() {
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }

  function getSeekPosition() {
    if (!enhanceApi.audioPreviewManager) return 0;
    return enhanceApi.audioPreviewManager.seekPosition();
  }

  function playPause() {
    enhanceApi.audioPreviewManager?.playPause();
  }

  function toggleEnhancedVsOriginalAudio() {
    console.log('toggleEnhancedVsOriginalAudio');
    if (!enhanceApi.audioPreviewManager) return;
    const newPlayerId =
      zCurrentPlayerId === AudioPreviewManager.AP_ID_ORIG
        ? AudioPreviewManager.AP_ID_ENHANCED
        : AudioPreviewManager.AP_ID_ORIG;
    zSetCurrentPlayerId(newPlayerId);
    enhanceApi.audioPreviewManager?.toggleAp(newPlayerId);
  }

  function getDuration() {
    if (!enhanceApi.audioPreviewManager) return 0;
    return enhanceApi.audioPreviewManager.duration();
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={0}>
        <BodySmSemiBold>Studio Preview</BodySmSemiBold>
        <BodySm pt={0.5}>
          Preview a clip of your enhanced audio here. You can toggle between the original audio and your enhanced audio.
          Powered by{' '}
          <a target={'_blank'} href="https://cleanvoice.ai/" rel="noreferrer" style={{textDecoration: 'underline'}}>
            <span style={{fontWeight: '600'}}>cleanvoice.ai</span>
          </a>
          .
        </BodySm>
      </Stack>
      {/* <AudioSwitcher fileUrl1={zEnhanceJob?.fileUrlOriginal} fileUrl2={zEnhanceJob?.fileUrlEnhance} /> */}
      <Stack direction="row" justify="center" align="center" spacing="4" width={'100%'}>
        <ZIconButton
          width={10}
          height={10}
          icon={
            zAudioState === PreviewAudioPlayer.STATE_PLAYING ? (
              <Icon as={FiPause} boxSize={6} />
            ) : (
              <Icon as={FiPlay} boxSize={6} />
            )
          }
          onClick={playPause}
        />
        <Stack spacing={0.5} width={'100%'} justifyContent={'end'} alignItems={'start'}>
          <ZSwitch
            isChecked={zCurrentPlayerId === AudioPreviewManager.AP_ID_ENHANCED}
            label={`Toggle Enhanced vs. Original - Previewing ${zCurrentPlayerId === AudioPreviewManager.AP_ID_ENHANCED ? 'Enhanced' : 'Original'}`}
            onChange={toggleEnhancedVsOriginalAudio}
          />
          <ZProgressBar
            value={Math.ceil((zEnhancePlaybackProgress / getDuration()) * 100)}
            animateDeterminate={false}
            transition="0.2s"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

class AudioPreviewManager {
  constructor() {
    this.players = {};
    this.playerCallbacks = {};
    this.currentPlayerId = AudioPreviewManager.AP_ID_ENHANCED; // defaulting to enhanced audio
  }

  static AP_ID_ORIG = 'original';
  static AP_ID_ENHANCED = 'enhanced';

  initPreviewPlayers(urlOriginal, urlEnhanced, playerCallbacks) {
    if (!urlOriginal || !urlEnhanced) {
      console.log('initPreviewPlayers: missing urlOriginal or urlEnhanced');
      return;
    }

    this.playerCallbacks = playerCallbacks;
    this.players[AudioPreviewManager.AP_ID_ORIG] = new PreviewAudioPlayer(
      urlOriginal,
      AudioPreviewManager.AP_ID_ORIG,
      this.playerCallbacks,
    );
    this.players[AudioPreviewManager.AP_ID_ENHANCED] = new PreviewAudioPlayer(
      urlEnhanced,
      AudioPreviewManager.AP_ID_ENHANCED,
      this.playerCallbacks,
    );
  }

  getCurrentPlayer() {
    return this.players[this.currentPlayerId];
  }

  isPlayersLoading() {
    return Object.values(this.players).some((player) => player && player.isLoading());
  }

  isPlayersReady() {
    return Object.values(this.players).every((player) => player && player.isLoaded());
  }

  playPause() {
    const currentPlayer = this.getCurrentPlayer();
    if (!currentPlayer) {
      console.log('playAndPause: missing current player');
      return;
    }
    currentPlayer.playPause();
  }

  pause() {
    this.getCurrentPlayer()?.pause();
  }

  seekPosition() {
    const currentPlayer = this.getCurrentPlayer();
    if (!currentPlayer) {
      console.log('seekPosition: missing current player');
      return;
    }
    return currentPlayer.position();
  }

  duration() {
    const currentPlayer = this.getCurrentPlayer();
    if (!currentPlayer) {
      console.log('duration: missing current player');
      return;
    }
    return currentPlayer.totalDuration();
  }

  /** @param {string} currentPlayerId - the audio player id to play when playing */
  toggleAp(currentPlayerId) {
    const position = this.getCurrentPlayer().position();
    const isPlaying = this.getCurrentPlayer().isPlaying();
    this.getCurrentPlayer().pause();
    this.currentPlayerId = currentPlayerId;
    this.getCurrentPlayer().seek(position, isPlaying);

    console.log('todo: toggleAp', currentPlayerId);
  }
}

/** Built on top of Howler.js */
class PreviewAudioPlayer {
  constructor(resources, referenceId, callbacks) {
    this.src = resources;
    this.referenceId = referenceId;
    this.callbacks = callbacks;

    this.state = PreviewAudioPlayer.STATE_UNLOADED;
    this.player = null;
    this.soundId = null;
    this.seekPos = null;

    this.init();
  }
  /**
   *  class attributes
   *
   *  this.src - s3 resource url
   *  this.player - a Howl instance
   *  this.state - see static state options below
   */

  static STATE_UNLOADED = 'unloaded';
  static STATE_LOADED = 'loaded';
  static STATE_PLAYING = 'playing';
  static STATE_PAUSED = 'paused';

  init() {
    const self = this;

    const startTime = Date.now();
    // init Howl instance
    const {Howl} = require('howler');

    this.player = new Howl({
      src: this.src,
      html5: true,
      preload: true,
      onload: function (id) {
        self.state = PreviewAudioPlayer.STATE_LOADED;
        self.callbacks.apOnLoad(self.referenceId);
        const epochNow = Date.now();
        console.log('load time = ', (epochNow - startTime) / 1000, 'seconds');
      },
      onloaderror: function (id, error) {
        console.log(error);
      },
      onseek: function (id) {
        console.log('onseek id: ', id);
      },
      onplayerror: function (id, error) {
        console.log(error);
      },
      onpause: function (id) {
        console.log('AP onPause', Date.now());
        self.callbacks.apOnPause(self.referenceId);
        self.callbacks.apOnStateChange(PreviewAudioPlayer.STATE_PAUSED);
      },
      onplay: function (id) {
        console.log('AP onPlay', Date.now());

        // setting seek pos here because calling play right after setting seek position doesn't work. Seek only works while playing
        if (self.seekPos) self.seek(self.seekPos, false);

        self.callbacks.apOnPlay(self.referenceId);
        self.callbacks.apOnStateChange(PreviewAudioPlayer.STATE_PLAYING);
      },
      onend: function () {
        console.log('AP onEnd', Date.now());
        self.callbacks.apOnEnd(self.referenceId);
        self.state = PreviewAudioPlayer.STATE_PAUSED;
        self.callbacks.apOnStateChange(PreviewAudioPlayer.STATE_PAUSED);
      },
    });
  }

  isLoading() {
    return this.state === PreviewAudioPlayer.STATE_UNLOADED && this.state.player != null && this.src != null;
  }

  isLoaded() {
    return this.state === PreviewAudioPlayer.STATE_LOADED;
  }

  seek() {
    this.player.seek(null, this.soundId);
  }

  /**
   *
   * @param {Number} pos - seek position in seconds
   * @param {Boolean} autoPlay - true/false start playing after seek
   */
  seek(pos, autoPlay) {
    if (this.isPlaying()) {
      this.seekPos = null;
      this.player.seek(pos, this.soundId);
    } else {
      this.seekPos = pos;
    }

    if (autoPlay) {
      this.play();
    }
  }

  initSoundId(pos) {
    if (!this.soundId) {
      this.soundId = this.player.play();
      this.player.seek(pos, this.soundId);
    }
  }

  position() {
    // const result = this.player.seek(null, this.soundId); // this was commented because it thought we were trying to set the seek position rather than get it because we passed in soundId
    const result = this.player.seek(); // always seeks the most recent played.
    // console.log('AP position(): ', result);
    return result;
  }

  playPause() {
    console.log('AP playPause()', this.isPlaying() ? 'playing' : 'paused');
    if (this.isPlaying()) {
      this.pause();
    } else {
      this.play();
    }
  }

  isPlaying() {
    return this.player.playing();
  }

  play() {
    if (this.soundId) {
      this.player.play(this.soundId);
    } else {
      this.soundId = this.player.play();
    }
  }

  pause() {
    this.seekPos = null;
    this.player.pause();
  }

  totalDuration() {
    const duration = this.player.duration(this.soundId);
    return duration;
  }
}

export {Enhance, PreviewAudioPlayer};
