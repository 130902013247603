import {Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {useEffect, useMemo, useState} from 'react';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ZCard from '../../components/abstraction_high/ZCard';
import ZCopyButton from '../../components/abstraction_high/ZCopyButton';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import {BodySm} from '../../components/common/TextStyle';
import {ConfirmModal, InviteUserModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import {USER} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';
import Constants from '../../utils/constants';

export const Team = () => {
  const zUser = authStore((state) => state.user);
  const zUpdateUser = authStore((state) => state.updateUser);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);
  const zTeam = authStore((state) => state.team);
  const zSetTeam = authStore((state) => state.setTeam);
  const zTeamRemoveUser = authStore((state) => state.teamRemoveUser);

  // Modals
  const [stateDisplayInviteUserModalProps, setDisplayInviteUserModalProps] = useState(null);
  const [stateDisplayRemoveTeammateModalProps, setDisplayRemoveTeammateModalProps] = useState(null);

  const {teamApi} = useAPI();

  // the authenticated user
  const authTeammate = useMemo(() => {
    if (!zTeam) {
      return;
    }

    const authTeammate = zTeam.find((teammate) => teammate.userSub === zUser.userSub);
    if (!authTeammate) {
      teamApi.postError('memoAuthTeammate', 'User not found in team', {userSub: zUser.userSub});
    }

    return authTeammate;
  }, [teamApi, zTeam, zUser.userSub]);

  const authUserRole = useMemo(() => authTeammate?.role, [authTeammate]);

  useEffect(() => {
    async function initialLoad() {
      if (!zUser.teamId) {
        zSetTeam([{sk: zUser.email, role: 'admin', userSub: zUser.userSub, name: USER.getFullName(zUser)}]);
        return;
      }

      zSetLoading(true);

      try {
        const getTeamPromise = teamApi.getTeam(zUser.teamId);
        const responses = await Promise.all([getTeamPromise]);
        responses.forEach(
          (isSuccess) => !isSuccess && zSetError('saveConfigAndRequestEnhance', 'Error requesting enhance'),
        );

        // set team
        zSetTeam(responses[0]);
      } catch (e) {
        console.log('error initialLoad', e);
        zSetError('initialLoad', e.message);
      } finally {
        zSetLoading(false);
      }
    }

    initialLoad();
  }, [zUser]);

  async function inviteUser(email, role) {
    if (email === zUser.email) {
      return 'You are already a member of this team.';
    }

    // check if invite or teammate already exists
    if (zTeam && zTeam.some((teammate) => teammate.sk === email)) {
      return `An invitation has already been sent to ${email}. If you'd like to send them another invitation, please cancel the prior invitation first.`;
    }

    const invitedTeamItem = await teamApi.inviteUser(zUser.userSub, email, role, zUser.email, zUser.teamId);
    if (!invitedTeamItem) {
      return;
    }

    const updatedZTeam = [...zTeam, invitedTeamItem];

    // update user with teamId if not already set
    if (!zUser.teamId) {
      zUpdateUser({teamId: invitedTeamItem.pk});

      // isOwner set to true because they didn't originally have a teamId - required for checking if they can leave team
      const authTeamItem = updatedZTeam.find((teammate) => teammate.userSub === zUser.userSub);
      authTeamItem.isOwner = true;
      authTeamItem.pk = invitedTeamItem.pk;
    }

    // update zTeam
    zSetTeam(updatedZTeam);
    return invitedTeamItem;
  }

  async function removeTeammate(pk, sk, userSub) {
    try {
      zSetLoading(true);

      const isSuccess = await teamApi.deleteTeamItem(pk, sk, userSub);
      if (!isSuccess) {
        throw new Error('Error removing teammate');
      }
    } catch (e) {
      console.log('error removeTeammate', e);
      zSetError('removeTeammate', e);
      return;
    } finally {
      zSetLoading(false);
    }

    // remove zUser.teamId
    if (userSub === zUser.userSub) {
      zUpdateUser({teamId: null});
    }

    // update zTeam
    zTeamRemoveUser(sk);
  }

  function getTeamInvitationLink(pk) {
    if (!pk) {
      zSetError('getTeamInvitationLink');
      return;
    }

    return Constants.BASE_URL + '/settings/team/' + pk;
  }

  const ExtraActionButton = ({teammate}) => {
    if (!teammate) {
      return null;
    }

    // if this is the current authenticated user, show "Leave Team"
    if (teammate.userSub === zUser.userSub && zUser.teamId && (!teammate.isOwner || zTeam.length === 1)) {
      return (
        <BodySm
          color={'red'}
          cursor={'pointer'}
          onClick={() => {
            setDisplayRemoveTeammateModalProps({
              pk: teammate.pk,
              sk: teammate.sk,
              email: teammate.sk,
              bodyText: "You will no longer have access to your team's resources. Are you sure you want to continue?",
              positiveText: 'Leave Team',
              userSub: zUser.userSub, // used to remove zUser.teamId
            });
          }}
        >
          Leave Team
        </BodySm>
      );
    }

    if (authUserRole !== 'admin') {
      return null;
    }
    // admin capabilities only beyond this point

    if (teammate.invitationPending) {
      return (
        <BodySm
          color={'red'}
          cursor={'pointer'}
          onClick={() => {
            setDisplayRemoveTeammateModalProps({
              pk: teammate.pk,
              sk: teammate.sk,
              email: teammate.sk,
              bodyText:
                'Team member with email ' +
                teammate.sk +
                " will no longer have access to your team's resources. Are you sure you want to continue?",
              positiveText: 'Cancel Invitation',
              userSub: teammate.userSub, // used to remove teamId
            });
          }}
        >
          Cancel Invitation
        </BodySm>
      );
    }

    if (teammate.role !== 'admin') {
      return (
        <BodySm
          color={'red'}
          cursor={'pointer'}
          onClick={() => {
            setDisplayRemoveTeammateModalProps({
              pk: teammate.pk,
              sk: teammate.sk,
              email: teammate.sk,
              bodyText:
                'Team member with email ' +
                teammate.sk +
                " will no longer have access to your team's resources. Are you sure you want to continue?",
              positiveText: 'Remove Member',
              userSub: teammate.userSub, // used to remove teamId
            });
          }}
        >
          Remove Member
        </BodySm>
      );
    }

    return null;
  };

  const TeamListItem = ({item: teammate = {}, index}) => (
    <Tr>
      <Td>{teammate.sk}</Td>

      <Td>
        {teammate.name && teammate.name}

        {!teammate.name && teammate.invitationPending && (
          <ZCopyButton
            cta={'Copy Invitation Link'}
            tooltipLabel={getTeamInvitationLink(teammate.pk)}
            getCopyContentCallback={() => {
              return getTeamInvitationLink(teammate.pk);
            }}
          />
        )}
      </Td>

      <Td>{teammate.role} </Td>

      <Td>
        <ExtraActionButton teammate={teammate} />
      </Td>
    </Tr>
  );

  const TeamList = ({...props}) => (
    <ZCard variant={'outline'} padding={0} {...props}>
      <Stack justify="flex-start" align="center" width="100%">
        <TableContainer overflowY="scroll" width={'100%'} borderRadius={'10px'}>
          <Table variant="simple" width={'100%'}>
            <Thead width={'100%'}>
              <Tr>
                <Th>Email</Th>
                <Th>Name</Th>
                <Th>Role</Th>
                <Th></Th>
              </Tr>
            </Thead>

            <Tbody>{zTeam && zTeam.map((item, idx) => <TeamListItem item={item} index={idx} key={item.sk} />)}</Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </ZCard>
  );

  return (
    <FlowContainer>
      <FlowHeader
        title={'Team'}
        description={
          'Collaborate with other users by adding them to your team. Team members share project resources and subscription credits. Reach out to us for enterprise options.'
        }
        hideBreadcrumbs={true}
        rightComponent={
          <ButtonPrimary
            isDisabled={authUserRole !== 'admin'}
            tooltipLabel={
              authUserRole !== 'admin'
                ? "Invitations are managed by admin users. Reach out to your team admin if you'd like to add a user."
                : null
            }
            onClick={() => setDisplayInviteUserModalProps({})}
          >
            Invite User
          </ButtonPrimary>
        }
      />

      <FlowBody>
        <Stack>
          <TeamList maxHeight={'39vh'} overflowY={'scroll'} />
        </Stack>

        <InviteUserModal
          onSubmit={async (email, role) => await inviteUser(email, role)}
          openThisModal={stateDisplayInviteUserModalProps}
          onCloseCallback={() => {
            setDisplayInviteUserModalProps(null);
          }}
        />

        <ConfirmModal
          positiveText={stateDisplayRemoveTeammateModalProps?.positiveText || 'Remove Teammate'}
          positiveCallback={() => {
            removeTeammate(
              stateDisplayRemoveTeammateModalProps.pk,
              stateDisplayRemoveTeammateModalProps.sk,
              stateDisplayRemoveTeammateModalProps.userSub,
            );
          }}
          bodyText={stateDisplayRemoveTeammateModalProps?.bodyText || 'Are you sure you want to remove this teammate?'}
          openThisModal={stateDisplayRemoveTeammateModalProps}
          closeModal={() => {
            setDisplayRemoveTeammateModalProps(null);
          }}
        />
      </FlowBody>

      <FlowFooter />
    </FlowContainer>
  );
};
