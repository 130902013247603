import {Icon} from '@chakra-ui/react';
import React from 'react';
import {FiArrowRight} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';

import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import {FlowBody, FlowContainer, FlowHeader} from '../../components/common/Structural';

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <FlowContainer>
      <FlowHeader title={'404: Page Not Found'} description={"Let's get you back on the right track!"} />
      <FlowBody>
        <ButtonSecondary wrap={true} rightIcon={<Icon as={FiArrowRight} />} onClick={() => navigate('/')}>
          Back to Home
        </ButtonSecondary>
      </FlowBody>
    </FlowContainer>
  );
};
