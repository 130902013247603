import {Button, IconButton, Tooltip} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function NavButton({
  navSize,
  rightIcon,
  leftIcon,
  size = 'md',
  colorScheme,
  variant,
  sx,
  style,
  onClick,
  children,
  ...buttonProps
}) {
  return (
    <Tooltip label={children} placement="right" borderRadius={'10px'}>
      {navSize === 'large' ? (
        <Button
          justifyContent={'flex-start'}
          rightIcon={rightIcon}
          leftIcon={leftIcon}
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          onClick={onClick}
          style={style}
          {...buttonProps}
        >
          {children}
        </Button>
      ) : (
        <IconButton
          borderRadius={'10px'}
          icon={leftIcon}
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          onClick={onClick}
          style={style}
          {...buttonProps}
        />
      )}
    </Tooltip>
  );
}

NavButton.propTypes = {
  rightIcon: PropTypes.element,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func.isRequired,

  textColor: PropTypes.string,
  size: PropTypes.string,
  colorScheme: PropTypes.string,
  sx: PropTypes.object,
  style: PropTypes.object,
};
