import {Stack} from '@chakra-ui/react';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import {ZForm} from '../../components/abstraction_high/ZForm';
import {ZInputSavable, ZRadioGroup} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import authStore from '../../stores/auth-store';
import {useAuthenticatedAPIs} from '../../utils/api-v2-context';

/**
 *
 * input object:
 *
 * id: "pid_1234567890"
 * name: "",
 * description: ""
 * type: "input"
 *
 *
 */

const defaultInputObject = {
  id: 'iid_' + uuid().replace(/-/g, ''),
  name: '',
  description: '',
  type: 'input',
  inputType: 'input', // input | textArea |
  label: '',
  labelType: 'smSemiBold' | 'bodySm',
  subLabel: '',
  placeholder: '',
};

export const InputBuilder = ({defaultInput}) => {
  const {inputId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {inputApi} = useAuthenticatedAPIs();

  const [stateInput, setInput] = useState(defaultInput ?? defaultInputObject);

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    let inputItem;
    if (inputId) inputItem = await inputApi.getInput(inputId);
    console.log('inputItem', inputItem);

    if (inputItem) setInput(inputItem);
  }

  function postInput() {
    // calculate dependencies
    const inputCopy = {...stateInput};
    inputCopy.isDraft = false;

    if (!checkInputPublishable(inputCopy)) return;

    console.log('saving input', JSON.stringify(inputCopy));
    inputApi.postInput(inputCopy);
    if (!inputId) navigate(location.pathname + '/' + inputCopy.id);
  }

  function saveDraft() {
    // calculate dependencies
    const inputCopy = {...stateInput};
    inputCopy.isDraft = true;

    console.log('saving draft of input', JSON.stringify(inputCopy));
    inputApi.postInput(inputCopy);
    if (!inputId) navigate(location.pathname + '/' + inputCopy.id);
  }

  function checkInputPublishable(input) {
    if (input.name === '' || input.description === '') {
      console.log('missing name or description');
      return false;
    }

    if (input.inputType === 'input' && input.label === '') {
      console.log('missing label');
      return false;
    }

    if (input.type !== 'input') {
      console.log('must set type to input');
      return false;
    }

    return true;
  }

  function updateInputState(attributes) {
    const inputCopy = {...stateInput};
    for (const [key, value] of Object.entries(attributes)) {
      inputCopy[key] = value;
    }
    setInput(inputCopy);
  }

  const InputMetadata = ({defaultInput, ...props}) => {
    function onSaveInput(attributeName, value) {
      console.log('saving', attributeName, value);
      // create a shallow copy
      let inputCopy = {...stateInput};

      // handling nested references to attributes
      if (attributeName.split('_').length > 1) {
        console.log('modify array attribute');
        const [attribute, elementValue] = attributeName.split('_');
        const arraySet = new Set(inputCopy[attribute]);
        value ? arraySet.add(elementValue) : arraySet.delete(elementValue);
        inputCopy[attribute] = [...arraySet];
      } else {
        _.set(inputCopy, attributeName, value);
      }
      console.log('inputCopy', inputCopy);

      // update state
      setInput(inputCopy);
    }

    return (
      <Stack spacing={4} {...props}>
        <ZInputSavable
          attributeName={'name'}
          placeholder={'Input Name'}
          defaultValue={defaultInput?.name}
          onSave={onSaveInput}
        />
        <ZInputSavable
          attributeName={'description'}
          isTextArea={true}
          placeholder={'Description of the input'}
          defaultValue={defaultInput?.description}
          onSave={onSaveInput}
        />
      </Stack>
    );
  };

  const InputTypeOptions = () => {
    return (
      <Stack spacing={10}>
        <ZInputSavable
          attributeName={'label'}
          placeholder={'Label'}
          defaultValue={stateInput?.label}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZInputSavable
          attributeName={'subLabel'}
          placeholder={'Sub Label'}
          defaultValue={stateInput?.subLabel}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZInputSavable
          attributeName={'placeholder'}
          placeholder={'Placeholder text'}
          defaultValue={stateInput?.placeholder}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZRadioGroup
          description={'Select the label font weight.'}
          attributeName={'labelType'}
          onChange={(attributeName, value) => {
            updateInputState({[attributeName]: value});
          }}
          value={stateInput?.labelType}
          elements={[
            {value: 'bodySm', label: 'Regular', tooltipText: 'Less aggressive'},
            {value: 'smSemiBold', label: 'Bold', tooltipText: 'More aggressive'},
          ]}
        />
      </Stack>
    );
  };

  const TextAreaTypeOptions = () => {
    return (
      <Stack spacing={10}>
        <ZInputSavable
          attributeName={'label'}
          placeholder={'Label'}
          defaultValue={stateInput?.label}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZInputSavable
          attributeName={'subLabel'}
          placeholder={'Sub Label'}
          defaultValue={stateInput?.subLabel}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZInputSavable
          isTextArea={true}
          attributeName={'placeholder'}
          placeholder={'Placeholder text'}
          defaultValue={stateInput?.placeholder}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZRadioGroup
          description={'Select the label font weight.'}
          attributeName={'labelType'}
          onChange={(attributeName, value) => {
            updateInputState({[attributeName]: value});
          }}
          value={stateInput?.labelType}
          elements={[
            {value: 'bodySm', label: 'Regular', tooltipText: 'Less aggressive'},
            {value: 'smSemiBold', label: 'Bold', tooltipText: 'More aggressive'},
          ]}
        />
      </Stack>
    );
  };

  const NumberInputTypeOptions = () => {
    return (
      <Stack spacing={10}>
        <ZInputSavable
          attributeName={'label'}
          placeholder={'Label'}
          defaultValue={stateInput?.label}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZInputSavable
          attributeName={'subLabel'}
          placeholder={'Sub Label'}
          defaultValue={stateInput?.subLabel}
          onSave={(attributeName, value) => updateInputState({[attributeName]: value})}
        />
        <ZRadioGroup
          description={'Select the label font weight.'}
          attributeName={'labelType'}
          onChange={(attributeName, value) => {
            updateInputState({[attributeName]: value});
          }}
          value={stateInput?.labelType}
          elements={[
            {value: 'bodySm', label: 'Regular', tooltipText: 'Less aggressive'},
            {value: 'smSemiBold', label: 'Bold', tooltipText: 'More aggressive'},
          ]}
        />
      </Stack>
    );
  };

  const SampleOutput = (props) => {
    return (
      <Stack my={4} {...props}>
        <ZForm inputs={[stateInput]} title={'Sample Output'} omitSubmitButton={true} />
      </Stack>
    );
  };

  return (
    <FlowContainer maxWidth={'1000px'}>
      <FlowHeader
        title={'Input Builder'}
        description={
          'Build a input for chatGPT using elements. You can draw from data made available through the transcript, or build on top of other inputs.'
        }
        rightComponent={
          <Stack>
            <ButtonPrimary
              onClick={() => {
                console.log('input: ', stateInput);
              }}
            >
              Print Input
            </ButtonPrimary>
            <Stack direction={'row'}>
              <ButtonPrimary onClick={saveDraft}>Save Draft</ButtonPrimary>
              <ButtonPrimary onClick={postInput}>Post Input</ButtonPrimary>
            </Stack>
          </Stack>
        }
      />
      <FlowBody>
        <InputMetadata pb={10} defaultInput={stateInput} />

        <Stack spacing={10}>
          <ZRadioGroup
            description={
              'Select which input type you would like to use. Once you publish, you will not be able to change this.'
            }
            attributeName={'inputType'}
            onChange={(attributeName, value) => {
              updateInputState({[attributeName]: value});
            }}
            isDisabled={stateInput.isDraft === false}
            value={stateInput.inputType}
            elements={[
              {value: 'input', label: 'Input', tooltipText: 'Select a range of outputs'},
              {value: 'textArea', label: 'Text Area', tooltipText: 'Select a number of outputs randomly'},
              {value: 'numberInput', label: 'Number Input', tooltipText: 'Select an individual number'},
            ]}
          />

          <ZRadioGroup
            description={
              'Automatically save and reuse this input between episodes. Once you publish, you will not be able to change this.'
            }
            attributeName={'reusable'}
            onChange={(attributeName, value) => {
              updateInputState({[attributeName]: value});
            }}
            isDisabled={stateInput.isDraft === false}
            value={stateInput.reusable}
            elements={[
              {value: 'true', label: 'Reusable', tooltipText: ''},
              {value: 'false', label: 'Not Reusable', tooltipText: ''},
            ]}
          />

          {stateInput.inputType == 'input' && <InputTypeOptions />}
          {stateInput.inputType == 'textArea' && <TextAreaTypeOptions />}
          {stateInput.inputType == 'numberInput' && <NumberInputTypeOptions />}

          <SampleOutput />
          {/* <SampleOutput position={"sticky"} bottom={0} /> */}
        </Stack>
      </FlowBody>
      <FlowFooter />
    </FlowContainer>
  );
};
