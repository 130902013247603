import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';
import {forwardRef, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {FiEdit, FiExternalLink, FiPlus, FiTrash} from 'react-icons/fi';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ZCard from '../../components/abstraction_high/ZCard';
import ZRadioElementCard from '../../components/abstraction_high/ZRadioElementCard';
import {
  ZAccordionSingleItem,
  ZCheckboxCardGroup,
  ZCheckboxGroup,
  ZInputSavable,
  ZRangeSlider,
  ZSlider,
} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import {BodySm, BodySmMuted, BodySmSemiBold} from '../../components/common/TextStyle';
import {GPT} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';

/**
 *
 * NOTES:
 * - iterable is an array of strings
 *
 * prompt object:
 *
 * id: "pid_1234567890"
 * name: "Summaries",
 * description: "Up to 10 summaries of each topic within the episode",
 * customInputIds: []
 * tags: ["podcast", "informative", "entertaining", "singlehost", "cohost", "guest"],
 * elements: []
 * type: "prompt"
 * outputType: "string" | "iterable"
 * iterableConfig: { // only useful if outputType is iterable
 * - maxNumOutputs: 10,
 * - iterationMethod: "range" | "select"
 * - iterationRange: [0, 10] | [0, 1]
 * - iterationSelect: 3 // selects randomly the number of elements provided
 * }, // optional
 * approxTokens: 1000,  // approx number of tokens in the output
 * dependencies: [], // calculated when posting/updating prompt
 * promptDepth:
 *
 *
 *
 * element object: (can be of type prompt, data_layer, or custom)
 *
 * id: "dl_transcript_fragments" | "dl_custom" | "pid_1234567890"
 * name: "Fragmented Transcript",
 * description: "The transcript of the audio file, broken into fragments",
 * content: "" // only applies to custom
 * type: "prompt" | "custom" | "data_layer"
 * outputType: "string" | "iterable" // applies only to prompt and data_layer
 * iterableConfig: // defined in prompt ^. Only useful if outputType is iterable
 * approxTokens: 1000,  // approx number of tokens consumed by using this element as input
 * dependencies: [], // calculated when posting/updating prompt
 * promptDepth: 0,
 *
 */

const defaultPromptElement = {
  id: 'dl_custom',
  name: 'Custom Content',
  description: 'Use this block to chain data together and customize results.',
  type: 'custom',
  outputType: 'string',
  promptDepth: 0,
  approxTokens: 0,
  blockType: 'inline',
  content: '',
};
const defaultPromptObject = {
  id: 'pid_' + uuid().replace(/-/g, ''),
  name: '',
  description: '',
  customInputIds: [],
  tags: ['podcast', 'informative', 'entertaining', 'singlehost', 'cohost', 'guest'],
  type: 'prompt',
  outputType: 'string',
  iterableConfig: {
    sourceId: '',
    // "pid_1234": {
    //   iterationMethod: "range", || "all" || "select"
    //   iterationRange: [0, 9],
    //   maxNumOutputs: 25,
    // }
  },
  elements: [defaultPromptElement],
};

export const PromptBuilder = ({defaultPrompt}) => {
  const {promptId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // const MAX_TOKENS = 2000;
  const {isOpen: isOpenAddBlock, onOpen: onOpenAddBlock, onClose: onCloseAddBlock} = useDisclosure();

  const {promptApi, gptApi, inputApi} = useAPI();

  const [stateDataOptions, setDataOptions] = useState([]);
  const [statePromptOptions, setPromptOptions] = useState([]);
  const [stateInputOptions, setInputOptions] = useState([]);
  const [stateEditElementIndex, setEditElementIndex] = useState();
  const [statePrompt, setPrompt] = useState(defaultPrompt ?? defaultPromptObject);

  const [stateMetadataAccordionOpen, setMetadataAccordionOpen] = useState(true);
  const [stateInputsAccordionOpen, setInputsAccordionOpen] = useState(false);
  const [stateOutputAccordionOpen, setOutputAccordionOpen] = useState(false);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function initData() {
    // Fetch dataOptions and promptOptions in parallel
    const [dataOptions, promptOptions] = await Promise.all([gptApi.getDataLayer(), promptApi.getPrompts()]);

    // Set dataOptions and promptOptions (filtered)
    if (dataOptions) setDataOptions(dataOptions);
    const nonDraftPrompts = promptOptions.filter((p) => p.id !== promptId && p.isDraft !== true);
    if (promptOptions) setPromptOptions(nonDraftPrompts);

    if (!promptId) return;

    // Update this prompt with prompts loaded from API
    let prompt = promptOptions.find((p) => p.id === promptId);
    if (!prompt) {
      console.log('prompt not found');
      defaultPromptObject.id = promptId;
      setPrompt(defaultPromptObject);
      return;
    }
    console.log('prompt found');

    // Update prompt elements with most recent data
    prompt.elements = prompt.elements.map((element) => {
      if (element.type === 'prompt') {
        const foundPrompt = promptOptions.find((p) => p.id === element.id);
        return foundPrompt ? {...element, ...foundPrompt} : element;
      }
      return element;
    });

    // Calculating custom inputs so that updates to sub prompts are reflected
    const customInputIds = calculateCustomInputIds(prompt);
    prompt.customInputIds = customInputIds;
    console.log('calculated custom inputs', customInputIds);

    if (customInputIds && customInputIds.length > 0) {
      const customInputs = await loadCustomInputOptions(true); // Pre-loading custom inputs
      setInputOptions(customInputs);
    }

    setPrompt(prompt);

    console.log('number of non-draft prompts:', nonDraftPrompts.length);
  }

  async function loadCustomInputOptions(doReturn = false) {
    const customInputs = await inputApi.getInputs();
    console.log('custom inputs', customInputs);
    if (!customInputs) return;
    if (doReturn) {
      return customInputs;
    } else {
      setInputOptions(customInputs);
    }
  }

  function postPrompt(isDraft = false) {
    // calculate dependencies
    const promptCopy = {...statePrompt};
    promptCopy.dependencies = calculateDependencies(promptCopy);
    promptCopy.customInputIds = calculateCustomInputIds(promptCopy);
    removeNestedPromptElements(promptCopy);
    promptCopy.isDraft = isDraft;

    if (!isDraft && !checkPromptPublishable(promptCopy)) return;

    console.log('saving prompt', JSON.stringify(promptCopy));
    promptApi.postPrompt(promptCopy);
    setPrompt(promptCopy);
    if (!promptId) navigate(location.pathname + '/' + promptCopy.id);
  }

  function saveDraft() {
    postPrompt(true);
  }

  function calculateDependencies(prompt) {
    const dependencies = new Set();
    prompt.elements.forEach((element) => {
      if (element.type === 'prompt') {
        dependencies.add(element.id);
      }
    });
    return Array.from(dependencies);
  }

  function calculateCustomInputIds(prompt) {
    if (!prompt) return [];

    const customInputIds = new Set(prompt.customInputIds);
    for (let element of prompt?.elements ?? []) {
      if (element.type === 'prompt') {
        element.customInputIds.forEach((inputId) => customInputIds.add(inputId));
      } else if (element.type === 'input') {
        customInputIds.add(element.id);
      }
    }
    return Array.from(customInputIds);
  }

  function checkPromptPublishable(prompt) {
    if (prompt.name === '' || prompt.description === '') {
      console.log('missing name or description');
      return false;
    }
    if (prompt.elements.length === 0) {
      console.log('missing elements');
      return false;
    }
    if (
      !prompt.dependencies ||
      (prompt.elements.some((element) => element.type === 'prompt') && prompt.dependencies.length === 0)
    ) {
      console.log('missing dependencies');
      return false;
    }
    if (prompt.outputType === 'iterable' && prompt.iterableConfig.sourceId === '') {
      console.log('missing iterable source');
      return false;
    }
    if (prompt.outputType === 'iterable' && !prompt.iterableConfig.maxNumOutputs) {
      console.log('missing maxNumOutputs');
      return false;
    }

    for (const element of prompt.elements.filter((element) => element.outputType === 'iterable')) {
      if (prompt.iterableConfig[element.id] == undefined) {
        console.log('missing iterable config for', element.id);
        return false;
      }
    }

    return true;
  }

  function removeNestedPromptElements(prompt) {
    for (const element of prompt.elements) {
      if (element.type === 'prompt') {
        delete element.elements;
      }
    }
  }

  function propagateChangesToDependents() {
    /**
     * Iterates over each potentialDependent in statePromptOptions to check if they contain
     * the target prompt element (identified by its ID). If found, it updates the attributes
     * of the target element in potentialDependent to match those present in both
     * the target element and statePrompt. After updating, it posts the updated version of the dependent prompt.
     */
    statePromptOptions.forEach((potentialDependent) => {
      const targetElement = potentialDependent.elements.find(
        (element) => element.type === 'prompt' && element.id === promptId,
      );

      if (targetElement) {
        console.log('found dependent', potentialDependent.id);

        Object.keys(statePrompt).forEach((key) => {
          if (targetElement.hasOwnProperty(key)) {
            targetElement[key] = statePrompt[key];
          }
        });

        console.log('updating dependent', potentialDependent);
        promptApi.postPrompt(potentialDependent);
      }
    });
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const promptCopy = {...statePrompt};
    const items = promptCopy.elements;

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    promptCopy.elements = items;

    setPrompt(promptCopy);
  };

  const addDefaultItem = () => {
    const promptCopy = {...statePrompt};
    promptCopy.elements = [...promptCopy.elements, defaultPromptElement];
    setPrompt(promptCopy);
  };

  const onDeleteElement = (id, index) => {
    const promptCopy = {...statePrompt};
    if (promptCopy.iterableConfig.sourceId === id) {
      promptCopy.iterableConfig.sourceId = '';
    }
    promptCopy.elements.splice(index, 1);
    promptCopy.dependencies = calculateDependencies(promptCopy); // ensuring dependencies are updated
    setPrompt(promptCopy);
  };

  const onOpenElementEditor = (id, elementType) => {
    console.log('onOpenElementEditor', id, elementType);

    let url;
    const localhostPrefix = 'http://localhost:3000';
    if (elementType === 'prompt') {
      url = `${localhostPrefix}/prompt_builder/${id}`;
    } else if (elementType === 'input') {
      url = `${localhostPrefix}/input_builder/${id}`;
    } else {
      console.log('Unknown element type');
    }
    if (!url) return;
    console.log('onOpenElementEditor opening url:', url);
    window.open(url, '_blank').focus();
  };

  const onEditElement = (item, index) => {
    setEditElementIndex(index);
    onOpenAddBlock();
  };

  function saveElement(element) {
    const elementCopy = {...element};
    const promptCopy = {...statePrompt};
    promptCopy.elements[stateEditElementIndex] = elementCopy;
    promptCopy.dependencies = calculateDependencies(promptCopy); // ensuring dependencies are updated

    // ensuring that sub prompt custom inputs are added to the parent prompt
    if (element.type === 'prompt')
      promptCopy.customInputIds = Array.from(
        new Set([...promptCopy.customInputIds, ...(element.customInputIds ?? [])]),
      );

    setEditElementIndex(null);
    setPrompt(promptCopy);
  }

  function CustomElement({onSaveCustomContent, defaultContent = ''}) {
    const onSave = (attr, content) => {
      onSaveCustomContent(content);
    };
    return (
      <ZInputSavable
        attributeName={'custom'}
        isTextArea={true}
        label={'Input custom content'}
        labelType={'smSemiBold'}
        placeholder={'Write me a blog post that...'}
        defaultValue={defaultContent}
        onSave={onSave}
      />
    );
  }

  function isIterationPossible() {
    return statePrompt.elements.some((element) => element.outputType === 'iterable');
  }

  const IterableConfigElement = ({element, index, ...props}) => {
    const [stateIterationMethod, setIterationMethod] = useState(
      statePrompt.iterableConfig[element.id]?.iterationMethod ?? '',
    );
    const [stateIterationRange, setIterationRange] = useState(
      statePrompt.iterableConfig[element.id]?.iterationRange ?? [0, element.iterableConfig.maxNumOutputs - 1],
    );
    const [stateSelectNumOutputs, setSelectNumOutputs] = useState(
      statePrompt.iterableConfig[element.id]?.selectNumOutputs ?? element.iterableConfig.maxNumOutputs,
    );

    function saveElementConfig() {
      const promptCopy = {...statePrompt};
      promptCopy.iterableConfig[element.id] = {
        iterationMethod: stateIterationMethod,
        iterationRange: stateIterationRange,
        selectNumOutputs: stateSelectNumOutputs,
      };

      // if this elementId is === iterableConfig.sourceId, calculate maxNumOutputs for prompt
      if (promptCopy.iterableConfig.sourceId === element.id) {
        if (stateIterationMethod === 'all') {
          promptCopy.iterableConfig.maxNumOutputs = element.iterableConfig.maxNumOutputs;
        } else if (stateIterationMethod === 'range') {
          promptCopy.iterableConfig.maxNumOutputs = stateIterationRange[1] - stateIterationRange[0] + 1;
        } else if (stateIterationMethod === 'select') {
          promptCopy.iterableConfig.maxNumOutputs = stateSelectNumOutputs;
        } else if (stateIterationMethod === 'match_index') {
          throw new Error("match_index can't be chosen as the iteration method for the source prompt");
        }
      }

      setPrompt(promptCopy);
    }

    function onIterationMethodChange(value) {
      console.log('onIterationMethodChange', value);
      setIterationMethod(value);
    }

    function onIterationRangeChange(range) {
      console.log('onIterationRangeChange', range);
      setIterationRange(range);
    }

    function onSelectNumOutputsChange(value) {
      console.log('onSelectNumOutputsChange', value);
      setSelectNumOutputs(value);
    }

    return (
      <Stack>
        <Stack>
          <BodySmMuted>
            You can adjust how many prompts to create, or which outputs from the iterable data source to use for each
            prompt. The upper bound is set by the maximum number of outputs of your selected data source, but no index
            out of bound exceptions will be thrown regardless.
          </BodySmMuted>
          <RadioGroup onChange={onIterationMethodChange} value={stateIterationMethod}>
            <Stack spacing={5} direction="row">
              <ZRadioElementCard
                value="all"
                selected={stateIterationMethod == 'all'}
                tooltipText={'An output for each input provided.'}
              >
                <Stack>
                  <BodySmSemiBold>All</BodySmSemiBold>
                </Stack>
              </ZRadioElementCard>
              <ZRadioElementCard
                value="range"
                selected={stateIterationMethod == 'range'}
                tooltipText={'Select a range of outputs'}
              >
                <Stack>
                  <BodySmSemiBold>Range</BodySmSemiBold>
                </Stack>
              </ZRadioElementCard>
              <ZRadioElementCard
                value="select"
                selected={stateIterationMethod == 'select'}
                tooltipText={'Select a number of outputs randomly'}
              >
                <Stack>
                  <BodySmSemiBold>Select</BodySmSemiBold>
                </Stack>
              </ZRadioElementCard>
              {statePrompt.iterableConfig.sourceId && statePrompt.iterableConfig.sourceId !== element.id && (
                <ZRadioElementCard
                  value="match_index"
                  selected={stateIterationMethod == 'match_index'}
                  tooltipText={
                    'Since you have two prompts with iterable output types, you can choose to use only the correlating output from your iterable data source output. If value exists at index, value will be empty.'
                  }
                >
                  <Stack>
                    <BodySmSemiBold>Match Iterable Source Index</BodySmSemiBold>
                  </Stack>
                </ZRadioElementCard>
              )}
            </Stack>
          </RadioGroup>
        </Stack>

        {stateIterationMethod == 'range' && (
          <Stack>
            <BodySm>
              Range:
              {stateIterationRange[0]}
              {' - '}
              {stateIterationRange[1]}: end index included in range. Number of prompts:{' '}
              {stateIterationRange[1] - stateIterationRange[0] + 1}
            </BodySm>
            <ZRangeSlider
              min={0}
              max={element.iterableConfig.maxNumOutputs - 1}
              defaultValue={stateIterationRange}
              onChangeEnd={onIterationRangeChange}
            />
          </Stack>
        )}

        {stateIterationMethod == 'select' && (
          <Stack>
            <BodySm>{'Range:\nMaximum number of prompts: (Randomly Selected): ' + stateSelectNumOutputs}</BodySm>
            <ZSlider
              min={1}
              max={element.iterableConfig.maxNumOutputs}
              defaultValue={1}
              onChangeEnd={onSelectNumOutputsChange}
            />
          </Stack>
        )}

        <Stack direction="row" justifyContent={'end'}>
          <Wrap>
            <WrapItem>
              <ButtonPrimary onClick={saveElementConfig}>Save</ButtonPrimary>
            </WrapItem>
          </Wrap>
        </Stack>
      </Stack>
    );
  };

  const IterableConfigOptions = (props) => {
    // const [stateOpenIterableConfigElementIndex, setOpenIterableConfigElementIndex] = useState(null);

    function onOutputTypeChange(outputType) {
      const promptCopy = {...statePrompt};
      promptCopy.outputType = outputType;

      setPrompt(promptCopy);
    }

    const onIterableDataSourceChange = (event) => {
      const promptCopy = {...statePrompt};
      promptCopy.iterableConfig.sourceId = event.target.value;
      const maxNumOutputs = promptCopy.elements.find((element) => element.id === event.target.value).iterableConfig
        .maxNumOutputs;
      promptCopy.iterableConfig.maxNumOutputs = maxNumOutputs;
      setPrompt(promptCopy);
    };

    return (
      <Stack {...props}>
        <ZAccordionSingleItem
          title={'Output Options'}
          defaultOpen={stateOutputAccordionOpen}
          onOpen={() => {
            setOutputAccordionOpen(true);
          }}
          onClose={() => {
            setOutputAccordionOpen(false);
          }}
        >
          <Stack spacing={'24px'}>
            <BodySmMuted>
              One or more elements has an output of type iterable. Select "multi-prompt" if you'd like to make a request
              for more than one element of output from the iterable data.
            </BodySmMuted>

            <RadioGroup onChange={onOutputTypeChange} value={statePrompt.outputType}>
              <Stack spacing={5} direction="row">
                <ZRadioElementCard
                  value="string"
                  selected={statePrompt.outputType == 'string'}
                  tooltipText={'Combine the iterable data types data into a single request.'}
                >
                  <Stack>
                    <BodySmSemiBold>Single Prompt</BodySmSemiBold>
                  </Stack>
                </ZRadioElementCard>
                <ZRadioElementCard
                  value="iterable"
                  selected={statePrompt.outputType == 'iterable'}
                  tooltipText={'Create a multiple requests request using peices of output from the iterable data.'}
                >
                  <Stack>
                    <BodySmSemiBold>Multi Prompt</BodySmSemiBold>
                  </Stack>
                </ZRadioElementCard>
              </Stack>
            </RadioGroup>

            {statePrompt.outputType == 'iterable' && (
              <Stack>
                <BodySmMuted>Select which iterable data source to use.</BodySmMuted>
                <Select
                  placeholder={'Select option'}
                  value={statePrompt.iterableConfig.sourceId}
                  onChange={onIterableDataSourceChange}
                >
                  {statePrompt.elements
                    .filter((element) => element.outputType === 'iterable')
                    .map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </Stack>
            )}
          </Stack>
        </ZAccordionSingleItem>

        {statePrompt.elements
          .filter((element) => element.outputType === 'iterable')
          .map((element, index) => (
            <ZAccordionSingleItem
              title={'Iterable Data Config: ' + element.name}
              defaultOpen={statePrompt.iterableConfig[element.id] == undefined}
              key={index}
            >
              <IterableConfigElement element={element} index={index} />
            </ZAccordionSingleItem>
          ))}
      </Stack>
    );
  };

  function BlockTypeRadio({stateSelectedBlockType, onChange}) {
    return (
      <Stack spacing={'24px'}>
        <RadioGroup onChange={onChange} value={stateSelectedBlockType}>
          <Stack spacing={5} direction="row">
            <ZRadioElementCard
              value="inline"
              selected={stateSelectedBlockType == 'inline'}
              tooltipText={'The value will be inserted seamlessly at the end of the preceeding text'}
            >
              <Stack>
                <BodySmSemiBold>Inline</BodySmSemiBold>
              </Stack>
            </ZRadioElementCard>
            <ZRadioElementCard
              value="block"
              selected={stateSelectedBlockType == 'block'}
              tooltipText={'The value will be placed beneath the preceeding text as a new paragraph'}
            >
              <Stack>
                <BodySmSemiBold>Block</BodySmSemiBold>
              </Stack>
            </ZRadioElementCard>
          </Stack>
        </RadioGroup>
      </Stack>
    );
  }

  const ElementItem = ({item: element, index, isDraggableElement}) => {
    return (
      <Stack flex="1" mr={2} spacing={1}>
        <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Stack flex="1" mr={2} spacing={1} flexGrow={1} width={'100%'}>
            {element.content && element.id === 'dl_custom' && <BodySmSemiBold>{element.content}</BodySmSemiBold>}
            <BodySmSemiBold>{element.name + (element.blockType ? ' (' + element.blockType + ')' : '')}</BodySmSemiBold>
            <BodySmMuted>{element.description}</BodySmMuted>
          </Stack>
          {isDraggableElement && (
            <Stack direction={'row'} spacing={2} justifyContent={'end'}>
              <IconButton
                variant="outline"
                size="sm"
                onClick={() => onEditElement(element, index)}
                colorScheme="white"
                aria-label="Edit Element"
                icon={<Icon as={FiEdit} />}
              />
              <IconButton
                variant="outline"
                size="sm"
                onClick={() => onDeleteElement(element.id, index)}
                colorScheme="white"
                aria-label="Delete Element"
                icon={<Icon as={FiTrash} />}
              />
              <IconButton
                variant="outline"
                size="sm"
                onClick={() => onOpenElementEditor(element.id, element.type)}
                colorScheme="white"
                aria-label="Open Element Editor"
                icon={<Icon as={FiExternalLink} />}
              />
            </Stack>
          )}
        </Flex>

        <Flex pt={4} direction={'row'} wrap={'wrap'} gap={1}>
          <BodySmMuted>id: {element?.id}</BodySmMuted>
          <BodySmMuted>type: {element?.type}</BodySmMuted>
          <BodySmMuted>outputType: {element?.outputType}</BodySmMuted>
          <BodySmMuted>approxTokens: {element?.approxTokens ?? GPT.aproxNumTokens(element?.content)}</BodySmMuted>
          <BodySmMuted>promptDepth: {element?.promptDepth}</BodySmMuted>
          <BodySmMuted>numCustomInputs: {element?.customInputIds?.length}</BodySmMuted>
          {element.outputType == 'iterable' && (
            <BodySmMuted>iterableConfig: {JSON.stringify(element.iterableConfig)}</BodySmMuted>
          )}
          {element.config && <BodySmMuted>config: {JSON.stringify(element.config)}</BodySmMuted>}
        </Flex>
      </Stack>
    );
  };

  const ElementEditor = () => {
    const selectedElement = statePrompt.elements[stateEditElementIndex];
    const [stateSelectedElement, setSelectedElement] = useState(selectedElement);
    console.log('stateSelectedElement', stateSelectedElement ?? selectedElement);

    const defaultContent = selectedElement?.content || '';
    const [stateSelectedElementId, setSelectedElementId] = useState(selectedElement?.id);
    const [stateOpenElementAccordionIndex, setOpenElementAccordionIndex] = useState(-1); // index of section being edited

    const onAccordionOpen = (index = null) => {
      console.log('opening', index);
      setOpenElementAccordionIndex(index);
    };

    const DataOptions = () => {
      function onOptionSelected(dataId) {
        const allowedDuplicateIds = ['dl_custom', 'dl_context', 'dl_context_short'];
        if (!allowedDuplicateIds.includes(dataId) && statePrompt.elements.find((element) => element.id === dataId)) {
          console.log('This element has already been added to the prompt. No duplicate ids allowed.');
          return;
        }
        const element = stateDataOptions.find((object) => object.id === dataId);
        setSelectedElementId(dataId);
        setSelectedElement(element);
      }

      return (
        <ZAccordionSingleItem
          title={'Base Data Layer'}
          defaultOpen={stateOpenElementAccordionIndex == 0}
          index={0}
          onOpen={onAccordionOpen}
        >
          <Stack>
            <RadioGroup onChange={onOptionSelected} value={stateSelectedElementId}>
              {stateDataOptions.map((item, index) => (
                <ZRadioElementCard
                  mt={2}
                  key={item.id}
                  value={item.id}
                  selected={!!(stateSelectedElementId === item.id)}
                >
                  <ElementItem item={item} index={index} />
                </ZRadioElementCard>
              ))}
            </RadioGroup>
          </Stack>
        </ZAccordionSingleItem>
      );
    };

    const PromptOptions = () => {
      function onOptionSelected(promptId) {
        // check for circular references
        // if (statePrompt?.id === promptId || statePrompt?.dependencies?.includes(promptId)) {
        //   console.log("Circular references are not allowed.");
        //   return;
        // }

        if (statePrompt.elements.find((element) => element.id === promptId)) {
          console.log('This element has already been added to the prompt. No duplicate ids allowed.');
          return;
        }

        const element = statePromptOptions.find((prompt) => prompt.id === promptId);
        setSelectedElementId(promptId);
        setSelectedElement(element);
      }

      return (
        <ZAccordionSingleItem
          title={'Prompts'}
          defaultOpen={stateOpenElementAccordionIndex == 1}
          index={1}
          onOpen={onAccordionOpen}
        >
          <Stack>
            <RadioGroup onChange={onOptionSelected} value={stateSelectedElementId}>
              {statePromptOptions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, index) => (
                  <ZRadioElementCard
                    mt={2}
                    key={item.id}
                    value={item.id}
                    selected={!!(stateSelectedElementId === item.id)}
                  >
                    <ElementItem item={item} index={index} />
                  </ZRadioElementCard>
                ))}
            </RadioGroup>
          </Stack>
        </ZAccordionSingleItem>
      );
    };

    const CustomInputOptions = () => {
      function onOptionSelected(id) {
        if (statePrompt.elements.find((element) => element.id === id)) {
          console.log('This element has already been added to the prompt. No duplicate ids allowed.');
          return;
        }

        const element = stateInputOptions.find((input) => input.id === id);
        setSelectedElementId(id);
        setSelectedElement(element);
      }

      return (
        <ZAccordionSingleItem
          title={'Custom Inputs'}
          defaultOpen={stateOpenElementAccordionIndex == 2}
          index={2}
          onOpen={onAccordionOpen}
        >
          <Stack>
            <RadioGroup onChange={onOptionSelected} value={stateSelectedElementId}>
              {stateInputOptions
                .filter((item) => statePrompt.customInputIds.includes(item.id))
                .map((item, index) => (
                  <ZRadioElementCard
                    mt={2}
                    key={item.id}
                    value={item.id}
                    selected={!!(stateSelectedElementId === item.id)}
                  >
                    <ElementItem item={item} index={index} />
                  </ZRadioElementCard>
                ))}
            </RadioGroup>
          </Stack>
        </ZAccordionSingleItem>
      );
    };

    const ConfigOption = ({item, index}) => {
      const onSaveInput = (_, value) => {
        const elementCopy = structuredClone(stateSelectedElement);
        elementCopy.config[index].value = value;
        setSelectedElement(elementCopy);
      };

      return (
        <Stack direction={'row'}>
          {item.type === 'boolean' && <Switch size="sm" isChecked={item.value} />}
          {item.type === 'int' && (
            <ZInputSavable
              attributeName={item.name}
              placeholder={item.value}
              defaultValue={item.value}
              onSave={onSaveInput}
            />
          )}
        </Stack>
      );
    };

    const DLConfigOptions = ({config}) => {
      return (
        <Stack>
          <BodySmSemiBold>DL Config Options</BodySmSemiBold>
          {Object.keys(config).map((key, index) => (
            <ConfigOption item={config[key]} index={index} key={index} />
          ))}
        </Stack>
      );
    };

    return (
      <>
        <Drawer isOpen={isOpenAddBlock} placement="right" size={'xl'} onClose={onCloseAddBlock}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerHeader borderBottomWidth="1px">Edit a prompt element</DrawerHeader>

            <DrawerBody>
              <Stack spacing="24px">
                {stateSelectedElementId == 'dl_custom' && (
                  <CustomElement
                    onSaveCustomContent={(content) => {
                      console.log('saving content', content);
                      const elementCopy = {...stateSelectedElement};
                      elementCopy.content = content;
                      setSelectedElement(elementCopy);
                    }}
                    defaultContent={defaultContent}
                  />
                )}

                <BlockTypeRadio
                  stateSelectedBlockType={stateSelectedElement?.blockType}
                  onChange={(blockType) => {
                    console.log('saving block type', blockType);
                    const elementCopy = {...stateSelectedElement};
                    elementCopy.blockType = blockType;
                    setSelectedElement(elementCopy);
                  }}
                />
                {stateSelectedElement?.config && <DLConfigOptions config={stateSelectedElement?.config} />}
                <DataOptions />
                <PromptOptions />
                <CustomInputOptions />
              </Stack>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px" justifyContent={'left'}>
              <Button
                variant="outline"
                mr={3}
                size={'md'}
                onClick={() => {
                  onCloseAddBlock();
                  setEditElementIndex(null);
                }}
              >
                Cancel
              </Button>
              <ButtonPrimary
                colorScheme="blue"
                isDisabled={stateSelectedElementId == -1 || !stateSelectedElement?.blockType}
                onClick={() => {
                  onCloseAddBlock();
                  saveElement(stateSelectedElement);
                }}
              >
                Save
              </ButtonPrimary>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  const PromptMetadataInput = ({defaultPrompt, ...props}) => {
    function onSaveInput(attributeName, value) {
      console.log('saving', attributeName, value);
      // create a shallow copy
      let promptCopy = {...statePrompt};

      // handling nested references to attributes
      if (attributeName.split('_').length > 1) {
        console.log('modify array attribute');
        const [attribute, elementValue] = attributeName.split('_');
        const arraySet = new Set(promptCopy[attribute]);
        value ? arraySet.add(elementValue) : arraySet.delete(elementValue);
        promptCopy[attribute] = [...arraySet];
      } else {
        _.set(promptCopy, attributeName, value);
      }
      console.log('promptCopy', promptCopy);

      // update state
      setPrompt(promptCopy);
    }

    const checkboxElements = [
      {
        attributeName: 'tags_podcast',
        defaultChecked: defaultPrompt?.tags?.includes('podcast'),
        label: 'Podcast',
      },
      {
        attributeName: 'tags_informative',
        defaultChecked: defaultPrompt?.tags?.includes('informative'),
        label: 'Informative',
      },
      {
        attributeName: 'tags_entertaining',
        defaultChecked: defaultPrompt?.tags?.includes('entertaining'),
        label: 'Entertaining',
      },
      {
        attributeName: 'tags_singlehost',
        defaultChecked: defaultPrompt?.tags?.includes('singlehost'),
        label: 'Single Host',
      },
      {
        attributeName: 'tags_cohost',
        defaultChecked: defaultPrompt?.tags?.includes('cohost'),
        label: 'Co-hosted',
      },
      {
        attributeName: 'tags_guest',
        defaultChecked: defaultPrompt?.tags?.includes('guest'),
        label: 'Guest Host',
      },
    ];
    return (
      <ZAccordionSingleItem
        title={'Metadata'}
        defaultOpen={stateMetadataAccordionOpen}
        onOpen={() => {
          setMetadataAccordionOpen(true);
        }}
        onClose={() => {
          setMetadataAccordionOpen(false);
        }}
      >
        <Stack spacing={4} {...props}>
          <ZInputSavable
            attributeName={'name'}
            placeholder={'Prompt Name'}
            defaultValue={defaultPrompt?.name}
            onSave={onSaveInput}
          />
          <ZInputSavable
            attributeName={'displayName'}
            placeholder={'Prompt Display Name'}
            defaultValue={defaultPrompt?.displayName}
            onSave={onSaveInput}
          />
          <ZInputSavable
            attributeName={'description'}
            isTextArea={true}
            placeholder={'Description of the prompt'}
            defaultValue={defaultPrompt?.description}
            onSave={onSaveInput}
          />
          <ZCheckboxGroup elements={checkboxElements} onSave={onSaveInput} />
        </Stack>
      </ZAccordionSingleItem>
    );
  };

  const CustomInputs = ({...props}) => {
    const saveInputSelection = () => {
      console.log('save stateSelectedInputs', stateSelectedInputs);
      const promptCopy = {...statePrompt};
      promptCopy.customInputIds = stateSelectedInputs;
      setPrompt(promptCopy);
      setInputsAccordionOpen(false);
    };

    const [stateSelectedInputs, setSelectedInputs] = useState(statePrompt?.customInputIds || []);

    return (
      <ZAccordionSingleItem
        title={'Custom Inputs'}
        defaultOpen={stateInputsAccordionOpen}
        onOpen={() => {
          setInputsAccordionOpen(true);
        }}
        onClose={() => {
          setInputsAccordionOpen(false);
        }}
      >
        <Stack spacing={4} {...props}>
          {stateInputOptions.length === 0 && (
            <Button onClick={() => loadCustomInputOptions(false)}>Load Custom Input Options</Button>
          )}
          <ZCheckboxCardGroup
            elements={stateInputOptions}
            selectedElements={stateSelectedInputs}
            onChange={(selectedElementIds) => {
              setSelectedInputs(selectedElementIds);
              console.log('selectedElementIds', selectedElementIds);
            }}
            elementBodyComponent={({element}) => {
              return (
                <Stack spacing={0}>
                  <BodySm>Name: {element.name}</BodySm>
                  <BodySm>Description: {element.description}</BodySm>
                </Stack>
              );
            }}
          />
          <Stack direction={'row'} spacing={4} justifyContent={'end'}>
            {stateInputOptions?.length && stateInputOptions.length > 0 && (
              <ButtonPrimary onClick={saveInputSelection}>Save Input Selection</ButtonPrimary>
            )}
          </Stack>
        </Stack>
      </ZAccordionSingleItem>
    );
  };

  const DraggablePromptElement = forwardRef(({item, index, ...props}, ref) => {
    return (
      <Stack ref={ref} {...props}>
        <ZCard>
          <ElementItem item={item} index={index} isDraggableElement={true} />
        </ZCard>
      </Stack>
    );
  });

  return (
    <FlowContainer maxWidth={'1000px'}>
      <FlowHeader
        title={'Prompt Builder'}
        description={
          'Build a prompt for chatGPT using elements. You can draw from data made available through the transcript, or build on top of other prompts.'
        }
        rightComponent={
          <Stack>
            <Stack direction={'row'}>
              <ButtonPrimary onClick={() => console.log('prompt: ', statePrompt)}>Print Prompt</ButtonPrimary>
              <ButtonPrimary
                onClick={() => {
                  postPrompt();
                  propagateChangesToDependents();
                }}
              >
                Update Dependents & Publish
              </ButtonPrimary>
            </Stack>
            <Stack direction={'row'}>
              <ButtonPrimary onClick={saveDraft}>Save Draft</ButtonPrimary>
              <ButtonPrimary onClick={() => postPrompt()}>Publish Prompt</ButtonPrimary>
            </Stack>
          </Stack>
        }
      />
      {statePrompt && (
        <FlowBody>
          <PromptMetadataInput defaultPrompt={statePrompt} />
          <CustomInputs pb={10} />
          {isIterationPossible() && <IterableConfigOptions pb={10} />}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <Stack {...provided.droppableProps} ref={provided.innerRef} justifyContent={'start'}>
                  {statePrompt.elements.map((item, index) => (
                    <Draggable key={`item ${index}`} draggableId={`item ${index}`} index={index}>
                      {(provided) => (
                        <DraggablePromptElement
                          item={item}
                          index={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        ></DraggablePromptElement>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
          <Button leftIcon={<Icon as={FiPlus} />} onClick={addDefaultItem}>
            Add Item
          </Button>
          <ElementEditor />
        </FlowBody>
      )}
      <FlowFooter />
    </FlowContainer>
  );
};
