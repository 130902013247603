import {Flex, Icon, Stack} from '@chakra-ui/react';
import {useEffect, useMemo, useState} from 'react';
import {FiFilePlus} from 'react-icons/fi';
import {useNavigate, useSearchParams} from 'react-router-dom';

import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import ZCard from '../../components/abstraction_high/ZCard';
import {ZAccordion} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import {Body16Medium, BodySmSemiBold} from '../../components/common/TextStyle';
import {CreateProjectModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import episodeStore from '../../stores/episode-store';
import projectStore from '../../stores/project-store';
import {PROJECT, TEAM} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';

const Projects = () => {
  const [searchParams] = useSearchParams(); // "viewAll", "createNew", "privateProject"

  const zUserConfigSelectedProject = authStore((state) => state.userConfigSelectedProject);
  const zUser = authStore((state) => state.user);
  const zTeam = authStore((state) => state.team);
  const zSetTeam = authStore((state) => state.setTeam);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);

  const zProjects = projectStore((state) => state.projects);
  const zSetProjectsForUserSub = projectStore((state) => state.setProjectsForUserSub);
  const zAddProjectsForUserSub = projectStore((state) => state.addProjectsForUserSub);
  const zAddProject = projectStore((state) => state.addProject);
  const zSetProjects = projectStore((state) => state.setProjects);
  const zProject = projectStore((state) => state.project);
  const zSetProject = projectStore((state) => state.setProject);
  const zNukeProjectStore = projectStore((state) => state.nukeProjectStore);
  const zSetEpisodes = episodeStore((state) => state.setEpisodes);

  const [stateCreateProjectModalProps, setCreateProjectModalProps] = useState({openThisModal: false});
  const navigate = useNavigate();

  const {projectApi, teamApi} = useAPI();
  const activeTeam = useMemo(() => (!zTeam ? null : TEAM.getActiveTeamMembers(zTeam)), [zTeam]);

  useEffect(() => {
    if (!zUser?.userSub) {
      return;
    }

    initialLoad();
  }, [zUser]);

  async function initialLoad() {
    zSetEpisodes([]); // reset episodes in episode store
    zNukeProjectStore(); // reset project store (doesn't reset projects array)

    const zProjects = await fetchProjects();
    if (!zProjects) {
      return;
    }

    initialProcesses(zProjects);
  }

  /**
   * @returns zProjects - a map { userSub: [projects]}
   */
  async function fetchProjects() {
    // if already loaded projects, don't load again
    if (Object.keys(zProjects ?? {}).length > 0) return zProjects;

    try {
      zSetLoading(true);

      // if part of a team, get projects for entire team
      if (zUser.teamId) {
        // get team
        const team = await teamApi.getTeam(zUser.teamId);
        if (!team || team.length == 0) {
          throw new Error('Error loading team');
        }

        zSetTeam(team);
        const activeTeam = TEAM.getActiveTeamMembers(team);

        // get projects for team
        const projects = await projectApi.batchGetProjects(activeTeam.map((teammate) => teammate.userSub));
        if (!projects) {
          throw new Error('Error loading projects');
        }

        zSetProjects(projects);
        return projects;
      } else {
        // if not part of a team, get projects for auth user
        const projects = await projectApi.getProjects(zUser.userSub, true);
        if (!projects) {
          throw new Error('Error loading projects');
        }

        zSetProjectsForUserSub(projects, zUser.userSub);
        return {[zUser.userSub]: projects};
      }
    } catch (e) {
      console.log('error fetching projects: ', e);
      zSetError('projectApi.getProjects()', {userSub: zUser.userSub, message: 'Error loading projects', error: e});
    } finally {
      zSetLoading(false);
    }
  }

  async function loadMoreProjects(userSub) {
    const moreProjects = await projectApi.getProjects(userSub, false);
    if (!moreProjects) return;

    zAddProjectsForUserSub(moreProjects, userSub);
  }

  /**
   * @typedef {{ [userSub: string]: [Project]}} ZProjects
   */

  /**
   * Initial processes to run after projects are loaded
   *
   * @param {ZProjects} zProjects
   * @returns
   */
  async function initialProcesses(zProjects) {
    if (searchParams.get('createNew')) {
      openCreateProjectModal();
      return;
    } else if (searchParams.get('privateProject')) {
      // create private project, if one is not already created
      if (zProjects[zUser.userSub].find((project) => project.name === 'Private Project')) {
        console.log('Private project already exists.');
        return;
      }

      const isSuccess = await createNewProject('Private Project', {defaultTemplateId: 'private_transcript'});
      if (!isSuccess) {
        openCreateProjectModal();
      }
      return;
    } else if ((zProjects[zUser.userSub]?.length ?? 0) == 0) {
      // if user has 0 projects, init onboarding flow
      await createNewProject('Default Project');
      return;
    } else if (searchParams.get('viewAll') || window.location.pathname.includes('/projects')) {
      console.log('Viewing all projects.');
      return;
    }

    // if project alreday set, do nothing
    if (zProject) {
      return;
    }

    // if user is part of a team, don't auto navigate
    if (zUser.teamId) {
      return;
    }

    // Auto Navigate: if user has projects, navigate to the saved zUser.configSelectedProject || first project
    const firstProject = zProjects[zUser.userSub][0];
    zUserConfigSelectedProject
      ? navigateToProject(zProjects[zUser.userSub].find((project) => project.projectId === zUserConfigSelectedProject))
      : navigateToProject(firstProject);
  }

  useEffect(() => {
    if (Object.keys(zProjects ?? {}).length != 0) {
      initialProcesses(zProjects);
    }
  }, [searchParams]);

  function openCreateProjectModal() {
    setCreateProjectModalProps({
      openThisModal: true,
      onSubmit: createNewProject,
      onCloseCallback: () => setCreateProjectModalProps({openThisModal: false}),
    });
  }

  async function createNewProject(name, data = {}) {
    zSetLoading(true);

    try {
      const project = await projectApi.postProject(name, data);
      if (!project.projectId) throw new Error('Error creating project');
      console.log('project created: ', project);
      zAddProject(project, zUser.userSub);
      navigateToProject(project);
    } catch (e) {
      zSetError('createNewProject', {userSub: zUser.userSub, e});
    } finally {
      zSetLoading(false);
    }
  }

  function navigateToProject(project) {
    if (!project) return;
    zSetProject(project);
    navigate(`/projects/${project.projectId}`);
  }

  return (
    <FlowContainer>
      <FlowHeader
        title={'Projects'}
        description={
          'Choose an existing project or create a new one. Your settings are saved per project for easy management of multiple workflows.'
        }
      />
      <FlowBody>
        <Stack mt={8}>
          <ProjectListContainer
            zProjects={zProjects}
            activeTeam={activeTeam}
            projectApi={projectApi}
            navigateToProject={navigateToProject}
            openCreateProjectModal={openCreateProjectModal}
            loadMoreProjects={loadMoreProjects}
          />

          <CreateProjectModal {...stateCreateProjectModalProps} />
        </Stack>
      </FlowBody>
      <FlowFooter />
    </FlowContainer>
  );
};

const ProjectItem = ({project}) => (
  <Flex maxW={'100%'}>
    <Body16Medium overflow="hidden" whiteSpace={'nowrap'} textOverflow="ellipsis" maxWidth={'100%'}>
      {project.name}
    </Body16Medium>
  </Flex>
);

const ProjectItemShell = ({bgColor, acronym, condensed, isSquare, useIcon, ...props}) => (
  <ZCard variant={'elevated'} p={0} height={16} growVertical={true} cursor={'pointer'} {...props}>
    <Flex height={'100%'} gap={4} maxW={'100%'}>
      <Stack
        style={{aspectRatio: 1 / 1}}
        borderRadius={'10px'}
        height={'100%'}
        overflow={'none'}
        backgroundColor={bgColor}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {useIcon ? useIcon : <BodySmSemiBold color={'white'}>{acronym}</BodySmSemiBold>}
      </Stack>
      {!condensed && (
        <Stack justifyContent={'center'} maxW={'100%'}>
          {props.children}
        </Stack>
      )}
    </Flex>
  </ZCard>
);

const ProjectList = ({userSub, zProjects, projectApi, navigateToProject, openCreateProjectModal, loadMoreProjects}) => {
  const zUser = authStore((state) => state.user);

  return (
    <Stack spacing={2}>
      {/* <Divider /> */}
      <Flex flexWrap={'wrap'} gap={2} width={'100%'}>
        {PROJECT.getSortedProjects(PROJECT.filterArchivedProjects(zProjects[userSub]))?.map((project, index) => (
          <ProjectItemShell
            onClick={() => {
              navigateToProject(project);
            }}
            key={project.projectId}
            bgColor={PROJECT.getProjectColor(project.projectId)}
            flex={index % 3 === 2 ? '1' : '0 0 calc(33.33% - 15px)'}
            minWidth={'30%'}
            acronym={PROJECT.getProjectAcronym(project.name)}
          >
            <ProjectItem project={project} />
          </ProjectItemShell>
        ))}

        {userSub === zUser.userSub && (
          <ProjectItemShell
            onClick={openCreateProjectModal}
            bgColor={'gray.100'}
            useIcon={<Icon as={FiFilePlus} boxSize={6} />}
            flex={(zProjects[userSub]?.length ?? 0) % 3 === 2 ? '1' : '0 0 calc(33.33% - 15px)'}
            minWidth={'30%'}
          >
            <Stack justifyContent={'center'} width={'100%'}>
              <Body16Medium>{'Create New Project'}</Body16Medium>
            </Stack>
          </ProjectItemShell>
        )}
      </Flex>
      {/* <Divider /> */}
      {projectApi.canLoadMoreProjects(userSub) && (
        <Flex justifyContent={'end'}>
          <ButtonSecondary onClick={() => loadMoreProjects(userSub)}>Load more...</ButtonSecondary>
        </Flex>
      )}
    </Stack>
  );
};

const ProjectListContainer = ({
  zProjects,
  activeTeam,
  projectApi,
  navigateToProject,
  openCreateProjectModal,
  loadMoreProjects,
}) => {
  const zUser = authStore((state) => state.user);
  const zSetError = appStore((state) => state.setError);

  if (!zProjects || Object.keys(zProjects).length == 0) {
    return null; // waiting for zProjects to load
  }

  // if only one element, return the ProjectList without the ZAccordion
  if (Object.keys(zProjects).length == 1) {
    return (
      <ProjectList
        userSub={zUser.userSub}
        zProjects={zProjects}
        projectApi={projectApi}
        navigateToProject={navigateToProject}
        openCreateProjectModal={openCreateProjectModal}
        loadMoreProjects={loadMoreProjects}
      />
    );
  }

  // if more than one element, displaying projects for a team
  if (!activeTeam) {
    return null; // waiting for activeTeam to load
  }

  if (activeTeam.length == 0 || activeTeam.length !== Object.keys(zProjects).length) {
    zSetError('ProjectListContainer', {zProjects, activeTeam});
    return null;
  }

  const nameWithApostrophe = (name) => (name.endsWith('s') ? `${name}'` : `${name}'s`);

  return (
    <ZAccordion
      maxHeight={'60vh'}
      overflowY={'scroll'}
      defaultOpen={true}
      panelPx={0}
      buttonDefaultBg={'gray.50'}
      elements={activeTeam.map((teammate) => {
        return {
          title: teammate.userSub === zUser.userSub ? 'Your Projects' : nameWithApostrophe(teammate.name) + ' Projects',
          children: (
            <ProjectList
              userSub={teammate.userSub}
              zProjects={zProjects}
              projectApi={projectApi}
              navigateToProject={navigateToProject}
              openCreateProjectModal={openCreateProjectModal}
              loadMoreProjects={loadMoreProjects}
            />
          ),
        };
      })}
    />
  );
};

export {Projects, ProjectItem, ProjectItemShell};
