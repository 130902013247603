import {Button} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function FancyButton({
  rightIcon,
  leftIcon,
  textColor = 'white',
  size = 'md',
  colorScheme = 'blue',
  sx,
  style,
  onClick,
  children,
  ...buttonProps
}) {
  return (
    <Button
      rightIcon={rightIcon}
      leftIcon={leftIcon}
      size={size}
      colorScheme={colorScheme}
      onClick={onClick}
      sx={{
        '.chakra-button__icon *': {color: textColor},

        color: textColor,
        backgroundImage: '/buttonbg.jfif',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        ':hover': {
          backgroundImage: '/buttonbg.jfif',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(85%)',
          transition: 'all .2s',
        },

        ':active': {
          backgroundImage: '/buttonbg.jfif',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(65%)',
          transition: 'all .2s',
        },
        ...sx,
      }}
      style={style}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

FancyButton.propTypes = {
  rightIcon: PropTypes.element,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func.isRequired,

  textColor: PropTypes.string,
  size: PropTypes.string,
  colorScheme: PropTypes.string,
  sx: PropTypes.object,
  style: PropTypes.object,
};
