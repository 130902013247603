import {Flex, Icon, Image, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Stack} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {BsLightningCharge} from 'react-icons/bs';
import {FiCheckCircle, FiInfo, FiSettings} from 'react-icons/fi';

import IconExperimentBlue from '../../assets/icons/experiment-blue.png';
import IconWaveColorful from '../../assets/icons/wave-colorful.png';
import PricingUtils from '../../utils/pricing-utils';
import ButtonPrimary from '../abstraction_high/ButtonPrimary';
import ButtonSecondary from '../abstraction_high/ButtonSecondary';
import ZCard from '../abstraction_high/ZCard';
import {Body14, Body14Light, Body15Bold, Body16Bold, H35} from '../common/TextStyle';
import {ConfirmModal} from '../modals/Modal';

export const VerticalPricingCard = ({
  defaultCTA,
  onClick,
  name,
  description,
  price,
  monthlyCredits,
  image,
  ...props
}) => {
  return (
    <Stack height={350}>
      <ZCard height="100%" pt={'40px'} pb={'40px'} pl={'28px'} pr={'28px'} growVertical={true}>
        <Flex direction={'column'} flexGrow={1} justifyContent={'space-between'}>
          <Flex direction={'column'} flexGrow={1}>
            <Flex gap={0} alignItems={'end'}>
              <Stack width={'69px'} height={'69px'} p={'12px'} mr={'12px'} borderRadius={'10px'} bg={'brand.bg'}>
                <Image src={image} />
              </Stack>

              <Stack justifyContent={'end'} spacing={0}>
                <Body16Bold>{name}</Body16Bold>
                <Flex gap={0}>
                  <H35>${price}</H35>
                  <Stack flexGrow={1} justifyContent={'end'}>
                    <Body14Light>&nbsp;/ month</Body14Light>
                  </Stack>
                </Flex>
              </Stack>
            </Flex>

            <Stack mt={'27px'} spacing={0}>
              <Flex gap={1} alignItems={'center'}>
                <Stack>
                  <Icon as={FiCheckCircle} color={'brand.primary'} />
                </Stack>
                <Body14>{monthlyCredits} uploads / month</Body14>
              </Flex>

              <Body14Light pt={'9px'}>{description}</Body14Light>
            </Stack>
          </Flex>
          <Flex direction={'column'} flexGrow={1} justifyContent={'end'}>
            <ButtonPrimary wrap={true} onClick={onClick} skipTextWrap={true}>
              <Body15Bold color={'white'}>{defaultCTA}</Body15Bold>
            </ButtonPrimary>
          </Flex>
        </Flex>
      </ZCard>
    </Stack>
  );
};

export const HorizontalPricingCard = ({onClick, name, description, image, ...props}) => (
  <ZCard pt={'40px'} pb={'40px'} pl={'28px'} pr={'28px'} growVertical={true} {...props}>
    <Flex direction={'column'} flexGrow={1} justifyContent={'space-between'}>
      <Flex direction={'column'} flexGrow={1}>
        <Flex justifyContent={'space-between'} flexGrow={1}>
          <Flex gap={0} alignItems={'end'}>
            <Stack width={'69px'} height={'69px'} p={'12px'} mr={'12px'} borderRadius={'10px'} bg={'brand.bg'}>
              <Image src={image} />
            </Stack>

            <Stack justifyContent={'end'} spacing={0}>
              <Body16Bold>{name}</Body16Bold>
              <Flex gap={0}>
                <Body14Light pt={'9px'}>{description}</Body14Light>
              </Flex>
            </Stack>
          </Flex>

          <Flex direction={'column'} flexGrow={1} justifyContent={'end'} alignItems={'end'}>
            <ButtonPrimary wrap={true} onClick={onClick} skipTextWrap={true}>
              <Body15Bold color={'white'}>contact us</Body15Bold>
            </ButtonPrimary>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </ZCard>
);

export const BuildYourOwnPlanCard = ({
  defaultCTA,
  onClick,
  isAnnual,
  callbackSetCredits = () => {},
  newPriceTrigger,
  ...props
}) => {
  const [statePrice, setPrice] = useState(30);
  const [stateCredits, setCredits] = useState(10);

  const [stateDisplayInfoModal, setDisplayInfoModal] = useState(false);

  useEffect(() => {
    setPrice(newPriceTrigger);
  }, [newPriceTrigger]);

  const name = 'Build Your Own';
  const image = IconExperimentBlue;

  return (
    <ZCard pt={'40px'} pb={'40px'} pl={'28px'} pr={'28px'} growVertical={true} {...props}>
      <Flex direction={'column'} flexGrow={1} justifyContent={'space-between'}>
        <Flex direction={'column'} flexGrow={1}>
          <Flex justifyContent={'space-between'} flexGrow={1}>
            <Flex gap={0} alignItems={'end'} flexGrow={1}>
              <Stack width={'69px'} height={'69px'} p={'12px'} mr={'12px'} borderRadius={'10px'} bg={'brand.bg'}>
                <Image src={image} />
              </Stack>

              <Stack justifyContent={'end'} spacing={0} width={'100%'}>
                <Flex alignItems={'center'} gap={1}>
                  <Body16Bold>{name}</Body16Bold>
                  <Icon
                    w={'.81rem'}
                    h={'.81rem'}
                    color={'black'}
                    as={FiInfo}
                    _hover={{color: 'playful.blue'}}
                    onClick={() => {
                      setDisplayInfoModal(true);
                    }}
                  />
                  <ConfirmModal
                    positiveText="Got it!"
                    neutralText={null}
                    positiveCallback={() => {}}
                    titleText={'Building a Custom Plan'}
                    bodyText={
                      "Select the exact number of uploads you'll need each month by dragging the slider.\n\nEven if you already have an active subscription, you can easily switch to a custom plan and get immediate access to the uploads you need."
                    }
                    openThisModal={stateDisplayInfoModal}
                    onCloseCallback={() => setDisplayInfoModal(false)}
                    closeOnEsc={true}
                    modalSize="xl"
                  />
                </Flex>
                <Flex gap={'12px'}>
                  <Flex gap={0}>
                    <H35 minWidth={'4.5rem'}>${statePrice}</H35>
                    <Stack flexGrow={1} justifyContent={'end'}>
                      <Body14Light>&nbsp;/ month</Body14Light>
                    </Stack>
                  </Flex>

                  <Slider
                    aria-label="custom_credits_slider"
                    width={'15vw'}
                    min={2}
                    max={100}
                    defaultValue={2}
                    value={stateCredits}
                    onChange={(v) => {
                      callbackSetCredits(v);
                      setCredits(v);
                      setPrice(v * (isAnnual ? 3 : 5));
                    }}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>

                  <Flex gap={0}>
                    <H35>{stateCredits}</H35>
                    <Stack justifyContent={'end'}>
                      <Body14Light>&nbsp;uploads / month</Body14Light>
                    </Stack>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>

            <Flex direction={'column'} justifyContent={'end'} alignItems={'end'}>
              <ButtonPrimary wrap={true} onClick={onClick} skipTextWrap={true}>
                <Body15Bold color={'white'}>{defaultCTA}</Body15Bold>
              </ButtonPrimary>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ZCard>
  );
};

/**
 *
 * @param {PricingUtils} memoPricingUtils
 * @returns
 */
export const CurrentSubscriptionCard = ({
  zSubscription,
  zUser,
  zTeam,
  memoPricingUtils,
  stateIsAnnualBilling,
  ...props
}) => {
  // if (!SUBSCRIPTION.hasActiveSub(zSubscription) && zSubscription?.meteredSubStatus !== "active") return null;
  if (!zSubscription?.sub_status || zSubscription?.sub_status == 'unactivated') return null;

  const name = 'Your Current Plan'; // SUBSCRIPTION.getProductNamePretty(zSubscription.product, zSubscription.price_id);
  const description = memoPricingUtils.getSubscriptionUsageInfo();
  const image = IconWaveColorful;
  const numCredits = memoPricingUtils.getNumCreditsInPlan();

  function manageSubscription() {
    const url = process.env.REACT_APP_DEV_ENV
      ? 'https://billing.stripe.com/p/login/test_eVa28O31zgobeg8eUU'
      : 'https://billing.stripe.com/p/login/4gwfZK1OC81ua1W3cc';
    window.open(url, '_newtab');
  }

  return (
    <ZCard pt={'40px'} pb={'40px'} pl={'28px'} pr={'28px'} growVertical={true} {...props}>
      <Flex direction={'column'} flexGrow={1}>
        <Flex justifyContent={'space-between'} flexGrow={1}>
          <Stack justifyContent={'space-between'} height={'100%'} width={'100%'}>
            <Stack spacing={'12px'} pb={'20px'}>
              <Flex gap={0} alignItems={'end'} flexGrow={1}>
                <Stack justifyContent={'end'} spacing={0} width={'100%'}>
                  <Flex gap={0} alignItems={'end'}>
                    <Stack
                      width={'69px'}
                      height={'69px'}
                      p={'12px'}
                      mr={'12px'}
                      borderRadius={'10px'}
                      bg={'brand.bg'}
                      flexShrink={0}
                    >
                      <Image src={image} />
                    </Stack>

                    <Stack justifyContent={'end'} spacing={0}>
                      <Body16Bold>{name}</Body16Bold>
                      <Flex gap={0}>
                        <Body14Light pt={'0px'}>{description}</Body14Light>
                      </Flex>
                    </Stack>
                  </Flex>

                  {/* <Progress value={memoPricingUtils.getSubUsageProgressValue()} borderRadius="8px" colorScheme="blue" size="xs" /> */}
                  <Flex gap={0}></Flex>
                </Stack>
              </Flex>
              {memoPricingUtils.getMeteredUsageInfo() && (
                <Body14Light pt={'0px'}>{memoPricingUtils.getMeteredUsageInfo()}</Body14Light>
              )}
            </Stack>

            <Flex justifyContent={'space-between'} alignItems={'end'}>
              <Flex gap={'6px'}>
                <Stack width={'28px'} height={'28px'} p={'6px'} borderRadius={'5px'} bg={'playful.purple'}>
                  <Icon height={'100%'} width={'100%'} as={BsLightningCharge} color={'white'} />
                </Stack>
                <Flex
                  pr={'.5rem'}
                  pl={'.5rem'}
                  pt={'.25rem'}
                  pb={'.25rem'}
                  bg={'rgba(14, 188, 179,.09)'}
                  borderRadius={'5px'}
                  height={'fit-content'}
                  width={'fit-content'}
                >
                  <Body14Light>{zSubscription.sub_status}</Body14Light>
                </Flex>
                <Flex
                  pr={'.5rem'}
                  pl={'.5rem'}
                  pt={'.25rem'}
                  pb={'.25rem'}
                  bg={'rgba(65,69,208,.09)'}
                  borderRadius={'5px'}
                  height={'fit-content'}
                  width={'fit-content'}
                >
                  <Body14Light>{zSubscription.sub_interval !== 'year' ? 'monthly' : 'yearly'}</Body14Light>
                </Flex>
              </Flex>
              <Flex direction={'column'} flexGrow={1} justifyContent={'end'} alignItems={'end'}>
                <ButtonSecondary wrap={true} onClick={() => manageSubscription()} leftIcon={<Icon as={FiSettings} />}>
                  manage
                </ButtonSecondary>
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </Flex>
    </ZCard>
  );
};
