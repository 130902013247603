import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';

import SessionStorageUtils from '../../utils/session-storage-utils';

const Signup = () => {
  const {loginWithRedirect, isAuthenticated} = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const preAuthPath = searchParams.get(SessionStorageUtils.Keys.PRE_AUTH_PATH);

  const navigateToPage = (urlString) => {
    const url = new URL(urlString, 'http://dummy.com'); // dummy domain to create URL object
    const {pathname, search} = url;

    if (search) {
      const searchParams = createSearchParams(search);
      navigate({
        pathname: pathname,
        search: searchParams.toString(),
      });
    } else {
      navigate(pathname);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      preAuthPath ? navigateToPage(preAuthPath) : navigate('/');
    } else {
      if (preAuthPath) SessionStorageUtils.setPreAuthPath(preAuthPath);
      loginWithRedirect({screen_hint: 'signup', redirectUri: window.location.origin + '/'});
    }
  }, [isAuthenticated, preAuthPath, navigate]);

  return <></>;
};

export default Signup;
