import {Radio, Stack, Text, Tooltip} from '@chakra-ui/react';

const ZRadioElementCard = ({value, selected, children, tooltipText, isDisabled, defaultChecked, ...props}) => {
  const selectedStyles = {
    borderColor: 'blue.500',
    borderWidth: '2px',
    backgroundColor: 'blue.50',
    ':hover': {borderColor: 'blue.600'},
  };

  const styles = selected ? selectedStyles : null;

  return (
    <Tooltip isDisabled={!tooltipText} label={tooltipText} placement="top-start" borderRadius={'10px'}>
      <Stack
        width={'100%'}
        direction="row"
        as="label"
        cursor="pointer"
        p="21px"
        rounded="lg"
        sx={{
          padding: '21px',
          borderColor: 'gray.200',
          borderWidth: '1px',
          ':hover': {borderColor: 'gray.300'},
          ...styles,
        }}
        {...props}
      >
        <Stack flexGrow={1}>{children}</Stack>

        <Radio
          isDisabled={isDisabled}
          isChecked={value}
          alignSelf="start"
          value={value}
          defaultChecked={defaultChecked}
        ></Radio>
      </Stack>
    </Tooltip>
  );
};

export default ZRadioElementCard;
