import {Icon} from '@chakra-ui/react';
import {debounce} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {BsBodyText, BsSoundwave} from 'react-icons/bs';
import {FiHome, FiLayers, FiPlus, FiRepeat} from 'react-icons/fi';
import {MdOutlineAdd, MdOutlineSlowMotionVideo} from 'react-icons/md';
import {useNavigate, useParams} from 'react-router-dom';

import ZTabs, {TabIcons} from '../../components/abstraction_high/ZTabs';
import {displayErrorToast} from '../../components/common/Structural';
import {TemplateCreateModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import projectStore from '../../stores/project-store';
import {EPISODE, GPT, TEMPLATE_V2} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import {Loader} from '../../utils/loader';
import {Clips} from './Clips';
import {Enhance} from './Enhance';
import EpHome from './EpHome';
import EpModal from './EpModal';
import {EpPrefs} from './EpPrefs';
import {EpSection, EpSectionContainer} from './EpSection';
import {Transcript} from './Transcript';

export const Episode = () => {
  const navigate = useNavigate();
  const {projectId, episodeId, tabId} = useParams();

  const episodeUUID = projectId + '___' + episodeId;

  const zSetError = appStore((state) => state.setError);
  const zSetLoading = appStore((state) => state.setLoading);
  const zProject = projectStore((state) => state.project);
  const zSetProject = projectStore((state) => state.setProject);

  const zEpisode = episodeStore((state) => state.episode);
  const zSetEpisode = episodeStore((state) => state.setEpisode);
  const zSetEpisodePollingPaused = episodeStore((state) => state.setEpisodePollingPaused);
  const zUpdateEpisode = episodeStore((state) => state.updateEpisode);
  const zProgrammaticallySelectTabByKey = episodeStore((state) => state.programmaticallySelectTabByKey);
  const zSetProgrammaticallySelectTabByKey = episodeStore((state) => state.setProgrammaticallySelectTabByKey);
  const zSelectedTabKey = episodeStore((state) => state.selectedTabKey); // the key that has been selected
  const zSetSelectedTabKey = episodeStore((state) => state.setSelectedTabKey);
  const zTriggerInitTabElements = episodeStore((state) => state.triggerInitTabElements);
  const zSetTriggerInitTabElements = episodeStore((state) => state.setTriggerInitTabElements);
  const zForceShowEpPrefs = episodeStore((state) => state.forceShowEpPrefs);
  const zResetTemplateBlockSelected = episodeStore((state) => state.resetTemplateBlockSelected);
  const zSetStatus = episodeStore((state) => state.setStatus);
  const zSetTranscript = episodeStore((state) => state.setTranscript);
  const zTranscriptSaveable = episodeStore((state) => state.transcriptSaveable);
  const zSetTranscriptSaveable = episodeStore((state) => state.setTranscriptSaveable);
  const zSetTranscriptJobProgress = episodeStore((state) => state.setTranscriptJobProgress);
  const zSetSaving = episodeStore((state) => state.setSaving);
  const zSaveableTemplates = episodeStore((state) => state.saveableTemplates);
  const zClearSaveableTemplates = episodeStore((state) => state.clearSaveableTemplates);
  const zTemplateMeta = episodeStore((state) => state.templateMeta);
  const zTemplates = episodeStore((state) => state.templates);
  const zSetTemplateMeta = episodeStore((state) => state.setTemplateMeta);
  const zSetTemplates = episodeStore((state) => state.setTemplates);
  const zAddTemplate = episodeStore((state) => state.addTemplate);
  const zGptReq = episodeStore((state) => state.gptReq);
  const zSetGptReq = episodeStore((state) => state.setGptReq);
  const zBatchUpdateGptReqPromptsFinished = episodeStore((state) => state.batchUpdateGptReqPromptsFinished);
  const zUpdateGptReqDataMap = episodeStore((state) => state.updateGptReqDataMap);
  const zUserDefinedNewPrompt = episodeStore((state) => state.userDefinedNewPrompt);
  const zSetUserDefinedNewPrompt = episodeStore((state) => state.setUserDefinedNewPrompt);
  const zUserModifiedPrompt = episodeStore((state) => state.userModifiedPrompt);
  const zSetUserModifiedPrompt = episodeStore((state) => state.setUserModifiedPrompt);
  const zPromptModHistory = episodeStore((state) => state.promptModHistory);
  const zSetPromptModHistory = episodeStore((state) => state.setPromptModHistory);
  const zSetRetryingFailedRequests = episodeStore((state) => state.setRetryingFailedRequests);
  const zFormInputs = episodeStore((state) => state.formInputs);
  const zSetFormInputs = episodeStore((state) => state.setFormInputs);
  const zSetEpInputs = episodeStore((state) => state.setEpInputs);
  const zTriggerFormInputRefresh = episodeStore((state) => state.triggerFormInputRefresh);
  const zNukeEpisodeStore = episodeStore((state) => state.nukeEpisodeStore);
  const zSetEnhanceJob = episodeStore((state) => state.setEnhanceJob);

  const [stateTabElements, setTabElements] = useState([]);
  const [stateEpModalProps, setEpModalProps] = useState(null);
  const [stateModalTemplateCreateProps, setModalTemplateCreateProps] = useState(null);

  const refLoaders = useRef({});

  const {
    episodeApi,
    projectApi,
    templateApi,
    gptApi,
    templateV2Api,
    promptModApi,
    transcriptApi,
    inputApi,
    epExportApi,
    lambdaApi,
    enhanceApi,
  } = useEpisodeDependentAPIs();

  function isValidEpisodeUrl() {
    const isValidTimestamp = (timestamp) => timestamp && timestamp.length === 13 && !isNaN(timestamp);
    return projectId && episodeId && projectId.split('_').length === 2 && isValidTimestamp(episodeId);
  }

  // Was this episode created using template V1, then converted to V2?
  // If so, disable prompt studio and prevent this template from being reused in future episodes
  const memoWasTemplateOriginallyV1 = useMemo(() => zEpisode?.gptTemplateId, [zEpisode?.gptTemplateId]);

  // EPISODE
  useEffect(() => {
    // check for valid episode url
    if (!isValidEpisodeUrl()) {
      displayErrorToast(
        'Invalid episode url. Please check the URL and try again, or contact support if you think this is a mistake.',
        {duration: null},
      );
      return;
    }

    initialLoad();

    return () => {
      stopAllLoaders();
      zNukeEpisodeStore();
    };
  }, []);

  const stopAllLoaders = () => Object.values(refLoaders.current).forEach((loader) => loader.stop());

  async function initialLoad() {
    zSetLoading(true);

    // get project
    if (!zProject) {
      const project = await projectApi.getProject(projectId);
      zSetProject(project);
    }

    // poll episode until complete
    pollEpisode();
  }

  function pollEpisode() {
    zSetEpisodePollingPaused(false);

    refLoaders.current['episode']?.stop(); // resetting loader if one already exists

    const loader = new Loader(() => episodeApi.getEpisode(), {timeout: 45 * 60 * 1000}, 'pollEpisode'); // 45 min timeout
    loader.initialResponse((episode) => {
      zSetLoading(false);

      if (!episode) {
        displayErrorToast(
          'Episode not found. Please check the URL and try again, or contact support if the issue persists.',
          {duration: null},
        );
        loader.stop();
        return;
      }

      zSetEpInputs(episode.inputsCustom ?? {});
    });
    loader.isDone((episode) => {
      if (!episode) return true;

      const status = EPISODE.getStatus(episode);
      updateEpisodeStore(episode, status);
      if (status.errorStages?.length ?? 0 > 0) zSetError('pollEpisode', {errorStages: status.errorStages});
      return (
        status.allStagesComplete == true ||
        (status.errorStages?.length ?? 0 > 0) ||
        episode.errorFileInvalid ||
        episodeStore.getState().episodePollingPaused
      );
    });
    loader.done((episode) => {
      console.log('episode poll done');
      const status = EPISODE.getStatus(episode);
      updateEpisodeStore(episode, status);
    });
    loader.start();

    refLoaders.current['episode'] = loader; // adding loader reference to be removed on unmount
  }

  function updateEpisodeStore(episode, status) {
    zSetEpisode(episode);
    zSetStatus(status);
  }

  useEffect(() => {
    if (!zEpisode) return;
    transcriptApi.setFileName(zEpisode.fileName);
    transcriptApi.setTranscriptionMethod(zEpisode.stages.stageTranscribe.config.method);
  }, [zEpisode?.fileName]);

  // TRANSCRIPT
  useEffect(() => {
    if (!zEpisode || !zEpisode.resTranscribeJobId || zEpisode.resTranscriptUrl) {
      return;
    }

    // polling for job success
    refLoaders.current['transcript']?.stop(); // resetting loader if one already exists

    const loader = new Loader(
      () => transcriptApi.getTranscriptionJob(zEpisode.resTranscribeJobId),
      {timeout: 45 * 60 * 1000},
      'pollTranscript',
    ); // 45 min timeout
    loader.isDone((job) => job?.status == 'error' || job?.status == 'completed'); // queued, processing, completed, error
    loader.progress((job) => updateTranscriptJobProgress(job));
    loader.done((job) => updateTranscriptJobProgress(job));
    loader.start();

    refLoaders.current['transcript'] = loader; // adding loader reference to be removed on unmount
  }, [zEpisode?.resTranscribeJobId]);

  function updateTranscriptJobProgress(job) {
    if (job?.status == 'error') {
      zSetTranscriptJobProgress(0);
      if (!zEpisode.errorFileInvalid) zSetError('TJOBID', job); // only post and display the error if the fileInvalid is not already set
      return;
    } else if (job?.status == 'completed') {
      if (episodeStore.getState().transcriptJobProgress == 100) return; // already set to 100
      zSetTranscriptJobProgress(95); // 100 when url available
      return;
    } else if (job?.status == 'queued') {
      zSetTranscriptJobProgress(10);
      return;
    } else if (job?.status == 'processing') {
      zSetTranscriptJobProgress(15);
      return;
    } else if (job) {
      zSetTranscriptJobProgress(5);
      return;
    } else {
      zSetTranscriptJobProgress(0);
      return;
    }
  }

  useEffect(() => {
    if (!zEpisode || !zEpisode.resTranscriptUrl) {
      return;
    }

    transcriptApi.setTranscriptUrl(zEpisode.resTranscriptUrl);
    zSetTranscriptJobProgress(100);

    // check if episode polling should be paused
    if (!EPISODE.getHasUserSubmittedInputs(zEpisode, zFormInputs)) {
      console.log('EPISODE: pause polling. waiting on preference submission');
      zSetEpisodePollingPaused(true); // pause episode poll until user submits inputs
    }

    async function loadTranscript() {
      const transcript = await transcriptApi.getDataFromS3();
      if (!transcript) {
        zSetError('ST1 transcript', 'transcript not found');
        return;
      }
      zSetTranscript(transcript);
    }

    loadTranscript();
  }, [zEpisode?.resTranscriptUrl]);

  // INPUTS
  useEffect(() => {
    if (!zFormInputs || !zFormInputs.length) return; // don't show modal if form inputs not set yet, or empty
    if (!zEpisode) return; // this should never happen, but it did once
    if (zEpisode.gptInvokedByUser) return; // don't show modal if user has already submitted preferences

    // open modal if inputs not set yet
    setEpModalProps({showModal: true});
  }, [zFormInputs]);

  async function fetchAndSetFormInputs(inputIds, formInputIdsRequired) {
    if (zFormInputs?.length) return; // Return if form inputs set beecause they don't change. They can be loaded either by template, or by gptRequest

    const formInputs = await inputApi.batchGetInputs(inputIds);
    for (const formInput of formInputs) {
      formInput.required = formInputIdsRequired.includes(formInput.id);
    }

    // iterate through form inputs and set default values from project config if not already present in inputsCustom from the episode
    const epInputsCopy = {...episodeStore.getState().epInputs}; // originally populated by episode.inputsCustom, but could not have been updated by user yet because form inputs not set
    let foundUpdate = false;
    for (const formInput of formInputs) {
      if (epInputsCopy[formInput.id]?.value) continue; // if value already set, don't override it
      if (!zProject.configInputs?.[formInput.id]) continue; // if value not set in project config, don't override it

      epInputsCopy[formInput.id] = {
        required: formInput.required,
        type: formInput.type,
        value: zProject.configInputs[formInput.id],
      };
      foundUpdate = true;
    }

    if (foundUpdate) {
      zSetEpInputs(epInputsCopy);
    }
    zSetFormInputs(formInputs);
  }

  // INIT TEMPLATES & SET INPUTS
  useEffect(() => {
    if (!zEpisode) return;

    // the template is derived from the episode. Must get and handle template differently depending on which version (ensuring backwards compatibility)
    if (!zEpisode.gptTemplateId && !zEpisode.gptTemplateIdV2) return;
    // if (zEpisode.gptTemplateId && zEpisode.gptTemplateIdV2) zSetError("GPTTMP1", { zEpisode }); // both templateIds set. This should never happen

    // init exports & form inputs with template
    if (zEpisode.gptTemplateIdV2) {
      initExportsAndFormInputs(zEpisode.gptTemplateIdV2, true);
    } else {
      initExportsAndFormInputs(zEpisode.gptTemplateId, false);
    }
  }, [zEpisode?.gptTemplateId, zEpisode?.gptTemplateIdV2]);

  async function initExportsAndFormInputs(templateId, isV2Template) {
    // get templates & templateMeta
    let templateMeta = null;
    let templates = null;
    if (!isV2Template) {
      const ogTemplate = await templateApi.getTemplate(templateId);
      const {templateMeta: tM, templates: t} = await templateV2Api.ogTemplateToTemplateV2(ogTemplate);
      templateMeta = tM;
      templates = t;

      // manipulate template v2 to use any saved exports
      const savedExports = await epExportApi.getEpExports();
      const modifiedTemplates = TEMPLATE_V2.mergeEpExportsIntoTemplate(
        savedExports,
        templateMeta,
        templates,
        episodeUUID,
      ); // updates because objects are passed by reference

      if (modifiedTemplates && modifiedTemplates.length) {
        templateMeta.pk = episodeUUID;
        const results = await Promise.all([
          // concurrent execution
          templateV2Api.postTemplateMeta(templateMeta),
          templateV2Api.postTemplates(modifiedTemplates),
        ]);
        // check if all updates success
        if (!results.every((result) => result))
          zSetError('GPTTEMPV2', {templateId: zEpisode.gptTemplateIdV2, zEpisode});
      }

      if (!modifiedTemplates?.length) {
        // ensure episode in ddb is updated to new gptTemplateIdV2. If mergeEpExportsIntoTemplate statement runs, then templateId is updated in postTemplateMeta
        const isSuccess = await episodeApi.updateEpisode({gptTemplateIdV2: templateMeta.pk});
        if (!isSuccess) zSetError('GPTTEMPV2.10', {templateId: zEpisode.gptTemplateIdV2, zEpisode});
      }

      // set current template meta and templates to be used by subsequent calls to templateV2Api
      templateV2Api.setCurrentTemplateMeta(templateMeta);
      templateV2Api.setCurrentTemplates(templates);
    } else {
      templateMeta = await templateV2Api.getTemplateMeta(templateId);
      const templateKeyList = templateMeta.templates.map((t) => ({pk: t.pk, sk: t.sk}));
      templates = await templateV2Api.batchGetTemplates(templateKeyList);
    }

    // check for errors
    if (!templateMeta || !templates || !Object.keys(templates).length) {
      zSetError('GPTTEMPV2', {templateId: zEpisode.gptTemplateIdV2, zEpisode});
      return;
    }

    // special template handling (resetting prompt studio template & adding new templates)
    const isSuccess = await templateV2Api.specialTemplateMods(templateMeta, templates);
    if (!isSuccess) {
      zSetError('GPTTEMPV2P2', {templateId: zEpisode.gptTemplateIdV2, zEpisode});
      return;
    }

    zSetTemplateMeta(templateMeta);
    zSetTemplates(templates);
    pollGptRequest(templates);
    fetchPromptModHistory();

    // set global state
    fetchAndSetFormInputs(templateMeta.formInputIds, templateMeta.formInputIdsRequired);
  }

  // GPT + POPULATE EXPORTS & INPUTS
  useEffect(() => {
    pollGptRequest();

    // ensure templateV2Api is set with gptApi, now that gptRequestId is available
    if (zEpisode?.gptRequestId && gptApi.gptReqId != zEpisode.gptRequestId) {
      gptApi.setGptReqId(zEpisode.gptRequestId);
      templateV2Api.setGptApi(gptApi);
    }
  }, [zEpisode?.gptRequestId]); // , zTemplates]); // zTemplates updates frequently. Need only poll gpt request the first time zTemplates is set

  const isDonePollingGptReq = useCallback(
    (gptReq) => {
      if (!gptReq) return false;

      // was originally checking that gptReq.status == "success" || gptReq.status == "error", but somehow race conditions in ddb were causing this to be true before all request statuses were updated, creating premature poll end
      // const isDone = gptReq?.status == "success" || gptReq?.status == "error";
      const finishedGptReqKeys = GPT.getFinishedGptReqKeys(gptReq);
      // get set of promptIds from finishedGptReqKeys
      const finishedPromptIds = new Set();
      finishedGptReqKeys.forEach((reqKey) => finishedPromptIds.add(gptReq.reqGraph[reqKey].id));
      // arbitrarily conform data to type of { [promptId]: status } to use with GPT.allPromptsFinished
      const finishedGptReqKeysObj = {};
      finishedPromptIds.forEach((promptId) => (finishedGptReqKeysObj[promptId] = 'finished')); // useless "finished" value
      // check if all prompts are finished
      const zTemplates = episodeStore.getState().templates;
      const isDone = GPT.allPromptsFinished(zTemplates, finishedGptReqKeysObj);
      return isDone;
    },
    [zTemplates],
  );

  useEffect(() => {
    if (refLoaders.current['gptReq']) refLoaders.current['gptReq'].isDone = (gptReq) => isDonePollingGptReq(gptReq);
  }, [isDonePollingGptReq]); // when gptTemplates changes, updating pollGptReq isDone callback

  function pollGptRequest(templates = null) {
    // if no gptRequestId, nothing to poll
    // if no exports, than the template hasn't been loaded yet, so nothing to populate
    if (!zEpisode?.gptRequestId || !(zTemplates ?? templates)) return;
    // if (isDonePollingGptReq(zGptReq)) return; // already done polling // prevents

    console.log('pollGptRequest restart/start', zEpisode?.gptRequestId);

    // polling for job success
    refLoaders.current['gptReq']?.stop(); // resetting loader if one already exists

    const loader = new Loader(
      () => gptApi.getGptRequest(zEpisode.gptRequestId),
      {timeout: 45 * 60 * 1000},
      'pollGptRequest',
    ); // 45 min timeout
    loader.initialResponse((gptReq) => {
      zSetRetryingFailedRequests(false);
      zSetGptReq(gptReq);
    });
    loader.progress((gptReq) => zSetGptReq(gptReq));
    loader.isDone((gptReq) => isDonePollingGptReq(gptReq));
    loader.done((gptReq) => zSetGptReq(gptReq));
    loader.start();

    refLoaders.current['gptReq'] = loader; // adding loader reference to be removed on unmount
  }

  async function fetchPromptModHistory() {
    if (zPromptModHistory?.length) return;
    const promptMods = await promptModApi.getPromptMods();
    zSetPromptModHistory(promptMods);
  }

  // GPT + UPDATE EXPORTS
  useEffect(() => {
    updateGptReqData();
  }, [zGptReq, zTemplates]);

  async function updateGptReqData() {
    if (!zGptReq || !zTemplates) {
      return;
    }

    const zGptReqPromptsFinished = episodeStore.getState().gptReqPromptsFinished;
    const newlyCompletedPromptIds = {};
    const uniquePromptIds = new Set();
    const newlyCompletedSectionIds = new Set();
    const promptStatusUpdates = {}; // New object to collect status updates // type: { [promptId]: status }

    const adjustStatusForBlock = (sectionId, blockPk, modBlockPk = null) => {
      const status = GPT.getStatusByPromptId(blockPk, zGptReq, modBlockPk);
      const isComplete = status == 'success';
      const isFailed = status == 'failed';

      const pk = GPT.getBlockPk(blockPk, modBlockPk);
      if (isComplete || isFailed) promptStatusUpdates[pk] = status;
      if (isComplete) {
        if (!newlyCompletedPromptIds[sectionId]) newlyCompletedPromptIds[sectionId] = [];
        newlyCompletedPromptIds[sectionId].push(pk);
        uniquePromptIds.add(pk);
        newlyCompletedSectionIds.add(sectionId);
      }
    };

    for (const sectionId of Object.keys(zTemplates)) {
      for (const blockPk of Object.keys(zTemplates[sectionId].blocks)) {
        const block = zTemplates[sectionId].blocks[blockPk];
        if (block.type !== 'prompt') {
          continue;
        }

        const shouldUpdateModBlocks = block.modChain;
        if (shouldUpdateModBlocks) {
          for (const modBlock of block.modChain.chain) {
            const fullModBlockPk = GPT.getFullModBlockPk(blockPk, modBlock.pk);
            if (zGptReqPromptsFinished[fullModBlockPk] !== 'success') {
              adjustStatusForBlock(sectionId, blockPk, modBlock.pk);
            }
          }
        }

        const shouldUpdateBlock = zGptReqPromptsFinished[blockPk] !== 'success';
        if (shouldUpdateBlock) {
          adjustStatusForBlock(sectionId, blockPk);
        }
      }
    }

    if (newlyCompletedSectionIds.size === 0) {
      return; // No completed prompts to update.
    }

    console.log(
      'newlyCompletedPromptIds (setting prompts to complete and updating element body)',
      newlyCompletedPromptIds,
    );

    // Set newly completed prompts to complete and update body
    const gptReqDataMap = await gptApi.batchGetPromptOutput(Array.from(uniquePromptIds), zGptReq);
    zUpdateGptReqDataMap(gptReqDataMap);

    // Batch update prompt statuses in the store
    zBatchUpdateGptReqPromptsFinished(promptStatusUpdates);
  }

  async function invokeGptInitForUserPrompt() {
    if (!zUserDefinedNewPrompt) {
      return;
    }

    // prevent duplicate invocation
    zSetUserDefinedNewPrompt(false);

    const episodeUpdatePromise = episodeApi.updateEpisode({
      stageGPT: 'newUserDefinedPrompt',
      userDefinedNewPrompt: true,
    });
    const lambdaInvokePromise = lambdaApi.invokeDataPipeline(projectId, episodeId);
    const promises = [episodeUpdatePromise, lambdaInvokePromise];
    const responses = await Promise.all(promises);
    responses.forEach((isSuccess) =>
      !isSuccess ? zSetError('submitPrompt', 'Error submitting user defined prompt') : null,
    );

    // restart / start episode and gpt-request polling
    pollEpisode();
    pollGptRequest();
  }

  async function invokeGptInitForModifiedPrompt() {
    if (!zUserModifiedPrompt) return;

    // prevent duplicate invocation
    zSetUserModifiedPrompt(false);

    const episodeUpdatePromise = episodeApi.updateEpisode({stageGPT: 'userModifiedPrompt', userModifiedPrompt: true});
    const lambdaInvokePromise = lambdaApi.invokeDataPipeline(projectId, episodeId);
    const promises = [episodeUpdatePromise, lambdaInvokePromise];
    const responses = await Promise.all(promises);
    responses.forEach((isSuccess) =>
      !isSuccess ? zSetError('submitPrompt', 'Error submitting modified prompt') : null,
    );

    // restart / start episode and gpt-request polling
    pollEpisode();
    pollGptRequest();
  }

  async function invokeGptInitForModifiedPrompt() {
    if (!zUserModifiedPrompt) return;
    console.log('invokeGptInitForModifiedPrompt');

    // prevent duplicate invocation
    zSetUserModifiedPrompt(false);

    const episodeUpdatePromise = episodeApi.updateEpisode({stageGPT: 'userModifiedPrompt', userModifiedPrompt: true});
    const lambdaInvokePromise = lambdaApi.invokeDataPipeline(projectId, episodeId);
    const promises = [episodeUpdatePromise, lambdaInvokePromise];
    const responses = await Promise.all(promises);
    responses.forEach((isSuccess) =>
      !isSuccess ? zSetError('submitPrompt', 'Error submitting modified prompt') : null,
    );

    // restart / start episode and gpt-request polling
    pollEpisode();
    pollGptRequest();
  }

  useEffect(() => {
    if (!zTemplates) return; // wait for elements to be set by template

    if (stateTabElements.length > 0) {
      console.log('stateTabElements already initialized. skipping update');
      return;
    }

    initTabElements(zTemplates, zEpisode, zGptReq, zSelectedTabKey);
  }, [zTemplateMeta]); // intentionally not including zEpisode, zGptReq

  useEffect(() => {
    initTabElements(zTemplates, zEpisode, zGptReq, zSelectedTabKey);
  }, [
    zGptReq,
    zSelectedTabKey,
    zTriggerInitTabElements,
    zEpisode?.gptInvokedByUser,
    zEpisode?.stageTranscribe,
    zEpisode?.stageTranscribeComplete,
    zEpisode?.errorFileInvalid,
    zEpisode?.resTranscriptUrl,
  ]); // intentionally not including zTemplates

  // todo it's possible that the reason that the debounce fn isn't being cancelled is because zSaveableTemplates is being compared shallowly,
  // and therefore not calling the useEffect regularly
  useEffect(() => {
    const isEpisodeSaveable = zSaveableTemplates && zSaveableTemplates.length > 0;

    if (!isEpisodeSaveable) {
      return;
    }

    const debounceDelay = zUserDefinedNewPrompt || zUserModifiedPrompt ? 0 : 4000;
    const debouncedFn = debounce(() => (isEpisodeSaveable ? saveTemplates() : null), debounceDelay);
    debouncedFn();
    checkForImmediateActionOnSaveableTemplates();

    return () => debouncedFn.cancel();
  }, [zSaveableTemplates]);

  async function checkForImmediateActionOnSaveableTemplates() {
    // if there is a post action, select the tab
    let tabKeyToSelect;
    for (const {sk, action} of zSaveableTemplates) {
      if (action === 'post') {
        tabKeyToSelect = sk;
        break;
      }
    }

    let selectTabTimeoutId;
    if (tabKeyToSelect) {
      zSetLoading(true);

      zSetTriggerInitTabElements(); // this is to trigger a refresh of tab element data
      setTimeout(() => {
        // select the new tab
        console.log('selecting new tab after timeout: ', tabKeyToSelect);
        zSetProgrammaticallySelectTabByKey(tabKeyToSelect); // this selects the tab based on the sk of the newly refreshed tab elements
        zSetSelectedTabKey(tabKeyToSelect); // also doing this so that tabs are rerendered
        zSetLoading(false);
      }, 500);
    }

    // if zUserDefinedNewPrompt, update template immediately
    if (zUserDefinedNewPrompt) {
      await saveTemplates();
      invokeGptInitForUserPrompt();
    }

    // if zUserModifiedPrompt, update template immediately
    if (zUserModifiedPrompt) {
      await saveTemplates();
      invokeGptInitForModifiedPrompt();
    }

    return {selectTabTimeoutId};
  }

  async function saveTemplates() {
    if (!zSaveableTemplates || !zSaveableTemplates.length) {
      return;
    }

    zSetSaving(true);

    for (const {sk, action} of zSaveableTemplates) {
      let isSuccess;
      if (action === 'post') {
        isSuccess = await templateV2Api.postTemplate(episodeStore.getState().templates[sk]);
      } else if (action === 'delete') {
        isSuccess = await templateV2Api.deleteTemplate(sk);
      } else if (action === 'update') {
        isSuccess = await templateV2Api.updateTemplate(sk, episodeStore.getState().templates[sk]);
      } else {
        zSetError('SAVE_ACTION', {action, sectionId: sk});
        break;
      }
      if (!isSuccess) {
        zSetError('SAVE');
        break;
      }
    }

    updateProjectDefaultTemplate(); // not performed as UI blocking operation

    zClearSaveableTemplates();
    zSetSaving(false);
  }

  async function updateProjectDefaultTemplate() {
    // check that this is a newer episode
    if (memoWasTemplateOriginallyV1) {
      console.log('Preventing updateProjectDefaultTemplate because episode was originally created with template V1');
      return;
    }

    if (!templateV2Api.currentTemplateMeta?.pk) {
      projectApi.postError('saveTemplates', 'no gptTemplateIdV2 when setting configDefaultTemplate', {
        projectId,
        episodeId,
        zEpisode,
      });
      return;
    }

    // set default template to current template for project
    console.log('setting configDefaultTemplate for project by updating configTemplatesUsed');

    // error check
    if (!zProject.configTemplatesUsed) {
      projectApi.postError('saveTemplates', '(non fatal) why no configTemplatesUsed in project?', {
        projectId,
        episodeId,
        zEpisode,
        currentTemplateMetaPk: templateV2Api.currentTemplateMeta?.pk,
      });
      return;
    }

    // project update
    // getting current zEpisode.gptTemplateIdV2 by looking at latest templateMeta.pk, which is stored in TEMPLATE_V2 and updated during postTemplateMeta
    const defaultTemplates = zProject.configTemplatesUsed ?? {};
    projectApi.updateProject(projectId, {
      configTemplatesUsed: {
        ...defaultTemplates,
        [templateV2Api.currentTemplateMeta.pk]: {
          lastUsedTs: Date.now(),
          version: templateV2Api.currentTemplateMeta.version,
        },
      },
    });
  }

  // todo it's possible that the reason that the debounce fn isn't being cancelled is because zTranscriptSaveable is being compared shallowly,
  // and therefore not calling the useEffect regularly
  useEffect(() => {
    // Create a fresh debounced function for this effect run.
    const debouncedFn = debounce(() => (zTranscriptSaveable ? saveUpdatedTranscript() : null), 5000);

    // Call the debounced function.
    debouncedFn();

    // Cleanup on component unmount or if the effect reruns.
    return () => debouncedFn.cancel();
  }, [zTranscriptSaveable]);

  async function saveUpdatedTranscript() {
    zSetSaving(true);

    const success = await transcriptApi.putTranscriptData(episodeStore.getState().transcript);
    if (!success) {
      zSetError('saveUpdatedTranscript');
    } else {
      transcriptApi.breakCache();
    }

    zSetSaving(false);
    zSetTranscriptSaveable(false);
  }

  // ENHANCE
  useEffect(() => {
    if (!zEpisode || !zEpisode.resEnhanceJobId) {
      return;
    }

    // polling for edit success from assembly api
    refLoaders.current['enhance']?.stop(); // resetting loader if one already exists

    const loader = new Loader(
      () => enhanceApi.getEnhanceJob(zEpisode.resEnhanceJobId),
      {timeout: 45 * 60 * 1000},
      'pollEnhanceJob',
    );
    loader.isDone((job) => !job || job.status === 'complete' || job.status === 'error');
    loader.initialResponse((job) => zSetEnhanceJob(job));
    loader.progress((job) => zSetEnhanceJob(job));
    loader.done((job) => zSetEnhanceJob(job));
    loader.start();

    refLoaders.current['enhance'] = loader; // adding loader reference to be removed on unmount
  }, [zEpisode?.resEnhanceJobId]);

  function initTabElements(zTemplates, zEpisode, zGptReq, zSelectedTabKey) {
    if (!zTemplates) {
      return;
    }

    const elements = [];
    const isUserInputMissing = !(zEpisode ?? {}).gptInvokedByUser; // !EPISODE.getHasUserSubmittedInputs(zEpisode, zFormInputs);
    const transcriptionFailed = zEpisode?.stageTranscribe === 'error' || zEpisode?.stageTranscribeComplete === 'error';
    const globalDisabledStatus = !zEpisode || zEpisode.errorFileInvalid || transcriptionFailed;
    const getGlobalDisabledMessage = () => {
      if (!globalDisabledStatus) return null;
      if (!zEpisode) return 'Episode not found';
      if (zEpisode.errorFileInvalid) return 'File is invalid';
      if (transcriptionFailed) return 'File processing failed';
      return null;
    };
    const globalDisabledMessage = getGlobalDisabledMessage();

    let isDisabled = globalDisabledStatus || !zEpisode.inputsCustom || transcriptionFailed;
    let isLoading = zEpisode?.stageTranscribe !== 'success';
    let tooltipLabel =
      globalDisabledMessage ??
      (isDisabled
        ? '<-- Submit episode info to enable this tab.'
        : isLoading
          ? zEpisode?.resTranscriptUrl
            ? 'Just a moment...'
            : "We're generating your transcript..."
          : 'Transcript');

    elements.push({
      title: 'Transcript',
      sk: 'transcript',
      icon: BsBodyText,
      isDisabled,
      isLoading,
      tooltipLabel,
      child: (
        <EpSectionContainer>
          <Transcript />
        </EpSectionContainer>
      ),
    });

    const isPromptStudioDisabled = (templateSk) => memoWasTemplateOriginallyV1 && templateSk === 'sid_prompt_studio';
    const epSectionTooltipLabel = (templateSk) => {
      if (!globalDisabledStatus && !isUserInputMissing && isPromptStudioDisabled(templateSk))
        return 'Prompt Studio: Unavailable for episodes created before Feb 2024.';
      return (
        globalDisabledMessage ??
        (isDisabled
          ? '<-- Submit episode info to enable this tab.'
          : isLoading
            ? zEpisode?.resTranscriptUrl
              ? 'Just a moment...'
              : 'Waiting for transcript...'
            : zTemplates[templateSk].name)
      );
    };

    zTemplateMeta.templates
      .filter((t) => zTemplates[t.sk].curatedId)
      .map((t) => t.sk)
      .forEach((sk) => {
        const sectionTemplate = zTemplates[sk];
        const isDisabled = globalDisabledStatus || isUserInputMissing || isPromptStudioDisabled(sk);
        const isLoading = !zGptReq;
        const isPinging = false;
        const tooltipLabel = epSectionTooltipLabel(sk);
        elements.push({
          title: sectionTemplate.name,
          sk: sk,
          isDisabled,
          isLoading,
          isPinging,
          tooltipLabel,
          icon: TabIcons[sectionTemplate.curatedId ?? 'UKNOWN'].icon,
          child: (
            <EpSectionContainer>
              <EpSection
                sectionTemplate={sectionTemplate}
                pollEpisodeCallback={pollEpisode}
                pollGptRequestCallback={pollGptRequest}
                setModalTemplateCreateProps={setModalTemplateCreateProps}
              />
            </EpSectionContainer>
          ),
        });
      });

    isDisabled = globalDisabledStatus || isUserInputMissing;
    isLoading = !zGptReq;
    tooltipLabel =
      globalDisabledMessage ??
      (isDisabled
        ? '<-- Submit episode info to enable this tab.'
        : isLoading
          ? zEpisode?.resTranscriptUrl
            ? 'Just a moment...'
            : 'Waiting for transcript...'
          : 'Clip Studio');

    elements.push({
      title: 'Clip Studio',
      sk: 'clip_studio',
      icon: MdOutlineSlowMotionVideo, // MdOutlineCropFree,
      isDisabled,
      isLoading,
      tooltipLabel,
      child: (
        <EpSectionContainer>
          <Clips />
        </EpSectionContainer>
      ),
    });

    isDisabled = globalDisabledStatus;
    isLoading = false;
    tooltipLabel = globalDisabledMessage ?? 'Pro Sound Studio';
    elements.push({
      title: 'Sound Studio',
      sk: 'sound_studio',
      icon: BsSoundwave,
      isDisabled,
      isLoading,
      tooltipLabel,
      child: (
        <EpSectionContainer>
          <Enhance pollEpisodeCallback={pollEpisode} />
        </EpSectionContainer>
      ),
    });

    // user defined templates
    isDisabled = globalDisabledStatus || isUserInputMissing;
    isLoading = !zGptReq;
    tooltipLabel =
      globalDisabledMessage ??
      (isDisabled
        ? '<-- Submit episode info to enable this tab.'
        : isLoading
          ? zEpisode?.resTranscriptUrl
            ? 'Just a moment...'
            : 'Waiting for transcript...'
          : 'Custom Templates');

    const popoverElements = zTemplateMeta.templates
      .filter((t) => !zTemplates[t.sk].curatedId)
      .map((t) => {
        const sectionTemplate = zTemplates[t.sk];
        const isDisabled = globalDisabledStatus || isUserInputMissing;
        const isLoading = !zGptReq;
        const isPinging = false;
        const tooltipLabel =
          globalDisabledMessage ??
          (isDisabled
            ? '<-- Submit episode info to enable this tab.'
            : isLoading
              ? zEpisode?.resTranscriptUrl
                ? 'Just a moment...'
                : 'Waiting for transcript...'
              : sectionTemplate.name);

        return {
          title: sectionTemplate.name,
          sk: t.sk,
          isDisabled,
          isLoading,
          showPingAnimation: isPinging,
          tooltipLabel,
          child: (
            <EpSectionContainer>
              <EpSection
                sectionTemplate={sectionTemplate}
                pollEpisodeCallback={pollEpisode}
                pollGptRequestCallback={pollGptRequest}
                setModalTemplateCreateProps={setModalTemplateCreateProps}
              />
            </EpSectionContainer>
          ),
        };
      });

    if (popoverElements.length > 0) {
      const currentlySelectedChild =
        zSelectedTabKey && popoverElements ? popoverElements.find((pe) => pe.sk === zSelectedTabKey)?.child : null; // ensuring that the child component remains if user has selected to view a custom template

      elements.push({
        title: 'Custom Templates',
        sk: 'custom_templates',
        icon: FiLayers,
        isDisabled,
        isLoading,
        tooltipLabel,
        child: currentlySelectedChild,
        popoverElements: popoverElements,
      });
    }

    if (!memoWasTemplateOriginallyV1) {
      isDisabled = globalDisabledStatus || isUserInputMissing;
      isLoading = !zGptReq;
      tooltipLabel =
        globalDisabledMessage ??
        (isDisabled
          ? '<-- Submit episode info to enable this tab.'
          : isLoading
            ? zEpisode?.resTranscriptUrl
              ? 'Just a moment...'
              : 'Waiting for transcript...'
            : 'Create A New Template');

      elements.push({
        title: 'New Template',
        sk: 'new_tab',
        icon: MdOutlineAdd,
        isDisabled,
        isLoading,
        tooltipLabel,
        child: null,
        popoverElements: [
          {
            title: 'Start Fresh',
            sk: 'start_fresh_pe_key',
            leftIcon: <Icon as={FiPlus} />,
            onClick: () => {
              const zTemplateMeta = episodeStore.getState().templateMeta;
              const zTemplates = episodeStore.getState().templates;
              setModalTemplateCreateProps({zTemplateMeta, zTemplates, isEntirelyNewTemplate: true});
            },
            child: null,
          },
          {
            title: 'Reuse an Existing Template',
            sk: 'duplicate_tab_pe_key',
            leftIcon: <Icon as={FiRepeat} />,
            onClick: () => {
              const zTemplateMeta = episodeStore.getState().templateMeta;
              const zTemplates = episodeStore.getState().templates;
              setModalTemplateCreateProps({zTemplateMeta, zTemplates});
            },
            child: null,
          },
        ],
      });
    }

    const copyElements = elements.map((e) => ({...e}));
    // remove elements with sk of custom_templates and new_tab
    let spliceIdx = copyElements.findIndex((e) => e.sk === 'custom_templates');
    if (spliceIdx > -1) copyElements.splice(spliceIdx, 1);
    spliceIdx = copyElements.findIndex((e) => e.sk === 'new_tab');
    if (spliceIdx > -1) copyElements.splice(spliceIdx, 1);
    // add home element
    elements.unshift({
      title: 'Start',
      sk: 'home',
      icon: FiHome,
      tooltipLabel: 'Start Here!',
      child: (
        <EpSectionContainer>
          {zForceShowEpPrefs || !zEpisode?.gptInvokedByUser ? (
            <EpPrefs pollEpisode={pollEpisode} />
          ) : (
            <EpHome tabElements={copyElements} />
          )}
        </EpSectionContainer>
      ),
    });

    setTabElements(elements);
  }

  function updateTabElementsWithSelectedPopoverChild(popoverChild, tabKey) {
    if (!popoverChild) return;
    const popoverElementIndex = stateTabElements.findIndex(
      (e) => e.sk === tabKey || (e.popoverElements && e.popoverElements.some((pe) => pe.sk === tabKey)),
    );
    const stateTabElementsCopy = [...stateTabElements];
    stateTabElementsCopy[popoverElementIndex] = {...stateTabElementsCopy[popoverElementIndex], child: popoverChild};
    setTabElements(stateTabElementsCopy);
  }

  // Wait for tab elements to be set, then select the tab based on the search param if it exists
  useEffect(() => {
    if (!stateTabElements?.length) {
      return;
    }

    // SET SELECTED TAB
    if (tabId) zSetProgrammaticallySelectTabByKey(tabId);
  }, [stateTabElements, tabId]);

  useEffect(() => {
    if (!zSelectedTabKey) return;
    zResetTemplateBlockSelected();
  }, [zSelectedTabKey]);

  function onTemplateCreated(template, name, description, sharingEnabled) {
    const modifiedTemplate = {...template, name, description, sharingEnabled};
    zAddTemplate(modifiedTemplate);
  }

  return (
    <>
      <ZTabs
        flex={1}
        padding={0}
        pt={4}
        backgroundColor={'inherit'}
        flexGrow={1}
        elements={stateTabElements}
        selectedTabKey={zSelectedTabKey}
        updateTabElementsWithSelectedPopoverChild={updateTabElementsWithSelectedPopoverChild}
        onTabChange={(tabKey) => {
          zSetProgrammaticallySelectTabByKey(null); // this ensures that a tab can be selected again
          zSetSelectedTabKey(tabKey); // this is for knowing which tab is selected
          navigate(`/projects/${projectId}/episodes/${episodeId}/tab/${tabKey}`);
        }}
        setProgrammaticallySelectTabByKey={zSetProgrammaticallySelectTabByKey}
        programmaticallySelectTabByKey={zProgrammaticallySelectTabByKey}
      />

      <EpModal
        openThisModal={stateEpModalProps}
        onCloseCallback={() => zTriggerFormInputRefresh()}
        pollEpisode={pollEpisode}
        closeModal={() => setEpModalProps(null)}
      />

      <TemplateCreateModal
        onSubmit={onTemplateCreated}
        openThisModal={stateModalTemplateCreateProps}
        templateMeta={stateModalTemplateCreateProps?.zTemplateMeta}
        templates={stateModalTemplateCreateProps?.zTemplates}
        editTemplateSk={stateModalTemplateCreateProps?.editTemplateSk}
        duplicateTemplateSk={stateModalTemplateCreateProps?.duplicateTemplateSk}
        isEntirelyNewTemplate={stateModalTemplateCreateProps?.isEntirelyNewTemplate}
        onCloseCallback={() => setModalTemplateCreateProps(null)}
        episodeUuid={episodeUUID}
      />
    </>
  );
};
