import {Card, CardProps} from '@chakra-ui/react';

// other card styles include: 'elevated', 'outline', 'filled', 'unstyled', 'ghost'

/**
 * @param {CardProps} props
 * @returns {JSX.Element}
 */
const ZCard = ({children, variant = 'elevated', spacing, growVertical = 0, ...props}) => (
  <Card
    borderRadius="10px"
    width="100%"
    variant={variant}
    padding={4}
    spacing={spacing}
    flexGrow={growVertical && 1}
    {...props}
  >
    {children}
  </Card>
);

export default ZCard;
