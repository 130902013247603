import {Button, Flex, Text, Tooltip, Wrap, WrapItem} from '@chakra-ui/react';

import ZPingAnimation from './ZPingAnimation';

export default function ButtonPrimary({
  wrap = false, // allows button to not be wrap content rather than be full width
  aligntRight = (wrap ?? false) && false,
  rightIcon,
  leftIcon,
  size = 'md',
  textColor = 'white',
  fontWeight = 'semibold',
  fontSize = '15px',
  colorScheme = 'blue',
  centerText = false,
  sx,
  style,
  onClick,
  isDisabled,
  children,
  tooltipLabel,
  tooltipLabelPlacement = 'right',
  showPingAnimation = false,
  width,
  skipTextWrap = false,
  ...buttonProps
}) {
  const ButtonPrimary = (
    <Flex width={width}>
      {showPingAnimation && <ZPingAnimation />}
      <Tooltip isDisabled={!tooltipLabel} label={tooltipLabel} placement={tooltipLabelPlacement} borderRadius={'lg'}>
        <Button
          justifyContent={centerText ? 'center' : 'flex-start'}
          rightIcon={rightIcon}
          leftIcon={leftIcon}
          size={size}
          colorScheme={colorScheme}
          onClick={onClick}
          isDisabled={isDisabled}
          sx={{
            '.chakra-button__icon *': {color: textColor},

            color: textColor,
            backgroundImage: '/buttonbg.jfif',
            backgroundSize: 'cover',
            backgroundPosition: 'center',

            ':hover': {
              backgroundImage: '/buttonbg.jfif',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: 'brightness(85%)',
              transition: 'all .2s',
            },

            ':active': {
              backgroundImage: '/buttonbg.jfif',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: 'brightness(65%)',
              transition: 'all .2s',
            },
            ...sx,
          }}
          style={style}
          width={width}
          {...buttonProps}
        >
          {skipTextWrap ? (
            children
          ) : (
            <Text fontWeight={fontWeight} fontSize={fontSize} textColor={textColor}>
              {children}
            </Text>
          )}
        </Button>
      </Tooltip>
    </Flex>
  );

  if (wrap) {
    return (
      <Wrap justify={aligntRight ? 'right' : 'left'}>
        <WrapItem>{ButtonPrimary}</WrapItem>
      </Wrap>
    );
  }

  return ButtonPrimary;
}

// ButtonPrimary.propTypes = {
//   rightIcon: PropTypes.element,
//   leftIcon: PropTypes.element,
//   onClick: PropTypes.func.isRequired,

//   textColor: PropTypes.string,
//   textSize: PropTypes.string,
//   fontWeight: PropTypes.string,
//   size: PropTypes.string,
//   colorScheme: PropTypes.string,
//   sx: PropTypes.object,
//   style: PropTypes.object,
// };
