import {AlignmentType, Paragraph, TabStopPosition, TabStopType, TextRun} from 'docx';

export default class DocxUtils {
  static DEFAULT_TITLE_OPTIONS = {spacing: {line: 276}, size: 36, font: 'Calibri', centered: false};
  static DEFAULT_TEXT_OPTIONS = {
    spacing: {line: 276},
    size: 23,
    font: 'Calibri',
    centered: false,
    maxRightTabStop: false,
  };

  static Title = (text, options = this.DEFAULT_TITLE_OPTIONS) => {
    return new Paragraph({
      children: [new TextRun({text: text, font: options.font, bold: true, size: options.size})],
      spacing: options.spacing,
      alignment: options.centered ? AlignmentType.CENTER : undefined,
      tabStops: options.maxRightTabStop ? [{type: TabStopType.RIGHT, position: TabStopPosition.MAX}] : undefined,
    });
  };

  static Text = (lines, options = this.DEFAULT_TEXT_OPTIONS) => {
    if (!lines) {
      return [];
    }

    return lines.map((line) => {
      const parts = line.split('\n');
      const runs = parts.map(
        (part, index) =>
          new TextRun({
            text: part,
            font: options.font,
            size: options.size,
            bold: options.bold,
            break: index < parts.length - 1 ? 1 : 0, // Add a break except for the last part
          }),
      );

      return new Paragraph({
        children: runs,
        spacing: options.spacing,
        bullet: options.bullet,
        alignment: options.centered ? AlignmentType.CENTER : undefined,
        tabStops: options.maxRightTabStop ? [{type: TabStopType.RIGHT, position: TabStopPosition.MAX}] : undefined,
      });
    });
  };

  /** looks for <strong> tags, and bolds text inside */
  static TextWithStrongTags = (lines, options = this.DEFAULT_TEXT_OPTIONS) => {
    if (!lines) {
      return [];
    }

    return lines.map((line) => {
      // Split the line into segments based on <strong> tags
      const regex = /<strong>(.*?)<\/strong>/g;
      let parts = [];
      let lastIndex = 0;
      line.replace(regex, (match, p1, offset) => {
        // Add the text before the <strong> tag if any
        if (offset > lastIndex) {
          parts.push({text: line.substring(lastIndex, offset), bold: false});
        }
        // Add the text inside the <strong> tag
        parts.push({text: p1, bold: true});
        lastIndex = offset + match.length;
      });

      // Add any remaining text after the last <strong> tag
      if (lastIndex < line.length) {
        parts.push({text: line.substring(lastIndex), bold: false});
      }

      // Create TextRuns from the parts
      const runs = parts.map(
        (part) =>
          new TextRun({
            text: part.text,
            font: options.font,
            size: options.size,
            bold: part.bold || options.bold, // Apply bold if set in part or options
          }),
      );

      return new Paragraph({
        children: runs,
        spacing: options.spacing,
        bullet: options.bullet,
        alignment: options.centered ? AlignmentType.CENTER : undefined,
        tabStops: options.maxRightTabStop ? [{type: TabStopType.RIGHT, position: TabStopPosition.MAX}] : undefined,
      });
    });
  };

  /**
   * @param {*} numLines
   * @param {*} textOptions
   * @returns
   */
  static EmptyLines = (numLines = 1, textOptions = this.DEFAULT_TEXT_OPTIONS) => {
    const lines = [];
    for (let i = 0; i < numLines; i++) {
      lines.push(new Paragraph({children: [], textOptions}));
    }
    return lines;
  };
}
