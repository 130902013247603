import {
  AspectRatio,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {FiTag, FiTrash, FiUpload} from 'react-icons/fi';

import appStore from '../../stores/app-store';
import {SUBSCRIPTION} from '../../utils/api-v2';
import {TEMPLATE_V2} from '../../utils/api-v2';
import DateUtils from '../../utils/date-utils';
import InputUtils from '../../utils/input-utils';
import ButtonPrimary from '../abstraction_high/ButtonPrimary';
import ButtonSecondary from '../abstraction_high/ButtonSecondary';
import FancyButton from '../abstraction_high/FancyButton';
import {ZForm} from '../abstraction_high/ZForm';
import ZProgressBar from '../abstraction_high/ZProgressBar';
import ZRadioElementCard from '../abstraction_high/ZRadioElementCard';
import {TabIcons} from '../abstraction_high/ZTabs';
import {ZAccordion, ZAlertFullWidth, ZEditable} from '../common/ComponentStyle';
import {FlowHelpIcon} from '../common/Structural';
import {
  Body14,
  Body16Bold,
  Body18SemiBold,
  BodyMd,
  BodyMdSemiBold,
  BodySm,
  BodySmMedium,
  BodySmMuted,
  BodySmSemiBold,
  HeaderLgBold,
} from '../common/TextStyle';

function ConfirmModal({
  openThisModal,
  onCloseCallback = () => {},
  titleText = 'Are you sure?',
  bodyComponent = null,
  bodyText,
  positiveText = 'TODO',
  positiveCallback = () => console.log('TODO positive callback'),
  neutralText = 'Cancel',
  ...props
}) {
  const inputRef = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();

  // onCloseCallback is called only once when the modal is closed
  const [stateWasOpened, setWasOpened] = useState(false);
  useEffect(() => {
    if (isOpen) setWasOpened(true);
    if (!isOpen && stateWasOpened) {
      setWasOpened(false);
      onCloseCallback();
    }
  }, [isOpen]);

  useEffect(() => {
    if (openThisModal) {
      console.log('opening modal');
      if (!isOpen) {
        onOpen();
      }
    } else {
      if (isOpen) {
        onClose();
      }
    }
  }, [openThisModal]);

  const header = <BodyMdSemiBold>{titleText}</BodyMdSemiBold>;

  const body = bodyComponent ? (
    bodyComponent
  ) : bodyText ? (
    <Stack>
      <BodySm whiteSpace="pre-line">{bodyText}</BodySm>
    </Stack>
  ) : (
    <Stack />
  );

  const footer = (
    <Flex justifyContent={'end'} gap={2}>
      {neutralText && <ButtonSecondary onClick={onClose}>{neutralText}</ButtonSecondary>}
      <ButtonPrimary
        onClick={() => {
          onClose();
          positiveCallback();
        }}
      >
        {positiveText}
      </ButtonPrimary>
    </Flex>
  );

  return (
    <ModalShell
      modalSize={'md'}
      body={body}
      header={header}
      footer={footer}
      initialFocusRef={inputRef}
      disclosure={{isOpen, onOpen, onClose}}
      {...props}
    />
  );
}

function InviteUserModal({openThisModal, onSubmit, onCloseCallback = () => {}}) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const inputEmailRef = useRef();
  const inputRoleRef = useRef();

  const [stateInputEmailValue, setInputEmailValue] = useState('');
  const [stateEmailInputErrorMsg, setEmailInputErrorMsg] = useState(null);
  const [stateLoading, setLoading] = useState(false);
  const [stateErrorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (openThisModal) {
      console.log('opening modal');
      setInputEmailValue(''); // reset email input value
      if (!isOpen) {
        onOpen();
      }
    }
  }, [openThisModal]);

  function close() {
    onClose();
    onCloseCallback();
  }

  async function inviteTeammate(email, role) {
    console.log('inviteTeammate', email, role);
    setLoading(true);

    const isSuccess = await onSubmit(email, role);
    if (!isSuccess || typeof isSuccess === 'string') {
      const errorMsg =
        typeof isSuccess === 'string'
          ? isSuccess
          : 'Something went wrong. Please contact us so we can take care of this asap.';
      setErrorMsg(errorMsg);
      setLoading(false);
      return;
    }

    setLoading(false);
    close();
  }

  function resetErrors() {
    setEmailInputErrorMsg(null); // clear error message (if any)
    setErrorMsg(null);
  }

  const body = (
    <Stack padding={'30px'}>
      <HeaderLgBold>Invite Teammate</HeaderLgBold>
      {stateErrorMsg && (
        <BodySm mt={2} color={'red'}>
          {stateErrorMsg}
        </BodySm>
      )}

      <FormControl isInvalid={stateEmailInputErrorMsg}>
        <FormLabel mt={8} fontWeight={'semibold'} color={'black'}>
          Email
        </FormLabel>
        <Input
          ref={inputEmailRef}
          value={stateInputEmailValue}
          onChange={(e) => {
            const value = e.target.value;
            if (value) resetErrors();
            setInputEmailValue(e.target.value);
          }}
          type="email"
          placeholder="jacob@podflow.ai"
          fontSize={'sm'}
        />
        <FormErrorMessage>{stateEmailInputErrorMsg}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel mt={8} fontWeight={'semibold'} color={'black'}>
          Role
        </FormLabel>
        <FormHelperText mt={0} mb={2} lineHeight={'130%'}>
          Editors can access all projects and episodes but, cannot access billing or send team invites. Admins have full
          access to episodes, projects, billing and team invites.
        </FormHelperText>
        <Select ref={inputRoleRef} defaultChecked={0} fontSize={'sm'}>
          <option>Editor</option>
          <option>Admin</option>
        </Select>
      </FormControl>

      <ButtonPrimary
        mt={8}
        wrap={true}
        isLoading={stateLoading}
        loadingText="One Moment..."
        aligntRight={true}
        onClick={() => {
          console.log('onSubmit');
          const emailValue = stateInputEmailValue;
          if (!emailValue) {
            setEmailInputErrorMsg('Email is required.');
            return;
          } else if (!InputUtils.validateEmail(emailValue)) {
            setEmailInputErrorMsg('Please enter a valid email address.');
            return;
          }

          const roleValue = inputRoleRef.current?.value?.toLowerCase();
          if (!roleValue) {
            setErrorMsg('Please select a role.');
            return;
          }

          inviteTeammate(emailValue, roleValue);
        }}
      >
        Invite
      </ButtonPrimary>
    </Stack>
  );

  return <ModalShell body={body} initialFocusRef={inputEmailRef} disclosure={{isOpen, onOpen, onClose: close}} />;
}

function PromptInteractionModal({openThisModal, onCloseCallback}) {
  const inputRef = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    return () => {
      if (isOpen) {
        // ensuring the state that triggers this modal is reset when the modal is closed. This allows the modal to be opened again if necessary
        onCloseCallback();
      }
    };
  }, []);

  useEffect(() => {
    if (openThisModal) {
      console.log('opening modal');
      if (!isOpen) {
        onOpen();
      }
    }
  }, [openThisModal]);

  return (
    <ModalShell
      initialFocusRef={inputRef}
      disclosure={{isOpen, onOpen, onClose}}
      body={
        <Box as="section" bg="bg-surface" pt={3} pb={3} onClick={onClose}>
          <Text>Click anywhere to continue.</Text>
        </Box>
      }
    />
  );
}

function PromptUpgradeModal({openThisModal, subscription, navigateToPricing}) {
  const inputRef = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const daysTilUploadReload = DateUtils.daysBetweenDates(subscription?.current_period_end, Date.now());
  const currentUploadLimit = SUBSCRIPTION.getMaxMonthlyUploadsBySub(subscription);

  useEffect(() => {
    if (openThisModal && subscription) {
      console.log('opening modal');
      if (!isOpen) {
        onOpen();
      }
    }
  }, [openThisModal, subscription]);

  const body = (
    <Box as="section" bg="bg-surface" pt={3} pb={3}>
      <Stack justify="flex-start">
        <Text mt={4} lineHeight="1" fontWeight="bold" fontSize="3xl">
          Enjoying Podflow? Upgrade for more uploads.
        </Text>

        <Stack pt={4} gap={2}>
          <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="18px" color="black" maxW="xl">
            You've reached your monthly upload limit. You'll receive{' '}
            {currentUploadLimit === 1 ? 'another free upload' : `${currentUploadLimit} more uploads`} in{' '}
            {daysTilUploadReload} days, or you can upgrade now and continue to create amazing content!
          </Text>

          <Text fontFamily="Inter" lineHeight="1.33" fontWeight="regular" fontSize="18px" color="black" maxW="xl">
            And remember you can always come back to this page later by visiting your library.
          </Text>
        </Stack>

        <Stack pt={8} gap={2} direction={{base: 'column', sm: 'row'}}>
          <FancyButton
            size="lg"
            leftIcon={<Icon as={FiTag} />}
            onClick={() => {
              navigateToPricing();
              onClose();
            }}
          >
            See Upgrade Options
          </FancyButton>

          <Button rounded="lg" size={'lg'} fontWeight="bold" colorScheme={'gray'} onClick={onClose}>
            Stay Here
          </Button>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <ModalShell
      body={body}
      initialFocusRef={inputRef}
      disclosure={{isOpen, onOpen, onClose}}
      modalSize={'3xl'}
      showCloseButton={false}
    />
  );
}

function SubscriptionUpgradeModal({upgradeInfo = {}, isUpgrading, invoice, navigateToUpload, onCloseCallback}) {
  const {productId, priceId, isMonthly, isDowngrade = false} = upgradeInfo;
  const inputRef = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (invoice || isUpgrading) {
      console.log('opening modal');
      if (!isOpen) {
        onOpen();
      }
    }
  }, [invoice, isUpgrading]);

  useEffect(() => {
    if (productId && !isOpen) {
      onCloseCallback();
    }
  }, [isOpen]);

  const name = () =>
    `${isDowngrade ? 'Switch' : 'Upgrade'} -> ${isMonthly ? 'Monthly' : 'Yearly'} ${SUBSCRIPTION.getProductNamePretty(productId, priceId)} Package`;

  const body = (
    <Box as="section" bg="bg-surface" pt={3} pb={3}>
      <Stack justify="flex-start" spacing={{base: '6', md: '7'}}>
        <Body16Bold>{name()}</Body16Bold>

        {isUpgrading && !invoice && <ZProgressBar width={'100%'} isIndeterminate={true} />}

        {invoice && (
          <>
            <Stack>
              <Body14>
                You've successfully {isDowngrade ? 'switched' : 'upgraded'} to the {isMonthly ? 'monthly' : 'yearly'}{' '}
                Podflow <b>{SUBSCRIPTION.getProductNamePretty(productId, priceId)}</b> Package. We're excited to see
                what you create! You will receive a receipt for <b>${(invoice.amount_due / 100).toFixed(2)}</b> at your{' '}
                <b>{invoice.customer_email}</b> email address.
              </Body14>
            </Stack>
            <Stack pt={4} alignItems={'end'}>
              <ButtonPrimary
                leftIcon={<Icon as={FiUpload} />}
                onClick={() => {
                  navigateToUpload();
                  onClose();
                }}
              >
                Upload an Episode
              </ButtonPrimary>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );

  return <ModalShell body={body} initialFocusRef={inputRef} disclosure={{isOpen, onOpen, onClose}} />;
}

function YoutubeUploadModal({onSubmit}) {
  const inputRef = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const closedView = (
    <Stack>
      <Button onClick={onOpen}>Upload from YouTube</Button>
    </Stack>
  );

  const body = (
    <Stack padding={'30px'}>
      <FormControl>
        <FormLabel>video url</FormLabel>
        <Input ref={inputRef} placeholder="https://www.youtube.com/..." />
      </FormControl>

      <Stack>
        <FancyButton
          onClick={() => {
            const input = inputRef.current?.value;
            if (!InputUtils.validateUrl(input)) {
              console.log('invalid url');
              return;
            }

            const invocationSuccess = onSubmit(input);
            if (invocationSuccess) {
              console.log('invocation success');
              onClose();
            } else {
              console.log('youtube upload invocation error');
            }
          }}
        >
          Submit
        </FancyButton>
      </Stack>
    </Stack>
  );

  return (
    <ModalShell closedView={closedView} body={body} initialFocusRef={inputRef} disclosure={{isOpen, onOpen, onClose}} />
  );
}

function InputModal({
  title,
  openThisModal,
  onSubmit,
  isTextArea = false,
  onCloseCallback = () => {},
  inputValidationCallback = InputUtils.validateInputWithMessage,
  onInvalidInputCallback = () => {},
}) {
  const inputRef = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [stateInvlidInputMessage, setInvalidInputMessage] = useState(null);

  useEffect(() => {
    if (openThisModal) {
      console.log('opening modal');
      if (!isOpen) {
        onOpen();
      }
    }
  }, [openThisModal]);

  function close() {
    onClose();
    onCloseCallback();
    setInvalidInputMessage(null);
  }

  function onError(message) {
    console.log('InputModal onError', message);
    close();
  }

  function onInvalidInput(message) {
    console.log('InputModal onInvalidInput', message);
    setInvalidInputMessage(message);
    onInvalidInputCallback(message);
  }

  const body = (
    <Stack padding={'30px'}>
      <FormControl>
        <FormLabel>{title ?? Input}</FormLabel>
        {isTextArea ? (
          <Textarea ref={inputRef} placeholder="type here..." />
        ) : (
          <Input ref={inputRef} placeholder="type here..." />
        )}
      </FormControl>
      {stateInvlidInputMessage && <BodySm color={'red'}>{stateInvlidInputMessage}</BodySm>}

      <Stack>
        <ButtonPrimary
          wrap={true}
          aligntRight={true}
          onClick={() => {
            const input = inputRef.current?.value;
            const errorMessage = inputValidationCallback(input);
            if (errorMessage) {
              onInvalidInput(errorMessage);
              return;
            }

            const invocationSuccess = onSubmit(input) ?? undefined;
            if (invocationSuccess === false) {
              onError('invocation error');
            } else {
              close();
            }
          }}
        >
          Submit
        </ButtonPrimary>
      </Stack>
    </Stack>
  );

  return <ModalShell body={body} initialFocusRef={inputRef} disclosure={{isOpen, onOpen, onClose: close}} />;
}

function SearchReplaceModal({utterances, openThisModal, onSubmit, onCloseCallback = () => {}}) {
  const refInput = useRef();
  const refInput2 = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [stateError, setError] = useState();
  const [stateNumSearchInstances, setNumSearchInstances] = useState(0);
  const [stateSearchInput, setSearchInput] = useState('');

  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

  const transcript = useMemo(() => {
    if (!utterances) return;
    const transcript = deepCopy(utterances)
      .map((utterance) => utterance.text)
      .join('\n\n');
    return transcript;
  }, [utterances]);

  useEffect(() => {
    if (openThisModal) {
      if (!isOpen) {
        onOpen();
      }
    }
  }, [openThisModal]);

  function close() {
    onClose();
    onCloseCallback();
  }

  function countOccurrences(str, substring) {
    if (!str || !substring) return 0;

    let count = 0;
    let position = 0;

    while (true) {
      position = str.indexOf(substring, position);
      if (position === -1) {
        break;
      }
      count++;
      position += substring.length; // Move to the next possible occurrence
    }

    return count;
  }

  function onSearchChange(e) {
    const value = e.target.value.trim();
    setSearchInput(value);
    if (!value) return;
    setNumSearchInstances(countOccurrences(transcript, value));
  }

  function submit() {
    const search = refInput.current?.value.trim();
    const replace = refInput2.current?.value.trim();

    if (!InputUtils.validateInput(search)) {
      setError('invalid input');
      return;
    }
    if (!countOccurrences(transcript, search)) {
      setError('No instances of search term were found.');
      return;
    }

    setError(undefined);

    if (search === replace) {
      close();
      return;
    }

    const invocationSuccess = onSubmit(search, replace) ?? undefined;
    if (invocationSuccess === false) {
      setError('invocation error');
    } else {
      console.log('invocation success');
      close();
    }
  }

  const body = (
    <Stack padding={'30px'}>
      <Text fontFamily={'Inter'} fontWeight={'bold'} fontSize={18}>
        Search and Replace
      </Text>
      <Text fontFamily="Inter" fontWeight="normal" fontSize="16px" color="black">
        Search for words or groups of words in your transcript that may be incorrect or mispelled, and replace them with
        the correct value. Search for whole words, if possible.
      </Text>
      <FormControl>
        <FormLabel mt={8}>Search</FormLabel>
        <Input ref={refInput} onChange={onSearchChange} placeholder="type here..." />
        {stateSearchInput ? (
          <FormHelperText mt={4}>
            We found {stateNumSearchInstances} instances of "{stateSearchInput}".
          </FormHelperText>
        ) : (
          <></>
        )}

        <FormLabel mt={8}>Replace</FormLabel>
        <Input ref={refInput2} placeholder="type here..." />
      </FormControl>

      {stateError && (
        <ZAlertFullWidth
          closeCallback={() => {
            setError(null);
          }}
          status="warning"
        >
          {stateError}
        </ZAlertFullWidth>
      )}

      <Stack>
        <FancyButton onClick={submit}>Modify Transcript</FancyButton>
      </Stack>
    </Stack>
  );

  return <ModalShell body={body} initialFocusRef={refInput} disclosure={{isOpen, onOpen, onClose: close}} />;
}

function CreateProjectModal({openThisModal, onSubmit, onCloseCallback = () => {}}) {
  const refInput = useRef();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [stateError, setError] = useState();

  const zSetError = appStore((state) => state.setError);

  useEffect(() => {
    if (openThisModal) {
      if (!isOpen) {
        onOpen();
      }
    }
  }, [openThisModal]);

  function close() {
    onClose();
    onCloseCallback();
  }

  function submit() {
    const name = refInput.current?.value.trim();

    if (!InputUtils.validateInput(name)) {
      console.log('invalid input', name);
      setError('Please complete the required fields.');
      return;
    }

    const invocationSuccess = onSubmit(name) ?? undefined;
    if (invocationSuccess === false) zSetError('An error occurred while creating your project');
    close();
  }

  const body = (
    <Stack padding={'30px'}>
      <Text fontFamily={'Inter'} fontWeight={'bold'} fontSize={18}>
        New Project
      </Text>
      {stateError && (
        <ZAlertFullWidth
          status="warning"
          closeCallback={() => {
            setError(null);
          }}
        >
          {stateError}
        </ZAlertFullWidth>
      )}

      <FormControl>
        <FormLabel mt={8}>Project Name</FormLabel>
        <Input ref={refInput} placeholder="i.e. The All-In Podcast" />
      </FormControl>

      {/* <FormControl>
        <FormLabel mt={8}>Language</FormLabel>
        <Input ref={refInput} placeholder="English" />
      </FormControl> */}

      <Flex justifyContent={'end'}>
        <ButtonPrimary mt={8} wrap={true} onClick={submit}>
          Create Project
        </ButtonPrimary>
      </Flex>
    </Stack>
  );

  return <ModalShell body={body} initialFocusRef={refInput} disclosure={{isOpen, onOpen, onClose: close}} />;
}

function PromptSelectionModal({blockToTransform, onPromptSelectedForTransformation, templates = {}}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [stateSelectedPromptPk, setSelectedPromptPk] = useState(null);

  useEffect(() => {
    if (blockToTransform && !isOpen) onOpen();
  }, [blockToTransform]);

  const elements = useMemo(() => {
    if (!blockToTransform) return [];

    // to prevent duplicate prompts in a section, figure out which prompts already exist in selected section
    // we must prevent duplicates because our epExports elements property is currently a map that uses the prompt pk as the key, it would take a lot of work to change this
    // Extract PKs of prohibited prompts
    const prohibitedSectionBlocks = Object.keys(templates[blockToTransform.sectionId].blocks).map(
      (pk) => templates[blockToTransform.sectionId].blocks[pk],
    );
    const prohibitedPromptPks = new Set(
      prohibitedSectionBlocks.filter((block) => block.type === 'prompt').map((block) => block.pk),
    );

    const sectionsArray = Object.values(templates).map((section) => {
      const promptBlockPkSet = new Set();
      const sectionBlocks = Object.keys(section.blocks).map((pk) => section.blocks[pk]);
      const promptBlocks = sectionBlocks.filter((block) => {
        // Check if block is a prompt, is not already added, and is not prohibited
        return block.type === 'prompt' && !promptBlockPkSet.has(block.pk) && !prohibitedPromptPks.has(block.pk);
      });

      // Add each valid prompt block's pk to the set
      promptBlocks.forEach((promptBlock) => promptBlockPkSet.add(promptBlock.pk));

      return {
        pk: section.pk,
        sk: section.sk,
        name: section.name,
        description: section.description,
        promptBlocks,
        curatedId: section.curatedId,
      };
    });

    return sectionsArray.filter((section) => section.promptBlocks.length > 0);
  }, [templates, blockToTransform]);

  const PromptOption = ({promptBlock}) => {
    return (
      <Stack>
        <BodySmMedium>{promptBlock.displayName ?? promptBlock.name}</BodySmMedium>
        {promptBlock.description && <BodySmMuted>{promptBlock.description}</BodySmMuted>}
      </Stack>
    );
  };

  const onPromptOptionSelected = (value) => {
    setSelectedPromptPk(value);
  };

  const body = (
    <Box as="section" bg="bg-surface" pt={3} pb={3}>
      <Stack justify="flex-start" spacing={8}>
        <Stack>
          <Body18SemiBold>Add an AI block to this template</Body18SemiBold>
          <BodySm>
            Select an AI block from the list below. Once selected, click the submit button to add it to your template.
          </BodySm>
          <Divider />
        </Stack>
        <ZAccordion
          maxHeight={'60vh'}
          overflowY={'scroll'}
          elements={elements.map((element) => {
            return {
              title: element.name,
              titleIcon: TabIcons[element.curatedId]?.icon ? (
                <Icon as={TabIcons[element.curatedId].icon} boxSize={4} />
              ) : null,
              description: element.description,
              children: (
                <Stack spacing={0}>
                  {element.promptBlocks.filter((b) => !b.impermanent).length <= 0 && (
                    <BodySm>
                      There are no more prompts available from this section... Remember that prompts created in the
                      prompt studio need to be saved before they can be added to another section.
                    </BodySm>
                  )}
                  <RadioGroup onChange={onPromptOptionSelected} value={stateSelectedPromptPk ?? 0}>
                    {element.promptBlocks
                      .filter((b) => !b.impermanent)
                      .sort((a, b) => a.displayName.localeCompare(b.displayName))
                      .map((promptBlock, index) => (
                        <ZRadioElementCard
                          mt={2}
                          key={promptBlock.pk}
                          value={promptBlock.pk}
                          selected={!!(stateSelectedPromptPk === promptBlock.pk)}
                        >
                          <PromptOption promptBlock={promptBlock} />
                        </ZRadioElementCard>
                      ))}
                  </RadioGroup>
                </Stack>
              ),
            };
          })}
        />
        <Flex justifyContent={'end'} gap={2} pt={8}>
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            isDisabled={!stateSelectedPromptPk}
            onClick={() => {
              onClose();
              let matchedElement, transformIntoThisBlock, originalTemplateSk;
              elements.some((element) => {
                const foundBlock = element.promptBlocks.find((block) => block.pk === stateSelectedPromptPk);
                if (foundBlock) {
                  matchedElement = element;
                  transformIntoThisBlock = foundBlock;
                  originalTemplateSk = matchedElement.sk;
                  return true; // Stops the iteration once the block is found
                }
                return false; // Continues the iteration if the block is not found
              });
              onPromptSelectedForTransformation(blockToTransform, transformIntoThisBlock, originalTemplateSk);
            }}
          >
            Submit
          </ButtonPrimary>
        </Flex>
      </Stack>
    </Box>
  );

  return <ModalShell body={body} disclosure={{isOpen, onOpen, onClose}} modalSize={'5xl'} />;
}

function TemplateCreateModal({
  openThisModal,
  templateMeta,
  templates,
  onSubmit,
  editTemplateSk = null,
  duplicateTemplateSk = null,
  isEntirelyNewTemplate = false,
  episodeUuid,
  onCloseCallback = () => {},
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const zSetError = appStore((state) => state.setError);
  const refZForm = useRef(null);
  const [stateSelectedTemplateSk, setSelectedTemplateSk] = useState(editTemplateSk ?? duplicateTemplateSk ?? null); // also set when modal opened
  const [stateSelectedTemplate, setSelectedTemplate] = useState(null);
  const [stateDuplicateTemplateSk, setDuplicateTemplateSk] = useState(duplicateTemplateSk); // also set when modal opened
  const [stateStep, setStep] = useState(0);

  useEffect(() => {
    if (openThisModal && !isOpen) {
      // reset state so that another template can be created
      setSelectedTemplateSk(editTemplateSk ?? duplicateTemplateSk ?? null);
      setDuplicateTemplateSk(duplicateTemplateSk);
      setStep(0);
      setSelectedTemplate(null);

      onOpen();
    }
    return () => {
      if (isOpen) {
        // ensuring the state that triggers this modal is reset when the modal is closed. This allows the modal to be opened again if necessary
        onCloseCallback();
      }
    };
  }, [openThisModal]);

  useEffect(() => {
    if (!isOpen) return;
    if (!isEntirelyNewTemplate && !stateSelectedTemplateSk) return;
    if (stateSelectedTemplate) return; // only allowing the selectedTemplate to be set once. This must be modified if we ever add a back button to the modal

    let selectedTemplate;
    if (isEntirelyNewTemplate && stateStep === 0) {
      // starting fresh with no template selected
      selectedTemplate = TEMPLATE_V2.createStarterTemplate(episodeUuid);
      incrementStep();
    } else if (editTemplateSk && stateStep === 0) {
      // editing a template
      selectedTemplate = templates[editTemplateSk];
      incrementStep();
    } else if (duplicateTemplateSk && stateStep === 0) {
      // duplicating a template
      selectedTemplate = createNewTemplateFromSelection();
      incrementStep();
    } else if (stateStep !== 0) {
      // creating a new template from existing
      selectedTemplate = createNewTemplateFromSelection();
    }

    if (selectedTemplate) setSelectedTemplate(selectedTemplate);
  }, [stateSelectedTemplateSk, stateStep, isOpen]);

  function getDefaultName(editTemplateSk, stateDuplicateTemplateSk) {
    if (editTemplateSk) return templates[editTemplateSk]?.name;
    if (stateDuplicateTemplateSk && templates[stateDuplicateTemplateSk]?.name)
      return `${templates[stateDuplicateTemplateSk]?.name} (copy)`;
    return '';
  }

  function getDefaultDescription(editTemplateSk, stateDuplicateTemplateSk) {
    if (editTemplateSk) return templates[editTemplateSk]?.description;
    if (stateDuplicateTemplateSk) return templates[stateDuplicateTemplateSk]?.description;
    return '';
  }

  const inputs = useMemo(() => {
    return [
      {
        id: 'template_name',
        inputType: 'input',
        label: 'Name',
        subLabel: 'What should we call your template?',
        placeholder: 'Conversation Starters',
        labelType: 'smSemiBold',
        required: true,
        defaultValue: getDefaultName(editTemplateSk, stateDuplicateTemplateSk),
      },
      {
        id: 'template_description',
        inputType: 'textArea',
        label: 'Description',
        subLabel: 'How do you plan on using this template?',
        placeholder: 'Helps me spark a discussion - perfect for facebook groups or social media posts.',
        labelType: 'smSemiBold',
        required: false,
        defaultValue: getDefaultDescription(editTemplateSk, stateDuplicateTemplateSk),
      },
      {
        id: 'template_public',
        inputType: 'checkbox',
        label: 'Share with the community?',
        labelType: 'bodySm',
        required: false,
        tooltipText:
          "If you create a cool template, we'd love to share it with the community. We'll give you credit, of course!",
        defaultValue: true,
      },
    ];
  }, [editTemplateSk, stateDuplicateTemplateSk, templates]);

  const memoInitialFormState = useMemo(() => {
    return {
      template_name: {value: getDefaultName(editTemplateSk, stateDuplicateTemplateSk)},
      template_description: {value: getDefaultDescription(editTemplateSk, stateDuplicateTemplateSk)},
      template_public: {value: true},
    };
  }, [editTemplateSk, stateDuplicateTemplateSk, templates]);

  if (!templateMeta || !templates) return null;

  function incrementStep() {
    setStep(stateStep + 1);
  }

  function createNewTemplateFromSelection() {
    if (!episodeUuid)
      zSetError('CreateProjectModal createNewTemplateFromSelection', {error: 'pk is not set', episodeUuid});

    // this is called on submission of the template select step.
    const newTemplate = {
      ...templates[stateSelectedTemplateSk],
      ...TEMPLATE_V2.getNewTemplateStarterAttributes(episodeUuid),
      curatedId: null,
    };

    return newTemplate;
  }

  const TemplateOption = ({template}) => {
    return (
      <Stack>
        <BodySmMedium>{template.displayName ?? template.name}</BodySmMedium>
        {template.description && <BodySmMuted>{template.description}</BodySmMuted>}
      </Stack>
    );
  };

  const onTemplateOptionSelected = (value) => {
    setDuplicateTemplateSk(value);
    setSelectedTemplateSk(value);
  };

  const handleSubmit = async (inputData) => {
    console.log('handleSubmit', inputData);
    if (!inputData || !stateSelectedTemplate) {
      return false;
    }

    onSubmit(
      stateSelectedTemplate,
      inputData.template_name.value.trim(),
      inputData.template_description.value.trim(),
      inputData.template_public.value,
    );
    onClose();
    return true;
  };

  async function triggerFormSubmission() {
    const inputsSubmitted = await refZForm.current.refSubmitForm();
    console.log('inputsSubmitted', inputsSubmitted);
  }

  const body = (
    <Box as="section" bg="bg-surface" pt={3} pb={3}>
      <Stack justify="flex-start" spacing={8}>
        <Stack>
          <Body18SemiBold>{stateStep == 0 ? 'Select a Template' : 'Tell us about this template...'}</Body18SemiBold>
          <BodySm>
            {stateStep == 0
              ? 'The template you select will be copied so that you can edit it without affecting your other templates. Once selected, click the submit button.'
              : 'Fill in some info about your template. You can always edit this later.'}
          </BodySm>
          <Divider />
        </Stack>
        <Stack maxHeight={'54vh'} height={'54vh'} overflowY={'scroll'}>
          {stateStep == 0 ? (
            <RadioGroup onChange={onTemplateOptionSelected} value={stateSelectedTemplateSk ?? 0}>
              {templateMeta.templates
                .map((templateKey) => templates[templateKey.sk])
                .filter((template) => template.isReusable ?? true)
                .map((template, index) => (
                  <ZRadioElementCard
                    mt={2}
                    key={template.sk}
                    value={template.sk}
                    selected={!!(stateSelectedTemplateSk === template.sk)}
                  >
                    <TemplateOption template={template} />
                  </ZRadioElementCard>
                ))}
            </RadioGroup>
          ) : (
            <ZForm
              pt={8}
              ref={refZForm}
              inputs={inputs}
              buttonLabel="Submit"
              initialState={memoInitialFormState}
              onSubmit={handleSubmit}
              omitSubmitButton={true}
            />
          )}
        </Stack>
        <Flex justifyContent={'end'} gap={2} pt={8}>
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            isDisabled={stateStep == 0 ? !stateSelectedTemplateSk : false} // !stateFormReady}
            onClick={() => {
              if (stateStep == 0) {
                incrementStep();
              } else {
                triggerFormSubmission();
              }
            }}
          >
            {stateStep == 0 ? 'Continue' : 'Submit'}
          </ButtonPrimary>
        </Flex>
      </Stack>
    </Box>
  );

  return <ModalShell body={body} disclosure={{isOpen, onOpen, onClose}} modalSize={'5xl'} />;
}

function YoutubeEmbedModal({title, src, openThisModal, onCloseCallback = () => {}}) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (openThisModal && !isOpen) {
      onOpen();
    }
  }, [openThisModal, isOpen, onOpen]);

  function close() {
    onClose();
    onCloseCallback();
  }

  return (
    <Modal isOpen={isOpen} onClose={close} isCentered size="5xl" initialFocusRef={null}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody paddingInline={0} paddingTop={10}>
          <AspectRatio maxW="100%" ratio={1.77777777}>
            <iframe
              title={title}
              src={src}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function ProjectSettingsModal({
  openThisModal,
  projectApi,
  zUser,
  zProject,
  zUpdateProject,
  zSetLoading,
  zSetError,
  openConfirmArchiveModal,
  onCloseCallback = () => {},
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const projectId = zProject?.projectId;

  useEffect(() => {
    if (openThisModal && !isOpen) {
      onOpen();
      setInvalidInput(false);
    }
  }, [openThisModal]);

  const [stateInvalidInput, setInvalidInput] = useState();

  if (!zProject) return null;

  function close() {
    onClose();
    onCloseCallback();
  }

  async function toggleProjectPrivacy(isPrivate) {
    try {
      zSetLoading(true);
      const isSuccess = await projectApi.updateProject(projectId, {isPrivate: isPrivate});
      if (!isSuccess) throw new Error('error updating project privacy');

      zUpdateProject({isPrivate: isPrivate}, true);
    } catch (e) {
      zSetError('hideProjectFromTeam', {projectId}, 'Something went wrong while hiding your project.');
    } finally {
      zSetLoading(false);
    }
  }

  async function toggleProjectPrivacy(isPrivate) {
    try {
      zSetLoading(true);
      const isSuccess = await projectApi.updateProject(projectId, {isPrivate: isPrivate});
      if (!isSuccess) throw new Error('error updating project privacy');

      zUpdateProject({isPrivate: isPrivate}, true);
    } catch (e) {
      zSetError('hideProjectFromTeam', {projectId}, 'Something went wrong while hiding your project.');
    } finally {
      zSetLoading(false);
    }
  }

  async function updateProjectName(name) {
    zSetLoading('Updating...');
    name = name.trim();

    // validate input
    if (!InputUtils.validateInput(name) || name.length > 100) {
      zSetLoading(false);
      setInvalidInput(true);
      return false;
    } else {
      setInvalidInput(false);
    }

    // post input
    try {
      const isSuccess = await projectApi.updateProject(projectId, {name});
      if (!isSuccess) throw new Error('error updating project name');

      // also update zustand state
      zUpdateProject({name}, true);
    } catch (e) {
      zSetError('updateProjectName', {name, projectId}, 'Something went wrong while updating your episode title.');
    } finally {
      zSetLoading(false);
    }
  }

  function archiveProject() {
    openConfirmArchiveModal();
    close();
  }

  const body = (
    <Stack padding={'30px'} spacing={8}>
      <Stack>
        {/* <BodyMdSemiBold>Project Name:</BodyMdSemiBold> */}
        <BodyMd>{'Rename Project:'}</BodyMd>
        <ZEditable
          editableControlsOnLeft={true}
          fontSize={'md'}
          defaultValue={zProject.name}
          onSubmit={(value) => updateProjectName(value)}
          maxWidth={'20vw'}
        />
        {stateInvalidInput && (
          <BodySm mt={2} color={'red'}>
            The project name you entered is invalid. Please try again.
          </BodySm>
        )}
      </Stack>

      {zUser.teamId && (
        <Stack>
          <BodyMd>
            {zProject.isPrivate
              ? 'This project is hidden from your team. Would you like to make it public?'
              : 'This project is visible to your team. Would you like to make it private?'}
          </BodyMd>
          <ButtonSecondary onClick={() => toggleProjectPrivacy(!zProject.isPrivate)}>
            {zProject.isPrivate ? 'Make Project Public' : 'Make Project Private'}
          </ButtonSecondary>
        </Stack>
      )}

      <Stack>
        <BodyMd>
          Would you like to archive this project so that it no longer appears in your list of projects? If you do, you
          will not be able to access the episodes in this project, and the data will eventually be deleted permanently.
        </BodyMd>
        <ButtonSecondary onClick={() => archiveProject()} leftIcon={<Icon as={FiTrash} />}>
          Archive Project
        </ButtonSecondary>
      </Stack>
    </Stack>
  );

  return (
    <ModalShell
      body={body}
      header={'Project Settings'}
      modalSize={'3xl'}
      disclosure={{isOpen, onOpen, onClose: close}}
      closeOnEsc={true}
    />
  );
}

function EpisodeSettingsModal({
  openThisModal,
  episodeApi,
  zEpisode,
  zUpdateEpisode,
  zSetLoading,
  zSetError,
  onCloseCallback = () => {},
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const projectId = zEpisode?.projectId;
  const episodeId = zEpisode?.episodeId;
  const episodeUuid = projectId + '___' + episodeId;

  useEffect(() => {
    if (openThisModal && !isOpen) {
      console.log('opening modal');
      onOpen();
      setInvalidInput(false);
    }
  }, [openThisModal]);

  const [stateInvalidInput, setInvalidInput] = useState();

  if (!zEpisode) return null;

  function close() {
    onClose();
    onCloseCallback();
  }

  async function updateEpisodeName(name) {
    zSetLoading('Updating...');
    name = name.trim();

    // validate input
    if (!InputUtils.validateInput(name) || name.length > 100) {
      zSetLoading(false);
      setInvalidInput(true);
      return false;
    } else {
      setInvalidInput(false);
    }

    // post input
    try {
      const isSuccess = await episodeApi.updateEpisode({fileName: name});
      if (!isSuccess) throw new Error('error updating project name');

      // also update zustand state
      zUpdateEpisode(episodeId, {fileName: name}, true);
    } catch (e) {
      zSetError('updateEpisodeName', {name, episodeUuid}, 'Something went wrong while updating your episode title.');
    } finally {
      zSetLoading(false);
    }
  }

  const body = (
    <Stack spacing={8} minHeight={'30vh'} py={8}>
      <Stack justifyContent={'space-between'} height={'100%'} flexGrow={1}>
        <Stack>
          <BodySmSemiBold>Edit File Name:</BodySmSemiBold>
          <ZEditable
            mt={0}
            fontSize={'md'}
            defaultValue={zEpisode.fileName}
            onSubmit={(value) => updateEpisodeName(value)}
            editableControlsOnLeft={false}
            maxWidth={'20vw'}
          />
          {stateInvalidInput && (
            <BodySm mt={2} color={'red'}>
              The episode name you entered is invalid. Please try again.
            </BodySm>
          )}
        </Stack>

        <Stack>
          <BodySmSemiBold>Have Questions?</BodySmSemiBold>
          <ButtonSecondary
            onClick={() => {
              if (window.$crisp) {
                window.$crisp.do('chat:open');
              }
            }}
          >
            Send us a message
          </ButtonSecondary>
        </Stack>

        <Flex justifyContent={'start'} gap={2}>
          <BodySmSemiBold>Watch Tutorial?</BodySmSemiBold>
          <FlowHelpIcon
            ytSrc={'https://www.youtube.com/embed/LdRsR4glu7U?si=cmFAWv6q7Xi1bjAL&autoplay=1'}
            ytTitle={'Podflow Episode Tutorial Video'}
            tooltipLabel={'Watch Quick Tutorial'}
            showPingAnimation={true}
          />
        </Flex>
      </Stack>
    </Stack>
  );

  return (
    <ModalShell
      body={body}
      header={'Episode Menu'}
      modalSize={'3xl'}
      disclosure={{isOpen, onOpen, onClose: close}}
      closeOnEsc={true}
    />
  );
}

function ZModal({trigger, ...props}) {
  // dyanmic props // this way, the entire modal can be customized and dynamically changed inline code
  const header = trigger?.header; // optional if titleText is provided
  const body = trigger?.body; // optional if bodyText is provided
  const footer = trigger?.footer; // optional if positiveText is provided
  const titleText = trigger?.titleText ?? 'Are you sure?';
  const bodyText = trigger?.bodyText ?? 'TODO: body text';
  const positiveText = trigger?.positiveText ?? 'Continue';
  const neutralText = trigger?.neutralText ?? 'Cancel';
  const positiveCallback = trigger?.positiveCallback ?? (() => console.log('TODO positive callback'));
  const onCloseCallback = trigger?.onCloseCallback ?? (() => {});
  const closeOnEsc = trigger?.closeOnEsc ?? true;
  const modalSize = trigger?.modalSize ?? 'xl';

  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (trigger) {
      if (!isOpen) onOpen();
    } else {
      if (isOpen) onClose();
    }
  }, [trigger]);

  const [stateWasOpened, setWasOpened] = useState(false); // onCloseCallback is called only once when the modal is closed
  useEffect(() => {
    if (isOpen) setWasOpened(true);
    if (!isOpen && stateWasOpened) {
      setWasOpened(false);
      onCloseCallback();
    }
  }, [isOpen]);

  const OptionalFooter = () => (
    <Flex justifyContent={'end'} gap={2}>
      {neutralText && <ButtonSecondary onClick={onClose}>{neutralText}</ButtonSecondary>}
      {positiveText && <ButtonPrimary onClick={() => positiveCallback()}>{positiveText}</ButtonPrimary>}
    </Flex>
  );

  const OptionalBody = () => (
    <Stack>
      <BodySm whiteSpace="pre-line">{bodyText}</BodySm>
    </Stack>
  );

  const mHeader = header ? header : <BodyMdSemiBold>{titleText}</BodyMdSemiBold>;
  const mBody = body ? body : <OptionalBody />;
  const mFooter = footer ? footer : <OptionalFooter />;

  return (
    <ModalShell
      header={mHeader}
      body={mBody}
      footer={mFooter}
      disclosure={{isOpen, onOpen, onClose}}
      closeOnEsc={closeOnEsc}
      modalSize={modalSize}
      {...props}
    />
  );
}

function ModalShell({
  closedView,
  body,
  header,
  footer,
  initialFocusRef,
  disclosure,
  modalSize,
  showCloseButton,
  bodyProps,
  closeOnEsc = false,
}) {
  const {isOpen, onClose} = disclosure;

  return (
    <Stack spacing={0}>
      {closedView && closedView}

      <Modal
        isOpen={isOpen}
        initialFocusRef={initialFocusRef ?? null}
        size={modalSize ?? 'xl'}
        onClose={onClose}
        isCentered
        closeOnEsc={closeOnEsc}
      >
        <ModalOverlay />
        <ModalContent>
          {header && <ModalHeader>{header}</ModalHeader>}
          {(showCloseButton ?? true) && <ModalCloseButton />}
          <ModalBody {...bodyProps}>{body}</ModalBody>
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export {
  ConfirmModal,
  InviteUserModal,
  YoutubeUploadModal,
  ModalShell,
  SearchReplaceModal,
  InputModal,
  SubscriptionUpgradeModal,
  PromptUpgradeModal,
  PromptInteractionModal,
  CreateProjectModal,
  PromptSelectionModal,
  TemplateCreateModal,
  YoutubeEmbedModal,
  ProjectSettingsModal,
  EpisodeSettingsModal,
  ZModal,
};
