// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
// CHANGES MADE TO THIS FILE MUST ALSO BE MADE IN THE LAMBDA EQUIVALENT prompt_dep_graphs.py
// // // // // // // // // // // // // // // // // // // // // // // // // // // //  // // // //

export default class PromptDepGraphs {
  static DEFAULT_USER_DEFINED_PROMPT_DEP_KEY = 'default_user_defined_prompt_dependencies';
  static PROMPT_DEP_GRAPHS = {
    V_12_19_23: {
      pid_default_episode_description_v2: [
        'pid_default_topic_summary',
        'pid_default_guest_bio_v2',
        'pid_default_speaker_perspectives_v2',
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
      ],
      pid_default_topic_summary: ['pid_default_topic_filtering', 'pid_default_topic_modeling'],
      pid_default_topic_filtering: ['pid_default_topic_modeling'],
      pid_default_topic_modeling: [],
      pid_default_guest_bio_v2: ['pid_default_topic_ubiquity_v2'],
      pid_default_topic_ubiquity_v2: [
        'pid_default_episode_summary',
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_episode_summary: [
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_speaker_perspectives_v2: [
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_topic_highlight: [
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_quotes: [],
      pid_default_episode_title_v2: [
        'pid_default_episode_description_v2',
        'pid_default_guest_bio_v2',
        'pid_default_topic_highlight',
      ],
      pid_default_general_social_post: ['pid_default_quotes', 'pid_default_topic_summary'],
      pid_default_hashtags_v2: ['pid_default_episode_description_v2', 'pid_default_topic_filtering'],
      pid_default_general_blog_v2: [
        'pid_default_quotes',
        'pid_default_episode_summary',
        'pid_default_topic_ubiquity_v2',
      ],
      default_user_defined_prompt_dependencies: ['pid_default_episode_summary', 'pid_default_topic_highlight'], // DEFAULT_USER_DEFINED_PROMPT_DEP_KEY
    },
    V_1_22_24_OLD: {
      pid_default_episode_description_v2: [
        'pid_default_topic_summary',
        'pid_default_guest_bio_v2',
        'pid_default_speaker_perspectives_v2',
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
      ],
      pid_default_topic_summary: ['pid_default_topic_filtering', 'pid_default_topic_modeling'],
      pid_default_topic_filtering: ['pid_default_topic_modeling'],
      pid_default_topic_modeling: [],
      pid_default_guest_bio_v2: ['pid_default_topic_ubiquity_v2'],
      pid_default_topic_ubiquity_v2: [
        'pid_default_episode_summary',
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_episode_summary: [
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_speaker_perspectives_v2: [
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_topic_highlight: [
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_quotes: [],
      pid_default_episode_title_v2: [
        'pid_default_episode_summary',
        'pid_default_guest_bio_v2',
        'pid_default_topic_highlight',
      ],
      pid_default_general_social_post: ['pid_default_quotes', 'pid_default_topic_summary'],
      pid_default_hashtags_v2: ['pid_default_episode_summary', 'pid_default_topic_filtering'],
      pid_default_general_blog_v2: [
        'pid_default_quotes',
        'pid_default_episode_summary',
        'pid_default_topic_ubiquity_v2',
      ],
      pid_default_key_tips: ['pid_default_topic_summary', 'pid_default_topic_filtering', 'pid_default_topic_modeling'],
      pid_default_key_learnings: [
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_key_questions: [
        'pid_default_episode_summary',
        'pid_default_key_learnings',
        'pid_default_key_tips',
        'pid_default_topic_summary',
        'pid_default_topic_filtering',
        'pid_default_topic_modeling',
      ],
      pid_default_seo_keywords: ['pid_default_topic_ubiquity_v2'],
      pid_default_instagram_captions: [
        'pid_default_key_questions',
        'pid_default_key_learnings',
        'pid_default_key_tips',
        'pid_default_hashtags_v2',
      ],
      pid_default_facebook_posts: ['pid_default_quotes'],
      pid_default_linkedin_post: ['pid_default_key_questions', 'pid_default_key_learnings'],
      pid_default_twitter_posts: ['pid_default_quotes', 'pid_default_key_questions', 'pid_default_episode_summary'],
      pid_default_clip_captions: ['pid_default_key_questions', 'pid_default_hashtags_v2'],
      pid_default_youtube_description: [
        'pid_default_episode_description_v2',
        'pid_default_topic_highlight',
        'pid_default_hashtags_v2',
      ],
      pid_default_blog_faq: ['pid_default_topic_ubiquity_v2', 'pid_default_key_questions'],
      pid_default_email_subjects: ['pid_default_topic_ubiquity_v2'],
      pid_default_email_newsletter_engagement: ['pid_default_key_questions', 'pid_default_key_learnings'],
      default_user_defined_prompt_dependencies: ['pid_default_episode_summary'],
    },
    V_1_22_24: {
      // V_2_6_24
      pid_default_episode_description_v2: [
        'pid_default_topic_summary',
        'pid_default_guest_bio_v2',
        'pid_default_speaker_perspectives_v2',
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
      ],
      pid_default_topic_summary: ['pid_default_topic_filtering'],
      pid_default_topic_filtering: ['pid_default_topic_modeling', 'pid_default_topic_ubiquity_v2'],
      pid_default_topic_modeling: [],
      pid_default_guest_bio_v2: ['pid_default_topic_ubiquity_v2'],
      pid_default_topic_ubiquity_v2: ['pid_default_topic_modeling'],
      pid_default_episode_summary: ['pid_default_topic_summary'],
      pid_default_speaker_perspectives_v2: ['pid_default_topic_ubiquity_v2'],
      pid_default_topic_highlight: ['pid_default_topic_summary'],
      pid_default_quotes: [],
      pid_default_episode_title_v2: [
        'pid_default_episode_summary',
        'pid_default_guest_bio_v2',
        'pid_default_topic_highlight',
      ],
      pid_default_general_social_post: ['pid_default_quotes', 'pid_default_topic_summary'],
      pid_default_hashtags_v2: ['pid_default_episode_summary', 'pid_default_topic_filtering'],
      pid_default_general_blog_v2: [
        'pid_default_quotes',
        'pid_default_episode_summary',
        'pid_default_topic_ubiquity_v2',
      ],
      pid_default_key_tips: ['pid_default_topic_summary'],
      pid_default_key_learnings: ['pid_default_topic_summary'],
      pid_default_key_questions: ['pid_default_episode_summary', 'pid_default_key_learnings', 'pid_default_key_tips'],
      pid_default_seo_keywords: ['pid_default_topic_ubiquity_v2'],
      pid_default_instagram_captions: [
        'pid_default_key_questions',
        'pid_default_key_learnings',
        'pid_default_key_tips',
        'pid_default_hashtags_v2',
      ],
      pid_default_facebook_posts: ['pid_default_quotes'],
      pid_default_linkedin_post: ['pid_default_key_questions', 'pid_default_key_learnings'],
      pid_default_twitter_posts: ['pid_default_quotes', 'pid_default_key_questions', 'pid_default_episode_summary'],
      pid_default_clip_captions: ['pid_default_key_questions', 'pid_default_hashtags_v2'],
      pid_default_youtube_description: [
        'pid_default_episode_description_v2',
        'pid_default_topic_highlight',
        'pid_default_hashtags_v2',
      ],
      pid_default_blog_faq: ['pid_default_topic_ubiquity_v2', 'pid_default_key_questions'],
      pid_default_email_subjects: ['pid_default_topic_ubiquity_v2'],
      pid_default_email_newsletter_engagement: ['pid_default_key_questions', 'pid_default_key_learnings'],
      default_user_defined_prompt_dependencies: ['pid_default_episode_summary'],
    },
    V_3_5_24: {
      pid_default_episode_description_v2: [
        'pid_default_topic_summary',
        'pid_default_guest_bio_v2',
        'pid_default_speaker_perspectives_v2',
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
      ],
      pid_default_topic_summary: ['pid_default_topic_filtering'],
      pid_default_topic_filtering: ['pid_default_topic_modeling', 'pid_default_topic_ubiquity_v2'],
      pid_default_topic_modeling: [],
      pid_default_guest_info: ['pid_default_topic_ubiquity_v2'],
      pid_default_guest_bio_v2: ['pid_default_guest_info', 'pid_default_topic_ubiquity_v2'],
      pid_default_topic_ubiquity_v2: ['pid_default_topic_modeling'],
      pid_default_episode_summary: ['pid_default_topic_summary'],
      pid_default_speaker_perspectives_v2: ['pid_default_topic_ubiquity_v2'],
      pid_default_topic_highlight: ['pid_default_topic_summary'],
      pid_default_quotes: [],
      pid_default_episode_title_v2: [
        'pid_default_episode_summary',
        'pid_default_guest_bio_v2',
        'pid_default_topic_highlight',
      ],
      pid_default_general_social_post: ['pid_default_quotes', 'pid_default_topic_summary'],
      pid_default_hashtags_v2: ['pid_default_episode_summary', 'pid_default_topic_filtering'],
      pid_default_general_blog_v2: [
        'pid_default_quotes',
        'pid_default_episode_summary',
        'pid_default_topic_ubiquity_v2',
      ],
      pid_default_key_tips: ['pid_default_topic_summary'],
      pid_default_key_learnings: ['pid_default_topic_summary'],
      pid_default_key_questions: ['pid_default_episode_summary', 'pid_default_key_learnings', 'pid_default_key_tips'],
      pid_default_seo_keywords: ['pid_default_topic_ubiquity_v2'],
      pid_default_instagram_captions: [
        'pid_default_key_questions',
        'pid_default_key_learnings',
        'pid_default_key_tips',
      ],
      pid_default_facebook_posts: ['pid_default_quotes'],
      pid_default_linkedin_post: ['pid_default_key_questions', 'pid_default_key_learnings'],
      pid_default_twitter_posts: ['pid_default_quotes', 'pid_default_key_questions', 'pid_default_episode_summary'],
      pid_default_clip_captions: ['pid_default_key_questions', 'pid_default_hashtags_v2'],
      pid_default_youtube_description: [
        'pid_default_episode_description_v2',
        'pid_default_topic_highlight',
        'pid_default_hashtags_v2',
      ],
      pid_default_blog_faq: ['pid_default_topic_ubiquity_v2', 'pid_default_key_questions'],
      pid_default_email_subjects: ['pid_default_topic_ubiquity_v2'],
      pid_default_email_newsletter_engagement: ['pid_default_key_questions', 'pid_default_key_learnings'],
      default_user_defined_prompt_dependencies: ['pid_default_episode_summary'],
    },
    V_3_5_24: {
      pid_default_episode_description_v2: [
        'pid_default_topic_summary',
        'pid_default_guest_bio_v2',
        'pid_default_speaker_perspectives_v2',
        'pid_default_topic_ubiquity_v2',
        'pid_default_episode_summary',
      ],
      pid_default_topic_summary: ['pid_default_topic_filtering'],
      pid_default_topic_filtering: ['pid_default_topic_modeling', 'pid_default_topic_ubiquity_v2'],
      pid_default_topic_modeling: [],
      pid_default_guest_info: ['pid_default_topic_ubiquity_v2'],
      pid_default_guest_bio_v2: ['pid_default_guest_info', 'pid_default_topic_ubiquity_v2'],
      pid_default_topic_ubiquity_v2: ['pid_default_topic_modeling'],
      pid_default_episode_summary: ['pid_default_topic_summary'],
      pid_default_speaker_perspectives_v2: ['pid_default_topic_ubiquity_v2'],
      pid_default_topic_highlight: ['pid_default_topic_summary'],
      pid_default_quotes: [],
      pid_default_episode_title_v2: [
        'pid_default_episode_summary',
        'pid_default_guest_bio_v2',
        'pid_default_topic_highlight',
      ],
      pid_default_general_social_post: ['pid_default_quotes', 'pid_default_topic_summary'],
      pid_default_hashtags_v2: ['pid_default_episode_summary', 'pid_default_topic_filtering'],
      pid_default_general_blog_v2: [
        'pid_default_quotes',
        'pid_default_episode_summary',
        'pid_default_topic_ubiquity_v2',
      ],
      pid_default_key_tips: ['pid_default_topic_summary'],
      pid_default_key_learnings: ['pid_default_topic_summary'],
      pid_default_key_questions: ['pid_default_episode_summary', 'pid_default_key_learnings', 'pid_default_key_tips'],
      pid_default_seo_keywords: ['pid_default_topic_ubiquity_v2'],
      pid_default_instagram_captions: [
        'pid_default_key_questions',
        'pid_default_key_learnings',
        'pid_default_key_tips',
      ],
      pid_default_facebook_posts: ['pid_default_quotes'],
      pid_default_linkedin_post: ['pid_default_key_questions', 'pid_default_key_learnings'],
      pid_default_twitter_posts: ['pid_default_quotes', 'pid_default_key_questions', 'pid_default_episode_summary'],
      pid_default_clip_captions: ['pid_default_key_questions', 'pid_default_hashtags_v2'],
      pid_default_youtube_description: [
        'pid_default_episode_description_v2',
        'pid_default_topic_highlight',
        'pid_default_hashtags_v2',
      ],
      pid_default_blog_faq: ['pid_default_topic_ubiquity_v2', 'pid_default_key_questions'],
      pid_default_email_subjects: ['pid_default_topic_ubiquity_v2'],
      pid_default_email_newsletter_engagement: ['pid_default_key_questions', 'pid_default_key_learnings'],
      default_user_defined_prompt_dependencies: ['pid_default_episode_summary'],
    },
  };
}
