import {EditorAPI} from '../api-v2';
import {Loader} from '../loader';
import {EpisodeEditor} from './episode-editor';

// todo rename variables to camel case
export class TextManager {
  constructor(fileUDID) {
    this.fileUDID = fileUDID;

    this.currentAudioTime = 0.0; // in seconds - todo needs to be replaced with state manager posistion
    this.totalAudioTime = 0.0; // in seconds - todo needs to be replaced with state manager posistion
    this.demo_audio_url = 'https://sur4ide-audio.s3.us-west-2.amazonaws.com/episodes/30S_441K_128BR.mp3'; // todo stop using default data
    this.transcriptUrl =
      'https://sur4ide-aws-transcription.s3.us-west-2.amazonaws.com/raw/63b77e8c44641e0a186ffe44___1677048481433.json'; // todo stop using default data

    this.start_index = 0;
    this.end_index = 0;

    this.api = new EditorAPI(fileUDID);
    this.transcript = [];
    this.wordItems = [];
    this.editorStack = []; // Deleted Text Elements Stack for Undo [type, last_start_key, last_deleted_text_elements] // type: add, delete, modify

    this.init();
  }

  async init() {
    this.getTranscript();
  }

  async getTranscript() {
    const loader = new Loader(() => this.api.getTranscriptFromS3(this.transcriptUrl));
    loader.isDone((x) => x?.results);
    loader.isError((x) => x?.status !== 200);
    loader.error((x) => {
      EpisodeEditor.i().tmOnTranscriptLoadError();
    });
    loader.done((x) => {
      this.setWordItems(x?.results?.items);
      this.setTranscript(x?.results?.transcripts);
    });
    loader.start();
  }

  // Handle Selection
  handleSelectionChange(event) {
    // setSelectedTextElements([]);
    // // Check the undefined case
    console.log('selection change');
    const selection = window.getSelection().getRangeAt(0);
    const startNode = selection.startContainer;
    const endNode = selection.endContainer;

    if (startNode.childNodes.length !== 0 || endNode.childNodes.length !== 0) {
      return;
    }

    if (startNode.parentNode.localName === 'div') {
      this.start_index = parseInt(startNode.nextSibling.dataset.index);
    } else {
      this.start_index = parseInt(startNode.parentNode.dataset.index);
    }
    if (endNode.parentNode.localName === 'div') {
      if (parseInt(endNode.nextSibling.dataset.index) === this.start_index) {
        this.end_index = this.start_index;
      } else {
        this.end_index = parseInt(endNode.nextSibling.dataset.index) - 1;
      }
    } else {
      this.end_index = parseInt(endNode.parentNode.dataset.index);
    }

    if (EpisodeEditor.i().wf) {
      EpisodeEditor.i().wf.clearRegions();
      EpisodeEditor.i().wf.addRegion({
        start: this.wordItems[this.start_index].start_time,
        end: this.wordItems[this.end_index].end_time,
        color: 'rgba(10, 10, 10, 0.1)',
      });
      const new_center = this.wordItems[this.start_index].start_time / EpisodeEditor.i().wf.getDuration();
      if (new_center < 0 || new_center > 1) {
        EpisodeEditor.i().wf.seekTo(0);
      } else {
        EpisodeEditor.i().wf.seekAndCenter(
          this.wordItems[this.start_index].start_time / EpisodeEditor.i().wf.getDuration(),
        );
      }
    }
  }

  // Delete Function
  async delete_audio_clip() {
    // todo de-duplicate this code that is seemingly similar to the delete keypress handling in TextEditor.js
    console.log('delete');
    // update the timeline
    let time_to_delete =
      parseFloat(this.wordItems[this.end_index].end_time) - parseFloat(this.wordItems[this.start_index].start_time);
    for (let i = this.end_index + 1; i < this.wordItems.length; i++) {
      this.wordItems[i].start_time = parseFloat(this.wordItems[i].start_time) - time_to_delete;
      this.wordItems[i].end_time = parseFloat(this.wordItems[i].end_time) - time_to_delete;
    }

    this.setWordItems([...this.wordItems.slice(0, this.start_index), ...this.wordItems.slice(this.end_index + 1)]);
    const start_time_peak_index = Math.floor(parseFloat(this.wordItems[this.start_index].start_time) * 600);
    const end_time_peak_index = Math.floor(parseFloat(this.wordItems[this.end_index].end_time) * 600);
    const updated_peaks = [
      ...EpisodeEditor.i().peaks.slice(0, start_time_peak_index),
      ...EpisodeEditor.i().peaks.slice(end_time_peak_index),
    ];
    EpisodeEditor.i().setPeaks(updated_peaks);
    this.setEditorStack([
      ...this.editorStack,
      {
        type: 'DELETE',
        start_index: this.start_index,
        items: this.wordItems.slice(this.start_index, this.end_index + 1),
        peaks: EpisodeEditor.i().peaks.slice(start_time_peak_index, end_time_peak_index),
      },
    ]);
    await EpisodeEditor.i().wf.load(this.demo_audio_url, updated_peaks);
    if (EpisodeEditor.i().wf) {
      console.log('clearing regions');
      EpisodeEditor.i().wf.clearRegions();
    }
  }

  undo_audio_clip() {
    console.log('undo');
    if (this.editorStack.length === 0) {
      return;
    }
    const last_edit = this.editorStack.pop();

    // Delete Case
    if (last_edit.type === 'DELETE') {
      this.setEditorStack([...this.editorStack]);
      // update the timeline
      let time_to_add =
        parseFloat(last_edit.items[last_edit.items.length - 1].end_time) - parseFloat(last_edit.items[0].start_time);
      for (let i = last_edit.start_index; i < this.wordItems.length; i++) {
        this.wordItems[i].start_time = parseFloat(this.wordItems[i].start_time) + time_to_add;
        this.wordItems[i].end_time = parseFloat(this.wordItems[i].end_time) + time_to_add;
      }
      this.setWordItems([
        ...this.wordItems.slice(0, last_edit.start_index),
        ...last_edit.items,
        ...this.wordItems.slice(last_edit.start_index),
      ]);
      const start_time_peak_index = Math.floor(parseFloat(this.wordItems[last_edit.start_index].start_time) * 600);
      const updated_peaks = [
        ...EpisodeEditor.i().peaks.slice(0, start_time_peak_index),
        ...last_edit.peaks,
        ...EpisodeEditor.i().peaks.slice(start_time_peak_index),
      ];
      EpisodeEditor.i().setPeaks(updated_peaks);
      EpisodeEditor.i().wf.current.clearRegions();
      EpisodeEditor.i().wf.current.load(this.demo_audio_url, updated_peaks);
    }
  }

  setWordItems(items) {
    this.wordItems = items;
    EpisodeEditor.i().tmOnWordItems(items);
  }

  setTranscript(transcript) {
    this.transcript = transcript;
    EpisodeEditor.i().tmOnTranscript(transcript);
  }

  setEditorStack(stack) {
    this.editorStack = stack;
  }
}
