import {create} from 'zustand';

import {ensureIsObject} from '../utils';
import {API} from '../utils/api-v2';

/**
 * This is a store for the app's global state.
 *
 * @typedef {object} AppStore
 * @property {boolean} isLoading
 * @property {(isLoading: boolean) => void} setLoading
 * @property {{ origin: string, displayMessage: string, data: object | string | any[], status: string } | null} error
 * @property {(origin: string, data: object | string | any[], displayMessage: string, status: string) => Promise<void>} setError
 * @property {() => void} hideError
 * @property {{ name: string, path: string }[]} breadcrumbs
 * @property {(breadcrumbs: { name: string, path: string }[]) => void} setBreadcrumbs
 * @property {(breadcrumb: { name: string, path: string }) => void} pushBreadcrumb
 * @property {(untilName: string) => void} popBreadcrumbs
 */

/**
 * @param {(partial: Partial<AppStore> | (state: AppStore) => Partial<AppStore>, replace?: boolean | undefined) => void} set
 * @param {() => AppStore} get
 * @param {import('zustand').StoreApi<AppStore>} api
 * @returns {AppStore}
 */
const appStore = (set, get, api) => ({
  isLoading: false,
  setLoading: (isLoading) => set((state) => ({isLoading})),

  error: null, // { origin: "", displayMessage: "", data: {} || "" || [], status: "" },
  setError: async (origin = null, data = null, displayMessage = null, status = 'warning') => {
    set((state) => ({error: {origin, displayMessage, data, status}}));

    // Add the URL to the error data.
    data = ensureIsObject(data);
    data.url = window.location.href;

    // Send the error data to the API. The UI will not wait for this to finish.
    const api = new API(null);
    api.postError(origin, 'frontend error', data, status).catch((e) => console.error('Error posting error API', e));
  },
  hideError: () => set((state) => ({error: null})),

  // list of objects with a name and a link
  breadcrumbs: [{name: 'projects', path: '/projects'}],
  setBreadcrumbs: (breadcrumbs) => set((state) => ({breadcrumbs})),
  pushBreadcrumb: (breadcrumb) => set((state) => ({breadcrumbs: [...state.breadcrumbs, breadcrumb]})),
  popBreadcrumbs: (untilName) =>
    set((state) => {
      let breadcrumbsCopy = [...state.breadcrumbs];
      while (breadcrumbsCopy.length > 0) {
        const lastBreadcrumb = breadcrumbsCopy[breadcrumbsCopy.length - 1];
        if (lastBreadcrumb.name === 'projects' || lastBreadcrumb.name === untilName) {
          break;
        }
        breadcrumbsCopy.pop();
      }
      return {breadcrumbs: breadcrumbsCopy};
    }),
});

export default create(appStore);
