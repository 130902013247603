import {useAuth0} from '@auth0/auth0-react';
import {Button, Icon, Stack, Text} from '@chakra-ui/react';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {FiCheckCircle, FiUser} from 'react-icons/fi';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import Cookies from 'universal-cookie';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import authStore from '../../stores/auth-store';
import SessionStorageUtils from '../../utils/session-storage-utils';

const Auth = ({origin}) => {
  // Authentication
  let {loginWithRedirect, isAuthenticated} = useAuth0();
  const [stateDidResendEmailVerification, setDidResendEmailVerification] = useState(false);

  // navigation
  const navigate = useNavigate();
  const searchParams = useSearchParams();

  // Store
  const zAuthUser = authStore((state) => state.authUser);

  // Modals
  const [displayTutorialModal, setDisplayTutorialModal] = useState(false);

  useEffect(() => {
    checkAuthComplete();
  }, [zAuthUser]);

  function checkAuthComplete() {
    console.log('checkAuthComplete email_verified:', zAuthUser?.email_verified, 'zAuthUser:', zAuthUser);

    if (zAuthUser && zAuthUser.email_verified) {
      setDisplayTutorialModal(false);
      navigateToPage();
    }
  }

  function navigateToPage() {
    const params = [];

    const searchParamsObj = Object.fromEntries(searchParams[0].entries());
    for (const key in searchParamsObj) {
      params.push([key, searchParamsObj[key]]);
    }

    console.log('navigating', origin, params);

    const search = createSearchParams(Object.fromEntries(searchParams[0].entries())).toString();
    console.log('search', search);
    navigate({pathname: origin, search: search});
  }

  function recheckEmailVerified() {
    // TRY 3: logout the user

    // TRY 3: Try this:
    // https://github.com/auth0/nextjs-auth0/issues/23#issuecomment-774034572
    // https://auth0.github.io/auth0-spa-js/interfaces/AuthorizationParams.html

    // TRY 2: on safari, the token isn't refreshed on loginWithRedirect - works perfectly on chrome
    // On second thought, it looks like this is working on safari too. Have a brand new user test this.
    SessionStorageUtils.setPreAuthPath();
    loginWithRedirect({redirectUri: window.location.origin + '/'});

    // TRY 1: This doesn't work because the token isn't refreshed
    // window.location.reload();
  }

  async function resendVerificationEmail() {
    console.log('resendVerificationEmail');
    const response = await axios.post(process.env.REACT_APP_BASE_URL + '/auth0/resend-verification-email', {
      rawUserSub: zAuthUser.sub,
    });
    const jobId = response?.data?.jobId;
    if (jobId) {
      setDidResendEmailVerification(true);
    } else console.log('resendVerificationEmail failed');
  }

  function setUserDismissedTutorialCookie(userDismissedTutorial) {
    const cookies = new Cookies();
    cookies.set('userDismissedTutorial', userDismissedTutorial, {path: '/', maxAge: 60 * 60 * 24 * 30});
  }

  const LoginSignup = () => (
    <>
      <Stack spacing={4}>
        <Text fontFamily="Inter" lineHeight="1.2" fontWeight="bold" fontSize="30px" color="#000000" textAlign="center">
          Welcome to Podflow!
        </Text>
        <Text
          fontFamily="Inter"
          lineHeight="1.56"
          fontWeight="regular"
          fontSize="18px"
          color="#000000"
          textAlign="center"
        >
          Sign up or login to start creating with Podflow.
        </Text>
      </Stack>
      <Stack direction={'row'}>
        <Button
          size="md"
          colorScheme="white"
          textColor={'black'}
          onClick={() => {
            SessionStorageUtils.setPreAuthPath();
            loginWithRedirect({redirectUri: window.location.origin + '/'});
          }}
        >
          Login
        </Button>
        <ButtonPrimary
          leftIcon={<Icon as={FiUser} />}
          size="md"
          colorScheme="blue"
          onClick={() => {
            SessionStorageUtils.setPreAuthPath();
            loginWithRedirect({
              screen_hint: 'signup',
              redirectUri: window.location.origin + '/',
            });
          }}
        >
          Sign Up
        </ButtonPrimary>
      </Stack>
    </>
  );

  const VerifyEmail = () => (
    <>
      <Stack spacing={4}>
        <Text fontFamily="Inter" lineHeight="1.2" fontWeight="bold" fontSize="30px" color="#000000" textAlign="center">
          Please verify your email address.
        </Text>
        <Text
          fontFamily="Inter"
          lineHeight="1.56"
          fontWeight="regular"
          fontSize="18px"
          color="#000000"
          textAlign="center"
        >
          Let's get started! You should have received an email requesting email verification. Please check your spam
          folder if you don't see the email in your inbox.
        </Text>
      </Stack>
      <Stack direction={'row'}>
        <Button
          variant="solid"
          colorScheme={stateDidResendEmailVerification ? 'green' : 'gray'}
          isDisabled={stateDidResendEmailVerification}
          onClick={() => {
            resendVerificationEmail();
          }}
        >
          {stateDidResendEmailVerification ? 'Success' : 'Resend Verification Email'}
        </Button>
        <ButtonPrimary
          leftIcon={<Icon as={FiCheckCircle} />}
          size="md"
          colorScheme="blue"
          tooltipLabel={"Click here once you've verified your email."}
          onClick={recheckEmailVerified}
        >
          Check Verification
        </ButtonPrimary>
      </Stack>
    </>
  );

  const Body = () => (
    <Stack height={'100%'}>
      <Stack
        mt={'30px'}
        paddingX="150px"
        paddingY="150px"
        borderRadius="8px"
        justify="center"
        align="center"
        spacing={8}
        borderColor="#bdbdbd"
        borderStartWidth="2px"
        borderEndWidth="2px"
        borderTopWidth="2px"
        borderBottomWidth="2px"
        borderStyle="dashed"
        backgroundColor="#fafafa"
      >
        {!isAuthenticated ? <LoginSignup /> : <VerifyEmail />}
      </Stack>
    </Stack>
  );

  return (
    <FlowContainer>
      <FlowHeader title={isAuthenticated ? 'Welcome to Podflow' : ''} />
      <FlowBody>
        <Body />
        {/* {displayTutorialModal ? <TutorialModal setUserDismissedTutorialCookie={setUserDismissedTutorialCookie} /> : <></>} */}
      </FlowBody>
      <FlowFooter />
    </FlowContainer>
  );
};

export default Auth;
