import TagManager from 'react-gtm-module';

export default class GTM {
  // these must be added as custom events here: https://analytics.google.com/analytics/web/#/a258843796p355970604/admin/streams/table/4665858271
  static EVENT_TEST = 'app_test';
  static EVENT_FILE_UPLOAD = 'app_file_upload';
  static EVENT_SIGNUP = 'app_signup';
  static EVENT_SUBSCRIBE = 'app_subscription';

  conversionSignup(userSub, email) {
    // recognize conversion
    GTM.pushEvent(GTM.EVENT_SIGNUP, {userSub, email});
  }

  /** price is in us dollars ($24.00 == 24.00) */
  conversionSubscription(userSub, email, subscription_price, subscription_transaction_id) {
    // recognize conversion
    try {
      window.rewardful('convert', {email: email});
    } catch (e) {
      console.log('error in rewardful conversion', e);
    }
    GTM.pushEvent(GTM.EVENT_SUBSCRIBE, {userSub, email, subscription_price, subscription_transaction_id});
  }

  conversionFileUpload(userSub, email) {
    GTM.pushEvent(GTM.EVENT_FILE_UPLOAD, {userSub, email});
  }

  /**
   *
   * @param {String} eventName - see list of available event types specified as static constants in this class
   */
  static pushEvent(eventName, customParams = {}) {
    console.log('GTM push event', eventName, 'custom params', customParams);
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...customParams,
      },
    });
  }
}
