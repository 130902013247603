import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  RadioGroup,
  Stack,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';
import {forwardRef, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {FiEdit, FiExternalLink, FiPlus, FiRefreshCw, FiTrash} from 'react-icons/fi';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ZCard from '../../components/abstraction_high/ZCard';
import {ZInputCustom} from '../../components/abstraction_high/ZForm';
import ZRadioElementCard from '../../components/abstraction_high/ZRadioElementCard';
import {
  ZAccordionSingleItem,
  ZCheckboxGroup,
  ZDraggable,
  ZInputSavable,
  ZRadioGroup,
} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import {BodySm, BodySmMuted, BodySmSemiBold} from '../../components/common/TextStyle';
import {GPT} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';

/**
 * Template Item
 *
 * id: "tid_36b1f6"
 * name:
 * description:
 * formInputIds: [] // added as prompts are added to section elements list - or when explicity adding a form input
 * formInputIdsRequired: [] // added as prompts are added to section elements list
 * tags: ["podcast", "informative", "entertaining", "singlehost", "cohost", "guest"],
 * sections: []
 */

/**
 * Section Item
 *
 * id: "sid_36b1f6"
 * name:
 * description:
 * elements: [] // contains prompts and inputs
 */
const getDefaultSectionObject = () => ({id: 'sid_' + uuid().replace(/-/g, ''), elements: []});

const defaultTemplateObject = {
  id: 'tid_' + uuid().replace(/-/g, ''),
  name: '',
  description: '',
  formInputIds: [],
  tags: ['podcast', 'informative', 'entertaining', 'singlehost', 'cohost', 'guest'],
  sections: [getDefaultSectionObject()],
};

export const TemplateBuilder = ({defaultTemplate}) => {
  const {templateId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // const MAX_TOKENS = 2000;
  const {isOpen: isOpenAddBlock, onOpen: onOpenAddBlock, onClose: onCloseAddBlock} = useDisclosure();

  const {promptApi, templateApi, inputApi} = useAPI();

  const [statePromptOptions, setPromptOptions] = useState([]);
  const [stateEditingSectionIndex, setEditingSectionIndex] = useState(-1); // index of section being edited
  const [stateEditingElementIndex, setEditingElementIndex] = useState(); // index of element being edited
  const [stateEditElement, setEditElement] = useState(); // prompt selected for replacement
  const [stateTemplate, setTemplate] = useState(defaultTemplate ?? defaultTemplateObject);
  const [stateFormInputs, setFormInputs] = useState([]);

  const [stateMetadataAccordionOpen, setMetadataAccordionOpen] = useState(false);
  const [stateInputsAccordionOpen, setInputsAccordionOpen] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    let template;
    if (templateId) {
      template = await templateApi.getTemplate(templateId);
    }

    const promptOptions = await promptApi.getPrompts();

    let formInputs;
    if ((template?.formInputIds ?? []).length > 0) {
      formInputs = await inputApi.batchGetInputs(template.formInputIds);
    }

    if (template) {
      setTemplate(template);
    }

    if (promptOptions) {
      setPromptOptions(promptOptions.filter((prompt) => prompt.isDraft !== true));
    }

    if (formInputs) {
      setFormInputs(formInputs);
    }
  }

  function postTemplate(isDraft = false) {
    const templateCopy = {...stateTemplate};
    templateCopy.formInputIds = calculateInputIds(templateCopy);
    templateCopy.formInputIdsRequired = calculateInputIds(templateCopy, true);
    templateCopy.isDraft = isDraft;

    if (!isDraft && !checkTemplatePublishable(templateCopy)) return;

    templateApi.postTemplate(templateCopy);
    setTemplate(templateCopy);

    if (!templateId) {
      navigate(location.pathname + '/' + templateCopy.id);
    }
  }

  const saveDraft = () => postTemplate(true);

  /**
   *
   * @param {*} template
   * @param {*} getRequiredOnly - default false
   * @returns
   */
  function calculateInputIds(template, getRequiredOnly = false) {
    const formInputIdsSet = new Set(getRequiredOnly ? [] : template.formInputIds.filter((id) => id) ?? []);

    const collectCustomInputIds = (element, getRequiredOnly) => {
      // Collect customInputIds if the element type is 'prompt'
      if (element.type === 'prompt') {
        if (element.customInputIds) {
          element.customInputIds.forEach((input) => formInputIdsSet.add(input));
        }

        // Recursively call this function for nested elements
        if (element.elements) {
          element.elements.forEach((nestedElement) => collectCustomInputIds(nestedElement, getRequiredOnly));
        }
      }

      // Collect id if the element type is 'input'
      if (!getRequiredOnly && element.type === 'input' && element.id) {
        formInputIdsSet.add(element.id);
      }
    };

    // Collect IDs
    template.sections.forEach((section) =>
      section.elements.forEach((element) => collectCustomInputIds(element, getRequiredOnly)),
    );

    // Maintain original order present in templateCopy.formInputIds
    const orderedFormInputIds = [];
    const addedIdsSet = new Set();

    if (template && template.formInputIds) {
      template.formInputIds.forEach((id) => {
        if (formInputIdsSet.has(id)) {
          orderedFormInputIds.push(id);
          addedIdsSet.add(id);
        }
      });
    }

    // Append any new items from formInputIdsSet not already in template.formInputIds
    formInputIdsSet.forEach((id) => {
      if (!addedIdsSet.has(id)) {
        orderedFormInputIds.push(id);
      }
    });

    return orderedFormInputIds.filter((id) => id);
  }

  /**
   * Fetch and update form inputs based on the template
   * @param templateCopy - The template to use for fetching the inputs
   */
  function updateFormInputIds(templateCopy) {
    // Calculate unique derived input IDs
    const derivedInputIds = calculateInputIds(templateCopy);

    // Fetch the inputs based on derived input IDs
    inputApi
      .batchGetInputs(derivedInputIds)
      .then((inputs) => setFormInputs(inputs))
      .catch((error) => console.error('Error fetching inputs:', error));
  }

  function checkTemplatePublishable(template) {
    if (template.name === '' || template.description === '') {
      console.log('missing name or description');
      return false;
    }

    if (template.sections.length === 0) {
      console.log('missing sections');
      return false;
    }

    for (let i = 0; i < template.sections.length; i++) {
      const section = template.sections[i];
      if (section.name === '' || section.description === '') {
        console.log('missing section name or description');
        return false;
      }

      if (section.elements.length === 0) {
        console.log('missing elements');
        return false;
      }

      for (let j = 0; j < section.elements.length; j++) {
        const prompt = section.elements[j];
        if (prompt === '') {
          console.log('missing element');
          return false;
        }
      }
    }

    if (calculateInputIds(template, true).length !== template.formInputIdsRequired.length) {
      console.log('missing required inputs');
      return false;
    }

    return true;
  }

  function updateTemplateState(attributes) {
    const templateCopy = {...stateTemplate};
    for (const [key, value] of Object.entries(attributes)) {
      templateCopy[key] = value;
    }

    setTemplate(templateCopy);
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const template = structuredClone(stateTemplate);
    const items = template.sections[stateEditingSectionIndex].elements;

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    template.sections[stateEditingSectionIndex].elements = items;

    setTemplate(template);
  };

  const onAddElement = (element) => {
    const templateCopy = structuredClone(stateTemplate);
    if (stateEditingElementIndex != undefined) {
      templateCopy.sections[stateEditingSectionIndex].elements[stateEditingElementIndex] = element;
    } else {
      templateCopy.sections[stateEditingSectionIndex].elements.push(element);
    }

    updateFormInputIds(templateCopy);
    setEditElement(null);
    setEditingElementIndex(null);
    setTemplate(templateCopy);
  };

  const onDeleteElement = (id, index, sectionIndex) => {
    const templateCopy = {...stateTemplate};

    // update elements
    templateCopy.sections[sectionIndex].elements.splice(index, 1);

    updateFormInputIds(templateCopy);
    setTemplate(templateCopy);
  };

  const refreshAllElements = async () => {
    const templateCopy = {...stateTemplate};

    // Iterate through each section
    for (let sectionIndex = 0; sectionIndex < templateCopy.sections.length; sectionIndex++) {
      const section = templateCopy.sections[sectionIndex];

      // Iterate through each element in the section
      for (let elementIndex = 0; elementIndex < section.elements.length; elementIndex++) {
        const element = section.elements[elementIndex];

        if (element.type === 'prompt') {
          // Fetch the new data for the prompt
          const promptId = element.id;
          const newPrompt = await promptApi.getPrompt(promptId);
          const existingPrompt = section.elements[elementIndex];
          section.elements[elementIndex] = {...existingPrompt, ...newPrompt};
        } else if (element.type === 'input') {
          // Fetch the new data for the input
          const inputId = element.id;
          const newInput = await inputApi.getInput(inputId);
          section.elements[elementIndex] = {...element, ...newInput};
        } else {
          console.error('Unknown element type:', element.type);
        }
      }
    }

    // Update Form Input Ids if necessary
    updateFormInputIds(templateCopy);

    // Update the state with the refreshed template
    setTemplate(templateCopy);
  };

  const onRefreshElement = (id, elementType, index, sectionIndex) => {
    if (elementType === 'prompt') {
      onRefreshPrompt(id, index, sectionIndex);
    } else if (elementType === 'input') {
      onRefreshInput(id, index, sectionIndex);
    } else {
      console.error('Unknown element type:', elementType);
    }
  };

  const onRefreshPrompt = (id, index, sectionIndex) => {
    promptApi.getPrompt(id).then((prompt) => {
      const templateCopy = {...stateTemplate};
      const existingPrompt = templateCopy.sections[sectionIndex].elements[index];
      templateCopy.sections[sectionIndex].elements[index] = {...existingPrompt, ...prompt};

      updateFormInputIds(templateCopy);
      setTemplate(templateCopy);
    });
  };

  const onRefreshInput = (inputId, index, sectionIndex) => {
    // update template
    inputApi.getInput(inputId).then((input) => {
      const templateCopy = {...stateTemplate};
      updateFormInputIds(templateCopy);
      setTemplate(templateCopy);
    });
  };

  const onEditElement = (item, index, sectionIndex) => {
    setEditingSectionIndex(sectionIndex);
    setEditingElementIndex(index);
    setEditElement(item);
    onOpenAddBlock();
  };

  const onOpenElementEditor = (id, elementType) => {
    let url;

    const localhostPrefix = 'http://localhost:3000';
    if (elementType === 'prompt') {
      url = `${localhostPrefix}/prompt_builder/${id}`;
    } else if (elementType === 'input') {
      url = `${localhostPrefix}/input_builder/${id}`;
    } else {
      console.log('Unknown element type');
    }

    if (!url) {
      return;
    }

    window.open(url, '_blank').focus();
  };

  const PromptOptions = ({
    stateSelectedDataId,
    setSelectedDataId,
    setSelectedElement,
    stateOpenElementAccordionIndex,
    onAccordionOpen,
  }) => {
    function onPromptOptionSelected(dataId) {
      if (stateTemplate.sections[stateEditingSectionIndex].elements.find((element) => element.id === dataId)) {
        console.log('This element has already been added to the template. No duplicate ids allowed.');
        return;
      }

      const element = statePromptOptions.find((object) => object.id === dataId);
      setSelectedDataId(dataId);
      setSelectedElement(element);
    }

    return (
      <ZAccordionSingleItem
        title={'Prompt Options'}
        defaultOpen={stateOpenElementAccordionIndex == 1}
        index={1}
        onOpen={onAccordionOpen}
      >
        <RadioGroup onChange={onPromptOptionSelected} value={stateSelectedDataId}>
          {statePromptOptions
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item, index) => (
              <ZRadioElementCard mt={2} key={item.id} value={item.id} selected={!!(stateSelectedDataId === item.id)}>
                <Element item={item} />
              </ZRadioElementCard>
            ))}
        </RadioGroup>
      </ZAccordionSingleItem>
    );
  };

  const InputOptions = ({
    stateSelectedDataId,
    setSelectedDataId,
    setSelectedElement,
    stateOpenElementAccordionIndex,
    onAccordionOpen,
  }) => {
    function onInputOptionSelected(id) {
      if (stateTemplate.sections[stateEditingSectionIndex].elements.find((element) => element.id === id)) {
        console.log('This element has already been added to the template. No duplicate ids allowed.');
        return;
      }

      const element = stateFormInputs.find((object) => object.id === id);
      setSelectedDataId(id);
      setSelectedElement(element);
    }

    return (
      <ZAccordionSingleItem
        title={'Input Options'}
        defaultOpen={stateOpenElementAccordionIndex == 2}
        index={2}
        onOpen={onAccordionOpen}
      >
        <RadioGroup onChange={onInputOptionSelected} value={stateSelectedDataId}>
          {stateFormInputs.map((item, index) => (
            <ZRadioElementCard mt={2} key={item.id} value={item.id} selected={!!(stateSelectedDataId === item.id)}>
              <Element item={item} />
            </ZRadioElementCard>
          ))}
        </RadioGroup>
      </ZAccordionSingleItem>
    );
  };

  const TemplateMetadataInput = ({template, ...props}) => {
    function onSaveInput(attributeName, value) {
      // create a shallow copy
      let newTemplate = {...stateTemplate};

      // handling nested references to attributes
      if (attributeName.split('_').length > 1) {
        const [attribute, elementValue] = attributeName.split('_');
        const arraySet = new Set(newTemplate[attribute]);
        value ? arraySet.add(elementValue) : arraySet.delete(elementValue);
        newTemplate[attribute] = [...arraySet];
      } else {
        _.set(newTemplate, attributeName, value);
      }

      // update state
      setTemplate(newTemplate);
    }

    const checkboxElements = [
      {
        attributeName: 'tags_podcast',
        defaultChecked: template?.tags?.includes('podcast'),
        label: 'Podcast',
      },
      {
        attributeName: 'tags_informative',
        defaultChecked: template?.tags?.includes('informative'),
        label: 'Informative',
      },
      {
        attributeName: 'tags_entertaining',
        defaultChecked: template?.tags?.includes('entertaining'),
        label: 'Entertaining',
      },
      {
        attributeName: 'tags_singlehost',
        defaultChecked: template?.tags?.includes('singlehost'),
        label: 'Single Host',
      },
      {
        attributeName: 'tags_cohost',
        defaultChecked: template?.tags?.includes('cohost'),
        label: 'Co-hosted',
      },
      {
        attributeName: 'tags_guest',
        defaultChecked: template?.tags?.includes('guest'),
        label: 'Guest Host',
      },
    ];

    const PublicPrivateOption = ({...props}) => {
      return (
        <ZRadioGroup
          description={'Should this template be made available to all users?'}
          attributeName={'isPublic'}
          onChange={(attributeName, value) => updateTemplateState({[attributeName]: value})}
          value={stateTemplate.isPublic}
          elements={[
            {value: 'true', label: 'Public', tooltipText: 'Building for a target segment?'},
            {value: 'false', label: 'Private', tooltipText: 'Building with someone in mind?'},
          ]}
        />
      );
    };

    return (
      <ZAccordionSingleItem
        title={'Metadata'}
        defaultOpen={stateMetadataAccordionOpen}
        onOpen={() => setMetadataAccordionOpen(true)}
        onClose={() => setMetadataAccordionOpen(false)}
      >
        <Stack spacing={4} {...props}>
          <ZInputSavable
            attributeName={'name'}
            placeholder={'Template Name'}
            defaultValue={template?.name}
            onSave={onSaveInput}
          />

          <ZInputSavable
            attributeName={'description'}
            isTextArea={true}
            placeholder={'Description of the template'}
            defaultValue={template?.description}
            onSave={onSaveInput}
          />

          <PublicPrivateOption />

          <ZCheckboxGroup elements={checkboxElements} onSave={onSaveInput} />
        </Stack>
      </ZAccordionSingleItem>
    );
  };

  const FormInputOrganizer = ({...props}) => {
    const handleDragEnd = (result) => {
      if (!result.destination) return;

      // update inputsCustom state
      const inputsCustom = [...stateFormInputs];
      const [reorderedInput] = inputsCustom.splice(result.source.index, 1);
      inputsCustom.splice(result.destination.index, 0, reorderedInput);
      setFormInputs(inputsCustom);

      // update template formInputIds order state
      const template = structuredClone(stateTemplate);
      const items = [...template.formInputIds];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      template.formInputIds = items;
      setTemplate(template);
    };

    async function addInputToTempalate(_, inputId) {
      // check if inputId already exists in template
      if (stateTemplate.formInputIds.includes(inputId)) {
        console.log('input already exists in template');
        return;
      }

      const input = await inputApi.getInput(inputId);
      if (!input) {
        console.log('input not found');
        return;
      }

      // update template state
      const templateCopy = {...stateTemplate};
      templateCopy.formInputIds = [...templateCopy.formInputIds, inputId];
      setTemplate(templateCopy);

      // update formInputs state
      const formInputsCopy = [...stateFormInputs];
      formInputsCopy.push(input);
      setFormInputs(formInputsCopy);
    }

    return (
      <ZAccordionSingleItem
        title={'Custom Inputs'}
        defaultOpen={stateInputsAccordionOpen}
        onOpen={() => setInputsAccordionOpen(true)}
        onClose={() => setInputsAccordionOpen(false)}
      >
        <Stack {...props}>
          <BodySmMuted>
            Prompts can specify inputs that they use, and any prompts added to template sections that have specified
            inputs to use will be automatically added here. If you'd like to add additional inputs, you can do so here
            manually. Note that these elements are draggable, and order is maintained when creating the form displayed
            to the user.
          </BodySmMuted>

          <ZInputSavable
            attributeName={'id'}
            label={'Add input to template by inputId'}
            placeholder={'i.e. iid_b38ff...'}
            onSave={addInputToTempalate}
          />

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <Stack {...provided.droppableProps} ref={provided.innerRef} justifyContent={'start'}>
                  {stateFormInputs.map((input, index) => (
                    <Draggable key={input.id} draggableId={input.id} index={index}>
                      {(provided) => (
                        <InputElement
                          pt={4}
                          input={input}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      </ZAccordionSingleItem>
    );
  };

  const SectionMetadataInput = ({sectionIndex, defaultSection, ...props}) => {
    function onSaveInput(attributeName, value) {
      // create a shallow copy
      const templateCopy = {...stateTemplate};
      const sectionCopy = templateCopy.sections[sectionIndex];

      // handling nested references to attributes
      _.set(sectionCopy, attributeName, value);
      setTemplate(templateCopy);
    }

    return (
      <Stack spacing={4} {...props}>
        <ZInputSavable
          attributeName={'name'}
          placeholder={'Section Name'}
          defaultValue={defaultSection?.name}
          onSave={onSaveInput}
        />

        <ZInputSavable
          attributeName={'description'}
          isTextArea={true}
          placeholder={'Description of the section'}
          defaultValue={defaultSection?.description}
          onSave={onSaveInput}
        />
      </Stack>
    );
  };

  const SectionElement = ({section, sectionIndex, ...props}) => {
    const onAccordionClose = () => setEditingSectionIndex(null);
    const onAccordionOpen = () => setEditingSectionIndex(sectionIndex);

    return (
      <ZAccordionSingleItem
        title={section.name ?? 'Section ' + (sectionIndex + 1)}
        defaultOpen={sectionIndex == stateEditingSectionIndex}
        onClose={onAccordionClose}
        onOpen={onAccordionOpen}
      >
        <Stack>
          <SectionMetadataInput sectionIndex={sectionIndex} defaultSection={section} />

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <Stack {...provided.droppableProps} ref={provided.innerRef} justifyContent={'start'}>
                  {section.elements.map((element, index) => (
                    <Draggable key={element.id} draggableId={element.id} index={index}>
                      {(provided) => (
                        <Element
                          item={element}
                          index={index}
                          sectionIndex={sectionIndex}
                          isDraggableElement={true}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        ></Element>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>

          <Button
            leftIcon={<Icon as={FiPlus} />}
            onClick={() => {
              setEditElement(null);
              setEditingSectionIndex(sectionIndex);
              onOpenAddBlock();
            }}
          >
            Add Element
          </Button>
        </Stack>
      </ZAccordionSingleItem>
    );
  };

  const ElementItem = ({item: element, index, sectionIndex, isDraggableElement}) => (
    <Stack flex="1" mr={2} spacing={1}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
        <Stack flex="1" mr={2} spacing={1} flexGrow={1} width={'100%'}>
          {element.content && element.id === 'dl_custom' && <BodySmSemiBold>{element.content}</BodySmSemiBold>}

          <Flex gap={4}>
            <BodySmSemiBold>{isDraggableElement ? element.displayName ?? element.name : element.name}</BodySmSemiBold>

            {(isDraggableElement ? element.name : element.displayName) && (
              <BodySm>{'(' + (isDraggableElement ? element.name : element.displayName) + ')'}</BodySm>
            )}
          </Flex>

          <BodySmMuted>{element.description}</BodySmMuted>
        </Stack>

        {isDraggableElement && (
          <Stack direction={'row'} spacing={2} justifyContent={'end'} alignItems={'center'}>
            <Wrap>
              <WrapItem>
                <Box
                  px={2}
                  py={1}
                  my={2}
                  borderRadius={'5px'}
                  backgroundColor={element.type == 'prompt' ? 'yellow.200' : 'blue.200'}
                >
                  <BodySmSemiBold>{element.type}</BodySmSemiBold>
                </Box>
              </WrapItem>
            </Wrap>

            <IconButton
              variant="outline"
              size="sm"
              onClick={() => onRefreshElement(element.id, element.type, index, sectionIndex)}
              colorScheme="white"
              aria-label="Refresh Element"
              icon={<Icon as={FiRefreshCw} />}
            />

            <IconButton
              variant="outline"
              size="sm"
              onClick={() => onEditElement(element, index, sectionIndex)}
              colorScheme="white"
              aria-label="Edit Element"
              icon={<Icon as={FiEdit} />}
            />

            <IconButton
              variant="outline"
              size="sm"
              onClick={() => onDeleteElement(element.id, index, sectionIndex)}
              colorScheme="white"
              aria-label="Delete Element"
              icon={<Icon as={FiTrash} />}
            />

            <IconButton
              variant="outline"
              size="sm"
              onClick={() => onOpenElementEditor(element.id, element.type)}
              colorScheme="white"
              aria-label="Open Element Editor"
              icon={<Icon as={FiExternalLink} />}
            />
          </Stack>
        )}
      </Flex>

      <Flex pt={4} direction={'row'} wrap={'wrap'} gap={1}>
        <BodySmMuted>id: {element?.id}</BodySmMuted>
        <BodySmMuted>type: {element?.type}</BodySmMuted>
        <BodySmMuted>outputType: {element?.outputType}</BodySmMuted>
        <BodySmMuted>approxTokens: {element?.approxTokens ?? GPT.aproxNumTokens(element?.content)}</BodySmMuted>
        <BodySmMuted>promptDepth: {element?.promptDepth}</BodySmMuted>
        <BodySmMuted>numCustomInputs: {element?.customInputIds?.length}</BodySmMuted>
        {element.outputType == 'iterable' && (
          <BodySmMuted>iterableConfig: {JSON.stringify(element.iterableConfig)}</BodySmMuted>
        )}
        {element.config && <BodySmMuted>config: {JSON.stringify(element.config)}</BodySmMuted>}
      </Flex>
    </Stack>
  );

  const PromptSelectionDrawer = ({element}) => {
    const defaultDataId = element?.id || -1;

    const [stateSelectedDataId, setSelectedDataId] = useState(defaultDataId);
    const [stateSelectedElement, setSelectedElement] = useState(element);

    const [stateOpenElementAccordionIndex, setOpenElementAccordionIndex] = useState(-1); // index of section being edited
    const onAccordionOpen = (index = null) => setOpenElementAccordionIndex(index);

    return (
      <>
        <Drawer isOpen={isOpenAddBlock} placement="right" size={'lg'} onClose={onCloseAddBlock}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerHeader borderBottomWidth="1px">
              Select data for the element{element && ' as a replacement'}.
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing="24px">
                <PromptOptions
                  stateSelectedDataId={stateSelectedDataId}
                  setSelectedDataId={setSelectedDataId}
                  setSelectedElement={setSelectedElement}
                  stateOpenElementAccordionIndex={stateOpenElementAccordionIndex}
                  onAccordionOpen={onAccordionOpen}
                />

                <InputOptions
                  stateSelectedDataId={stateSelectedDataId}
                  setSelectedDataId={setSelectedDataId}
                  setSelectedElement={setSelectedElement}
                  stateOpenElementAccordionIndex={stateOpenElementAccordionIndex}
                  onAccordionOpen={onAccordionOpen}
                />
              </Stack>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px" justifyContent={'left'}>
              <Button variant="outline" mr={3} size={'md'} onClick={onCloseAddBlock}>
                Cancel
              </Button>

              <ButtonPrimary
                colorScheme="blue"
                isDisabled={stateSelectedDataId == -1}
                onClick={() => {
                  onAddElement(stateSelectedElement);
                  onCloseAddBlock();
                }}
              >
                Submit
              </ButtonPrimary>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  const Element = forwardRef(({item, index, sectionIndex, isDraggableElement, ...props}, ref) => (
    <Stack ref={ref} {...props}>
      {isDraggableElement ? (
        <ZCard>
          <ElementItem item={item} index={index} sectionIndex={sectionIndex} isDraggableElement={isDraggableElement} />
        </ZCard>
      ) : (
        <ElementItem item={item} index={index} sectionIndex={sectionIndex} isDraggableElement={isDraggableElement} />
      )}
    </Stack>
  ));

  const InputElement = forwardRef(({input, ...props}, ref) => (
    <Stack ref={ref} {...props}>
      <ZDraggable>
        <ZInputCustom pt={4} input={input} />
      </ZDraggable>
    </Stack>
  ));

  return (
    <FlowContainer maxWidth={'1000px'}>
      <FlowHeader
        title={'Template Builder'}
        description={
          "Build a template that defines the interface, inputs, and data created for a user's upload flow. You can draw from existing prompts, and add input sections (such as the shownote urls)"
        }
        rightComponent={
          <Stack>
            <Stack direction={'row'}>
              <ButtonPrimary onClick={() => console.log('template: ', stateTemplate)}>Print Template</ButtonPrimary>
              <ButtonPrimary onClick={refreshAllElements}>Refresh All Elements</ButtonPrimary>
            </Stack>

            <Stack direction={'row'}>
              <ButtonPrimary onClick={saveDraft}>Save Draft</ButtonPrimary>
              <ButtonPrimary onClick={() => postTemplate()}>Publish Template</ButtonPrimary>
            </Stack>
          </Stack>
        }
      />

      <FlowBody>
        <TemplateMetadataInput pb={10} template={stateTemplate} />
        {stateFormInputs && <FormInputOrganizer />}

        {stateTemplate?.sections.map((section, sectionIndex) => (
          <SectionElement key={sectionIndex} section={section} sectionIndex={sectionIndex} />
        ))}

        <Button
          leftIcon={<Icon as={FiPlus} />}
          onClick={() => {
            const template = {...stateTemplate};
            template.sections.push(getDefaultSectionObject());
            setEditingSectionIndex(template.sections.length - 1);
            setTemplate(template);
          }}
        >
          Add Section
        </Button>
        <PromptSelectionDrawer element={stateEditElement} />
      </FlowBody>
      <FlowFooter />
    </FlowContainer>
  );
};
