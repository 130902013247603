import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const customPrimary = defineStyle({
  '.chakra-button__icon *': {color: 'white'},
  '.chakra-icon *': {stroke: 'white'}, // makes customPrimary IconButtons have white icons
  // ".chakra-button > svg *": { stroke: "white" },
  color: 'white',
  backgroundImage: '/buttonbg.jfif',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  ':hover': {
    backgroundImage: './buttonbg.jfif',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(85%)',
    transition: 'all .2s',
  },
  ':active': {
    backgroundImage: './buttonbg.jfif',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(65%)',
    transition: 'all .2s',
  },
});

export const Button = defineStyleConfig({variants: {customPrimary}});
