import {theme as proTheme} from '@chakra-ui/pro-theme';
import {theme as baseTheme, extendTheme} from '@chakra-ui/react';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

import {Button} from './Button';
import {IconButton} from './IconButton';

export const theme = extendTheme(proTheme, {
  styles: {global: {body: {bg: 'white'}, '*': {'&::-webkit-scrollbar': {width: '0px', background: 'transparent'}}}},
  fonts: {heading: `'Inter', system-ui, sans-serif`, body: `'Inter', system-ui, sans-serif`},
  fontWeights: {...baseTheme.fontWeights},
  components: {Button, IconButton},
  colors: {
    ...baseTheme.colors,
    brand: {
      primary: baseTheme.colors.blue[600], // "#3182ce",
      bg: '#edf2f7', // light blue
      bg2: '#dce6ef', // darker
      secondary: '#008080',
      dkgray: '#231F20',
    },
    playful: {
      blue: '#5395F7', // rgb(83, 149, 247)
      teal: '#0EBCB2', // rgb(14, 188, 178)
      purple: '#4145D0', // rgb(65, 69, 208)
      yellow: '#F4D705', // rgb(244, 215, 5)
      red: '#D93D07', // rgb(217, 61, 7)
      orange: '#E19E01', // rgb(225, 158, 1)
    },
    extra: {tan: '#FCC670', coralLight: '#FF92A4', coral: '#FF6F61'},
  },
});
