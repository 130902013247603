import {
  Box,
  Container,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
  useMultiStyleConfig,
  useTab,
} from '@chakra-ui/react';
import {forwardRef, useEffect, useState} from 'react';
import {BsFire, BsLightningCharge} from 'react-icons/bs';
import {FiChevronDown, FiMail, FiStar, FiThumbsUp} from 'react-icons/fi';
import {GiQuillInk} from 'react-icons/gi';
import {GoQuestion} from 'react-icons/go';

import ButtonSecondary from './ButtonSecondary';
import ZCard from './ZCard';
import ZPingAnimation from './ZPingAnimation';

export const TabIcons = Object.freeze({
  SHOWNOTES_V1: {icon: BsFire, ogSk: 'sid_65dd81ff74fd4f1685c9cfab6d8d3c2a'},
  DETAILS_V1: {icon: FiStar, ogSk: 'sid_65dd81ff74fd4f1685c9cfab6d8d3c2b'},
  SOCIALS_V1: {icon: FiThumbsUp, ogSk: 'sid_aef36205a3724012831d772d471515f5'},
  BLOG_V1: {icon: GiQuillInk, ogSk: 'sid_aef36205a3724012831d772d471515f4'},
  EMAIL_V1: {icon: FiMail, ogSk: 'sid_email'},
  PROMPT_STUDIO_V1: {icon: BsLightningCharge, ogSk: 'sid_prompt_studio'},
  UKNOWN: {icon: GoQuestion},
});

/**
 * @param {*} props - contains: elements
 * - elements. elements is an array of objects with the following properties: title, sk, child (react componenet e.g. <Stack><Text /></Stack>)
 */
const ZTabs = ({
  elements,
  maxWidth = 'none',
  selectedTabKey,
  updateTabElementsWithSelectedPopoverChild,
  onTabChange,
  setProgrammaticallySelectTabByKey,
  programmaticallySelectTabByKey = null,
  updateSearchParam,
  ...props
}) => {
  const [stateTabIndex, setTabIndex] = useState(0);

  /**
   * This get's tricky when a new template/tab/popoverElementTab is added at the same time that it needs to be selected programmatically.
   * To perform this correctly, the tabElements data must be refreshed before the call to select the tab programmatically. This happens in checkForImmediateActionOnSaveableTemplates()
   */
  useEffect(() => {
    if (!programmaticallySelectTabByKey || !elements || elements.length == 0) return;

    let tabIndexToSelect = elements.findIndex(
      (e) =>
        e.sk === programmaticallySelectTabByKey ||
        (e.popoverElements && e.popoverElements.some((pe) => pe.sk === programmaticallySelectTabByKey)),
    );
    if (tabIndexToSelect === -1) {
      tabIndexToSelect = 0; // if the tab key is not found, default to the first tab
      programmaticallySelectTabByKey = null;
    }

    const elementSelected = elements[tabIndexToSelect];
    updateTabElementsWithSelectedPopoverChild(
      elementSelected.popoverElements ? elementSelected.child : null,
      programmaticallySelectTabByKey,
    );

    setTabIndex(tabIndexToSelect);
    onTabChange(programmaticallySelectTabByKey);
  }, [programmaticallySelectTabByKey]);

  function handleTabChange(index) {
    // this method is only ever called by chakra's Tabs component. It is not called when a popover element is clicked
    const elementSelected = elements[index];
    setTabIndex(index);
    onTabChange(elementSelected.sk);
  }

  return (
    <ZCard variant="unstyled" maxWidth={maxWidth} {...props}>
      <Container maxWidth={maxWidth} px={0} py={4}>
        <Stack spacing="16">
          <Tabs index={stateTabIndex} onChange={handleTabChange} p={0} size="lg" variant="with-line" isLazy>
            <TabList>
              {elements.map((element) =>
                element.popoverElements ? (
                  <ZTabPopover
                    tooltipLabel={element.tooltipLabel}
                    key={element.sk}
                    icon={element.icon}
                    isDisabled={element.isDisabled}
                    isLoading={element.isLoading}
                    isPinging={element.isPinging}
                    selectedTabKey={selectedTabKey}
                    onSelected={setProgrammaticallySelectTabByKey}
                    elements={element.popoverElements}
                  />
                ) : (
                  <ZTab
                    tooltipLabel={element.tooltipLabel}
                    key={element.sk}
                    icon={element.icon}
                    isDisabled={element.isDisabled}
                    isLoading={element.isLoading}
                    isPinging={element.isPinging}
                  >
                    {element.title}
                  </ZTab>
                ),
              )}
            </TabList>

            <TabPanels>
              {elements.map((element) => (
                <TabPanel px={0} key={element.sk}>
                  {element.child}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
    </ZCard>
  );
};

const ZTabDivider = () => (
  <Box position="absolute" right="0%" top="25%" borderRight="1px solid #E2E8F0" height={'50%'} />
);

const ZTab = forwardRef(({icon, isLoading, isDisabled, isPinging, tooltipLabel, ...props}, ref) => {
  const tabProps = useTab({...props, ref});
  const isSelected = !!tabProps['aria-selected'];
  const styles = useMultiStyleConfig('Tabs', tabProps);

  // Handle click events when the tab is disabled
  const handleClick = (e) => {
    if (isDisabled || isLoading) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const getHoverStyle = (isDisabled, isLoading) => {
    if (isDisabled || isLoading) {
      // Return a style object for the disabled or loading state
      return {};
    } else {
      // Return a style object for the normal hover state
      return {
        color: 'playful.blue',
        borderColor: 'playful.blue',
        borderBottomWidth: '1px',
      };
    }
  };

  return (
    <Tooltip isDisabled={!tooltipLabel} label={tooltipLabel} placement="top-start" borderRadius={'md'}>
      <Flex
        __css={styles.tab}
        {...(!isDisabled && !isLoading ? tabProps : {})} // Spread tabProps conditionally. If not spread, must spread them later in div with display none to keep tab indices correct
        onClick={handleClick}
        justifyContent={'center'}
        alignItems={'start'}
        gap={1}
        flexGrow={1}
        px={0}
        py={2}
        outline={'none'}
        fontWeight={isSelected ? 'medium' : 'regular'}
        cursor={isDisabled || isLoading ? 'not-allowed' : 'pointer'}
        color={!isSelected ? 'brand.dkgray' : 'playful.blue'}
        opacity={isDisabled || isLoading ? 0.6 : 1}
        _hover={getHoverStyle(isDisabled, isLoading)}
        position="relative" // Added for positioning ZTabDivider
      >
        {(isDisabled || isLoading) && <Stack {...tabProps} display={'none'} />}{' '}
        {/* this is a hack to get the tabProps indices to work while still disabling default tab click functionality */}
        <Flex gap={1} justifyContent={'center'} alignItems={'center'}>
          {isPinging && <ZPingAnimation scale={0.6} />}
          {!isDisabled && isLoading && <Spinner size="xs" color="brand.dkgray" thickness="1px" speed="0.65s" />}
          <Icon as={icon} boxSize={4} />
        </Flex>
        {/* <Box borderTopRightRadius={"md"} position="absolute" top={0} right="0%" width="25%" borderTop="1px solid #E2E8F0" borderRight="1px solid #E2E8F0" height={"50%"} /> */}
        <ZTabDivider />
      </Flex>
    </Tooltip>
  );
});

const ZTabPopover = forwardRef(
  (
    {
      elements,
      icon,
      selectedTabKey,
      onSelected = () => console.log('implement onSelectedCallback to ensure proper selection of tab'),
      isLoading,
      isDisabled,
      isPinging,
      tooltipLabel,
      ...props
    },
    ref,
  ) => {
    const {onOpen, onClose, isOpen} = useDisclosure();

    const tabProps = useTab({...props, ref});
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);

    const handleOpen = () => {
      if (!isDisabled && !isLoading) {
        onOpen();
      }
    };

    const getHoverStyle = (isDisabled, isLoading) => {
      if (isDisabled || isLoading) {
        // Return a style object for the disabled or loading state
        return {};
      } else {
        // Return a style object for the normal hover state
        return {
          color: 'playful.blue',
          borderColor: 'playful.blue',
          borderBottomWidth: '1px',
        };
      }
    };

    return (
      <Popover
        trigger="hover"
        isDisabled={isDisabled || isLoading}
        isLazy
        placement="bottom-end"
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Stack flexGrow={1}>
            <Tooltip isDisabled={!tooltipLabel} label={tooltipLabel} placement="top-start" borderRadius={'md'}>
              <Flex
                __css={styles.tab}
                justifyContent={'center'}
                alignItems={'start'}
                gap={1}
                px={0}
                py={2}
                outline={'none'}
                fontWeight={isSelected ? 'medium' : 'regular'}
                cursor={isDisabled || isLoading ? 'not-allowed' : 'pointer'}
                color={!isSelected ? 'brand.dkgray' : 'playful.blue'}
                opacity={isDisabled || isLoading ? 0.6 : 1}
                _hover={getHoverStyle(isDisabled, isLoading)}
                position="relative" // Added for positioning ZTabDivider
              >
                <Stack {...tabProps} display={'none'} />{' '}
                {/* this is a hack to get the tabProps indices to work while still disabling default tab click functionality */}
                <Flex gap={1} justifyContent={'center'}>
                  {isPinging && <ZPingAnimation scale={0.6} />}
                  {!isDisabled && isLoading && <Spinner size="xs" color="brand.dkgray" thickness="1px" speed="0.65s" />}
                  <Icon as={icon} boxSize={4} />
                  <Icon as={FiChevronDown} />
                </Flex>
                <ZTabDivider />
              </Flex>
            </Tooltip>
          </Stack>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Stack spacing={2} alignItems={'end'}>
              {elements.map((element) => (
                <ButtonSecondary
                  borderColor={selectedTabKey === element.sk ? 'playful.blue' : 'inherit'}
                  textColor={selectedTabKey === element.sk ? 'playful.blue' : null}
                  key={element.sk}
                  wrap={true}
                  {...element} // includes leftIcon &
                  onClick={() => {
                    if (typeof element.onClick === 'function') element.onClick();
                    onSelected(element.sk); // if child is null, then it will be ignored
                    onClose();
                  }}
                >
                  {element.title}
                </ButtonSecondary>
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);

export default ZTabs;
