export default class DateUtils {
  static zeroPad(num, places) {
    return String(num).padStart(places, '0');
  }

  static formatSecondsHHMMSS(secs) {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs % 3600) / 60);
    let seconds = Math.floor(secs % 60);

    let time =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0');

    return time;
  }

  static formatSecondsMMSSMS(secs) {
    // formatted MM:SS.MS
    let m = Math.floor(secs / 60);
    let s = Math.floor(secs % 60);
    let ms = Math.round((secs % 1) * 100);
    return this.zeroPad(m, 2) + ':' + this.zeroPad(s, 2) + '.' + ms;
  }

  /**
   * Formats a timestamp into "Month dd, year" format.
   *
   * @param {number} timestamp - epoch time in milliseconds
   * @returns {string} Formatted date string
   */
  static formatDate(timestamp, includeYear = true) {
    const date = new Date(timestamp);
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = date.getDate();

    if (!includeYear) return `${month} ${this.zeroPad(day, 2)}`;
    return `${month} ${this.zeroPad(day, 2)}, ${year}`;
  }

  /**
   *
   * @param {*} dateFuture - epoch millis
   * @param {*} dateNow - epoch seconds
   * @returns days between the two days rounded up to nearest integer
   */
  static daysBetweenDates(dateFuture, dateNow) {
    if (!dateFuture || !dateNow) return 999;

    const oneDay = 24 * 60 * 60 * 1000; // one day in milliseconds
    const diff = dateFuture - dateNow; // difference in milliseconds
    const days = Math.ceil(diff / oneDay); // round up to nearest integer
    return days;
  }

  /**
   * Computes the time difference between two dates and formats it as a string.
   * The string will describe the difference in the largest relevant unit (e.g., months, days, hours).
   *
   * @param {number} dateFuture - epoch in milliseconds
   * @param {number} dateNow - epoch in seconds
   * @returns {string} Description of the time difference in the largest relevant time unit.
   */
  static timeBetweenDatesString(dateFuture, dateNow) {
    if (!dateFuture || !dateNow) return 'N/A';

    const diff = Math.abs(dateFuture - dateNow);
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) return `${years} ${years === 1 ? 'year' : 'years'}`;
    if (months > 0) return `${months} ${months === 1 ? 'month' : 'months'}`;
    if (days > 0) return `${days} ${days === 1 ? 'day' : 'days'}`;
    if (hours > 0) return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    if (minutes > 0) return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
  }

  /**
   *
   * @param {*} dateFuture - epoch millis
   * @param {*} dateNow - epoch millis
   * @returns hours between the two dates rounded up to the nearest integer
   */
  static hoursBetweenDates(dateFuture, dateNow) {
    const oneHour = 60 * 60 * 1000; // one hour in milliseconds
    const diff = dateFuture - dateNow; // difference in milliseconds
    const hours = Math.ceil(diff / oneHour); // round up to nearest integer
    return hours;
  }

  /**
   *
   * @param {Number} timestamp millis since epoch
   * @param {Number} numMonths num months to advance
   * @returns millis since epoch advanced the provided number of months
   */
  static advanceMonths(timestamp, numMonths) {
    const date = new Date(timestamp);
    date.setMonth(date.getMonth() + numMonths);
    return date.getTime();
  }

  /**
   * Calculates the index of the current period (month) based on the provided anchor timestamp and the current timestamp.
   * The index is determined by counting the number of full months that have elapsed since the anchor date up to the current date.
   * For example, if the anchor date is in February and the current date is in March of the same year, the index would be 1,
   * indicating that one full month has elapsed since the anchor date.
   *
   * @param anchorTimestamp The anchor timestamp in milliseconds since the epoch (Unix time), marking the start of the reference period.
   * @param currentTimestamp The current timestamp in milliseconds since the epoch (Unix time).
   * @return The index of the current month relative to the anchor month. This index is zero-based, meaning if the current date is in the same month as the anchor date, the index will be 0.
   */
  static calculateCurrentPeriodIndex(anchorTimestamp, currentTimestamp) {
    // Convert the timestamps to Date objects
    let anchorDate = new Date(anchorTimestamp);
    let currentDate = new Date(currentTimestamp);

    // Calculate the difference in months between the anchor date and the current date
    let monthDiff =
      (currentDate.getYear() - anchorDate.getYear()) * 12 + (currentDate.getMonth() - anchorDate.getMonth());

    return monthDiff;
  }
}
