import {Flex, Icon, Stack, Text} from '@chakra-ui/react';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {FiUpload} from 'react-icons/fi';
import {useNavigate, useSearchParams} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import {ZAlertFullWidth} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowHeader} from '../../components/common/Structural';
import {Body16} from '../../components/common/TextStyle';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import {SUBSCRIPTION, TEAM} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';
import CookieUtils from '../../utils/cookie-utils';
import GTM from '../../utils/google-tag-manager';

const OrderSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const zSetSubscription = authStore((state) => state.setSubscription);
  const [stateConfirmationInfo, setConfirmationInfo] = useState({});

  const zUser = authStore((state) => state.user);
  const zTeam = authStore((state) => state.team);
  const zSetTeam = authStore((state) => state.setTeam);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);

  const refSession = useRef();
  const refCustomer = useRef();

  const {subscriptionApi, teamApi} = useAPI();

  useEffect(() => {
    if (!zUser) {
      return;
    }

    if (searchParams) {
      const sessionId = searchParams.get('session_id');
      getCheckoutSession(sessionId);
      fetchSubscription();
    } else {
      console.log('no search params');
      navigateToUpload();
    }
  }, [zUser, searchParams]);

  async function getCheckoutSession(sessionId) {
    try {
      zSetLoading(true);

      const response = await axios.get(process.env.REACT_APP_BASE_URL + '/stripe/checkout', {params: {sessionId}});
      const {session, customer} = response.data;

      refSession.current = session;
      refCustomer.current = customer;

      setConfirmationInfo({
        name: customer.name,
        email: customer.email,
        productId: session.metadata.productId,
        priceId: session.metadata.priceId,
        orderTotal: Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(session.amount_total / 100),
      });

      // recognize conversion
      const cookies = new CookieUtils();
      const lastSubscriptionTransactionId = cookies.getCookie(CookieUtils.Keys.LAST_SUB_TRANSACTION_ID);
      if (lastSubscriptionTransactionId !== sessionId) {
        new GTM().conversionSubscription(
          zUser.userSub,
          zUser.email,
          session.amount_total / 100,
          sessionId,
          lastSubscriptionTransactionId,
        );
      } else {
        console.log("skipping GTM sub conversion event because it's a duplicate");
      }
      cookies.setCookie(CookieUtils.Keys.LAST_SUB_TRANSACTION_ID, sessionId);
    } catch (e) {
      console.log('error getCheckoutSession(): ', e);
    } finally {
      zSetLoading(false);
    }
  }

  async function getTeamOwnerUserSub() {
    if (!zUser.teamId) return null;

    let currentTeam = zTeam;
    if (!zTeam) {
      // fetch zTeam if not already fetched
      const team = await teamApi.getTeam(zUser.teamId);
      if (!team || team.length == 0) zSetError('fetchSubscription', 'Error fetching team');
      zSetTeam(team);
      currentTeam = team;
    }
    const teamOwner = TEAM.getTeamOwner(currentTeam);
    return teamOwner.userSub;
  }

  async function fetchSubscription() {
    const userSub = (await getTeamOwnerUserSub()) ?? zUser.userSub;

    // get subscription
    const subscription = await subscriptionApi.getSubscription(userSub, false);
    zSetSubscription(subscription);
  }

  function navigateToUpload() {
    console.log('navigateToUpload()');
    navigate('/');
  }

  return (
    <FlowContainer>
      <Stack spacing={0}>
        <FlowHeader entireComponent={<></>} />
        <Flex alignItems={'start'} pt={'60px'} pb={'60px'}>
          <Stack flexGrow={1}>
            <Text
              fontFamily={'Inter'}
              fontWeight={'bold'}
              fontSize={'39px'}
              lineHeight={'145%'}
              textAlign={'left'}
              color={'brand.dkgray'}
              verticalAlign={'top'}
            >
              {stateConfirmationInfo?.name && 'Thank you, ' + stateConfirmationInfo.name.split(' ')[0] + '!'}
            </Text>
          </Stack>
        </Flex>
      </Stack>
      <FlowBody>
        {stateConfirmationInfo.priceId && stateConfirmationInfo.productId && (
          <Stack>
            <ZAlertFullWidth status="success">Order Success</ZAlertFullWidth>
            <Body16>
              Thanks for purchasing the{' '}
              <b>
                Podflow{' '}
                {SUBSCRIPTION.getProductNamePretty(stateConfirmationInfo.productId, stateConfirmationInfo.priceId)}{' '}
                package
              </b>
              . We're excited to see what you create! You should receive a receipt for{' '}
              <b>{stateConfirmationInfo.orderTotal}</b> at your <b>{stateConfirmationInfo.email}</b> email address.
            </Body16>
          </Stack>
        )}
        <Flex justifyContent={'end'} pt={8}>
          <ButtonPrimary
            wrap={true}
            leftIcon={<Icon as={FiUpload} />}
            onClick={() => {
              navigateToUpload();
              fetchSubscription();
            }}
          >
            Upload an Episode
          </ButtonPrimary>
        </Flex>
      </FlowBody>
    </FlowContainer>
  );
};

export default OrderSuccess;
