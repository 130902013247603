/**
 * Loader class to load data from API with progress and timeout
 *
 * @param {Function}
 * @param {Object} options
 * @param {Number} options.ms - interval in milliseconds
 * @param {Number} options.timeout - timeout in milliseconds
 *
 * @example
 * const loader = new Loader(() => api.getAudioLibraryItem());
 * loader.isDone(x => x?.shownotesURL);
 * loader.progress(x => 0);
 * loader
 *   .start()
 *   .then(x => onShownotesURLLoaded(x?.shownotesURL))
 */
export class Loader {
  constructor(callback, options, origin = undefined) {
    this.callback = callback;
    this.origin = origin;

    this.MS = options?.ms ?? 5000 /* 5sec */;
    this.TIMEOUT = options?.timeout ?? 10 * 60 * 1000 /* 10min */;

    this.interval = null;
    this.isDoneCallback = null;
    this.initialResponseCallback = () => {};
    this.progressCallback = (x) => 0;
    this.timeoutCallback = () => null;
    this.isErrorCallback = undefined;
    this.errorCallback = undefined;
    this.doneCallback = null;
  }

  isDone(callback) {
    this.isDoneCallback = callback;
  }
  initialResponse(callback) {
    this.initialResponseCallback = callback;
  }
  progress(callback) {
    this.progressCallback = callback;
  }
  timeout(callback) {
    this.timeoutCallback = callback;
  }
  isError(callback) {
    this.isErrorCallback = callback;
  }
  error(callback) {
    this.errorCallback = callback;
  }
  done(callback) {
    this.doneCallback = callback;
  }

  stop() {
    clearInterval(this.interval);
    this.interval = null;
  }

  onTimeout() {
    this.stop();
  }

  async start() {
    if (!this.isDoneCallback) {
      console.error("Function 'loader.isDone(x => ...)' is Required!");
      return;
    }

    if (!this.doneCallback) {
      console.error("Function 'loader.done(x => ...)' is Required!");
      return;
    }

    return new Promise((resolve) => {
      const initialCallback = async () => {
        try {
          const x = await this.callback();
          this.initialResponseCallback(x);
          if (this.isDoneCallback(x)) {
            this.stop();
            this.doneCallback(x);
            resolve(x); // resolve the promise
          }
        } catch (error) {
          this.stop();
          if (this.errorCallback) {
            this.errorCallback(error);
          }
          resolve(null); // resolve the promise
        }
      };

      const intervalCallback = async () => {
        try {
          const x = await this.callback();

          if (this.isDoneCallback(x)) {
            this.stop();
            this.doneCallback(x);
            resolve(x);
          }

          if (this.isErrorCallback && this.errorCheckCallback(x)) {
            this.stop();
            if (this.errorCallback) {
              this.errorCallback(x);
            }
            resolve(null); // resolve the promise
          }

          if (this.progressCallback) {
            this.progressCallback(x);
          }
        } catch (error) {
          this.stop();
          if (this.errorCallback) {
            this.errorCallback(error);
          }
          resolve(null); // resolve the promise
        }
      };

      const timeoutCallback = async () => {
        this.stop();
        this.timeoutCallback();
        resolve(null); // resolve the promise
      };

      const start = async () => {
        // calling callback once to start before waiting interval length
        await initialCallback();
        this.interval = setInterval(intervalCallback, this.MS);
        setTimeout(timeoutCallback, this.TIMEOUT);
      };

      start();
    });
  }
}
