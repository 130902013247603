import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  CloseButton,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  RadioGroup,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Switch,
  Textarea,
  Tooltip,
  useBoolean,
  useEditableControls,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {FiEdit, FiInfo, FiSave, FiX} from 'react-icons/fi';
import {MdDragIndicator} from 'react-icons/md';

import {getStringCapitalCase} from '../../utils';
import ZCard from '../abstraction_high/ZCard';
import ZRadioElementCard from '../abstraction_high/ZRadioElementCard';
import {Body16Medium, BodySm, BodySmMuted, BodySmSemiBold, BodyXsMuted} from './TextStyle';

const ZColorPicker = ({color, colors, onChange}) => (
  <Popover variant="picker">
    <PopoverTrigger>
      <Button
        aria-label={color}
        background={color}
        border={'var(--chakra-colors-gray-200) solid 1px'}
        height="22px"
        width="22px"
        padding={0}
        minWidth="unset"
        borderRadius={3}
      ></Button>
    </PopoverTrigger>

    <PopoverContent width="170px">
      <PopoverArrow bg={color} />

      <PopoverCloseButton color="white" />

      <PopoverHeader
        height="100px"
        backgroundColor={color}
        borderTopLeftRadius={5}
        borderTopRightRadius={5}
        color="white"
      >
        <Center height="100%">{color}</Center>
      </PopoverHeader>

      <PopoverBody height="120px">
        <SimpleGrid columns={5} spacing={2}>
          {colors.map((c) => (
            <Button
              key={c}
              aria-label={c}
              background={c}
              height="22px"
              width="22px"
              padding={0}
              minWidth="unset"
              borderRadius={3}
              _hover={{background: c}}
              onClick={() => onChange(c)}
            ></Button>
          ))}
        </SimpleGrid>

        <Input
          borderRadius={3}
          marginTop={3}
          placeholder="red.100"
          size="sm"
          value={color}
          onChange={(e) => onChange(e.target.value)}
        />
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

function ZAccordionSingleItem({
  title,
  index,
  defaultOpen,
  animationDelay = 100,
  onOpen = () => {},
  onClose = () => {},
  ...props
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
    if (isOpen) {
      onClose(index);
    } else {
      onOpen(index);
    }
  }

  return (
    <Accordion
      width={'100%'}
      allowMultiple
      defaultIndex={defaultOpen ? [0] : null}
      backgroundColor={'white'}
      borderRadius={'10px'}
    >
      <AccordionItem border={'none'}>
        <Stack
          onClick={() => {
            // delaying onClose or onOpen call to wait for animation to finish
            _.delay(() => toggleIsOpen(), animationDelay);
          }}
        >
          <ZAccordionButton title={title} />
        </Stack>
        <AccordionPanel pb={4}>{props.children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

/**
 * @param {*} elements: each element has: { title, children, }
 * @returns
 */
const ZAccordion = ({
  elements,
  defaultOpen = false,
  onOpen = () => {},
  allowToggle = true,
  allowMultiple = null,
  panelPx = undefined,
  border = 'none',
  buttonDefaultBg,
  ...props
}) => (
  <Accordion
    allowToggle={allowToggle}
    allowMultiple={allowMultiple}
    width={'100%'}
    defaultIndex={defaultOpen ? [0] : null}
    backgroundColor={'white'}
    borderRadius={'10px'}
    {...props}
  >
    {elements.map((element, index) => (
      <AccordionItem border={border} key={index}>
        <Stack onClick={() => onOpen(index, element)}>
          <ZAccordionButton title={element.title} titleIcon={element.titleIcon} buttonDefaultBg={buttonDefaultBg} />
        </Stack>

        <AccordionPanel px={panelPx} pb={4}>
          {element.children}
        </AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
);

function ZAccordionButton({title, titleIcon, buttonDefaultBg, ...props}) {
  return (
    <Stack>
      <AccordionButton borderTopRadius={'10px'} background={buttonDefaultBg}>
        <Flex gap={1} alignItems={'center'}>
          {titleIcon}
          <Body16Medium>{title ?? 'Untitled'}</Body16Medium>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
    </Stack>
  );
}

function ZAlertFullWidth({status, closeCallback, showAlertIcon = true, wrapInCard = false, cta, ...props}) {
  const BodyContent = () => (
    <Stack>
      <Alert py={1} width={'100%'} status={status ?? 'info'} borderRadius={'10px'}>
        <HStack width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
          <HStack alignItems={'center'}>
            {showAlertIcon && <AlertIcon />}
            <BodySm>{props.children}</BodySm>
          </HStack>
          {closeCallback && <CloseButton alignContent={'center'} alignSelf={'center'} onClick={closeCallback} />}
        </HStack>
      </Alert>
      {cta}
    </Stack>
  );

  if (wrapInCard) {
    return (
      <ZCard py={4} px={8} backgroundColor="white" gap={2} width={'100%'} {...props}>
        <BodyContent />
      </ZCard>
    );
  }

  return <BodyContent />;
}

const Label = ({label, attributeName, labelType = 'smSemiBold', ...props}) => {
  if (!label && !attributeName) {
    return null;
  }

  return (
    <Stack {...props}>
      {labelType === 'smSemiBold' ? (
        <BodySmSemiBold>{label ?? getStringCapitalCase(attributeName)}</BodySmSemiBold>
      ) : (
        <BodySm>{label ?? getStringCapitalCase(attributeName)}</BodySm>
      )}
    </Stack>
  );
};

const SubLabel = ({subLabel, attributeName, ...props}) => {
  if (!subLabel && !attributeName) {
    return null;
  }

  return (
    <Stack {...props}>
      <BodyXsMuted>{subLabel}</BodyXsMuted>
    </Stack>
  );
};

/** onSave parameters are (attributeName, value) */
function ZInputSavable({
  isTextArea = false,
  attributeName,
  label,
  labelType,
  subLabel,
  placeholder,
  defaultValue = '',
  onSave,
  onChange = () => {},
  isSaveable = true,
  ...props
}) {
  const refOrigValue = useRef(defaultValue);
  const [stateValue, setValue] = useState(defaultValue);
  const [showSaveOptions, setShowSaveOptions] = useState(false);

  useEffect(() => setValue(defaultValue), [defaultValue]); // if default value changes, update state value (used in production)

  return (
    <Stack spacing={0} {...props}>
      <Label label={label} attributeName={attributeName} labelType={labelType} />
      <SubLabel subLabel={subLabel} attributeName={attributeName} />
      <Stack direction="row" width={'100%'}>
        <InputGroup flexGrow={1}>
          {isTextArea ? (
            <Textarea
              placeholder={placeholder}
              value={stateValue}
              focusBorderColor="blue.600"
              onChange={(e) => {
                onChange(attributeName, e.target.value);
                setShowSaveOptions(refOrigValue.current === e.target.value ? false : true);
                setValue(e.target.value);
              }}
            />
          ) : (
            <Input
              placeholder={placeholder}
              value={stateValue}
              focusBorderColor="blue.600"
              onChange={(e) => {
                onChange(attributeName, e.target.value);
                setShowSaveOptions(refOrigValue.current === e.target.value ? false : true);
                setValue(e.target.value);
              }}
            />
          )}
        </InputGroup>
        {showSaveOptions && isSaveable && (
          <Stack direction={'row'}>
            <IconButton
              variant="outline"
              onClick={() => {
                setShowSaveOptions(false);
                refOrigValue.current = stateValue;
                onSave(attributeName, stateValue);
              }}
              colorScheme="blue"
              aria-label="Save"
              icon={<Icon as={FiSave} />}
            />
            <IconButton
              variant="outline"
              onClick={() => {
                setShowSaveOptions(false);
                setValue(refOrigValue.current);
              }}
              colorScheme="white"
              aria-label="Cancel"
              icon={<Icon as={FiX} />}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

const ZNumberInput = ({
  attributeName,
  label,
  subLabel,
  labelType,
  defaultValue = 1,
  onChange = () => {},
  min = 1,
  max = 10,
  ...props
}) => {
  const [stateValue, setValue] = useState(defaultValue);

  const _onChange = (valueString) => {
    const value = parseInt(valueString);
    setValue(value);
    onChange(attributeName, value);
  };

  return (
    <Stack spacing={0} {...props}>
      <Label label={label} attributeName={attributeName} labelType={labelType} />
      <SubLabel subLabel={subLabel} attributeName={attributeName} />
      <NumberInput min={min} max={max} onChange={(valueString) => _onChange(valueString)} value={stateValue}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Stack>
  );
};

function ZEditable({
  isTextArea = false,
  attributeName,
  label,
  labelType,
  placeholder,
  fontSize,
  defaultValue = '',
  onSubmit: onSubmitCallback,
  onChange = () => {},
  isSaveable = true,
  editableControlsOnLeft = false,
  maxWidth = undefined,
  ...props
}) {
  const refOrigValue = useRef(defaultValue);
  const [stateValue, setValue] = useState(defaultValue);
  const [showSaveOptions, setShowSaveOptions] = useState(false);

  const Label = () => {
    if (!label && !attributeName) return null;
    return (
      <Stack>
        {labelType === 'smSemiBold' ? (
          <BodySmSemiBold>{label ?? getStringCapitalCase(attributeName)}</BodySmSemiBold>
        ) : (
          <BodySm>{label ?? getStringCapitalCase(attributeName)}</BodySm>
        )}
      </Stack>
    );
  };

  function EditableControls(props) {
    const {isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps} = useEditableControls();

    return isEditing ? (
      <Stack direction={'row'} {...props}>
        <IconButton
          borderRadius={'5px'}
          icon={<Icon as={FiSave} />}
          size={'xs'}
          {...getSubmitButtonProps()}
          aria-label="Save"
        />
        <IconButton
          borderRadius={'5px'}
          icon={<Icon as={FiX} />}
          size={'xs'}
          {...getCancelButtonProps()}
          aria-label="Cancel"
        />
      </Stack>
    ) : (
      <IconButton
        borderRadius={'5px'}
        icon={<Icon as={FiEdit} />}
        size={'xs'}
        {...getEditButtonProps()}
        aria-label="Edit"
      />
    );
  }

  return (
    <Stack spacing={0} {...props}>
      <Label />
      <Stack direction="row" width={'100%'}>
        <InputGroup flexGrow={1}>
          {isTextArea ? (
            <Textarea
              placeholder={placeholder}
              value={stateValue}
              onChange={(e) => {
                onChange(attributeName, e.target.value);
                setShowSaveOptions(refOrigValue.current === e.target.value ? false : true);
                setValue(e.target.value);
              }}
            />
          ) : (
            <Editable
              placeholder={placeholder}
              defaultValue={defaultValue}
              fontSize={fontSize}
              onSubmit={onSubmitCallback}
            >
              <Stack direction={'row'} alignItems={'center'}>
                {editableControlsOnLeft && <EditableControls pr={1} />}
                <EditablePreview overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" maxWidth={maxWidth} />
                <EditableInput />
                {!editableControlsOnLeft && <EditableControls pl={1} />}
              </Stack>
            </Editable>
          )}
        </InputGroup>
      </Stack>
    </Stack>
  );
}

const ZSwitch = ({attributeName, isChecked, label, onChange, ...props}) => {
  return (
    <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} {...props}>
      <Switch size={'sm'} isChecked={isChecked} onChange={(e) => onChange(attributeName, e.target.checked)} />
      <BodySm>{label}</BodySm>
    </Stack>
  );
};

const ZCheckbox = ({attributeName, defaultChecked, label, labelType, onChange, tooltipText, ...props}) => {
  return (
    <Stack direction="row" {...props}>
      <Checkbox
        colorScheme="blue"
        defaultChecked={defaultChecked}
        onChange={(e) => onChange(attributeName, e.target.checked)}
      />
      <Label label={label} attributeName={attributeName} labelType={labelType} />
      {tooltipText && (
        <Tooltip isDisabled={!tooltipText} label={tooltipText} placement="right" borderRadius={'10px'}>
          <Stack justifyContent={'center'}>
            <Icon as={FiInfo} />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};

const ZCheckboxGroup = ({elements, onSave, ...props}) => {
  return (
    <Stack direction="row" gap={8} wrap={'wrap'} {...props}>
      {elements.map((element, index) => (
        <ZCheckbox
          key={index}
          attributeName={element.attributeName}
          defaultChecked={element.defaultChecked}
          label={element.label}
          onChange={onSave}
        />
      ))}
    </Stack>
  );
};

const ZCheckboxCard = ({
  attributeName,
  selected: selectedInitial,
  children,
  element,
  tooltipText,
  onChange: onChangeCallback,
  isDisabled,
  elementBodyComponent,
  ...props
}) => {
  const [stateSelected, setSelected] = useState(selectedInitial);

  const onChange = (e) => {
    const isChecked = e.target.checked;
    setSelected(isChecked);
    onChangeCallback(attributeName, isChecked);
  };

  const selectedStyles = {
    borderColor: 'blue.500',
    padding: '20px',
    borderWidth: '2px',
    backgroundColor: 'blue.50',
    ':hover': {
      borderColor: 'blue.600',
    },
  };

  const styles = stateSelected ? selectedStyles : null;

  return (
    <Tooltip isDisabled={!tooltipText} label={tooltipText} placement="top-start" borderRadius={'10px'}>
      <Stack
        width={'100%'}
        direction="row"
        as="label"
        cursor="pointer"
        p="21px"
        rounded="lg"
        sx={{
          padding: '21px',
          borderColor: 'gray.200',
          borderWidth: '1px',
          ':hover': {
            borderColor: 'gray.300',
          },
          ...styles,
        }}
        {...props}
      >
        {children && <Stack flexGrow={1}>{children}</Stack>}
        {elementBodyComponent && element && <Stack flexGrow={1}>{elementBodyComponent((element = {element}))}</Stack>}
        <Checkbox
          isDisabled={isDisabled}
          colorScheme="blue"
          defaultChecked={stateSelected}
          alignSelf="start"
          onChange={onChange}
        />
      </Stack>
    </Tooltip>
  );
};

/** element object has an id, then the body component specified as prop can use other attributes of element */
const ZCheckboxCardGroup = ({
  elements = [],
  selectedElements: initialSelectedElements = [],
  disabledElements = [],
  onChange: onChangeCallback,
  elementBodyComponent,
  ...props
}) => {
  const [stateSelectedElements, setSelectedElements] = useState(initialSelectedElements);

  const onChange = (attributeName, value) => {
    const newSelectedElements = value
      ? [...stateSelectedElements, attributeName]
      : stateSelectedElements.filter((element) => element !== attributeName);
    setSelectedElements(newSelectedElements);
    onChangeCallback(newSelectedElements);
  };

  return (
    <Stack {...props} spacing={2}>
      {elements.map((element, index) => (
        <ZCheckboxCard
          attributeName={element.id}
          key={index}
          element={element}
          onChange={onChange}
          selected={stateSelectedElements.includes(element.id)}
          isDisabled={disabledElements.includes(element.id)}
          elementBodyComponent={elementBodyComponent}
        />
      ))}
    </Stack>
  );
};

const ZSlider = ({min, max, step = 1, defaultValue, onChangeEnd, ...props}) => {
  return (
    <Stack {...props}>
      <Slider onChangeEnd={onChangeEnd} defaultValue={defaultValue} min={min} max={max} step={step}>
        <SliderTrack bg="blue.100">
          <Box position="relative" right={10} />
          <SliderFilledTrack bg="blue.600" />
        </SliderTrack>
        <SliderThumb boxSize={6} />
      </Slider>
    </Stack>
  );
};

const ZRangeSlider = ({min = 0, max, step = 1, defaultValue, onChangeEnd, ...props}) => {
  return (
    <Stack width={'100%'} {...props}>
      <RangeSlider onChangeEnd={onChangeEnd} defaultValue={defaultValue} min={min} max={max} step={step}>
        <RangeSliderTrack bg="blue.100">
          <RangeSliderFilledTrack bg="blue.600" />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={6} index={0} />
        <RangeSliderThumb boxSize={6} index={1} />
      </RangeSlider>
    </Stack>
  );
};

const ZRadioGroup = ({attributeName, description, value, onChange, isDisabled, elements, ...props}) => {
  const [stateValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Stack {...props}>
      <BodySmMuted>{description}</BodySmMuted>
      <RadioGroup
        onChange={(value) => {
          setValue(value);
          onChange(attributeName, value);
        }}
        value={stateValue}
      >
        <Stack spacing={5} direction="row">
          {elements.map((element, index) => (
            <ZRadioElementCard
              key={index}
              defaultChecked={element.value == stateValue}
              isDisabled={isDisabled || element.isDisabled}
              value={element.value}
              selected={element.value == stateValue}
              tooltipText={element.tooltipText}
            >
              <Stack>
                <BodySmSemiBold>{element.label}</BodySmSemiBold>
              </Stack>
            </ZRadioElementCard>
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

const ZIconButtonAnimated = ({icon, tooltipText, ...props}) => {
  const [isClicked, setClicked] = useBoolean();
  const [stateHasClicked, setHasClicked] = useState(false);

  return (
    <Tooltip isDisabled={!tooltipText} label={tooltipText} placement="top-start" borderRadius={'10px'}>
      <Stack>
        <IconButton
          icon={React.cloneElement(icon, {
            transition: 'transform 0.2s, color 0.2s',
            transform: isClicked ? 'scale(1.2)' : 'scale(1)',
            color: stateHasClicked ? 'blue.600' : 'inherit',
          })}
          variant={'outline'}
          colorScheme="blue"
          border={'1px'}
          borderColor={'brand.bg2'}
          {...props}
          onClick={(e) => {
            setClicked.on();
            setTimeout(() => {
              setClicked.off();
            }, 200);
            setHasClicked(true);
            if (props.onClick) {
              props.onClick(e);
            }
          }}
        />
      </Stack>
    </Tooltip>
  );
};

const ZIconButton = ({icon, tooltipText, tooltipTextPlacement = 'top-start', isPrimary = false, ...props}) => {
  const sx = isPrimary
    ? {
        '.chakra-button__icon *': {color: 'white'},

        color: 'white',
        backgroundImage: '/buttonbg.jfif',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        ':hover': {
          backgroundImage: '/buttonbg.jfif',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(85%)',
          transition: 'all .2s',
        },

        ':active': {
          backgroundImage: '/buttonbg.jfif',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(65%)',
          transition: 'all .2s',
        },
      }
    : undefined;

  return (
    <Tooltip isDisabled={!tooltipText} label={tooltipText} placement={tooltipTextPlacement} borderRadius={'lg'}>
      <Stack>
        <IconButton
          icon={icon}
          variant={'outline'}
          colorScheme="blue"
          border={'1px'}
          borderColor={'brand.bg2'}
          sx={sx}
          {...props}
        />
        ;
      </Stack>
    </Tooltip>
  );
};

const ZDraggable = ({children, ...props}) => {
  return (
    <Flex {...props} direction={'row'} gap={4} alignItems={'center'}>
      <ZCard flexGrow={1}>{children}</ZCard>
      <Icon w={6} h={6} color={'gray.400'} as={MdDragIndicator} />
    </Flex>
  );
};

export {
  ZColorPicker,
  ZAccordionSingleItem,
  ZAccordion,
  ZAlertFullWidth,
  ZInputSavable,
  ZNumberInput,
  ZSwitch,
  ZCheckbox,
  ZCheckboxGroup,
  ZSlider,
  ZRangeSlider,
  ZRadioGroup,
  ZCheckboxCard,
  ZCheckboxCardGroup,
  ZEditable,
  ZDraggable,
  ZIconButton,
  ZIconButtonAnimated,
};
