import {Alert, AlertIcon, Button, Icon, Stack, Text, Textarea, useToast} from '@chakra-ui/react';
import axios from 'axios';
import {useRef, useState} from 'react';
import {InlineWidget} from 'react-calendly';
import {FiArrowLeft, FiSave} from 'react-icons/fi';
import {FiUpload} from 'react-icons/fi';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';

import FancyButton from '../../components/abstraction_high/FancyButton';
import {getPK} from '../../utils/data-transfer';

const Feedback = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const fileUDID = searchParams.get('fileUDID');

  const refBugs = useRef();
  const refFavoriteFeature = useRef();
  const refFeatureFeedback = useRef();
  const refOtherFeedback = useRef();

  const [stateFeedbackSubmitted, setFeedbackSubmitted] = useState(false);

  function clearFeedback() {
    refBugs.current.value = '';
    refFavoriteFeature.current.value = '';
    refFeatureFeedback.current.value = '';
    refOtherFeedback.current.value = '';
  }

  function getFeedback() {
    return {
      bugs: refBugs.current?.value,
      favoriteFeature: refFavoriteFeature.current?.value,
      featureFeedback: refFeatureFeedback.current?.value,
      otherFeedback: refOtherFeedback.current?.value,
    };
  }
  async function submitFeedbackForm(event) {
    event.preventDefault();

    const {bugs, favoriteFeature, featureFeedback, otherFeedback} = getFeedback();
    if (bugs || favoriteFeature || featureFeedback || otherFeedback) {
      await saveFeedback(bugs, favoriteFeature, featureFeedback, otherFeedback);
      clearFeedback();
    } else {
      console.log('no feedback to submit');
    }
  }

  async function saveFeedback(bugs, favoriteFeature, featureFeedback, otherFeedback) {
    console.log(`saveFeedback`);

    try {
      let newUserSub;
      if (!fileUDID) {
        newUserSub = 'anonymous';
      } else {
        const {userSub, _} = getPK(fileUDID);
        newUserSub = userSub;
      }
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/ddb/feedback', {
        userSub: newUserSub,
        bugs: bugs,
        favoriteFeature: favoriteFeature,
        featureFeedback: featureFeedback,
        otherFeedback: otherFeedback,
      });

      if (response.status != 200) {
        throw new Error('failed to save feedback');
      }

      setFeedbackSubmitted(true);
      console.log(`success saveFeedback`);
      return true;
    } catch (e) {
      toast({
        title: 'An error occurred while saving your feedback. Please contact support.',
        status: 'warning',
        isClosable: true,
      });
      console.log(`error saveFeedback ${e}`);
      return false;
    }
  }

  function navigateToUpload() {
    // check to see if feedback has been saved. highlight button if not
    const feedback = getFeedback();
    console.log(feedback);
    for (const item in feedback) {
      console.log(feedback[item]);
      if (feedback[item]) {
        toast({
          title: 'Please save your feedback',
          status: 'success',
          isClosable: true,
        });
        return;
      }
    }

    navigate('/');
  }

  function navigationToConfirmation() {
    const search = createSearchParams({fileUDID: fileUDID}).toString();
    navigate({pathname: '/confirmation', search: search});
  }

  return (
    <Stack justify="flex-start" align="center" padding="40px" spacing="40px" width="full" overflowY="scroll">
      <Stack justify="flex-start" align="center" spacing="10px">
        <Text fontFamily="Inter" lineHeight="1" fontWeight="bold" fontSize="72px" color="black" textAlign="center">
          Give Feedback
        </Text>
        <Text
          fontFamily="Inter"
          lineHeight="1.33"
          fontWeight="regular"
          fontSize="24px"
          color="black"
          width="600px"
          maxWidth="100%"
          textAlign="center"
        >
          We're listening. Tell us what you think. If you'd rather send us a direct message, click the chat icon in the
          bottom right corner of the screen.
        </Text>
        {fileUDID && (
          <Stack direction="row" spacing="20px">
            <Button
              leftIcon={<Icon as={FiArrowLeft} />}
              size="lg"
              colorScheme="gray"
              onClick={navigationToConfirmation}
            >
              Back
            </Button>
            <FancyButton
              size="lg"
              colorScheme="teal"
              variant="solid"
              leftIcon={<Icon as={FiUpload} />}
              onClick={() => {
                navigateToUpload();
              }}
            >
              Upload Another Episode
            </FancyButton>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" justify="center" align="flex-start" spacing="40px">
        <Stack justify="flex-start" align="flex-start" spacing="20px" alignSelf="stretch">
          <Text
            fontFamily="Inter"
            lineHeight="1.2"
            fontWeight="bold"
            fontSize="30px"
            color="#000000"
            textAlign="center"
          >
            Schedule some time to talk with us
          </Text>
          <InlineWidget
            url="https://calendly.com/podflow/feedback"
            styles={{
              width: '100%',
              height: '100%',
            }}
            // pageSettings= { ...some code }
            // prefill = { ... some code }
            // utm = { ... some code }
          />
        </Stack>
        <Stack spacing="20px" alignSelf="stretch" justify="center" align="center">
          <Text
            fontFamily="Inter"
            fontWeight="bold"
            fontSize="30px"
            color="gray.300"
            alignSelf="stretch"
            textAlign="center"
          >
            OR
          </Text>
        </Stack>
        <Stack justify="flex-start" align="flex-start" spacing="20px" alignSelf="stretch">
          <Text
            fontFamily="Inter"
            lineHeight="1.2"
            fontWeight="bold"
            fontSize="30px"
            color="#000000"
            textAlign="center"
          >
            What do you think? We're listening.
          </Text>

          {!stateFeedbackSubmitted ? (
            <Stack width="100%">
              <Stack spacing="5px" width="100%">
                <Text fontFamily="Inter" lineHeight="1.43" fontWeight="regular" fontSize="14px" color="black">
                  Any issues? Bugs, glitches, things not working right?
                </Text>
                <Textarea
                  placeholder="Glitches, bugs, or other issues"
                  isInvalid={false}
                  isDisabled={false}
                  height="80px"
                  ref={refBugs}
                  alignSelf="stretch"
                  borderColor="gray.200"
                  borderStartWidth="1px"
                  borderEndWidth="1px"
                  borderTopWidth="1px"
                  borderBottomWidth="1px"
                  fontSize="14px"
                />
              </Stack>
              <Stack spacing="5px" width="100%">
                <Text fontFamily="Inter" lineHeight="1.43" fontWeight="regular" fontSize="14px" color="black">
                  Which feature(s) are most important to you?
                </Text>
                <Textarea
                  placeholder="Which features made Podflow right for you? What features do you really want to see ASAP?"
                  isInvalid={false}
                  isDisabled={false}
                  height="80px"
                  ref={refFavoriteFeature}
                  alignSelf="stretch"
                  borderColor="gray.200"
                  borderStartWidth="1px"
                  borderEndWidth="1px"
                  borderTopWidth="1px"
                  borderBottomWidth="1px"
                  fontSize="14px"
                />
              </Stack>
              <Stack spacing="5px" width="100%">
                <Text fontFamily="Inter" lineHeight="1.43" fontWeight="regular" fontSize="14px" color="black">
                  Which features worked as well as you wanted, and which ones didn’t?
                </Text>
                <Textarea
                  placeholder={'Ex. "The shownotes blew my mind! But the enhancement makes my voice sound choppy."'}
                  isInvalid={false}
                  isDisabled={false}
                  height="80px"
                  alignSelf="stretch"
                  ref={refFeatureFeedback}
                  borderColor="gray.200"
                  borderStartWidth="1px"
                  borderEndWidth="1px"
                  borderTopWidth="1px"
                  borderBottomWidth="1px"
                  fontSize="14px"
                />
              </Stack>
              <Stack spacing="5px" width="100%">
                <Text fontFamily="Inter" lineHeight="1.43" fontWeight="regular" fontSize="14px" color="black">
                  Any other feedback? It can be about anything
                </Text>
                <Textarea
                  placeholder="Do you have any other feedback? What would you change if you could snap your fingers and make it happen?"
                  isInvalid={false}
                  isDisabled={false}
                  height="80px"
                  alignSelf="stretch"
                  borderColor="gray.200"
                  borderStartWidth="1px"
                  ref={refOtherFeedback}
                  borderEndWidth="1px"
                  borderTopWidth="1px"
                  borderBottomWidth="1px"
                  fontSize="14px"
                />
              </Stack>
              <Button
                leftIcon={<Icon as={FiSave} />}
                colorScheme="gray"
                variant="solid"
                width="100%"
                onClick={submitFeedbackForm}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Stack
              borderRadius="8px"
              justify="center"
              maxWidth="600px"
              width="90vw"
              align="center"
              borderColor="gray.200"
              borderWidth="1px"
              background="#FFFFFF"
            >
              <Alert status="success" variant="subtle">
                <AlertIcon />
                Feedback Submitted! Thanks!
              </Alert>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Feedback;
