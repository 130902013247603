import {EpisodeEditor} from './episode-editor';

/** Built on top of Howler.js */
export class AudioPlayer {
  constructor(resources, referenceId) {
    this.src = resources;
    this.referenceId = referenceId;
    this.soundId = null;
    this.seekPos = null;
    this.init();

    // DEV
    this.seekStart = 0;
    this.seekLatency = 0;
  }

  // STATES
  static STATE_UNLOADED = 'unloaded';
  static STATE_LOADED = 'loaded';
  static STATE_PLAYING = 'playing';
  static STATE_PAUSED = 'paused';

  // AP REFERENCE NAMES
  static AP_RAW_REF = 'audio_player_raw_ref';
  static AP_BG_NOISE_REF = 'audio_player_background_noise_ref';
  static AP_AGGRESSIVE_REF = 'audio_player_aggressive_ref';

  init() {
    const self = this;
    this.state = AudioPlayer.STATE_UNLOADED;

    const {Howl} = require('howler');

    this.player = new Howl({
      src: this.src,
      html5: true,
      preload: true,
      onload: function (id) {
        this.state = AudioPlayer.STATE_LOADED;
        EpisodeEditor.i().apOnLoad(self.referenceId);
      },
      onloaderror: function (id, error) {},
      onseek: function (id) {
        this.seekStart = Date.now();
      },
      onplayerror: function (id, error) {},
      onpause: function (id) {
        EpisodeEditor.i().apOnPause(self.referenceId);
        EpisodeEditor.i().apOnStateChange(AudioPlayer.STATE_PAUSED);
      },
      onplay: function (id) {
        // setting seek pos here because calling play right after setting seek position doesn't work. Seek only works while playing
        if (self.seekPos) {
          self.seek(self.seekPos, true);
        }

        this.seekLatency = Date.now() - this.seekStart;
        EpisodeEditor.i().apOnPlay(self.referenceId);
        EpisodeEditor.i().apOnStateChange(AudioPlayer.STATE_PLAYING);
      },
      onend: function () {
        EpisodeEditor.i().apOnEnd(self.referenceId);
        self.state = AudioPlayer.STATE_PAUSED;
        EpisodeEditor.i().apOnStateChange(AudioPlayer.STATE_PAUSED);
      },
    });
  }

  /**
   * @param {Number} pos - seek position in seconds
   * @param {Boolean} autoPlay - true/false start playing after seek
   */
  seek(pos, autoPlay) {
    if (!pos && !autoPlay) {
      this.player.seek(null, this.soundId);
      return;
    }

    if (this.isPlaying()) {
      this.seekPos = null;
      this.player.seek(pos, this.soundId);
    } else {
      this.seekPos = pos;
    }

    if (autoPlay) {
      this.play();
    }
  }

  initSoundId(pos) {
    if (!this.soundId) {
      this.soundId = this.player.play();
      this.player.seek(pos, this.soundId);
    }
  }

  position() {
    const result = this.player.seek();
    return result;
  }

  isPlaying() {
    return this.player.playing();
  }

  play() {
    if (this.soundId) {
      this.player.play(this.soundId);
    } else {
      this.soundId = this.player.play();
    }
  }

  pause() {
    this.seekPos = null;
    this.player.pause();
  }

  totalDuration() {
    return this.player.duration(this.soundId);
  }
}
