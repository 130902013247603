import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  CloseButton,
  Flex,
  Icon,
  Stack,
  Tooltip,
  createStandaloneToast,
} from '@chakra-ui/react';
import {useState} from 'react';
import {FiHelpCircle, FiMessageSquare} from 'react-icons/fi';
import {Link} from 'react-router-dom';

import appStore from '../../stores/app-store';
import ButtonPrimary from '../abstraction_high/ButtonPrimary';
import ZCard from '../abstraction_high/ZCard';
import ZCopyButton from '../abstraction_high/ZCopyButton';
import ZPingAnimation from '../abstraction_high/ZPingAnimation';
import ZProgressBar from '../abstraction_high/ZProgressBar';
import {YoutubeEmbedModal} from '../modals/Modal';
import {ZAlertFullWidth} from './ComponentStyle';
import {Body16, BodySm, BodySmSemiBold, H3} from './TextStyle';
import {theme} from './theme';

const devEnv = process.env.REACT_APP_DEV_ENV;

export const FlowContainer = ({
  children,
  maxWidth,
  minHeight = '100vh',
  maxHeight = '100vh',
  padding,
  py,
  px,
  pb = 20,
  ...props
}) => (
  <Flex w={'100%'} flexDir={'column'} alignItems={'center'} justifyContent={'center'} overflowY="scroll" {...props}>
    <Flex
      width={'100%'}
      flexDir={'column'}
      justifyContent={'space-between'}
      minHeight={minHeight}
      maxHeight={maxHeight}
      maxWidth={maxWidth ?? '7xl'}
      py={py ?? '30px'}
      pb={pb}
      px={px ?? '40px'}
    >
      <ZAlertErrorDefault />
      {children}
    </Flex>
  </Flex>
);

export const FlowHeader = ({
  entireComponent,
  title,
  description,
  rightComponent,
  bottomComponent,
  hideBreadcrumbs,
  flowHelpIcon,
  ...props
}) => {
  const zIsLoading = appStore((state) => state.isLoading);

  const FlowHeaderContainer = ({children, ...props}) => (
    <Stack spacing={1} mb={title ? '9px' : 0} width={'100%'}>
      {zIsLoading ? (
        (zIsLoading && typeof zIsLoading) === 'number' ? (
          <ZProgressBar value={zIsLoading} isIndeterminate={false} />
        ) : (
          <ZProgressBar isIndeterminate={true} />
        )
      ) : (
        <Stack height={2} />
      )}

      {children}
    </Stack>
  );

  if (entireComponent) {
    return <FlowHeaderContainer>{entireComponent}</FlowHeaderContainer>;
  }

  return (
    <FlowHeaderContainer {...props}>
      <Flex justifyContent={'space-between'} alignItems={'end'} w={'100%'}>
        <Stack justifyContent={'center'} spacing={0}>
          <Flex direction={'row'} gap={4} alignItems={'end'}>
            <H3 maxWidth={'30vw'} overflow="hidden" whiteSpace={'nowrap'} textOverflow="ellipsis">
              {title}
            </H3>
            {!hideBreadcrumbs && <ZBreadcrumb />}
            {flowHelpIcon}
          </Flex>

          {bottomComponent && <Box mt={2}>{bottomComponent}</Box>}

          {description && (
            <Body16 color="muted" mt={2}>
              {description}
            </Body16>
          )}
        </Stack>

        <Stack justifyContent={'center'} alignItems={'end'}>
          {rightComponent}
        </Stack>
      </Flex>
    </FlowHeaderContainer>
  );
};

export const FlowHelpIcon = ({
  ytSrc,
  ytTitle,
  tooltipLabel,
  showPingAnimation,
  tooltipLabelPlacement = 'right',
  ...iconProps
}) => {
  const [stateModalProps, setModalProps] = useState(null);
  const [stateShowPingAnimation, setShowPingAnimation] = useState(showPingAnimation);

  const handleMouseEnter = () => {
    // setShowPingAnimation(false); // Stop the animation when hovered
  };

  return (
    <Flex
      mb={'3px'}
      cursor="pointer"
      onMouseEnter={handleMouseEnter}
      onClick={() => {
        setModalProps({showModal: true});
        setShowPingAnimation(false);
      }}
    >
      <Tooltip isDisabled={!tooltipLabel} label={tooltipLabel} placement={tooltipLabelPlacement} borderRadius={'lg'}>
        <Stack spacing={0}>
          <Icon
            as={FiHelpCircle}
            color={'gray.900'}
            _hover={{color: 'brand.primary'}}
            fontSize="16px"
            cursor="pointer"
            title="Help"
            {...iconProps}
          />

          <YoutubeEmbedModal
            openThisModal={stateModalProps}
            onCloseCallback={() => setModalProps(null)}
            title={ytTitle}
            src={ytSrc}
          />
        </Stack>
      </Tooltip>

      {stateShowPingAnimation && <ZPingAnimation />}
    </Flex>
  );
};

export const FlowBody = (props) => (
  <Stack width={'100%'} justifyContent={'start'} spacing={0} flexGrow={1} {...props}>
    {props.children}
  </Stack>
);

export const FlowFooter = ({leftComponent, rightComponent, children}) => (
  <Flex pt={'30px'} justifyContent={'space-between'}>
    {!!children && children}
    {!children && <Stack justifyContent={'center'}>{leftComponent}</Stack>}
    {!children && <Stack justifyContent={'end'}>{rightComponent}</Stack>}
  </Flex>
);

export const FlowAlerts = ({stateError, setError, stateInfo, setInfo, wrapInCard = false, showCta = false}) => (
  <Stack>
    {stateError && (
      <ZAlertFullWidth
        cta={
          showCta && (
            <Button size="md" onClick={() => (window.$crisp ? window.$crisp.do('chat:open') : null)}>
              Contact Us
            </Button>
          )
        }
        status={'warning'}
        closeCallback={() => setError(false)}
      >
        {stateError} If the issue persists, please reach out and be sure to include your name, email, and url.
      </ZAlertFullWidth>
    )}

    {stateInfo && (
      <ZAlertFullWidth status={'info'} closeCallback={() => setInfo(false)}>
        {stateInfo}
      </ZAlertFullWidth>
    )}
  </Stack>
);

/**
 * @param {*} pathElements - a list of objects with a name and a link
 */
export const ZBreadcrumb = ({pathElements, ...props}) => {
  const zBreadcrumbs = appStore((state) => state.breadcrumbs);

  return (
    zBreadcrumbs.length > 1 && (
      <Breadcrumb {...props}>
        {zBreadcrumbs.map((element) => (
          <BreadcrumbItem key={element.path}>
            <BreadcrumbLink as={Link} to={element.path}>
              {element.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    )
  );
};

// FATAL ERRORS ONLY
function ZAlertErrorDefault({closeCallback, showAlertIcon = true, wrapInCard = false, cta, ...props}) {
  const zError = appStore((state) => state.error);
  const zHideError = appStore((state) => state.hideError);

  function getErrorContent() {
    let content = '';

    // add url
    content += 'URL: \n';
    content += window.location.href + '\n\n';

    // add error
    if (zError.displayMessage) {
      content += 'Message: \n';
      content += zError.displayMessage + '\n\n';
    }
    if (zError.origin) {
      content += 'Origin: \n';
      content += zError.origin + '\n\n';
    }
    if (zError.data) {
      content += 'Data: \n';
      content += JSON.stringify(zError.data) + '\n\n';
    }

    // extra info
    content += 'Name & Email: \n';
    return content;
  }

  const onClose = () => zHideError();
  const CopyErrorButton = () => <ZCopyButton getCopyContentCallback={getErrorContent} cta={'Copy Info'} />;
  const OpenChatButton = () => (
    <ButtonPrimary
      size="md"
      leftIcon={<Icon as={FiMessageSquare} />}
      onClick={() => (window.$crisp ? window.$crisp.do('chat:open') : null)}
    >
      Report Error
    </ButtonPrimary>
  );

  const AlertContent = () => (
    <Flex gap={2} width={'100%'}>
      <Alert py={1} width={'100%'} status={zError.status ?? 'error'} borderRadius={'5px'} variant={'left-accent'}>
        <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex alignItems={'center'} gap={2}>
            {false && <AlertIcon />}
            <BodySmSemiBold>Something went wrong</BodySmSemiBold>
          </Flex>
        </Flex>
      </Alert>
      <CloseButton alignContent={'center'} alignSelf={'center'} onClick={onClose} />
    </Flex>
  );

  const BodyContent = () => (
    <Flex direction={'column'}>
      <BodySm>
        Please report this error to us and we will fix it as soon as possible. Thank you for your patience.
      </BodySm>
    </Flex>
  );

  const CtaContent = () => (
    <Flex justifyContent={'end'} gap={2}>
      {devEnv && <CopyErrorButton />}
      <OpenChatButton />
    </Flex>
  );

  if (!zError) {
    return null;
  }

  return (
    <ZCard variant="outline" p={4} gap={1} width={'100%'} mb={'30px'} {...props}>
      <AlertContent />
      <BodyContent />
      <CtaContent />
    </ZCard>
  );
}

export const defaultToastConfig = {
  duration: undefined,
  isClosable: true,
  status: 'info',
  variant: 'left-accent',
  position: 'bottom',
};
export function displayErrorToast(title, attributes = defaultToastConfig) {
  displayToast(title, {...defaultToastConfig, ...attributes, status: 'warning'});
}
export function displaySuccessToast(title, attributes = defaultToastConfig) {
  displayToast(title, {...defaultToastConfig, ...attributes, status: 'success'});
}
export function displayInfoToast(title, attributes = defaultToastConfig) {
  displayToast(title, {...defaultToastConfig, ...attributes, status: 'info'});
}
export function displayToast(title, attributes = defaultToastConfig) {
  if (!title) {
    return;
  }

  const {toast} = createStandaloneToast(theme);
  const config = {title, ...defaultToastConfig, ...attributes};
  toast(config);
}

export function getToast() {
  const {toast} = createStandaloneToast(theme);
  return toast;
}
