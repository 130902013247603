import WaveSurfer from 'wavesurfer.js';
import * as WaveformCursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import * as WaveformRegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import * as WaveformTimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';

import {EpisodeEditor} from './episode-editor';

export class Waveform {
  constructor(resources, viewWidth, callbacks) {
    this.src = resources;
    this.callbacks = callbacks;
    this.viewWidth = viewWidth;
    this.ws = null; // Wave Surfer instance

    this.currentAudioTime = 0;
    this.totalAudioTime = 0;
    this.region = {start: 0, end: 0};
    this.demo_audio_url = 'https://sur4ide-audio.s3.us-west-2.amazonaws.com/episodes/30S_441K_128BR.mp3'; // todo stop using default data

    this.init();
  }

  async init() {
    if (!this.ws) {
      this.ws = WaveSurfer.create({
        container: '#waveform',
        partialRender: false,
        waveColor: '#3182CE',
        progressColor: '#319795',
        backend: 'MediaElement',
        scrollParent: true,
        minPxPerSec: 120,
        //   fillParent: false,
        pixelRatio: 1,
        plugins: [
          WaveformRegionsPlugin.create({
            dragSelection: {
              slop: 5,
            },
          }),
          WaveformTimelinePlugin.create({
            container: '#waveform-timeline',
            height: 5,
          }),
          WaveformCursorPlugin.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '5px',
              'font-size': '10px',
            },
          }),
        ],
      });

      this.ws.on('region-created', () => {
        this.ws.clearRegions(); // only allow one region at a time
      });

      this.ws.on('interaction', () => {
        console.log('interacted');
      });

      const response = await fetch('testingJson.txt');
      const json = await response.json();
      console.log(JSON.parse(json[3]).data);
      // console.log(json[3].data)
      EpisodeEditor.i().setPeaks(JSON.parse(json[3]).data);

      this.ws.on('region-update-end', () => {
        console.log('region update end');
        let region = Object.values(this.ws.regions.list)[0];
        if (region) {
          let start = region.start;
          let end = region.end;
          this.setRegion({start, end});
        }
      });

      this.ws.load(this.demo_audio_url, json[3].data, 0);

      this.ws.on('ready', () => {
        this.setTotalAudioTime(this.ws.getDuration());
      });

      this.ws.on('audioprocess', () => {});
    }
  }

  load(url, peaks) {
    this.ws.load(url, peaks);
  }

  clearRegions() {
    console.log('wf clear regions');
    this.ws.clearRegions();
  }

  position() {
    return this.ws.getCurrentTime();
  }

  setCurrentPosition() {
    const pos = this.ws.getCurrentTime();
    this.currentAudioTime = pos;
    this.callbacks.wfSetCurrentPosition(pos);
  }

  setTotalAudioTime(totalAudioTime) {
    this.totalAudioTime = totalAudioTime;
    EpisodeEditor.i().wfSetTotalAudioTime(totalAudioTime);
  }

  setRegion(region) {
    this.region = region;
    EpisodeEditor.i().wfSetRegion(region);
  }
}
