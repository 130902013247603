import {Icon} from '@chakra-ui/react';
import {useState} from 'react';
import {FiCopy} from 'react-icons/fi';

import {copyToClipboard} from '../../utils';
import ButtonSecondary from './ButtonSecondary';

const ZCopyButton = ({
  cta,
  tooltipLabel,
  tooltipLabelPlacement = 'right',
  getCopyContentCallback = () => console.log('TODO getCopyContentCallback'),
  wrap = true,
  ...props
}) => {
  const [stateCopied, setStateCopied] = useState(false);

  async function onCopyClick() {
    const copyContent = getCopyContentCallback();
    const copySuccess = await copyToClipboard(copyContent);
    if (!copySuccess) {
      return;
    }

    setStateCopied(true);
    setTimeout(() => setStateCopied(false), 3000);
  }

  return (
    <ButtonSecondary
      leftIcon={<Icon as={FiCopy} />}
      onClick={onCopyClick}
      wrap={wrap}
      tooltipLabel={tooltipLabel}
      tooltipLabelPlacement={tooltipLabelPlacement}
      {...props}
    >
      {stateCopied ? 'Copied!' : cta || 'Copy'}
    </ButtonSecondary>
  );
};

export default ZCopyButton;
