export default class InputUtils {
  static validateInputEasy(input) {
    console.log('validateInputEasy', input);
    return true;
  }

  static validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  static validateUrl = (url) => {
    // todo implement
    return true;
  };

  static validateInput = (input) => {
    if (input.trim().length === 0) return false;
    return true;
  };

  /** @returns undefined if valid input, and message if not */
  static validateInputWithMessage = (input) => {
    if (input.trim().length === 0) return 'Input is required';
    return undefined;
  };

  /** @returns a function accepting input. The returned function returns undefined if input is valid, and a message otherwise */
  static validateInputLengthFunc(minLength = 1, maxLength = 100) {
    return (input) => {
      if (!input) return 'Input is required';
      if (!this.validateInput(input)) return 'Input is required';

      const trimmedLength = input.trim().length;
      if (trimmedLength < minLength) return `Input must be at least ${minLength} characters`;
      if (trimmedLength > maxLength) return `Input is too large.`;
    };
  }

  static removeFileExtension = (filename) => {
    const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, '');
    console.log('filenameWithoutExtension', filenameWithoutExtension); // Output: example
    return filenameWithoutExtension;
  };
}
