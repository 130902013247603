import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Switch,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {Icon, Stack} from '@chakra-ui/react';
import {useEffect, useRef, useState} from 'react';
import {FiCheck, FiChevronsLeft, FiChevronsRight, FiInfo, FiMic, FiSearch} from 'react-icons/fi';
import {MdFormatQuote} from 'react-icons/md';

import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import {QuillEditor} from '../../components/abstraction_high/QuillEditor';
import ZCard from '../../components/abstraction_high/ZCard';
import ZPingAnimation from '../../components/abstraction_high/ZPingAnimation';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import DownloadTranscriptButton from '../../components/abstraction_mid/DownloadTranscriptButton';
import {BodySm, BodySmMuted, BodySmSemiBold} from '../../components/common/TextStyle';
import {InputModal, SearchReplaceModal} from '../../components/modals/Modal';
import episodeStore from '../../stores/episode-store';
import {TRANSCRIPT} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';

export const Transcript = () => (
  <Stack spacing={4}>
    <Header />
    <TranscriptDisplay />
  </Stack>
);

export const TranscriptDisplay = ({height}) => {
  const zTranscript = episodeStore((state) => state.transcript);

  if (!zTranscript) {
    return <Stack spacing={4}>{<TranscriptProgressUpdate />}</Stack>;
  }

  return <Stack spacing={4}>{<Body height={height} />}</Stack>;
};

const NoContentMessage = () => (
  <Flex mx={4} my={4} gap={4} alignItems={'center'}>
    <Icon boxSize={6} as={FiInfo} />

    <BodySmSemiBold>
      This transcript is empty. Please take a look at the file that you uploaded to make sure everything seems normal.
    </BodySmSemiBold>
  </Flex>
);

const TranscriptProgressUpdate = () => {
  const zTranscriptJobProgress = episodeStore((state) => state.transcriptJobProgress);
  const zEpisode = episodeStore((state) => state.episode);

  if (!zEpisode.inputsCustom) {
    return (
      <ZCard variant="outline" minHeight={'4.6rem'}>
        <Flex gap={4} alignItems={'center'}>
          <Icon boxSize={6} as={FiInfo} />
          <BodySmMuted>
            Submit your episode info so we can start building your transcript. Your transcript will appear here when
            we're done.
          </BodySmMuted>
        </Flex>
      </ZCard>
    );
  }

  return (
    <ZCard variant="outline" minHeight={'4.6rem'}>
      <Stack>
        {zTranscriptJobProgress != 100 && (
          <Flex gap={4} alignItems={'center'}>
            <Icon boxSize={6} as={FiInfo} />

            <BodySmMuted>
              We're building your transcript! This takes just 30 seconds for a 30 minute recording. Don't want to wait?
              You can leave this screen at any time - your progress is already saved."
            </BodySmMuted>
          </Flex>
        )}

        <BodySmMuted>Transcript {zTranscriptJobProgress == 100 ? 'Complete' : 'Progress...'}</BodySmMuted>

        <ZProgressBar value={zTranscriptJobProgress ?? 0} size={'xs'} isIndeterminate={false} />
      </Stack>
    </ZCard>
  );
};

const Body = ({height = '60vh'}) => {
  const zTranscript = episodeStore((state) => state.transcript);
  const zSpeakers = episodeStore((state) => state.speakers);
  const zTranscriptFormatSettings = episodeStore((state) => state.transcriptFormatSettings);
  const zUpdateUtterance = episodeStore((state) => state.updateUtterance);
  const zSetTranscriptSaveable = episodeStore((state) => state.setTranscriptSaveable);
  const zSetTranscriptElementSelected = episodeStore((state) => state.setTranscriptElementSelected);

  return (
    <ZCard variant="outline" padding={0} pt={4} flexGrow={1} flex={1}>
      <Box height={height} overflowY="scroll">
        {!zTranscript.utterances && <NoContentMessage />}

        {zTranscript.utterances?.map((utterance, index) => {
          const {displayText} = TRANSCRIPT.getUtteranceLabelText(utterance, zTranscriptFormatSettings, zSpeakers);
          const utteranceText = TRANSCRIPT.getUtteranceBodyText(utterance, zTranscriptFormatSettings, zSpeakers, true);

          return (
            <Stack key={index} spacing={0} py={'12px'} px={6}>
              <Flex direction={'row'} gap={4} justifyContent={'space-between'}>
                <ElementBlockDivider index={index} />

                <Stack flexGrow={1} spacing={0}>
                  {displayText && (
                    <QuillEditor
                      content={displayText}
                      passPlainTextInOnChangeCallback={true}
                      readOnly={
                        zTranscriptFormatSettings.style !== 'default'
                          ? 'Editing is disabled for this transcript format style.'
                          : null
                      }
                      onChange={(content) => {}}
                      onChangeDelayed={(content) => {
                        const utteranceCopy = {...utterance, label: content};
                        zUpdateUtterance(index, utteranceCopy);
                        zSetTranscriptSaveable(true);
                      }}
                      isFocusedCallback={(isFocused) => (isFocused ? zSetTranscriptElementSelected(index) : null)}
                    />
                  )}

                  <QuillEditor
                    content={utteranceText}
                    passPlainTextInOnChangeCallback={true}
                    readOnly={
                      zTranscriptFormatSettings.style !== 'default'
                        ? 'Editing is disabled for this transcript format style.'
                        : null
                    }
                    onChange={(html) => {}}
                    onChangeDelayed={(html) => {
                      const utteranceCopy = {...utterance, text: html};
                      zUpdateUtterance(index, utteranceCopy);
                      zSetTranscriptSaveable(true);
                    }}
                    isFocusedCallback={(isFocused) => (isFocused ? zSetTranscriptElementSelected(index) : null)}
                  />
                </Stack>
              </Flex>
            </Stack>
          );
        })}
      </Box>
    </ZCard>
  );
};

const Header = () => {
  const [stateSpeakerModalProps, setSpeakerModalProps] = useState({openThisModal: false});
  const [stateSearchReplaceModalProps, setSearchReplaceModalProps] = useState({openThisModal: false});

  const zTranscript = episodeStore((state) => state.transcript);
  const zTranscriptJobProgress = episodeStore((state) => state.transcriptJobProgress);
  const zSetTranscript = episodeStore((state) => state.setTranscript);
  const zSetTranscriptSaveable = episodeStore((state) => state.setTranscriptSaveable);
  const zTranscriptFormatSettings = episodeStore((state) => state.transcriptFormatSettings);
  const zUpdateTranscriptFormatSettings = episodeStore((state) => state.updateTranscriptFormatSettings);
  const zSpeakers = episodeStore((state) => state.speakers);

  const {transcriptApi} = useEpisodeDependentAPIs();

  function toggleShowTimestamps(event) {
    const isChecked = event.target.checked;
    zUpdateTranscriptFormatSettings({showTimestamps: isChecked});
  }

  function toggleShowSpeakerLabels(event) {
    const isChecked = event.target.checked;
    zUpdateTranscriptFormatSettings({showSpeakers: isChecked});
  }

  function changeFormatStyle(style) {
    const finalStyle = style === 'bold_speakers' ? style : 'default';
    if (finalStyle !== zTranscriptFormatSettings.style) {
      zUpdateTranscriptFormatSettings({style: finalStyle});
    }
  }

  function openSearchReplaceModal() {
    setSearchReplaceModalProps({
      utterances: zTranscript.utterances,
      openThisModal: true,
      onSubmit: searchAndReplace,
      onCloseCallback: () => setSearchReplaceModalProps({openThisModal: false}),
    });
  }

  async function searchAndReplace(search, replace) {
    const modTranscript = await transcriptApi.searchAndReplace(search, replace, zTranscript);
    zSetTranscript(modTranscript);
    zSetTranscriptSaveable(true);
    return modTranscript;
  }

  if (zTranscriptJobProgress !== 100) {
    return null;
  }

  return (
    <Stack spacing={0}>
      <ZCard variant="outline" height={'4.6rem'}>
        <Stack direction="row" justify="space-between">
          <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
            <BodySm>Show Timestamps</BodySm>
            <Switch
              size={'sm'}
              onChange={toggleShowTimestamps}
              isChecked={zTranscriptFormatSettings.showTimestamps ?? false}
            />
          </Stack>

          <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
            <BodySm>Show Speaker Labels</BodySm>
            <Switch
              size={'sm'}
              onChange={toggleShowSpeakerLabels}
              isChecked={zTranscriptFormatSettings.showSpeakers ?? false}
            />
          </Stack>

          <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
            <ChangeStylePopover changeFormatStyle={changeFormatStyle} formatSettings={zTranscriptFormatSettings} />
          </Stack>

          <Stack flexGrow={1}></Stack>

          <Stack direction="row" justify="space-between" maxWidth={'66%'} overflowX={'auto'}>
            {!!zTranscript?.speakerOptions && (
              <AssignSpeakers transcriptApi={transcriptApi} setSpeakerModalProps={setSpeakerModalProps} />
            )}
          </Stack>

          <ButtonSecondary leftIcon={<Icon as={FiSearch} />} onClick={openSearchReplaceModal}>
            Search & Replace
          </ButtonSecondary>

          <DownloadTranscriptButton
            isDisabled={!zTranscript?.utterances || (Object.keys(zTranscript.utterances).length ?? 0) === 0}
            fileName={episodeStore.getState().episode?.fileName}
            formatSettings={zTranscriptFormatSettings}
            speakers={zSpeakers}
          />
        </Stack>
      </ZCard>

      <InputModal {...stateSpeakerModalProps} />

      <SearchReplaceModal {...stateSearchReplaceModalProps} />
    </Stack>
  );
};

const ElementBlockDivider = ({index}) => {
  const zTranscriptElementSelected = episodeStore((state) => state.transcriptElementSelected);

  function isElementFocused() {
    return true;
    return zTranscriptElementSelected === index;
  }

  return <Flex>{isElementFocused() ? <Box width={'1px'} bg="gray.300" /> : <Box width={'1px'} />}</Flex>;
};

const AssignSpeakers = ({transcriptApi, setSpeakerModalProps}) => (
  <Popover placement="bottom-start">
    <PopoverTrigger>
      <Stack>
        <ButtonSecondary leftIcon={<Icon as={FiMic} />}>Edit Speaker Labels</ButtonSecondary>
      </Stack>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader border={'none'}>
        <BodySmSemiBold>Edit Speaker Labels</BodySmSemiBold>
      </PopoverHeader>
      <PopoverBody>
        <AssignSpeakersMapping transcriptApi={transcriptApi} setSpeakerModalProps={setSpeakerModalProps} />
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

export const AssignSpeakersMapping = ({transcriptApi, setSpeakerModalProps, mapHorizontally = false}) => {
  const zTranscript = episodeStore((state) => state.transcript);
  const zSetTranscriptSaveable = episodeStore((state) => state.setTranscriptSaveable);
  const zSetOriginalUtteranceSpeakerMapping = episodeStore((state) => state.setOriginalUtteranceSpeakerMapping);
  const zSpeakers = episodeStore((state) => state.speakers);
  const zSetSpeakers = episodeStore((state) => state.setSpeakers);
  const zSetTranscriptUtterances = episodeStore((state) => state.setTranscriptUtterances);
  const zSetTranscriptSpeakerOptions = episodeStore((state) => state.setTranscriptSpeakerOptions);

  const containerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollWidth > container.clientWidth) {
      setHasOverflow(true);
      setShowShadow(true); // add this line
    } else {
      setShowShadow(false); // hide shadow if no overflow
    }
  }, [zSpeakers]);

  const [scrollDirection, setScrollDirection] = useState('right');

  useEffect(() => {
    const container = containerRef.current;

    const handleScrollEvent = () => {
      if (container.scrollLeft === 0) {
        setScrollDirection('right');
        setShowShadow(true); // show shadow when not fully scrolled to the left
      } else if (container.scrollLeft + container.clientWidth >= container.scrollWidth - 5) {
        setScrollDirection('left');
        setShowShadow(false); // hide shadow when fully scrolled to the right
      } else {
        setShowShadow(true); // ensure shadow is visible when scrolling back from right
      }
    };

    container.addEventListener('scroll', handleScrollEvent);
    return () => container.removeEventListener('scroll', handleScrollEvent);
  }, [containerRef]);

  const handleScroll = () => {
    const container = containerRef.current;
    const scrollAmount = 100;

    if (container) {
      if (scrollDirection === 'right') {
        container.scrollLeft += scrollAmount;
      } else if (scrollDirection === 'left') {
        container.scrollLeft -= scrollAmount;
      }
    }
  };
  return (
    <Flex gap={2}>
      {!zSpeakers.some((x) => x.length > 1) && <ZPingAnimation isDisabled={false} topLeft={true} offsetX={0} />}
      <Flex
        gap={2}
        direction={mapHorizontally ? 'row' : 'column'}
        ref={containerRef}
        overflowX={'scroll'}
        sx={{
          boxShadow: showShadow ? '8px 0 8px -5px rgba(0,0,0,0.15)' : 'none',
        }}
      >
        {zSpeakers.map((speaker, i) => (
          <Box key={i} display={'block'}>
            <Select
              width={'auto'}
              minWidth={'130px'}
              value={speaker}
              onChange={(e) => {
                function updateSpeakerAndTranscript(newSpeakerValue) {
                  const updatedSpeakers = [...zSpeakers];
                  updatedSpeakers[i] = newSpeakerValue;
                  zSetSpeakers(updatedSpeakers);

                  zSetOriginalUtteranceSpeakerMapping(TRANSCRIPT.getUtteranceSpeakerMapping(zTranscript));
                  zSetTranscriptUtterances(
                    transcriptApi.replaceSpeakerInUtterances(speaker, newSpeakerValue, zTranscript),
                  );
                  zSetTranscriptSaveable(true);
                }

                if (e.target.value === 'add') {
                  const callback = (newSpeaker) => {
                    updateSpeakerAndTranscript(newSpeaker);
                    zSetTranscriptSpeakerOptions([...zTranscript.speakerOptions, newSpeaker]);
                  };
                  setSpeakerModalProps({
                    title:
                      "Input a new speaker name here, and we'll replace speaker " +
                      speaker +
                      ' with your new speaker name.',
                    openThisModal: true,
                    onSubmit: callback,
                    onCloseCallback: () => setSpeakerModalProps({openThisModal: false}),
                  });
                  return;
                }

                updateSpeakerAndTranscript(e.target.value);
              }}
            >
              <option value={speaker}>{speaker.length === 1 ? 'Speaker ' + speaker : speaker}</option>
              {zTranscript.speakerOptions
                .filter((speakerOption) => speakerOption != speaker)
                .map((speakerOption, index) => (
                  <option key={index} value={speakerOption}>
                    {speakerOption.length === 1 ? 'Speaker ' + speakerOption : speakerOption}
                  </option>
                ))}
              <option value={'add'}>{'+ Add a custom label...'}</option>
            </Select>
          </Box>
        ))}
      </Flex>
      {hasOverflow && (
        <Tooltip label={'scroll ' + scrollDirection} placement="top-start" borderRadius={'5px'}>
          <IconButton
            borderColor={'#e2e8f0'}
            variant="outline"
            height={'100%'}
            onClick={handleScroll}
            colorScheme="white"
            aria-label="Scroll"
            icon={scrollDirection === 'right' ? <Icon as={FiChevronsRight} /> : <Icon as={FiChevronsLeft} />}
          />
        </Tooltip>
      )}

      {/* Display the scroll button only if there's overflow */}
    </Flex>
  );
};

const ChangeStylePopover = ({changeFormatStyle, formatSettings}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  function changeStyle(style) {
    changeFormatStyle(style);
    onClose();
  }

  const Container = ({id, children}) => {
    return (
      <Flex justifyContent={'end'} alignItems={'center'} gap={2}>
        {formatSettings.style === id && <Icon as={FiCheck} />}
        <ButtonSecondary onClick={() => changeStyle(id)}>{children}</ButtonSecondary>
      </Flex>
    );
  };

  return (
    <Popover placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Tag
          size={'sm'}
          variant="outline"
          sx={{'--badge-color': 'var(--chakra-colors-gray-200)'}}
          cursor={'pointer'}
          _hover={{bg: 'brand.bg'}}
        >
          <TagLeftIcon marginInlineEnd={0.5} color={'chakra-body-text'} as={MdFormatQuote} />
          <TagLabel color={'chakra-body-text'}>Style</TagLabel>
        </Tag>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <BodySmSemiBold>Select a Format Style</BodySmSemiBold>
        </PopoverHeader>
        <PopoverBody>
          <Flex justifyContent={'end'}>
            <Stack alignItems={'end'}>
              <Container id="bold_speakers">Bold Speakers</Container>
              <Container id="default">Default Style</Container>
            </Stack>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
