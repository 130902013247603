import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const customPrimary = defineStyle({
  color: 'white',
  backgroundImage: '/buttonbg.jfif',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  ':hover': {
    backgroundImage: './buttonbg.jfif',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(85%)',
    transition: 'all .2s',
  },
  ':active': {
    backgroundImage: './buttonbg.jfif',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(65%)',
    transition: 'all .2s',
  },
});

export const IconButton = defineStyleConfig({variants: {customPrimary}});
