import {Box, Button, Center, CircularProgress, Flex, Icon, Stack, Text, useToast} from '@chakra-ui/react';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {FiPause, FiPlay} from 'react-icons/fi';
import styled from 'styled-components';

import LineBreaker from '../../components/abstraction_high/LineBreaker';
import {AudioPlayer} from '../../utils/editor/audio-player';
import {EpisodeEditor} from '../../utils/editor/episode-editor';

const TextEditor = () => {
  const refRoot = useRef(null);
  const toast = useToast();

  const [stateAudioPosition, setAudioPosition] = useState(0.0);
  const [stateTotalAudioTime, setTotalAudioTime] = useState(0.0);
  const [audioState, setAudioState] = useState(AudioPlayer.STATE_UNLOADED); // unloaded, loaded, playing, paused
  const [stateWordItems, setWordItems] = useState([]);
  const [stateTranscript, setTranscript] = useState([]);

  // region
  const [region, setRegion] = useState({start: 0, end: 0});

  // Selected Text Elements
  const [selectedTextElements, setSelectedTextElements] = useState([]);

  // Deleted Text Elements
  const [deletedTextSet, setDeletedTextSet] = useState(new Set());

  // Input Ref
  const inputRef = useRef();

  useEffect(() => {
    initComponentCallbacks();
    setWordItems(EpisodeEditor.i().textManager.wordItems);
    setTranscript(EpisodeEditor.i().textManager.transcript);
  }, []);

  useLayoutEffect(() => {
    EpisodeEditor.i().initWaveform(refRoot.current.offsetWidth);
  }, []);

  function initComponentCallbacks() {
    EpisodeEditor.i().addComponentCallbacks({
      teOnPositionChange(position) {
        setAudioPosition(position);
      },
      teOnTranscriptLoadError: () => {
        toast({
          title: 'An error occurred while retrieving your transcript. Please try again.',
          status: 'error',
          isClosable: true,
        });
      },
      teSetAudioState: setAudioState,
      teSetRegion: setRegion,
      teSetTotalAudioTime: setTotalAudioTime,
      teSetWordItems: setWordItems,
      teSetTranscript: setTranscript,
    });
  }

  function handleKeyDown(event) {
    // Delete
    event.persist();

    if (event.key === 'Backspace' || event.key === 'Delete') {
      EpisodeEditor.i().textManager.delete_audio_clip();
    }

    // Undo
    if ((event.key === 'z' && event.ctrlKey) || (event.key === 'z' && event.metaKey)) {
      console.log('undo');
      if (EpisodeEditor.i().textManager.editorStack.length === 0) {
        return;
      }
      const last_edit = EpisodeEditor.i().textManager.editorStack.pop();

      // Delete Case
      if (last_edit.type === 'DELETE') {
        EpisodeEditor.i().textManager.setEditorStack([...EpisodeEditor.i().textManager.editorStack]);
        EpisodeEditor.i().textManager.setWordItems([
          ...EpisodeEditor.i().textManager.wordItems.slice(0, last_edit.start_index),
          ...last_edit.items,
          ...EpisodeEditor.i().textManager.wordItems.slice(last_edit.start_index),
        ]);
        const start_time_peak_index = Math.floor(
          parseFloat(EpisodeEditor.i().textManager.wordItems[last_edit.start_index].start_time) * 600,
        );
        const updated_peaks = [
          ...EpisodeEditor.i().textManager.peaks.slice(0, start_time_peak_index),
          ...last_edit.peaks,
          ...EpisodeEditor.i().textManager.peaks.slice(start_time_peak_index),
        ];
        EpisodeEditor.i().textManager.setPeaks(updated_peaks);
        EpisodeEditor.i().waveform.clearRegions();
        // wavesurfer.current.load(demo_audio_url, updated_peaks);
      }
    }
  }

  const TextElement = ({text, start, end, speaker_label, index}) => {
    const start_time = parseFloat(start);
    const end_time = parseFloat(end);
    const speaker = speaker_label;
    if (
      start_time <= stateAudioPosition &&
      end_time >= stateAudioPosition &&
      region.start <= start_time &&
      end_time <= region.end
    ) {
      // Current Audio Playing and Highlighted
      return (
        <span
          key={index}
          data-index={index}
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflowWrap: 'break-word',
            backgroundColor: '#81E6D9',
            transition: 'background-color 0.2s ease-in-out, transform 0.2s ease-in-out',
            transform: 'scale(1.01)',
            transformOrigin: 'top left',
            borderRadius: '5px',
            padding: '0px 5px',
          }}
        >
          {text}
        </span>
      );
    } else if (start_time <= stateAudioPosition && end_time >= stateAudioPosition) {
      // Current Audio Playing
      return (
        <span
          key={index}
          data-index={index}
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflowWrap: 'break-word',
            backgroundColor: '#81E6D9',
            padding: '0px 5px',
            borderRadius: '5px',
            transition: 'background-color 0.2s ease-in-out, transform 0.2s ease-in-out',
            transform: 'scale(1.01)',
            transformOrigin: 'top left',
          }}
        >
          {text}
        </span>
      );
    } else if (
      (region.start <= start_time && end_time <= region.end) ||
      (index >= selectedTextElements[0] && index <= selectedTextElements[1])
    ) {
      // Current Region Selected
      return (
        <span
          key={index}
          data-index={index}
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflowWrap: 'break-word',
            backgroundColor: '#81E6D9',
            padding: '0px 2px',
          }}
        >
          {text}
        </span>
      );
    } else {
      return (
        <span
          key={index}
          data-index={index}
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
          }}
        >
          {text}&nbsp;
        </span>
      );
    }
  };

  return (
    <StyledElement ref={refRoot}>
      <Button
        colorScheme="blue"
        bgGradient="linear(to-r, blue.400, blue.500)"
        borderRadius="5px"
        align="left"
        position="fixed"
        top="60px"
        padding="8px 20px"
        w="auto"
        right="50"
        zIndex="999"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          color="white"
          onClick={() => {
            EpisodeEditor.i().updateSnapshot('rendering');
          }}
        >
          Publish
        </Text>
      </Button>

      {/* <Button colorScheme="teal" isLoading spinner={<BeatLoader size={8} color='white' />} borderRadius="5px" align="left" position="fixed" top="60px" padding="8px 20px" w="auto" right="280" zIndex="999">
        <Text fontSize="md" fontWeight="bold" color="white">
          Publish
        </Text>
      </Button> */}

      <Stack justifyContent={'left'} spacing="10px" top="0">
        <Center>
          <Text
            bgGradient="linear(to-r, teal.300, blue.500)"
            borderRadius="10px"
            width="400px"
            fontFamily="Inter"
            lineHeight="1.33"
            padding={2}
            mt="60px"
            mb="20px"
            fontWeight="bold"
            fontSize="24px"
            color="white"
            textAlign="center"
          >
            Edit audio by editing text.
          </Text>
        </Center>

        <Stack justify={'flex'} align="center">
          {!stateTranscript && <CircularProgress isIndeterminate />}
        </Stack>

        <Flex wrap={'wrap'} width="1200px">
          <Box
            ref={inputRef}
            width="1200px"
            mx="20"
            contentEditable={true}
            onSelect={EpisodeEditor.i().textManager.handleSelectionChange}
            onKeyDown={handleKeyDown}
            suppressContentEditableWarning={true}
            _focus={{outline: 'none'}}
            _hover={{outline: 'none'}}
            _active={{outline: 'none'}}
            spellCheck="false"
          >
            {stateWordItems.map((item, index) => {
              if (deletedTextSet.has(item.alternatives[0].content)) {
                return null;
              } else if (item.type === 'pronunciation') {
                let text_val = item.alternatives[0].content;
                if (index != stateWordItems.length - 1 && stateWordItems[index + 1].type === 'punctuation') {
                  text_val += stateWordItems[index + 1].alternatives[0].content;
                }
                if (index === 0) {
                  text_val = item.speaker_label + ': ' + text_val;
                }
                if (index != 0 && stateWordItems[index - 1].speaker_label != item.speaker_label) {
                  text_val = item.speaker_label + ': ' + text_val;
                  return (
                    <>
                      <LineBreaker key={index} />
                      <TextElement
                        text={text_val}
                        start={item.start_time}
                        end={item.end_time}
                        speaker_label={item.speaker_label}
                        index={index}
                      />
                    </>
                  );
                }
                return (
                  <TextElement
                    text={text_val}
                    start={item.start_time}
                    end={item.end_time}
                    speaker_label={item.speaker_label}
                    index={index}
                  />
                );
              }
            })}
          </Box>
        </Flex>

        <Box width="100%" bottom={0}>
          <Center>
            <Button
              color="primary"
              variant="outlined"
              borderRadius="25px 0px 0px 25px"
              width="60px"
              height="50px"
              mt={10}
              mb={2}
              bgGradient="linear(to-r, teal.200, teal.300)"
              justify="center"
              align="center"
              onClick={EpisodeEditor.i().play}
            >
              {audioState == 'playing' ? <Icon as={FiPause} boxSize={6} /> : <Icon as={FiPlay} boxSize={6} />}
            </Button>
            <Center
              color="primary"
              variant="outlined"
              borderRadius="0px 25px 25px 0px"
              width="150px"
              height="50px"
              mt={10}
              mb={2}
              bgGradient="linear(to-r, teal.300, blue.300)"
              justify="center"
              align="center"
              fontSize={20}
            >
              {stateAudioPosition.toFixed(2) + ' / ' + stateTotalAudioTime.toFixed(2)}
            </Center>
          </Center>

          <div className="daw-container">
            <div className="waveform-container">
              <div id="waveform" className="waveform" />
              <div id="waveform-timeline" className="waveform-timeline"></div>
            </div>
          </div>
        </Box>
      </Stack>
    </StyledElement>
  );
};

export default TextEditor;

const StyledElement = styled.main`
  display: flex;
  flex-direction: column;

  @keyframes enlarge {
    from {
      width: 100%;
      height: 100%;
    }
    to {
      width: 110%;
      height: 110%;
    }
  }

  span {
    font-size: 18px;
    line-height: 25px;
    caret-color: #48bb78;
  }

  span::selection {
    background: #81e6d9;
  }

  .daw-container {
    display: flex;
    background: 'red';
    width: 100%;
    justify-content: center;
  }
  .waveform-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .waveform {
  }
  .waveform-timeline {
    display: flex;
    justify-content: left;
  }

  .progress-bar {
    margin-top: 3rem;
  }

  .dropzone {
    margin-top: 3rem;
  }
  .outlined-error {
    background: hsla(0, 100%, 50%, 0.1);
  }

  .title-input-container {
    margin: 1rem 0;
  }
`;
