import {useAuth0} from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  CloseButton,
  Divider,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {useEffect, useMemo, useState} from 'react';
import {BsLightningCharge} from 'react-icons/bs';
import {FiExternalLink, FiFolder, FiLoader, FiLogIn, FiLogOut, FiPlus, FiUpload, FiUser} from 'react-icons/fi';
import {MdLibraryMusic, MdOutlineFeedback, MdOutlineReviews} from 'react-icons/md';
import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';

import {ProjectItemShell} from '../../pages/project/Projects';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import projectStore from '../../stores/project-store';
import {PROJECT, SUBSCRIPTION, TEAM} from '../../utils/api-v2';
import PricingUtils from '../../utils/pricing-utils';
import SessionStorageUtils from '../../utils/session-storage-utils';
import ButtonPrimary from '../abstraction_high/ButtonPrimary';
import ButtonSecondary from '../abstraction_high/ButtonSecondary';
import ZCard from '../abstraction_high/ZCard';
import {displayInfoToast} from '../common/Structural';
import {BodySm, BodySmMuted, BodySmSemiBold} from '../common/TextStyle';
import NavButton from './NavButton';
import NavItem from './NavItem';
import NavLogo from './NavLogo';

const EXPANDED_WIDTH = '300px';
const MINIMIZED_WIDTH = '104px';
const NAVBAR_TRANSITION = 'all .2s';

const Navbar = () => {
  const {loginWithRedirect, logout} = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const zUser = authStore((state) => state.user);
  const zTeam = authStore((state) => state.team);
  const zSetTeam = authStore((state) => state.setTeam);
  const zSubscription = authStore((state) => state.subscription);
  const zSetSubscription = authStore((state) => state.setSubscription);
  const zAuthenticating = authStore((state) => state.authenticating);
  const zSetBreadcrumb = appStore((state) => state.setBreadcrumbs);
  const zSetError = appStore((state) => state.setError);
  const zProject = projectStore((state) => state.project);

  const [stateNavSize, setNavSize] = useState('small');

  const subscriptionApi = useMemo(() => new SUBSCRIPTION(), []);
  const teamApi = useMemo(() => new TEAM(zUser?.userSub, zUser?.email), [zUser]);

  useEffect(() => {
    if (!zUser) {
      return;
    }

    async function getTeamOwnerUserSub() {
      if (!zUser.teamId) return null;

      let currentTeam = zTeam;
      if (!zTeam) {
        // fetch zTeam if not already fetched
        const team = await teamApi.getTeam(zUser.teamId);
        if (!team || team.length == 0) zSetError('fetchSubscription', 'Error fetching team');
        zSetTeam(team);
        currentTeam = team;
      }
      const teamOwner = TEAM.getTeamOwner(currentTeam);
      return teamOwner.userSub;
    }

    async function fetchSubscription(userSub) {
      userSub = (await getTeamOwnerUserSub()) ?? userSub;

      // get subscription
      const subscription = await subscriptionApi.getSubscription(userSub, false);
      zSetSubscription(subscription);
    }

    fetchSubscription(zUser.userSub);
  }, [zUser]);

  function reconstructBreadcrumbPath(location) {
    const path = location.pathname;
    const pathParts = path.split('/').filter((part) => part);
    const queryParameters = location.search; // This holds the query parameters

    let breadcrumbs = [];
    let constructedPath = '';

    for (let i = 0; i < pathParts.length; i++) {
      constructedPath += '/' + pathParts[i];

      // Append query parameters to the last part of the path
      const fullPath = i === pathParts.length - 1 ? constructedPath + queryParameters : constructedPath;

      // Dynamically construct the name based on the path part
      let name = '';
      switch (pathParts[i]) {
        case 'projects':
          name = 'projects';
          break;
        case 'episodes':
          name = ''; // omit this
          break;
        default:
          // If the path part is not "projects", consider it an ID
          const previousPathPart = pathParts[i - 1];
          if (previousPathPart === 'projects') {
            // If the previous path part is "projects", then the current path part is a project ID
            name = 'project';
          } else if (previousPathPart === 'episodes') {
            // If the previous path part is "episodes", then the current path part is an episode ID
            name = 'episode';
          } else if (previousPathPart === 'private_transcript') {
            name = 'transcript';
          }
      }

      // if name is not empty, push the breadcrumb
      if (name) breadcrumbs.push({name, path: fullPath});
    }

    console.log('breadcrumbs', breadcrumbs, 'pathname', location.pathname, 'search', location.search);
    return breadcrumbs;
  }

  useEffect(() => {
    console.log('The route has changed to', location.pathname);
    const breadcrumbs = reconstructBreadcrumbPath(location);
    zSetBreadcrumb(breadcrumbs);
  }, [location]);

  function navigateToPage(page, searchParams) {
    if (searchParams) {
      navigate({pathname: page, search: createSearchParams(searchParams).toString()});
    } else {
      navigate(page);
    }
  }

  return (
    <Box
      width={stateNavSize === 'large' ? EXPANDED_WIDTH : MINIMIZED_WIDTH}
      height={'100%'}
      paddingY="30px"
      paddingX="20px"
      display="flex"
      flexDirection={'column'}
      gap="40px"
      justifyContent="flex-start"
      alignItems="flex-start"
      bg="gray.50"
      transition={NAVBAR_TRANSITION}
      borderRightRadius={'10px'}
      shadow="base"
    >
      <NavLogo navSize={stateNavSize} />

      {/* Expand/Collapse button */}
      {/* <ExpandButton
        isMax={stateNavSize === "large"}
        label="Navigation"
        maxIcon={<Icon as={FiChevronLeft} />}
        minIcon={<Icon as={FiChevronRight} />}
        position="absolute"
        left={stateNavSize === "large" ? "280px" : "84px"}
        top="40px"
        onClick={() => {
          stateNavSize === "large" ? setNavSize("small") : setNavSize("large");
        }}
        shadow={"base"}
        transition={NAVBAR_TRANSITION}
      /> */}

      <Box width="100%" height="100%" gap="20px" display="flex" flexDirection={'column'}>
        {/* Navigation tabs */}
        <Box width="100%" height="100%" gap="20px" display="flex" flexDirection={'column'} alignSelf="stretch">
          <ProjectSwitchOptions
            stateNavSize={stateNavSize}
            projectOptions={[
              {
                cta: 'View All Projects',
                onClick: () => navigateToPage('/projects', {viewAll: true}),
                icon: <Icon as={FiFolder} boxSize={6} />,
              },
              {
                cta: 'Create New Project',
                onClick: () => navigateToPage('/projects', {viewAll: true, createNew: true}),
                icon: <Icon as={FiPlus} boxSize={6} />,
              },
            ]}
          />

          {zProject && (
            <>
              <NavItem
                name="New Episode"
                onClick={() => navigateToPage(`/projects/${zProject.projectId}`)}
                selected={['/episode'].find((partialPath) => location.pathname.includes(partialPath)) > -1}
                icon={<Icon as={FiUpload} boxSize={6} />}
                navSize={stateNavSize}
              />

              <NavItem
                name="Episode Library"
                onClick={() => navigateToPage(`/projects/${zProject.projectId}`)}
                selected={false}
                icon={<Icon as={MdLibraryMusic} boxSize={6} />}
                navSize={stateNavSize}
              />
            </>
          )}

          {zUser && !SUBSCRIPTION.hasActiveSub(zSubscription) && (
            <NavItem
              name="Upgrade"
              onClick={() => navigateToPage('/pricing')}
              selected={false}
              icon={<Icon as={BsLightningCharge} boxSize={6} />}
              navSize={stateNavSize}
            />
          )}
        </Box>

        {/* Profile */}
        {!zAuthenticating && zUser && (
          <AuthenticatedPopup
            stateNavSize={stateNavSize}
            navigateToPage={navigateToPage}
            zSubscription={zSubscription}
            logout={logout}
          />
        )}

        {!zAuthenticating && !zUser && (
          <UnauthenticatedPopup
            stateNavSize={stateNavSize}
            loginWithRedirect={loginWithRedirect}
            navigateToPage={navigateToPage}
          />
        )}
      </Box>
    </Box>
  );
};

const SelectedProjectOption = ({stateNavSize}) => {
  const zProject = projectStore((state) => state.project);

  return (
    // <Tooltip label={zProject?.name ? zProject?.name : "Select a Project"} placement="right" borderRadius={"5px"}>
    <Box>
      <ProjectItemShell
        acronym={PROJECT.getProjectAcronym(zProject?.name)}
        bgColor={PROJECT.getProjectColor(zProject?.projectId)}
        useIcon={!PROJECT.getProjectAcronym(zProject?.name) && <Icon as={FiFolder} boxSize={6} />}
        height={16}
        condensed={stateNavSize === 'small'}
        background={'gray.50'}
        _hover={{background: 'brand.bg', transition: 'all .2s'}}
      >
        <Stack justifyContent={'center'} overflowX={'hidden'}>
          <Text
            fontWeight={'bold'}
            fontSize="large"
            lineHeight="5"
            color="gray.800"
            style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
          >
            {zProject?.name ?? 'Select a Project'}
          </Text>
        </Stack>
      </ProjectItemShell>
    </Box>
    // </Tooltip>
  );
};

const UnauthenticatedPopup = ({stateNavSize, loginWithRedirect, navigateToPage}) => {
  return (
    <Box display="flex" flexDirection={'column'} gap="10px">
      {/* <NavButton navSize={stateNavSize} leftIcon={<Icon as={BsLightningCharge} />} onClick={() => navigateToPage("/pricing")}>
        Pricing
      </NavButton> */}
      <NavButton
        navSize={stateNavSize}
        leftIcon={<Icon as={FiLogIn} />}
        onClick={() => {
          SessionStorageUtils.setPreAuthPath();
          loginWithRedirect({redirectUri: window.location.origin + '/'});
        }}
      >
        Login
      </NavButton>

      <ButtonPrimary
        w="100%"
        justifyContent={stateNavSize === 'large' ? 'flex-start' : 'center'}
        leftIcon={<Icon as={FiUser} />}
        onClick={() => {
          SessionStorageUtils.setPreAuthPath();
          loginWithRedirect({screen_hint: 'signup', redirectUri: window.location.origin + '/'});
        }}
        tooltipLabel={'Sign Up'}
      >
        {stateNavSize === 'large' ? 'Sign Up' : <></>}
      </ButtonPrimary>
    </Box>
  );
};

const AuthenticatedPopupOptions = ({onClose, navigateToPage, ...props}) => {
  const zUser = authStore((state) => state.user);
  const zSubscription = authStore((state) => state.subscription);

  const memoPricingUtils = useMemo(() => new PricingUtils(zSubscription, zUser), [zSubscription, zUser]);

  const MeteredUsage = ({...props}) => {
    return (
      <Stack spacing={2} {...props}>
        <BodySmSemiBold>Metered Usage</BodySmSemiBold>
        <Progress value={100} borderRadius="8px" colorScheme="blue" size="sm" />
        {memoPricingUtils.getMeteredUsageInfo() && <BodySmMuted>{memoPricingUtils.getMeteredUsageInfo()}</BodySmMuted>}
      </Stack>
    );
  };

  return (
    <ZCard variant="outline" {...props}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Flex justifyContent={'space-between'}>
            <BodySmSemiBold>Monthly Credits</BodySmSemiBold>
            <CloseButton onClick={onClose} />
          </Flex>

          <Progress
            value={memoPricingUtils.getSubUsageProgressValue()}
            borderRadius="8px"
            colorScheme="blue"
            size="sm"
          />
          <BodySmMuted>{memoPricingUtils.getSubscriptionUsageInfo()}</BodySmMuted>

          {!memoPricingUtils.getCreditsRemainingBeforeExtraCharges() && (
            <Stack>
              <ButtonPrimary
                mt={2}
                wrap={true}
                aligntRight={false}
                onClick={() => {
                  navigateToPage('/pricing');
                  onClose();
                }}
              >
                upgrade
              </ButtonPrimary>
            </Stack>
          )}

          <Divider pt={4} />

          {memoPricingUtils.getMeteredUsage() != null && (
            <Stack pt={4} spacing={4}>
              <MeteredUsage />
              <Divider />
            </Stack>
          )}
        </Stack>

        <Stack>
          <BodySm>{zUser.email}</BodySm>
          <Flex justifyContent={'start'}>
            <ButtonSecondary
              onClick={() => {
                navigateToPage('/settings/team');
                onClose();
              }}
            >
              View Team
            </ButtonSecondary>
          </Flex>
        </Stack>
      </Stack>
    </ZCard>
  );
};

const AuthenticatedPopup = ({stateNavSize, navigateToPage, logout}) => {
  const zSubscription = authStore((state) => state.subscription);
  const zUser = authStore((state) => state.user);

  return (
    <Stack spacing={4}>
      <AuthenticatedOptions stateNavSize={stateNavSize} navigateToPage={navigateToPage} logout={logout} />
      <Popover placement="right-end" closeOnBlur={false} defaultIsOpen={false} trigger="hover">
        {({isOpen, onClose}) => (
          <>
            {/* Profile button & indicator */}
            <PopoverTrigger>
              <Box
                width="100%"
                gap="10px"
                display="flex"
                flexDirection={stateNavSize === 'large' ? 'row' : 'column-reverse'}
                justifyContent={stateNavSize === 'large' ? 'flex-start' : 'center'}
                alignItems={'center'}
                cursor="pointer"
                transition={NAVBAR_TRANSITION}
              >
                <Avatar
                  style={{aspectRatio: 1 / 1}}
                  padding={'8px'}
                  width={'100%'}
                  height={'100%'}
                  borderRadius={'100%'}
                  name={zUser.nickname ?? zUser.firstName ?? zUser.email}
                  src={zUser.picture}
                />
                <Box
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  display={stateNavSize === 'large' ? 'flex' : 'none'}
                >
                  <Text fontFamily={'Inter'} fontSize="md" fontWeight={'semibold'} lineHeight={'6'}>
                    {zUser.nickname}
                  </Text>
                </Box>
                {/* <ExpandButton isMax={isOpen} label="Profile Menu" maxIcon={<Icon as={FiChevronDown} />} minIcon={<Icon as={FiChevronUp} />} marginLeft={stateNavSize === "large" ? "auto" : "0"} /> */}
              </Box>
            </PopoverTrigger>
            <PopoverContent
              w={'20rem'}
              boxShadow="none !important"
              pl="20px"
              background="none"
              border="none"
              transition={NAVBAR_TRANSITION}
            >
              <PopoverBody padding="0px">
                {/* Popup menu when profile is clicked */}
                <AuthenticatedPopupOptions onClose={onClose} navigateToPage={navigateToPage} />
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Stack>
  );
};

const AuthenticatedOptions = ({stateNavSize, onClose, navigateToPage, logout}) => {
  const zSubscription = authStore((state) => state.subscription);

  const [stateLoggingOut, setLoggingOut] = useState(false);

  return (
    <Box width="100%" gap="10px" display="flex" flexDirection={'column'}>
      {/* <NavButton
        navSize={stateNavSize}
        leftIcon={<Icon as={FiSlack} />}
        rightIcon={<Icon as={FiExternalLink} />}
        onClick={() => {
          window.open("https://join.slack.com/t/podflowai/shared_invite/zt-1sinjt900-yMWNNnmSIQlEAkeMuKV9xQ", "_blank", "noopener,noreferrer");
        }}
      >
        Join Our Slack
      </NavButton> */}
      <NavButton
        navSize={stateNavSize}
        leftIcon={<Icon as={MdOutlineReviews} />}
        rightIcon={<Icon as={FiExternalLink} />}
        onClick={() => {
          window.open('https://senja.io/p/podflow/r/TOCTTv', '_blank', 'noopener,noreferrer');
        }}
      >
        Leave Testimonial
      </NavButton>
      {/* <NavButton
        navSize={stateNavSize}
        leftIcon={<Icon as={FiCalendar} />}
        rightIcon={<Icon as={FiExternalLink} />}
        onClick={() => {
          window.open("https://calendly.com/machamilton/meeting", "_blank", "noopener,noreferrer");
        }}
      >
        Schedule a Call With Us
      </NavButton> */}
      <NavButton
        navSize={stateNavSize}
        leftIcon={<Icon as={MdOutlineFeedback} />}
        onClick={() => {
          if (window.$crisp) window.$crisp.do('chat:open');
          displayInfoToast("Send us a message, we'd love to hear from you! ->");
          // navigateToPage("/feedback");
        }}
      >
        Help
      </NavButton>
      <NavButton
        navSize={stateNavSize}
        leftIcon={stateLoggingOut ? <Icon as={FiLoader}></Icon> : <Icon as={FiLogOut} />}
        isLoading={stateLoggingOut}
        onClick={() => {
          setLoggingOut(true);
          logout({returnTo: window.location.origin});
          setLoggingOut(false);
        }}
      >
        Logout
      </NavButton>
      <NavButton
        navSize={stateNavSize}
        variant="customPrimary"
        leftIcon={<Icon as={BsLightningCharge} />}
        onClick={() => {
          navigateToPage('/pricing');
        }}
      >
        {!SUBSCRIPTION.hasActiveSub(zSubscription) ? 'Upgrade' : 'Subscription'}
      </NavButton>
    </Box>
  );
};

/** export options is an array of objects. { cta: "", onClick: () => {}, icon: <Icon as={}> } */
const ProjectSwitchOptions = ({projectOptions, stateNavSize, wrap = false, ...props}) => {
  const zProject = projectStore((state) => state.project);
  const {onOpen, onClose, isOpen} = useDisclosure();

  return (
    <Stack {...props}>
      <Popover placement="right-start" isOpen={isOpen} onOpen={onOpen} onClose={onClose} trigger="hover">
        <PopoverTrigger>
          <Box>
            <SelectedProjectOption stateNavSize={stateNavSize} />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Stack spacing={4}>
              {zProject && (
                <Stack spacing={1}>
                  {/* <BodySm>Current Project:</BodySm> */}
                  <BodySm>{zProject.name}</BodySm>
                </Stack>
              )}
              <Stack spacing={2}>
                {projectOptions.map((option, index) => (
                  <ButtonSecondary
                    key={index}
                    wrap={wrap}
                    leftIcon={option.icon}
                    onClick={() => {
                      option.onClick();
                      onClose();
                    }}
                  >
                    {option.cta}
                  </ButtonSecondary>
                ))}
              </Stack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Stack>
  );
};

export default Navbar;
