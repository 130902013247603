import {create} from 'zustand';

/**
 * @typedef {object} User
 * @property {string} userSub
 * @property {unknown} configSelectedProject
 *
 * @typedef {object} Subscription
 *
 * @typedef {object} Teamate
 * @property {string} sk
 *
 * @typedef {object} AuthStore
 * @property {import('@auth0/auth0-react').User} authUser
 * @property {(authUser: import('@auth0/auth0-react').User) => void} setAuthUser
 * @property {User} user
 * @property {(user: User) => void} setUser
 * @property {(attributes: object) => void} updateUser
 * @property {unknown} userConfigSelectedProject
 * @property {boolean} authenticating
 * @property {(authenticating: boolean) => void} setAuthenticating
 * @property {Subscription} subscription
 * @property {(subscription: Subscription) => void} setSubscription
 * @property {Teamate[]} team
 * @property {(team: Teamate[]) => void} setTeam
 * @property {(sk: Teamate['sk']) => void} teamRemoveUser
 */

/**
 * @param {(partial: Partial<AuthStore> | (state: AuthStore) => Partial<AuthStore>, replace?: boolean | undefined) => void} set
 * @param {() => AuthStore} get
 * @param {import('zustand').StoreApi<AuthStore>} api
 * @returns {AuthStore}
 */
const authStore = (set) => ({
  authUser: null, // auth0 user
  setAuthUser: (authUser) => set((state) => ({authUser})),

  user: null, // our user
  setUser: (user) =>
    set((state) => ({user, userConfigSelectedProject: user?.configSelectedProject, userSub: user?.userSub})),
  updateUser: (attributes) => set((state) => ({user: {...state.user, ...attributes}})),
  userConfigSelectedProject: null, // set by setUser
  setUserConfigSelectedProject: (configSelectedProject) =>
    set((state) => ({userConfigSelectedProject: configSelectedProject})),

  authenticating: true,
  setAuthenticating: (authenticating) => set((state) => ({authenticating})),

  subscription: null,
  setSubscription: (subscription) => set((state) => ({subscription})),

  team: null, // becomes an array of teammate objects
  setTeam: (team) => set((state) => ({team})),
  teamRemoveUser: (sk) =>
    set((state) => ({
      team: state.team.filter((teammate) => teammate.sk !== sk),
    })),
});

export default create(authStore);
