import {Button, Flex, Icon, Stack, Text, useToast} from '@chakra-ui/react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {FiPause, FiPlay} from 'react-icons/fi';
import {useSearchParams} from 'react-router-dom';

import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import ZTabs from '../../components/abstraction_high/ZTabs';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import {PromptInteractionModal} from '../../components/modals/Modal';
import {AudioPlayer} from '../../utils/editor/audio-player';
import {EpisodeEditor} from '../../utils/editor/episode-editor';
import DebugEditor from './DebugEditor';
import TextEditor from './TextEditor';

const Editor = () => {
  const refTimer = useRef();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const fileUDID = searchParams.get('fileUDID') ?? '6439997645094fb681f72696___1681668681709'; // todo stop defaulting to demo data
  const [audioState, setAudioState] = useState(AudioPlayer.STATE_UNLOADED); // unloaded, loaded, playing, paused
  const [statePromptInteraction, setPromptInteraction] = useState(false); // whether or not the user has interacted with browser - interaction required for audio context https://stackoverflow.com/questions/55026293/google-chrome-javascript-issue-in-getting-user-audio-the-audiocontext-was-not

  const [statePlaybackPercentage, setPlaybackPercentage] = useState(0);

  useEffect(() => {
    EpisodeEditor.i().init(fileUDID);
    initComponentCallbacks();
    setupKeyboardShortcuts();

    return () => {
      // force stopping audio when user exits screen
      pause();
      removeKeyboardShortcuts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUDID]);

  function setupKeyboardShortcuts() {
    document.addEventListener('keydown', keydownHandler);
  }

  function removeKeyboardShortcuts() {
    document.removeEventListener('keydown', keydownHandler);
  }

  function play() {
    startTimer();

    EpisodeEditor.i().stateManager.play();
    EpisodeEditor.i().setAudioState(AudioPlayer.STATE_PLAYING);
  }

  function pause() {
    stopTimer();

    EpisodeEditor.i().stateManager.pause();
    setAudioState(AudioPlayer.STATE_PAUSED);
  }

  // function seek(relPos, autoPlay) {
  //   if (autoPlay === true) startTimer();
  //   EE.i().stateManager.seekByRelPos(relPos, autoPlay);
  // }

  // control playback of audio clip
  function playOrPause() {
    console.log('audioState', audioState);
    if (audioState === AudioPlayer.STATE_PLAYING) {
      console.log('will pause');
      pause();
    } else {
      console.log('will play');
      play();
    }
  }

  function startTimer() {
    clearInterval(refTimer.current);
    refTimer.current = setInterval(() => {
      console.log(
        'relPos',
        EpisodeEditor.i().stateManager.relativePosition(),
        'ttlDur',
        EpisodeEditor.i().stateManager.totalRelativeDuration(),
      );
      setPlaybackPercentage(
        (EpisodeEditor.i().stateManager.relativePosition() / EpisodeEditor.i().stateManager.totalRelativeDuration()) *
          100,
      );
    }, 150);

    return () => {
      clearInterval(refTimer.current);
    };
  }

  function stopTimer() {
    clearInterval(refTimer.current);
  }

  function initComponentCallbacks() {
    EpisodeEditor.i().addComponentCallbacks({
      eOnFinish: () => {
        console.log('smOnFinish()');
        setPlaybackPercentage(100);
        stopTimer();
      },
      eOnEmptyState: () => {
        // when there are no state items left, or audio there is no audio remaining after manipulations
        console.log('smOnEmptyState');
        toast({
          title: 'Whoops. Looks like the audio is all gone.',
          status: 'info',
          duration: null,
          isClosable: true,
        });
      },
      eSetAudioState: setAudioState,
      eSetPromptInteraction: setPromptInteraction,
    });
  }

  const keydownHandler = useCallback((event) => {
    // Check if the user pressed cmd+z (Mac) or ctrl+z (Windows)
    if ((event.metaKey || event.ctrlKey) && event.code === 'KeyZ') {
      event.preventDefault(); // Prevent the browser's default undo behavior
      if (event.shiftKey) {
        // User pressed cmd+shift+z (Mac) or ctrl+shift+z (Windows) for redo
        EpisodeEditor.i().stateManager.redo();
      } else {
        // User pressed cmd+z (Mac) or ctrl+z (Windows) for undo
        EpisodeEditor.i().stateManager.undo();
      }
    }
  }, []);

  /**
   * Renders final audio file using pydub in lambda and returns.
   * The result is also stored via S3. The S3 url is returned to the client and saved to the database.
   */
  function renderFinalAudio() {
    EpisodeEditor.i().updateSnapshot('rendering');
  }

  return (
    <FlowContainer>
      <FlowHeader title={'Editor'} description={'Edit text to edit audio.'} />
      <FlowBody>
        <Stack justifyContent={'start'}>
          <ZTabs
            elements={[
              {
                title: 'Debug',
                child: <DebugEditor />,
              },
              {
                title: 'Text',
                child: <TextEditor />,
              },
              {
                title: 'Editor',
                child: <Text>Editor</Text>,
              },
            ]}
          />

          <Stack spacing="20px">
            <Flex spacing="20px">
              <Button
                borderRadius="8px"
                width="50px"
                height="50px"
                background="gray.300"
                onClick={() => {
                  console.log('clicked');
                  playOrPause();
                }}
              >
                {audioState == 'playing' ? <Icon as={FiPause} boxSize={6} /> : <Icon as={FiPlay} boxSize={6} />}
              </Button>
              <Button onClick={renderFinalAudio}>Render Final Audio</Button>
              <Button
                onClick={() => {
                  EpisodeEditor.i().updateSnapshot();
                }}
              >
                Update Snapshot
              </Button>
            </Flex>
          </Stack>
          <ZProgressBar value={statePlaybackPercentage} />
        </Stack>

        {/* initializing audio context */}
        <PromptInteractionModal
          openThisModal={statePromptInteraction}
          onCloseCallback={() => {
            setPromptInteraction(false);
            EpisodeEditor.i().initStateManagerPlayers();
          }}
        />
      </FlowBody>
      <FlowFooter />
    </FlowContainer>
  );
};

export default Editor;
