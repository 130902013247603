import {produce} from 'immer';
import {create} from 'zustand';

function getStateDefaults(state) {
  // put here the properties you want to retain
  return {
    projects: state.projects,
  };
}

/**
 * @typedef {object} Project
 * @property {string} projectId
 * @property {string} projectName
 * @property {string} userSub
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {unknown} configEnhance
 *
 * @typedef {object} ProjectStore
 * @property {Project[]} projects
 * @property {(projects: Project[]) => void} setProjects
 * @property {(projects: Project[], userSub: string) => void} setProjectsForUserSub
 * @property {(projects: Project[], userSub: string) => void} addProjectsForUserSub
 * @property {(project: Project, userSub: string) => void} addProject
 * @property {(project: Project, userSub: string) => void} removeProject
 *
 * @property {Project} project
 * @property {(project: Project) => void} setProject
 * @property {(attributes: object, alsoUpdateProjects: boolean) => void} updateProject
 * @property {(configEnhance: unknown) => void} setProjectConfigEnhance
 *
 * @property {() => void} nukeProjectStore
 */

/**
 * @param {(partial: Partial<ProjectStore> | (state: ProjectStore) => Partial<ProjectStore>, replace?: boolean | undefined) => void} set
 * @param {() => ProjectStore} get
 * @param {import('zustand').StoreApi<ProjectStore>} api
 * @returns {ProjectStore}
 */
const projectStore = (set) => ({
  projects: {}, // { [usersub]: projects } // projects is an array of projects
  setProjects: (projects) => set((state) => ({projects})),
  setProjectsForUserSub: (projects, userSub) =>
    set(
      produce((draft) => {
        draft.projects[userSub] = projects;
      }),
    ),
  addProjectsForUserSub: (projects, userSub) =>
    set(
      produce((draft) => {
        if (!draft.projects[userSub]) {
          draft.projects[userSub] = [];
        }
        draft.projects[userSub] = [...draft.projects[userSub], ...projects];
      }),
    ),
  addProject: (project, userSub) =>
    set(
      produce((draft) => {
        if (!draft.projects[userSub]) {
          draft.projects[userSub] = [];
        }
        draft.projects[userSub].push(project);
      }),
    ),
  removeProject: (project, userSub) =>
    set(
      produce((draft) => {
        if (draft.projects[userSub]) {
          draft.projects[userSub] = draft.projects[userSub].filter((p) => p.projectId !== project.projectId);
        }
      }),
    ),
  project: null,
  setProject: (project) => set((state) => ({project})),
  updateProject: (attributes, alsoUpdateProjects) => {
    set(
      produce((draft) => {
        draft.project = {...draft.project, ...attributes};

        if (alsoUpdateProjects) {
          Object.keys(draft.projects).forEach((userSub) => {
            draft.projects[userSub] = draft.projects[userSub].map((p) => {
              if (p.projectId === draft.project.projectId) {
                return {...p, ...attributes};
              }
              return p;
            });
          });
        }
      }),
    );
  },

  setProjectConfigEnhance: (configEnhance) => {
    set(
      produce((draft) => {
        draft.project.configEnhance = configEnhance;
      }),
    );
  },

  // sets every state value to null, expect those specified in getStateDefaults
  nukeProjectStore() {
    console.log('zstore nukeProjectStore');
    set((state) => {
      let newState = {};

      // Set all keys in state to null except functions
      Object.keys(state).forEach((key) => {
        if (typeof state[key] !== 'function') {
          newState[key] = null;
        }
      });

      // Merge initial state values
      newState = {...newState, ...getStateDefaults(state)};

      return newState;
    });
  },
});

export default create(projectStore);
