import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import {Icon, Stack, Text} from '@chakra-ui/react';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {BsArrowRepeat, BsQuestion} from 'react-icons/bs';
import {
  FiCheck,
  FiChevronDown,
  FiCopy,
  FiEdit,
  FiEdit3,
  FiFileText,
  FiPlus,
  FiRepeat,
  FiSave,
  FiSend,
  FiTrash,
} from 'react-icons/fi';
import {GoLightBulb} from 'react-icons/go';
import {MdChevronLeft, MdChevronRight, MdDragIndicator, MdOutlineHistory} from 'react-icons/md';
import {useParams} from 'react-router-dom';

import {AutoResizeTextarea} from '../../components/abstraction_high/AutoResizeTextArea';
import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import {QuillEditor, htmlToPlainText} from '../../components/abstraction_high/QuillEditor';
import ZCard from '../../components/abstraction_high/ZCard';
import ZCopyButton from '../../components/abstraction_high/ZCopyButton';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {ZIconButton} from '../../components/common/ComponentStyle';
import {displayInfoToast} from '../../components/common/Structural';
import {BodyMdSemiBold, BodySm, BodySmMuted, BodySmSemiBold, BodyXsMuted} from '../../components/common/TextStyle';
import {ConfirmModal, InputModal, PromptSelectionModal, ZModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import {copyToClipboard} from '../../utils';
import {GPT, TEMPLATE_V2} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';

const EpSectionContainer = ({children, ...props}) => {
  return <Box {...props}>{children}</Box>;
};

const SectionContext = createContext({});

const EpSection = ({sectionTemplate, pollEpisodeCallback, pollGptRequestCallback, setModalTemplateCreateProps}) => {
  const sectionId = sectionTemplate.sk;

  const {projectId, episodeId} = useParams();

  const {lambdaApi, gptApi, epExportApi, episodeApi, promptModApi} = useEpisodeDependentAPIs();

  const zGptReq = episodeStore((state) => state.gptReq);
  const zTemplates = episodeStore((state) => state.templates);
  const zAddTemplateBlock = episodeStore((state) => state.addTemplateBlock);
  const zDeleteTemplateBlock = episodeStore((state) => state.deleteTemplateBlock);
  const zReplaceTemplateBlock = episodeStore((state) => state.replaceTemplateBlock);
  const zUpdateTemplateBlockIndices = episodeStore((state) => state.updateTemplateBlockIndices);
  const zSetRetryingFailedRequests = episodeStore((state) => state.setRetryingFailedRequests);
  const zScrollToBlockPk = episodeStore((state) => state.scrollToBlockPk);
  const zSetScrollToBlockPk = episodeStore((state) => state.setScrollToBlockPk);
  const zSetError = appStore((state) => state.setError);

  const [stateBlockToTransformToPrompt, setBlockToTransformToPrompt] = useState(null);
  const [stateDisplayConfirmDeleteModalProps, setDisplayConfirmDeleteModalProps] = useState(null);
  const [stateTriggerModalModHistory, setTriggerModalModHistory] = useState();

  const allowRetryRequests = useMemo(() => {
    const failedReqKeys = GPT.getFailedRequests(zGptReq);
    if (failedReqKeys.length > 0) return true;
    return false;
  }, [zGptReq]);

  useEffect(() => {
    if (!zScrollToBlockPk) return;
    console.log('trying to scroll to pk...', zScrollToBlockPk);
    const element = document.getElementById(`item-${zScrollToBlockPk}`);
    console.log('element', element);
    if (element) {
      console.log('scrolling to element...', element.id);
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
    zSetScrollToBlockPk(null);
  }, [zScrollToBlockPk]);

  function findBlockByPk(pk) {
    return zTemplates[sectionId].blocks[pk];
  }

  function handleDragEnd(result) {
    console.log('handleDragEnd');

    // Do nothing if item is dropped outside the list
    if (!result.destination) return;

    const {source, destination} = result;

    // Do nothing if the item is dropped into the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    const elements = zTemplates[sectionId].blocks;
    const elementKeys = Object.keys(elements);

    // Validate and fix the indexing before proceeding
    function validateAndFixIndices(elements) {
      const indices = elementKeys.map((key) => elements[key].index);
      const uniqueIndices = [...new Set(indices)];

      // Check for duplicates or missing indices and fix them
      if (uniqueIndices.length !== elementKeys.length || Math.max(...indices) >= elementKeys.length) {
        console.log('Invalid indices found. Fixing indices...');

        // Sort element keys by their current indices to maintain order
        elementKeys.sort((a, b) => elements[a].index - elements[b].index);

        // Reindex all elements sequentially
        elementKeys.forEach((key, index) => {
          elements[key].index = index;
        });
      }
    }

    validateAndFixIndices(elements);

    // Create a copy of element keys array and sort them by index
    const orderedKeys = elementKeys.slice().sort((a, b) => elements[a].index - elements[b].index);

    // Move the element to its new position
    const [removed] = orderedKeys.splice(source.index, 1);
    orderedKeys.splice(destination.index, 0, removed);

    // Now, orderedKeys has the new order of elements
    zUpdateTemplateBlockIndices(sectionId, orderedKeys);
  }

  function onAddBlock(index) {
    console.log('onAddBlock');
    index = index + 1; // add block after the clicked element

    const newBlock = TEMPLATE_V2.createUndefinedBlock(index);
    zAddTemplateBlock(sectionId, newBlock, index);
  }

  function onDeleteBlock(index, elementId) {
    console.log('onDeleteBlock');

    if (Object.keys(zTemplates[sectionId].blocks).length === 1 && sectionId !== 'sid_prompt_studio') {
      displayInfoToast("Sorry, you can't delete the last block in the template.");
      return;
    }

    const block = zTemplates[sectionId].blocks[elementId];

    // check if the block is from a curated template that can't be edited
    // originalTemplateSk is only set for blocks that were created by moving a prompt from one template to another
    if (
      zTemplates[sectionId].curatedId &&
      block.type === 'prompt' &&
      !block.userPrompt &&
      (block.originalTemplateSk ?? sectionId) === sectionId
    ) {
      displayInfoToast("Sorry, you can't permanently delete the prompts in curated templates like this one.", {
        duration: 15000,
      });
      displayInfoToast(
        `However, you can create a new custom template that looks just like this one! Visit the "Create a New Template" tab at the top of the screen, then click the "Reuse an Existing Template".`,
        {
          duration: 30000,
        },
      );
      return;
    }

    const zGptReqDataMap = episodeStore.getState().gptReqDataMap;
    const blockContent = htmlToPlainText(GPT.getBlockContent(block, zGptReqDataMap));
    if (!block.impermanent && blockContent.trim()) {
      // if the block is permanent and has content, prompt the user to confirm deletion
      setDisplayConfirmDeleteModalProps({elementId});
      return;
    }

    zDeleteTemplateBlock(sectionId, elementId);
  }

  function onPromptSelectedForTransformation(blockToTransform, transformIntoThisBlock, originalTemplateSk) {
    console.log('blockToTransform', blockToTransform, 'transformIntoThisBlock', transformIntoThisBlock);
    zReplaceTemplateBlock(blockToTransform.sectionId, blockToTransform.pk, {
      ...transformIntoThisBlock,
      index: blockToTransform.index,
      originalTemplateSk: blockToTransform.originalTemplateSk ?? originalTemplateSk,
    });
  }

  async function retryFailedRequests(gptReqItem) {
    console.log('retryFailedRequests');
    zSetRetryingFailedRequests(true);

    // search for failed requests
    let isSuccess = await gptApi.resetFailedRequests(gptReqItem);
    if (isSuccess === null) {
      console.log('Skipping retry. No failed requests to reset.');
      return;
    } else if (!isSuccess) {
      zSetError('retryFailedRequests', 'Failed to reset failed requests');
    }

    // update episode stage status
    isSuccess = await episodeApi.updateEpisode({stageGPT: 'retrying'});
    if (!isSuccess) {
      zSetError('retryFailedRequests', 'Failed to reset failed requests. episode');
      return;
    }

    // retry gpt_request lambda
    isSuccess = await lambdaApi.invokeDataPipeline(projectId, episodeId);
    if (!isSuccess) {
      zSetError('retryFailedRequests', 'Failed to retry failed requests');
      return;
    }

    // restart / start episode and gpt-request polling
    pollEpisodeCallback();
    pollGptRequestCallback();
  }

  function isPromptStudioSection() {
    return sectionId === 'sid_prompt_studio';
  }

  if (!zTemplates[sectionId]?.blocks) return <Stack></Stack>;

  return (
    <Stack spacing={0}>
      <SectionContext.Provider value={{allowRetryRequests, retryFailedRequests}}>
        <Stack spacing={2}>
          <Flex gap={4}>
            <EpSectionSidebar
              sectionTemplate={sectionTemplate}
              sectionId={sectionId}
              onDeleteBlock={onDeleteBlock}
              onAddBlock={onAddBlock}
              setModalTemplateCreateProps={setModalTemplateCreateProps}
            />

            <Stack flex={7} minHeight="70vh" maxHeight="70vh" overflow={'scroll'} spacing={0}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {Object.keys(zTemplates[sectionId].blocks)
                        .sort((a, b) => zTemplates[sectionId].blocks[a].index - zTemplates[sectionId].blocks[b].index)
                        .map((pk, index) => (
                          <Draggable key={pk} draggableId={pk} index={zTemplates[sectionId].blocks[pk].index}>
                            {(provided) => (
                              <Stack spacing={0} pb={4} px={0} ref={provided.innerRef} {...provided.draggableProps}>
                                <Flex direction={'row'} gap={2} justifyContent={'space-between'} id={`item-${pk}`}>
                                  <EpElement
                                    sectionId={sectionId}
                                    elementId={pk}
                                    index={index}
                                    elementType={findBlockByPk(pk).type}
                                    onDeleteBlock={onDeleteBlock}
                                    onAddBlock={onAddBlock}
                                    setBlockToTransformToPrompt={setBlockToTransformToPrompt}
                                    draggableProps={provided}
                                    setTriggerModalModHistory={setTriggerModalModHistory}
                                    promptModApi={promptModApi}
                                  />
                                </Flex>
                              </Stack>
                            )}
                          </Draggable>
                        ))}

                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>

              {isPromptStudioSection() && <PromptStudioSection sectionId={sectionId} />}
            </Stack>
          </Flex>
        </Stack>
      </SectionContext.Provider>
      <PromptSelectionModal
        blockToTransform={stateBlockToTransformToPrompt}
        onPromptSelectedForTransformation={onPromptSelectedForTransformation}
        templates={zTemplates}
      />
      <ConfirmModal
        titleText={'Are you sure?'}
        bodyText={'This block has content inside of it that could be gone forever. Are you sure you want to delete it?'}
        positiveText="Continue"
        positiveCallback={() => zDeleteTemplateBlock(sectionId, stateDisplayConfirmDeleteModalProps?.elementId)}
        openThisModal={stateDisplayConfirmDeleteModalProps}
        closeModal={() => setDisplayConfirmDeleteModalProps(null)}
      />
      <ZModal trigger={stateTriggerModalModHistory} />
    </Stack>
  );
};

const PromptStudioSection = ({sectionId}) => {
  const zTemplates = episodeStore((state) => state.templates);
  const zAddTemplateBlock = episodeStore((state) => state.addTemplateBlock);

  const [stateInputValue, setInputValue] = useState('');
  const [stateShowSuggestions, setShowSuggestions] = useState(Object.keys(zTemplates[sectionId].blocks).length === 0);
  const [stateRecentlySelectedSuggestion, setRecentlySelectedSuggestion] = useState(null);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  const memoUserDefinedPromptSuggestions = useMemo(() => {
    return [
      {
        display: 'Instagram Story Polls',
        value:
          "Propose interactive poll questions related to the episode's content for Instagram Stories, designed to engage the audience and gather their opinions.\n\nFor Example:\n```\n1. Which cryptocurrencies do you prefer to stake on platforms like Crypto.com?\na) Bitcoin\nb) Ethereum\n```",
      },
      {
        display: 'Promotion Action Plan',
        value:
          "Create a checklist of 5 straightforward tasks to enhance this episode's reach and engage more listeners. Each item should be actionable and aimed at immediate impact.",
      },
      {
        display: 'Follow-Up Content Ideas',
        value:
          'Based on the themes and discussions in this episode, suggest 5 ideas for follow-up content or episodes that would interest and benefit my audience.',
      },
      {
        display: 'Quote Cards',
        value:
          "Extract three impactful exact quotes from this episode and format them for use in social media graphics. Include the speaker's name and a relevant hashtag.",
      },
      // {
      //   display: "Promotion Ideas",
      //   value:
      //     "Identify 5 easy-to-implement tasks for boosting this episode's visibility and listener engagement. Focus on simple yet effective tactics such as a single impactful social media post, a straightforward content repurposing idea, a basic community interaction initiative, a direct but achievable partnership opportunity, and one creative method that’s closely related to the episode's content.",
      // },
      {
        display: '3 Interesting Learnings',
        value:
          'Identify 3 unique insights or learnings from this episode and present them in a compelling way to share with my audience. Format it with a leading dash for emphasis.',
      },
      // {
      //   display: "Email Newsletter Content",
      //   value: "Draft content for an email newsletter that teases the episode's content, includes a call-to-action encouraging listeners to tune in, and links to additional resources mentioned in the episode.",
      // },
      {
        display: 'Discussion Questions',
        value:
          "Generate a list of 5 thought-provoking questions based on the episode's content that I can use to engage with my audience on social media or podcast platforms.",
      },
      // {
      //   display: "Key Takeaways for LinkedIn Post",
      //   value: "Create a LinkedIn post summarizing the top professional insights or career advice shared in this episode, formatted for business-minded audiences.",
      // },
      {
        display: 'Twitter (X) Thread',
        value:
          'Create a Twitter thread outline summarizing the key points, quotes, and takeaways from this episode. Each point should be tweet-sized and ready to post.',
      },
      {
        display: 'YouTube Tags',
        value:
          'Generate a list of relevant YouTube tags based on the main topics, guest expertise, and key moments from this episode. Tags should be concise and targeted for SEO. Your response should be a comma separated list of tags.',
      },
    ];
  }, []);

  function submitPrompt() {
    // user defined prompt
    const userPrompt = sanitizeInput(stateInputValue) ?? '';
    console.log('userPrompt', userPrompt);

    // ensure input isn't empty and is > 500 words
    if (!userPrompt) return;
    const wordCount = userPrompt.split(' ').length;
    if (wordCount > 500) {
      displayInfoToast('Please limit your prompt to 500 words or less.', {duration: 3000});
      return;
    }

    // clear input
    setInputValue('');

    // check if input is == recently selected suggestion
    let displayName = null;
    if (stateRecentlySelectedSuggestion?.value === userPrompt) displayName = stateRecentlySelectedSuggestion.display;

    // add a user defined prompt block to the current section
    const index = Object.keys(zTemplates[sectionId].blocks).length; // add block after the clicked element
    const userPromptBlock = TEMPLATE_V2.createUserPromptBlock(index, userPrompt, displayName);
    zAddTemplateBlock(sectionId, userPromptBlock, index, true);
  }

  function sanitizeInput(input) {
    return input.trim();
  }

  return (
    <Stack spacing={2}>
      {stateShowSuggestions && (
        <Flex flexWrap={'wrap'} gap={2} width={'100%'}>
          {memoUserDefinedPromptSuggestions.map((suggestion) => (
            <ButtonSecondary
              key={suggestion.display}
              wrap={true}
              onClick={() => {
                setRecentlySelectedSuggestion(suggestion);
                setInputValue(suggestion.value ?? '');
              }}
            >
              {suggestion.display}
            </ButtonSecondary>
          ))}
        </Flex>
      )}
      <Flex gap={2} mt={0}>
        <AutoResizeTextarea
          placeholder={'Ask me anything about this episode...'}
          maxLength={1000}
          value={stateInputValue ? stateInputValue : ''}
          onChange={handleInputChange}
          isInvalid={false}
          alignSelf="stretch"
          minH="84px"
          // minH="unset"
          resize="none"
          borderColor={'gray.200'}
          maxHeight={40}
          onKeyDown={(e) => {
            // Check if Enter key is pressed without Shift key
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault(); // Prevent default Enter key behavior (new line)
              if (!sanitizeInput(stateInputValue)) return;
              submitPrompt();
            }
          }}
        />
        <Stack justifyContent={'end'} spacing={2}>
          <ZIconButton
            tooltipText={'Want some ideas??'}
            icon={<Icon as={GoLightBulb} />}
            onClick={() => setShowSuggestions(!stateShowSuggestions)}
          />
          <ZIconButton
            isDisabled={!sanitizeInput(stateInputValue)}
            isPrimary={true}
            icon={<Icon as={FiSend} />}
            tooltipText={!sanitizeInput(stateInputValue) ? '<- Type Here...' : 'Generate Content!'}
            onClick={submitPrompt}
          />
        </Stack>
      </Flex>
    </Stack>
  );
};

/** export options is an array of objects. { cta: "", onClick: () => {}, icon: <Icon as={}> } */
const ExportOptionsButton = ({
  exportOptions,
  triggerIsPrimaryButton,
  buttonCta = 'Export',
  wrap = false,
  width,
  centerText,
  isDisabled = false,
  tooltipLabel,
  trigger = 'hover',
  ...props
}) => {
  const {onOpen, onClose, isOpen} = useDisclosure();

  return (
    <Stack width={width} {...props}>
      <Popover trigger={trigger} placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Stack width={width}>
            {triggerIsPrimaryButton ? (
              <ButtonPrimary
                rightIcon={<Icon as={FiChevronDown} />}
                isDisabled={isDisabled}
                tooltipLabel={tooltipLabel}
                wrap={wrap}
                width={width}
                centerText={centerText}
              >
                {buttonCta}
              </ButtonPrimary>
            ) : (
              <ButtonSecondary
                rightIcon={<Icon as={FiChevronDown} />}
                isDisabled={isDisabled}
                tooltipLabel={tooltipLabel}
                wrap={wrap}
                width={width}
                centerText={centerText}
              >
                {buttonCta}
              </ButtonSecondary>
            )}
          </Stack>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Stack spacing={2} alignItems={'end'}>
              {exportOptions.map((option, index) => (
                <ButtonSecondary
                  key={index}
                  leftIcon={option.icon}
                  onClick={() => {
                    option.onClick();
                    onClose();
                  }}
                >
                  {option.cta}
                </ButtonSecondary>
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Stack>
  );
};

const EpSectionSidebarFragment = ({block, setChangeBlockDisplayNameProps, changeBlockDisplayNameCallback}) => {
  if (!block) return null;

  const BlockBody = () => {
    if (block.type === 'prompt') {
      return (
        <>
          <BodySmSemiBold>{block.displayName ?? block.name ?? 'Block'}</BodySmSemiBold>
          {block.description && <BodySmMuted whiteSpace="pre-line">{block.description}</BodySmMuted>}
        </>
      );
    } else if (block.type === 'input') {
      return (
        <>
          <BodySmSemiBold>{'Input Block'}</BodySmSemiBold>
          {block.description && <BodySmMuted whiteSpace="pre-line">{block.description}</BodySmMuted>}
        </>
      );
    } else if (block.type === 'block') {
      return (
        <Flex
          alignItems={'center'}
          gap={1}
          cursor={'pointer'}
          onClick={() => {
            setChangeBlockDisplayNameProps({
              title: `Rename this text block? It's currently named: ${block.displayName ?? block.name ?? 'Text Block'}.`,
              openThisModal: true,
              onSubmit: changeBlockDisplayNameCallback,
              onCloseCallback: () => setChangeBlockDisplayNameProps({openThisModal: false}),
            });
          }}
        >
          <BodySmSemiBold>{block.displayName ?? block.name ?? 'Text Block'}</BodySmSemiBold>{' '}
          <Icon w={'.81rem'} h={'.81rem'} as={FiEdit3} />
        </Flex>
      );
    } else if (block.type === 'undefined') {
      return <BodySmSemiBold>{'Select Block Type...'}</BodySmSemiBold>;
    }
  };

  return (
    <Stack pt={2}>
      <ZCard variant="outline" borderColor={'playful.blue'} maxHeight={'18vh'} overflowY={'scroll'} pt={2} pb={2}>
        <BlockBody />
      </ZCard>
    </Stack>
  );
};

const EpSectionSidebar = ({sectionTemplate, sectionId, onDeleteBlock, onAddBlock, setModalTemplateCreateProps}) => {
  const zEpisode = episodeStore((state) => state.episode);
  const zSetError = appStore((state) => state.setError);
  const zTemplateBlockSelected = episodeStore((state) => state.templateBlockSelected);
  const zTemplateMeta = episodeStore((state) => state.templateMeta);
  const zTemplates = episodeStore((state) => state.templates);
  const zUpdateTemplateBlock = episodeStore((state) => state.updateTemplateBlock);
  const zDeleteTemplate = episodeStore((state) => state.deleteTemplate);
  const zGptReqDataMap = episodeStore((state) => state.gptReqDataMap);

  const [stateDisplayConfirmModalProps, setDisplayConfirmModalProps] = useState(null);
  const [stateChangeBlockDisplayNameProps, setChangeBlockDisplayNameProps] = useState({openThisModal: false});

  const memoBlock = useMemo(() => {
    if (!zTemplateBlockSelected) return null;
    const {sectionId, elementId} = zTemplateBlockSelected;
    return zTemplates[sectionId].blocks[elementId];
  }, [zTemplateBlockSelected, zTemplates]);

  const deleteTemplate = () => zDeleteTemplate(sectionId);

  function changeBlockDisplayName(newDisplayName) {
    if (!memoBlock) {
      return;
    }

    // validate newDisplayName
    if (!newDisplayName) return;
    if (newDisplayName === memoBlock.displayName) return;
    if (newDisplayName.length > 100) {
      displayInfoToast('Name must be less than 100 characters.');
      return;
    } else if (newDisplayName.length < 1) {
      displayInfoToast('Name must be at least 1 character.');
      return;
    }

    zUpdateTemplateBlock(sectionId, memoBlock.pk, {displayName: newDisplayName});
  }

  return (
    <Flex direction={'column'} gap={2} flex={3}>
      <Stack>
        <Tooltip
          isDisabled={!sectionTemplate.description}
          label={sectionTemplate.description}
          placement="right-start"
          borderRadius={'lg'}
        >
          <Stack>
            <ZCard variant="outline" borderColor={'playful.blue'} maxHeight={'18vh'} overflowY={'scroll'} pt={2} pb={2}>
              {/* <Stack spacing={0} width={'100%'} height={'100%'}> */}
              <BodySmSemiBold>{sectionTemplate.name}</BodySmSemiBold>
              {/* </Stack> */}
            </ZCard>
          </Stack>
        </Tooltip>
      </Stack>

      <Flex gap={2} width={'100%'}>
        <ExportOptionsButton
          buttonCta="Export Section"
          triggerIsPrimaryButton={false}
          wrap={false}
          centerText={true}
          width={'100%'}
          exportOptions={[
            {
              cta: 'Export to document (.docx)',
              icon: <Icon as={FiFileText} />,
              onClick: () => {
                try {
                  GPT.generateAndDownloadSectionDocx(zTemplates, sectionId, zEpisode.fileName, zGptReqDataMap);
                } catch (e) {
                  zSetError('generateAndDownloadDocx', e.message);
                }
              },
            },
          ]}
        />

        <ZCopyButton
          cta={'Copy Section'}
          tooltipLabel={'Copy all the content in this section to your clipboard...'}
          tooltipLabelPlacement="left-end"
          wrap={false}
          centerText={true}
          width={'100%'}
          getCopyContentCallback={() => GPT.getSectionContent(zTemplates, sectionId, zGptReqDataMap)}
        />
      </Flex>

      <Flex gap={2}>
        {!sectionTemplate.curatedId && (
          <ZIconButton
            tooltipText={'Delete This Template'}
            icon={<Icon as={FiTrash} />}
            onClick={() => setDisplayConfirmModalProps({})}
          />
        )}
        {!sectionTemplate.curatedId && (
          <ZIconButton
            tooltipText={'Edit Template Info'}
            icon={<Icon as={FiEdit} />}
            onClick={() => setModalTemplateCreateProps({zTemplateMeta, zTemplates, editTemplateSk: sectionId})}
          />
        )}
        {(sectionTemplate.isReusable ?? true) && (
          <ButtonSecondary
            tooltipLabel={'Create a new template using this one as a starting point...'}
            wrap={false}
            width={'100%'}
            centerText={true}
            leftIcon={<Icon as={FiRepeat} />}
            onClick={() => setModalTemplateCreateProps({zTemplateMeta, zTemplates, duplicateTemplateSk: sectionId})}
          >
            Reuse This Template
          </ButtonSecondary>
        )}
      </Flex>

      {zTemplateBlockSelected && (
        <EpSectionSidebarFragment
          block={memoBlock}
          setChangeBlockDisplayNameProps={setChangeBlockDisplayNameProps}
          changeBlockDisplayNameCallback={changeBlockDisplayName}
        />
      )}

      {zTemplateBlockSelected && memoBlock && (
        <Stack spacing={2}>
          {memoBlock.impermanent && zGptReqDataMap[memoBlock.pk] && (
            <ButtonSecondary
              wrap={false}
              width={'100%'}
              centerText={true}
              leftIcon={<Icon as={FiSave} />}
              onClick={() => zUpdateTemplateBlock(sectionId, memoBlock.pk, {impermanent: false})}
              tooltipLabel={
                "Would you like to save this custom prompt so that it's automaticaly regenerated in future episodes?"
              }
            >
              Save Prompt
            </ButtonSecondary>
          )}
          <Flex gap={2} width={'100%'}>
            <ZIconButton
              variant="outline"
              onClick={() => onDeleteBlock(zTemplateBlockSelected.index, zTemplateBlockSelected.elementId)}
              colorScheme="white"
              aria-label="Delete Block"
              icon={<Icon as={FiTrash} />}
              tooltipText={'Delete Selected Block'}
            />
            <ButtonSecondary
              wrap={false}
              width={'100%'}
              centerText={true}
              leftIcon={<Icon as={FiPlus} />}
              tooltipLabel={'Add a new block beneath the selected block...'}
              tooltipLabelPlacement={'top-start'}
              onClick={() => onAddBlock(memoBlock.index)}
            >
              Add Block Below
            </ButtonSecondary>
          </Flex>
          {zTemplateBlockSelected.hasContent && (
            <ZCopyButton
              wrap={false}
              width={'100%'}
              centerText={true}
              cta={'Copy Block'}
              tooltipLabel={'Copy the content of the selected block to your clipboard...'}
              getCopyContentCallback={() => {
                const zGptReqDataMap = episodeStore.getState().gptReqDataMap;
                return htmlToPlainText(GPT.getBlockContent(memoBlock, zGptReqDataMap));
              }}
            />
          )}
        </Stack>
      )}

      <ConfirmModal
        positiveText="Continue"
        positiveCallback={async () => deleteTemplate()}
        titleText={'Are you sure?'}
        bodyText={'This action cannot be undone. Are you sure you want to delete this template?'}
        openThisModal={stateDisplayConfirmModalProps}
        closeModal={() => setDisplayConfirmModalProps(null)}
      />
      <InputModal {...stateChangeBlockDisplayNameProps} />
    </Flex>
  );
};

const EpElementContainer = ({
  children,
  draggableProps,
  sectionId,
  elementId,
  index,
  onAddBlock,
  width = '100%',
  setTriggerModalModHistory,
  promptModApi,
  ...props
}) => {
  const zTemplates = episodeStore((state) => state.templates);
  const zTemplateBlockSelected = episodeStore((state) => state.templateBlockSelected);
  const zUpdateTemplateBlock = episodeStore((state) => state.updateTemplateBlock);
  const zGptReqDataMap = episodeStore((state) => state.gptReqDataMap);
  const zAddPromptMod = episodeStore((state) => state.addPromptMod);

  const memoBlock = useMemo(() => {
    return zTemplates[sectionId].blocks[elementId];
  }, [zTemplateBlockSelected, zTemplates[sectionId].blocks[elementId], zGptReqDataMap]);

  const memoSelectedModBlock = useMemo(() => {
    return GPT.getSelectedModBlock(memoBlock);
  }, [memoBlock]);

  const memoShowModificationPopover = useMemo(() => {
    return <ShowModification />;
  }, [memoSelectedModBlock]);

  const regeneratePrompt = useCallback(
    (userMod, userModDisplayName = null) => {
      console.log('userMod', userMod);

      // ensure input isn't empty and is > 200 words
      if (!userMod) return;
      const wordCount = userMod.split(' ').length;
      if (wordCount > 200) {
        displayInfoToast('Please limit your modification request to 200 words or less.', {duration: 3000});
        return;
      }

      // find blocks with matching pk among all templates // find the max modChain.chain length // create a new modPk with the max modChain length // this makes the modPk unique
      const buildModPk = () => {
        let maxChainLength = 0;

        Object.values(zTemplates).forEach((section) => {
          Object.values(section.blocks).forEach((block) => {
            if (block.pk === memoBlock.pk) {
              const currentLength = block.modChain ? block.modChain.chain.length : 0;
              maxChainLength += currentLength;
            }
          });
        });

        return 'mod_' + maxChainLength;
      };

      const zGptReqDataMap = episodeStore.getState().gptReqDataMap;
      let preModOutput = GPT.getBlockContent(memoBlock, zGptReqDataMap);
      preModOutput = htmlToPlainText(preModOutput);
      if (GPT.aproxNumTokens(preModOutput) === 0) {
        displayInfoToast("Sorry, you can't modify this block because it's empty. Please try another block.", {
          duration: 3000,
        });
        return;
      }
      if (GPT.aproxNumTokens(preModOutput) > 3000) {
        displayInfoToast("Sorry, you can't modify this block because it's too long. Please try another block.", {
          duration: 3000,
        });
        return;
      }

      const currentModChain = memoBlock.modChain;
      const newMod = {
        pk: buildModPk(), // modPk is 0 indexed
        mod: userMod,
        modDisplay: userModDisplayName,
        preModOutput: preModOutput,
      };
      const updatedModChain = {
        selectedIdx: (currentModChain?.chain?.length ?? 0) + 1, // 0 references the original block
        chain: [...(currentModChain?.chain ?? []), newMod],
      };

      zUpdateTemplateBlock(sectionId, memoBlock.pk, {modChain: updatedModChain}, true);
      addModToPromptHistory(newMod);
    },
    [memoBlock, zTemplates, zGptReqDataMap],
  );

  async function addModToPromptHistory(newMod) {
    const {mod} = newMod;
    if (!mod) return;

    const zPromptModHistory = episodeStore.getState().promptModHistory;
    if (zPromptModHistory.length > 0 && zPromptModHistory[0].mod === mod) {
      console.log('Skipping duplicate mod');
      return;
    }

    const modItem = await promptModApi.postPromptMod({mod: mod, promptPk: memoBlock.pk, templateSk: sectionId});
    if (!modItem) {
      promptModApi.postError('addModToPromptHistory', 'failed to save prompt mod', {
        mod,
        promptPk: memoBlock.pk,
        templateSk: sectionId,
      });
      return;
    }
    zAddPromptMod(modItem);
  }

  const memoModPopover = useMemo(() => {
    return <RegenerateButton setTriggerModalModHistory={setTriggerModalModHistory} />;
  }, [regeneratePrompt]);

  function sanitizeInput(input) {
    return input.trim();
  }

  const ElementLabel = () => {
    const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);

    if (!memoBlock) return null;

    function isElementFocused() {
      if (!zTemplateBlockSelected) return false;
      return zTemplateBlockSelected.sectionId === sectionId && zTemplateBlockSelected.elementId === elementId;
    }

    function getLabelTitle(block) {
      if (block.type === 'prompt') {
        return block.displayName ?? block.name ?? 'Block';
      } else if (block.type === 'input' || block.type === 'block') {
        return block.displayName ?? block.name ?? 'Text Block'; // displayName & name are only available in curated templates
      } else if (block.type === 'undefined') {
        return 'Select block type...';
      } else {
        console.log('Invalid block type', block.type);
        throw new Error('Invalid block type');
      }
    }

    return (
      <Wrap m={0}>
        <WrapItem m={0}>
          <Tooltip label={'Select'} placement="top-start" borderRadius={'5px'}>
            <Box
              border={'1px'}
              borderTopRadius={'5px'}
              borderBottomEndRadius={'5px'}
              borderColor={isElementFocused() ? 'playful.blue' : 'gray.300'}
              px={3}
              onClick={() => zSetTemplateBlockSelected(sectionId, elementId, index)}
              cursor="pointer"
              _hover={{bg: 'gray.100'}}
            >
              <BodySmMuted color={isElementFocused() ? 'playful.blue' : 'black'}>
                {getLabelTitle(memoBlock)}
              </BodySmMuted>
            </Box>
          </Tooltip>
        </WrapItem>
      </Wrap>
    );
  };

  const SaveImpermanentBlockButton = () => {
    if (!memoBlock) return null;

    return (
      <Tooltip
        label={"If you like this prompt, save it so that it's automaticaly regenerated for future episodes."}
        placement="top-start"
        borderRadius={'10px'}
      >
        <Box
          border={'1px'}
          borderRadius={'5px'}
          borderColor={'gray.300'}
          px={3}
          _hover={{bg: 'gray.100'}}
          onClick={() => zUpdateTemplateBlock(sectionId, elementId, {impermanent: false})}
          cursor={'pointer'}
        >
          <BodySm>Save</BodySm>
        </Box>
      </Tooltip>
    );
  };

  function ModChainNavigation() {
    function changeMod(moveLeft) {
      const currentModChain = memoBlock.modChain;
      if (!currentModChain) return;

      const newSelectedIdx = moveLeft ? currentModChain.selectedIdx - 1 : currentModChain.selectedIdx + 1;
      if (newSelectedIdx < 0 || newSelectedIdx > currentModChain.chain.length) return;

      console.log('ModChainNavigation newSelectedIdx', newSelectedIdx);
      zUpdateTemplateBlock(sectionId, elementId, {modChain: {...currentModChain, selectedIdx: newSelectedIdx}});
    }

    return (
      <Flex gap={2} alignItems={'center'}>
        <Tooltip label={'Previous'} placement="top-start" borderRadius={'5px'}>
          <Box
            border={'1px'}
            borderRadius={'5px'}
            borderColor={'gray.300'}
            p={'.15rem'}
            onClick={() => changeMod(true)}
            _hover={{bg: 'gray.100'}}
            cursor="pointer"
          >
            <Flex alignItems={'center'} justifyContent={'center'}>
              <Icon as={MdChevronLeft} w={'1rem'} h={'1rem'} />
            </Flex>
          </Box>
        </Tooltip>

        <BodyXsMuted>
          {memoBlock.modChain.selectedIdx + 1} / {memoBlock.modChain.chain.length + 1}
        </BodyXsMuted>

        <Tooltip label={'Next'} placement="top-start" borderRadius={'5px'}>
          <Box
            border={'1px'}
            borderRadius={'5px'}
            borderColor={'gray.300'}
            p={'.15rem'}
            onClick={() => changeMod(false)}
            _hover={{bg: 'gray.100'}}
            cursor="pointer"
          >
            <Flex alignItems={'center'} justifyContent={'center'}>
              <Icon as={MdChevronRight} w={'1rem'} h={'1rem'} />
            </Flex>
          </Box>
        </Tooltip>
      </Flex>
    );
  }

  function RegenerateButton({setTriggerModalModHistory}) {
    const zPromptModHistory = episodeStore((state) => state.promptModHistory);
    const {onOpen, onClose, isOpen} = useDisclosure();

    const [stateModInput, setModInput] = useState('');
    function handleInputChange(event) {
      setModInput(event.target.value);
    }

    // const promptsWithoutRegenerateOriginal = [
    //   "pid_default_topic_filtering",
    //   "pid_default_topic_summary",
    //   "pid_default_topic_highlight",
    //   "pid_default_quotes"

    //   memoBlock.modChain.chain.filter((mod) => mod.mod !== "regenerate_original");
    // ]

    const memoModSuggestions = useMemo(() => {
      const modSuggestions = [
        {
          display: 'Make this shorter',
          value: 'Make this slightly shorter.',
        },
        {
          display: 'Make this longer',
          value: 'Make this slightly longer.',
        },
        {
          display: 'Make this more casual',
          value: 'Modify your response so that it has a more professional casual tone.',
        },
        {
          display: 'Make this more professional',
          value: 'Modify your response so that it has a more profressional tone.',
        },
        {
          display: 'Regenerate Original',
          value: 'regenerate_original',
        },
      ];
      if (zPromptModHistory?.length) {
        // prepend mod history to mod suggestions
        modSuggestions.unshift({
          display: 'View History',
          value: 'mod_history_modal',
          icon: MdOutlineHistory,
        });
      }
      return modSuggestions;
    }, [zPromptModHistory]);

    function buildModalModHistory(isVisible) {
      if (!isVisible) return setTriggerModalModHistory(null);

      const Header = () => {
        return (
          <Flex alignItems={'center'} gap={2}>
            <Icon as={MdOutlineHistory} w={'1.3rem'} h={'1.3rem'} />
            <BodyMdSemiBold>Your Prompt History</BodyMdSemiBold>
          </Flex>
        );
      };
      const Body = () => {
        return (
          <Stack>
            {!zPromptModHistory?.length && <BodySm>Your prompt history is empty.</BodySm>}
            <Stack spacing={2} overflowY={'scroll'} maxH={'60vh'}>
              {zPromptModHistory.map((modItem, index) => (
                <Flex gap={1} key={index}>
                  <ZCopyButton wrap={false} cta={'Copy'} getCopyContentCallback={() => modItem.mod} />
                  <ZCard variant="outline" borderColor={'playful.blue'} p={2}>
                    <BodySm whiteSpace="pre-line">{modItem.mod}</BodySm>
                  </ZCard>
                </Flex>
              ))}
            </Stack>
          </Stack>
        );
      };

      const Footer = null;

      setTriggerModalModHistory({
        neutralText: 'Continue',
        positiveText: '',
        header: <Header />,
        body: <Body />,
        footer: Footer,
        positiveCallback: () => regeneratePrompt('testing mod history'),
        onCloseCallback: () => setTriggerModalModHistory(null),
        closeOnEsc: true,
        modalSize: '5xl',
      });
    }

    return (
      <Popover trigger={'click'} placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Stack>
            <Tooltip label={'Modify'} placement="top-start" borderRadius={'5px'}>
              <Box
                border={'1px'}
                borderRadius={'5px'}
                borderColor={'gray.300'}
                p={'.2rem'}
                _hover={{bg: 'gray.100'}}
                cursor="pointer"
              >
                <Flex alignItems={'center'} justifyContent={'center'}>
                  <Icon as={BsArrowRepeat} w={'.9rem'} h={'.9rem'} />
                </Flex>
              </Box>
            </Tooltip>
          </Stack>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <Stack spacing={4}>
              <AutoResizeTextarea
                size="sm"
                placeholder={'Enter your custom modificiation here...'}
                // maxLength={1000}
                value={stateModInput ? stateModInput : ''}
                onChange={handleInputChange}
                isInvalid={false}
                alignSelf="stretch"
                minH="unset"
                resize="none"
                borderColor={'gray.200'}
                maxHeight={40}
                onKeyDown={(e) => {
                  // Check if Enter key is pressed without Shift key
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent default Enter key behavior (new line)
                    regeneratePrompt(sanitizeInput(stateModInput));
                  }
                }}
              />
              <Flex gap={2} alignItems={'center'}>
                <Divider flexGrow={1} />
                <BodySmMuted>or</BodySmMuted>
                <Divider flexGrow={1} />
              </Flex>
              <Stack spacing={2}>
                {memoModSuggestions.map((suggestion, index) => (
                  <ButtonSecondary
                    key={index}
                    wrap={false}
                    centerText={true}
                    leftIcon={suggestion.icon && <Icon as={suggestion.icon} />}
                    width={'100%'}
                    onClick={() => {
                      if (suggestion.value === 'mod_history_modal') {
                        console.log('mod_history_modal');
                        buildModalModHistory(true);
                        return;
                      }

                      const userMod = sanitizeInput(suggestion.value) ?? '';
                      console.log('userMod', userMod);
                      regeneratePrompt(userMod, suggestion.display);
                    }}
                  >
                    {suggestion.display}
                  </ButtonSecondary>
                ))}
              </Stack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  function CopyBlockButton() {
    const [stateCopying, setCopying] = useState(false);

    const memoBlockContent = useMemo(() => {
      if (!memoBlock || !zGptReqDataMap) return null;

      return GPT.getBlockContent(memoBlock, zGptReqDataMap);
    }, [memoBlock, zGptReqDataMap]);

    if (!memoBlockContent) return null;

    return (
      <Wrap m={0}>
        <WrapItem m={0}>
          <Tooltip label={'Copy'} placement="top-start" borderRadius={'5px'}>
            <Box
              border={'1px'}
              borderRadius={'5px'}
              borderColor={'gray.300'}
              p={'.25rem'}
              onClick={async () => {
                const copyContent = htmlToPlainText(memoBlockContent);
                const copySuccess = await copyToClipboard(copyContent);
                if (!copySuccess) return;
                setCopying(true);
                setTimeout(() => setCopying(false), 3000);
              }}
              _hover={{bg: 'gray.100'}}
              cursor="pointer"
            >
              <Flex alignItems={'center'} justifyContent={'center'}>
                <Icon as={stateCopying ? FiCheck : FiCopy} w={'.8rem'} h={'.8rem'} />
              </Flex>
            </Box>
          </Tooltip>
        </WrapItem>
      </Wrap>
    );
  }

  function ShowModification() {
    const {onOpen, onClose, isOpen} = useDisclosure();

    if (!memoBlock?.modChain) return null;

    const truncateText = (text, maxWords) => {
      const words = text.split(' ');
      if (words.length > maxWords) {
        return words.slice(0, maxWords).join(' ') + '...';
      } else {
        return text;
      }
    };

    return (
      <Popover trigger={'hover'} placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Box border={'1px'} borderRadius={'5px'} borderColor={'gray.300'} p={'.15rem'} _hover={{bg: 'gray.100'}}>
            <Flex alignItems={'center'} justifyContent={'center'}>
              <Icon as={BsQuestion} w={'1rem'} h={'1rem'} />
            </Flex>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Stack maxW={'30vw'}>
              <BodySmSemiBold>
                {memoSelectedModBlock ? 'Current Modification:' : 'This is the original content.'}
              </BodySmSemiBold>
              {memoSelectedModBlock && (
                <Stack spacing={4}>
                  <BodySmMuted whiteSpace="pre-line">
                    {memoSelectedModBlock.modDisplay || memoSelectedModBlock.mod}
                  </BodySmMuted>
                  <Divider />
                  <Stack spacing={2}>
                    <BodySmSemiBold>Original Content:</BodySmSemiBold>
                    <BodySmMuted whiteSpace="pre-line">
                      {truncateText(memoSelectedModBlock.preModOutput, 100)}
                    </BodySmMuted>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Stack flexGrow={1} width={width} spacing={0} {...props}>
      <Flex justifyContent={'space-between'} width={'100%'}>
        <Flex gap={1}>
          <ElementLabel />
          <CopyBlockButton />
          {memoBlock.type === 'prompt' && GPT.getBlockContent(memoBlock, zGptReqDataMap) && memoModPopover}
          {memoBlock?.impermanent && zGptReqDataMap?.[memoBlock.pk] && <SaveImpermanentBlockButton />}
        </Flex>
        <Flex gap={1}>
          {memoShowModificationPopover}
          {memoBlock?.modChain && <ModChainNavigation />}
        </Flex>
      </Flex>
      <Flex gap={2} width={'100%'}>
        <ElementBlockDivider
          elementId={elementId}
          sectionId={sectionId}
          index={index}
          draggableProps={draggableProps}
        />
        <Stack width={'100%'} spacing={0} pt={2}>
          {children}
          {/* <ElementToolbar draggableProps={draggableProps} sectionId={sectionId} elementId={elementId} onAddBlock={onAddBlock} /> */}
        </Stack>
      </Flex>
    </Stack>
  );
};

const EpElement = ({
  sectionId,
  elementId,
  elementType,
  index,
  onDeleteBlock,
  onAddBlock,
  setBlockToTransformToPrompt,
  draggableProps,
  setTriggerModalModHistory,
  promptModApi,
}) => {
  const zGptReq = episodeStore((state) => state.gptReq);

  let elementComponent;

  if (!zGptReq) {
    console.log('this should never happen, this tab should be disabled until zGptReq is set');
    elementComponent = (
      <>
        <Spinner />
        <BodySmSemiBold>LOADING...</BodySmSemiBold>
      </>
    );
  }

  switch (elementType) {
    case 'block':
      elementComponent = <EpBlock sectionId={sectionId} elementId={elementId} index={index} width={'100%'} />;
      break;
    case 'input':
      elementComponent = <EpInput sectionId={sectionId} elementId={elementId} index={index} width={'100%'} />;
      break;
    case 'prompt':
      elementComponent = <EpPrompt sectionId={sectionId} elementId={elementId} index={index} width={'100%'} />;
      break;
    case 'undefined':
      elementComponent = (
        <EpUndefinedBlock
          sectionId={sectionId}
          elementId={elementId}
          index={index}
          onDeleteBlock={onDeleteBlock}
          setBlockToTransformToPrompt={setBlockToTransformToPrompt}
          width={'100%'}
        />
      );
      break;
    default:
      console.log('Invalid element type', elementType);
      throw new Error('Invalid element type');
  }

  return (
    <EpElementContainer
      draggableProps={draggableProps}
      sectionId={sectionId}
      elementId={elementId}
      index={index}
      onAddBlock={onAddBlock}
      setTriggerModalModHistory={setTriggerModalModHistory}
      promptModApi={promptModApi}
    >
      {elementComponent}
    </EpElementContainer>
  );
};

const ElementToolbar = ({draggableProps, sectionId, elementId, onAddBlock}) => {
  const zTemplateBlockSelected = episodeStore((state) => state.templateBlockSelected);

  function isElementFocused() {
    if (!zTemplateBlockSelected) return false;
    return zTemplateBlockSelected.sectionId === sectionId && zTemplateBlockSelected.elementId === elementId;
  }

  return (
    <>
      {isElementFocused() ? (
        <Box width={8}>
          <Flex direction={'row'} alignItems={'center'}>
            <Tooltip
              label={'Click this icon to add a new block below this one'}
              placement="top-end"
              borderRadius={'lg'}
            >
              <Stack _hover={{bg: 'gray.100'}} cursor={'pointer'} onClick={onAddBlock} borderRadius={'100%'} p={0.5}>
                <Icon w={'14px'} color={'gray.400'} as={FiPlus} />
              </Stack>
            </Tooltip>
            <Tooltip label={'Click and drag this icon to re-order the blocks'} placement="top-end" borderRadius={'lg'}>
              <Stack _hover={{bg: 'gray.100'}} borderRadius={'100%'} {...draggableProps.dragHandleProps} p={0.5}>
                <Icon w={'14px'} color={'gray.400'} as={MdDragIndicator} />
              </Stack>
            </Tooltip>
          </Flex>
        </Box>
      ) : (
        <Box {...draggableProps.dragHandleProps} width={8} flexShrink={0} />
      )}
    </>
  );
};

const ElementBlockDivider = ({sectionId, elementId, index, draggableProps}) => {
  const zTemplateBlockSelected = episodeStore((state) => state.templateBlockSelected);
  const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);

  function isElementFocused() {
    if (!zTemplateBlockSelected) return false;
    return zTemplateBlockSelected.sectionId === sectionId && zTemplateBlockSelected.elementId === elementId;
  }

  function onClick() {
    console.log('testing this click');
    zSetTemplateBlockSelected(sectionId, elementId, index);
  }

  return (
    <Tooltip label={'Click and drag up and down to re-order the blocks'} placement="left-start" borderRadius={'lg'}>
      <Flex pr={2} justifyContent={'center'} onClick={onClick} {...draggableProps.dragHandleProps}>
        {isElementFocused() ? (
          <Divider orientation="vertical" borderColor={'playful.blue'} />
        ) : (
          <Divider orientation="vertical" borderColor={'gray.300'} />
        )}
      </Flex>
    </Tooltip>
  );
};

const EpBlock = ({sectionId, elementId, index, ...props}) => {
  const zTemplates = episodeStore((state) => state.templates);
  const zUpdateTemplateBlock = episodeStore((state) => state.updateTemplateBlock);
  const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);

  const block = useMemo(() => {
    return zTemplates[sectionId].blocks[elementId];
  }, [zTemplates, sectionId, elementId]);

  return (
    <Flex {...props}>
      <QuillEditor
        content={block.body}
        onChange={(html) => {}}
        onChangeDelayed={(html) => {
          zUpdateTemplateBlock(sectionId, elementId, {body: html});
        }}
        isFocusedCallback={(isFocused) => {
          if (isFocused) zSetTemplateBlockSelected(sectionId, elementId, index);
        }}
      />
    </Flex>
  );
};

const EpUndefinedBlock = ({sectionId, elementId, index, onDeleteBlock, setBlockToTransformToPrompt, ...props}) => {
  const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);
  const zUpdateTemplateBlock = episodeStore((state) => state.updateTemplateBlock);

  return (
    <Stack
      padding={0}
      spacing={0}
      onClick={() => {
        zSetTemplateBlockSelected(sectionId, elementId, index, false);
      }}
      {...props}
    >
      <Flex gap={2}>
        <ButtonSecondary onClick={() => zUpdateTemplateBlock(sectionId, elementId, {type: 'block'})}>
          Text Block
        </ButtonSecondary>
        <ButtonSecondary onClick={() => setBlockToTransformToPrompt({sectionId, pk: elementId, index})}>
          AI Block
        </ButtonSecondary>
        <ZIconButton
          variant="outline"
          onClick={() => onDeleteBlock(index, elementId)}
          colorScheme="white"
          aria-label="Delete Block"
          icon={<Icon as={FiTrash} />}
          tooltipText={'Delete Block'}
        />
      </Flex>
    </Stack>
  );
};

const EpInput = ({sectionId, elementId, index, ...props}) => {
  const zEpisode = episodeStore((state) => state.episode);
  const zTemplates = episodeStore((state) => state.templates);
  const zUpdateTemplateBlock = episodeStore((state) => state.updateTemplateBlock);
  const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);

  const block = useMemo(() => {
    return zTemplates[sectionId].blocks[elementId];
  }, [zTemplates, sectionId, elementId]);

  return (
    <Flex {...props}>
      <QuillEditor
        content={block.body || zEpisode.inputsCustom[elementId].value}
        onChange={(html) => {}}
        onChangeDelayed={(html) => zUpdateTemplateBlock(sectionId, elementId, {body: html})}
        isFocusedCallback={(isFocused) => {
          if (isFocused) zSetTemplateBlockSelected(sectionId, elementId, index);
        }}
      />
    </Flex>
  );
};

const EpPrompt = ({sectionId, elementId, index, ...props}) => {
  const zTemplates = episodeStore((state) => state.templates);
  const zGptReq = episodeStore((state) => state.gptReq);
  const zGptReqDataMap = episodeStore((state) => state.gptReqDataMap);
  const zGptReqPromptsFinished = episodeStore.getState().gptReqPromptsFinished;

  const block = useMemo(() => {
    return zTemplates[sectionId].blocks[elementId];
  }, [zTemplates, zGptReqDataMap, sectionId, elementId]);

  if (!zGptReq) {
    // this should never happen, this tab should be disabled until zGptReq is set.
    return null;
  }

  // efficiently checking for complete prompt
  const modBlock = GPT.getSelectedModBlock(block);
  const blockPk = GPT.getBlockPk(block.pk, modBlock?.pk);
  if (zGptReqPromptsFinished[blockPk] == 'success')
    return <EpPromptComplete sectionId={sectionId} elementId={elementId} index={index} />;

  // slightly less efficient
  const isComplete = GPT.isPromptCompleteByPromptId(elementId, zGptReq, modBlock?.pk);
  if (isComplete) return <EpPromptComplete sectionId={sectionId} elementId={elementId} index={index} {...props} />;

  // less efficient
  const status = GPT.getStatusByPromptId(blockPk, zGptReq, modBlock?.pk);
  switch (status) {
    case 'queued':
    case 'waiting':
    case 'running':
    case 'error':
    case 'failed':
      return <EpPromptRunning sectionId={sectionId} elementId={elementId} index={index} status={status} {...props} />;
    default:
      console.log('Invalid prompt status', status);
      throw new Error('Invalid prompt status');
  }
};

function EpPromptComplete({sectionId, elementId, index, ...props}) {
  const zTemplates = episodeStore((state) => state.templates);
  const zGptReqDataMap = episodeStore((state) => state.gptReqDataMap);
  const zUpdateTemplateBlock = episodeStore((state) => state.updateTemplateBlock);
  const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);

  const memoBlock = useMemo(() => {
    return zTemplates[sectionId].blocks[elementId];
  }, [zTemplates[sectionId].blocks[elementId], zGptReqDataMap, sectionId, elementId]);

  const memoSelectedModBlock = useMemo(() => {
    return GPT.getSelectedModBlock(memoBlock);
  }, [memoBlock, memoBlock.modChain?.selectedIdx]);

  if (!zGptReqDataMap || !memoBlock) return <Stack></Stack>;

  return (
    <Flex {...props}>
      <QuillEditor
        content={GPT.getBlockContent(memoBlock, zGptReqDataMap)}
        onChange={(html) => {}}
        onChangeDelayed={(html) => {
          if (memoSelectedModBlock) {
            // Use structuredClone to create a deep clone of memoBlock, ensuring it's not frozen
            const block = structuredClone(zTemplates[sectionId].blocks[elementId]);
            const modChain = {...block.modChain};
            modChain.chain[modChain.selectedIdx - 1].body = html;
            zUpdateTemplateBlock(sectionId, elementId, {modChain});
          } else {
            zUpdateTemplateBlock(sectionId, elementId, {body: html});
          }
        }}
        isFocusedCallback={(isFocused) => {
          if (isFocused) zSetTemplateBlockSelected(sectionId, elementId, index);
        }}
      />
    </Flex>
  );
}

const EpPromptRunning = ({sectionId, elementId, index, status, ...props}) => {
  const zGptReq = episodeStore((state) => state.gptReq);
  const zSetTemplateBlockSelected = episodeStore((state) => state.setTemplateBlockSelected);
  const zRetryingFailedRequests = episodeStore((state) => state.retryingFailedRequests);

  const {allowRetryRequests, retryFailedRequests} = useContext(SectionContext);

  useEffect(() => {
    console.log('EpPromptRunning useEffect');
  }, []);

  const [stateProgress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(Math.round(GPT.getPromptProgress(elementId, zGptReq, true)));
  }, [zGptReq]);

  function getStatusText(status) {
    if (status === 'error') {
      return 'running';
    } else if (status === 'waiting') {
      return 'in progress...';
    } else {
      return status;
    }
  }

  return (
    <ZCard
      padding={0}
      variant="outline"
      spacing={0}
      onClick={() => {
        zSetTemplateBlockSelected(sectionId, elementId, index, false);
      }}
      {...props}
    >
      <Box overflow={'hidden'} width={'100%'} borderRadius={'10px'}>
        <ZProgressBar value={stateProgress} size={'xs'} isIndeterminate={false} />
      </Box>
      <Wrap m={0}>
        <WrapItem m={0}>
          <Box borderRightRadius={'5px'} backgroundColor={'blue.100'} px={3}>
            <BodySm muted={true}>{getStatusText(status)}</BodySm>
          </Box>
        </WrapItem>
      </Wrap>
      <Stack p={3}>
        <Text fontSize={'sm'} color={'gray.400'} p={3} fontStyle={'italic'}>
          {allowRetryRequests
            ? 'We encountered an error while creating some content. Click retry, or contact support if the issue persists.'
            : "...We're working our magic. Your content will appear here when it's ready."}
        </Text>

        <Flex justifyContent={'end'}>
          {allowRetryRequests && (
            <ButtonPrimary
              wrap={true}
              onClick={() => {
                retryFailedRequests(zGptReq);
              }}
              isLoading={zRetryingFailedRequests}
              loadingText={'Retrying...'}
              tooltipLabelPlacement={'left'}
              tooltipLabel={'Something went wrong. Click to retry.'}
            >
              Try Again
            </ButtonPrimary>
          )}
        </Flex>
      </Stack>
    </ZCard>
  );
};

export {EpSection, EpSectionContainer, ExportOptionsButton, ElementToolbar};
