import {Box, Flex, Image, Stack} from '@chakra-ui/react';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import {ZAlertFullWidth} from '../../components/common/ComponentStyle';
import {FlowBody, FlowContainer, FlowFooter, FlowHeader} from '../../components/common/Structural';
import {BodyMd} from '../../components/common/TextStyle';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import {useAPI} from '../../utils/api-v2-context';

export const Invite = () => {
  const {inviteId} = useParams();

  const navigate = useNavigate();

  const zUser = authStore((state) => state.user);
  const zUpdateUser = authStore((state) => state.updateUser);
  const zSetTeam = authStore((state) => state.setTeam);
  const zIsLoading = appStore((state) => state.isLoading);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);

  const [stateTeam, setTeam] = useState(null);

  const {teamApi} = useAPI();
  const teammateInvitation = useMemo(() => stateTeam?.find?.((tm) => tm.sk === zUser.email), [stateTeam, zUser.email]);

  useEffect(() => {
    async function initialLoad() {
      if (stateTeam || !inviteId) {
        return;
      }

      zSetLoading(true);

      try {
        const getTeamPromise = teamApi.getTeam(inviteId);
        const responses = await Promise.all([getTeamPromise]);
        responses.forEach(
          (isSuccess) => !isSuccess && zSetError('saveConfigAndRequestEnhance', 'Error requesting enhance'),
        );

        // set tmp team state
        setTeam(responses[0]);
      } catch (e) {
        console.log('error initialLoad', e);
        zSetError('initialLoad', e.message);
      } finally {
        zSetLoading(false);
      }
    }

    initialLoad();
  }, []);

  async function acceptInvite() {
    try {
      zSetLoading(true);
      const updatedTeam = await teamApi.acceptInvite(inviteId, zUser.email, zUser.userSub);
      if (!updatedTeam) {
        throw new Error('Error accepting invitation');
      }

      if (!zUser.teamId) {
        zUpdateUser({teamId: inviteId});
      }

      zSetTeam(updatedTeam);
      navigate('/settings/team');
    } catch (e) {
      console.log('error acceptInvitation', e);
      zSetError('acceptInvitation', e.message);
    } finally {
      zSetLoading(false);
    }
  }

  const AlreadyPartOfTeamBody = () => (
    <ZAlertFullWidth status="info">
      You are already a part of a team. If you'd like to join another team, you need to leave your current team first.
    </ZAlertFullWidth>
  );

  const NoInviteBody = () => (
    <ZAlertFullWidth status="info">
      This team invitation doesn't exist or isn't yours. Make sure you're signed into the Podflow account using the same
      email as the one in your invite. Please reach out to your team admin for additional assistance.
    </ZAlertFullWidth>
  );

  const AlreadyAcceptedBody = () => (
    <Stack>
      <ZAlertFullWidth status="success">You're already accepted this invitation.</ZAlertFullWidth>
      <Flex justifyContent={'end'}>
        <ButtonPrimary onClick={() => navigate('/settings/team')}>Visit Team Page</ButtonPrimary>
      </Flex>
    </Stack>
  );

  const Logo = () => (
    <Box
      display="flex"
      flexDirection={'row'}
      gap="20px"
      paddingX={'10px'}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Image
        src={process.env.PUBLIC_URL + '/images/podflow-square.png'}
        height="45px"
        borderRadius="10px"
        alt="Podflow Logo"
        my={'7.5px'}
      />
    </Box>
  );

  const InviteBody = ({...props}) => (
    <Stack spacing={8} {...props}>
      <BodyMd>
        {teammateInvitation.invitedBy +
          ' has invited your to join their Podflow Team! Accept their invitation to join the team as an ' +
          teammateInvitation.role +
          '.'}
      </BodyMd>
      <Flex justifyContent={'end'}>
        <ButtonPrimary wrap={true} isDisabled={zIsLoading} onClick={() => acceptInvite()}>
          Accept Invitation
        </ButtonPrimary>
      </Flex>
    </Stack>
  );

  const Body = () => {
    if (zIsLoading) {
      return null;
    }

    if (!inviteId || !teammateInvitation) {
      return <NoInviteBody />;
    }

    if (zUser.teamId) {
      return zUser.teamId === inviteId ? <AlreadyAcceptedBody /> : <AlreadyPartOfTeamBody />;
    }

    return teammateInvitation.invitationPending ? <InviteBody mt={4} /> : <AlreadyAcceptedBody />;
  };

  return (
    <FlowContainer maxWidth={'67%'}>
      <Stack spacing={16} mt={16}>
        <Flex justifyContent={'center'}>
          <Logo />
        </Flex>

        <FlowHeader title={'Podflow Team Invitation'} hideBreadcrumbs={true} />
      </Stack>

      <FlowBody>
        <Stack>
          <Body />
        </Stack>
      </FlowBody>

      <FlowFooter />
    </FlowContainer>
  );
};
