import {Box, Flex, Stack, Tooltip} from '@chakra-ui/react';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {BodyXsMuted} from '../common/TextStyle';

export const Step = ({stepData, isActive, onClick, index, isDisabled, ...props}) => {
  useEffect(() => {
    if (isDisabled) {
      onClick = null;
    }
  }, [isDisabled]);

  const bgSxPrimary = {
    backgroundImage: '/buttonbg.jfif',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    ':hover': {
      backgroundImage: '/buttonbg.jfif',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      filter: 'brightness(85%)',
      transition: 'all .2s',
    },

    ':active': {
      backgroundImage: '/buttonbg.jfif',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      filter: 'brightness(65%)',
      transition: 'all .2s',
    },
  };

  return (
    <Flex {...props} alignItems={'center'}>
      <Tooltip
        isDisabled={!stepData?.tooltipLabel}
        label={stepData?.tooltipLabel}
        placement="bottom-start"
        borderRadius={'lg'}
      >
        <Stack spacing={1} justifyContent={'center'}>
          <BodyXsMuted>{stepData?.name}</BodyXsMuted>
          <Box
            h="10px"
            sx={isActive && bgSxPrimary}
            bg={!isActive && 'border'}
            borderRadius="5px"
            width={'81px'}
            transition="background 0.2s"
            onClick={!isDisabled ? onClick : undefined}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
          />
        </Stack>
      </Tooltip>
    </Flex>
  );
};

export const useStep = (props) => {
  const {maxStep, initialStep = 0} = props;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const canGoToNextStep = useMemo(() => currentStep + 1 <= maxStep, [currentStep, maxStep]);
  const canGoToPrevStep = useMemo(() => currentStep - 1 >= 0, [currentStep]);

  const setStep = useCallback(
    (step) => {
      const newStep = step instanceof Function ? step(currentStep) : step;
      if (newStep >= 0 && newStep <= maxStep) {
        setCurrentStep(newStep);
        return;
      }
      throw new Error('Step not valid');
    },
    [maxStep, currentStep],
  );

  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep((step) => step + 1);
    }
  }, [canGoToNextStep]);

  const goToPrevStep = useCallback(() => {
    if (canGoToPrevStep) {
      setCurrentStep((step) => step - 1);
    }
  }, [canGoToPrevStep]);

  const reset = useCallback(() => {
    setCurrentStep(0);
  }, []);

  return [
    currentStep,
    {
      goToNextStep,
      goToPrevStep,
      canGoToNextStep,
      canGoToPrevStep,
      setStep,
      reset,
    },
  ];
};
