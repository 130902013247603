import styled from 'styled-components';

const PingWrapper = styled.div`
  position: relative;
  width: 1px; // Or whatever width you want
  height: 1px; // Or whatever height you want
  z-index: 99;
  // Add other styling as necessary
`;

const StyledPingAnimation = styled.div`
  position: absolute;
  right: ${(props) =>
    props.topLeft ? 'auto' : '-' + props.offsetX.toString() + 'px'}; /* Use 'auto' if topLeft is true */
  left: ${(props) =>
    props.topLeft ? '-' + props.offsetX.toString() + 'px' : 'auto'}; /* Use '-6px' if topLeft is true */
  top: -6px;
  width: 12px;
  height: 12px;
  transform: scale(${(props) => props.scale || 1});
  z-index: 99;

  .ping {
    box-sizing: border-box;
    background: #5395f7;
    border-radius: 50%;
    width: 100%; /* Full width of the parent */
    height: 100%; /* Full height of the parent */
    animation: load 1.5s ease-out infinite;
  }

  @keyframes load {
    0%,
    100% {
      box-shadow: 0 0 0 0 rgba(83, 149, 247, 0);
    }
    25% {
      box-shadow: 0 0 0 0 rgba(83, 149, 247, 0.5);
    }
    75% {
      box-shadow: 0 0 0 6px rgba(83, 149, 247, 0);
    }
  }
`;

function ZPingAnimation({isDisabled, topLeft = false, offsetX = 6, scale = 1, ...props}) {
  if (isDisabled) {
    return <></>;
  }

  return (
    <PingWrapper>
      <StyledPingAnimation topLeft={topLeft} offsetX={offsetX} scale={scale}>
        <div className="ping" />
      </StyledPingAnimation>
    </PingWrapper>
  );
}

export default ZPingAnimation;
