import {Stack} from '@chakra-ui/react';
import {debounce} from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import styled from 'styled-components';

import {displayInfoToast} from '../common/Structural';

const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    font-size: 15px;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding: 0;
    width: 100%;
  }
`;

function QuillEditor({
  content,
  readOnly,
  onChange = () => {},
  onChangeDelayed = () => {},
  passPlainTextInOnChangeCallback = false,
  isFocusedCallback = () => {},
  ...props
}) {
  const [editorHtml, setEditorHtml] = useState(convertNewlinesToHtml(content));
  const quillRef = useRef(); // Create a ref

  useEffect(() => {
    setEditorHtml(convertNewlinesToHtml(content));
  }, [content]);

  const saveChanges = useCallback(
    debounce((text) => {
      onChangeDelayed(text);
    }, 100),
    [onChangeDelayed],
  );

  // cancel debounced call on unmount
  useEffect(() => {
    return () => {
      saveChanges.cancel();
    };
  }, [saveChanges]);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      editor.on('selection-change', (range) => {
        if (range) {
          isFocusedCallback(true);
        } else {
          isFocusedCallback(false);
        }
      });

      editor.on('text-change', () => {
        if (editor.getLength() <= 1) {
          editor.updateContents(
            {
              ops: [{insert: ' '}],
            },
            'silent',
          );
          setTimeout(() => editor.focus(), 1); // Force focus
        }
      });
    }
  }, []);

  return (
    <Stack
      {...props}
      width={'100%'}
      onClick={() => {
        if (readOnly)
          displayInfoToast(typeof readOnly === 'string' ? readOnly : 'Editing is currently disabled.', {
            duration: 1000,
          });
      }}
    >
      <StyledQuill
        ref={quillRef} // Pass the ref
        theme="bubble"
        readOnly={readOnly}
        value={editorHtml}
        onChange={(content, delta, source, editor) => {
          if (source === 'user') {
            // const text = editor.getText();
            setEditorHtml(content);

            if (passPlainTextInOnChangeCallback) content = htmlToPlainText(content);
            onChange(content);
            saveChanges(content); // Debounced save
          }
        }}
        modules={{
          toolbar: false,
        }}
      />
    </Stack>
  );
}

function htmlToPlainText(html) {
  if (!html) return '';

  // quill works with strictly with <p> tags as far as my users are concerned
  html = html.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '\n');

  html = html.replaceAll('</li></ol>', '\n').replaceAll('</li></ul>', '\n');
  html = html.replaceAll('<ul>', '').replaceAll('</ul>', '\n');
  html = html.replaceAll('<ol>', '').replaceAll('</ol>', '\n');
  html = html.replaceAll('<li>', '- ').replaceAll('</li>', '\n');

  // Create a new DOMParser
  var parser = new DOMParser();

  // Use the DOMParser to parse the HTML string
  var doc = parser.parseFromString(html, 'text/html');

  // Get the plain text content // Remove trailing newline characters
  const plainTextContent = (doc.body.textContent || '').replace(/\n+$/g, '');
  return plainTextContent;
}

// Helper function to convert newline characters to HTML line breaks
function convertNewlinesToHtml(text) {
  if (!text) return '';
  return text.replaceAll('\n', '<br>');
}

// import { Quill } from "react-quill";
// function htmlToPlainText(html) {
//   if (!html) return "";
//   const tempQuill = new Quill(document.createElement("div")); // Create a temporary Quill instance with an empty div
//   tempQuill.clipboard.dangerouslyPasteHTML(html); // Paste the HTML content
//   const plainTextContent = tempQuill.getText(); // Get the plain text content
//   return plainTextContent;
// }

export {QuillEditor, htmlToPlainText};
