import {AudioPlayer} from './audio-player';
import {EpisodeEditor} from './episode-editor';

export class StateManager {
  constructor() {
    this.players = null;
    this.state = []; // array of pointers. each pointer contains: reference, absStart, absEnd
    this.currentStateIdx = 0;
    this.isOrWillBePlaying = false;
    this.copyState = [];
    this.savedSeekPos = null; // if user seeks, but autoplay === false, saving seek position for next play

    // undo/redo
    this.undoStack = [];
    this.redoStack = [];

    // used to account for latency in seek operation
    this.seekStart = 0; // epoch millis
    this.seekLatency = 0; // in seconds

    // used to adjust audio state pointer in a timely manner
    this.timeout = null;
    this.interval = null;

    // DEV
    this.seekStartFinishes = [];
  }

  initAudioPlayers(players) {
    this.players = players;

    // must initialize with reference to entire raw audio file
    this.execOperation({
      relPos: 0,
      absStart: 0,
      absEnd: this.players[AudioPlayer.AP_BG_NOISE_REF].totalDuration(),
      opType: StateManager.ABS_ADD_OP,
      reference: AudioPlayer.AP_BG_NOISE_REF,
    });
  }

  /**
   * OPERATIONS
   * an operation object contains a subset of the following, depending on the operation:
   *
   * reference        - to an audio file
   * relStart         - timestamp in seconds
   * relEnd           - timestamp in seconds
   * opType           - operation type i.e. relative add or absolute delete
   * relPos           -
   * stateList        -
   * absStart         -
   * absEnd           -
   * originReference  -
   * targetReference  -
   *
   * relPos & stateList are used for addOp
   * relPos & absStart & absEnd are used for absAddOp
   * originReference & target referece are used for absTransformOp
   *
   */
  static DELETE_OP = 'delete';
  static ADD_OP = 'add';
  static TRANSFORM_OP = 'transform';
  static ABS_DELETE_OP = 'abs_delete';
  static ABS_ADD_OP = 'abs_add';
  static ABS_TRANSFORM_OP = 'abs_transform';

  /**
   * @param {Boolean} invalidateState - if state should be invalidated
   * @returns true if state was changed, and false otherwise
   */
  changeStateCheck(invalidateState = false) {
    this.updateDebugState();
    let wasPlaying = this.isOrWillBePlaying;
    const latency = 0; // wasPlaying ? this.seekLatency : 0
    const {index, absPos, exceededMaxPosition} = this.getAbsPosAndStateIdxByRelPosition(
      this.relativePosition() + latency,
      false,
    );

    if (exceededMaxPosition) {
      console.log('changeStateCheck() exceededMaxPosition - calling on finish in changes state');
      this.onFinish();
      return false;
    }

    // check if state should change.
    if (index !== this.currentStateIdx || invalidateState) {
      if (this.state[index].reference !== this.state[this.currentStateIdx].reference) this.pause();
      this.currentStateIdx = index;
      // const latency = (wasPlaying ? this.seekLatency : 0) / 1000;
      this.seekByAbsPos(absPos, wasPlaying);
      if (wasPlaying) this.seekStart = Date.now(); // for latency calculation
      return true;
    }
    return false;
  }

  startChangeStateCheck() {
    console.log('startChangeStateCheck');
    const self = this;
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      self.changeStateCheck();
    }, 10);
  }

  stopChangeStateCheck() {
    console.log('stopChangeStateCheck');
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  setSeekLatency(latency) {
    console.log('smSeekLatency', latency);
    this.seekLatency = latency;
  }

  play() {
    this.isOrWillBePlaying = true;
    if (this.savedSeekPos) console.log('using savedSeekPos', this.savedSeekPos);
    this.seekByRelPos(this.savedSeekPos ?? this.relativePosition(), true);
  }

  pause() {
    this.isOrWillBePlaying = false;
    this.currentPlayer().pause();
    this.stopChangeStateCheck();
  }

  /**
   *
   * @param {*} relPos
   * @param {*} autoPlay
   */
  seekByRelPos(relPos, autoPlay) {
    const {index, absPos} = this.getAbsPosAndStateIdxByRelPosition(relPos, false);
    this.currentStateIdx = index;
    if (!autoPlay && !this.isOrWillBePlaying) this.savedSeekPos = relPos;
    this.seekByAbsPos(absPos, autoPlay);
  }

  /**
   *
   * @param {*} absPos
   * @param {*} autoplay - forces play if true. If false, AP will continue as it is
   */
  seekByAbsPos(absPos, autoplay) {
    if (autoplay) this.isOrWillBePlaying = true;
    this.currentPlayer().seek(absPos, autoplay);
  }

  apOnPlay(reference) {
    console.log('apOnPlay');
    if (this.seekStart) {
      // this.seekLatency = Date.now() - this.seekStart;
      console.log('zzz calculated latency:', Date.now() - this.seekStart);
      console.log('seekLatency', this.seekLatency);
      this.seekStart = 0;
    }

    this.savedSeekPos = null;
    this.startChangeStateCheck();
  }

  apOnPause(reference) {
    console.log('apOnPause');
  }

  apOnEnd(reference) {
    console.log('apOnEnd');
    if (this.onFinalState()) this.onFinish();
  }

  /**
   * Since ap 'playing' attribute only returns true after the onPlay callback and not right after play is called - keeping track of whether ap is playing here
   * @returns whether the ap is currently playing or will be in the future.
   */
  apIsOrWillBePlaying() {
    return this.isOrWillBePlaying;
  }

  /**
   * uses sequential operations to render final audio state
   */
  renderState() {
    // iterate through operations and update new state
    this.state = [];
    const origUndoStack = structuredClone(this.undoStack);
    const origRedoStack = structuredClone(this.redoStack);
    for (const operation of origUndoStack) {
      this.execOperation(operation);
    }

    this.undoStack = origUndoStack;
    this.redoStack = origRedoStack;
  }

  undo() {
    if (this.undoStack.length <= 1) return;
    this.pause();

    const operation = this.undoStack.pop();
    if (operation) {
      this.redoStack.push(operation);
      this.renderState();
    }
  }

  redo() {
    this.pause();

    const operation = this.redoStack.pop();
    if (operation) {
      this.execOperation(operation, true, true);
    }
  }

  execOperation(operation, includeInStack = true, isRedo = false) {
    if (includeInStack) this.undoStack.push(operation);
    if (!isRedo) this.redoStack = [];

    const {relStart, relEnd, opType, reference, stateList, absStart, absEnd, originReference, targetReference} =
      operation;
    let {relPos} = operation;

    // updates the statemanager to point at and seek to the correct AP throughout the duration of the audio file
    switch (opType) {
      case StateManager.DELETE_OP:
        this.deleteOp(relStart, relEnd); // reference must be calculated
        break;
      case StateManager.ADD_OP:
        this.addOp(relPos, stateList); // reference must be calculated
        break;
      case StateManager.TRANSFORM_OP:
        this.transformOp(relStart, relEnd, reference);
        break;
      case StateManager.ABS_DELETE_OP:
        this.absDeleteOp(absStart, absEnd, reference);
        break;
      case StateManager.ABS_ADD_OP:
        this.absAddOp(relPos, absStart, absEnd, reference);
        break;
      case StateManager.ABS_TRANSFORM_OP:
        this.absTransformOp(absStart, absEnd, originReference, targetReference);
        break;
      default:
        console.log('error operation not recognized. op:', operation);
    }
    this.purgeStateList();
    this.changeStateCheck(true);
  }

  deleteOp(relStart, relEnd) {
    /**
     * either the state holds the information related to the absolute timestamp, or the absolute timestamp is calculated at runtime when the relative timestamp marker is hit
     * The state must hold the abs ts information because we can't derive this information later otherwise if we're not dealing with the beginning of a audio item uninterupted
     */

    // calculate the absolute timestamps for the audio players
    // find state item currently including relStart - favorEarlyStatesOnConflict: true
    const relStartStateInfo = this.getAbsPosAndStateIdxByRelPosition(relStart, true);
    const startStateIdx = relStartStateInfo.index;
    // const startState = this.state[startStateIdx];
    const startAbsPos = relStartStateInfo.absPos;
    // find state item currently including relEnd - favorEarlyStatesOnConflict: false
    const relEndStateInfo = this.getAbsPosAndStateIdxByRelPosition(relEnd, true);
    const endStateIdx = relEndStateInfo.index;
    const endState = this.state[endStateIdx];
    const endAbsPos = relEndStateInfo.absPos;

    if (startStateIdx === endStateIdx) {
      // if the edit spans a single state item, then that state item is shortened and an additional state item is added for the tail end
      // save initial end state for later
      const curEndStateAbsEnd = endState.absEnd;

      // modify state to end at beginning of deletion
      this.state[startStateIdx].absEnd = startAbsPos;

      // insert new state to point at audio after the deletion
      if (endAbsPos < curEndStateAbsEnd) {
        const newState = {
          reference: endState.reference,
          absStart: endAbsPos,
          absEnd: curEndStateAbsEnd,
        };
        this.state.splice(startStateIdx + 1, 0, newState);
      }
    } else {
      // else the edit spans 2 state items, then the tail end of the earliest state item is pushed forward and the front end of the latest state item is pushed back
      this.state[startStateIdx].absEnd = startAbsPos;
      this.state[endStateIdx].absStart = endAbsPos;

      this.state.splice(startStateIdx + 1, endStateIdx - startStateIdx - 1);
    }
  }

  /**
   *
   * REMEMBER! for one state item to be appended to the other without splitting the the first state item into 2 peices at relPos, relPos must be === to the first state item's absEnd value.
   * This will cause the function getAbsPosAndStateIdxByRelPosition() to return "conflicting" === true, and the stateList will be inserted efficiently into this.state
   *
   * @param {*} relPos - relative position to insert stateList attribute
   * @param {*} stateList - a list of state items, such as a subset of this.state
   * @returns
   */
  addOp(relPos, stateList) {
    // if state is empty
    if (this.state && !this.state.length) {
      this.state.push(...stateList);
      return;
    }

    const {index, absPos, exceededMaxPosition} = this.getAbsPosAndStateIdxByRelPosition(relPos, true);

    // insert state representing second half of the state split at relPos
    // if the relPos === initialState.absEnd, don't add second half of state back into this.state
    const endSubState = {
      absStart: absPos,
      absEnd: this.state[index].absEnd,
      reference: this.state[index].reference,
    };
    this.state.splice(index + 1, 0, endSubState);

    // insert stateList - using deep copy of state list in case stateList is reused
    const stateListClone = structuredClone(stateList);
    console.log('addOp stateListClone', stateListClone);
    this.state.splice(index + 1, 0, ...stateListClone);

    if (!exceededMaxPosition) this.state[index].absEnd = absPos;
  }

  transformOp(relStart, relEnd, reference) {
    console.log('relStart', relStart, 'relEnd', relEnd, 'reference', reference);

    // startState by relPos
    const relStartStateInfo = this.getAbsPosAndStateIdxByRelPosition(relStart, true);
    let startStateIdx = relStartStateInfo.index;
    const startAbsPos = relStartStateInfo.absPos;
    const startConflict = relStartStateInfo.conflicting;

    // endState by relPos
    const relEndStateInfo = this.getAbsPosAndStateIdxByRelPosition(relEnd, true);
    let endStateIdx = relEndStateInfo.index;
    const endAbsPos = relEndStateInfo.absPos;

    console.log('relStartStateInfo', relStartStateInfo, 'relEndStateInfo', relEndStateInfo);

    if (startStateIdx === endStateIdx) {
      //  spans a single state item
      console.log('transformOp spans a single state item');

      const transformedStateItem = {
        absStart: startAbsPos,
        absEnd: endAbsPos,
        reference: reference,
      };

      // new state for second half
      this.state.splice(startStateIdx + 1, 0, {
        absStart: endAbsPos,
        absEnd: this.state[startStateIdx].absEnd,
        reference: this.state[startStateIdx].reference,
      });

      // insert new transformed state item
      this.state.splice(startStateIdx + 1, 0, transformedStateItem);

      // modify first half
      if (!startConflict) this.state[startStateIdx].absEnd = startAbsPos;
    } else {
      // spans a 2 or more state items
      console.log('transformOp spans a 2 or more state items');

      const startSubsetTransformation = {
        absStart: startAbsPos,
        absEnd: this.state[startStateIdx].absEnd,
        reference: reference,
      };

      const endSubsetTransformation = {
        absStart: this.state[endStateIdx].absStart,
        absEnd: endAbsPos,
        reference: reference,
      };

      console.log(
        'startSubsetTransformation',
        startSubsetTransformation,
        'endSubsetTransformation',
        endSubsetTransformation,
      );

      // modify reference of each index between start and finish
      for (let i = startStateIdx + 1; i < endStateIdx; i++) {
        this.state[i].reference = reference;
      }

      // modify first half of start state
      this.state[startStateIdx].absEnd = startAbsPos;

      // modify second half of endState
      this.state[endStateIdx].absStart = endAbsPos;

      // insert new transformed state item for second half of start state
      this.state.splice(startStateIdx + 1, 0, startSubsetTransformation);
      // update end state index to account for new state item
      endStateIdx++;

      // insert new transformed state item for first half of end state
      this.state.splice(endStateIdx, 0, endSubsetTransformation);
    }
  }

  absDeleteOp(absStart, absEnd, reference) {
    // high level: perform deleteOp on all pointers matching specifications
    // iterate through this.state updating state's reference when the og state has reference & is pointing at audio between absStart and absEnd
    // for each change required, use relative deleteOp to update the state, then restart the search (because deleteOp will likely alter indices)
    // note: this results in infinite loop when deleteOp does not successfully remove the entire portion of state item matching specifications

    const clipFoundCallback = (relStart, relEnd) => {
      console.log('absDeleteOp deleteOp call');
      this.execOperation({relStart: relStart, relEnd: relEnd, opType: StateManager.DELETE_OP}, false);
    };

    this.getRelClipsByAbsClips(absStart, absEnd, reference, clipFoundCallback);
  }

  absAddOp(relPos, absStart, absEnd, reference) {
    const newStateItem = {
      absStart: absStart,
      absEnd: absEnd,
      reference: reference,
    };
    console.log('absAddOp newStateItem', newStateItem);

    this.execOperation(
      {
        opType: StateManager.ADD_OP,
        relPos: relPos,
        stateList: [newStateItem],
      },
      false,
    );
  }

  absTransformOp(absStart, absEnd, originReference, targetReference) {
    // high level: perform transformOp on all pointers matching specifications
    // iterate through this.state updating state's reference when the og state has originReference & is pointing at audio between absStart and absEnd
    // for each change required, use relative transformOp to update the state, then restart the search (because transformOp will likely alter indices)
    // note: this results in infinite loop when transformOp does not successfully remove the entire portion of state item matching specifications

    const clipFoundCallback = (relStart, relEnd) => {
      console.log('absTransformOp transformOp call');
      this.execOperation(
        {relStart: relStart, relEnd: relEnd, reference: targetReference, opType: StateManager.TRANSFORM_OP},
        false,
      );
    };

    this.getRelClipsByAbsClips(absStart, absEnd, originReference, clipFoundCallback);
  }

  copyOp(relStart, relEnd) {
    // GOAL: capture a subset of this.state
    // 1. create state item from relStart and relEnd
    // 2. insert any intermediate state items from state
    // note: this operation doesn't get added to operations list because state isn't modified. However, an addOp using this copy state would get added to opList

    // startState by relPos
    const relStartStateInfo = this.getAbsPosAndStateIdxByRelPosition(relStart, true);
    const startStateIdx = relStartStateInfo.index;
    const startAbsPos = relStartStateInfo.absPos;

    // endState by relPos
    const relEndStateInfo = this.getAbsPosAndStateIdxByRelPosition(relEnd, true);
    const endStateIdx = relEndStateInfo.index;
    const endAbsPos = relEndStateInfo.absPos;

    const copyState = [];
    if (startStateIdx === endStateIdx) {
      //  spans a single state item
      copyState.push({absStart: startAbsPos, absEnd: endAbsPos, reference: this.state[startStateIdx].reference});
    } else {
      // spans a 2 or more state items
      const startState = {
        absStart: startAbsPos,
        absEnd: this.state[startStateIdx].absEnd,
        reference: this.state[startStateIdx].reference,
      };

      const endState = {
        absStart: this.state[endStateIdx].absStart,
        absEnd: endAbsPos,
        reference: this.state[endStateIdx].reference,
      };

      const intermediateStates = this.state.slice(startStateIdx + 1, endStateIdx);

      copyState.push(startState);
      copyState.push(...intermediateStates);
      copyState.push(endState);
    }

    this.copyState = this.purgeStateList(copyState);
  }

  pasteOp(relPos) {
    const operation = {relPos: relPos, stateList: this.copyState, opType: StateManager.ADD_OP};
    this.execOperation(operation);
  }

  /**
   * @param {*} stateItem
   * @returns false if stateItem duration is 0
   */
  isValidStateItem(stateItem) {
    if (!stateItem.reference || stateItem.absStart === undefined || stateItem.absEnd === undefined) {
      throw Error('stateItem missing attribute');
    }

    if (stateItem.absEnd <= stateItem.absStart) {
      return false;
    }

    return true;
  }

  /**
   * removes unecessary items from state list
   * items are unecessary when:
   * - duration is 0 or negative
   * - items transition to next item with no meaninful change i.e. reference is equal and absEnd == absStart of next item
   * @param {List} list - optional - an initial list to purge and return. - this.state is default
   * @returns purged version of provided list, or nothing if list param is undefined
   */
  purgeStateList(list) {
    const clonedList = structuredClone(list ?? this.state);
    const shouldRemoveStateItem = (i) => !this.isValidStateItem(clonedList[i]);
    const isTransitionUseless = (i) =>
      i + 1 < clonedList.length &&
      clonedList[i].absEnd === clonedList[i + 1].absStart &&
      clonedList[i].reference === clonedList[i + 1].reference;

    for (let i = 0; i < clonedList.length; i++) {
      if (shouldRemoveStateItem(i)) {
        clonedList.splice(i, 1);
        i--; // to account for the removed item
      } else if (isTransitionUseless(i)) {
        clonedList[i].absEnd = clonedList[i + 1].absEnd;
        clonedList.splice(i + 1, 1);
        i--; // to account for the removed item
      }
    }

    if (list) {
      return clonedList;
    } else {
      this.state = clonedList;
    }

    // if no items left in this.state
    if (this.state.length === 0) {
      EpisodeEditor.i().smOnEmptyState();
      this.onFinish();
    }
  }

  /**
   * @param {*} relPos
   * @param {*} favorEarlyStatesOnConflict - if provided timestamp conflicts with relStart timestamp already in state, choose a prior state
   * @returns state index, absPos (in seconds) of relativePos within state[stateIdx], whether the relPos conflicted with a previous state item, exceededMaxPosition: if relPos is greater than relativeDuration of audio
   */
  getAbsPosAndStateIdxByRelPosition(relPos, favorEarlyStatesOnConflict) {
    let cummulativeRelDuration = 0;
    let conflicting = false;
    let exceededMaxPosition = false;
    let i = 0;
    while (i < this.state.length) {
      const stateDuration = this.state[i].absEnd - this.state[i].absStart;
      if (cummulativeRelDuration + stateDuration >= relPos) {
        // check if relPos collides with existing state item
        conflicting = false;
        if (cummulativeRelDuration + stateDuration === relPos) {
          conflicting = true;
          if (!favorEarlyStatesOnConflict) {
            i++;
            continue;
          }
        }

        break;
      } else if (i + 1 === this.state.length) {
        // relPos exceeds all state pointers.
        // It's greater than the maximum, which should only happen during testing
        return {
          index: i,
          absPos: cummulativeRelDuration + stateDuration,
          conflicting: true,
          exceededMaxPosition: true,
        };
      }
      cummulativeRelDuration += stateDuration;
      i++;
    }
    const result = {
      index: i,
      absPos: relPos + this.state[i].absStart - cummulativeRelDuration,
      conflicting: conflicting,
      exceededMaxPosition: exceededMaxPosition,
    };
    return result;
  }

  getRelClipsByAbsClips(absStart, absEnd, reference, clipFoundCallback) {
    if (absStart === absEnd) {
      console.log('getRelClipsByAbsStartAndFinish operation empty');
      return;
    }

    // high level: operations should be performed on all pointers matching specifications.

    let searchComplete = false;
    while (!searchComplete) {
      searchComplete = true;

      let i = 0;
      while (i < this.state.length) {
        const pointer = this.state[i];
        if (pointer.reference === reference) {
          let relStart;
          let relEnd;

          // determine whether this state pointer should be modified by absTransformOp
          // should be modified if pointer has same reference & contains any abs audio between absStart and absEnd

          // if operation's absStart is contained within the pointer
          if (absStart >= pointer.absStart && absStart < pointer.absEnd) {
            // calculate offset of operation's absStart & pointer's absStart
            const startOffset = absStart - pointer.absStart;
            // calculate relStart from state index and offset
            relStart = this.totalRelativeDuration(i) + startOffset;
          }
          // if operation's absEnd is contained within the pointer
          if (absEnd <= pointer.absEnd && absEnd > pointer.absStart) {
            // calculate offset of operation's absStart & pointer's absStart
            const endOffset = pointer.absEnd - absEnd;
            // calculate relEnd from state index and offset
            relEnd = this.totalRelativeDuration(i + 1) - endOffset;
          }

          // if pointer's absStart is contained within the operation
          if (pointer.absStart >= absStart && pointer.absStart < absEnd) {
            relStart = this.totalRelativeDuration(i);
          }

          // if pointer's absEnd is contained within the operation
          if (pointer.absEnd <= absEnd && pointer.absEnd > absStart) {
            relEnd = this.totalRelativeDuration(i + 1);
          }

          // if pointer should be modified
          if (relStart || relEnd) {
            console.log('relStart', relStart, 'relEnd', relEnd);

            // set default values
            if (relStart === undefined) relStart = this.totalRelativeDuration(i);
            if (relEnd === undefined) relEnd = this.totalRelativeDuration(i) + pointer.absEnd;

            clipFoundCallback(relStart, relEnd);

            searchComplete = false;
            break;
          }
        }

        i++;
      }
    }
  }

  currentPlayer() {
    // ensure no out of bounds index
    const potentialIndex = Math.min(this.currentStateIdx, Math.max(0, this.state.length - 1));
    if (potentialIndex !== this.currentStateIdx) {
      this.currentStateIdx = potentialIndex;
      console.warning('current state index adjusted to', this.currentStateIdx);
    }

    return this.players[this.state[this.currentStateIdx].reference];
  }

  /**
   *
   * @returns relative position of the currently playing audio player
   */
  relativePosition() {
    let totalDuration = 0;
    for (let i = 0; i < this.currentStateIdx; i++) {
      totalDuration += this.state[i].absEnd - this.state[i].absStart;
    }
    totalDuration += this.currentPlayer().position() - this.state[this.currentStateIdx].absStart;
    // console.log('relativePosition result: ', totalDuration, ' epoch millis: ', Date.now());
    if (totalDuration < 0) totalDuration = 0;
    return totalDuration;
  }

  /**
   *
   * @param {Number} upToIndex - optional param - finds relative duration up to the provided index
   * @returns cummulative duration from state items
   */
  totalRelativeDuration(upToIndex) {
    const stateList = upToIndex !== undefined ? this.state.slice(0, upToIndex) : this.state;
    return stateList.reduce((total, pointer) => total + (pointer.absEnd - pointer.absStart), 0);
  }

  /**
   *
   * @param {*} stateIdx
   * @returns relative starting position of the state indicated by the provided index - in seconds
   */
  relPosOfStateStart(stateIdx) {
    let totalDuration = 0;
    for (let i = 0; i < stateIdx; i++) {
      totalDuration += this.state[i].absEnd - this.state[i].absStart;
    }
    // console.log('relPosOfStateStart(): ', totalDuration);
    return totalDuration; // plus 1 millisecond to transition into next state
  }

  /**
   *
   * @returns true if the current state is that last available state
   */
  onFinalState() {
    return this.currentStateIdx >= this.state.length - 1;
  }

  onFinish() {
    this.pause();

    // resetting for replay
    this.currentStateIdx = 0;
    this.savedSeekPos = 0;
    EpisodeEditor.i().smOnFinish();
  }

  /**
   * used when saving draft or on final render
   * So that user can pick up where they left of if needed. Or for debugging purposes.
   * @returns state, undoStack, and redoStack to S3. Can be used to restore state.
   */
  getStateManagerSnapshot() {
    return {
      smState: this.state,
      smUndoStack: this.undoStack,
      smRedoStack: this.redoStack,
    };
  }

  /**
   * used for debugging. Includes:
   * - number of states
   * - current state index
   * - isOrWillBePlaying
   *
   */
  updateDebugState() {
    const debugState = {
      numStates: this.state.length,
      currentStateIdx: this.currentStateIdx,
      copyStateSize: this.copyState.length,
      operationsSize: this.undoStack.length,
      redoOperationsSize: this.redoStack.length,
      seekLatency: this.seekLatency.toFixed(3),
      // relPos: this.relativePosition().toFixed(2),
    };
    EpisodeEditor.i().smUpdateDebugState(debugState);
  }
}

/**
 * each pointer in statemanager has:
 * reference
 * start - absolute start timestamp of clip in given reference
 * end - absolute end timestamp of clip in given reference
 *
 * There are two types of timestamp inputs:
 * 1. The absolute timestamp that represents an moment on an audio file in it's origin form. Edits using this timestamp must be offset by the prior edits that have been made to the state manager. What about overlapping edits? Do we ever get overlapping edits with this type of timestamp
 * 2. This relative timestamp is relative to the edits that have already been made. This will be the the timestamp arises from user edits / interaction as is used to display any form of visual to the user. This will be the type of timestamp that will be stored in the state manager
 *
 * Do these operations need to be written with both timestamps in mind?
 * Can an absolute timestamp be used after a relative edit has been made?
 *
 *
 * Absolute timestamps can be used to edit after relative timestamps have been created in editor, but this would require every edit in the relative state manager,
 * whereas relative edits only require changes to the timestamp at or around the point of edit. The question becomes whether or not we need to be able to make absolute edits after a relative edit has been made?
 * We receive absolute edits from the ai that finds issues and suggests edits, so we would need a way to transform absolute edits into relative edits to start, and a way to undo those re
 *
 *
 * Yes, we must be able to make absolute edits at any time, but they are the only types of edits that need to be made non-sequentially
 * When we make an AE, it is as if the original audio file had or did not have the edit - so any reference to a relative edit timestamp region affected by an absolute edit must be updated.
 * So, when an absolute edit is made, that edit is appended to the edit list sequentially. The operations remain the same, but the state manager will need to be overhauled.
 *
 *
 *
 *
 *
 * SHOOOOOT - we need a way to undo relative edits in a non-sequentiall order - is that the same as making an absolute edit
 *
 *
 *
 * I need to be able to take a timestamp of any clip in its modified form and know exactly how many milliseconds of duration I am into any given reference
 */
