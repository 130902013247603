import {Flex, Icon, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.css';
import {DragDrop, StatusBar} from '@uppy/react';
import '@uppy/status-bar/dist/style.css';
import axios from 'axios';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FiSettings, FiTrash} from 'react-icons/fi';
import {useParams} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import ZCard from '../../components/abstraction_high/ZCard';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {ZEditable, ZIconButton} from '../../components/common/ComponentStyle';
import {
  FlowBody,
  FlowContainer,
  FlowFooter,
  FlowHeader,
  displayErrorToast,
  displayInfoToast,
} from '../../components/common/Structural';
import {ConfirmModal, ProjectSettingsModal} from '../../components/modals/Modal';
import SubUpgradeModal from '../../components/modals/SubUpgradeModal';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import episodeStore from '../../stores/episode-store';
import projectStore from '../../stores/project-store';
import {EPISODE, PROJECT, SUBSCRIPTION, TEAM, USER} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';
import Constants from '../../utils/constants';
import GTM from '../../utils/google-tag-manager';
import InputUtils from '../../utils/input-utils';
import UppyPodflowPlugin from '../../utils/uppy-podflow-plugin';

const devEnv = process.env.NODE_ENV === 'development';

export const Project = () => {
  const {projectId} = useParams();

  const navigate = useNavigate();
  const location = useLocation(); // get current location

  const zSetUserConfigSelectedProject = authStore((state) => state.setUserConfigSelectedProject);
  const zUser = authStore((state) => state.user);
  const zTeam = authStore((state) => state.team);
  const zSetTeam = authStore((state) => state.setTeam);
  const zSetSubscription = authStore((state) => state.setSubscription);
  const zIsLoading = appStore((state) => state.isLoading);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);
  const zProject = projectStore((state) => state.project);
  const zSetProject = projectStore((state) => state.setProject);

  const zSetEpisodes = episodeStore((state) => state.setEpisodes);
  const zAddEpisode = episodeStore((state) => state.addEpisode);
  const zEpisodes = episodeStore((state) => state.episodes);
  const zSetEpisode = episodeStore((state) => state.setEpisode);
  const zUpdateEpisode = episodeStore((state) => state.updateEpisode);

  const [stateExhaustedEpisodes, setExhaustedEpisodes] = useState(true);
  const [stateUploadProgress, setUploadProgress] = useState(false); // the upload bar just above the DragDrop (for pre/post processing)

  // Modals
  const [stateDisplayUpgradeModal, setDisplayUpgradeModal] = useState(false);
  const [stateDisplayDeleteEpisodeModalProps, setDisplayDeleteEpisodeModalProps] = useState(null);

  const {projectApi, episodeApi, userApi, subscriptionApi, teamApi} = useAPI();

  // UPLOADING (With Uppy)
  const refUpgradeModalMessage = useRef();
  const MiB = 0x10_00_00;

  /**
   * This generator transforms a deep object into URL-encodable pairs
   * to work with `URLSearchParams` on the client and `body-parser` on the server.
   */
  function* serializeSubPart(key, value) {
    if (typeof value !== 'object') {
      yield [key, value];
      return;
    }

    if (Array.isArray(value)) {
      for (const val of value) {
        yield* serializeSubPart(`${key}[]`, val);
      }

      return;
    }

    for (const [subkey, val] of Object.entries(value)) {
      yield* serializeSubPart(key ? `${key}[${subkey}]` : subkey, val);
    }
  }

  // If you want to avoid preflight requests, use URL-encoded syntax:
  // If you don't care about additional preflight requests, you can also use:
  // return JSON.stringify(data)
  // You'd also have to add `Content-Type` header with value `application/json`.
  const serialize = (data) => new URLSearchParams(serializeSubPart(null, data));

  const callbackPreUpload = useCallback(
    async (fileIDs, uppy) => {
      // Validation: Ensure only one file is uploaded
      const files = fileIDs.map((fileID) => uppy.getFile(fileID));
      if (files.length > 1) {
        displayInfoToast('Please upload only one file at a time.');
        for (const file of files) {
          uppy.removeFile(file.id);
        }

        return;
      }

      // Get file
      const file = files[0];

      // Validation: Ensure a project is selected
      if (!zProject) {
        zSetError('onDrop', 'no project selected');
        onUploadFailed();
        uppy.removeFile(file.id);
        return;
      }

      return await prepareToUploadFile(file, uppy);
    },
    [zTeam, zUser, zProject, subscriptionApi, teamApi, episodeApi],
  );

  const callbackPostUpload = useCallback(
    async (fileIDs, uppy) => {
      const {episodeId, episode} = uppy.getState();
      if (!episodeId || !episode) {
        zSetError('callbackPostUpload', 'episodeId or episode is null or undefined');
        return;
      }

      return onUploadComplete(episodeId, 1, episode);
    },
    [zUser, subscriptionApi, episodeApi],
  );

  const uppy = useMemo(
    () =>
      new Uppy({
        autoProceed: true,
        allowMultipleUploadBatches: true,
        restrictions: {
          maxNumberOfFiles: 1,
          minFileSize: 0,
          maxFileSize: 5 * 1000 * 1000 * 1000, // 5 GB
          allowedFileTypes: [
            'audio/*',
            'video/*',
            ...Constants.SUPPORTED_AUDIO_FILE_EXTENSIONS,
            ...Constants.SUPPORTED_VIDEO_FILE_EXTENSIONS,
          ],
        },
      })
        .use(UppyPodflowPlugin, {
          id: 'UppyPodflowPlugin',
          preProcessing: callbackPreUpload,
          postProcessing: callbackPostUpload,
        })
        .use(AwsS3, {
          id: 'PodflowAwsS3Plugin',

          shouldUseMultipart: (file) => file.size > 100 * MiB,

          /**
           * This method tells Uppy how to retrieve a temporary token for signing on the client.
           * Signing on the client is optional, you can also do the signing from the server.
           */
          async getTemporarySecurityCredentials({signal}) {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sts`, {signal});
            if (!response.ok) throw new Error('Unsuccessful request', {cause: response});
            return response.json();
          },

          // ========== Non-Multipart Uploads ==========

          /**
           * This method tells Uppy how to handle non-multipart uploads.
           * If for some reason you want to only support multipart uploads,
           * you don't need to implement it.
           */
          async getUploadParameters(file, options) {
            // if (typeof crypto?.subtle === 'object') {
            //   // If WebCrypto is available, let's do signing from the client.
            //   return uppy.getPlugin('PodflowAwsS3Plugin').createSignedURL(file, options);
            // }

            const {episodeId} = uppy.getState();
            const fileUDID = projectId + '___' + episodeId;

            // Send a request to our Express.js signing endpoint.
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sign-s3`, {
              method: 'POST',
              headers: {accept: 'application/json'},
              body: serialize({fileUDID: fileUDID, filename: file.name, contentType: file.type}),
              signal: options.signal,
            });

            if (!response.ok) {
              throw new Error('Unsuccessful request', {cause: response});
            }

            // Parse the JSON response.
            const data = await response.json();

            // Return an object in the correct shape.
            return {
              method: data.method,
              url: data.url,
              fields: {}, // For presigned PUT uploads, this should be left empty.
              // Provide content type header required by S3
              headers: {'Content-Type': file.type},
            };
          },

          // ========== Multipart Uploads ==========

          // The following methods are only useful for multipart uploads:
          // If you are not interested in multipart uploads, you don't need to
          // implement them (you'd also need to set `shouldUseMultipart: false` though).

          async createMultipartUpload(file, signal) {
            signal?.throwIfAborted();

            const metadata = {};

            Object.keys(file.meta || {}).forEach((key) => {
              if (file.meta[key] != null) metadata[key] = file.meta[key].toString();
            });

            const {episodeId} = uppy.getState();
            const fileUDID = projectId + '___' + episodeId;
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/s3/multipart`, {
              method: 'POST',
              // Send and receive JSON.
              headers: {accept: 'application/json'},
              body: serialize({fileUDID: fileUDID, filename: file.name, type: file.type, metadata}),
              signal,
            });

            if (!response.ok) {
              throw new Error('Unsuccessful request', {cause: response});
            }

            // Parse the JSON response.
            const data = await response.json();
            return data;
          },

          async abortMultipartUpload(file, {key, uploadId}, signal) {
            const filename = encodeURIComponent(key);
            const uploadIdEnc = encodeURIComponent(uploadId);
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/s3/multipart/${uploadIdEnc}?key=${filename}`,
              {method: 'DELETE', signal},
            );

            if (!response.ok) {
              throw new Error('Unsuccessful request', {cause: response});
            }
          },

          async signPart(file, options) {
            const {uploadId, key, partNumber, signal} = options;

            signal?.throwIfAborted();

            if (uploadId == null || key == null || partNumber == null) {
              throw new Error('Cannot sign without a key, an uploadId, and a partNumber');
            }

            const filename = encodeURIComponent(key);
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/s3/multipart/${uploadId}/${partNumber}?key=${filename}`,
              {signal},
            );

            if (!response.ok) {
              throw new Error('Unsuccessful request', {cause: response});
            }

            const data = await response.json();
            return data;
          },

          async listParts(file, {key, uploadId}, signal) {
            signal?.throwIfAborted();

            const filename = encodeURIComponent(key);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/s3/multipart/${uploadId}?key=${filename}`, {
              signal,
            });

            if (!response.ok) {
              throw new Error('Unsuccessful request', {cause: response});
            }

            const data = await response.json();
            return data;
          },

          async completeMultipartUpload(file, {key, uploadId, parts}, signal) {
            signal?.throwIfAborted();

            const filename = encodeURIComponent(key);
            const uploadIdEnc = encodeURIComponent(uploadId);
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/s3/multipart/${uploadIdEnc}/complete?key=${filename}`,
              {
                method: 'POST',
                headers: {'Content-Type': 'application/json', accept: 'application/json'},
                body: JSON.stringify({parts}),
                signal,
              },
            );

            if (!response.ok) {
              throw new Error('Unsuccessful request', {cause: response});
            }

            const data = await response.json();
            return data;
          },
        })
        .on('file-added', (file) => {
          console.log('UPPY_DEBUG: file added', file);
        })
        .on('upload', (data) => {
          const {id, fileIDs} = data;
          console.log(`UPPY_DEBUG: Starting upload ${id} for files ${fileIDs}`);
        })
        .on('progress', (progress) => {
          // progress: integer (total progress percentage)
          console.log('UPPY_DEBUG: progress', progress);
        })
        .on('upload-progress', (file, progress) => {
          // file: { id, name, type, ... }
          // progress: { uploader, bytesUploaded, bytesTotal }
          console.log('UPPY_DEBUG: upload progress', file.name, progress.bytesUploaded, progress.bytesTotal);
        })
        .on('complete', (result) => {
          console.log('UPPY_DEBUG: complete: successful files:', result.successful);
          console.log('UPPY_DEBUG: complete: failed files:', result.failed);
        })
        .on('upload-success', (file, response) => {
          console.log('UPPY_DEBUG: upload-success', file.name, response.uploadURL);
        })
        .on('upload-error', (file, error, response) => {
          console.log('UPPY_DEBUG: error with file:', file.id);
          console.log('UPPY_DEBUG: error message:', error);
        })
        .on('error', (error) => {
          console.error('UPPY_DEBUG: error:', error);
          projectApi.postError('uppy', error.message, {
            userSub: zUser?.userSub,
            url: window.location.href,
            currentTime: Date.now().toString(),
          });
        })
        .on('info-visible', () => {
          const {info} = uppy.getState();
          console.log('UPPY_DEBUG: info-visible:', info);
          if (info.length === 0) {
            return;
          }

          // display last message
          const message = info[info.length - 1]?.message;
          displayInfoToast(message, {duration: 6000});
        }),
    [callbackPreUpload, callbackPostUpload],
  );

  useEffect(() => {
    return () => uppy.close(); // Clean up the Uppy instance when the component unmounts
  }, [uppy]);

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    if (!zProject) return;
    updateDefaultProject();
  }, [zProject]);

  useEffect(() => {
    // checks if user is leaving page while upload is in progress
    const handleBeforeUnload = (event) => {
      if (stateUploadProgress > 0 && stateUploadProgress < 100) {
        // Standard for most browsers
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = 'You have an ongoing upload. Are you sure you want to leave?';
      }
    };

    if (stateUploadProgress > 0 && stateUploadProgress < 100) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [stateUploadProgress]);

  async function initialLoad() {
    zSetLoading(true);

    try {
      await Promise.all([
        zProject?.projectId !== projectId && loadProject(),
        (!zEpisodes || zEpisodes.length === 0) && loadEpisodes(),
      ]);
    } catch (e) {
      console.log('error in initialLoad', e);
    } finally {
      zSetLoading(false);
    }
  }

  async function loadProject() {
    try {
      const project = await projectApi.getProject(projectId);
      zSetProject(project);
    } catch (e) {
      console.log('error loading project', e);
      zSetError('loadProject', e.message);
    }
  }

  async function loadEpisodes() {
    try {
      const data = await episodeApi.getEpisodes();
      if (!data) {
        throw new Error('error loading episodes');
      }

      const {episodes, lastEvaluatedKey} = data;
      const currentEpisodes = zEpisodes ?? [];
      zSetEpisodes([...currentEpisodes, ...episodes]); // maintains the order of episodes
      setExhaustedEpisodes(!lastEvaluatedKey);
    } catch (e) {
      console.log('error loading episodes', e);
      zSetError('loadEpisodes', e.message);
    }
  }

  async function updateDefaultProject() {
    if (zUser.configSelectedProject === projectId || zUser.userSub !== zProject.userSub) {
      return; // must be owner of the project
    }

    try {
      const isSuccess = await userApi.updateUser(zUser.userSub, {configSelectedProject: projectId});
      zSetUserConfigSelectedProject(projectId);
      if (!isSuccess) {
        throw new Error('error updating default project');
      }
    } catch (e) {
      console.log('error updating default project', e);
    }
  }

  function navigateToEpisode(episode) {
    if (episode.gptTemplateId == 'private_transcript') {
      navigateAndAppendPath('/private_transcript' + '/' + episode.timestamp);
      return;
    }

    navigateAndAppendPath('/episodes' + '/' + episode.timestamp);
  }

  function navigateAndAppendPath(path) {
    const completePath = location.pathname + path;
    navigate(completePath); // append path to current path
  }

  async function getTeamOwnerUserSub() {
    if (!zUser.teamId) {
      return null;
    }

    let currentTeam = zTeam;
    if (!zTeam) {
      // fetch zTeam if not already fetched
      const team = await teamApi.getTeam(zUser.teamId);
      if (!team || team.length == 0) zSetError('fetchSubscription', 'Error fetching team');
      zSetTeam(team);
      currentTeam = team;
    }

    const teamOwner = TEAM.getTeamOwner(currentTeam);
    return teamOwner.userSub;
  }

  /**
   * @param {*} numUploads - number of files being uploaded
   * @param {*} numUploadsRequiredAfterUpdate (optional. default 0) - the number of uploads that will be uploaded. Need to ensure that the user will have enough uploads remaining, but don't yet want to update the user's subscription usage
   * @returns
   */
  async function updateUserSubscriptionUsage(numUploads, numUploadsRequiredAfterUpdate = 0) {
    // check for uploads remaining after being updated
    // if negative, server doesn't update secondsRemaining but returns negative number indicating user needs an upgrade
    const userSub = (await getTeamOwnerUserSub()) ?? zUser.userSub;
    const {uploadsRemaining, subItem} = await subscriptionApi.updateSubscriptionUsage(userSub, numUploads, projectId);

    if (uploadsRemaining == null || !subItem) {
      setUploadProgress(false);
      zSetError('NSREM', 'ur == null');
      return false;
    }

    // updating subscription item
    zSetSubscription(subItem);

    if (uploadsRemaining < numUploadsRequiredAfterUpdate) {
      setUploadProgress(false);

      // file uploaded exceeds remaining upload limit
      refUpgradeModalMessage.current =
        "You've exceeded your monthly upload limit for your current subscription plan, but don't skip a beat! Upgrade now to continue creating with Podflow.";

      setDisplayUpgradeModal(true);
      return false;
    }

    return true;
  }

  async function prepareToUploadFile(file, uppy) {
    try {
      setUploadProgress(true);

      // get usage for user
      const numFilesToBeUploaded = 1;
      const proceedToUpload = await updateUserSubscriptionUsage(0, numFilesToBeUploaded);
      if (!proceedToUpload) {
        uppy.removeFile(file.id);
        return;
      }

      // create episodeId (sk) (timestamp) for this upload
      const episodeId = Date.now().toString();

      // create fileName and fileExtInitial
      const fileName = file.name;
      const fileExtInitial = file.name.split('.').pop();

      // post episode to db, but archived until upload is complete
      const episode = await episodeApi.postEpisode(episodeId, {fileName, fileExtInitial, isArchived: true});
      if (!episode) {
        displayErrorToast('Something went wrong while uploading. Please contact support and try again.');
        zSetError('prepareToUploadFile', 'episode is null or undefined: PE1F');
        setUploadProgress(false);
        uppy.removeFile(file.id);
        return;
      }

      uppy.setState({episodeId, episode});
    } catch (e) {
      zSetError('prepareToUploadFile', e);
      uppy.removeFile(file.id);
      console.log('error startFileUpload(): ', e);
    } finally {
      setUploadProgress(false);
    }
  }

  async function onUploadComplete(episodeId, numFilesUploaded, episode) {
    setUploadProgress(true);

    // update episode to remove isArchived
    const isSuccess = await episodeApi.updateEpisode({isArchived: false}, episodeId);
    if (!isSuccess) {
      displayErrorToast('Something went wrong while uploading. Please contact support and try again.', {
        duration: null,
      });
      zSetError('onUploadComplete', 'episode is null or undefined: PE2F');
      setUploadProgress(false);
      return;
    }

    // unarchive and set the episode in zustand
    episode = {...episode, isArchived: false};
    zSetEpisode(episode);
    zAddEpisode(episode);

    // get usage for user
    const subUpdateSuccess = await updateUserSubscriptionUsage(numFilesUploaded);
    if (!subUpdateSuccess) {
      episodeApi.postError('onUploadComplete', 'SubUpdateFailed. How?', {
        userSub: zUser?.userSub,
        url: window.location.href,
        currentTime: Date.now().toString(),
      }); // posting this error async. Shouldn't affect user, but we want to know about it.
    }

    zSetLoading(false);
    setUploadProgress(false);

    new GTM().conversionFileUpload(zUser.userSub, zUser.email);
    navigateToEpisode(episode);
  }

  async function startYoutubeFileUpload(youtubeVideoUrl, episode) {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/lambda/invokeYoutubeToS3', {
        projectId,
        episodeId: episode.timestamp,
        url: youtubeVideoUrl,
      });

      if (response.status != 200) {
        throw new Error('failed to save feedback');
      }
    } catch (e) {
      console.log('YoutubeUploadModal submitUrl error', e);
      return false;
    }

    onUploadComplete(episode);
  }

  function onUploadFailed() {
    setUploadProgress(false);
    zSetLoading(false);
  }

  const UploadOptions = () => (
    <Stack spacing={0} pt={2}>
      <Stack height={2} alignItems={'center'} spacing={0}>
        {stateUploadProgress && <ZProgressBar value={undefined} size={'xs'} isIndeterminate={true} />}
      </Stack>
      <DragDrop
        uppy={uppy}
        width={'100%'}
        note="Upload an episode! Audio or video up to 5GB."
        locale={{
          strings: {
            dropHereOr: 'Drop here or %{browse}',
            browse: 'browse',
          },
        }}
      />
      <Stack spacing={0} pt={2}>
        <StatusBar uppy={uppy} hideUploadButton={true} hidePauseResumeButton={true} />
      </Stack>
    </Stack>
  );

  async function updateEpisodeFileName(newTitle, episodeId) {
    zSetLoading('Updating...');
    newTitle = newTitle.trim();

    // validate input
    if (!InputUtils.validateInput(newTitle)) {
      zSetLoading(false);
      return false;
    }

    const isSuccess = await episodeApi.updateEpisode({fileName: newTitle}, episodeId);
    if (!isSuccess) {
      zSetError(
        'updateEpisodeFileName',
        {newTitle, episodeId},
        'Something went wrong while updating your episode title.',
      );
    } else {
      zUpdateEpisode(episodeId, {fileName: newTitle});
    }

    zSetLoading(false);
  }

  async function onArchiveLibraryItem(episodeId, index) {
    zSetLoading('Deleting...');

    const isSuccess = await episodeApi.updateEpisode({isArchived: true}, episodeId);
    if (!isSuccess) {
      zSetError('onDeleteLibraryItem', {episodeId, index}, 'Something went wrong while deleting your episode.');
    } else {
      zUpdateEpisode(episodeId, {isArchived: true});
    }

    zSetLoading(false);
  }

  const EpisodeLibraryItem = ({item: episode = {}, index, disableNavigation}) => (
    <>
      <Tr>
        <Td borderLeftRadius="10px">
          {new Date(Number(episode.timestamp ?? 'invalid')).toLocaleString('en-US', {
            year: 'numeric', // Optional: You can adjust these options
            month: 'numeric', // Optional: You can adjust these options
            day: 'numeric', // Optional: You can adjust these options
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // Optional: Set to false if you want 24-hour time
          })}
        </Td>
        <Td width={'100%'}>
          <Stack display="flex" flexDirection="row" align="center" justify="flex-start">
            <ZEditable
              defaultValue={episode.fileName}
              onSubmit={(value) => {
                updateEpisodeFileName(value, episode.timestamp);
              }}
              maxWidth={'33vw'}
            />
          </Stack>
        </Td>
        <Td borderRightRadius="10px">
          <Flex direction={'row'} alignItems={'center'} gap={2}>
            <ButtonSecondary onClick={() => navigateToEpisode(episode)} isDisabled={disableNavigation}>
              View Episode
            </ButtonSecondary>
            <ZIconButton
              variant="outline"
              onClick={() =>
                setDisplayDeleteEpisodeModalProps({episodeId: episode.timestamp, fileName: episode.fileName, index})
              }
              colorScheme="white"
              aria-label="Delete Element"
              icon={<Icon as={FiTrash} />}
              isDisabled={disableNavigation}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  );

  const EpisodeLibrary = ({disableNavigation, ...props}) => (
    <ZCard variant={'outline'} padding={0} {...props}>
      <Stack justify="flex-start" align="center" width="100%">
        <TableContainer overflowY="scroll" width={'100%'} borderRadius={'10px'}>
          <Table variant="simple" width={'100%'}>
            <Thead width={'100%'}>
              <Tr>
                {/* <Th>Status</Th> */}
                <Th>Upload Time</Th>
                <Th>Title</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {zEpisodes
                .filter((x) => !x.isArchived)
                .map((item, idx) => (
                  <EpisodeLibraryItem
                    item={item}
                    index={idx}
                    key={item.projectId + '___' + item.timestamp}
                    disableNavigation={disableNavigation}
                  />
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        {!stateExhaustedEpisodes && (
          <Flex justifyContent={'end'} width={'100%'}>
            <ButtonPrimary
              my={4}
              mx={6}
              minHeight="40px"
              isLoading={zIsLoading}
              loadingText="Loading More..."
              onClick={loadEpisodes}
            >
              Load More
            </ButtonPrimary>
          </Flex>
        )}
      </Stack>
    </ZCard>
  );

  return (
    <FlowContainer>
      {process.env.NODE_ENV === 'development' && false && (
        <ButtonPrimary
          onClick={() => {
            async function sendIt() {
              const subUpdateSuccess = await updateUserSubscriptionUsage(1);
              console.log('test subUpdateSuccess', subUpdateSuccess);
            }
            sendIt();
          }}
        >
          INCREMENT USER SUB USAGE
        </ButtonPrimary>
      )}

      <FlowHeader
        title={zProject?.name ? zProject.name : 'Project...'}
        rightComponent={<ProjectSettingsHeaderComponent projectApi={projectApi} />}
      />

      <FlowBody>
        <Stack pt={2} spacing={0}>
          <UploadOptions />

          <Stack pt={4}>
            <EpisodeLibrary disableNavigation={stateUploadProgress} />
          </Stack>
        </Stack>
        {/* <ZTabs
          elements={[
            {
              title: "Overview",
              child: (
                <Stack>
                  <UploadOptions />
                  <EpisodeLibrary />
                </Stack>
              ),
            },
            {
              title: "Sources",
              child: <Text>Sources</Text>,
            },
            {
              title: "Destinations",
              child: <Text>Destinations</Text>,
            },
          ]}
        /> */}

        {/* <YoutubeUploadModal onSubmit={prepareToUploadYoutubeUrl} /> */}
        <SubUpgradeModal
          message={refUpgradeModalMessage.current}
          openModal={stateDisplayUpgradeModal}
          closeModal={() => {
            setDisplayUpgradeModal(false);
          }}
        />
        <ConfirmModal
          positiveText="Delete Episode"
          positiveCallback={() => {
            onArchiveLibraryItem(
              stateDisplayDeleteEpisodeModalProps.episodeId,
              stateDisplayDeleteEpisodeModalProps.index,
            );
          }}
          bodyText={
            'This episode, ' +
            stateDisplayDeleteEpisodeModalProps?.fileName +
            ', will be permanently deleted. Are you sure you want to continue?'
          }
          openThisModal={stateDisplayDeleteEpisodeModalProps}
          closeModal={() => {
            setDisplayDeleteEpisodeModalProps(null);
          }}
        />
      </FlowBody>
      <FlowFooter />
    </FlowContainer>
  );
};

const ProjectSettingsHeaderComponent = ({projectApi}) => {
  const zUser = authStore((state) => state.user);
  const zProject = projectStore((state) => state.project);
  const zUpdateProject = projectStore((state) => state.updateProject);
  const zBreadcrumbs = appStore((state) => state.breadcrumbs);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);

  const navigate = useNavigate();

  const [stateDisplayProjectSettingsModal, setDisplayProjectSettingsModal] = useState();
  const [stateDisplayConfirmDeleteModalProps, setDisplayConfirmDeleteModalProps] = useState();

  if (!zProject) return null;
  if (zProject.userSub !== zUser.userSub) return null; // must be owner of the project

  async function archiveProject() {
    if (!zProject) return;

    zSetLoading('Deleting...');
    const isSuccess = await projectApi.updateProject(zProject.projectId, {isArchived: true});
    if (!isSuccess) {
      zSetError('archiveProject', {zProject}, 'Something went wrong while deleting your project.');
    } else {
      zUpdateProject({isArchived: true}, true);
    }
    zSetLoading(false);
    exitPage();
  }

  function exitPage() {
    const targetBreadCrumb = zBreadcrumbs[zBreadcrumbs.length - 2];
    if (targetBreadCrumb) navigate(targetBreadCrumb.path);
  }

  return (
    <Stack spacing={0}>
      <ZIconButton
        icon={<Icon as={FiSettings} />}
        tooltipText={'Project Settings'}
        onClick={() => setDisplayProjectSettingsModal({})}
      />

      <ProjectSettingsModal
        openThisModal={stateDisplayProjectSettingsModal}
        projectApi={projectApi}
        zUser={zUser}
        zProject={zProject}
        zUpdateProject={zUpdateProject}
        zSetLoading={zSetLoading}
        zSetError={zSetError}
        openConfirmArchiveModal={() => setDisplayConfirmDeleteModalProps({projectName: zProject?.name})}
      />

      <ConfirmModal
        titleText={'Are you sure?'}
        bodyText={
          'This project, ' +
          stateDisplayConfirmDeleteModalProps?.projectName +
          ', will be permanently deleted. Are you sure you want to continue?'
        }
        positiveText="Continue"
        positiveCallback={() => archiveProject()}
        openThisModal={stateDisplayConfirmDeleteModalProps}
        closeModal={() => setDisplayConfirmDeleteModalProps(null)}
      />
    </Stack>
  );
};
