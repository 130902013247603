import {produce} from 'immer';
import {isEqual} from 'lodash';
import {create} from 'zustand';

const defaultGptStatus = {
  allStagesComplete: false,
  percentageComplete: 0.0,
  runningStages: [],
  errorStages: undefined,
};

/**
 * @typedef {typeof defaultGptStatus} Status
 */

function getStateDefaults(state) {
  // put here the properties you want to retain
  return {
    episodes: state.episodes,
    saveableTemplates: [],
    speakers: [],
    formInputs: [],
    status: defaultGptStatus,
    currentPlayerId: 'enhanced',
    enhancePlaybackProgress: 0,
    transcriptJobProgress: 0,
    transcriptFormatSettings: {showSpeakers: true, showTimestamps: true, style: 'default'},
    reqData: {},
    gptReqPromptsFinished: {},
    chats: {},
    promptModHistory: [],
  };
}

/**
 * When a template is updated its pk needs to be modified so that it never updates a template from a prior episode
 * @param {object} draft - immer draft
 * @param {string} templateSk
 * @param {string} ogTemplatePk - the template pk before operations were performed on it
 */
function onTemplateUpdated(draft, templateSk) {
  if (!draft.episode || !draft.saveableTemplates || !draft.templateMeta) return;
  const ogTemplatePk = draft.templates[templateSk].pk;
  const requiredTemplatePkOnUpdate = draft.episode.projectId + '___' + draft.episode.timestamp;

  // add sks to saveableTemplates for automatic push to the cloud i.e. addSaveableTemplate(templateSk);
  if (!draft.saveableTemplates.some((template) => template.sk === templateSk))
    draft.saveableTemplates.push({sk: templateSk, action: 'update'});

  // Update templateMeta templates list - if pk of template changed
  if (ogTemplatePk !== requiredTemplatePkOnUpdate) {
    const index = draft.templateMeta.templates.findIndex((t) => t.sk === templateSk);
    if (index === -1) throw new Error(`Could not find template with sk ${templateSk} in templateMeta.templates`);

    draft.templateMeta.templates[index] = {
      ...draft.templateMeta.templates[index],
      pk: requiredTemplatePkOnUpdate,
    };

    // pk is set to current episode uuid so that changing the template doesn't affect other episodes
    draft.templates[templateSk].pk = requiredTemplatePkOnUpdate;
  }
}

/**
 * Error
 * @typedef {object} Error
 *
 * Speaker
 * @typedef {object} Speaker
 *
 * Episode
 * @typedef {object} Episode
 * @property {string} timestamp
 * @property {string} projectId
 * @property {string} episodeId
 * @property {any} inputsCustom
 *
 * Transcript
 * @typedef {object} Word
 * @property {number} confidence
 * @property {number} end
 * @property {number} start
 * @property {string} speaker
 * @property {string} text
 *
 * @typedef {object} Utterance
 * @property {string} speaker
 * @property {number} confidence
 * @property {number} end
 * @property {string} label
 * @property {string} speaker
 * @property {number} start
 * @property {string} text
 * @property {Word[]} words
 *
 * @typedef {string} SpeakerOption
 *
 * @typedef {object} Transcript
 * @property {Utterance[]} utterances
 * @property {SpeakerOption[]} speakerOptions
 * @property {string} acoustic_model
 * @property {number} audio_duration
 * @property {*} audio_end_at
 * @property {*} audio_start_from
 * @property {string} audio_url
 * @property {boolean} auto_chapters
 * @property {boolean} auto_highlights
 * @property {*} auto_highlights_result
 * @property {*} boost_param
 * @property {*} chapters
 * @property {number} confidence
 * @property {boolean} content_safety
 * @property {{status: string, results: number[], summary: object}} content_safety_labels
 * @property {*} custom_spelling
 * @property {boolean} custom_topics
 * @property {boolean} disfluencies
 * @property {boolean} dual_channel
 * @property {*} entities
 * @property {boolean} entity_detection
 * @property {boolean} filter_profanity
 * @property {boolean} format_text
 * @property {boolean} iab_categories
 * @property {{status: string, results: number[], summary: object}} iab_categories_result
 * @property {string} id
 * @property {string} language_code
 * @property {boolean} language_detection
 * @property {string} language_model
 * @property {boolean} punctuate
 * @property {boolean} redact_pii
 * @property {boolean} redact_pii_audio
 * @property {*} redact_pii_audio_quality
 * @property {*} redact_pii_policies
 * @property {*} redact_pii_sub
 * @property {boolean} sentiment_analysis
 * @property {*} sentiment_analysis_results
 * @property {SpeakerOption[]} speakerOptions
 * @property {boolean} speaker_labels
 * @property {*} speakers_expected
 * @property {*} speech_model
 * @property {*} speech_threshold
 * @property {boolean} speed_boost
 * @property {string} status
 * @property {boolean} summarization
 * @property {*} summary
 * @property {*} summary_model
 * @property {*} summary_type
 * @property {string} text
 * @property {*} throttled
 * @property {any[]} topics
 * @property {Utterance[]} utterances
 * @property {boolean} webhook_auth
 * @property {*} webhook_auth_header_name
 * @property {*} webhook_status_code
 * @property {string} webhook_url
 * @property {any[]} word_boost
 * @property {Word[]} words
 *
 * Template
 * @typedef {string} PromptId
 * @typedef {{[PromptId]: boolean}} CompletedPrompts
 *
 * @typedef {object} Block
 * @property {number} pk
 * @property {number} index
 * @property {boolean} isComplete
 * @property {string} body
 *
 * @typedef {"post" | "update" | "delete"} TemplateAction
 *
 * @typedef {object} Template
 * @property {string} sk
 * @property {string} curatedId
 * @property {Block[]} blocks
 * @property {TemplateAction} action
 * @property {boolean} isNewTemplate
 *
 * @typedef {object} TemplateMeta
 *
 * @typedef {object} TemplateBlock
 * @property {string} sectionId
 * @property {string} elementId
 * @property {number} index
 * @property {boolean} hasContent
 *
 * FormInput
 * @typedef {object} FormInput
 *
 * EpisodeInput
 * @typedef {string} EpisodeInputId
 * @typedef {string} EpisodeInput
 * @property {"string" | "number" | "boolean"} type
 * @property {string} value
 * @property {boolean} required
 * @typedef {{[EpisodeInputId]: EpisodeInput}} EpisodeInputs
 *
 * GPTRequest
 * @typedef {object} GPTRequest
 *
 * GPTRequestData
 * @typedef {object} GPTRequestData
 *
 * Prompt
 * @typedef {string} PromptId
 * @typedef {"success" | "failed"} PromptStatus
 *
 * EnhanceJob
 * @typedef {object} EnhanceJob
 * @property {string} enhancedUrl
 * @property {boolean} clipUrlOriginal
 * @property {boolean} clipUrlEnhanced
 * @property {number} progress
 *
 * AudioState
 * @typedef {"unloaded" | "loaded" | "playing" | "paused"} AudioState
 *
 * ChatHistory
 * @typedef {any} ChatHistory
 */

/**
 * @typedef {object} EpisodeStore
 * @property {Episode[]} episodes
 * @property {(episodes: Episode[]) => void} setEpisodes
 * @property {(episode: Episode) => void} addEpisode
 * @property {(episode: Episode) => void} removeEpisode
 * @property {(episodeId: Episode['episodeId'], attribute: Partial<Episode>, updateEpisodeInLibrary?: boolean) => void} updateEpisode
 * @property {Episode} episode
 * @property {(episode: Episode) => void} setEpisode prevent unnecessary renders by checking if the episode is the same before setting
 * @property {(episode: Episode) => void} addEpisode
 * @property {() => void} setEpisodeUserRequestedEnhance
 * @property {() => void} setEpisodeResEnhancedUrl
 * @property {(inputsCustom: Episode['inputsCustom']) => void} setEpisodeInputsCustom
 * @property {boolean} episodePollingPaused
 * @property {(episodePollingPaused: boolean) => void} setEpisodePollingPaused
 * @property {string} programmaticallySelectTabByKey use to select a tab programatically, rather than being update when a tab is selected
 * @property {(programmaticallySelectTabByKey: boolean) => void} setProgrammaticallySelectTabByKey
 * @property {string} selectedTabKey
 * @property {(selectedTabKey: boolean) => void} setSelectedTabKey
 * @property {boolean} triggerInitTabElements
 * @property {() => void} setTriggerInitTabElements
 * @property {boolean} forceShowEpPrefs
 * @property {(forceShowEpPrefs: boolean) => void} setForceShowEpPrefs
 * @property {boolean} isSaving
 * @property {(isSaving: boolean) =>} setSaving
 * @property {Status} status
 * @property {(status: Status) => void} setStatus
 * @property {Transcript} transcript
 * @property {(transcript: Transcript) => void} setTranscript
 * @property {(utterances: Utterance[]) => void} setTranscriptUtterances
 * @property {(index: number, newUtterance: Utterance) => void} updateUtterance
 * @property {any} originalUtteranceSpeakerMapping
 * @property {(originalUtteranceSpeakerMapping: any) => void} setOriginalUtteranceSpeakerMapping: (originalUtteranceSpeakerMapping) => set(() => ({ originalUtteranceSpeakerMapping })),
 * @property {Speaker[]} speakers
 * @property {(speakers: Speakers[]) => void} setSpeakers
 * @property {<R>(utterances: Utterance[], returnSpeakerOptions?: R) => R ? SpeakerOption[] : void} updateSpeakerConfigByUtterances TODO
 * @property {(speakerOptions: SpeakerOption[]) => void} setTranscriptSpeakerOptions
 * @property {boolean} transcriptShowSpeakers
 * @property {(transcriptShowSpeakers: boolean) => void} setTranscriptShowSpeakers
 * @property {boolean} transcriptShowTimestamps
 * @property {(transcriptShowTimestamps: boolean) => void} setTranscriptShowTimestamps
 * @property {boolean} transcriptSaveable
 * @property {(transcriptSaveable: boolean) => void} setTranscriptSaveable
 * @property {number} transcriptJobProgress
 * @property {(transcriptJobProgress: number) => void} setTranscriptJobProgress
 * @property {any} transcriptElementSelected
 * @property {(transcriptElementSelected: any) => void} setTranscriptElementSelected

 * @property {CompletedPrompts} completedPrompts
 * @property {(completedPrompts: CompletedPrompts) => void} setCompletedPrompts
 * @property {(promptId: PromptId) => void} addCompletedPrompt

 * @property {Template[]} saveableTemplates must be unique, this value is maintained in this episodeStore when templates and templateMeta are updated
 * @property {() => void} clearSaveableTemplates
 * @property {TemplateBlock | null} templateBlockSelected
 * @property {() => void} resetTemplateBlockSelected
 * @property {(sectionId: TemplateBlock['sectionId'], elementId: TemplateBlock['elementId'], index: TemplateBlock['index'], hasContent?: TemplateBlock['hasContent']) => void} setTemplateBlockSelected
 * @property {TemplateMeta} templateMeta
 * @property {(templateMeta: TemplateMeta) => void} setTemplateMeta
 * @property {Template[]} templates
 * @property {(templates: Template[]) => void} setTemplates
 * @property {(template: Template) => void} addTemplate
 * @property {(templateSk: Template['sk']) => void} deleteTemplate
 * @property {(templateSk: Template['sk'], attributes: Partial<Template>) => void} updateTemplate
 * @property {(templateSk: Template['sk'], block: Block, index: number, isUserDefinedPromptBlock?: boolean) => void} addTemplateBlock
 * @property {(templateSk: Template['sk'], blockPk: Block['pk']) => void} deleteTemplateBlock
 * @property {(templateSk: Template['sk'], blockPkToUpdate: Block['pk'], updatedBlock: Block) => void} setTemplateBlock
 * @property {(templateSk: Template['sk'], blockPkToUpdate: Block['pk'], updatedBlock: Block) => void} replaceTemplateBlock
 * @property {(templateSk: Template['sk'], blockPk: Block['pk'], attributes: Partial<Block>) => void} updateTemplateBlock
 * @property {(templateSk: Template['sk'], updates: {blockPk: Block['pk'], attributes: Partial<Block>}[]) => void} updateTemplateBlocks updates multiple blocks at once. Updates is an array of objects with blockPk and attributes. example updates: [{blockPk: "pk_123", attributes: {isComplete: true, body: "new body"}}]
 * @property {(templateSk: Template['sk'], reorderedBlockPks: Block['pk'][]) => void} updateTemplateBlockIndices

 * @property {FormInput[]} formInputs These are copies of the form inputs from prompt-input table, as stored in the template and used to create form
 * @property {(formInputs: FormInput[]) => void} setFormInputs
 * @property {EpisodeInputs} epInputs This is the inputs object that is output from the form. Data here can be used in the export and in gpt requests.
 * @property {(epInputs: EpisodeInputs) => void} setEpInputs
 * @property {(inputId: EpisodeInputId, input: EpisodeInput) => void} updateEpInput
 * @property {number} eventFormInputRefresh this is a number that is incremented to trigger a refresh
 * @property {() => void} triggerFormInputRefresh

 * @property {GPTRequest} gptReq
 * @property {(gptReq: GPTRequest) => void} setGptReq
 * @property {{[PromptId]: PromptStatus}} gptReqPromptsFinished
 * @property {(promptStatuses: {[PromptId]: PromptStatus}) => void} batchUpdateGptReqPromptsFinished
 * @property {GPTRequestData} gptReqDataMap
 * @property {(gptReqDataMap: GPTRequestData) => void} setGptReqDataMap
 * @property {(attributes: Partial<GPTRequestData>) => void} updateGptReqDataMap
 * @property {boolean} userDefinedNewPrompt
 * @property {(userDefinedNewPrompt: boolean) => void} setUserDefinedNewPrompt
 * @property {boolean} retryingFailedRequests
 * @property {(retryingFailedRequests: boolean) => void} setRetryingFailedRequests

 * @property {EnhanceJob} enhanceJob
 * @property {(enhanceJob: EnhanceJob) => void} setEnhanceJob
 * @property {boolean} enhanceEnabled
 * @property {(enhanceEnabled: boolean) => void} setEnhanceEnabled
 * @property {boolean} enhanceDownloading
 * @property {(enhanceDownloading: boolean) => void} setEnhanceDownloading
 * @property {string} currentPlayerId: "enhanced",
 * @property {(currentPlayerId) => void} setCurrentPlayerId
 * @property {AudioState} audioState
 * @property {(audioState: AudioState) => void} setAudioState
 * @property {number} enhancePlaybackProgress
 * @property {(enhancePlaybackProgress: number) => void} setEnhancePlaybackProgress

 * @property {ChatHistory} chatHistory
 * @property {(chatHistory: ChatHistory) => void} setChatHistory

 * @property {() => void} nukeEpisodeStore sets every state value to null, expect those specified in getStateDefaults
 */

/**
 * @param {(partial: Partial<EpisodeStore> | (state: EpisodeStore) => Partial<EpisodeStore>, replace?: boolean | undefined) => void} set
 * @param {() => EpisodeStore} get
 * @param {import('zustand').StoreApi<EpisodeStore>} api
 * @returns {EpisodeStore}
 */
const episodeStore = (set, get) => ({
  episodes: [],
  setEpisodes: (episodes) => set((state) => ({episodes})),
  addEpisode: (episode) =>
    set((state) => {
      let episodesCopy = [...state.episodes];
      let episodeExists = episodesCopy.some((ep) => isEqual(ep, episode));
      if (!episodeExists) {
        episodesCopy.unshift(episode); // add to front of array
      }
      return {episodes: episodesCopy};
    }),
  removeEpisode: (episode) =>
    set((state) => {
      let episodesCopy = [...state.episodes];
      let episodeIndex = episodesCopy.findIndex((ep) => isEqual(ep, episode));
      if (episodeIndex !== -1) {
        episodesCopy.splice(episodeIndex, 1);
      }
      return {episodes: episodesCopy};
    }),
  updateEpisode: (episodeId, attributes, updateEpisodeInLibrary = true) =>
    set((state) => {
      let newState = {};
      if (updateEpisodeInLibrary) {
        const updatedEpisodes = state.episodes.map((episode) =>
          episode.timestamp === episodeId ? {...episode, ...attributes} : episode,
        );
        newState.episodes = updatedEpisodes;
      }

      if (state.episode && state.episode.timestamp === episodeId) {
        newState.episode = {...state.episode, ...attributes};
      }
      return newState;
    }),

  episode: null,
  setEpisode: (episode) => {
    // prevent unnecessary renders by checking if the episode is the same before setting
    const currentEpisode = get().episode;
    if (!isEqual(currentEpisode, episode)) {
      console.log('zstore episode update');
      set({episode});
    }
  },
  addEpisode: (episode) =>
    set((state) => {
      let episodesCopy = [...state.episodes];
      let episodeExists = episodesCopy.some((ep) => isEqual(ep, episode));
      if (!episodeExists) {
        episodesCopy.unshift(episode); // Adds episode to the beginning of the array
      }
      return {episodes: episodesCopy};
    }),

  setEpisodeUserRequestedEnhance: () => {
    set(
      produce((draft) => {
        draft.episode.userRequestedEnhance = true;
      }),
    );
  },
  setEpisodeResEnhancedUrl: () => {
    set(
      produce((draft) => {
        draft.episode.resEnhancedUrl = true;
      }),
    );
  },
  setEpisodeInputsCustom: (inputsCustom) => {
    set(
      produce((draft) => {
        draft.episode.inputsCustom = inputsCustom;
      }),
    );
  },
  episodePollingPaused: false,
  setEpisodePollingPaused: (episodePollingPaused) => {
    console.log('zstore setEpisodePollingPaused', episodePollingPaused);
    set((state) => ({episodePollingPaused}));
  },

  programmaticallySelectTabByKey: null, // use to select a tab programatically, rather than being update when a tab is selected
  setProgrammaticallySelectTabByKey: (programmaticallySelectTabByKey) =>
    set((state) => ({programmaticallySelectTabByKey})),

  selectedTabKey: null,
  setSelectedTabKey: (selectedTabKey) => set((state) => ({selectedTabKey})),

  triggerInitTabElements: false,
  setTriggerInitTabElements: () => set((state) => ({triggerInitTabElements: !state.triggerInitTabElements})),

  forceShowEpPrefs: false,
  setForceShowEpPrefs: (forceShowEpPrefs) => set((state) => ({forceShowEpPrefs})),

  scrollToBlockPk: null,
  setScrollToBlockPk: (scrollToBlockPk) => set((state) => ({scrollToBlockPk})),

  isSaving: false,
  setSaving: (isSaving) => set((state) => ({isSaving})),

  status: defaultGptStatus,
  setStatus: (status) => set((state) => ({status})),

  transcript: null,
  setTranscript: (transcript) => {
    console.log('zstore setTranscript');
    if (!transcript || !transcript.utterances || transcript.utterances.length === 0) {
      console.log('setting null transcript, or empty utterances');
      set((state) => ({transcript}));
      return;
    }

    // update speaker config
    const updateSpeakerConfigByUtterances = get().updateSpeakerConfigByUtterances;
    const speakerOptions = updateSpeakerConfigByUtterances(transcript.utterances, true);
    transcript.speakerOptions = speakerOptions;

    set((state) => ({transcript}));
  },
  setTranscriptUtterances: (utterances) => {
    console.log('zstore setTranscriptUtterances');
    const updateSpeakerConfigByUtterances = get().updateSpeakerConfigByUtterances;
    updateSpeakerConfigByUtterances(utterances);

    set((state) => ({
      ...state,
      transcript: {
        ...state.transcript,
        utterances,
      },
    }));
  },
  updateUtterance: (index, newUtterance) => {
    console.log('zstore updateUtterance');
    const utterancesCopy = [...get().transcript.utterances];
    utterancesCopy[index] = newUtterance;

    set((state) => ({
      ...state,
      transcript: {
        ...state.transcript,
        utterances: utterancesCopy,
      },
    }));
  },

  originalUtteranceSpeakerMapping: null,
  setOriginalUtteranceSpeakerMapping: (originalUtteranceSpeakerMapping) =>
    set(() => ({originalUtteranceSpeakerMapping})),
  speakers: [],
  setSpeakers: (speakers) => set(() => ({speakers})),
  updateSpeakerConfigByUtterances: (utterances, returnSpeakerOptions = false) => {
    const speakers = calculateSpeakers(utterances);
    const setSpeakers = get().setSpeakers;
    setSpeakers(speakers);

    const uniqueSpeakersFromUser = getSubmittedGuestAndHostNames(get().epInputs); // provided in episode details submission
    const setTranscriptSpeakerOptions = get().setTranscriptSpeakerOptions;

    const speakerOptions = Array.from(
      new Set([...(get().transcript?.speakerOptions ?? []), ...uniqueSpeakersFromUser, ...speakers]),
    );
    if (returnSpeakerOptions) return speakerOptions;
    setTranscriptSpeakerOptions(speakerOptions);
  },
  setTranscriptSpeakerOptions: (speakerOptions) => {
    console.log('zstore setTranscriptSpeakerOptions');
    if (!get().transcript) return;

    set((state) => ({
      ...state,
      transcript: {
        ...state.transcript,
        speakerOptions,
      },
    }));
  },
  transcriptFormatSettings: {showSpeakers: true, showTimestamps: true, style: 'default'},
  updateTranscriptFormatSettings: (attributes) =>
    set((state) => ({transcriptFormatSettings: {...state.transcriptFormatSettings, ...attributes}})),
  setTranscriptFormatSettings: (transcriptFormatSettings) => set((state) => ({transcriptFormatSettings})),
  transcriptSaveable: false,
  setTranscriptSaveable: (transcriptSaveable) => set((state) => ({transcriptSaveable})),
  transcriptJobProgress: 0,
  setTranscriptJobProgress: (transcriptJobProgress) => set((state) => ({transcriptJobProgress})),
  transcriptElementSelected: null,
  setTranscriptElementSelected: (transcriptElementSelected) => set((state) => ({transcriptElementSelected})),

  completedPrompts: {},
  setCompletedPrompts: (completedPrompts) => set((state) => ({completedPrompts})),
  addCompletedPrompt: (promptId) =>
    set(
      produce((draft) => {
        draft.completedPrompts[promptId] = true;
      }),
    ),

  saveableTemplates: [], // list of objects. {sk, isNewTemplate), must be unique // this value is maintained in this episodeStore when templates and templateMeta are updated
  clearSaveableTemplates: () =>
    set(
      produce((state) => {
        state.saveableTemplates = [];
      }),
    ),

  /** {sectionId, elementId, index} */
  templateBlockSelected: null,
  resetTemplateBlockSelected: () => set((state) => ({templateBlockSelected: null})),
  setTemplateBlockSelected: (sectionId, elementId, index, hasContent = true) =>
    set((state) => {
      if (
        state.templateBlockSelected?.sectionId === sectionId &&
        state.templateBlockSelected?.elementId === elementId &&
        state.templateBlockSelected?.index === index &&
        state.templateBlockSelected?.hasContent === hasContent
      ) {
        // If the new sectionId and elementId are the same as the old ones, return the old state
        // This will prevent a re-render because the state has not changed
        return state;
      } else {
        // If the new sectionId or elementId is different, update the state
        return {...state, templateBlockSelected: {sectionId, elementId, index, hasContent}};
      }
    }),

  templateMeta: null,
  setTemplateMeta: (templateMeta) => set((state) => ({templateMeta})),
  templates: null,
  setTemplates: (templates) => set((state) => ({templates})),
  addTemplate: (template) =>
    set(
      produce((draft) => {
        // add sks to saveableTemplates for automatic push to the cloud i.e. addSaveableTemplate(templateSk);
        if (!draft.saveableTemplates.some((t) => t.sk === template.sk))
          draft.saveableTemplates.push({sk: template.sk, action: 'post'});

        draft.templates[template.sk] = template; // updating templates
        // if template sk already exists in templateMeta, do nothing. Else add it to templateMeta
        if (!draft.templateMeta.templates.some((t) => t.sk === template.sk))
          draft.templateMeta.templates.push({pk: template.pk, sk: template.sk}); // updating templatesMeta
      }),
    ),
  deleteTemplate: (templateSk) =>
    set(
      produce((draft) => {
        // check that the template attribute curatedId is false
        if (draft.templates[templateSk].curatedId) throw new Error('Cannot delete a curated template');

        // add sks to saveableTemplates for automatic push to the cloud i.e. addSaveableTemplate(templateSk);
        if (!draft.saveableTemplates.some((t) => t.sk === templateSk))
          draft.saveableTemplates.push({sk: templateSk, action: 'delete'});

        // delete template from templates
        delete draft.templates[templateSk];

        // delete template from templateMeta
        const index = draft.templateMeta.templates.findIndex((t) => t.sk === templateSk);
        if (index === -1) throw new Error(`Could not find template with sk ${templateSk} in templateMeta.templates`);
        draft.templateMeta.templates.splice(index, 1);

        // determine the sk of the tab to select after the current tab is deleted
        let tabKeyToSelect = null;
        // select the first tab that isn't curated
        for (const templateMeta of draft.templateMeta.templates) {
          const template = draft.templates[templateMeta.sk];
          if (template && !template.curatedId) {
            tabKeyToSelect = templateMeta.sk;
            break;
          }
        }
        // If there are no more user defined templates, use the sk of the first template in templateMeta
        if (tabKeyToSelect === null && draft.templateMeta.templates.length > 0) {
          tabKeyToSelect = draft.templateMeta.templates[0].sk;
        }

        draft.triggerInitTabElements = !draft.triggerInitTabElements; // this is to trigger a refresh of tab element data
        draft.programmaticallySelectTabByKey = tabKeyToSelect; // this selects the tab based on the sk of the newly refreshed tab elements
        draft.selectedTabKey = tabKeyToSelect; // also doing this so that tabs are rerendered
      }),
    ),
  updateTemplate: (templateSk, attributes) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);

        // Update the template in the templates map.
        const updatedTemplate = {...draft.templates[templateSk], ...attributes};
        draft.templates[templateSk] = updatedTemplate;
      }),
    );
  },
  addTemplateBlock: (templateSk, block, index, isUserDefinedPromptBlock = null) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);

        const blocks = draft.templates[templateSk].blocks;

        // Increment index of all elements after the new one
        for (let key in blocks) {
          if (blocks[key].index >= index) {
            blocks[key].index += 1;
          }
        }

        // Directly assign the index to the new block and add it
        block.index = index;
        blocks[block.pk] = block;

        // triggering gpt init to handle new user defined prompt
        draft.userDefinedNewPrompt = isUserDefinedPromptBlock;
      }),
    );
  },
  deleteTemplateBlock: (templateSk, blockPk) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);

        const blocks = draft.templates[templateSk].blocks;

        if (!blocks[blockPk]) {
          return; // If the block doesn't exist, return early
        }
        const deletedIndex = blocks[blockPk].index;
        delete blocks[blockPk]; // Remove the block

        // Decrement index of all blocks after the deleted one
        for (let key in blocks) {
          if (blocks[key].index > deletedIndex) {
            blocks[key].index -= 1;
          }
        }
      }),
    );
  },
  setTemplateBlock: (templateSk, blockPkToUpdate, updatedBlock) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);

        // if the updatedBlock has a different ID, update and replace block
        if (updatedBlock.pk && updatedBlock.pk !== blockPkToUpdate) {
          // Delete the old block
          delete draft.templates[templateSk].blocks[blockPkToUpdate];
          // Assign the new block to the new ID
          draft.templates[templateSk].blocks[updatedBlock.pk] = updatedBlock;
        } else {
          // if pk is the same, just update the existing block
          Object.assign(draft.templates[templateSk].blocks[blockPkToUpdate], updatedBlock);
        }
      }),
    );
  },
  replaceTemplateBlock: (templateSk, blockPkToUpdate, updatedBlock) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);
        // Delete the old block
        delete draft.templates[templateSk].blocks[blockPkToUpdate];
        // Assign the new block to the new ID
        draft.templates[templateSk].blocks[updatedBlock.pk] = updatedBlock;
      }),
    );
  },
  updateTemplateBlock: (templateSk, blockPk, attributes, hasUserModifiedPrompt = null) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);
        const blockToUpdate = draft.templates[templateSk].blocks[blockPk];
        draft.templates[templateSk].blocks[blockPk] = {...blockToUpdate, ...attributes};

        // triggering gpt init to handle new user modified prompt
        draft.userModifiedPrompt = hasUserModifiedPrompt;
      }),
    );
  },
  updateTemplateBlocks: (templateSk, updates) => {
    // updates multiple blocks at once. Updates is an array of objects with blockPk and attributes
    // example updates: [{blockPk: "pk_123", attributes: {isComplete: true, body: "new body"}}]
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);

        updates.forEach(({blockPk, attributes}) => {
          for (let key in attributes) {
            draft.templates[templateSk].blocks[blockPk][key] = attributes[key];
          }
        });
      }),
    );
  },
  updateTemplateBlockIndices: (templateSk, reorderedBlockPks) => {
    set(
      produce((draft) => {
        onTemplateUpdated(draft, templateSk);

        reorderedBlockPks.forEach((pk, index) => {
          draft.templates[templateSk].blocks[pk].index = index;
        });
      }),
    );
  },

  /** These are copies of the form inputs from prompt-input table, as stored in the template and used to create form */
  formInputs: [],
  setFormInputs: (formInputs) => set((state) => ({formInputs})),

  /**
   * This is the inputs object that is output from the form. Data here can be used in the export and in gpt requests.
   epInputs(object)
      - Key: inputId
      - type: "string" || "number" || "boolean"
      - value: string
      - required: boolean
   */
  epInputs: null,
  setEpInputs: (epInputs) => set((state) => ({epInputs})),
  updateEpInput: (inputId, input) => {
    set(
      produce((draft) => {
        draft.epInputs[inputId] = input;
      }),
    );
  },
  eventFormInputRefresh: 0, // this is a number that is incremented to trigger a refresh
  triggerFormInputRefresh: () => set((state) => ({eventFormInputRefresh: state.eventFormInputRefresh + 1})),

  gptReq: null,
  setGptReq: (gptReq) => set((state) => ({gptReq})),
  gptReqPromptsFinished: {}, // object: {promptId: "success || "failed"}
  batchUpdateGptReqPromptsFinished: (promptStatuses) => {
    set(
      produce((draft) => {
        Object.entries(promptStatuses).forEach(([promptId, status]) => {
          draft.gptReqPromptsFinished[promptId] = status;
        });
      }),
    );
  },

  gptReqDataMap: {},
  setGptReqDataMap: (gptReqDataMap) => set((state) => ({gptReqDataMap})),
  updateGptReqDataMap: (attributes) => set((state) => ({gptReqDataMap: {...state.gptReqDataMap, ...attributes}})),

  userDefinedNewPrompt: null,
  setUserDefinedNewPrompt: (userDefinedNewPrompt) => set((state) => ({userDefinedNewPrompt})),
  userModifiedPrompt: null,
  setUserModifiedPrompt: (userModifiedPrompt) => set((state) => ({userModifiedPrompt})),

  promptModHistory: [],
  setPromptModHistory: (promptModHistory) => set((state) => ({promptModHistory})),
  addPromptMod: (promptMod) =>
    set(
      produce((draft) => {
        draft.promptModHistory.unshift(promptMod);
      }),
    ),

  retryingFailedRequests: null,
  setRetryingFailedRequests: (retryingFailedRequests) => set((state) => ({retryingFailedRequests})),

  /** {downloadUrl, edits, statistics} */
  enhanceJob: null,
  setEnhanceJob: (enhanceJob) => set(() => ({enhanceJob})),
  enhanceEnabled: false,
  setEnhanceEnabled: (enhanceEnabled) => set(() => ({enhanceEnabled})),
  enhanceDownloading: false,
  setEnhanceDownloading: (enhanceDownloading) => set(() => ({enhanceDownloading})),
  currentPlayerId: 'enhanced',
  setCurrentPlayerId: (currentPlayerId) => set(() => ({currentPlayerId})),
  audioState: null,
  setAudioState: (audioState) => set(() => ({audioState})),
  enhancePlaybackProgress: 0,
  setEnhancePlaybackProgress: (enhancePlaybackProgress) => set(() => ({enhancePlaybackProgress})),

  chatHistory: null,
  setChatHistory: (chatHistory) => set(() => ({chatHistory})),

  // sets every state value to null, expect those specified in getStateDefaults
  nukeEpisodeStore() {
    console.log('zstore nukeEpisodeStore');
    set((state) => {
      let newState = {};

      // Set all keys in state to null except functions
      Object.keys(state).forEach((key) => {
        if (typeof state[key] !== 'function') {
          newState[key] = null;
        }
      });

      // Merge initial state values
      newState = {...newState, ...getStateDefaults(state)};

      return newState;
    });
  },
});

function calculateSpeakers(utterances) {
  const speakers = new Set();
  // find all unique speakers
  utterances.forEach((utterance) => {
    if (utterance.speaker.trim()) speakers.add(utterance.speaker);
  });

  return Array.from(speakers);
}

/** @returns: unique names submitted via episode details */
function getSubmittedGuestAndHostNames(epInputs) {
  if (!epInputs || !Object.keys(epInputs).length) return [];

  const guestNames =
    (epInputs['iid_b38ff39c21dc447cb1b4c5712c1f520d'] || {})['value']?.split(',').map((name) => name.trim()) || [];
  const hostNames =
    (epInputs['iid_cb3842b137a6495895345f1b4628eba7'] || {})['value']?.split(',').map((name) => name.trim()) || [];
  const uniqueNames = new Set([...guestNames, ...hostNames]);
  return [...uniqueNames].filter((name) => name.length); // filtering empty values
}

export default create(episodeStore);
