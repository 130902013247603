export async function retryWithExponentialBackoff(fn, maxAttempts = 3, initialDelay = 500) {
  let attempt = 0;
  let delay = initialDelay;

  while (attempt < maxAttempts) {
    try {
      return await fn();
    } catch (error) {
      console.log(`Error on attempt ${attempt + 1}:`, error);
      attempt++;

      // Check the error type (this is just an example, it might vary based on the API you are using)
      if (error.response && (error.response.status === 404 || error.response.status === 403)) {
        // No point in retrying
        throw error;
      }

      if (attempt < maxAttempts) {
        // wait for a delay before next attempt
        await new Promise((resolve) => setTimeout(resolve, delay));
        // double the delay for the next attempt
        delay *= 2;
      } else {
        throw error;
      }
    }
  }
}

export function getStringCapitalCase(str) {
  //split the above string into an array of strings
  //whenever a blank space is encountered
  const arr = str.split(' ');

  //loop through each element of the array and capitalize the first letter.
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  return arr.join(' ');
}

export async function copyToClipboard(content) {
  try {
    await navigator.clipboard.writeText(content);
    return true;
  } catch (err) {
    console.error('Failed to copy text: ', err);
    return false;
  }
}

export function replaceFileExt(fileName, newFileExt) {
  var fileNameParts = fileName.trim().split('.');

  // Check if filename has an extension
  if (fileNameParts.length < 2) {
    // If it doesn't have an extension, simply append the new extension
    return fileName + newFileExt;
  }

  var fileNameWithoutExtension = fileNameParts.slice(0, -1).join('.');
  return fileNameWithoutExtension + newFileExt;
}

// replaces spaces with underscores and makes lowercase
export function formatAsFileName(text, replaceSpaces = false) {
  if (replaceSpaces) {
    return text.trim().replace(/\s/g, '_').toLowerCase();
  }
  return text.trim();
}

/** ensures the variable is converted to a map type. The original data is stored under the 'default' key if it wasn't already a map */
export function ensureIsObject(value) {
  if (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof Date) &&
    !(value instanceof RegExp)
  ) {
    return value;
  } else {
    return {default: value};
  }
}

export function isObject(variable) {
  return typeof variable === 'object' && !Array.isArray(variable) && variable !== null;
}

export function deepEqual(a, b) {
  // If the two values are strictly equal, return true
  if (a === b) return true;

  // If the types are different, return false
  if (typeof a !== typeof b) return false;

  // If either value is null or not an object, return false
  if (a === null || b === null || typeof a !== 'object' || typeof b !== 'object') return false;

  // Get the list of keys for each object, excluding keys with undefined values
  const keysA = Object.keys(a).filter((key) => a[key] !== undefined);
  const keysB = Object.keys(b).filter((key) => b[key] !== undefined);

  // If the number of keys is different, return false
  if (keysA.length !== keysB.length) return false;

  // Check if all keys in `a` exist and are equal in `b`
  for (const key of keysA) {
    if (!keysB.includes(key)) return false;
    if (!deepEqual(a[key], b[key])) return false;
  }

  return true;
}

export function truncateAtNearestSpace(str, maxLength, addEllipsis = false) {
  // If the string is shorter than the maxLength, return it as is
  if (str.length <= maxLength) {
    return str;
  }

  // Find the nearest space before maxLength
  const truncateAt = str.substring(0, maxLength).lastIndexOf(' ');

  // If no space is found, truncate at maxLength
  if (truncateAt === -1) {
    return addEllipsis ? str.substring(0, maxLength) + "..." : str.substring(0, maxLength);
  }

  // Otherwise, return the string truncated at the last space before maxLength
  const truncatedStr = str.substring(0, truncateAt);
  return addEllipsis ? truncatedStr + "..." : truncatedStr;
}
