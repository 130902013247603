import {Text} from '@chakra-ui/react';

export const common = {
  fontFamily: 'Inter',
  fontWeight: 'bold',
  textAlign: 'left',
  verticalAlign: 'top',
};

export const H2 = (props) => (
  <Text {...common} fontSize={'30px'} lineHeight={'40px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const H3 = (props) => (
  <Text {...common} fontSize={'24px'} lineHeight={'30px'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const H35 = (props) => (
  <Text {...common} fontSize={'27px'} lineHeight={'30px'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const H4 = (props) => (
  <Text {...common} fontSize={'18px'} lineHeight={'24px'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const HeaderLgBold = (props) => (
  <Text {...common} fontSize={'lg'} lineHeight={'30px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body18 = (props) => (
  <Text {...common} fontWeight={'normal'} fontSize={'18px'} lineHeight={'28px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body18SemiBold = (props) => (
  <Text {...common} fontWeight={'semibold'} fontSize={'18px'} lineHeight={'28px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body16 = (props) => (
  <Text {...common} fontWeight={'normal'} fontSize={'16px'} lineHeight={'24px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body16Medium = (props) => (
  <Text {...common} fontWeight={'medium'} fontSize={'16px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body16SemiBold = (props) => (
  <Text {...common} fontWeight={'semibold'} fontSize={'16px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body16Bold = (props) => (
  <Text {...common} fontSize={'16px'} color={'black'} {...props}>
    {props.children}
  </Text>
);

export const Body15 = (props) => (
  <Text {...common} fontWeight={'normal'} fontSize={'15px'} lineHeight={'20px'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const Body15SemiBold = (props) => (
  <Text {...common} fontWeight={'semibold'} fontSize={'15px'} lineHeight={'20px'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const Body15Bold = (props) => (
  <Text {...common} fontSize={'15px'} lineHeight={'20px'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const BodySm = (props) => (
  <Text fontFamily={'Inter'} fontSize={'sm'} {...props}>
    {props.children}
  </Text>
);

export const BodySmMuted = (props) => (
  <Text fontFamily={'Inter'} fontSize={'sm'} color={'muted'} {...props}>
    {props.children}
  </Text>
);

export const BodySmMedium = (props) => (
  <Text fontFamily={'Inter'} fontSize={'sm'} fontWeight={'medium'} {...props}>
    {props.children}
  </Text>
);

export const BodySmSemiBold = (props) => (
  <Text fontFamily={'Inter'} fontWeight={'semibold'} fontSize={'sm'} {...props}>
    {props.children}
  </Text>
);

export const BodyMd = (props) => (
  <Text fontFamily={'Inter'} fontSize={'md'} {...props}>
    {props.children}
  </Text>
);

export const BodyMdSemiBold = (props) => (
  <Text fontFamily={'Inter'} fontSize={'md'} fontWeight={'semibold'} lineHeight={'100%'} {...props}>
    {props.children}
  </Text>
);

export const BodyXsMuted = (props) => (
  <Text fontFamily={'Inter'} fontSize={'xs'} color={'muted'} {...props}>
    {props.children}
  </Text>
);

export const Body14 = (props) => (
  <Text fontFamily={'Inter'} fontSize={'14px'} fontWeight={'normal'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);

export const Body14Light = (props) => (
  <Text fontFamily={'Inter'} fontSize={'14px'} fontWeight={'light'} color={'brand.dkgray'} {...props}>
    {props.children}
  </Text>
);
