import {Box, Flex, SimpleGrid, Stack, Switch, Text} from '@chakra-ui/react';
import axios from 'axios';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import PricingIconTierII from '../../assets/icons/airplane-blue.png';
import PricingIconTierIV from '../../assets/icons/enterprise.png';
import PricingIconTierI from '../../assets/icons/paper-airplane-blue.png';
import PricingIconTierIII from '../../assets/icons/rocket-blue.png';
import ZCard from '../../components/abstraction_high/ZCard';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {
  BuildYourOwnPlanCard,
  CurrentSubscriptionCard,
  HorizontalPricingCard,
  VerticalPricingCard,
} from '../../components/abstraction_mid/PricingCard';
import {
  FlowBody,
  FlowContainer,
  FlowHeader,
  displayErrorToast,
  displayInfoToast,
} from '../../components/common/Structural';
import {Body14, Body14Light, Body15, BodySm, BodySmMuted} from '../../components/common/TextStyle';
import {ConfirmModal, SubscriptionUpgradeModal, ZModal} from '../../components/modals/Modal';
import appStore from '../../stores/app-store';
import authStore from '../../stores/auth-store';
import {SUBSCRIPTION, TEAM} from '../../utils/api-v2';
import {useAPI} from '../../utils/api-v2-context';
import PricingUtils from '../../utils/pricing-utils';
import SessionStorageUtils from '../../utils/session-storage-utils';

const Pricing = () => {
  // navigation
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const zSubscription = authStore((state) => state.subscription);
  const zSetSubscription = authStore((state) => state.setSubscription);
  const zUser = authStore((state) => state.user);
  const zTeam = authStore((state) => state.team);
  const zSetTeam = authStore((state) => state.setTeam);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);

  const [statePricingPageLoading, setPricingPageLoading] = useState(true);
  const [stateOnFirstLoadSubscription, setOnFirstLoadSubscription] = useState(false);
  const [stateIsAnnualBilling, setIsAnnualBilling] = useState(true); // else monthly
  const [stateDisplayConfirmSubChangeModalProps, setDisplayConfirmSubChangeModalProps] = useState();
  const [stateDisplayChangeSubInfoModalProps, setDisplayChangeSubInfoModalProps] = useState();
  const [stateTriggerCancelSubModal, setTriggerCancelSubModal] = useState();

  const [stateCustomMonthlyCredits, setCustomCredits] = useState(10);
  const [stateCustomPriceTrigger, setCustomPriceTrigger] = useState(30);

  // upgrade
  const [stateUpgradeInfo, setUpgradeInfo] = useState({});
  const [stateUpgradingProduct, setUpgradingProduct] = useState();
  const [stateUpgradeInvoice, setUpgradeInvoice] = useState();

  const {subscriptionApi, teamApi} = useAPI();
  const pricingUtils = useMemo(() => new PricingUtils(zSubscription, zUser), [zSubscription, zUser]);

  useEffect(() => {
    if (!zUser?.userSub) {
      navigate('/');
      return;
    }

    const initFunction = async () => {
      await fetchSubscription(zUser.userSub);
      setPricingPageLoading(false);
    };

    if (!zUser?.userSub) return;

    initFunction();
  }, [zUser]);

  useEffect(() => {
    if (stateOnFirstLoadSubscription) {
      setOnFirstLoadSubscription(false);
      autoSubscribeUsingQueryParams();
    }
  }, [stateOnFirstLoadSubscription]);

  async function fetchSubscription() {
    const userSub = (await getTeamOwnerUserSub()) ?? zUser.userSub;

    const subscription = await subscriptionApi.getSubscription(userSub, false);
    setOnFirstLoadSubscription(true);
    zSetSubscription(subscription);
    return subscription;
  }

  async function getTeamOwnerUserSub() {
    if (!zUser.teamId) {
      return null;
    }

    let currentTeam = zTeam;
    if (!zTeam) {
      const team = await teamApi.getTeam(zUser.teamId);
      if (!team || team.length === 0) {
        zSetError('fetchSubscription', 'Error fetching team');
      }

      zSetTeam(team);
      currentTeam = team;
    }

    const teamOwner = TEAM.getTeamOwner(currentTeam);
    return teamOwner.userSub;
  }

  /** @param {string} invoiceId */
  async function fetchInvoice(invoiceId) {
    return axios.get(process.env.REACT_APP_BASE_URL + '/stripe/invoice', {params: {invoiceId}}).catch((e) => {
      setUpgradingProduct(false);
      return false;
    });
  }

  function autoSubscribeUsingQueryParams() {
    // check for missing params
    if (!searchParams) return;
    if (!searchParams.has('tier')) return;
    if (!searchParams.has('interval')) {
      displaySubModError();
      return;
    }

    const tier = searchParams.get('tier');
    const isYearly = searchParams.get('interval') === 'yearly'; // default to monthly when none set
    const tierIVMonthlyCredits = searchParams.get('credits'); // only necessary when tier is TIER_IV

    let preventTierParamUsage = false;
    // Check for tier use prevention
    if (SessionStorageUtils.checkTierUsagePrevention()) {
      console.log('Tier param usage prevention detected');
      preventTierParamUsage = true;
      return;
    }

    if (!tier || preventTierParamUsage) {
      // Reset tier use prevention
      SessionStorageUtils.clearTierUsagePrevention();
      return;
    }

    // Using tier query param for auto navigation
    console.log('Using tier query param for auto navigation, tier:', tier, 'credits:', tierIVMonthlyCredits);
    tryCheckout(tier, !isYearly, tierIVMonthlyCredits);
    // Void future use of tier query param for 10 minutes
    SessionStorageUtils.setTierUsagePrevention();
  }

  useEffect(() => {
    setCustomPriceTrigger(stateCustomMonthlyCredits * (stateIsAnnualBilling ? 3 : 5));
  }, [stateIsAnnualBilling]);

  /**
   *  SIMPLE SUB PLANS
   *  - Any customer currently operating on a non-default product ID  (customers with an old subscription)
   *      - Require that they cancel, then re-subscribe when they need to
   *  - Upgrades
   *      - What is an upgrade
   *          - default product id -> default product id
   *          - anything that gives them more credits
   *              - monthly- -> monthly+
   *              - Annual- -> annual+
   *              - monthly -> annual
   *      - Preserves current monthly credits (to prevent abuse)
   *  - Downgrades
   *      - What is a downgrade
   *          - Annual -> Monthly
   *          - Annual+ -> annual-
   *          - Monthly+ -> monthly-
   *      - Require that they cancel, then re-subscribe when they need to
   *
   * @param {*} tier
   * @param {*} tierIVMonthlyCredits - only necessary when tier is TIER_IV
   * @returns
   */
  async function tryCheckout(tier, isMonthly, tierIVMonthlyCredits = null) {
    // check for missing params
    if (!tier || isMonthly === undefined) {
      subscriptionApi.postError('tryCheckout', 'missing params', {zUser, tier, isMonthly, tierIVMonthlyCredits});
      displaySubModError();
      return;
    }

    // user must be logged in
    if (!zUser?.userSub) {
      navigate('/');
      return;
    }

    // get userSub to use for subscription handling
    const userSub = (await getTeamOwnerUserSub()) ?? zUser.userSub;

    // check for authorization
    const hasAdminAbility = TEAM.hasAdminAbility(zUser, zTeam);
    if (!hasAdminAbility) {
      displayInfoToast("Please reach out to your team admin if you'd like to modify your team's subscription.", {
        duration: 3000,
      });
      return;
    }

    // derive productId, priceId, quantity
    const productId = SUBSCRIPTION.getDefaultProductId();
    const priceId = getDefaultPriceIdByTier(tier, isMonthly);
    const quantity = getDefaultCreditsByTier(tier, isMonthly, tierIVMonthlyCredits);

    // check for errors
    if (!productId || !priceId || !quantity) {
      subscriptionApi.postError('tryCheckout', 'missing params', {
        userSub,
        productId,
        priceId,
        tier,
        tierIVMonthlyCredits,
      });
      displaySubModError();
      return;
    }

    const subscriptionId = zSubscription?.subscriptionId;

    // 4 cases: active, new, upgrade, downgrade
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) {
      // new subscription
      newSubscription(tier, userSub, priceId, productId, quantity);
    } else if (isActiveByTier(tier, isMonthly, tierIVMonthlyCredits)) {
      // active subscription
      displayInfoToast('You are currently subscribed to this plan.', {duration: 3000});
    } else if (isUpgradeAvailable(productId, priceId, isMonthly, tierIVMonthlyCredits)) {
      // upgrade subscription
      setDisplayConfirmSubChangeModalProps({subscriptionId, priceId, productId, quantity, isMonthly});
    } else if (isDowngradeAvailable(productId, priceId)) {
      // downgrade subscription
      setDisplayConfirmSubChangeModalProps({
        subscriptionId,
        priceId,
        productId,
        quantity,
        isMonthly,
        isDowngrade: true,
      });
    } else {
      // switch subscription
      displaySubModificationInstructions();
    }
  }

  async function newSubscription(tier, userSub, priceId, productId, quantity) {
    // ensure subItem exists in db. Create temporary if necessary
    let subItem = zSubscription;
    if (!subItem) {
      const response = await subscriptionApi.updateSubscriptionUsage(userSub, 0); // create new temporary subscription
      if (response?.subItem) {
        subItem = response.subItem;
      } else {
        zSetError('tierMeteredSelected', {message: 'failed to create new subscription item', data: {userSub, subItem}});
        console.log('Error: new sub item not created.');
      }
    }

    switch (tier) {
      case SUBSCRIPTION.TIER_I:
      case SUBSCRIPTION.TIER_II:
      case SUBSCRIPTION.TIER_III:
      case SUBSCRIPTION.TIER_IV:
        initStripeCheckout(userSub, priceId, productId, quantity);
        break;
      default:
        zSetError('newSubscription', {message: 'tier not found', data: {tier, userSub, priceId, productId, quantity}});
        break;
    }
  }

  /**
   * @param {string} priceId
   * @param {string} userSub
   * @param {*} tier
   */
  async function initStripeCheckout(userSub, priceId, productId, quantity) {
    console.log(`initStripeCheckout ${priceId}`);

    // proceed to stripe checkout
    try {
      zSetLoading(true);
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/stripe/checkout', {
        userSub: userSub,
        priceId: priceId,
        productId: productId,
        quantity: quantity,
      });
      const {url} = response.data;
      window.location.href = url;
    } catch (e) {
      zSetError('initStripeCheckout', {
        message: 'failed to create checkout session',
        data: {priceId, userSub, productId, quantity},
      });
    } finally {
      zSetLoading(false);
    }
  }

  /** isDowngrade changes only the display */
  async function upgradeSubscription(subscriptionId, priceId, productId, quantity, isMonthly, isDowngrade = false) {
    console.log('upgradeSubscription', productId);
    if (!subscriptionId || !priceId || !productId || !quantity || isMonthly === undefined) {
      zSetError('upgradeSubscription', {
        message: 'missing params',
        data: {subscriptionId, priceId, productId, quantity, isMonthly},
      });
      return;
    }

    const hasAdminAbility = TEAM.hasAdminAbility(zUser, zTeam);
    if (!hasAdminAbility) {
      displayInfoToast("Please reach out to your team admin if you'd like to modify your team's subscription.", {
        duration: 3000,
      });
      return;
    }

    try {
      zSetLoading(true);
      setUpgradeInfo({productId, priceId, quantity, isMonthly, isDowngrade});
      setUpgradingProduct(productId);

      const response = await axios.put(process.env.REACT_APP_BASE_URL + '/stripe/upgrade', {
        subscriptionId,
        priceId,
        quantity,
      });
      if (response.status !== 200) throw new Error('failed to upgrade');

      const invoiceResponse = await fetchInvoice(response.data.latest_invoice);
      if (invoiceResponse.status !== 200) throw new Error('failed to upgrade. Invoice unavailable');

      // update modal
      setUpgradeInvoice(invoiceResponse.data);
    } catch (e) {
      subscriptionApi.postError('upgradeSubscription', e, {
        subscriptionId,
        priceId,
        productId,
        quantity,
        isMonthly,
        zUser,
      });
      displaySubModError();
    } finally {
      setUpgradingProduct(false);
      zSetLoading(false);
    }
  }

  function displaySubModificationInstructions() {
    setDisplayChangeSubInfoModalProps({});
    // toast({
    //   title: "To switch to , please cancel your current subscription and purchase a new subscription when your existing subscription expires.",
    //   status: "info",
    //   duration: 5000,
    //   isClosable: true,
    // });
  }

  async function cancelSubscriptionNow() {
    // check for error
    if (!zSubscription || !zSubscription.subscriptionId) {
      zSetError('cancelSubscriptionNow', {message: 'no subscription found', data: {zSubscription}});
      return;
    }

    console.log('cancelSubscriptionNow');
    try {
      buildModalCancelSub(true, true);
      const isSuccess = await subscriptionApi.cancelTieredSubscriptionImmediately(
        zSubscription.userSub,
        zSubscription.subscriptionId,
      ); // create new temporary subscription
      await new Promise((r) => setTimeout(r, 5000)); // waiting for the webhook to fire
      fetchSubscription(zSubscription.userSub); // fetching updated subscription
      if (!isSuccess)
        zSetError('cancelSubscriptionNow', {message: 'failed to cancel subscription', data: {zSubscription}});
    } catch (e) {
      zSetError('cancelSubscriptionNow', e, {zSubscription});
      displaySubModError();
    } finally {
      buildModalCancelSub(false);
      setDisplayChangeSubInfoModalProps(null);
    }
  }

  function displaySubModError() {
    displayErrorToast(
      'Something went wrong while trying to modify your subscription. Please contact support using the chat icon in the bottom right corner of the screen.',
      {duration: null},
    );
  }

  function isActiveByTier(tier, isMonthly, tierIVMonthlyCredits) {
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) return false;

    switch (tier) {
      case SUBSCRIPTION.TIER_I:
        return isActiveTierI(isMonthly);
      case SUBSCRIPTION.TIER_II:
        return isActiveTierII(isMonthly);
      case SUBSCRIPTION.TIER_III:
        return isActiveTierIII(isMonthly);
      case SUBSCRIPTION.TIER_IV:
        return isActiveTierIV(isMonthly, tierIVMonthlyCredits);
      default:
        return false;
    }
  }

  function isActiveTierI(isMonthly) {
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) return false;
    const productId = zSubscription?.product;
    const priceId = zSubscription?.price_id;

    if (SUBSCRIPTION.DEFAULT_PROD_IDS.includes(productId)) {
      return isMonthly
        ? priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_I
        : priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_I;
    }
    return false;
  }

  function isActiveTierII(isMonthly) {
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) return false;
    const productId = zSubscription?.product;
    const priceId = zSubscription?.price_id;

    if (SUBSCRIPTION.DEFAULT_PROD_IDS.includes(productId)) {
      return isMonthly
        ? priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_II
        : priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_II;
    }
    return false;
  }

  function isActiveTierIII(isMonthly) {
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) {
      return false;
    }

    const productId = zSubscription?.product;
    const priceId = zSubscription?.price_id;

    if (!SUBSCRIPTION.DEFAULT_PROD_IDS.includes(productId)) {
      return false;
    }

    return isMonthly
      ? priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_III
      : priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_III;
  }

  function isActiveTierIV(isMonthly, tierIVMonthlyCredits) {
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) {
      return false;
    }

    const productId = zSubscription?.product;
    const priceId = zSubscription?.price_id;
    const quantity = zSubscription?.sub_quantity;

    // check if productId matches
    if (!SUBSCRIPTION.DEFAULT_PROD_IDS.includes(productId)) {
      return false;
    }

    // check if priceId matches
    if (isMonthly && priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_IV) {
      // check if quantity matches
      return quantity === tierIVMonthlyCredits;
    } else if (!isMonthly && priceId === SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_IV) {
      // check if quantity matches
      return Math.ceil(quantity / 12) === tierIVMonthlyCredits;
    }
  }

  function isPriceIdForTierIV(priceId) {
    return (
      SUBSCRIPTION.DEFAULT_PRICE_IDS_YEARLY_TIER_IV.includes(priceId) ||
      SUBSCRIPTION.DEFAULT_PRICE_IDS_MONTHLY_TIER_IV.includes(priceId)
    );
  }

  function isUpgradeAvailable(toProductId, toPriceId, isMonthly, tierIVMonthlyCredits = null) {
    if (!toProductId || !toPriceId) throw new Error('missing params');
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) return false;
    const fromProductId = zSubscription?.product;
    const fromPriceId = zSubscription?.price_id;

    // already subscribed to this, except for TIER_IV
    if (fromPriceId === toPriceId && !isPriceIdForTierIV(fromPriceId)) return false;

    // return false if default product id != default product id
    if (toProductId !== SUBSCRIPTION.getDefaultProductId()) return false;
    if (fromProductId !== SUBSCRIPTION.getDefaultProductId()) return false;

    // allow anything that gives more credits per month
    let fromCredits = zSubscription?.sub_quantity;
    fromCredits = fromCredits ?? SUBSCRIPTION.priceDetailsMapping.get(fromPriceId)?.defaultCredits;
    let toCredits = SUBSCRIPTION.priceDetailsMapping.get(toPriceId)?.defaultCredits;
    if (toCredits === -1) toCredits = isMonthly ? tierIVMonthlyCredits : tierIVMonthlyCredits * 12;
    if (!fromCredits || !toCredits) {
      zSetError('isUpgradeAvailable', {message: 'priceDetailsMapping failed', data: {fromPriceId, toPriceId}});
      return false;
    }
    if (toCredits > fromCredits) return true;

    return false;
  }

  function isDowngradeAvailable(toProductId, toPriceId) {
    if (!toProductId || !toPriceId) throw new Error('missing params');
    if (!SUBSCRIPTION.hasActiveSub(zSubscription)) return false;
    const fromProductId = zSubscription?.product;
    const fromPriceId = zSubscription?.price_id;

    // already subscribed to this, except for TIER_IV
    if (fromPriceId === toPriceId && !isPriceIdForTierIV(fromPriceId)) return false;

    // allowing anything to default product id from old product id
    if (fromProductId === SUBSCRIPTION.getDefaultProductId() && toProductId === SUBSCRIPTION.getDefaultProductId())
      return true;
    return false;
  }

  function getDefaultCtaByTier(tier) {
    const productId = SUBSCRIPTION.getDefaultProductId();
    const priceId = getDefaultPriceIdByTier(tier, !stateIsAnnualBilling);

    const isTierCurrentlyActive = isActiveByTier(tier, !stateIsAnnualBilling, stateCustomMonthlyCredits);
    return !SUBSCRIPTION.hasActiveSub(zSubscription)
      ? 'subscribe'
      : isTierCurrentlyActive
        ? 'active'
        : isUpgradeAvailable(productId, priceId, !stateIsAnnualBilling, stateCustomMonthlyCredits)
          ? 'upgrade'
          : isDowngradeAvailable(productId, priceId)
            ? 'switch'
            : 'switch';
  }

  const navigateToUpload = () => navigate('/');

  const LoadingSub = () => (
    <ZCard variant="outline" minHeight={'4.6rem'} mt={6}>
      <Stack>
        <BodySmMuted>Loading Plans...</BodySmMuted>
        <ZProgressBar size={'xs'} isIndeterminate={true} />
      </Stack>
    </ZCard>
  );

  function getDefaultPriceIdByTier(tier, isMonthly) {
    if (!tier || isMonthly === undefined) throw new Error('missing params');

    switch (tier) {
      case SUBSCRIPTION.TIER_I:
        return isMonthly
          ? SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_I
          : SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_I;
      case SUBSCRIPTION.TIER_II:
        return isMonthly
          ? SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_II
          : SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_II;
      case SUBSCRIPTION.TIER_III:
        return isMonthly
          ? SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_III
          : SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_III;
      case SUBSCRIPTION.TIER_IV:
        return isMonthly
          ? SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_MONTHLY_TIER_IV
          : SUBSCRIPTION.DEFAULT_CHECKOUT_PRICE_ID_ANNUAL_TIER_IV;
      case SUBSCRIPTION.TIER_TEAM:
      case SUBSCRIPTION.TIER_FREE:
        zSetError('getPriceIdByTier', {message: 'no price id for free or team', data: {tier, isMonthly}});
      default:
        zSetError('getPriceIdByTier', {message: 'tier not found', data: {tier, isMonthly}});
    }
  }

  function getDefaultCreditsByTier(tier, isMonthly, tierIVMonthlyCredits = null) {
    if (!tier || isMonthly === undefined) {
      throw new Error('missing params');
    }

    const priceId = getDefaultPriceIdByTier(tier, isMonthly);

    let credits;
    if (tier === SUBSCRIPTION.TIER_IV) {
      if (!tierIVMonthlyCredits) {
        zSetError('numUploadsByTier', {message: 'credits not found', data: {tier, priceId}});
      }

      credits = isMonthly ? tierIVMonthlyCredits : tierIVMonthlyCredits * 12; // custom credits TIER_IV
    } else {
      credits = SUBSCRIPTION.priceDetailsMapping.get(priceId).defaultCredits;
    }

    if (!credits) {
      zSetError('numUploadsByTier', {message: 'credits not found', data: {tier, priceId}});
    }

    return credits;
  }

  const BillingIntervalToggle = () => (
    <ZCard p={0} alignItems={'center'}>
      <Flex pt={'1.5rem'} pb={'1.5rem'} gap={'1rem'}>
        <Body14>Monthly Billing</Body14>
        <Switch isChecked={stateIsAnnualBilling} size="md" onChange={() => setIsAnnualBilling(!stateIsAnnualBilling)} />
        <Body14>Annual Billing</Body14>
      </Flex>
    </ZCard>
  );

  function buildModalCancelSub(isVisible, isCancelling) {
    if (!isVisible) {
      return setTriggerCancelSubModal(null);
    }

    const isCancellingBody = (
      <Stack>
        <BodySm>Working...</BodySm>
        <ZProgressBar size={'xs'} isIndeterminate={true} />
      </Stack>
    );

    const isNotCancellingBody = (
      <BodySm>
        When you cancel your subscription, you will lose access to your current plan and any remaining credits. This is
        necessary if you'd like to switch to another subscription plan now. Continue?
      </BodySm>
    );

    setTriggerCancelSubModal({
      titleText: 'Confirm Subscription Cancellation',
      positiveText: 'Continue',
      neutralText: 'Nevermind',
      body: isCancelling ? isCancellingBody : isNotCancellingBody,
      footer: isCancelling ? <Stack /> : null,
      positiveCallback: () => cancelSubscriptionNow(),
      onCloseCallback: () => setTriggerCancelSubModal(null),
      closeOnEsc: true,
      modalSize: 'xl',
    });
  }

  return (
    <>
      <FlowContainer>
        <FlowHeader
          entireComponent={
            <Flex alignItems={'start'} pt={'60px'} pb={'60px'}>
              <Stack flexGrow={1}>
                <Text
                  fontFamily={'Inter'}
                  fontWeight={'bold'}
                  fontSize={'39px'}
                  lineHeight={'145%'}
                  textAlign={'left'}
                  color={'brand.dkgray'}
                  verticalAlign={'top'}
                >
                  Select a plan
                </Text>
                <Body15>... and watch your post-production workload disappear. Welcome to the team!</Body15>
              </Stack>
            </Flex>
          }
        />

        <FlowBody>
          {statePricingPageLoading ? (
            <LoadingSub />
          ) : (
            <>
              <CurrentSubscriptionCard
                mb={'40px'}
                zSubscription={zSubscription}
                memoPricingUtils={pricingUtils}
                zUser={zUser}
                zTeam={zTeam}
                stateIsAnnualBilling={stateIsAnnualBilling}
              />

              <Stack spacing={'20px'}>
                <SimpleGrid alignItems="center" mt={'20px'} columns={{base: 1, lg: 3}} spacing={'20px'}>
                  <BillingIntervalToggle />
                  <Stack height={'100%'} justifyContent={'end'}>
                    <Flex
                      pr={'.5rem'}
                      pl={'.5rem'}
                      pt={'.25rem'}
                      pb={'.25rem'}
                      bg={'rgba(65,69,208,.09)'}
                      borderRadius={'5px'}
                      width={'fit-content'}
                    >
                      <Body14Light>save 40% annually</Body14Light>
                    </Flex>
                  </Stack>
                </SimpleGrid>

                <SimpleGrid alignItems="center" mt={'20px'} columns={{base: 1, lg: 3}} spacing={'20px'}>
                  <VerticalPricingCard
                    name={SUBSCRIPTION.getProductNamePretty(
                      null,
                      getDefaultPriceIdByTier(SUBSCRIPTION.TIER_I, !stateIsAnnualBilling),
                    )}
                    description="Spend more time creating phenomenal content!"
                    price={stateIsAnnualBilling ? 23 : 39}
                    image={PricingIconTierI}
                    monthlyCredits={getDefaultCreditsByTier(SUBSCRIPTION.TIER_I, true)}
                    defaultCTA={getDefaultCtaByTier(SUBSCRIPTION.TIER_I)}
                    onClick={() => tryCheckout(SUBSCRIPTION.TIER_I, !stateIsAnnualBilling)}
                  />
                  <VerticalPricingCard
                    name={SUBSCRIPTION.getProductNamePretty(
                      null,
                      getDefaultPriceIdByTier(SUBSCRIPTION.TIER_II, !stateIsAnnualBilling),
                    )}
                    description="Scale your efforts in a big, big way."
                    price={stateIsAnnualBilling ? 59 : 99}
                    image={PricingIconTierII}
                    monthlyCredits={getDefaultCreditsByTier(SUBSCRIPTION.TIER_II, true)}
                    defaultCTA={getDefaultCtaByTier(SUBSCRIPTION.TIER_II)}
                    onClick={() => tryCheckout(SUBSCRIPTION.TIER_II, !stateIsAnnualBilling)}
                  />
                  <VerticalPricingCard
                    name={SUBSCRIPTION.getProductNamePretty(
                      null,
                      getDefaultPriceIdByTier(SUBSCRIPTION.TIER_III, !stateIsAnnualBilling),
                    )}
                    description="Streamline and systemize your workflow. "
                    price={stateIsAnnualBilling ? 179 : 299}
                    image={PricingIconTierIII}
                    monthlyCredits={getDefaultCreditsByTier(SUBSCRIPTION.TIER_III, true)}
                    defaultCTA={getDefaultCtaByTier(SUBSCRIPTION.TIER_III)}
                    onClick={() => tryCheckout(SUBSCRIPTION.TIER_III, !stateIsAnnualBilling)}
                  />
                </SimpleGrid>

                <BuildYourOwnPlanCard
                  isAnnual={stateIsAnnualBilling}
                  callbackSetCredits={setCustomCredits}
                  newPriceTrigger={stateCustomPriceTrigger}
                  defaultCTA={getDefaultCtaByTier(SUBSCRIPTION.TIER_IV)}
                  onClick={() => tryCheckout(SUBSCRIPTION.TIER_IV, !stateIsAnnualBilling, stateCustomMonthlyCredits)}
                />
                <Box pb={24} pt={0} mt={0}>
                  <HorizontalPricingCard
                    name="Enterprise"
                    image={PricingIconTierIV}
                    description={"Have a custom request? Let's chat ->"}
                    onClick={() => window.open('https://svpg07htsta.typeform.com/to/NJDvqrpr', '_blank')}
                  />
                </Box>
              </Stack>
            </>
          )}
        </FlowBody>
      </FlowContainer>

      <Stack display={'none'}>
        <ConfirmModal
          positiveText="Continue"
          neutralText="Cancel"
          positiveCallback={() => {
            const p = stateDisplayConfirmSubChangeModalProps;
            if (!p) return;
            upgradeSubscription(p.subscriptionId, p.priceId, p.productId, p.quantity, p.isMonthly, p.isDowngrade);
          }}
          titleText={`Confirm ${stateDisplayConfirmSubChangeModalProps?.isDowngrade ? 'Switch' : 'Upgrade'} -> ${stateDisplayConfirmSubChangeModalProps?.isMonthly ? 'Monthly' : 'Yearly'} ${SUBSCRIPTION.getProductNamePretty(stateDisplayConfirmSubChangeModalProps?.productId, stateDisplayConfirmSubChangeModalProps?.priceId)} Plan`}
          bodyText={`Your current subscription will be ${stateDisplayConfirmSubChangeModalProps?.isDowngrade ? 'switched' : 'upgraded'} to the ${stateDisplayConfirmSubChangeModalProps?.isMonthly ? 'monthly' : 'yearly'} Podflow ${SUBSCRIPTION.getProductNamePretty(stateDisplayConfirmSubChangeModalProps?.productId, stateDisplayConfirmSubChangeModalProps?.priceId)} Plan with ${stateDisplayConfirmSubChangeModalProps?.isMonthly ? stateDisplayConfirmSubChangeModalProps?.quantity : stateDisplayConfirmSubChangeModalProps?.quantity / 12} uploads per month. Continue?`}
          openThisModal={stateDisplayConfirmSubChangeModalProps}
          closeModal={() => setDisplayConfirmSubChangeModalProps(null)}
          onCloseCallback={() => setDisplayConfirmSubChangeModalProps(null)}
          closeOnEsc={true}
          modalSize="xl"
        />

        <SubscriptionUpgradeModal
          upgradeInfo={stateUpgradeInfo ?? {}}
          isUpgrading={stateUpgradingProduct}
          invoice={stateUpgradeInvoice}
          navigateToUpload={navigateToUpload}
          onCloseCallback={() => {
            console.log('closing upgrade modal & refreshing subscription');
            fetchSubscription(authStore.getState().user?.userSub);
            setUpgradingProduct(false);
            setUpgradeInfo({});
            setUpgradeInvoice(null);
          }}
        />

        <ConfirmModal
          positiveText="Got it!"
          neutralText={null}
          positiveCallback={() => {}}
          titleText={'Changing Your Subscription'}
          bodyComponent={
            <Stack spacing={4}>
              <Stack spacing={1}>
                <BodySm>1. Cancel your existing subscription</BodySm>
                <Flex gap={0}>
                  <BodySm whiteSpace="pre-line">2. Wait for your existing subscription to expire,&nbsp;</BodySm>
                  <BodySm
                    color={'brand.primary'}
                    as="u"
                    _hover={{color: 'playful.purple'}}
                    whiteSpace="pre-line"
                    cursor={'pointer'}
                    onClick={() => buildModalCancelSub(true)}
                  >
                    or end it now
                  </BodySm>
                </Flex>
                <BodySm>3. Subscribe to the package of your choice.</BodySm>
              </Stack>

              <BodySm>{'Reach out if you have questions :)'}</BodySm>
              <BodySm>
                This helps us prevent duplicate charges and ensures you get the most out of your subscription.
              </BodySm>
            </Stack>
          }
          openThisModal={stateDisplayChangeSubInfoModalProps}
          closeModal={() => setDisplayChangeSubInfoModalProps(null)}
          onCloseCallback={() => setDisplayChangeSubInfoModalProps(null)}
          closeOnEsc={true}
          modalSize="xl"
        />
        <ZModal trigger={stateTriggerCancelSubModal} />
      </Stack>
    </>
  );
};

export default Pricing;
