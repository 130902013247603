import {Button, Flex, Stack, Text} from '@chakra-ui/react';
import _ from 'lodash';
import {useEffect, useState} from 'react';

import Latency from '../../components/abstraction_low/Latency';
import {AudioPlayer} from '../../utils/editor/audio-player';
import {EpisodeEditor} from '../../utils/editor/episode-editor';
import {StateManager} from '../../utils/editor/state-manager';

const DebugEditor = ({seek}) => {
  const [stateSmDebug, setSmDebug] = useState({numStates: 0});

  useEffect(() => {
    EpisodeEditor.i().addComponentCallbacks({setSmDebug});
  }, []);

  function setSeekLatency(latency) {
    EpisodeEditor.i().stateManager.setSeekLatency(latency);
  }

  function testSeek0() {
    EpisodeEditor.i().stateManager.seekByRelPos(0, true);
  }

  function testSeek() {
    EpisodeEditor.i().statemanager.seekByRelPos(6, true);
  }

  function testDelete() {
    EpisodeEditor.i().stateManager.execOperation({relStart: 1, relEnd: 10, opType: StateManager.DELETE_OP});
  }

  function testCopyState() {
    EpisodeEditor.i().stateManager.copyOp(0, 3);
  }

  function testPasteState() {
    EpisodeEditor.i().stateManager.pasteOp(2);
  }

  function testTransform() {
    EpisodeEditor.i().stateManager.execOperation({
      relStart: 7,
      relEnd: 9,
      reference: AudioPlayer.AP_RAW_REF,
      opType: StateManager.TRANSFORM_OP,
    });
  }

  function testAbsTransform() {
    EpisodeEditor.i().stateManager.execOperation({
      absStart: 1,
      absEnd: 2,
      originReference: AudioPlayer.AP_BG_NOISE_REF,
      targetReference: AudioPlayer.AP_RAW_REF,
      opType: StateManager.ABS_TRANSFORM_OP,
    });
  }

  async function runIsolatedTest() {
    EpisodeEditor.i().stateManager.copyOp(0, 2);
    EpisodeEditor.i().stateManager.pasteOp(5);
    EpisodeEditor.i().stateManager.copyOp(2, 4);
    EpisodeEditor.i().stateManager.pasteOp(5);
    EpisodeEditor.i().stateManager.copyOp(5, 10);
    EpisodeEditor.i().stateManager.pasteOp(0);
    EpisodeEditor.i().stateManager.execOperation({
      absStart: 0,
      absEnd: 3,
      reference: AudioPlayer.AP_BG_NOISE_REF,
      opType: StateManager.ABS_DELETE_OP,
    });
  }

  async function runLatencyTest() {
    // find minimum audio length
    const minAudioLengthPlayer = Object.keys(EpisodeEditor.i().stateManager.players).reduce((prev, curr) =>
      EpisodeEditor.i().stateManager.players[prev]?.totalDuration() ??
      0 < EpisodeEditor.i().stateManager.players[curr].totalDuration()
        ? EpisodeEditor.i().stateManager.players[prev]
        : EpisodeEditor.i().stateManager.players[curr],
    );

    const minAudioLength = minAudioLengthPlayer.totalDuration();

    const seekTestIncrements = 0.5; // seconds
    for (let i = 0; i < minAudioLength / seekTestIncrements - 1.5; i = i + 2) {
      EpisodeEditor.i().stateManager.execOperation({
        relStart: i * seekTestIncrements,
        relEnd: (i + 1) * seekTestIncrements,
        reference: AudioPlayer.AP_RAW_REF,
        opType: StateManager.TRANSFORM_OP,
      });
    }
  }

  function runTests() {
    let testResult;

    EpisodeEditor.i().stateManager.execOperation({relStart: 2, relEnd: 3, opType: StateManager.DELETE_OP});
    EpisodeEditor.i().stateManager.execOperation({relStart: 1, relEnd: 3, opType: StateManager.DELETE_OP});
    EpisodeEditor.i().stateManager.copyOp(0, 2);
    EpisodeEditor.i().stateManager.pasteOp(2);

    testResult = [
      {
        absStart: 0,
        absEnd: 1,
        reference: 'audio_player_background_noise_ref',
      },
      {
        reference: 'audio_player_background_noise_ref',
        absStart: 4,
        absEnd: 5,
      },
      {
        absStart: 0,
        absEnd: 1,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 4,
        absEnd: 22.1,
        reference: 'audio_player_background_noise_ref',
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 1');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();

    EpisodeEditor.i().stateManager.copyOp(0, 2);
    EpisodeEditor.i().stateManager.pasteOp(50);
    EpisodeEditor.i().stateManager.pasteOp(50);
    EpisodeEditor.i().stateManager.pasteOp(50);

    testResult = [
      {
        absStart: 0,
        absEnd: 22.1,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 0,
        absEnd: 2,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 0,
        absEnd: 2,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 0,
        absEnd: 2,
        reference: 'audio_player_background_noise_ref',
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 2');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();

    EpisodeEditor.i().stateManager.execOperation({relStart: 2, relEnd: 3, opType: StateManager.DELETE_OP});
    EpisodeEditor.i().stateManager.copyOp(0, 3);
    EpisodeEditor.i().stateManager.pasteOp(2);

    testResult = [
      {
        absStart: 0,
        absEnd: 2,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 0,
        absEnd: 2,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 3,
        absEnd: 4,
        reference: 'audio_player_background_noise_ref',
      },
      {
        reference: 'audio_player_background_noise_ref',
        absStart: 3,
        absEnd: 22.1,
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 3');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();

    EpisodeEditor.i().stateManager.execOperation({relStart: 8, relEnd: 9, opType: StateManager.DELETE_OP});
    EpisodeEditor.i().stateManager.execOperation({
      relStart: 7,
      relEnd: 9,
      reference: AudioPlayer.AP_RAW_REF,
      opType: StateManager.TRANSFORM_OP,
    });

    testResult = [
      {
        absStart: 0,
        absEnd: 7,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 7,
        absEnd: 8,
        reference: 'audio_player_raw_ref',
      },
      {
        absStart: 9,
        absEnd: 10,
        reference: 'audio_player_raw_ref',
      },
      {
        reference: 'audio_player_background_noise_ref',
        absStart: 10,
        absEnd: 22.1,
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 4');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();

    EpisodeEditor.i().stateManager.execOperation({
      absStart: 2,
      absEnd: 3,
      originReference: AudioPlayer.AP_BG_NOISE_REF,
      targetReference: AudioPlayer.AP_RAW_REF,
      opType: StateManager.ABS_TRANSFORM_OP,
    });
    EpisodeEditor.i().stateManager.execOperation({
      absStart: 2.5,
      absEnd: 4,
      originReference: AudioPlayer.AP_BG_NOISE_REF,
      targetReference: AudioPlayer.AP_RAW_REF,
      opType: StateManager.ABS_TRANSFORM_OP,
    });
    EpisodeEditor.i().stateManager.copyOp(0, 1);
    EpisodeEditor.i().stateManager.pasteOp(5);
    EpisodeEditor.i().stateManager.execOperation({
      absStart: 0,
      absEnd: 1,
      originReference: AudioPlayer.AP_BG_NOISE_REF,
      targetReference: AudioPlayer.AP_RAW_REF,
      opType: StateManager.ABS_TRANSFORM_OP,
    });

    testResult = [
      {
        absStart: 0,
        absEnd: 1,
        reference: 'audio_player_raw_ref',
      },
      {
        absStart: 1,
        absEnd: 2,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 2,
        absEnd: 4,
        reference: 'audio_player_raw_ref',
      },
      {
        absStart: 4,
        absEnd: 5,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 0,
        absEnd: 1,
        reference: 'audio_player_raw_ref',
      },
      {
        absStart: 5,
        absEnd: 22.1,
        reference: 'audio_player_background_noise_ref',
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 5');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();

    EpisodeEditor.i().stateManager.copyOp(1, 2);
    EpisodeEditor.i().stateManager.pasteOp(0);
    EpisodeEditor.i().stateManager.copyOp(2, 4);
    EpisodeEditor.i().stateManager.pasteOp(5);
    EpisodeEditor.i().stateManager.execOperation({
      absStart: 0,
      absEnd: 3,
      reference: AudioPlayer.AP_BG_NOISE_REF,
      opType: StateManager.ABS_DELETE_OP,
    });

    testResult = [
      {
        reference: 'audio_player_background_noise_ref',
        absStart: 3,
        absEnd: 22.1,
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 6');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();

    EpisodeEditor.i().stateManager.copyOp(0, 2);
    EpisodeEditor.i().stateManager.pasteOp(5);
    EpisodeEditor.i().stateManager.copyOp(2, 4);
    EpisodeEditor.i().stateManager.pasteOp(5);
    EpisodeEditor.i().stateManager.copyOp(5, 10);
    EpisodeEditor.i().stateManager.pasteOp(0);
    EpisodeEditor.i().stateManager.execOperation({
      absStart: 0,
      absEnd: 3,
      reference: AudioPlayer.AP_BG_NOISE_REF,
      opType: StateManager.ABS_DELETE_OP,
    });

    testResult = [
      {
        reference: 'audio_player_background_noise_ref',
        absStart: 3,
        absEnd: 4,
      },
      {
        absStart: 5,
        absEnd: 6,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 3,
        absEnd: 5,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 3,
        absEnd: 4,
        reference: 'audio_player_background_noise_ref',
      },
      {
        absStart: 5,
        absEnd: 22.1,
        reference: 'audio_player_background_noise_ref',
      },
    ];

    if (!_.isEqual(EpisodeEditor.i().stateManager.state, testResult)) {
      throw new Error('FAILED TEST 7');
    }

    EpisodeEditor.i().initStateManager();
    EpisodeEditor.i().initStateManagerPlayers();
  }

  const EpisodeEditorStateView = () => (
    <Flex gap={4}>
      {Object.keys(stateSmDebug).map((smDebugKey) => (
        <Stack justify={'start'}>
          <Text>{smDebugKey}</Text>
          <Text>{stateSmDebug[smDebugKey]}</Text>
        </Stack>
      ))}
    </Flex>
  );

  return (
    <Flex dir="row" width={'100%'} align={'center'} justify={'center'}>
      <Stack justify="center" align="flex-start" spacing="20px">
        <EpisodeEditorStateView />
        <Button onClick={testSeek0}>Seek0</Button>
        <Button onClick={testSeek}>Seek</Button>
        <Button onClick={testDelete}>Delete</Button>
        <Button onClick={testCopyState}>Copy</Button>
        <Button onClick={testPasteState}>Paste</Button>
        <Button onClick={testTransform}>Transform</Button>
        <Button onClick={testAbsTransform}>Abs Transform</Button>
        <Button onClick={runIsolatedTest}>Run Isolated Test</Button>
        <Button onClick={runLatencyTest}>Run Latency Test</Button>
        <Button onClick={runTests}>RUN TESTS</Button>
        <Latency setSeekLatency={setSeekLatency} defaultLatency={30} />
      </Stack>
    </Flex>
  );
};

export default DebugEditor;
