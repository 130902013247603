import {Button, Flex, Text, Tooltip, Wrap, WrapItem} from '@chakra-ui/react';

import ZPingAnimation from './ZPingAnimation';

/**
 * @param {_chakra_ui_system.ComponentWithAs<"button", ButtonProps>} param0
 */
export default function ButtonSecondary({
  rightIcon,
  leftIcon,
  centerIcon,
  size = 'md',
  textColor = 'brand.dkgray',
  fontWeight = 'medium',
  fontSize = '15px',
  centerText = false,
  sx,
  style,
  onClick,
  children,
  tooltipLabel,
  tooltipLabelPlacement = 'right',
  wrap = false, // allows button to not be wrap content rather than be full width
  flexGrow,
  showPingAnimation = false,
  width,
  borderColor = null,
  ...buttonProps
}) {
  const ButtonSecondary = (
    <Flex width={width}>
      {showPingAnimation && <ZPingAnimation />}
      <Tooltip isDisabled={!tooltipLabel} label={tooltipLabel} placement={tooltipLabelPlacement} borderRadius={'lg'}>
        <Button
          justifyContent={centerText ? 'center' : 'flex-start'}
          rightIcon={rightIcon}
          leftIcon={leftIcon}
          size={size}
          onClick={onClick}
          sx={sx}
          style={style}
          border={'1px'}
          variant={'outline'}
          borderColor={borderColor ?? 'brand.bg2'}
          _hover={{bg: 'brand.bg'}}
          width={width}
          {...buttonProps}
        >
          <Text fontWeight={fontWeight} fontSize={fontSize} textColor={textColor}>
            {children}
          </Text>
        </Button>
      </Tooltip>
    </Flex>
  );

  if (wrap) {
    return (
      <Wrap>
        <WrapItem>{ButtonSecondary}</WrapItem>
      </Wrap>
    );
  }

  return ButtonSecondary;
}

// ButtonSecondary.propTypes = {
//   rightIcon: PropTypes.element,
//   leftIcon: PropTypes.element,
//   onClick: PropTypes.func.isRequired,

//   textColor: PropTypes.string,
//   textSize: PropTypes.string,
//   fontWeight: PropTypes.string,
//   size: PropTypes.string,
//   colorScheme: PropTypes.string,
//   sx: PropTypes.object,
//   style: PropTypes.object,
// };
