import {createContext, useContext, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import authStore from '../stores/auth-store';
import episodeStore from '../stores/episode-store';
import {
  CLIPS,
  CLIP_CONFIG,
  ENHANCE,
  EPISODE,
  EPISODE_EXPORT,
  GPT,
  INPUT,
  LAMBDA,
  PROJECT,
  PROMPT,
  PROMPT_MOD,
  STAT,
  SUBSCRIPTION,
  TEAM,
  TEMPLATE,
  TEMPLATE_V2,
  TRANSCRIPT,
  USER,
} from './api-v2';

/**
 * @typedef {object} APIContextType
 * @property {USER} userApi
 * @property {STAT} statApi
 * @property {SUBSCRIPTION} subscriptionApi
 *
 * @property {PROJECT} projectApi
 * @property {TEMPLATE} templateApi
 * @property {INPUT} inputApi
 * @property {PROMPT} promptApi
 * @property {LAMBDA} lambdaApi
 * @property {ENHANCE} enhanceApi
 * @property {CLIP_CONFIG} clipConfigApi
 * @property {TEAM} teamApi
 *
 * @property {PROMPT_MOD} promptModApi
 *
 * @property {EPISODE} episodeApi
 * @property {GPT} gptApi
 * @property {TEMPLATE_V2} templateV2Api
 * @property {TRANSCRIPT} transcriptApi
 * @property {EPISODE_EXPORT} epExportApi
 * @property {CLIPS} clipsApi
 */

/** @type {React.Context<APIContextType> | undefined} */
const APIContext = createContext();

export const APIProvider = ({children}) => {
  const {projectId, episodeId} = useParams();

  const zUser = authStore((state) => state.user);
  const userSub = zUser?.userSub || projectId?.split?.('-')?.[0];
  const zUpdateEpisode = episodeStore((state) => state.updateEpisode);

  const value = {};

  // Unauthenticated APIs
  const userApi = useMemo(() => new USER(), []);
  const statApi = useMemo(() => new STAT(), []);
  const subscriptionApi = useMemo(() => new SUBSCRIPTION(), []);

  // UserSub only APIs
  const projectApi = useMemo(() => new PROJECT(userSub), [userSub]);
  const templateApi = useMemo(() => new TEMPLATE(userSub), [userSub]);
  const inputApi = useMemo(() => new INPUT(userSub), [userSub]);
  const promptApi = useMemo(() => new PROMPT(userSub), [userSub]);
  const lambdaApi = useMemo(() => new LAMBDA(userSub), [userSub]);
  const enhanceApi = useMemo(() => new ENHANCE(userSub), [userSub]);
  const clipConfigApi = useMemo(() => new CLIP_CONFIG(userSub), [userSub]);
  const teamApi = useMemo(() => new TEAM(userSub, zUser?.email), [userSub, zUser]);

  // Project Specific APIs
  const promptModApi = useMemo(() => new PROMPT_MOD(userSub, projectId), [userSub, projectId]);

  // Episode Specific APIs
  const episodeApi = useMemo(
    () =>
      new EPISODE(userSub, projectId, episodeId, (attributes) => {
        zUpdateEpisode(episodeId, attributes, true);
        return true;
      }),
    [userSub, projectId, episodeId, zUpdateEpisode],
  );
  const gptApi = useMemo(() => new GPT(userSub, projectId, episodeId), [userSub, projectId, episodeId]);
  const templateV2Api = useMemo(
    () => new TEMPLATE_V2(userSub, projectId, episodeId, episodeApi),
    [userSub, projectId, episodeId, episodeApi],
  );
  const transcriptApi = useMemo(
    () => new TRANSCRIPT(userSub, projectId, episodeId, episodeApi),
    [userSub, projectId, episodeId, episodeApi],
  );
  const epExportApi = useMemo(() => new EPISODE_EXPORT(userSub, projectId, episodeId), [userSub, projectId, episodeId]);
  const clipsApi = useMemo(() => new CLIPS(userSub, projectId, episodeId), [userSub, projectId, episodeId]);

  value['userApi'] = userApi;
  value['statApi'] = statApi;
  value['subscriptionApi'] = subscriptionApi;

  value['projectApi'] = projectApi;
  value['templateApi'] = templateApi;
  value['inputApi'] = inputApi;
  value['promptApi'] = promptApi;
  value['lambdaApi'] = lambdaApi;
  value['enhanceApi'] = enhanceApi;
  value['clipConfigApi'] = clipConfigApi;
  value['teamApi'] = teamApi;

  value['promptModApi'] = promptModApi;

  value['episodeApi'] = episodeApi;
  value['gptApi'] = gptApi;
  value['templateV2Api'] = templateV2Api;
  value['transcriptApi'] = transcriptApi;
  value['epExportApi'] = epExportApi;
  value['clipsApi'] = clipsApi;

  return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
};

export const useAPI = () => {
  const api = useContext(APIContext);
  if (api === undefined) {
    throw new Error('useAPI must be used within a APIProvider');
  }

  return api;
};

export const useUnAuthenticatedAPIs = () => {
  const api = useAPI();
  return {
    userApi: api.userApi,
    statApi: api.statApi,
    subscriptionApi: api.subscriptionApi,
  };
};

export const useAuthenticatedAPIs = () => {
  const api = useAPI();
  return {
    projectApi: api.projectApi,
    templateApi: api.templateApi,
    inputApi: api.inputApi,
    promptApi: api.promptApi,
    lambdaApi: api.lambdaApi,
    enhanceApi: api.enhanceApi,
    clipConfigApi: api.clipConfigApi,
    teamApi: api.teamApi,
  };
};

export const useProjectDependentAPIs = () => {
  const authAPIs = useAuthenticatedAPIs();
  const api = useAPI();
  return {...authAPIs, promptModApi: api.promptModApi};
};

export const useEpisodeDependentAPIs = () => {
  return useAPI();
};
