import {Box, Image, Text} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';

/**
 * @typedef {Object} NavLogoProps
 * @property {'large' | string} navSize
 */

/**
 * @param {NavLogoProps} props
 */
const NavLogo = (props) => {
  const {navSize} = props;
  const navigate = useNavigate();

  return (
    <Box
      w="260px"
      display="flex"
      flexDirection={'row'}
      gap="20px"
      paddingX={'10px'}
      justifyContent="flex-start"
      alignItems="center"
      cursor="pointer"
      onClick={() => navigate('/projects')}
    >
      <Image
        src={process.env.PUBLIC_URL + '/images/podflow-square.png'}
        height="45px"
        borderRadius="10px"
        alt="Podflow Logo"
        my={'7.5px'}
      />

      <Text display={navSize === 'large' ? 'flex' : 'none'} fontWeight="800" fontSize="40px" color="gray.800">
        podflow
      </Text>
    </Box>
  );
};

export default NavLogo;
