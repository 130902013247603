import {Box, Divider, IconButton, Stack, useColorModeValue} from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';
import {BsChevronBarLeft, BsChevronBarRight} from 'react-icons/bs';
import {useParams} from 'react-router-dom';

import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {BodyMdSemiBold} from '../../components/common/TextStyle';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';

/**
 * This is a carousel component that displays a list of templates
 *
 * @typedef {object} OnClickParams
 * @property {object} config
 * @property {string} name
 * @property {number} index
 *
 * @param {object} props
 * @param {function(OnClickParams): void} props.onClick
 * @param {object} props.selectedConfig
 *
 * @returns
 */
export const ClipTemplatesBody = ({onClick, selectedConfig}) => {
  const {projectId} = useParams();
  const userSub = projectId.split('_')[0];

  const {clipConfigApi} = useEpisodeDependentAPIs();

  const [clipConfigPresets, setClipConfigPresets] = useState([]);
  const [stateLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);

      try {
        const data = await clipConfigApi.getClipConfigs('default');
        const cleanData = data?.clipConfigs?.map((item) => ({...item, config: JSON.parse(item.config)}));
        setClipConfigPresets(cleanData);
      } catch (e) {
        console.error('Error parsing clip config', e);
        clipConfigApi.postError('fetchTemplates', 'Error parsing clip config', {userSub});
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  return (
    <Stack spacing={2}>
      {stateLoading && <ZProgressBar value={undefined} size={'xs'} isIndeterminate={true} />}
      {stateLoading ? (
        <Stack height={4} />
      ) : (
        <Carousel items={clipConfigPresets} onClick={onClick} selectedConfig={selectedConfig} />
      )}
    </Stack>
  );
};
const Carousel = ({items, onClick, selectedConfig}) => {
  const shadowColor = useColorModeValue('gray.200', 'gray.700');
  const arrowButtonStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    color: 'white',
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '50%',
    boxShadow: `0 2px 4px ${shadowColor}`,
  };

  return (
    <Box overflowX={'hidden'} position={'relative'} width={'100%'}>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={4} overflowX="scroll" height="100%">
        {items.map((item, index) => (
          <Item key={index} item={item} onClick={onClick} selectedConfig={selectedConfig} />
        ))}
      </Box>

      <IconButton
        aria-label="Previous"
        icon={<BsChevronBarLeft />}
        onClick={() => {} /* Implement previous slide functionality */}
        left="0.5rem"
        {...arrowButtonStyles}
      />
      <IconButton
        aria-label="Next"
        icon={<BsChevronBarRight />}
        onClick={() => {} /* Implement next slide functionality */}
        right="0.5rem"
        {...arrowButtonStyles}
      />
    </Box>
  );
};

const Item = ({item, onClick, selectedConfig}) => {
  const {pk, sk, name, config} = item;

  function getImgPath() {
    switch (name.toLowerCase()) {
      case 'simple':
        return '/images/gifs/clip_simple.gif';
      case 'karaoke':
        return '/images/gifs/clip_karaoke.gif';
      case 'default':
        return '/images/gifs/clip_default.gif';
    }
  }

  return (
    <Stack width="100%" spacing={2} alignItems={'center'}>
      <BodyMdSemiBold as="span">{name?.toUpperCase()}</BodyMdSemiBold>
      <Divider
        height={'2px'}
        borderRadius={'5px'}
        background={isEqual(config, selectedConfig) ? 'playful.blue' : 'brand.bg'}
      />
      <Stack
        spacing={0}
        alignItems={'center'}
        width="100%"
        background="brand.bg"
        borderRadius="md"
        boxShadow="md"
        overflow="hidden"
        cursor={'pointer'}
        _hover={{background: 'brand.bg2'}}
        onClick={() => onClick(config, {pk, sk}, name)}
      >
        <img src={getImgPath()} alt={name} />
      </Stack>
    </Stack>
  );
};
