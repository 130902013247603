import {
  Badge,
  Box,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react';
import {Player} from '@remotion/player';
import _ from 'lodash';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FiCheck, FiEdit, FiInfo, FiX} from 'react-icons/fi';
import {GoStack} from 'react-icons/go';
import {MdDownload} from 'react-icons/md';
import {useParams} from 'react-router-dom';

import ButtonPrimary from '../../components/abstraction_high/ButtonPrimary';
import ButtonSecondary from '../../components/abstraction_high/ButtonSecondary';
import ZCard from '../../components/abstraction_high/ZCard';
import ZProgressBar from '../../components/abstraction_high/ZProgressBar';
import {ZColorPicker, ZIconButton} from '../../components/common/ComponentStyle';
import {BodySm, BodySmMuted, BodySmSemiBold, BodyXsMuted, H3} from '../../components/common/TextStyle';
import {ModalShell} from '../../components/modals/Modal';
import {VideoProd} from '../../components/remotion/VideoProd';
import {
  AspectRatio,
  AspectRatioValue,
  BackgroundColor,
  FitFill,
  FontFamily,
  TextColor,
  convertVhToPx,
  defaultInputProps,
  useGroupWords,
  useHeightWidth,
  useWidthHeight,
} from '../../components/remotion/utils';
import appStore from '../../stores/app-store';
import episodeStore from '../../stores/episode-store';
import projectStore from '../../stores/project-store';
import {replaceFileExt} from '../../utils';
import {CLIP_CONFIG, Clip, EPISODE} from '../../utils/api-v2';
import {useEpisodeDependentAPIs} from '../../utils/api-v2-context';
import Constants from '../../utils/constants';
import {getPublicS3URL} from '../../utils/data-transfer';
import {Loader} from '../../utils/loader';
import {getUUID} from '../../utils/uuid';
import {ClipTemplatesBody} from './ClipsTemplatesBody';

/**
 * @typedef {object} Config
 * @property {string} url
 * @property {Transcript} transcript
 * @property {number?} startTime
 * @property {number?} endTime
 * @property {AspectRatio} aspectRatio
 * @property {BackgroundColor} backgroundColor
 * @property {TextColor} textColor
 * @property {number} fontSize
 * @property {FontFamily} fontFamily
 * @property {FontWeight} fontWeight
 * @property {TextPosition} textPosition
 * @property {boolean} fill
 * @property {number} fillPosition
 * @property {boolean} showCaption
 *
 * @typedef {<K extends keyof Config>(key: K, value: Config[K]) => void} UpdateInputType
 */

const Clips = () => {
  const {projectId, episodeId} = useParams();

  // zStore data
  const zEpisode = episodeStore((state) => state.episode);
  const zProject = projectStore((state) => state.project);
  const zTranscript = episodeStore((state) => state.transcript);
  const zSetLoading = appStore((state) => state.setLoading);
  const zSetError = appStore((state) => state.setError);
  const url = useMemo(() => getPublicS3URL(Constants.S3_DATA_BUCKET, zEpisode?.raw_object_key), [zEpisode]);

  /**
   * @typedef {Clip & {index: number}} StateClip
   * @type {[StateClip | null, (clip: StateClip | null) => void]}
   */
  const [selectedClip, setSelectedClip] = useState(null);
  const [stateSelectedClipConfigItem, setSelectedClipConfigItem] = useState(null);

  const {lambdaApi, episodeApi, projectApi, clipsApi, clipConfigApi} = useEpisodeDependentAPIs();

  /**
   * @type {[Clip[], (clips: Clip[]) => void]}
   */
  const [clips, setClips] = useState([]);
  useEffect(() => {
    if (!zEpisode || !zProject) return; // wait for these to load
    if (clips.length > 0 && clips.every((clip) => ['error', 'complete'].includes(clip.status))) return; // If we already have clips, don't fetch them again

    const fetchClipConfig = async () => {
      if (zProject.configClipPreset) {
        const configItem = await clipConfigApi.getClipConfig(
          zProject.configClipPreset.pk,
          zProject.configClipPreset.sk,
        );
        if (configItem) {
          configItem.key = {pk: configItem.pk, sk: configItem.sk};
          setSelectedClipConfigItem(configItem);
        } else {
          zSetError('configClipPreset');
        }
      }
    };

    // If the user has requested clips, fetch the clips, otherwise fetch the clip config
    if (zEpisode.userRequestedClips) {
      pollClips();
    } else {
      fetchClipConfig();
    }
  }, [zEpisode, zProject]);

  useEffect(() => {
    return () => refClipLoader.current?.stop(); // CLEANUP
  }, []);

  const refClipLoader = useRef(null);

  function pollClips() {
    refClipLoader.current?.stop(); // resetting loader if one already exists

    const loader = new Loader(() => clipsApi.getClips(), {timeout: 30 * 60 * 1000}, 'pollClips'); // 30 min timeout
    loader.initialResponse((clips) => setClips(clips || []));
    loader.progress((clips) => setClips(clips || []));
    loader.isDone((clips) => (!clips ? false : clips.every((clip) => ['error', 'complete'].includes(clip.status)))); // check that each clip status is either error or complete
    loader.done((clips) => setClips(clips || []));
    loader.start();

    refClipLoader.current = loader; // adding loader reference to be removed on unmount
  }

  async function userRequestedClips() {
    zSetLoading(true);

    const projectUpdatePromise = projectApi.updateProject(projectId, {
      configClipPreset: {pk: stateSelectedClipConfigItem.key.pk, sk: stateSelectedClipConfigItem.key.sk},
    });
    const episodeUpdatePromise = episodeApi.updateEpisode({userRequestedClips: true});
    const lambdaInvokePromise = lambdaApi.invokeDataPipeline(projectId, episodeId);

    // ensure each response was successful
    const responses = await Promise.all([projectUpdatePromise, episodeUpdatePromise, lambdaInvokePromise]);
    responses.forEach((isSuccess) => (!isSuccess ? zSetError('userRequestedClips', 'Error requesting enhance') : null));

    zSetLoading(false);
  }

  async function changeDefaultTemplate(config, key) {
    setSelectedClipConfigItem({key, config});
  }

  /* eslint-disable no-restricted-globals */
  const onSave = async () => {
    if (!confirm('Are you sure you want to save this clip?')) {
      return;
    }

    const {index, clipId, ...newClip} = selectedClip;
    newClip.status = 'queued';

    const resp = await clipsApi.updateClip(clipId, newClip);
    if (!resp) {
      console.error('Error saving clip');
      return;
    }

    setClips((p) =>
      Object.values({...p, [index]: {clipId, ...newClip, updatedAt: Date.now()}}).sort(
        (a, b) => b.updatedAt - a.updatedAt,
      ),
    );
    setSelectedClip(null);

    const success = await lambdaApi.invokeClipRenderJobFinder(projectId, episodeId);
    if (!success) {
      zSetError('clipRenderJobFinder', 'Error requesting clip render');
    }

    pollClips();
  };

  const onDelete = async (clipId) => {
    if (!confirm('Are you sure you want to delete this clip?')) {
      return;
    }

    const resp = await clipsApi.deleteClip(clipId);
    if (!resp) {
      console.error('Error deleting clip');
      return;
    }

    setClips((p) => p.filter((c) => c.clipId !== clipId));
  };

  const onEdit = (index, clip) => {
    console.log('Editing clip:', index, clip);
    setSelectedClip({index, ...clip});
  };

  const onCancel = () => {
    setSelectedClip(null);
  };

  const onDownload = (clipUrl, clipName) => {
    const link = document.createElement('a');
    link.href = clipUrl;

    if (!clipName) clipName = 'Clip' + replaceFileExt(zEpisode.fileName, '.mp4');
    clipName = clipName.replace(/[^a-zA-Z0-9]/g, '_');
    const fileName = 'Clip' + ': ' + clipName + '.mp4';
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onRefresh = async () => {
    try {
      const resp = await clipsApi.getClips();
      if (resp) setClips(resp);
    } catch (error) {
      console.error('Error searching clips');
      console.error(error);
    }
  };

  const onAdd = async () => {
    try {
      const clipId = getUUID();
      const newClip = {name: 'New Clip', startTime: 0, endTime: 60, config: defaultInputProps};
      const resp = await clipsApi.postClip(clipId, newClip);
      if (resp) {
        setClips((p) => [{clipId, ...newClip}, ...p]);
      }
    } catch (error) {
      console.error('Error searching clips');
      console.error(error);
    }
  };

  /* eslint-enable no-restricted-globals */
  const memoBody = useMemo(() => {
    // check if fileExt is supported
    const fileExt = zEpisode?.fileExtInitial;
    const supportedFileExt = Constants.SUPPORTED_VIDEO_FILE_EXTENSIONS.map((ext) => ext.slice(1)); // remove the dot
    if (!supportedFileExt.includes(fileExt)) {
      return (
        <Stack>
          <ZCard variant="outline" borderColor={'playful.blue'} pt={2} pb={2}>
            <BodySmSemiBold>You uploaded audio... Upload a video file to use Clip Studio Beta!</BodySmSemiBold>
            <BodySmMuted>
              Support for audiograms is coming soon. If you want to take advantage of clip studio now, upload a video
              file.
            </BodySmMuted>
          </ZCard>
        </Stack>
      );
    }

    if (!zEpisode?.userRequestedClips) {
      return (
        <Stack gap={4}>
          <ClipTemplatesBody onClick={changeDefaultTemplate} selectedConfig={stateSelectedClipConfigItem?.config} />
        </Stack>
      );
    }

    if (clips?.length == 0) return <ClipProgress status={'clips in progress'} />;

    if (selectedClip) {
      return (
        <EpClipEdit
          episode={zEpisode}
          clip={selectedClip}
          setClip={setSelectedClip}
          url={url}
          transcript={zTranscript}
          onSave={onSave}
          onCancel={onCancel}
        />
      );
    } else {
      const sortedClips =
        clips && Array.isArray(clips)
          ? [...clips].sort((a, b) => {
              const scoreA = typeof a.score === 'number' ? a.score : -Infinity;
              const scoreB = typeof b.score === 'number' ? b.score : -Infinity;
              return scoreB - scoreA; // sort by score, descending
            })
          : [];

      return (
        <Stack spacing={4}>
          {sortedClips.map((clip, index) => (
            <EpClipItem
              key={index}
              index={index}
              episode={zEpisode}
              clip={clip}
              transcript={zTranscript}
              url={url}
              onEdit={() => onEdit(index, clip)}
              onDelete={() => onDelete(clip.clipId)}
              onDownload={() => onDownload(clip.outputUrl, clip.title)}
            />
          ))}
        </Stack>
      );
    }
  }, [clips, selectedClip, stateSelectedClipConfigItem, zEpisode?.userRequestedClips, zEpisode?.fileExtInitial, url]);

  return (
    <Stack spacing={0}>
      <Stack spacing={4}>
        <EpSectionHeader
          minHeight={'4.6rem'}
          height={'4.6rem'}
          projectId={projectId}
          onRefresh={onRefresh}
          onAdd={onAdd}
          userRequestedClips={userRequestedClips}
          stateSelectedClipConfigItem={stateSelectedClipConfigItem}
        />

        <Flex gap={4}>
          <EpSectionSidebar flex={3} clip={selectedClip} setClip={setSelectedClip} transcript={zTranscript} />

          <EpSectionBody flex={7}>{memoBody}</EpSectionBody>
        </Flex>
      </Stack>
    </Stack>
  );
};

/**
 *
 * @param {object} param0
 * @param {string} param0.projectId
 * @param {function(): void} param0.onRefresh
 * @param {function(): void} param0.onAdd
 * @param {object} props
 * @returns
 */
const EpSectionHeader = ({projectId, onRefresh, onAdd, userRequestedClips, stateSelectedClipConfigItem, ...props}) => {
  const disclosure = useDisclosure();

  const zEpisode = episodeStore((state) => state.episode);

  const onCloseDefaultConfig = () => disclosure.onClose();
  const onOpenDefaultConfig = () => disclosure.onOpen();
  const onConfirmDefaultConfig = async () => {
    disclosure.onClose();

    const resp = await CLIP_CONFIG.updateClipConfig(projectId, {config: JSON.stringify(defaultInputProps)});
    if (!resp) {
      console.error('Error updating default clip input props');
      return;
    }
  };

  return (
    <ZCard variant="outline" {...props}>
      <Stack height={'100%'}>
        <Stack height={'100%'} direction="row" justify="space-between">
          <Flex alignItems={'center'} gap={2}>
            <BodySmSemiBold>Clip Studio</BodySmSemiBold>
            <Stack>
              <Tooltip
                placement="bottom-start"
                borderRadius={'5px'}
                label={
                  'Clip Studio builds social media assets, so you can leverage your podcast to grow your brand. Select a style, and clip studio will curate 30-60 second shareable audio/video snippets from your episode.'
                }
              >
                <Stack>
                  <Icon w={'.81rem'} h={'.81rem'} color={'black'} as={FiInfo} _hover={{color: 'playful.blue'}} />
                </Stack>
              </Tooltip>
            </Stack>

            <Badge ml={2} colorScheme={'green'}>
              Beta
            </Badge>
          </Flex>

          <Spacer />

          {!zEpisode?.userRequestedClips && (
            <ButtonPrimary isDisabled={!stateSelectedClipConfigItem} onClick={userRequestedClips}>
              Generate Clips
            </ButtonPrimary>
          )}

          {/* <ZIconButton icon={<Icon as={MdRefresh} />} label="Refresh" onClick={onRefresh} />
            <ZIconButton icon={<Icon as={FiPlus} />} label="Add" onClick={onAdd} /> */}

          {/* <ModalShell
            disclosure={disclosure}
            closedView={
              <ZIconButton icon={<Icon as={MdSettings} />} label="DefaultClipConfig" onClick={onOpenDefaultConfig} />
            }
            header="Default Clip Input Props"
            body={
              <Stack spacing={4}>
                <FormLabel>Aspect Ratio</FormLabel>
                <Select placeholder="Select Aspect Ratio">
                  {Object.entries(AspectRatio).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>

                <FormLabel>Background Color</FormLabel>
                <Select placeholder="Select Background Color">
                  {Object.entries(BackgroundColor).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>

                <FormLabel>Text Color</FormLabel>
                <Select placeholder="Select Text Color">
                  {Object.entries(TextColor).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>

                <FormLabel>Font Family</FormLabel>
                <Select placeholder="Select Font Family">
                  {Object.entries(FontFamily).map(([k, v]) => (
                    <option key={k} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>

                <FormLabel>Font Size</FormLabel>
                <ZNumberInput min={0} max={64} step={2} />

                <FormLabel>Font Weight</FormLabel>
                <ZNumberInput min={100} max={900} step={100} defaultValue={300} />

                <FormLabel>Text Position</FormLabel>
                <ZNumberInput min={0} max={100} step={1} />
              </Stack>
            }
            footer={
              <Stack direction="row" justify="flex-end">
                <ZIconButton icon={<Icon as={FiCheck} />} label="Confirm" onClick={onConfirmDefaultConfig} isPrimary />
                <ZIconButton icon={<Icon as={FiX} />} label="Close" onClick={onCloseDefaultConfig} />
              </Stack>
            }
          /> */}
        </Stack>
      </Stack>
    </ZCard>
  );
};

/**
 * TODO: maybe don't take config as a prop, or at least update them here
 *
 * TODO: Move down with the screen
 *
 * @param {object} param0
 * @param {Clip} param0.clip
 * @param {function(Clip): void} param0.setClip
 * @param {object} props
 * @returns
 */
const EpSectionSidebar = ({children, clip, setClip, transcript, ...props}) => {
  const config = clip?.config || defaultInputProps;
  const updateConfig = (key, value) => {
    setClip((p) => {
      const newConfig = {...p.config, [key]: value};
      return {...p, config: newConfig};
    });
  };

  const memoMaxEndTime = useMemo(() => {
    if (!transcript) return clip?.endTime ?? 0;

    const clipEnd = Math.floor(transcript.words[transcript.words.length - 1].end / 100) / 10;
    return clipEnd;
  }, [transcript]);

  // Templates
  const templatesDisclosure = useDisclosure();
  const onOpenTemplates = () => {
    templatesDisclosure.onOpen();
  };
  const onTemplateSelect = (config) => {
    setClip((p) => ({...p, config: {...p.config, ...config}}));
    templatesDisclosure.onClose();
  };

  if (!clip) return null;

  return (
    <Flex direction={'column'} gap={2} {...props}>
      {clip && (
        <>
          <ModalShell
            modalSize={'3xl'}
            disclosure={templatesDisclosure}
            closedView={
              <ButtonSecondary
                width={'100%'}
                centerText={true}
                leftIcon={<Icon as={GoStack} />}
                onClick={() => onOpenTemplates()}
              >
                Select a Style Template
              </ButtonSecondary>
            }
            header="Clip Style Templates"
            body={<ClipTemplatesBody onClick={onTemplateSelect} selectedConfig={clip.config} />}
          />
          <ZCard variant="outline" overflowY={'scroll'}>
            <Stack spacing={4}>
              <Stack spacing={0}>
                <Flex gap={2}>
                  <Stack spacing={0}>
                    <FormLabel>Start</FormLabel>
                    <NumberInput
                      min={Math.max(0, (clip?.endTime ?? 0) - 60)}
                      max={clip?.endTime ?? 0}
                      step={0.1}
                      value={clip?.startTime}
                      onChange={(v) => {
                        try {
                          const parsedValue = parseFloat(v);
                          if (!isNaN(parsedValue)) {
                            setClip((p) => ({...p, startTime: parsedValue}));
                          }
                        } catch (e) {
                          console.error('Failed to parse value:', e);
                        }
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Stack>

                  <Stack spacing={0}>
                    <FormLabel>End</FormLabel>
                    <NumberInput
                      min={clip?.startTime ?? 0}
                      max={Math.min(memoMaxEndTime, (clip?.startTime ?? 0) + 60)}
                      step={0.1}
                      value={clip?.endTime}
                      onChange={(v) => {
                        try {
                          const parsedValue = parseFloat(v);
                          if (!isNaN(parsedValue)) {
                            setClip((p) => ({...p, endTime: parsedValue}));
                          }
                        } catch (e) {
                          console.error('Failed to parse value:', e);
                        }
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Stack>
                </Flex>
                <Flex justifyContent={'end'}>
                  <BodySmSemiBold>{Math.round(parseFloat(clip?.endTime - clip?.startTime))} seconds</BodySmSemiBold>
                </Flex>
              </Stack>

              <Stack spacing={0}>
                <FormLabel>Aspect Ratio</FormLabel>
                <Stack spacing={2} direction="row">
                  {Object.entries(AspectRatio).map(([k, v]) => (
                    <ZIconButton
                      key={k}
                      icon={<BodyXsMuted color={config.aspectRatio === v ? 'white' : undefined}>{v}</BodyXsMuted>}
                      isPrimary={config.aspectRatio === v}
                      onClick={() => updateConfig('aspectRatio', v)}
                    />
                  ))}
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Stack spacing={0} flex="2">
                  <FormLabel>Layout</FormLabel>
                  <Stack spacing={2} direction="row">
                    {Object.entries(FitFill).map(([k, v]) => (
                      <ZIconButton
                        key={k}
                        icon={<BodyXsMuted color={config.fill === v ? 'white' : undefined}>{v}</BodyXsMuted>}
                        isPrimary={config.fill === (v === 'Fill' ? true : false)}
                        onClick={() => updateConfig('fill', v === 'Fill' ? true : false)}
                      />
                    ))}
                  </Stack>
                </Stack>
                {config.fill && (
                  <Stack spacing={0} flex="1">
                    <FormLabel>Fill Position</FormLabel>
                    <NumberInput
                      min={0}
                      max={100}
                      step={0.1}
                      value={config.fillPosition || 50}
                      onChange={(v) => updateConfig('fillPosition', parseFloat(v))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Stack>
                )}
              </Stack>

              {config.fill && (
                <Stack spacing={2} direction="row">
                  <Slider
                    value={config.fillPosition || 50}
                    onChange={(v) => updateConfig('fillPosition', v)}
                    min={0}
                    max={100}
                    step={0.1}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                  </Slider>
                </Stack>
              )}

              <Checkbox
                isChecked={config.showCaption}
                colorScheme="blue"
                onChange={() => updateConfig('showCaption', !config.showCaption)}
              >
                {config.showCaption ? 'Captions On' : 'Captions Off'}
              </Checkbox>

              <Flex justifyContent={'space-between'}>
                <Stack spacing={0}>
                  <FormLabel>Background Color</FormLabel>
                  <ZColorPicker
                    color={config.backgroundColor}
                    colors={Object.values(BackgroundColor)}
                    onChange={(color) => updateConfig('backgroundColor', color)}
                  />
                </Stack>

                <Stack spacing={0}>
                  {/* Text Styles */}
                  <FormLabel>Text Color</FormLabel>
                  <ZColorPicker
                    color={config.textColor}
                    colors={Object.values(TextColor)}
                    onChange={(color) => updateConfig('textColor', color)}
                  />
                </Stack>
              </Flex>

              <Stack spacing={0}>
                <FormLabel>Font</FormLabel>
                <Select
                  placeholder="Select Font Family"
                  value={config.fontFamily}
                  onChange={(event) => updateConfig('fontFamily', event.target.value)}
                >
                  {Object.entries(FontFamily).map((v) => (
                    <option key={v[0]} value={v[1]}>
                      {v[1]}
                    </option>
                  ))}
                </Select>
              </Stack>

              <Stack spacing={0}>
                <FormLabel>Font Size</FormLabel>
                <Slider
                  // value={parseFloat(config.fontSize?.replace('rem', '')) || parseFloat(defaultInputProps.fontSize.replace('rem', ''))}
                  // onChange={(v) => updateConfig('fontSize', v + 'rem')}
                  value={parseFloat(config.fontSize)}
                  onChange={(v) => updateConfig('fontSize', v)}
                  min={0}
                  max={4}
                  step={0.1}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6} />
                </Slider>
              </Stack>

              <Stack spacing={0}>
                <FormLabel>Font Weight</FormLabel>
                <Slider
                  value={parseInt(config.fontWeight)}
                  onChange={(v) => updateConfig('fontWeight', v)}
                  min={100}
                  max={900}
                  step={100}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6} />
                </Slider>
              </Stack>

              <Stack spacing={0}>
                <FormLabel>Text Position</FormLabel>
                <Slider
                  value={parseInt(config.textPosition?.replace('%', ''))}
                  onChange={(v) => {
                    updateConfig('textPosition', v + '%');
                  }}
                  min={0}
                  max={100}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6} />
                </Slider>
              </Stack>

              {Constants.DEV_ENV && (
                <ButtonSecondary
                  width={'100%'}
                  centerText={true}
                  onClick={() => {
                    console.log('clip config: ', config);
                    console.log(FontFamily[0], FontFamily);
                  }}
                >
                  DEV_DEBUG: Print Clip Config
                </ButtonSecondary>
              )}
            </Stack>
          </ZCard>
        </>
      )}
    </Flex>
  );
};

const EpSectionBody = ({children, ...props}) => {
  return (
    <Stack overflowY={'scroll'} {...props} spacing={0}>
      {children}
    </Stack>
  );
};

/**
 * TODO: name doesn't need to be editable here so people don't spin off a rerender just to update the name
 *
 * @param {object} param0
 * @param {EPISODE} param0.episode
 * @param {Clip} param0.clip
 * @param {function(Clip): void} param0.setClip
 * @param {string} param0.url
 * @param {Transcript} param0.transcript
 * @param {function(): void} param0.onSave
 * @param {function(): void} param0.onCancel
 * @param {function(): void} param0.onDelete
 * @param {function(): void} param0.onDownload
 */
const EpClipEdit = ({episode, clip, setClip, url, transcript, onSave, onCancel}) => {
  const [prev, grouped, next] = useGroupWords(transcript.words, clip.startTime, clip.endTime);
  const _prev = prev.text?.slice(prev.text.length - 50, prev.text.length - 1);
  const _next = next.text?.slice(0, 50);

  return (
    <ZCard variant="outline" direction="row" justify="space-between" padding={4}>
      <Stack spacing={8} w="100%">
        <Flex gap={2}>
          {/* 
          <Spacer />

          <Stack spacing={2} direction="row">
            <Input
              placeholder="Clip Name"
              value={clip?.name}
              onChange={(e) => setClip((p) => ({...p, name: e.target.value}))}
            />
          </Stack> */}

          <Spacer />

          <ButtonSecondary leftIcon={<Icon as={FiX} />} onClick={onCancel}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary leftIcon={<Icon as={FiCheck} />} onClick={onSave}>
            Save Changes
          </ButtonPrimary>
        </Flex>

        <Stack id="ep-player" spacing={0} direction="row" justify="center">
          <EpPlayer episode={episode} clip={clip} url={url} transcript={transcript} height={convertVhToPx(50)} />
        </Stack>

        <div>
          <BodySmMuted as="span" color="gray.400">
            {_prev ? '...' + _prev : ''}
          </BodySmMuted>
          <BodySmMuted as="span">{grouped.text}</BodySmMuted>
          <BodySmMuted as="span" color="gray.400">
            {_next ? _next + '...' : ''}
          </BodySmMuted>
        </div>
      </Stack>
    </ZCard>
  );
};

/**
 * @param {object} param0
 * @param {EPISODE} param0.episode
 * @param {Clip} param0.clip
 * @param {Transcript} param0.transcript
 * @param {function(): void} param0.onEdit
 * @param {function(): void} param0.onDelete
 * @param {function(): void} param0.onDownload
 */
const EpClipItem = ({index, episode, clip, transcript, url, onEdit, onDelete, onDownload}) => {
  const [prev, grouped, next] = useGroupWords(transcript?.words, clip.startTime, clip.endTime);

  const maxHeightPx = convertVhToPx(50);
  const maxWidthPx = convertVhToPx(50);
  const memoWidthHeight = useMemo(() => {
    const config = clip.config || defaultInputProps;
    if (AspectRatioValue[config.aspectRatio] > 1) {
      return {width: maxWidthPx, height: null};
    } else {
      return {width: null, height: maxHeightPx};
    }
  }, [clip.config]);

  if (!transcript?.words) return null;
  return (
    <Stack spacing={4}>
      {/* <ZCard padding={0} variant="outline" spacing={0}> */}
      <Flex gap={2}>
        <H3 color={'gray.400'} fontStyle="italic">{`#${index + 1}`}</H3>
        <H3>{clip.title}</H3>
      </Flex>
      <Flex gap={4}>
        <Stack spacing={0} borderRadius={'10px'}>
          <Stack spacing={2} overflow={'visible'}>
            <Box width={'100%'} cursor={'pointer'}>
              <EpPlayer
                episode={episode}
                clip={clip}
                url={url}
                transcript={transcript}
                width={memoWidthHeight.width}
                height={memoWidthHeight.height}
              />
            </Box>

            <Flex justifyContent={'end'} gap={2}>
              <ButtonSecondary
                isDisabled={clip.status !== 'complete'}
                width={'100%'}
                centerText={true}
                leftIcon={<Icon as={FiEdit} />}
                onClick={onEdit}
              >
                Edit Clip
              </ButtonSecondary>
              <ButtonPrimary
                width={'100%'}
                centerText={true}
                isLoading={clip.status !== 'complete'}
                loadingText={clip.status === 'error' ? 'Status: Error' : clip.status ?? 'Status: Unknown'}
                leftIcon={<Icon as={MdDownload} />}
                onClick={onDownload}
              >
                Download
              </ButtonPrimary>
              {/* <ZIconButton tooltipText={'Edit Clip'} icon={<Icon as={FiEdit} />} label="Edit" onClick={onEdit} /> */}
              {/* <ZIconButton
                tooltipText={'Download HD'}
                icon={<Icon as={MdDownload} />}
                label="Download"
                onClick={onDownload}
              /> */}
              {/* <ZIconButton
              tooltipText={'Delete Clip'}
              icon={<Icon as={MdDeleteOutline} />}
              label="Delete"
              onClick={onDelete}
            /> */}
            </Flex>
          </Stack>
        </Stack>

        <Stack>
          {/* <EpElementContainer title={`Clip ${index + 1} Title`}>
            <BodySm>{clip.title}</BodySm>
          </EpElementContainer> */}
          {/* <EpElementContainer title={'Time Range'}>
            <BodySm>
              {formatTime(clip.startTime)} - {formatTime(clip.endTime)}
            </BodySm>
          </EpElementContainer> */}

          <EpElementContainer title={'Score'}>
            <BodySm>{clip.score}</BodySm>
          </EpElementContainer>

          <EpElementContainer title={'Score Explanation'}>
            <BodySm>{clip.scoreReasoning}</BodySm>
          </EpElementContainer>

          <EpElementContainer title={'Transcript'}>
            <BodySm>{grouped.text}</BodySm>
          </EpElementContainer>
        </Stack>
      </Flex>
    </Stack>
  );
};
/**
 * @param {object} param0
 * @param {EPISODE} param0.episode
 * @param {Clip} param0.clip
 * @param {string} param0.url
 * @param {Transcript} param0.transcript
 * @param {number} param0.width - only one of width or height should be set
 * @param {number} param0.height - only one of width or height should be set
 */
const EpPlayer = ({episode, clip, url, transcript, width: initWidth, height: initHeight}) => {
  const config = clip.config || defaultInputProps;

  const widthHeight = useWidthHeight(config.aspectRatio, initWidth);
  const heightWidth = useHeightWidth(config.aspectRatio, initHeight);
  const [width, height] = initWidth ? widthHeight : heightWidth;

  const fps = 30;
  const durationInFrames = Math.max(0, parseInt(clip.endTime * fps - clip.startTime * fps));

  return (
    <>
      {!!height && !!width && !!durationInFrames && (
        <Player
          component={VideoProd}
          inputProps={{
            ...config,
            url,
            words: transcript.words,
            startTime: clip.startTime,
            endTime: clip.endTime,
          }}
          durationInFrames={durationInFrames}
          compositionWidth={width}
          compositionHeight={height}
          fps={fps}
          controls
        />
      )}
    </>
  );
};

const ClipProgress = ({status, ...props}) => {
  function getStatusText(status) {
    if (status === 'error') {
      return 'running';
    } else if (status === 'waiting') {
      return 'in progress...';
    } else {
      return status;
    }
  }

  return (
    <ZCard padding={0} variant="outline" spacing={0} {...props}>
      <Box overflow={'hidden'} width={'100%'} borderRadius={'10px'}>
        <ZProgressBar size={'xs'} isIndeterminate={true} />
      </Box>
      <Wrap m={0}>
        <WrapItem m={0}>
          <Box borderRightRadius={'5px'} backgroundColor={'blue.100'} px={3}>
            <BodySm muted={true}>{getStatusText(status)}</BodySm>
          </Box>
        </WrapItem>
      </Wrap>
      <Stack p={3} spacing={3}>
        <Text fontSize={'sm'} color={'gray.400'} pt={3} pl={3} pr={3} fontStyle={'italic'}>
          {status === 'failed'
            ? 'We encountered an error while creating this content. Click retry, or contact support if the issue persists.'
            : "...We're working our magic. Your clips will appear here when they're ready."}
        </Text>
        {status !== 'failed' && (
          <Text fontSize={'sm'} color={'gray.400'} pl={3} pr={3} fontStyle={'italic'}>
            Est. wait time: 3 minutes. You can leave this page and come back later.
          </Text>
        )}
      </Stack>
    </ZCard>
  );
};

const EpElementContainer = ({children, title, isSelected, ...props}) => {
  const ElementLabel = ({...props}) => {
    return (
      <Wrap m={0}>
        <WrapItem m={0}>
          <Box
            border={'1px'}
            borderTopRadius={'5px'}
            borderBottomEndRadius={'5px'}
            borderColor={isSelected ? 'playful.blue' : 'gray.300'}
            px={3}
            // cursor="pointer"
            // _hover={{bg: 'gray.100'}}
          >
            <BodySmMuted color={isSelected ? 'playful.blue' : 'black'}>{title}</BodySmMuted>
          </Box>
        </WrapItem>
      </Wrap>
    );
  };

  const ElementBlockDivider = ({...props}) => {
    return (
      <Flex pr={2} justifyContent={'center'}>
        {isSelected ? (
          <Divider orientation="vertical" borderColor={'playful.blue'} />
        ) : (
          <Divider orientation="vertical" borderColor={'gray.300'} />
        )}
      </Flex>
    );
  };

  return (
    <Stack width={'100%'} spacing={0} {...props}>
      <ElementLabel />
      <Flex gap={2} width={'100%'}>
        <ElementBlockDivider />
        <Stack width={'100%'} spacing={0} pt={2}>
          {children}
        </Stack>
      </Flex>
    </Stack>
  );
};

export {Clips};
