import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {BiBox} from 'react-icons/bi';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';

import FancyButton from '../abstraction_high/FancyButton';

// Create a text input component
function InputField({name, value, setValue}) {
  const label = useMemo(() => name.charAt(0).toUpperCase() + name.slice(1), [name]);
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input placeholder={label} value={value} onChange={(e) => setValue(e.target.value)} />
    </FormControl>
  );
}

// Create the Popover
function ProviderModal(props) {
  const {
    audioLibraryItem,
    fields,
    selectedProvider,
    onSave,
    disclosure: {isOpen, onOpen, onClose},
  } = props;

  const initialRef = useRef();
  const finalRef = useRef();

  const initialFields = useMemo(() => {
    const audioLibraryItemProvider =
      selectedProvider === audioLibraryItem?.provider?.key ? audioLibraryItem?.provider : {};
    return Object.fromEntries(fields.map((name) => [name, audioLibraryItemProvider[name] || '']));
  }, [
    fields,
    audioLibraryItem.provider?.key,
    audioLibraryItem.provider?.token,
    audioLibraryItem.provider?.clientSecret,
    audioLibraryItem.provider?.clientID,
  ]);

  const [inputValues, setInputValues] = useState(initialFields);

  const handleSave = useCallback(() => {
    onSave(inputValues);
  }, [inputValues, onSave]);

  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Your Account Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {fields.map((name) => (
            <InputField
              key={name}
              name={name}
              value={inputValues[name] || ''}
              setValue={(value) => setInputValues((prev) => ({...prev, [name]: value}))}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <FancyButton mr={3} onClick={handleSave}>
            Save
          </FancyButton>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * Renders the form for selecting a podcast provider.
 * @param {Object} props - The component's props.
 * @param {Object} props.provider - The selected podcast provider.
 * @param {Array} props.providers - The list of available podcast providers.
 * @param {Function} props.onChange - A function that gets called when a podcast provider is selected.
 * @param {Function} props.onOpen - A function that gets called when the popover is opened.
 * @param {Function} props.onClose - A function that gets called when the popover is closed.
 * @param {boolean} props.isOpen - Whether the popover is currently open.
 */
const ProviderForm = (props) => {
  const {audioLibraryItem, provider, providers, onChange, onOpen, onClose, isOpen} = props;
  const formDisclosure = useDisclosure();
  const [requiredVariables, setRequiredVariables] = useState([]);
  const [newProvider, setNewProvider] = useState(null);

  /**
   * Handles a click on a podcast provider.
   * @param {Object} e - The click event.
   */
  const handleProviderClick = (x) => {
    setNewProvider(x);
    const p = providers.find((v) => v.key === x);
    setRequiredVariables(p.requiredVariables);
    onClose(); // close popover
    formDisclosure.onOpen(); // open requiredVariables Form
  };

  /**
   * Handles a form submission.
   * @param {Object} form - The form data.
   */
  const handleFormSubmit = (form) => {
    formDisclosure.onClose();
    onChange({key: newProvider, form});
  };

  return (
    <>
      <ProviderModal
        audioLibraryItem={audioLibraryItem}
        selectedProvider={newProvider}
        fields={requiredVariables}
        disclosure={formDisclosure}
        onSave={handleFormSubmit}
      />

      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="top-start" closeOnBlur>
        <PopoverTrigger>
          <Stack
            borderRadius="8px"
            width="50px"
            height="50px"
            cursor="pointer"
            border="2px solid var(--chakra-colors-gray-100)"
            // _hover={{ border: "3px solid var(--chakra-colors-gray-100)" }}
            justify="center"
            align="center"
          >
            {provider?.logo_url ? (
              <Image src={provider.logo_url} alt="Favicon of Selected Provider" />
            ) : (
              <Stack
                borderRadius="8px"
                width="50px"
                height="50px"
                background="gray.200"
                justify="center"
                align="center"
              >
                <Icon as={BiBox} boxSize={6} />
              </Stack>
            )}
          </Stack>
        </PopoverTrigger>
        <PopoverContent py={2} width="none">
          <Grid>
            {providers.map((providerItem) => (
              <GridItem key={providerItem.key}>
                <Stack
                  direction="row"
                  py={1}
                  px={8}
                  height="30px"
                  borderRadius="16px"
                  _hover={{bg: 'gray.100'}}
                  cursor="pointer"
                  onClick={() => handleProviderClick(providerItem.key)}
                >
                  <Image src={providerItem.logo_url} alt="Favicon of Selected Provider" />
                  <Text
                    px={2}
                    fontFamily="Inter"
                    lineHeight="1.33"
                    fontWeight="regular"
                    maxWidth="500px"
                    fontSize="20px"
                    color="gray.600"
                    textAlign="center"
                    textOverflow="ellipsis"
                  >
                    {providerItem.key.toUpperCase()}
                  </Text>
                </Stack>
              </GridItem>
            ))}
          </Grid>
        </PopoverContent>
      </Popover>
    </>
  );
};

const PodcastForm = (props) => {
  const {audioLibraryItem, podcast, podcasts, onChange, onOpen, onClose, isOpen, providerSelected} = props;

  console.log('podcasts: ', podcast, podcasts);

  /**
   * Handles a click on a podcast.
   * @param {Object} e - The click event.
   */
  const handlePodcastClick = (podcastID) => {
    onClose();
    console.log({podcastID});
    onChange({podcastID});
  };

  if (!providerSelected) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Text
          fontFamily="Inter"
          lineHeight="1.33"
          fontWeight="regular"
          maxWidth="500px"
          fontSize="20px"
          color="gray.600"
          textAlign="center"
          textOverflow="ellipsis"
        >
          {'Select a Podcast Provider'}
        </Text>
      </Stack>
    );
  }

  if (podcasts?.length <= 0) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Text
          fontFamily="Inter"
          lineHeight="1.33"
          fontWeight="regular"
          maxWidth="500px"
          fontSize="20px"
          color="gray.600"
          textAlign="center"
          textOverflow="ellipsis"
        >
          {'---'}
        </Text>
      </Stack>
    );
  }

  return (
    <>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="top-start" closeOnBlur>
        <PopoverTrigger>
          <Stack direction="row" justifyContent="space-between" p={2} borderRadius={8} cursor="pointer">
            <Text
              fontFamily="Inter"
              lineHeight="1.33"
              fontWeight="regular"
              maxWidth="500px"
              fontSize="20px"
              color="gray.600"
              textAlign="center"
              textOverflow="ellipsis"
            >
              {podcast?.title ?? 'Select a Podcast'}
            </Text>
            <IconButton size="sm" icon={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />} />
          </Stack>
        </PopoverTrigger>
        <PopoverContent py={2} width="none">
          <Grid>
            {podcasts.map((podcast) => (
              <GridItem key={podcast.id}>
                <Text
                  data-value={podcast.id}
                  onClick={() => handlePodcastClick(podcast.id)}
                  py={1}
                  px={8}
                  _hover={{bg: 'gray.100'}}
                  cursor="pointer"
                  fontFamily="Inter"
                  lineHeight="1.33"
                  fontWeight="regular"
                  maxWidth="500px"
                  fontSize="20px"
                  color="gray.600"
                  textAlign="center"
                  textOverflow="ellipsis"
                >
                  {podcast.title}
                </Text>
              </GridItem>
            ))}
          </Grid>
        </PopoverContent>
      </Popover>
    </>
  );
};

// 4. Create the Layout/ProviderButton
const ProviderButton = (props) => {
  const {
    audioLibraryItem,
    podcast,
    podcasts = [],
    provider,
    providers = [],
    onProviderChange,
    onPodcastChange,
    providerDataIsLoading,
    podcastDataIsLoading,
  } = props;

  const [popoverSelected, setPopoverSelected] = useState(null);
  const onClose = () => setPopoverSelected(null);

  if (providerDataIsLoading) {
    return <CircularProgress color="teal" trackColor="" size={10} isIndeterminate />;
  }

  if (podcastDataIsLoading) {
    return (
      <Stack direction="row" justify="flex-start" align="center" spacing="20px" width="100%">
        <ProviderForm
          audioLibraryItem={audioLibraryItem}
          provider={provider}
          providers={providers}
          onChange={onProviderChange}
          onOpen={() => setPopoverSelected('provider')}
          onClose={onClose}
          isOpen={popoverSelected === 'provider'}
        />
        <CircularProgress color="teal" trackColor="" size={10} isIndeterminate />
      </Stack>
    );
  }

  return (
    <Stack direction="row" justify="flex-start" align="center" spacing="20px" width="100%">
      <ProviderForm
        audioLibraryItem={audioLibraryItem}
        provider={provider}
        providers={providers}
        onChange={onProviderChange}
        onOpen={() => setPopoverSelected('provider')}
        onClose={onClose}
        isOpen={popoverSelected === 'provider'}
      />
      <PodcastForm
        audioLibraryItem={audioLibraryItem}
        podcast={podcast}
        podcasts={podcasts}
        onChange={onPodcastChange}
        onOpen={() => setPopoverSelected('podcast')}
        onClose={onClose}
        isOpen={popoverSelected === 'podcast'}
        providerSelected={!!provider}
      />
    </Stack>
  );
};

export default ProviderButton;
