import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {useEffect} from 'react';
import {BsLightningCharge} from 'react-icons/bs';
import {useNavigate} from 'react-router-dom';

import ButtonPrimary from '../abstraction_high/ButtonPrimary';
import {ZAlertFullWidth} from '../common/ComponentStyle';
import {BodyMd} from '../common/TextStyle';

export default function SubUpgradeModal({message, openModal, closeModal}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    if (openModal) {
      onOpen();
    } else {
      onClose();
    }
  }, [openModal]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent padding={2}>
        <ModalCloseButton />
        <ModalHeader>
          <Text lineHeight="1" fontWeight="bold" fontSize="2xl">
            Upgrade to Continue
          </Text>
        </ModalHeader>
        <ModalBody>
          <Box pt={8} pb={4}>
            <ZAlertFullWidth status="info">You've run out of monthly uploads.</ZAlertFullWidth>
          </Box>
          <BodyMd>{message}</BodyMd>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent={'end'} pt={8}>
            <ButtonPrimary wrap={true} leftIcon={<Icon as={BsLightningCharge} />} onClick={() => navigate('/pricing')}>
              upgrade
            </ButtonPrimary>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
