export default class Constants {
  static DEV_ENV = process.env.REACT_APP_DEV_ENV;
  static BASE_URL = process.env.REACT_APP_DEV_ENV ? 'http://localhost:3000' : 'https://app.podflow.ai';

  static DEFAULT_TEMPLATE_VERSION = 'V_1_22_24';

  // UPLOAD
  static PIPELINE_STAGES = {
    raw: true,
    normalize: true,
    cleanvoiceEnhance: false,
    transcribe: true,
    awsTranscribe: false,
    assemblyTranscribe: false,
    summarize: true,
    suggest: false,
    completed: true,
  };

  static SUPPORTED_VIDEO_FILE_EXTENSIONS = ['.mp4', '.mov', '.webm']; // '.avi', '.flv', '.wmv', '.mkv', '.mpeg', '.3gp', '.m4v',
  static SUPPORTED_AUDIO_FILE_EXTENSIONS = ['.mp3', '.wav', '.m4a', '.aac']; // '.ogg' '.flac', '.wma'
  static SUPPORTED_FILE_EXTENSIONS =
    Constants.SUPPORTED_AUDIO_FILE_EXTENSIONS + Constants.SUPPORTED_VIDEO_FILE_EXTENSIONS;

  static MAX_UPLOAD_AND_NORMALIZATION_TIME = 1000 * 60 * 10;

  // SHOWNOTES
  static GENERATED_MATERIALS_TO_MAKE_VISIBLE = [
    'general_title',
    'general_summary',
    'long_summary',
    'timestamps',
    'episode_links',
    'best_quotes',
    'all_quotes',
    'blog_post',
    'social_post',
    'hashtags',
  ];
  static GENERATED_MATERIALS_TO_ORDER = [
    'general_title',
    'general_summary',
    'long_summary',
    'timestamps',
    'episode_links',
    'best_quotes',
    'all_quotes',
    'blog_post',
    'social_post',
    'hashtags',
  ];

  static AUTH0_SIGNUP_REDIRECT_SECRET = 'p;{5S5>7W-S.YVR';

  static TRANSCRIPT_FLOW = 'transcript';
  static MATERIALS_FLOW = 'materials';
  static MATERIALS_ENHANCE_FLOW = 'materials_enhance';

  // OTHER
  static S3_DATA_BUCKET = 'podflow-data';
}
