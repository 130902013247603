import axios from 'axios';

window.Buffer = window.Buffer || require('buffer').Buffer;

export async function getPresignedURL(objectKey, bucket) {
  try {
    console.log('getting presigned url');
    console.log(`objectKey: ${objectKey} & bucket: ${bucket}`);
    const response = await axios.get(process.env.REACT_APP_BASE_URL + '/s3/getPresignedURL', {
      params: {
        objectKey: objectKey,
        bucket: bucket,
      },
    });
    if (response.status !== 200) {
      throw new Error('error: getPresignedURL() failed');
    }
    const json = await response.data;
    let {presignedURL} = json;
    console.log(presignedURL);

    return presignedURL;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function getPK(fileUDID) {
  const pkParts = fileUDID.split('___');
  const projectId = pkParts[0];
  const episodeId = Number(pkParts[1]);
  return {projectId, episodeId};
}

export async function getAudioLibraryItem(fileUDID) {
  try {
    const {userSub, timestamp} = getPK(fileUDID);
    const response = await axios.get(process.env.REACT_APP_BASE_URL + '/ddb/audioLibraryItem', {
      params: {
        userSub: userSub,
        timestamp: timestamp,
      },
    });
    return response.data;
  } catch (e) {
    console.log('failed getAudioLibraryItem()');
    console.log(e);
    return null;
  }
}

export async function invokeBatchGenerateMaterialsLambda(fileUDID, blogTopicIndex, materialsToGenerate) {
  console.log('invokeBatchGenerateMaterials');
  try {
    const {userSub, timestamp} = getPK(fileUDID);
    const response = await axios.post(process.env.REACT_APP_BASE_URL + '/lambda/invokeBatchGenerateMaterials', {
      userSub: userSub,
      timestamp: timestamp,
      blogTopicIndex: blogTopicIndex,
      materialsToGenerate: materialsToGenerate,
    });

    return response;
  } catch (e) {
    console.log('invokeBatchGenerateMaterialsLambda(): ', e);
    return null;
  }
}

export async function getGeneratedMaterialsItem(fileUDID) {
  try {
    const {userSub, timestamp} = getPK(fileUDID);
    const response = await axios.get(process.env.REACT_APP_BASE_URL + '/ddb/generated-materials', {
      params: {
        userSub: userSub,
        timestamp: timestamp,
      },
    });
    // remove userSub and timestamp so we get only the generated materials
    if (response.data.userSub) {
      delete response.data.userSub;
    }
    if (response.data.timestamp) {
      delete response.data.timestamp;
    }
    // console.log(response.data);
    return response.data;
  } catch (e) {
    console.log('failed getGeneratedMaterialsItem', e);
    return null;
  }
}

export async function putGeneratedMaterialsItem(fileUDID, generatedMaterials) {
  console.log('postGeneratedMaterialsItem', fileUDID);
  try {
    if (!fileUDID) {
      throw new Error('403: not authenticated');
    }

    const materialsToUpload = generatedMaterials;
    const {userSub, timestamp} = getPK(fileUDID);
    const response = await axios.put(process.env.REACT_APP_BASE_URL + '/ddb/generated-materials', {
      userSub: userSub,
      timestamp: timestamp,
      generatedMaterials: materialsToUpload,
    });
    // console.log("postGeneratedMaterialsItem response status:" + response.status)
    return response.status;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export function getPublicS3URL(bucket, objectKey) {
  return 'https://' + bucket + '.s3.amazonaws.com/' + encodeURIComponent(objectKey);
  // https://sur4ide-transcriptions.s3.amazonaws.com/auth0%7C63b77e8c44641e0a186ffe44___1673557631036___transcription.txt
}
