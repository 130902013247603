import axios from 'axios';

import {getPK} from '../data-transfer';
import {AudioPlayer} from './audio-player';
import {StateManager} from './state-manager';
import {TextManager} from './text-manager';
import {Waveform} from './waveform';

export class EpisodeEditor {
  static instance = null;
  static i() {
    // "i" stands for instance. This is a singleton pattern.
    if (EpisodeEditor.instance == null) {
      EpisodeEditor.instance = new EpisodeEditor();
    }
    return this.instance;
  }

  constructor() {
    this.stateManager = null;
    this.players = null;
    this.waveform = null;
    this.textManager = null;

    this.componentCallbacks = null;
    this.playersReady = {};

    this.audioState = AudioPlayer.STATE_UNLOADED;
    this.peaks = [];

    // debug
    this.loadStartTime = 0;
  }

  // INIT AND RESET
  async init(fileUDID) {
    console.log('initializing episode editor');
    this.fileUDID = fileUDID;
    this.initStateManager();
    this.initTextManager();
    await this.loadAudioAndInitPlayers();
  }

  resetEditor() {
    this.stateManager = null;
    this.players = null;
    this.waveform = null;
    this.textState = null;
    this.componentCallbacks = null;
  }

  // CALLBACKS
  addComponentCallbacks(callbacks) {
    console.log('adding component callbacks', callbacks);
    this.componentCallbacks = {...this.componentCallbacks, ...callbacks};
  }
  smOnFinish() {
    this.componentCallbacks.eOnFinish();
  }
  smOnEmptyState() {
    this.componentCallbacks.eOnEmptyState();
  }
  smUpdateDebugState(debugState) {
    if (this.componentCallbacks.setSmDebug) this.componentCallbacks.setSmDebug(debugState);
  }
  setAudioState(state) {
    this.audioState = state;
    this.componentCallbacks.eSetAudioState(state);
    if (this.componentCallbacks.teSetAudioState) this.componentCallbacks.teSetAudioState(state);
  }
  apOnLoad(id) {
    const updatedPlayers = this.playersReady;
    updatedPlayers[id] = true;
    this.playersReady = updatedPlayers;

    const self = this;
    function isAllPlayersReady(updatedPlayers) {
      console.log('statePlayersReady. took ', (self.loadStartTime - Date.now()) / 1000, ' seconds');
      return updatedPlayers[AudioPlayer.AP_RAW_REF] === true; // && statePlayersReady[AP.AP_BG_NOISE_REF] === true && statePlayersReady[AP.AP_AGGRESSIVE_REF] === true
    }

    if (!this.stateManager.players && isAllPlayersReady(updatedPlayers)) {
      console.log('all players ready. checking duration.', this.players[AudioPlayer.AP_RAW_REF].totalDuration()); // browsers only give duration after interaction, so we check that duration is available here or force the user to interact
      if (!this.players[AudioPlayer.AP_RAW_REF].totalDuration()) {
        console.log('duration is 0. forcing user to interact with audio player.');
        this.componentCallbacks.eSetPromptInteraction(true);
        return;
      }

      this.stateManager.initAudioPlayers(this.players);
    }
  }
  apOnStateChange(state) {
    this.setAudioState(state);
  }
  apOnPlay(reference) {
    this.stateManager.apOnPlay(reference);
  }
  apOnPause(reference) {
    this.stateManager.apOnPause(reference);
    // stopTimer();
  }
  apOnEnd(reference) {
    this.stateManager.apOnEnd(reference);
    // stopTimer();
  }
  tmOnWordItems(wordItems) {
    if (this.componentCallbacks.teSetWordItems) this.componentCallbacks.teSetWordItems(wordItems);
  }
  tmOnTranscript(transcript) {
    if (this.componentCallbacks.teSetTranscript) this.componentCallbacks.teSetTranscript(transcript);
  }
  tmOnTranscriptLoadError() {
    this.componentCallbacks.teOnTranscriptLoadError();
  }
  // waveform
  wfOnPositionChange(position) {
    this.componentCallbacks.teOnPositionChange(position);
  }
  wfSetRegion(region) {
    // {start: 0.0, end: 0.0}
    this.componentCallbacks.teSetRegion(region);
  }
  wfSetTotalAudioTime(totalAudioTime) {
    this.componentCallbacks.teSetTotalAudioTime(totalAudioTime);
  }

  initStateManager() {
    this.stateManager = new StateManager(this.stateManagerCallbacks);
  }

  /** must be called after audio players finish loading and have content */
  initStateManagerPlayers() {
    console.log('ee initStateManagerPlayers');
    this.stateManager.initAudioPlayers(this.players);
  }

  initTextManager() {
    this.textManager = new TextManager(this.textManagerCallbacks, this.fileUDID);
  }

  initWaveform(width) {
    this.waveform = new Waveform(this.waveformCallbacks);
  }

  async loadAudioAndInitPlayers() {
    const epochNow = Date.now();
    console.log('starting load. now: ', epochNow);
    this.loadStartTime = Date.now();

    const rawAudioResources = ['audio/48K_BR_Z.wav']; // ["https://sur4ide-audio.s3.us-west-2.amazonaws.com/episodes/30S_441K_128BR.mp3"];
    const bgNoiseAudioResources = ['audio/48K_BR_Z.wav']; // ["audio/48K_BR_Z_MASTERED.wav"];
    const aggressiveAudioResources = [''];
    // const aggressiveEditsResource = ''; // exported list of aggressive edits

    this.initAudioPlayers(rawAudioResources, bgNoiseAudioResources, aggressiveAudioResources);
  }

  initAudioPlayers(rawAudioResources, bgNoiseAudioResources, aggressiveAudioResources) {
    /** contains multiple instances of AudioPlayer. indices are 'raw', 'bg_noise', '' */
    this.players = {
      [AudioPlayer.AP_RAW_REF]: new AudioPlayer(rawAudioResources, AudioPlayer.AP_RAW_REF, this.audioPlayerCallbacks),
      [AudioPlayer.AP_BG_NOISE_REF]: new AudioPlayer(
        bgNoiseAudioResources,
        AudioPlayer.AP_BG_NOISE_REF,
        this.audioPlayerCallbacks,
      ),
      // [AP.AP_AGGRESSIVE_REF]: new AP(aggressiveAudioResources, AP.AP_AGGRESSIVE_REF, this.audioPlayerCallbacks),
    };
  }

  /**
   *
   * @param {*} status - editing, draft, rendering, complete
   * @param {*} extraAttributes - any additional attributes to add to the snapshot
   * @returns true if successful, false otherwise
   */
  async updateSnapshot(status, extraAttributes = {}) {
    try {
      const smSnapshot = this.stateManager.getStateManagerSnapshot();

      const {userSub, timestamp} = getPK(this.fileUDID);
      const response = await axios.put(process.env.REACT_APP_BASE_URL + '/edit/snapshot', {
        userSub: userSub,
        timestamp: timestamp,
        ...smSnapshot,
        ...extraAttributes,
      });

      if (response.status !== 200) throw new Error('putSnapshot failed with status ' + response.status);

      return true;
    } catch (e) {
      console.log('putSnapshot failed', e);
      return false;
    }
  }

  setPeaks(peaks) {
    this.peaks = peaks;
  }
}
