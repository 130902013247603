export default class LocalStorageUtils {
  static isLocalStorageAvailable() {
    try {
      const key = '__test__';
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  static clearLocalStorage() {
    localStorage.clear();
  }
}
